import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { lastValueFrom, take } from 'rxjs';
import Owner from 'src/app/models/owner.model';

@Component({
  selector: 'app-team-main-view',
  templateUrl: './team-main-view.component.html',
  styleUrls: ['./team-main-view.component.scss'],
})
export class TeamMainViewComponent implements OnInit {
  @Input('matStepperNext') matStepperNext: any;
  owner
  constructor(
    private store:Store<{owner:Owner}>
  ) {
    
  }

  async ngOnInit() {
    this.owner = await lastValueFrom(this.store.select('owner').pipe(take(1)))

  }
}
