import { TranslateService } from '@ngx-translate/core';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UserInfo } from 'src/app/apis/users-info.service';
import { isArbolesTransaction } from 'src/app/models/cash-flow/cashFlow';
import {
  convertToMetricSystem,
  convetToOtherUnit,
} from 'src/app/utils/unit-convert';
import units from '../../../../config/unit.json';
@Component({
  selector: 'app-product-cashflow-list-item',
  templateUrl: './product-cashflow-list-item.component.html',
  styleUrls: ['./product-cashflow-list-item.component.scss'],
})
export class ProductCashflowListItemComponent implements OnInit, OnChanges {
  @Input() selectedPorduct;
  @Input() filtreList;
  @Input() product_id;
  @Input() is_money;
  @Input() initial_unit;
  lang;
  is_convertion_needed = true;
  constructor(private userInfo: UserInfo,
    public translateService:TranslateService) {}

  async ngOnInit() {
    this.lang = await this.userInfo.getSelectedLangage();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.is_convertion_needed = this.initial_unit !== 'unit';
    if (Array.isArray(this.filtreList))
      this.filtreList = this.filtreList.map((cashflow) => {
        const unit = units.all.find((un) => un.value === cashflow.unity);
        const otherUnit = units.all.find(
          (un) => un.value === this.initial_unit
        );
        var toOriginalValue = cashflow.quantity;
        if (unit && otherUnit && unit != otherUnit) {
          const toMetricQty = convertToMetricSystem(unit, cashflow.quantity);
          toOriginalValue = convetToOtherUnit(otherUnit, toMetricQty);
        }
        if (isArbolesTransaction(cashflow)) this.is_convertion_needed = false;
        cashflow.converted_quantity = toOriginalValue;
        cashflow.displayable_unit = unit;
        this.selectedPorduct.displayable_unit = otherUnit;
        return cashflow;
      });
  }

  formatDate(date) {
    const options = {
      weekday: 'short',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    return date.toLocaleDateString('es-ES', options);
  }
}
