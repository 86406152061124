import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { FamilyMainViewComponent } from './family-main-view/family-main-view.component';
import { FamilyViewTwoComponent } from './family-view-two/family-view-two.component';
import { FamilyViewOneComponent } from './family-view-one/family-view-one.component';
import { FamilyRoutingModule } from './family-routing.module';
import { FooterModule } from '../../widgets/footer-component/header-footer.module';
import { ModalPageComponent } from './modal-page/modal-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { EffectsModule } from '@ngrx/effects';
import { FamilyMemberEffect } from 'src/app/utils/states-management/effects/family-members.effect';
import { AutofocusDirective } from 'src/app/utils/autofocus.directive';

@NgModule({
  declarations: [
    FamilyMainViewComponent,
    FamilyViewOneComponent,
    FamilyViewTwoComponent,
    ModalPageComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  imports: [
    TranslateModule.forChild(),
    FooterModule,
    FamilyRoutingModule,
    MatTabsModule,
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    EffectsModule.forFeature([FamilyMemberEffect]),
    
  ],
  exports: [
    FamilyMainViewComponent,
    FamilyViewOneComponent,
    FamilyViewTwoComponent,
    ModalPageComponent,
  ],
})
export class FamilyModule {}
