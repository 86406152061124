import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { dev } from '../config/endpoints.json';
import { Inject, Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { Objective } from '../models/objective.model';

@Injectable({ providedIn: 'root' })
export class ProgramasAPI {
  base_url = environment.appRoot + '/api/';
  constructor(private http: HttpClient) {}

  getProgramasByCountryID(id): Observable<any> {
    const url =
      this.base_url +
      dev.endpoint.programas.get_programas_by_country_id +
      '/' +
      id;
    return this.http.get(url);
  }
  addSubsciption(id) {
    const url =
      this.base_url + dev.endpoint.programas.add_subscription + '/' + id ;

    return this.http.get(url);
  }
  getVotes(): Observable<any> {
    const url =
      this.base_url + dev.endpoint.programas.get_vote_by_owner_and_programas_id;

    return this.http.get(url);
  }
  removeVote(id): Observable<any> {
    const url = this.base_url + dev.endpoint.programas.remove_vote + '/' + id;

    return this.http.delete(url);
  }
  insertUpdatVotes(vote) {
    const url = this.base_url + dev.endpoint.programas.insert_updat_votes;
    const formData = new FormData();
    formData.append('owner_id', vote?.owner_id);
    formData.append('prog_id', vote?.programas_id);
    formData.append('stars_number', vote?.stars_number);
    formData.append('comment', vote?.comment);

    return this.http.post(url, formData);
  }
}
