import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { dev } from '../../../../config/offlines_keys.json';
import { Storage } from '@capacitor/storage';
import { CashflowAPI } from 'src/app/apis/cashflow.service';
import { HttpClient } from '@angular/common/http';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import {
  orderAlphabaticallyByAttribute,
  pushOtrosToEnd,
} from '../../../../utils/array-filtrer';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-expenses-selector',
  templateUrl: './expenses-selector.component.html',
  styleUrls: ['./expenses-selector.component.scss'],
})
export class ExpensesSelectorComponent implements OnInit {
  expenses;
  @Input() cashFlow;
  categories;
  current_required_form_json;
  selected_category;
  selected_sub_category;
  selected_sub_sub_category;

  selected_expenses;
  selected_sub_expenses;
  selected_sub_sub_expenses;
  @Output() cashflowCategoriesChange: EventEmitter<CashFlow> =
    new EventEmitter<CashFlow>();
  labels: any = null;
  lang;
  pago_per_cantitad_coeschada = 0;

  constructor(
    private httpclient: HttpClient,
    private translateService: TranslateService
  ) {}

  async ngOnInit() {
    this.lang = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );

    const apiCashflow = new CashflowAPI(this.httpclient);
    apiCashflow.getCashflowExpenses().subscribe((value) => {
      this.expenses = value;

      if (Array.isArray(this.expenses)) {
        this.expenses = pushOtrosToEnd(
          this.expenses,
          this.translateService.instant('other_key')
        );
      }
      if (this.cashFlow.id) this.loadData();
    });
  }

  async hasFormJSON(val) {
    if (val?.formulaire_requirement) {
      const strig = val.formulaire_requirement;
      strig.replace(/\s+/g, '').trim();

      const first_parse = JSON.parse(val.formulaire_requirement);

      if (typeof first_parse === 'object')
        this.current_required_form_json = first_parse;
      else this.current_required_form_json = JSON.parse(first_parse);

      this.labels = this.current_required_form_json.labels;
    }
  }

  async loadData() {
    if (this.cashFlow.cashflow_expenses_categorie) {
      this.expenses.forEach((mainCategories) => {
        if (
          mainCategories.id === this.cashFlow.cashflow_expenses_categorie.id
        ) {
          this.categories = mainCategories.cash_flow_categories;
          this.firstTiersExpenses(mainCategories);
        } else if (
          Array.isArray(mainCategories.cashflow_expenses_sub_category)
        ) {
          mainCategories.cashflow_expenses_sub_category.forEach(
            (subCategorie) => {
              if (
                subCategorie.id === this.cashFlow.cashflow_expenses_categorie.id
              ) {
                this.categories = mainCategories.cash_flow_categories;
                this.firstTiersExpenses(mainCategories);
                this.midTiersExpenses(subCategorie);
              } else if (
                Array.isArray(subCategorie.cashflow_expenses_sub_category)
              ) {
                subCategorie.cashflow_expenses_sub_category.forEach(
                  (subSubCategorie) => {
                    if (
                      subSubCategorie.id ===
                      this.cashFlow.cashflow_expenses_categorie.id
                    ) {
                      this.categories = mainCategories.cash_flow_categories;
                      this.selected_sub_sub_expenses = subSubCategorie;
                      this.firstTiersExpenses(mainCategories);
                      this.midTiersExpenses(subCategorie);
                      this.lastExpensesChange(subSubCategorie);
                    }
                  }
                );
              }
            }
          );
        }
      });
    }
    if (this.categories) {
      this.categories.forEach((mainCategorie) => {
        if (mainCategorie.id === this.cashFlow.cashflow_categories.id) {
          this.selected_category = mainCategorie;
        } else if (Array.isArray(mainCategorie.cash_flow_sub_category)) {
          mainCategorie.cash_flow_sub_category.forEach((subCategorie) => {
            if (subCategorie.id === this.cashFlow.cashflow_categories.id) {
              this.selected_category = mainCategorie;
              this.selected_sub_category = subCategorie;
            } else if (Array.isArray(subCategorie.cash_flow_sub_category)) {
              subCategorie.cash_flow_sub_category.forEach((element) => {
                if (element.id === this.cashFlow?.cashflow_categories.id) {
                  this.selected_category = mainCategorie;
                  this.selected_sub_category = subCategorie;
                  this.selected_sub_sub_category = element;
                }
              });
            }
          });
        }
      });
    }
  }

  firstTiersExpenses(valu) {
    this.selected_expenses = valu;
    if (!this.cashFlow.id) {
      this.selected_sub_expenses = null;
      this.selected_sub_sub_expenses = null;
      this.current_required_form_json = null;
    }
    if (!this.isArray(valu)) {
      this.cashFlow.cash_flow_expense_id = valu.id;
      this.selected_expenses.cash_flow_expenses = pushOtrosToEnd(
        this.selected_expenses.cash_flow_categories,
        this.translateService.instant('other_key')
      );

      this.cashflowCategoriesChange.emit(this.cashFlow);
    }
    this.checkCategories(valu);
    this.hasFormJSON(valu);
  }

  midTiersExpenses(valu) {
    this.current_required_form_json = null;
    this.cashFlow.cash_flow_expense_id = valu.id;
    this.selected_sub_expenses = valu;
    this.cashflowCategoriesChange.emit(this.cashFlow);
    this.hasFormJSON(valu);
  }
  lastExpensesChange(valu) {
    this.current_required_form_json = null;
    this.cashFlow.cash_flow_expense_id = valu.id;
    this.selected_sub_sub_expenses = valu;
    this.cashflowCategoriesChange.emit(this.cashFlow);
    this.hasFormJSON(valu);
  }

  isArray(item) {
    return (
      (Array.isArray(item?.cash_flow_sub_category) &&
        item?.cash_flow_sub_category?.length > 0) ||
      (Array.isArray(item?.cash_flow_expenses) &&
        item?.cash_flow_expenses?.length > 0) ||
      (Array.isArray(item?.cashflow_expenses_sub_category) &&
        item?.cashflow_expenses_sub_category.length > 0)
    );
  }

  checkCategories(expense) {
    if (
      Array.isArray(expense.cash_flow_categories) &&
      expense.cash_flow_categories.length > 0
    ) {
      this.categories = orderAlphabaticallyByAttribute(
        this.categories,
        'title',
        this.lang
      );
      this.categories = pushOtrosToEnd(
        expense.cash_flow_categories,
        this.translateService.instant('other_key')
      );
      if (this.cashFlow.cashflow_categories) {
        const categ = expense.cash_flow_categories.find(
          (element) => element.id === this.cashFlow.cashflow_categories.id
        );
        if (categ) this.onChangeCategory(categ);
      }
    } else this.categories = null;
  }

  onChangeCategory(val) {
    this.cashFlow.cash_flow_categorie_id = val.id;
    this.cashFlow.cashflow_categories = val;

    this.selected_category = val;
    this.cashflowCategoriesChange.emit(this.cashFlow);
  }

  onChangeSubCategory(val) {
    this.cashFlow.cash_flow_categorie_id = val.id;
    this.cashFlow.cashflow_categories = val;

    this.selected_sub_category = val;
    this.cashflowCategoriesChange.emit(this.cashFlow);
  }
  onChangeSubSubCategory(val) {
    this.cashFlow.cash_flow_categorie_id = val.id;
    this.cashFlow.cashflow_categories = val;

    this.selected_sub_sub_category = val;
    this.cashflowCategoriesChange.emit(this.cashFlow);
  }

  compareWithFn(e, e2) {
    return e && e2 ? e?.id === e2?.id : e === e2;
  }

  cashFlowMoneyChange(event) {
    this.cashFlow = event;
    this.cashflowCategoriesChange.emit(this.cashFlow);
  }
  pagoPerCantitadCoechadaFormtrigger(event) {
    this.pago_per_cantitad_coeschada = event;
    if (event === 1) {
      this.cashFlow.amount = 0;
      this.cashflowCategoriesChange.emit(this.cashFlow);
    }
  }
  operationUpdate(event) {
    this.cashFlow = event;
    this.cashflowCategoriesChange.emit(this.cashFlow);
  }
}
