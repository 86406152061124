import { Component, Input, OnInit } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { DeleteAccountComponent } from 'src/app/components/delete-account/delete-account.component';
import { FamilyAccessComponent } from 'src/app/components/family-access/family-access.component';
import { PersonalDataComponent } from 'src/app/components/personal-data/personal-data.component';
import { ProfileInfoComponent } from 'src/app/components/personal-data/profile-info/profile-info.component';
import { CoffeeSalesComponent } from 'src/app/modules/coffee-sales/coffee-sales.component';
import { AnalysisWorkforceComponent } from 'src/app/modules/dashboard/cashflow/analysis-workforce/analysis-workforce.component';
import { FamilyViewTwoComponent } from 'src/app/modules/family/family-view-two/family-view-two.component';
import { FarmAddressDetailsComponent } from 'src/app/modules/farm/farm-details/farm-address-details/farm-address-details.component';
import { LotsComponent } from 'src/app/modules/farm/lots-components/lots/lots.component';
import { PreProductSelectorComponent } from 'src/app/modules/farm/others-agricol-product-components/pre-product-selector/pre-product-selector.component';
import { PageCountriesComponent } from 'src/app/modules/page-countries/page-countries.component';
import { PartnerMainViewComponent } from 'src/app/modules/partner/partner-main-view/partner-main-view.component';
import { ProgramsComponent } from 'src/app/modules/programs/programs.component';
import { TeamFamilyComponent } from 'src/app/modules/team/team-family/team-family.component';
import { LicenseAgreementComponent } from 'src/app/widgets/license-agreement/license-agreement.component';
import { LanguageComponent } from '../../widgets/language/language.component';
import { ProductAnimalInterfaceComponent } from '../../widgets/product-animal-interface/product-animal-interface.component';
import { AccordionQuestionComponent } from 'src/app/widgets/accordion-question/accordion-question.component';
import { QuestionAnswersComponent } from 'src/app/modules/dashboard/question-answers/question-answers.component';
import { CalculetteComponentComponent } from 'src/app/modules/dashboard/calculette-component/calculette-component.component';
import { TermesConditionsComponent } from 'src/app/modules/termes-conditions/termes-conditions.component';
import { TeamMainComponent } from 'src/app/modules/team/team-main.component';
import { QuestionInterfaceTeamComponent } from 'src/app/modules/team/team-interface-team/team-interface-team.component';
import { TeamInterfaceLiteComponent } from 'src/app/modules/team/team-interface-lite/team-interface-lite.component';
import { lastValueFrom, take } from 'rxjs';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss'],
})
export class MenuListComponent implements OnInit {
  constructor() {}
  @Input() menu_list;
  @Input() title;
  @Input() info;
  @Input() tab;
  @Input() has_crop;
  @Input() nav: IonNav;

  rootParams;
  
  async ngOnInit() {
  }

  async navigateTo(tab) {
    this.rootParams = {
      nav: this.nav,
      sub_tab: true,
      title: tab.key,
      tab: this.tab,
      info_label: tab.info_key,
      config: tab?.config,
    };
    switch (tab.component) {
      case 'PersonalDataComponent':
        this.nav.setRoot(PersonalDataComponent, this.rootParams);
        break;
      case 'ProfileInfoComponent':
        this.nav.setRoot(ProfileInfoComponent, this.rootParams);
        break;
      case 'FamilyViewTwoComponent':
        this.nav.setRoot(FamilyViewTwoComponent, this.rootParams);
        break;
      case 'PageCountriesComponent':
        this.nav.setRoot(LanguageComponent, this.rootParams);
        break;
      case 'FamilyAccessComponent':
        this.nav.setRoot(FamilyAccessComponent, this.rootParams);
        break;
      case 'DeleteAccountComponent':
        this.nav.setRoot(DeleteAccountComponent, this.rootParams);
        break;
      case 'TermesConditionsComponent':
        this.nav.setRoot(TermesConditionsComponent, this.rootParams);
        break;
      case 'FarmAddressDetailsComponent':
        this.nav.setRoot(FarmAddressDetailsComponent, this.rootParams);
        break;
      case 'ProductAnimalInterfaceComponent':
        this.nav.setRoot(ProductAnimalInterfaceComponent, this.rootParams);
        break;
      case 'LotsComponent':
        this.nav.setRoot(LotsComponent, this.rootParams);
        break;
        case 'TeamFamilyComponent':
          this.nav.setRoot(TeamFamilyComponent, this.rootParams);
          break;
          case 'TeamInterfaceLiteComponent':
            this.nav.setRoot(TeamInterfaceLiteComponent, this.rootParams);
            break;
      case 'CoffeeSalesComponent':
        this.nav.setRoot(CoffeeSalesComponent, this.rootParams);
        break;
      case 'PartnerMainViewComponent':
        this.nav.setRoot(PartnerMainViewComponent, this.rootParams);
        break;
      case 'ProgramsComponent':
        this.nav.setRoot(ProgramsComponent, this.rootParams);
        break;
      case 'QuestionAnswersComponent':
        this.nav.setRoot(QuestionAnswersComponent,{
          rootParams:this.rootParams
        });
        break;
      case 'CalculetteComponentComponent':
        this.nav.setRoot(CalculetteComponentComponent,{
          rootParams:this.rootParams
        });
        break;
    }
  }
}
