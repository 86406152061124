import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CashflowAPI } from 'src/app/apis/cashflow.service';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  ANIMALES_ACCOUNTING_ENTRY_CATEGORY,
  CAFE_ACCOUNTING_ENTRY_CATEGORY,
  OTROS_ACCOUNTING_ENTRY_CATEGORY,
  PERSONAL_ACCOUNTING_ENTRY_CATEGORY,
} from 'src/app/utils/consts';
export const SortBy = ['activity', 'persons', 'product'];

export interface FilterChoice {
  sort_by: string;
  start_date: string;
  end_date: string;
  monthly_yearly: string;
}
interface FormConfig {
  show_person: boolean;
  show_activity: boolean;
  show_product: boolean;
  show_animal: boolean;
  show_cashflow_categories: boolean;
}
@Component({
  selector: 'app-analyses-filter',
  templateUrl: './analyses-filter.component.html',
  styleUrls: ['./analyses-filter.component.scss'],
})
export class AnalysesFilterComponent implements OnInit {
  @Input() accountingEntryCategory;
  @Input() title = '';
  @Input() is_income;
  filter: FilterChoice = {
    sort_by: 'cashflowcategories',
    start_date: new Date(new Date().getFullYear(), 0, 1).toISOString(),
    end_date: new Date(new Date().getFullYear(), 11, 31).toISOString(),
    monthly_yearly: 'm-Y',
  };
  formConfig: FormConfig;
  activities;
  activity_ids = [];
  constructor(
    private modalController: ModalController,
    private cashflowAPI: CashflowAPI,
    public translateService: TranslateService
  ) {}

  ngOnInit() {
    this.cashflowAPI
      .getActivities(CAFE_ACCOUNTING_ENTRY_CATEGORY)
      .subscribe((value: any) => {
        this.activities = value.map((activity) => {
          activity.is_checked = false;
          activity.check_all_subs = false;
          activity.sub_activities = activity.sub_activities.map((element) => {
            element.is_checked = activity.check_all_subs;
            if (element.sub_activities) {
              element.sub_activities.map((val) => {
                val.is_checked = activity.is_checked;
                return val;
              });
            }
            return element;
          });

          return activity;
        });
      });
    this.formConfig = {
      show_activity: false,
      show_animal: false,
      show_cashflow_categories: true,
      show_person: false,
      show_product: false,
    };
    if (
      this.accountingEntryCategory.id == CAFE_ACCOUNTING_ENTRY_CATEGORY &&
      !this.is_income
    ) {
      this.formConfig.show_activity = true;
      this.formConfig.show_cashflow_categories = true;
    } else if (
      this.accountingEntryCategory.id == OTROS_ACCOUNTING_ENTRY_CATEGORY
    ) {
      this.formConfig.show_product = true;
    } else if (
      this.accountingEntryCategory.id == ANIMALES_ACCOUNTING_ENTRY_CATEGORY
    )
      this.formConfig.show_animal = true;
    else if (
      this.accountingEntryCategory.id == PERSONAL_ACCOUNTING_ENTRY_CATEGORY
    )
      this.formConfig.show_person = true;
  }

  async expand(activity) {
    activity.is_expanded = !activity.is_expanded;
  }
  inCheckParent(input, itemChecked) {
    input.is_checked = !itemChecked;
    if (input.activity_id && !input.is_checked) {
      this.activities = this.activities.map((act) => {
        if (act?.id == input?.activity_id) {
          act.is_checked = false;
        }
        return act;
      });
    }
  }
  checkAllSubsChange(activity) {
    activity.sub_activities = activity.sub_activities.map((element) => {
      element.is_checked = !activity.is_checked;
      if (element.sub_activities) {
        element.sub_activities = element.sub_activities.map((val) => {
          val.is_checked = element.is_checked;

          return val;
        });
      }
      if (activity.activity_id && activity.is_checked) {
        this.activities = this.activities.map((act) => {
          if (act?.id == activity?.activity_id) {
            act.is_checked = false;
          }
          return act;
        });
      } else {
      }

      return element;
    });
  }

  Check(value) {
    this.filter.sort_by = value;
  }
  cancel() {
    this.modalController.dismiss(
      {
        filter: null,
      },
      '',
      'AnalysesFilterComponent'
    );
  }
  confirm() {
    this.activity_ids = [];
    let activities = this.activities;
    activities.forEach((activity) => {
      if (activity?.is_checked) {
        this.activity_ids.push(activity.id);
      }
      activity?.sub_activities.forEach((element) => {
        if (element?.is_checked) {
          this.activity_ids.push(element.id);
        }
        if (element?.sub_activities) {
          element?.sub_activities.forEach((val) => {
            if (val?.is_checked) {
              this.activity_ids.push(val.id);
            }
          });
        }
      });
    });

    this.modalController.dismiss(
      {
        filter: this.filter,
        activity_ids: this.activity_ids,
      },
      '',
      'AnalysesFilterComponent'
    );
  }
}
