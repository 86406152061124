import {
  Directive,
  HostListener,
  ElementRef,
  AfterViewInit,
  Input,
} from '@angular/core';
import { FormatNumber } from './number_formatter';

@Directive({
  selector: '[currencyInput]',
  host: {
    '(input)': 'handleInput($event.target.value)',
  },
})
export class CurrencyInputDirective implements AfterViewInit {
  inputElement: HTMLInputElement = null;
  @Input('currencyInput') type: any;

  constructor(private el: ElementRef, private currencyPipe: FormatNumber) {}

  ngAfterViewInit() {
    let element = this.el.nativeElement;

    if (element.tagName === 'INPUT') {
      this.inputElement = element;
    } else {
      this.inputElement = element.getElementsByTagName('input')[0];
    }
  }

  handleInput(value) {}
  @HostListener('ionChange', ['$event.target.value'])
  onChange(value) {
    if (value =="0,00") {
      this.el.nativeElement.value = 0;
    }

  }

  @HostListener('ionBlur', ['$event.target.value'])
  onBlur(value) {
        var number_int = value.toString().replace(/\s/g, '')
        if (value =="0,00") {
          this.el.nativeElement.value = 0;
        }
        if (number_int != 0 && !isNaN(number_int) && this.type=='input') {
        this.el.nativeElement.value = this.currencyPipe.transform(number_int);
      } else if (isNaN(number_int)) {
        this.el.nativeElement.value = 0;
      }

 
  }
}
