import { LicensePolicyAPI } from './../../../apis/license_policy.service';
import { Component, NgModule, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-license-agreement',
  templateUrl: './license-agreement.component.html',
  styleUrls: ['./license-agreement.component.scss'],
})
export class LicenseAgreementComponent implements OnInit {
  constructor(
    private licenseAgreementService: LicensePolicyAPI,
    private translateService: TranslateService
  ) {}
  licenseText;
  lang;
  ngOnInit() {
    this.lang = this.translateService.currentLang;
    this.licenseAgreementService
      .getLicenseAgreement(this.lang)
      .subscribe((value) => {
        this.licenseText = value.license;
      });
  }
}
