import { selectAllUnits } from './states-management/reducers/units.reducer';
import { Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import units from '../config/unit.json';
import { dev } from '../config/offlines_keys.json';
import { CashFlow } from '../models/cash-flow/cashFlow';
import { Storage } from '@capacitor/storage';
import {
  orderAlphabaticallyTitles,
  orderAlphabaticallyUnit,
} from './array-filtrer';
import { ANIMAL_IDS, LECHE_ID, QUESO_IDS } from './consts';
import { Unit } from '../models/Unit';
import { Store } from '@ngrx/store';
import { loadUnit } from './states-management/actions/units-action';
import { lastValueFrom, take } from 'rxjs';
import Owner from '../models/owner.model';
import { cloneDeep } from 'lodash';

export const convertToMetricSystem = (unit, value, toFixed = 2) => {
  return (unit?.equivalent_in_metric * value).toFixed(toFixed);
};

export const convetToOtherUnit = (unit, value, toFixed = 2) => {
  return (value / unit?.equivalent_in_metric).toFixed(toFixed);
};

export const getRealGoodTransactionValues = (
  destUnit,
  fromUnit,
  value,
  unitsList = units.all,
  toFixed = 2
) => {
  if (destUnit !== fromUnit) {
    const fromUnitObject = unitsList.find((element) => {
      if (element && element.id) return element?.id === fromUnit;
      else return undefined;
    });
    const originalUnit = unitsList.find((elment) => {
      if (elment && elment.id) return elment?.id === destUnit;
      else return undefined;
    });
    if (fromUnitObject && originalUnit) {
      const metricSystemCashflowValue = convertToMetricSystem(
        fromUnitObject,
        value,
        toFixed
      );
      const originalUnitCashflowValue = convetToOtherUnit(
        originalUnit,
        metricSystemCashflowValue,
        toFixed
      );
      return originalUnitCashflowValue;
    }
  }
  return value;
};

export const getQtyUpdateInsertion = (cashflow: CashFlow) => {
  var realGood = parseFloat(
    getRealGoodTransactionValues(
      cashflow.modelBeingUpdated?.unit,
      cashflow.unity,
      cashflow.quantity
    )
  );
  var realInitialValue = 0;
  realInitialValue = cashflow.id
    ? parseFloat(
        getRealGoodTransactionValues(
          cashflow.modelBeingUpdated?.unit,
          cashflow.initial_unit ? cashflow.initial_unit : cashflow.unity,
          cashflow.initial_value ? cashflow.initial_value : 0
        )
      )
    : 0;

  let qty_update = 0;
  if (cashflow.quantity && cashflow.unity)
    if (cashflow.is_income || cashflow.is_a_loss) {
      qty_update = cashflow.modelBeingUpdated?.qty
        ? cashflow.modelBeingUpdated?.qty - realGood + realInitialValue
        : 0 - realGood + realInitialValue;
    } else {
      qty_update = cashflow.modelBeingUpdated?.qty
        ? cashflow.modelBeingUpdated?.qty + realGood - realInitialValue
        : realGood - realInitialValue;
    }
  else return cashflow.modelBeingUpdated?.qty;
  return qty_update;
};

export const getQtyUpdateDelete = (cashflow: CashFlow) => {
  var realGood = parseFloat(
    getRealGoodTransactionValues(
      cashflow.modelBeingUpdated?.unit,
      cashflow.unity,
      cashflow.quantity
    )
  );

  let qty_update = 0;
  if (cashflow.quantity && cashflow.unity)
    if (cashflow.is_income || cashflow.is_a_loss) {
      qty_update = cashflow.modelBeingUpdated?.qty
        ? cashflow.modelBeingUpdated?.qty + realGood
        : realGood;
    } else {
      qty_update = cashflow.modelBeingUpdated?.qty
        ? cashflow.modelBeingUpdated?.qty - realGood
        : realGood;
    }
  else return cashflow.modelBeingUpdated?.qty;
  return qty_update;
};

@Pipe({ name: 'getRealGoodTransactionValues', pure: false })
export class GetRealGoodTransactionValues {
  unitSystem;
  constructor(private store: Store) {
    this.store.select(selectAllUnits).subscribe((value) => {
      this.unitSystem = value;
    });
  }
  transform(unit_value, destination_unit, outgoing_unit = 5, toFixed = 2) {
    return getRealGoodTransactionValues(
      destination_unit,
      outgoing_unit,
      unit_value,
      this.unitSystem,
      toFixed
    );
  }
}

@Pipe({ name: 'convertValueToMetric', pure: false })
export class ConvertValueToMetric {
  transform(value: number, outgoing_unit: number) {
    const unit = units.all.find((element) => element.id === outgoing_unit);

    return unit.equivalent_in_metric * value;
  }
}

export const getDisplayableUnit = (unit) => {
  return units.all.find((element) => element.value === unit);
};

@Pipe({ name: 'getDisplayableUnit', pure: false })
export class GetDisplayableUnit {
  unitSystem;
  constructor(
    private langaeTranslateService: TranslateService,
    private store: Store
  ) {
    this.store.select(selectAllUnits).subscribe((value) => {
      this.unitSystem = value;
    });
  }
  transform(unit_value: any, units: Array<Unit> = []) {
    const u = this.unitSystem.find((element) => element.id == unit_value);
    if (u && u.value) return this.langaeTranslateService.instant(u.value);
    else return '';
  }
}
@Pipe({ name: 'getUnitKey', pure: false })
export class GetUnitKey {
  unitSystem;
  constructor(private store: Store) {
    this.store.select(selectAllUnits).subscribe((value) => {
      this.unitSystem = value;
    });
  }
  transform(unit_value: any, units: Array<Unit> = []) {
    const u = this.unitSystem.find((element) => element.id == unit_value);
    if (u && u.value) return u.value;
    else return '';
  }
}
@Pipe({ name: 'getUnitList', pure: true })
export class GetUnitListByProductID {
  unitSystem;
  owner;
  constructor(
    private store: Store<{ owner: Owner }>,
    private langageTranslateService: TranslateService
  ) {
    this.store.select(selectAllUnits).subscribe((value) => {
      this.unitSystem = cloneDeep(value);
      this.unitSystem.forEach((element) => {
        element[
          this.langageTranslateService.currentLang == 'es'
            ? 'title'
            : 'title_' + this.langageTranslateService.currentLang
        ] = this.langageTranslateService.instant(element.value);
      });
    });
   this.owner = lastValueFrom(this.store.select('owner').pipe(take(1)))
  }
  async transform(product_id, is_animal, unit_type = null) {
    if (!unit_type) {
      if (!is_animal)
        return this.nonAnimalProduct(this.owner?.countrie, product_id);
      else return this.animalProduct(this.owner?.countrie, product_id);
    } else {
      return this.filterByUnitType(this.owner?.countrie, unit_type);
    }
  }

  nonAnimalProduct(country, product_id) {
    if (
      [16, 17, 18].includes(product_id) ||
      (product_id == 1 && product_id !== 9)
    ) {
       return orderAlphabaticallyTitles(
        this.unitSystem.filter((element) => element.type === 'p'),
        this.langageTranslateService.currentLang
      );
    } else if (![16, 17, 18, 1].includes(product_id) && product_id !== 9) {
      return orderAlphabaticallyTitles(
        this.unitSystem.filter(
          (element) => element.type === 'p' || element.type === 'n'
        ),
        this.langageTranslateService.currentLang
      );
    } else if (product_id === 9)
      return orderAlphabaticallyTitles(
        this.unitSystem,
        this.langageTranslateService.currentLang
      );
    // } else if (country.id === 2) {
    //   //Colombia this.unitSystem
    //   if ([16, 17, 18, 1].includes(product_id))
    //     return orderAlphabaticallyUnit(
    //       this.unitSystem.filter((element) => element.type === 'p'),
    //       this.langageTranslateService.currentLang
    //     );
    //   else if (![16, 17, 18, 1].includes(product_id) || [11].includes(product_id) && product_id !== 9)
    //     return orderAlphabaticallyUnit(
    //       this.unitSystem.filter(
    //         (element) => element.type === 'p' || element.type === 'n'
    //       ),
    //       this.langageTranslateService.currentLang
    //     );
    //   else if (product_id === 9)
    //     return orderAlphabaticallyUnit(
    //       this.unitSystem,
    //       this.langageTranslateService.currentLang
    //     );
    // }
  }
  animalProduct(country, product_id) {
     // if (country.id === 1) {
    //Costa rica this.unitSystem
    if (QUESO_IDS.includes(product_id))
      return orderAlphabaticallyUnit(
        this.unitSystem.filter(
          (element) => element.type === 'p' || element.type === 'n'
        ),
        this.langageTranslateService.currentLang
      );
    else if (LECHE_ID.includes(product_id))
      return orderAlphabaticallyUnit(
        this.unitSystem.filter((element) => element.type === 'v'),
        this.langageTranslateService.currentLang
      );
    else if (ANIMAL_IDS.includes(product_id))
      return orderAlphabaticallyUnit(
        this.unitSystem.filter((element) => element.type === 'n'),
        this.langageTranslateService.currentLang
      );
    else if (product_id == 57)
      return orderAlphabaticallyUnit(
        this.unitSystem,
        this.langageTranslateService.currentLang
      );
    else
      return orderAlphabaticallyUnit(
        this.unitSystem.filter((element) => element.type === 'p'),
        this.langageTranslateService.currentLang
      );
    // } else if (country.id === 2) {
    //   //Colombia this.unitSystem
    //   if (QUESO_IDS.includes(product_id))
    //     return orderAlphabaticallyUnit(
    //       this.unitSystem.filter(
    //         (element) => element.type === 'p' || element.type === 'n'
    //       ),
    //       this.langageTranslateService.currentLang
    //     );
    //   else if (LECHE_ID.includes(product_id))
    //     return orderAlphabaticallyUnit(
    //       this.unitSystem.filter((element) => element.type === 'v'),
    //       this.langageTranslateService.currentLang
    //     );
    //   else if (ANIMAL_IDS.includes(product_id))
    //     return orderAlphabaticallyUnit(
    //       this.unitSystem.filter((element) => element.type === 'n'),
    //       this.langageTranslateService.currentLang
    //     );
    //   else if (product_id == 57)
    //     return orderAlphabaticallyUnit(
    //       this.unitSystem,
    //       this.langageTranslateService.currentLang
    //     );
    //   else
    //     return orderAlphabaticallyUnit(
    //       this.unitSystem.filter((element) => element.type === 'p'),
    //       this.langageTranslateService.currentLang
    //     );
    // }
  }

  filterByUnitType(country, type) {
     return orderAlphabaticallyUnit(
      this.unitSystem.filter((element) => element.type === type),
      this.langageTranslateService.currentLang
    );
  }
}
