import { take } from 'rxjs/operators';
import { selectAllPlots } from './../../../../utils/states-management/reducers/plot.reducer';
import { LotAPI } from './../../../../apis/lot.service';
import { MORPH_Lot } from '../../../../utils/consts';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { UserInfo } from 'src/app/apis/users-info.service';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Storage } from '@capacitor/storage';
import { Lot, USAGE_TYPE } from 'src/app/models/lots.model';
import { dev } from '../../../../config/offlines_keys.json';
import { ModalController, PopoverController } from '@ionic/angular';
import { LotsCreatorComponent } from '../lots-creator/lots-creator.component';
import { PriorDashboardComponent } from '../../../prior-dashboard/prior-dashboard.component';
import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import { showConfirmModal } from 'src/app/utils/pop_over_maker';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import {
  BUY_AGRICOLAS_LOT,
  BUY_OTRAS_USE_LOT,
  PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY,
} from 'src/app/utils/consts';
import { TranslateService } from '@ngx-translate/core';
import { UnifiedCashflowInputComponentComponent } from 'src/app/modules/cashflow/unified-cashflow-input-component/unified-cashflow-input-component.component';
import { Store } from '@ngrx/store';
import { loadPlots } from 'src/app/utils/states-management/actions/plots.action';
import { cloneDeep } from 'lodash';
import { Farm } from 'src/app/models/farm/farm.model';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { lastValueFrom } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { loadFarm } from 'src/app/utils/states-management/actions/farm.action';
import { environment } from 'src/environments/environment';
import { LotSingleEntryComponent } from '../../widgets/lot-single-entry/lot-single-entry.component';

@Component({
  selector: 'app-lot-manager',
  templateUrl: './lot-manager.component.html',
  styleUrls: ['./lot-manager.component.scss'],
  providers: [LotSingleEntryComponent],

})

export class LotManagerComponent implements OnInit, OnChanges {
  @Input() used_for;
  isAddView = false;
  total_area_current = 0;
  tota_perimetes = 0;
  @Input() isDashboardView;
  @Input() nav;
  @Input() rootStack;
  @Input() is_attached_to_lot_component = false;
  @Output() lotAddedFromDashboard: EventEmitter<any> = new EventEmitter();
  @Output() canGoNext: EventEmitter<boolean> = new EventEmitter();
  lot_list: any = [];
  add_button;
  modal: any;
  USAGE_TYPE = USAGE_TYPE;
  add_header;
  lang;
  farm:Farm
  base_url = environment.appRoot;
  // @ViewChild(LotSingleEntryComponent) lotSingleEntryComponent: LotSingleEntryComponent;

  constructor(
    private modalController: ModalController,
    private popover: PopoverController,
    private userInfo: UserInfo,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private LotAPI: LotAPI,
    private store: Store<{ farm: Farm,plots: any }>,
    public lotSingleEntryComponent: LotSingleEntryComponent

  ) {}

  updateLot(lot){
    this.lotSingleEntryComponent.selectedLot(lot,this.used_for)
  }
  deleteLot(lot){
    this.lotSingleEntryComponent.delete(lot)
  }
  async ngOnInit() {
    this.store.dispatch(loadPlots())

    this.farm =  await lastValueFrom(this.store.select(getFarm).pipe(take(1)))

    this.store.select(selectAllPlots).subscribe((value) => {
      this.total_area_current = 0;
      this.tota_perimetes = 0;
      var lots = cloneDeep(value)

      if (Array.isArray(lots)) {
        
        this.lot_list = lots.filter((vlue:any) => {
          if (vlue.type === this.used_for) {
            if (vlue.superficie) {
              this.total_area_current += parseFloat(vlue.superficie.toString());
              this.tota_perimetes += parseFloat(vlue.perimeters);
            }

            vlue.is_expanded = true;
            return { is_expanded: true, vlue };
          }
        });
      }
    });
    // await this.reload();
    this.translate();
    this.translateService.onLangChange.subscribe((lang) => {
      this.translate();
    });
  }
  async translate() {
    if (this.used_for === USAGE_TYPE.agricol) {
      this.add_button = await this.translateService
        .instant('titles.lot_creator_title_1')
      this.add_header = await this.translateService
        .instant('titles.lot_creator_title_map')
    } else {
      this.add_button = await this.translateService
        .instant('titles.lot_creator_title_4')

      this.add_header = await this.translateService
        .instant('titles.lot_creator_title_4')
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.lang = await this.userInfo.getSelectedLangage();
  }

  async reload() {

  }

  async add() {
    let componentProps = {
      used_for: this.used_for,
      lot: new Lot(),
      modalMain: this.modalController,
      title: this.add_button,
      title_header: this.add_header,
    };
    let modal = await this.toastUtils.modalBuilder(
      LotsCreatorComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper'],
      true,
      '2'
    );
    await modal.onWillDismiss().then((value) =>{ 
      this.lotAdded(value)
      //TODO to delete

    });
  }

  async lotAdded(value) {
    await this.reload();
    if (value?.data?.is_agricol) {
      const lot = value?.data?.lot;
      var has_crop = false;
      if (Array.isArray(lot?.productions)) {
        lot.productions.forEach((element) => {
          if (
            element.product_id === 1 ||
            element.name?.includes('Café Cereza') ||
            element.name?.includes('Cherry')
          )
            has_crop = true;
        });
      }
      if (has_crop)
        await this.showPopOver(
          await this.translateService
            .get('infobull.infobulle_5')
            .toPromise()
            .then((value) => value)
        );
    }

    if (this.isDashboardView && value?.data?.lot) {
      this.lotAddedFromDashboard.emit(value.data.lot);
    }
  }

  async showPopOver(message) {
    let componentProps = { key1: message };

    await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
  }

  navBack() {
    this.nav.setRoot(this.rootStack, {
      nav: this.nav,
      rootStack: PriorDashboardComponent,
    });
  }
  async cashflowInsertion(): Promise<Boolean> {
    const modal = await showConfirmModal(
      this.popover,
      await this.translateService
        .get('add_cashflow_entry')
        .toPromise()
        .then((value) => value),
      null,
      'Sí',
      'No'
    );
    const { data } = await modal.onWillDismiss();
    return data?.confirmed;
  }

  async initBuyCashflow(categories, id): Promise<CashFlow> {
    const cashflow = new CashFlow();
    cashflow.morphable_id = id;
    cashflow.morphable_type = MORPH_Lot;
    cashflow.cash_flow_categorie_id =
      this.used_for === USAGE_TYPE.agricol
        ? BUY_AGRICOLAS_LOT
        : BUY_OTRAS_USE_LOT;
    cashflow.accounting_entries_categories =
      PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY;
    categories.forEach((element) => {
      if (Array.isArray(element.cash_flow_sub_category))
        element.cash_flow_sub_category.forEach((ele) => {
          if (ele.id === cashflow.cash_flow_categorie_id)
            cashflow.cashflow_categories = ele;
        });
    });
    cashflow.accounting_entries_categories =
      PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY;
    cashflow.is_income = 0;
    cashflow.quantity = 1;
    // cashflow.unity = 'unit';
    cashflow.payment_methods = 'cash';
    let country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    cashflow.currency = country.currency
    cashflow.farm_id = this.farm.id
    cashflow.date = new Date().toISOString();
    return cashflow;
  }

  async showRelatedPopover(id) {
    const cashFlowCategFiltred = await Storage.get({
      key: dev.CASHFLOW_CATEGORIES,
    }).then((value) =>
      JSON.parse(value.value).find(
        (element) => element.id == PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY
      )
    );

    let componentProps = {
      cashFlow: await this.initBuyCashflow(cashFlowCategFiltred, id),
      accountingEntryCategories: cashFlowCategFiltred,
      modalController: this.modalController,
      no_post_submit_action: true,
    };
    let modal = await this.toastUtils.modalBuilder(
      UnifiedCashflowInputComponentComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper'],
      true,
      '2'
    );

    await modal.onWillDismiss();
  }

  eventReciever(boolean) {
    this.canGoNext.emit(boolean);
  }
}
