import { dev } from 'src/app/config/offlines_keys.json';
import { cloneDeep } from 'lodash';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { LotAPI } from 'src/app/apis/lot.service';
import { Lot } from 'src/app/models/lots.model';
import { UpdateSync } from '../actions/offline.action';
import {
  addPlot,
  addPlotSuccess,
  deletePlot,
  deletePlotSuccess,
  loadPlots,
  loadPlotsSuccess,
  updatePlot,
  updatePlotSuccess,
} from '../actions/plots.action';
import { nextOfflineIndex } from '../reducers/offline.reducer';
import { Storage } from '@capacitor/storage';

@Injectable()
export class PlotsEffect {
  adapter: EntityAdapter<Lot> = createEntityAdapter<Lot>();

  loadPlots$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadPlots),
      switchMap((action) => {
        return this.lotAPI.getLotByFarm();
      }),
      // map((playLoad: any) =>
      //   playLoad.map((element) => {
      //     if (element.birth_date)
      //       element.age = calculateAge(new Date(element.birth_date));
      //     else element.age = 0;
      //     return element;
      //   })
      // ),
      map((playLoad: any) => loadPlotsSuccess({ plots: playLoad }))
    )
  );

  deletePlot$ = createEffect(() =>
    this.actions.pipe(
      ofType(deletePlot),

      switchMap((action) => {
        return this.lotAPI.deleteLot(action.lot.id).pipe(
          map((result) => {
            this.removeFromLocalStorage(action.lot);

            return { result, lot: action.lot };
          })
        );
      }),
      map(({ result, lot }) => {
        return deletePlotSuccess({ id: lot.id });
      })

      // catchError((err) => of(dataSyncWithServerError(err)))
    )
  );
  async removeFromLocalStorage(lot) {
    const lot_full_list = await Storage.get({
      key: dev.LOT_KEY,
    }).then((val) => JSON.parse(val.value));

    lot_full_list.forEach((element, index, array) => {
      if (element.id === lot.id) {
        lot_full_list.splice(index, 1);
      }
    });
    await Storage.set({
      key: dev.LOT_KEY,
      value: JSON.stringify(lot_full_list),
    });
    // this.relodNeeded.emit();
  }
  updatePlot$ = createEffect(() =>
    this.actions.pipe(
      ofType(updatePlot),
      switchMap((action) => {
        return this.lotAPI.updateLot({...action.data}).pipe(
          map((result) => {
            return { result };
          })
        );
      }),

      map(({ result }) => {
        return UpdateSync({
          data: result,
          offlineId: result.id,
          stateName: 'plots',
        });

      })
    )
  );

  addPlot$ = createEffect(() =>
    this.actions.pipe(
      ofType(addPlot),

      switchMap((action) => {
        return this.lotAPI.insertLot({...action.data});
      }),
      switchMap(async (result: Lot) => {
        return addPlotSuccess({
          data: result,
          offlineId: await this.store
            .select(nextOfflineIndex)
            .pipe(take(1))
            .toPromise(),
        });
      })
    )
  );
  addPlotSuccess$ = createEffect(() =>
    this.actions.pipe(
      ofType(addPlotSuccess),

      map((action: any) => {
        // this.store.dispatch(
        //   UpdateSync({
        //     data: action.data,
        //     offlineId: action.offlineId,
        //     stateName: 'plots',
        //   })
        // );
        return UpdateSync({
          data: action.data,
          offlineId: action.offlineId,
          stateName: 'plots',
        });

        // console.log(action)
        // if (action?.props?.should_add_employee) {
        //   let member = action.data;
        //   member.farm_id = action?.props.farm_id;
        //   member.morphable_type = 'Plot';
        //   member.morphable_id = member.id;
        //   return this.employeeAPI.saveEmployee(member);
        // } else {
        //   // return of(loadPlot());
        //   return ;
        // }
      })
      // switchMap(({ result, props }) => {
      //   return of(loadPlot());
      // })
    )
  );
  private state$: any;

  constructor(
    private lotAPI: LotAPI,
    private actions: Actions,
    // public translateService: TranslateService,
    private store: Store<{ plots: any }>
  ) {
    this.state$ = this.store.select('plots').pipe(
      filter(Boolean), // Ignore the initial null state
      shareReplay(1) // Share the state across multiple subscribers
    );
  }
}
