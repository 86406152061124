import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TransactionTypeButtonsComponent } from './widgets/transaction-type-buttons/transaction-type-buttons.component';
import { FooterModule } from 'src/app/widgets/footer-component/header-footer.module';
import { PaidMethodQuantityCommentComponent } from './widgets/paid-method-quantity-comment/paid-method-quantity-comment.component';
import { MoneyOperationsSelectorComponent } from './widgets/money-operations-selector/money-operations-selector.component';
import { ManoDeObraWidgetComponent } from './widgets/mano-de-obra-widget/mano-de-obra-widget.component';
import { SalaryInputModalComponent } from './widgets/salary-input-modal/salary-input-modal.component';
import { ThirdModelSelectorComponent } from './widgets/third-model-selector/third-model-selector.component';
import { ExpensesSelectorComponent } from './widgets/expenses-selector/expenses-selector.component';
import { OperationViewComponent } from './widgets/operation-view/operation-view.component';
import { OperationDebtViewComponent } from './widgets/operation-debt-view/operation-debt-view.component';
import { OperationCautionViewComponent } from './widgets/operation-caution-view/operation-caution-view.component';
import { BankAccountRelatedSelectorComponent } from './widgets/bank-account-related-selector/bank-account-related-selector.component';
import { TransferMoneyReportingWidgetComponent } from './widgets/transfer-money-reporting-widget/transfer-money-reporting-widget.component';
import { OperationTrackersCashflowThridModelComponent } from './widgets/operation-trackers-cashflow-thrid-model/operation-trackers-cashflow-thrid-model.component';
import { GoodsRelatedToCashflowComponent } from './widgets/goods-related-to-cashflow/goods-related-to-cashflow.component';
import { TranslateModule } from '@ngx-translate/core';
import { UnifiedCashflowInputComponentComponent } from './unified-cashflow-input-component/unified-cashflow-input-component.component';
import { ActivitiesSelectorComponent } from './widgets/activities-selector/activities-selector.component';
import { EffectsModule } from '@ngrx/effects';
import { CashflowEffect } from 'src/app/utils/states-management/effects/cashflow.effect';
import { CashflowCategoriesCashflowExpensesSelectorsComponent } from './widgets/cashflow-categories-cashflow-expenses-selectors/cashflow-categories-cashflow-expenses-selectors.component';
import { CashflowExpensesSelectorsComponent } from './widgets/cashflow-expenses-selectors/cashflow-expenses-selectors.component';
import { UnitsEffects } from 'src/app/utils/states-management/effects/units.effect';
import { GetUnitKey, GetUnitListByProductID } from 'src/app/utils/unit-convert';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FooterModule,
    TranslateModule.forChild(),
    EffectsModule.forFeature([CashflowEffect,UnitsEffects]),
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  declarations: [
    ManoDeObraWidgetComponent,
    TransactionTypeButtonsComponent,
    PaidMethodQuantityCommentComponent,
    MoneyOperationsSelectorComponent,
    SalaryInputModalComponent,
    ThirdModelSelectorComponent,
    ExpensesSelectorComponent,
    OperationViewComponent,
    OperationDebtViewComponent,
    OperationCautionViewComponent,
    BankAccountRelatedSelectorComponent,
    TransferMoneyReportingWidgetComponent,
    OperationTrackersCashflowThridModelComponent,
    GoodsRelatedToCashflowComponent,
    UnifiedCashflowInputComponentComponent,
    ActivitiesSelectorComponent,
    CashflowCategoriesCashflowExpensesSelectorsComponent,
    CashflowExpensesSelectorsComponent,
    GetUnitKey,
  ],
  exports: [
    ManoDeObraWidgetComponent,
    TransactionTypeButtonsComponent,
    PaidMethodQuantityCommentComponent,
    MoneyOperationsSelectorComponent,
    SalaryInputModalComponent,
    ThirdModelSelectorComponent,
    ExpensesSelectorComponent,
    OperationViewComponent,
    OperationDebtViewComponent,
    OperationCautionViewComponent,
    BankAccountRelatedSelectorComponent,
    TransferMoneyReportingWidgetComponent,
    OperationTrackersCashflowThridModelComponent,
    GoodsRelatedToCashflowComponent,
    UnifiedCashflowInputComponentComponent,
    ActivitiesSelectorComponent,
    CashflowCategoriesCashflowExpensesSelectorsComponent,
    CashflowExpensesSelectorsComponent,
  ],
})
export class CashflowModule {}
