import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';

// if (environment.production) {
//   enableProdMode();
// }

if (navigator.serviceWorker) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('sw.js')
      .then((reg) => {
        console.log('Service worker registered:', reg);

  
      })
      .catch((err) => {
        console.error('Service worker registration failed:', err);
      });
  });
}

// Sentry.init({
//   dsn: 'https://2e1a749c2f95189ba4bc21d00fa77e6c@sentry.terrakodo.com/3',
//   integrations: [
//     // Registers and configures the Tracing integration,
//     // which automatically instruments your application to monitor its
//     // performance, including custom Angular routing instrumentation
//     Sentry.browserTracingIntegration(),
//     // Registers the Replay integration,
//     // which automatically captures Session Replays
//     Sentry.replayIntegration(),
//   ],

//   transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
//   transportOptions: {
//     dbName: 'sentry-offline',
//     storeName: 'queue',
//     maxQueueSize: 30,
//     flushAtStartup: false,
//   },

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,

//   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/+.\.fynka\.terrakodo\.com\/api/],

//   // Capture Replay for 10% of all sessions,
//   // plus for 100% of sessions with an error
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((val) => {
    defineCustomElements(window);
  });
