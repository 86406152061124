import { CashflowAPI } from 'src/app/apis/cashflow.service';
import { dataSyncWithServerError } from './../actions/owner.action';
import { loadFamilyMemberSuccess } from './../actions/family-members.action';
import { AccountingCategoriesAPI } from 'src/app/apis/accounting_categories.service';
import { getTotalsAccountingCategoriesSuccess, GET_TOTAL_ACCOUNTING_ENTIRES_LOADING, GET_TOTAL_ACCOUNTING_ENTIRES_SUCCEESS } from './../actions/accountings-entries.action';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { getTotalsAccountingCategories } from '../actions/accountings-entries.action';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { deleteCashflow, deleteCashflowSuccess } from '../actions/cashflow.action';

@Injectable()
export class AccountingEntriesEffect {
  constructor(
    private actions$: Actions,
      public accountingCategoriesAPI: AccountingCategoriesAPI,
      private cashflowAPI: CashflowAPI,

  //   public translateService: TranslateService,
  ) {}
  getTotalAccountingEntries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTotalsAccountingCategories),
      switchMap((action) => {
        return this.accountingCategoriesAPI.getTotalsAccountingCategories(action.startDate,action.endDate)?.pipe(
          map((val) => {
            return getTotalsAccountingCategoriesSuccess({
              TotalsAccountingCategories: val,
            });          }),
          catchError((err) => of(dataSyncWithServerError(err)))
        );
      }),
    )
  );



}
