import { RoutingConfig } from 'src/app/utils/routing-config';
import { UserInfo } from './../../apis/users-info.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/apis/login.service';
import { Router } from '@angular/router';
import { dev } from '../../config/offlines_keys.json';
import { Storage } from '@capacitor/storage';
import { ToastUtils } from '../../utils/toast_builder';
import { OwnerAPI } from 'src/app/apis/owners.service';
import { handleResponse } from 'src/app/utils/user_data_storage';
import { TranslateService } from '@ngx-translate/core';
import { SmsRetriever } from '@ionic-native/sms-retriever/ngx';
import { Store } from '@ngrx/store';
import Owner from 'src/app/models/owner.model';
import { getOwnerById } from 'src/app/utils/states-management/actions/owner.action';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { from, lastValueFrom, of } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { LOAD_APP_DATA, loadAppData } from 'src/app/utils/states-management/actions/offline.action';
import { loadFarm } from 'src/app/utils/states-management/actions/farm.action';
import { loadFamilyMember } from 'src/app/utils/states-management/actions/family-members.action';
import {
  loadAgriProduct,
  loadSelectedAgriProduct,
} from 'src/app/utils/states-management/actions/agri-product.action';
import {
  loadAnimal,
  loadSelectedAnimal,
} from 'src/app/utils/states-management/actions/animals.action';
import { LoadingController } from '@ionic/angular';
// declare var SMSReceive: any;

@Component({
  selector: 'app-sms-validation',
  templateUrl: './sms-validation.component.html',
  styleUrls: ['./sms-validation.component.scss'],
})
export class SmsValidationComponent implements OnInit {
  language: any;
  seconde: number = 59;
  interval;
  minutes = 1;
  showExpMessage = false;
  is_next_clicked = false;
  OTP: string = '';
  showOTPInput: boolean = false;
  OTPmessage: string =
    'An OTP is sent to your number. You should receive it in 15 s';
  loading = false;
  mobile_number = this._Activatedroute.snapshot.paramMap.get('mobile_number');
  prefix = this._Activatedroute.snapshot.paramMap.get('code');
  network = this._Activatedroute.snapshot.paramMap.get('network');
  code_input?: string;
  lang;
  smsToast;

  constructor(
    private store: Store<{ owner: Owner }>,
    private _Activatedroute: ActivatedRoute,
    public LoginService: LoginService,
    public router: Router,
    private ownerApi: OwnerAPI,
    private userInfo: UserInfo,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private routingConfig: RoutingConfig,
    private loadingCtrl: LoadingController
  ) {
    this.translateService
      .get('screens.sms_validation')
      .subscribe((value) => (this.language = value));
  }

  async ngOnInit() {
    this.lang = await this.userInfo.getSelectedLangage();
    this.deadPinTimer();
  }

  selectOwner() {
    const subscribtion = this.store
      .select('owner')
      .subscribe(async (value: Owner) => {
        let token = await Storage.get({ key: dev.ACCESS_TOKEN }).then(
          (value) => value.value
        );

        if (value.id && value.checkpoint_path && token) {
          await this.routingConfig.saveCropProductListToStorage();
          this.loading = false;
          this.router
            .navigateByUrl(value.checkpoint_path)
            .then((val) => subscribtion.unsubscribe());
        } else if (value.id && token && !value.checkpoint_path) {
          await this.routingConfig.saveCropProductListToStorage();
          this.loading = false;
          this.router
            .navigateByUrl('/person/profile-information')
            .then((val) => subscribtion.unsubscribe());
        }
      });
  }
  processSMS(data) {
    const message = data.Message;
    if (message != -1) {
      this.OTP = message.slice(0, 6);
      this.OTPmessage = 'OTP received. Proceed to register';
    }
  }

  async resendSms() {
    this.deadPinTimer();
    if (this.smsToast) {
      this.smsToast.dismiss();
    }
    this.LoginService.resendSms(
      this.mobile_number,
      this.prefix,
      await this.translateService.instant('sms_message')
    ).subscribe((response) => {
      if (response.status) {
        this.presentToast(this.language.sms_send_valid);
      } else {
        this.presentToast(this.language.errors.sms_send_error); //
      }
    });
  }

  deadPinTimer() {
    this.minutes = 5;
    this.seconde = 59;
    this.showExpMessage = false;
    clearTimeout(this.interval);

    this.interval = setInterval(() => {
      if (this.minutes == 0 && this.seconde == 0) {
        this.seconde = 0;
        this.minutes = 0;

        clearTimeout(this.interval);
      } else {
        if (this.seconde > 0 && this.minutes >= 0) {
          this.seconde--;
        } else {
          this.seconde = 59;
          this.minutes -= 1;
        }
      }
    }, 1000);
  }
  loadingModal;
  async submitForm() {
    this.loadingModal = await this.loadingCtrl.create({
      spinner: 'bubbles',
      mode: 'ios',
    });
    if (!this.code_input) {
      this.presentToast(this.language.errors.sms_send_error);
      return;
    }
    this.loadingModal.present();

    this.LoginService.validateAndLogin(
      this.mobile_number,
      this.prefix,
      this.code_input,
      this.network
    )
      .pipe(
        switchMap((value) => {

          if (value.access_token)
            return from([
              Storage.set({
                key: dev.ACCESS_TOKEN,
                value: value.access_token,
              }),
              // Storage.set({
              //   key: dev.USER_DATA,
              //   value: JSON.stringify(value.user),
              // }),
            ]);
          else return of(null);
        }),
        map((value) => {
          if (value) {
            this.loadingModal.dismiss();

            this.presentToast(this.language.valid);
            this.store.dispatch(getOwnerById());

          } else {
            this.loadingModal.dismiss(); 

            return this.presentToast(this.language.errors.sms_send_error);
          }
        }),
        catchError((err) => {
          this.loadingModal.dismiss();
          return of({});
        })
      )
      .subscribe((val) => this.selectOwner());
    // .subscribe(async (response) => {
    //   if (this.smsToast) {
    //     this.smsToast.dismiss();
    //   }
    //   if (response.access_token) {
    //     this.loading = true;
    //     this.is_next_clicked = true;
    //     await
    //     await Storage.set({
    //       key: dev.USER_DATA,
    //       value: JSON.stringify(response.user),
    //     });
    //
    //     setTimeout(async () => {
    //       this.loading = false;
    //       this.store.dispatch(getOwnerById());
    //     }, 400);
    //     // if (response.user?.owner_id != null) {
    //     //   // this.ownerApi.getOwnerById().subscribe(
    //     //   //   async (val: any) => {
    //     //   //     await handleResponse(val);
    //     //   //     setTimeout(() => {}, 3000);
    //     //   //   },
    //     //   //   (error) => console.log(error)
    //     //   // );
    //     // } else {
    //     //   await this.routingConfig.saveCropProductListToStorage();

    //     // }
    //   } else {
    //     this.presentToast(this.language.errors.sms_send_error);
    //   }
    // });
  }

  async presentToast(msg, duration?) {
    let message = msg || this.language.errors.sms;

   await this.toastUtils
      .toastBuilder('', '', message, '', duration)
      .then((value) => {
        if (duration) {
          this.smsToast = value;
        }
      });
  }

  goBack() {
    this.router.navigate(['/person']);
  }
}
