export default class Partner {
  id?: any;
  name?: string;
  type?: string;
  personal_identification_number?: string;
  owner_id?: any;
  partners_type_id: number;
  partner_type: any;
  country_id: any;
  crop_id: any;
  morphable_type: any;
  morphable_id: any;
  image_path: string;
  file_id
  hasSelectedPhoto : boolean = false;
  file
  blobFile
}
