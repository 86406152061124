import { EmptyListModule } from 'src/app/widgets/empty-list/empty-list.module';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { ClustersAmbassadorsSelectorsComponent } from './clusters-ambassadors-selectors/clusters-ambassadors-selectors.component';
import { FarmScreensActivityStartComponent } from './farm-screens-activity-start/farm-screens-activity-start.component';
import { LotsOverviewComponent } from './lots-components/lots-overview/lots-overview.component';
import { LotManagerComponent } from './lots-components/lot-manager/lot-manager.component';
import { LotsCreatorComponent } from './lots-components/lots-creator/lots-creator.component';
import { PreProductSelectorComponent } from './others-agricol-product-components/pre-product-selector/pre-product-selector.component';
import { LotsComponent } from './lots-components/lots/lots.component';
import { FooterModule } from 'src/app/widgets/footer-component/header-footer.module';
import { FarmRoutingModule } from './farm-routing.module';
import { AgriculturalProductWidgetComponent } from './widgets/agricultural-product-widget/agricultural-product-widget.component';
import { MapComponent } from './map/map.component';
import { MapComponent as MapWidget } from './widgets/map/map.component';
import { AgmCoreModule } from '@agm/core';
import { PorductionEditorComponent } from './others-agricol-product-components/porduction-editor/porduction-editor.component';
import { CoffeGenSelectorComponent } from './widgets/coffe-gen-selector/coffe-gen-selector.component';
import { LotProductionSelectorComponent } from './widgets/lot-production-selector/lot-production-selector.component';
import { LotSingleEntryComponent } from './widgets/lot-single-entry/lot-single-entry.component';
import { UpdateAnimalPropretyComponent } from './animals/update-animal-proprety/update-animal-proprety.component';
import { AnimalesSelectorComponent } from './animals/animals-selector/animals-selector.component';
import { AnimalesListComponent } from './animals/animals-list/animals-list.component';
import { AnimalesFormComponent } from './animals/animals-form/animals-form.component';
import { AnimalesComponent } from './animals/animals/animals.component';
import { ProductFormComponent } from './others-agricol-product-components/product-form/product-form.component';
import { ProductUpdateFormComponent } from './others-agricol-product-components/product-update-form/product-update-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { FarmAddressDetailsComponent } from './farm-details/farm-address-details/farm-address-details.component';
import { FarmOwnershipComponent } from './farm-details/farm-ownership/farm-ownership.component';
import { ClusterMainDetailsComponent } from './farm-details/cluster-main-details.component/cluster-main-details.component';
import { DynamicFormQuestionsComponent } from 'src/app/utils/dynamic-forms/dynamic-form-questions/dynamic-form-questions.component';
import { SkeletonLoaderModule } from 'src/app/widgets/skeleton-loader/skeleton-loader.module';
import { ProductCardComponent } from 'src/app/widgets/product-card/product-card.component';
import { ProductsCardsComponent } from './others-agricol-product-components/products-cards/products-cards.component';
import { EffectsModule } from '@ngrx/effects';
import { FarmEffect } from 'src/app/utils/states-management/effects/farm.effect';
import { OwnerEffect } from 'src/app/utils/states-management/effects/owner.effect';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyIonicModule } from '@ngx-formly/ionic';

@NgModule({
  declarations: [
    LotsComponent,
    PreProductSelectorComponent,
    LotsCreatorComponent,
    LotManagerComponent,
    LotsOverviewComponent,
    FarmScreensActivityStartComponent,
    FarmAddressDetailsComponent,
    ClusterMainDetailsComponent,
    FarmOwnershipComponent,
    ClustersAmbassadorsSelectorsComponent,
    AgriculturalProductWidgetComponent,
    MapComponent,
    MapWidget,
    PorductionEditorComponent,
    CoffeGenSelectorComponent,
    LotProductionSelectorComponent,
    LotSingleEntryComponent,
    AnimalesComponent,
    AnimalesFormComponent,
    AnimalesListComponent,
    AnimalesSelectorComponent,
    UpdateAnimalPropretyComponent,
    ProductFormComponent,
    ProductUpdateFormComponent,
    DynamicFormQuestionsComponent,
    ProductCardComponent,
    ProductsCardsComponent,
    
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  imports: [
    CommonModule,
    FooterModule,
    FarmRoutingModule,
    MatTabsModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    SkeletonLoaderModule,
    // EmptyListModule,
    TranslateModule.forChild(),
    EffectsModule.forFeature([FarmEffect, OwnerEffect]),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBUifABNr4WJU-GllxulFIgONkVRb5apiM',
      libraries: ['places', 'drawing', 'geometry'],
      language: localStorage.lang || 'en',
    }),
    FormlyIonicModule,
    FormlyModule.forRoot({
      validationMessages: [{ name: 'required', message: 'This field is required' }],
    })
  ],
  exports: [
    FarmRoutingModule,
    LotsComponent,
    PreProductSelectorComponent,
    LotsCreatorComponent,
    LotManagerComponent,
    LotsOverviewComponent,
    FarmScreensActivityStartComponent,
    FarmAddressDetailsComponent,
    ClusterMainDetailsComponent,
    FarmOwnershipComponent,
    ClustersAmbassadorsSelectorsComponent,
    AgriculturalProductWidgetComponent,
    MapComponent,
    MapWidget,
    PorductionEditorComponent,
    CoffeGenSelectorComponent,
    LotProductionSelectorComponent,
    LotSingleEntryComponent,
    AnimalesComponent,
    AnimalesFormComponent,
    AnimalesListComponent,
    AnimalesSelectorComponent,
    UpdateAnimalPropretyComponent,
    ProductFormComponent,
    ProductUpdateFormComponent,
    ProductCardComponent,
    
  ],
})
export class FarmModule {}
