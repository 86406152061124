import { DateFormatPipe } from './../../../../utils/pipes-utils/date_formatter';
import { environment } from 'src/environments/environment';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import { StatisticsAPI } from 'src/app/apis/statistics.service';
import { BookCashflowListFilter, BookComponent } from '../book/book.component';
import { SummaryComponent } from '../../summary/summary.component';
import { lastValueFrom, take } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { Store } from '@ngrx/store';
import {
  getHarvestSummary,
  getTotalsAccountingCategoriesResult,
} from 'src/app/utils/states-management/selector/dashboard.selector';
import { loadHarvestSummary } from 'src/app/utils/states-management/actions/dashboard.action';
import { AccountingEntryCategory } from 'src/app/models/cash-flow/cashflow_categories';
import { clone, cloneDeep } from 'lodash';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import { UnifiedCashflowInputComponentComponent } from 'src/app/modules/cashflow/unified-cashflow-input-component/unified-cashflow-input-component.component';
import Owner from 'src/app/models/owner.model';
import { connectionStatus } from 'src/app/utils/states-management/reducers/offline.reducer';

interface StatRequestParams {
  start_date: string;
  end_date: string;
  sort_by: string;
  monthly_yearly: string;
}
@Component({
  selector: 'app-analysis-harvest',
  templateUrl: './analysis-harvest.component.html',
  styleUrls: ['./analysis-harvest.component.scss'],
})
export class AnalysisHarvestComponent implements OnInit {
  @Input() nav: IonNav;
  lineChart;
  user;
  cropProductAccounting;
  params: StatRequestParams = {
    start_date: new Date(new Date().getFullYear(), 0, 1).toISOString(),
    end_date: new Date().toISOString(),
    sort_by: null,
    monthly_yearly: null,
  };
  isFiltred = false;
  @ViewChild('lineChart') lineChartCanvas: ElementRef;
  base_url = environment.appRoot;
  selected_year;

  sum = 0;
  constructor(
    public translateService: TranslateService,
    private statisticsApi: StatisticsAPI,
    private modalController: ModalController,
    private datePipe: DateFormatPipe,
    private store: Store<{
      accountingEntries: Array<AccountingEntryCategory>;
      owner: Owner;
    }>
  ) {
    this.store
      .select('owner')
      .subscribe((value) => (this.user = cloneDeep(value)));
  }

  Check(crop) {
    crop.checked = !crop.checked;
  }
  onFilterClick(filter) {
    this.filterAccordion[filter] = !this.filterAccordion[filter];
  }

  handelFilterClicked(ev) {
    this.filterAccordion = {
      general: ev,
      selection: ev,
      category: ev,
    };
  }
  async ngOnInit() {
    let { data } = await lastValueFrom(
      this.store.select(getTotalsAccountingCategoriesResult).pipe(take(1))
    );
    this.cropProductAccounting = cloneDeep(data).filter(
      (elment) => elment.morphable_type
    );
    this.cropProductAccounting.forEach((element) => {
      element['checked'] = true;
    });
    this.store.select(getHarvestSummary).subscribe((value) => {
      console.log(value);
      this.drawLineChart(value);
      this.isFiltred = true;
    });
  }
  filterAccordion = {
    general: true,
    selection: true,
    category: true,
  };
  redirectToBook() {
    let bookFilter: BookCashflowListFilter = {
      startDate: this.params.start_date,
      endDate: this.params.end_date,
      filterCategory: [1],
      filterType: [0],
      categorieIsFiltred: true,
      cashflowTypeIsFiltred: true,
      is_loading: true,
      isFiltred: false,
      page: 1,
      page_size: 10,
      isHarvestingFilter: true,
    };
    this.nav.setRoot(BookComponent, {
      nav: this.nav,
      bookCashflowListFilter: bookFilter,
      rootStack: SummaryComponent,
    });
  }

  initDate(startDate, endDate) {
    this.selected_year =
      this.datePipe.transform(startDate, this.translateService.currentLang) +
      ' - ' +
      this.datePipe.transform(endDate, this.translateService.currentLang);
  }

  HandelDateChange(value) {
    this.params.start_date = value.start_date;
    this.params.end_date = value.end_date;
    this.initDate(this.params.start_date, this.params.end_date);

    this.fetchAndDrawData();
  }

  getTotal(values) {
    this.sum = 0;
    values?.map((element) => {
      const keys = Object.keys(element);
      if (keys[0]) {
        this.sum += element[keys[0]];
      }
    });
  }

  async drawLineChart(values) {
    this.getTotal(values);
    if (this.lineChart) this.lineChart.destroy();
    var dataHarvested = {
      label: this.translateService.instant('activity.43'),
      data: new Array(),
      lineTension: 0,
      fill: false,
      borderColor: 'green',
      backgroundColor: 'green',
      pointBorderColor: 'green',
      pointBackgroundColor: 'green',
    };
    const labels = new Array();
    values?.map((element) => {
      const keys = Object.keys(element);
      if (keys[0]) {
        labels.push(keys[0]);
        dataHarvested.data.push(element[keys[0]]);
      }
    });
    var speedData = {
      labels: labels,
      datasets: [dataHarvested],
    };
    this.lineChart = new Chart(this.lineChartCanvas.nativeElement, {
      type: 'line',
      data: speedData,
      options: {
        scales: {
          y: {
            position: 'right',
            title: {
              color: '#bdbdbd',
              text: 'KG',
              display: false,
              align: 'end',
              font: {
                size: 14,
                family: "'Poppins', ui-sans-serif, system-ui, '-apple-system'",
              },
            },
            beginAtZero: true,
          },
          x: {
            title: {
              color: '#bdbdbd',
              text: await this.translateService.instant('label.time'),
              display: false,
              align: 'end',
              font: {
                size: 14,
                family: "'Poppins', ui-sans-serif, system-ui, '-apple-system'",
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }

  fetchAndDrawData() {
    let data = {
      startDate: this.params.start_date,
      endDate: this.params.end_date,
      cropId: this.cropProductAccounting[0].id,
    };
    this.store.dispatch(loadHarvestSummary({ data: data }));

    // this.statisticsApi
    //   .harvestSummary(
    //     this.params.start_date,
    //     this.params.end_date,
    //     this.cropProductAccounting[0].id
    //   )
    //   .subscribe((val) => {
    //     this.drawLineChart(val);
    //     this.isFiltred = true;
    //   });
  }
  async openHarvestingCashflow() {
    let cashflow = new CashFlow();
    cashflow.date = new Date().toISOString();
    cashflow.farm_id = this.user?.owner?.farm_id;
    cashflow.accounting_entry_categories = this.cropProductAccounting[0].id;
    cashflow.is_income = false;
    if (this.cropProductAccounting[0].id == 10) {
      cashflow.cash_flow_expense_id = 63;
      cashflow.cashflow_expenses_categorie =
        this.cropProductAccounting[0]?.cashflow_category
          .find((value) => value.id == 299)
          ?.cash_flow_expenses.find((value) => value.id == 63);

      cashflow.cashflow_categories =
        this.cropProductAccounting[0]?.cashflow_category.find(
          (value) => value.id == 299
        );
      cashflow.cash_flow_categorie_id = 299;
    } else {
      cashflow.cash_flow_categorie_id = 148;
      cashflow.cash_flow_expense_id = 63;
      cashflow.cashflow_expenses_categorie =
        this.cropProductAccounting[0]?.cashflow_category
          .find((value) => value.id == 292)
          ?.cash_flow_expenses.find((value) => value.id == 63);
      cashflow.cashflow_categories =
        this.cropProductAccounting[0]?.cashflow_category.find(
          (value) => value.id == 292
        );
      cashflow.cash_flow_categorie_id = 292;
    }
    const modal = await this.modalController.create({
      component: UnifiedCashflowInputComponentComponent,
      cssClass: ['my-custom-modal-css ', 'modal-wrapper'],
      componentProps: {
        // cashFlowEntrieName: sell.accounting_entries_categories,
        accountingEntryCategories: this.cropProductAccounting[0],
        modalController: this.modalController,
        cashflow: cashflow,
        is_update: false,
        popOverContent: this.translateService.instant('select_harvesting'),
      },
    });
    modal.present();
    await modal.onWillDismiss();
    this.fetchAndDrawData();
    // this.setupotherProdList();
  }
  isOffline(): boolean {
    let isOffline: boolean;
    this.store.select(connectionStatus).subscribe((isOnline) => {
      isOffline = !isOnline;
    });
    return isOffline;
  }
}
