import { MORPH_Garentee } from './../../../../utils/consts';
import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Storage } from '@capacitor/storage';
import { ModalController } from '@ionic/angular';
import { OperationsAPI } from 'src/app/apis/operations.service';
import {
  DEBT_OPERATION,
  GARENTEE_OPERATION,
  MONEY_LOAN_OPERATION,
} from 'src/app/utils/consts';
import { OperationCautionViewComponent } from '../operation-caution-view/operation-caution-view.component';
import { OperationDebtViewComponent } from '../operation-debt-view/operation-debt-view.component';
import { OperationViewComponent } from '../operation-view/operation-view.component';
import { dev } from '../../../../config/offlines_keys.json';
@Component({
  selector: 'app-operation-trackers-cashflow-thrid-model',
  templateUrl: './operation-trackers-cashflow-thrid-model.component.html',
  styleUrls: ['./operation-trackers-cashflow-thrid-model.component.scss'],
})
export class OperationTrackersCashflowThridModelComponent
  implements OnInit, DoCheck
{
  @Input() model_required_object;
  @Input() cashflow;
  @Input() owner;
  is_add_required;
  operationList: any[] = new Array();
  is_loading;
  oldAmount = 0;
  oldPaidSoFar = 0;
  operationSelected;
  inProgressPayment = 0;
  MORPH_Garentee = MORPH_Garentee;
  @Output() cashflowOperationChange: EventEmitter<any> = new EventEmitter();

  constructor(
    private modalCtr: ModalController,
    private operationAPI: OperationsAPI
  ) {}

  async ngOnInit() {
    this.cashflow.is_operation = true;
    this.is_add_required = true;
    await this.getOperations();
  }

  async insertOperation() {
    var component;
    var componentProps;
    if (this.model_required_object.model_name.includes('operation')) {
      if (this.model_required_object.query.includes(MORPH_Garentee)) {
        component = OperationCautionViewComponent;
        componentProps = {
          operationType: GARENTEE_OPERATION,
          cashflow: this.cashflow,
          modalController: this.modalCtr,
        };
      } else if (this.model_required_object.query.includes('debt')) {
        component = OperationDebtViewComponent;
        componentProps = {
          operationType: DEBT_OPERATION,
          cashflow: this.cashflow,
          modalController: this.modalCtr,
        };
      } else if (this.model_required_object.query.includes('money loan')) {
        component = OperationViewComponent;
        componentProps = {
          operationType: MONEY_LOAN_OPERATION,
          cashflow: this.cashflow,
          modalController: this.modalCtr,
        };
      }
    }
    if (component && componentProps) {
      const modal = await this.modalCtr.create({
        component: component,
        cssClass: ['my-custom-modal-css ', 'modal-wrapper'],
        componentProps: componentProps,
        id: '2',
      });
      await modal.present();
      await modal.onDidDismiss().then(async (val) => {
        if (val?.data?.loan) {
          this.operationSelected = val.data.loan;
          this.operationSelected.amount = parseFloat(val.data.loan.amount);
          this.operationSelected.paid_so_far = parseFloat(
            val.data.loan.paid_so_far
          );
          this.operationList.push(this.operationSelected);
          this.modelSelectChanged(val.data.loan);
        }
      });
    }
  }

  modelSelectChanged(vlaue) {
    this.inProgressPayment = 0;
    this.calculateProgress();
    this.cashflow.operation_id = vlaue.id;
    this.cashflow.initial_value = vlaue;
    //this.cashflow.operation = this.model_required_object;
    this.operationSelected = vlaue;
    this.operationSelected.calculatedPercent = this.calculatePercent(
      this.operationSelected.amount,
      this.operationSelected.paid_so_far
    );
    this.cashflowOperationChange.emit(this.cashflow);
  }

  calculatePercent(amount, paid_so_far) {
    if (!paid_so_far) {
      paid_so_far = 0;
    }
    return paid_so_far / amount;
  }

  async getOperations() {
    if (!this.owner)
      this.owner = await Storage.get({ key: dev.USER_KEY }).then((value) =>
        JSON.parse(value.value)
      );
    this.operationAPI.getOperation().subscribe((value) => {
      if (Array.isArray(value)) {
        this.operationList = value.filter((element) =>
          this.model_required_object.query.includes(element.operation_type)
        );
      }
      this.operationList = this.operationList.map((operations) => {
        operations.calculatedPercent = this.calculatePercent(
          operations.amount,
          operations.paid_so_far
        );

        return operations;
      });
      if (this.cashflow.operation_id) {
        this.operationSelected = this.operationList.find(
          (element) => element.id === this.cashflow.operation_id
        );

        if (this.operationSelected?.paid_so_far) {
          this.oldAmount = this.cashflow.amount;
          this.oldPaidSoFar = this.operationSelected.paid_so_far
            ? this.operationSelected.paid_so_far
            : 0;
        } else {
          this.oldAmount = 0;
        }
      }

      this.is_loading = false;
    });
  }

  operationChange(event) {
    this.cashflow = event;
    this.cashflowOperationChange.emit(event);
  }

  ngDoCheck(): void {
    if (this.operationSelected) {
      this.calculateProgress();
      this.operationSelected.calculatedPercent = this.calculatePercent(
        this.operationSelected.amount,
        this.inProgressPayment
      );
    }
  }

  compareWithList(o1, o2) {
    return o1.id === o2.id;
  }

  calculateProgress() {
    this.inProgressPayment =
      this.cashflow.amount +
      this.operationSelected.paid_so_far -
      this.oldAmount;
  }
}
