import { OwnerAPI } from 'src/app/apis/owners.service';
import { handleResponse } from 'src/app/utils/user_data_storage';
import { dev } from 'src/app/config/offlines_keys.json';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationUtils } from 'src/app/utils/animations';
import { Platform } from '@ionic/angular';
import { Storage } from '@capacitor/storage';
import { setUserCountry } from 'src/app/utils/states-management/actions/countries.action';
import { Store } from '@ngrx/store';
import { lastValueFrom, take } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
})
export class SplashComponent implements OnInit {
  constructor(
    public router: Router,
    private platform: Platform,
    private animationUtils: AnimationUtils,
    private ownerAPI: OwnerAPI,
    private store:Store
  ) {}

  async ngOnInit() {
    this.animationUtils.fadeInOutAnimation('img-animation', 2000);
    await this.navigate();
  }

  async navigate() {
    this.platform.ready().then(async () => {
         let country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)))

      let lang = (await Storage.get({ key: dev.LANGAGE_KEY })).value;

      var token = await (await Storage.get({ key: dev.ACCESS_TOKEN })).value;
      if (token) {
        this.ownerAPI.getOwnerById().subscribe(
          async (owner: any) => {
            if (owner && owner.checkpoint_path && token) {
              if (owner.checkpoint_path.includes('dashboard')) {
                this.router.navigateByUrl('/dashboard');
                await handleResponse(owner);
                if(owner.countrie){
                  this.store.dispatch(setUserCountry({country:owner?.countrie}))
                }
              } else {
                this.router.navigateByUrl(owner.checkpoint_path);
              }
            }
          },
          async (err: any) => {
            if (err.status == 401) {
              if (!lang) {
                this.router.navigateByUrl('lang');
              } else if (!country) {
                this.router.navigateByUrl('countries');
              } else {
                this.router.navigateByUrl('login');
              }
            }
          }
        );
      }else{
        if (!lang && country) {
          this.router.navigateByUrl('lang');
        } else if (lang && !country) {
          this.router.navigateByUrl('countries');
        } else {
          this.router.navigateByUrl('login');
        }
      }
    });
  }
}
