import { RoutingConfig } from 'src/app/utils/routing-config';
import { dev } from 'src/app/config/offlines_keys.json';
import { Network } from '@capacitor/network';
import {
  updateConnectionStatus,
  syncData,
  loadAppData,
  LOAD_APP_DATA,
} from './utils/states-management/actions/offline.action';
import { ToastUtils } from './utils/toast_builder';
import { Router, RouterEvent } from '@angular/router';
import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Storage } from '@capacitor/storage';
import { handleResponse } from './utils/user_data_storage';
import { TranslateService } from '@ngx-translate/core';
import { Capacitor, Plugins } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { Device } from '@capacitor/device';
import { PopoverComponent } from './widgets/popover/popover.component';
import { AnimationUtils } from './utils/animations';
import { setUserCountry } from './utils/states-management/actions/countries.action';
import { lastValueFrom, take } from 'rxjs';
import Owner from './models/owner.model';
import { Store } from '@ngrx/store';
import { register } from 'swiper/element/bundle';
import { StatusBar } from '@capacitor/status-bar';
import { Location } from '@angular/common';
import { MenuPage } from 'src/app/modules/home/menu/menu.page';
// import { Workbox } from 'workbox-window/Workbox.mjs';

import {
  LOGOUT,
  getOwnerById,
} from './utils/states-management/actions/owner.action';

const { App } = Plugins;
register();
import routes from './config/routing.json';
import {
  IonNav,
  IonTabBar,
  IonTabs,
  NavController,
  Platform,
  PopoverController,
} from '@ionic/angular';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChild('container', { read: ViewContainerRef })
  container: ViewContainerRef;
  @ViewChild(IonTabs) ionTabs: IonTabs;

  steps: { template: string; title: string }[];
  animation;
  selectedStep: number = 0;
  isSecondBackClicked = false;
  device_info;
  loadingModal;
  isHomeView;
  routing;
  showLandingHeader = false;
  isOnline = true;
  appSyncState = {
    isSyncDone: true,
    isSyncOnProgress: false,
    operation: {},
    isOnline: true,
    showOfflineStatus: false,
  };
  isMainAnalysisPage: boolean = true;

  showConnectionStatus = false;
  owner: Owner;

  constructor(
    private popoverController: PopoverController,
    public router: Router,
    private platform: Platform,
    private toastUtils: ToastUtils,
    private animationUtils: AnimationUtils,
    private store: Store<{ owner: Owner; connection }>,
    private translate: TranslateService,
    private routingConfig: RoutingConfig,
    private location: Location,
    private swUpdate: SwUpdate
  ) {
    this.initServiceWorkerLoading();
    this.animationUtils.routerAnimation().then((value) => {
      this.animation = value;
    });
    this.router.events.subscribe(async (val: RouterEvent) => {
 
      this.isHomeView = this.router.url.includes(routes.dashboard.home);

      let routing = await this.routingConfig.getRoutingFilter();
      this.showLandingHeader =
        routing?.find(
          (val) => val.route.includes(this.router.url) && val.tabIndex > -1
        ) &&
        !this.router.url.includes('home') &&
        !this.router.url.includes('login') &&
        !this.router.url.includes('landing_done') &&
        this.router.url != '/'
          ? true
          : false;
    });

    // if (Capacitor.getPlatform() === 'android') {
    //   setTimeout(() => {
    //     StatusBar.setOverlaysWebView({ overlay: true });
    //     StatusBar.setBackgroundColor({ color: '#00000000' });
    //   }, 100);
    // }

    this.platform.backButton.subscribeWithPriority(9999, async (event: any) => {
      if (!this.isSecondBackClicked) {
        this.isSecondBackClicked = true;
        document.addEventListener(
          'backbutton',
          async function (event) {
            event.preventDefault();
          },
          true
        );
      } else {
        App.exitApp();
      }
    });
  }
  offlinePopOver;
  offlineSyncPopOver;
  unSyncOperationLength = 0;
  async ngOnInit(): Promise<void> {
    await SplashScreen.show({
      autoHide: true,
      showDuration: 2000,
    });
    let props = {
      key1: 'app_offline_info_sync',
      canBeClosed: false,
      is_confirm_needed: false,
      is_info_popover: false,
      is_loader_needed: true,
    };

    this.store.select('connection').subscribe(async (connection) => {
      if (connection?.isSyncOnProgress && !this.offlineSyncPopOver) {
        this.offlineSyncPopOver = await this.toastUtils.popOverBuilder(
          props,
          PopoverComponent,
          'popover-custom',
          null,
          false,
          true
        );
      } else if (this.offlineSyncPopOver && !connection?.isSyncOnProgress) {
        this.offlineSyncPopOver?.dismiss();
        this.offlineSyncPopOver = null;
      }

      this.appSyncState.isOnline = connection.isOnline;
      this.appSyncState.isSyncDone = connection.isSyncDone;
      this.unSyncOperationLength = Array.from(
        Object.values(connection.entities)
      ).length;

      var owner = await lastValueFrom(this.store.select('owner').pipe(take(1)));

      if (
        connection.isOnline == false &&
        ((owner && !owner?.checkpoint_path?.includes('home')) ||
          this.router.url.includes('login'))
      ) {
        this.offlinePopOver = await this.popoverController.create({
          component: PopoverComponent,
          cssClass: 'popover-custom',
          animated: true,
          componentProps: CProps,
          backdropDismiss: false,
        });
        this.offlinePopOver.present();
      } else if (this.offlinePopOver) {
        this.offlinePopOver.dismiss();
      }

      if (
        this.unSyncOperationLength == 0 &&
        this.appSyncState.isOnline == true
      ) {
        setTimeout(() => {
          this.appSyncState.showOfflineStatus = !this.appSyncState.isOnline;
        }, 2000);
      } else {
        this.appSyncState.showOfflineStatus = !this.appSyncState.isOnline;
      }
    });

    this.device_info = await Device.getInfo();
    let token = await Storage.get({ key: dev.ACCESS_TOKEN }).then(
      (value) => value.value
    );
    if (token) {
      this.store.dispatch(getOwnerById());
      this.routing = (
        await this.routingConfig.getConfigObject()
      )?.landing?.routingConfig;
      await this.navigate();
    } else {
      // this.store.dispatch({ type: LOGOUT, redirection_path: '/login' });
    }

    await SplashScreen.hide();
    let CProps = {
      key1: this.router.url.includes('login')
        ? await this.translate.instant('app_offline_info_login')
        : await this.translate.instant('app_offline_info'),
      canBeClosed: false,
      is_confirm_needed: false,
    };
    this.offlinePopOver = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: 'popover-custom',
      animated: true,
      componentProps: CProps,
      backdropDismiss: false,
    });

    Network.addListener('networkStatusChange', async (status) => {
      this.store.dispatch(
        updateConnectionStatus({ isOnline: status.connected })
      );
      if (status.connected == true) {
        this.store.dispatch(syncData());
      }
    });
  }

  async navigate() {
    this.platform.ready().then(async () => {
      this.store
        .select('owner')
        .pipe(take(1))
        .subscribe({
          next: async (owner: any) => {
            this.owner = owner;

            if (!owner?.country_id) {
              // this.store.dispatch({ type: LOGOUT, redirection_path: '/' });
              return;
            } else {
              this.store.dispatch(setUserCountry({ country: owner.countrie }));
            }
            if (owner && owner?.checkpoint_path) {
              // this.translate.use(owner?.preferred_language || 'en');
              if (owner?.checkpoint_path.includes('home')) {
                // if(owner?.farm?.id){
                // this.store.dispatch(loadAppData())
                // }
                this.router.navigateByUrl('/home');
                await handleResponse(owner);
                await SplashScreen.hide();
              } else if (owner?.checkpoint_path) {
                let componentProps = {
                  key1: await this.translate.instant('landing_creation_info'),
                  close_button: await this.translate.instant('submit'),
                };
                const popover = await this.toastUtils.popOverBuilder(
                  componentProps,
                  PopoverComponent,
                  'popover-custom'
                );

                await this.router.navigateByUrl(owner.checkpoint_path);
              }
            }
          },
          error: async (err: any) => {
            if (err.status == 401) {
              // this.store.dispatch({ type: LOGOUT, redirection_path: '/' });
            }
          },
        });
    });
  }
  async showPopOver() {
    let componentProps = {
      key1: await this.translate.instant('offline_notification'),
    };
    await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
  }

  //   handelTabClicked(tab) {
  // //     console.log(')-------');
  // //     console.log(this.ionTabs.getSelected());
  // //     this.ionTabs.tabBar.selectedTab='home/wallet'
  // //     this.ionTabs.getSelected()
  // //     const currentRoute = this.router.url;

  // //     if(this.router.url == tab){
  // //       this.router
  // //       this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
  // //         this.router.navigate([currentRoute]);
  // //       });
  // // }
  //   }
  handelTabClicked = (event: MouseEvent) => {
    const { tab } = event
      .composedPath()
      .find(
        (element: any) => element.tagName === 'ION-TAB-BUTTON'
      ) as EventTarget & { tab: string };
    // without checking resetStackTabs this will work for any tab

    if (
      [
        'home/wallet',
        'home/book',
        'home/analysis',
        'home/profile',
        'home/menu',
      ].includes(tab) &&
      this.ionTabs.outlet.canGoBack(1, tab)
    ) {
      event.stopImmediatePropagation();
      return this.ionTabs.outlet.pop(3, tab);

      // here we may need pop depth more than one if we handle deeper nav for a tab
    }
  };

  async initServiceWorkerLoading() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe((event) => {
        console.log(
          '[App] Update available: current version is',
          event.current,
          'available version is',
          event.available
        );
      });
    }
  }
}
