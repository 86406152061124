import { RoutingConfig } from 'src/app/utils/routing-config';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../config/offlines_keys.json';
import Money_reporting from 'src/app/models/money_reporting.model';
import { MoneyReportingsAPI } from '../../../apis/money_reportings.service';
import { IonNav, ModalController, PopoverController } from '@ionic/angular';
import { ModalBankAccountComponent } from '../modal-bank-account/modal-bank-account.component';
import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { PriorDashboardComponent } from '../../prior-dashboard/prior-dashboard.component';
import { CashflowAPI } from 'src/app/apis/cashflow.service';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import {
  CASH_DEPOSI_TYPE,
  CURRENT_ACCOUNT,
  INITIAL_SOLD_CASHFLOW_CATEGORIE_ID,
  INTITIAL_SOLD_ACCOUNTING_ENTRIE_CATEGORIE_ID,
  INVESTMENT_ACCOUNT,
  PENSION_PLAN_TYPE_GOV,
  PENSION_PLAN_TYPE_NO_GOV,
  SAVING_ACCOUNT,
} from 'src/app/utils/consts';
import { showConfirmModal } from 'src/app/utils/pop_over_maker';
import { ModalCashflowEntryListComponent } from '../../dashboard/modal-cashflow-entry-list/modal-cashflow-entry-list.component';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap, take } from 'rxjs/operators';
import { from, lastValueFrom, of } from 'rxjs';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { Store } from '@ngrx/store';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';

@Component({
  selector: 'app-bank-accounts',
  templateUrl: './bank-accounts.component.html',
  styleUrls: ['./bank-accounts.component.scss'],
})
export class BankAccountsComponent implements OnInit {
  @ViewChild('tabs') tabGroup: MatTabGroup;
  @Input() isDashboardView: Boolean;
  @Input() nav: IonNav;
  @Input() rootStack;
  @Input() claimed_tab;
  fecha;
  existing_cash_money;
  existing_saving_account;
  owner_id: number;
  pension_plan_name;
  contribution;
  currency;
  has_pension;
  list_bank_type2: any;
  list_bank_type3: any;
  list_bank_type4: any;
  list_bank_type1: any;
  country;
  modal_cashflow;
  selected_step = 0;
  saving_account = new Money_reporting();
  formConfig;
  has_cuenta_corrient = false;
  hast_cuenta_ahoros = false;
  is_retired;
  has_cuenta_inversion = false;
  is_pension = false;
  farm;
  constructor(
    private router: Router,
    public modalController: ModalController,
    private popover: PopoverController,
    private moneyReportingsAPI: MoneyReportingsAPI,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private cashflowAPI: CashflowAPI,
    private routingConfig: RoutingConfig,
    private store:Store
  ) {}
  async ngOnInit() {
    let ClassName = 'BankAccountsComponent';
    this.formConfig = await this.routingConfig.getPageFormConfig(ClassName);
    await this.getOwnerId();
    this.saving_account.owner_id = this.owner_id;
    this.saving_account.type = PENSION_PLAN_TYPE_GOV;
    this.fecha = new Date().toISOString();

    this.getMoneyReportings().subscribe();
    await this.getcountrie_currency();

    this.claimed_tab
      ? (this.tabGroup.selectedIndex = this.claimed_tab)
      : (this.tabGroup.selectedIndex = 0);

    this.farm = await lastValueFrom(this.store.select(getFarm).pipe(take(1)))
  }

  isPension(value) {
    this.is_pension = value;
    if (this.pension_plan_name == 'BEPS' && value) {
      this.pension_plan_name = '';
    }
  }

  setContribution(value) {
    this.contribution = value;
  }

  async getcountrie_currency() {
    this.country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    this.currency = this.country?.currency;
  }

  async showPopOver(val) {
    let lang;
    if (val == 0) {
      lang = await this.translateService.get('bubble_desc_cash').toPromise();
    } else if (val == 1) {
      lang = await this.translateService
        .get('Money_reporting_type1_description')
        .toPromise();
    } else if (val == 2) {
      lang = await this.translateService
        .get('Money_reporting_type2_description')
        .toPromise();
    } else if (val == 3) {
      lang = await this.translateService
        .get('Money_reporting_type3_description')
        .toPromise();
    } else if (val == 4) {
      lang = await this.translateService.get('bubble_desc_pension').toPromise();
    }
    let componentProps = { key1: lang };

    await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
  }

  async tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.selected_step = tabChangeEvent.index;
    if (tabChangeEvent.index == 0) {
      this.showPopOver(0);
    } else if (tabChangeEvent.index == 1) {
      this.showPopOver(1);
    } else if (tabChangeEvent.index == 2) {
      this.showPopOver(2);
    } else if (tabChangeEvent.index == 3) {
      this.showPopOver(3);
    } else if (tabChangeEvent.index == 4) {
      this.showPopOver(4);
    }
  }

  getMoneyReportings() {
    return this.moneyReportingsAPI.getMoneyReporting().pipe(
      map((value) => {
        var MoneyReporting = value;
        if (MoneyReporting != undefined) {
          this.list_bank_type1 = MoneyReporting.filter(
            (value) => value.type == CASH_DEPOSI_TYPE
          );
          this.list_bank_type2 = MoneyReporting.filter(
            (value) => value.type == CURRENT_ACCOUNT
          );
          this.list_bank_type3 = MoneyReporting.filter(
            (value) => value.type == INVESTMENT_ACCOUNT
          );
          this.list_bank_type4 = MoneyReporting.filter(
            (value) => value.type == SAVING_ACCOUNT
          );

          this.existing_saving_account = MoneyReporting.find(
            (value) =>
              value.type == PENSION_PLAN_TYPE_GOV ||
              value.type === PENSION_PLAN_TYPE_NO_GOV
          );
          if (
            this.existing_saving_account &&
            this.existing_saving_account.currency &&
            this.existing_saving_account.amount
          ) {
            this.existing_saving_account.amount = Math.abs(
              this.existing_saving_account.amount
            );
            this.pension_plan_name =
              this.existing_saving_account.money_reporting_name;
            this.is_retired = false;
            this.has_pension = true;
            this.contribution = this.existing_saving_account.amount;
            this.currency = this.existing_saving_account.currency;
            if (this.existing_saving_account.money_reporting_name == 'BEPS') {
              this.pension_plan_name = 'BEPS';
              this.has_pension = true;
              this.is_pension = false;
            } else {
              this.is_pension = true;
            }
          } else {
            this.is_retired = true;
            this.has_pension = false;
          }
        }
      })
    );
  }

  async getOwnerId() {
    await Storage.get({ key: dev.USER_KEY }).then((value) => {
      this.owner_id = JSON.parse(value.value)?.id;
    });
  }

  async mreturn() {
    if (this.tabGroup.selectedIndex > 0) {
      this.tabGroup.selectedIndex--;
    } else {
      this.router.navigateByUrl(await this.routingConfig.getBackUrl());
    }
  }

  async mnext() {
    if (this.tabGroup.selectedIndex + 1 <= 4) this.tabGroup.selectedIndex++;
    else {
      if (this.has_pension) {
        this.saving_account.amount = this.contribution;
        this.saving_account.currency = this.currency;
      } else {
        this.saving_account.amount = 0;
        this.saving_account.currency = null;
      }
      if (this.has_pension && this.is_pension) {
        this.saving_account.money_reporting_name = this.pension_plan_name;
      } else if (
        this.has_pension &&
        this.is_pension == false &&
        this.country.id == 2
      ) {
        this.saving_account.money_reporting_name = 'BEPS';
        this.saving_account.type = PENSION_PLAN_TYPE_NO_GOV;
      } else {
        this.saving_account.money_reporting_name = this.pension_plan_name;
      }

      if (
        this.saving_account.money_reporting_name &&
        this.contribution == undefined &&
        this.has_pension == '0' &&
        this.is_pension &&
        !this.is_retired
      ) {
        await this.toastUtils
          .toastBuilder(
            '',
            'bottom',
            await this.translateService
              .instant('errors.money_reporting_req'),
                          'danger'
          )
      } else if (
        !this.saving_account.money_reporting_name &&
        !this.is_retired &&
        this.has_pension
      ) {
      await  this.toastUtils
          .toastBuilder(
            '',
            'bottom',
            await this.translateService
              .instant('errors.money_reporting_req'),
                          'danger'
          )
      } else if (
        (this.saving_account.money_reporting_name != undefined &&
          this.contribution != undefined &&
          this.has_pension == '0') ||
        (this.saving_account.money_reporting_name != undefined &&
          this.has_pension == '1') ||
        (this.saving_account.money_reporting_name != undefined &&
          this.has_pension != undefined)
      ) {
        if (
          this.saving_account.money_reporting_name == undefined &&
          this.has_pension == undefined
        ) {
          this.saving_account.amount = 0;
        }
        this.moneyReportingsAPI.getMoneyReporting().subscribe((item) => {
          var MoneyReporting = item;

          if (MoneyReporting) {
            this.existing_saving_account = MoneyReporting.find(
              (value) =>
                value.type == PENSION_PLAN_TYPE_GOV ||
                value.type == PENSION_PLAN_TYPE_NO_GOV
            );
            if (this.existing_saving_account) {
              this.saving_account.id = this.existing_saving_account.id;
              this.moneyReportingsAPI
                .updateMoneyReporting(this.saving_account)
                .subscribe((value) => {
                  if (!this.isDashboardView) {
                    this.updateInicialSold(this.existing_saving_account);
                  }
                });
            } else {
              this.moneyReportingsAPI
                .storeMoneyReporting(this.saving_account)
                .subscribe((val) => {
                  this.saveInicialSold(val);
                });
            }
          }
        });

        this.isDashboardView
          ? this.nav.setRoot(this.rootStack, {
              nav: this.nav,
              rootStack: PriorDashboardComponent,
            })
          : this.router.navigateByUrl(await this.routingConfig.getNextUrl());
      } else if (
        this.saving_account.money_reporting_name == undefined &&
        this.contribution == undefined
      ) {
        this.isDashboardView
          ? this.nav.setRoot(this.rootStack, {
              nav: this.nav,
              rootStack: PriorDashboardComponent,
            })
          : this.router.navigateByUrl(await this.routingConfig.getNextUrl());
      }
    }
  }

  async updateInicialSold(value) {
    if (value?.cashflow.length > 0) {
      let cashflow = new CashFlow();
      cashflow = value.cashflow[0];
      cashflow.amount = value.amount;
      this.cashflowAPI.updateCashFlow(cashflow).subscribe((val) => {
        val;
      });
    }
  }
  async saveInicialSold(value) {
    if (!this.isDashboardView) {
      const cashflow = new CashFlow();
      cashflow.farm_id = this.farm.id;
      cashflow.amount =
        value?.type === PENSION_PLAN_TYPE_GOV ||
        value?.type === PENSION_PLAN_TYPE_NO_GOV
          ? this.contribution
          : value?.amount;
      cashflow.currency = value?.currency;
      cashflow.cash_flow_categorie_id = INITIAL_SOLD_CASHFLOW_CATEGORIE_ID;
      cashflow.accounting_entries_categories =
        INTITIAL_SOLD_ACCOUNTING_ENTRIE_CATEGORIE_ID;
      cashflow.date = new Date().toISOString();
      cashflow.comment = value.money_reporting_name;
      cashflow.money_reporting_id = value.id;
      this.cashflowAPI.saveCashFlow(cashflow).subscribe();
    }
  }

  async presentModal(bank_type: string, formConfig) {
    let modal = await this.toastUtils.modalBuilder(
      ModalBankAccountComponent,
      {
        bank_type: bank_type,
        formConfig: formConfig,
      },
      ['my-custom-modal-css', 'modal-wrapper']
    );

    await modal.onWillDismiss();
    this.getMoneyReportings().subscribe();
  }

  async presentupdateModal($id: number, bank_type: string, formConfig) {
    var value = true;
    if (this.isDashboardView) {
      value = await this.requestUserToInsertCashflow(
        await this.translateService.get('add_cashflow_entry').toPromise()
      );
    }

    if (value == false || (value && !this.isDashboardView)) {
      let modal = await this.toastUtils.modalBuilder(
        ModalBankAccountComponent,
        {
          bank_type: bank_type,
          formConfig: formConfig,
          id: $id,
        },
        ['my-custom-modal-css', 'modal-wrapper']
      );
      from(modal.onDidDismiss())
        .pipe(
          map((data) => data),
          switchMap((data: any) => {
            if (!data.canceled) {
              return this.getMoneyReportings();
            } else return of(data);
          })
        )
        .subscribe();

      await modal.onWillDismiss();
    }
  }

  async delete(item) {
    if (!this.isDashboardView) {
      this.moneyReportingsAPI
        .deleteMoneyReporting(item.id)
        .pipe(
          map((deleteResult) => deleteResult),
          switchMap((deleteResult) => this.getMoneyReportings())
        )
        .subscribe();
    } else {
      let value = true;
      if (item.amount != 0 && this.isDashboardView) {
        value = await this.requestUserToInsertCashflow(
          await this.translateService.get('redirect_cashflow_entry').toPromise()
        );
      } else {
        this.moneyReportingsAPI
          .deleteMoneyReporting(item.id)
          .pipe(
            map((deleteResult) => deleteResult),
            switchMap((deleteResult) => this.getMoneyReportings())
          )
          .subscribe();
      }
    }
  }

  async updateForm($id: number, bank_type: string, formConfig) {
    await this.presentupdateModal($id, bank_type, formConfig);
  }

  answeredBankAccount1(value) {
    this.has_cuenta_corrient = value;
  }

  answeredBankAccount2(value) {
    this.has_cuenta_inversion = value;
  }

  answeredBankAccount3(value) {
    this.hast_cuenta_ahoros = value;
  }

  answeredBankAccount4(value) {
    this.is_retired = value;
  }

  answeredBankAccountPension(value) {
    this.has_pension = value;
  }

  async requestUserToInsertCashflow(msg) {
    const modal = await showConfirmModal(
      this.popover,
      msg,
      null,
      await this.translateService.get('yes').toPromise(),
      await this.translateService.get('no').toPromise()
    );
    const { data } = await modal.onWillDismiss();
    if (data?.confirmed) {
      this.modal_cashflow = await this.popover.create({
        component: ModalCashflowEntryListComponent,
        componentProps: {
          modalController: this.popover,
          nav: this.nav,
        },
        cssClass: ['popover_class', 'diario'],
        id: '2',
      });
      this.modal_cashflow.present();
      await this.modal_cashflow.onWillDismiss();
      this.getMoneyReportings().subscribe();
    }
    return data?.confirmed;
  }
}
