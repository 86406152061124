import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ProductSale } from 'src/app/models/cash-flow/sale.model';
import { PaginationResult } from 'src/app/models/pagination-result.model';
import { paginateSalesSuccess } from '../actions/product-sale.action';

export interface ProductSaleState extends EntityState<ProductSale> {
  selectedSaleId: number | null;
  paginationResult: PaginationResult<ProductSale> | null;
  allValues: Array<ProductSale>;
}

export function selectedProductSaleId(a: ProductSale): number {
  return a.id ? a.id : 0;
}

export const productSaleAdapter: EntityAdapter<ProductSale> =
  createEntityAdapter<ProductSale>({
    selectId: selectedProductSaleId,
  });
export const initialProductSaleState: ProductSaleState =
  productSaleAdapter.getInitialState({
    selectedSaleId: null,
    paginationResult: null,
    allValues: [],
  });

export const productSales = createReducer(
  initialProductSaleState,
  on(paginateSalesSuccess, (state, { paginationResult }) => {
    if (paginationResult.meta.current_page == 1) {
      return productSaleAdapter.setAll(paginationResult.data, {
        ...state,
        allValues: paginationResult.data,
        paginationResult,
      });
    } else
      return productSaleAdapter.addMany(paginationResult.data, {
        ...state,
        allValues: state.allValues.concat(paginationResult.data),
        paginationResult,
      });
  })
);
