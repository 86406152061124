import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import { createReducer, on } from '@ngrx/store';
import {
  clearCashflowSelection,
  getCashflowByIdSuccess,
} from '../actions/cashflow.action';

export const initialState = new CashFlow();
export const cashflowReducer = createReducer(
  initialState,

  on(getCashflowByIdSuccess, (state, action) => {
    state = action.data;
    return state;
  }),
  on(clearCashflowSelection, (state, action) => {
    return new CashFlow();
  })
);
