import { Component, Input, OnInit } from '@angular/core';
import { IonNav, PopoverController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, take } from 'rxjs';
import { PersonalDataComponent } from 'src/app/components/personal-data/personal-data.component';
import Owner from 'src/app/models/owner.model';
import { LOGOUT } from 'src/app/utils/states-management/actions/owner.action';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { ConfirmModalComponent } from 'src/app/widgets/confirm-modal/confirm-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  image_path ;
  constructor(
    private store: Store<{ owner: Owner }>,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private popover: PopoverController
  ) {}
  user;
  age;
  birth_date;
  @Input() nav: IonNav;
  rootParams;
  base_url = environment.appRoot;

  handelUpdate() {
    this.rootParams = {
      nav: this.nav,
      sub_tab: true,
      title: 'profile_lebels.myaccount',
      tab: 0,
      info_label: '',
    };
    this.nav.setRoot(PersonalDataComponent, this.rootParams);
  }
  async ngOnInit() {
    this.user = await lastValueFrom(this.store.select('owner').pipe(take(1)));
    this.image_path = this.user?.file?.path ? this.base_url + this.user.file.path : "assets/photo-placeholder.svg";

    if (this.user?.birth_date) {
      this.birth_date = new Date(this.user?.birth_date);
      var timeDiff = Math.abs(Date.now() - this.birth_date);

      this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
    }
  }

  async logOut() {
    const confirm = await this.askConfirmation();
    if (confirm) {
      // console.log(confirm);
      this.store.dispatch({ type: LOGOUT, redirection_path: '/' });
    }
  }

  async askConfirmation() {
    let componentProps = {
      title: await this.translateService.instant('log_out_title'),
      confirmation_text: await this.translateService.instant('log_out'),
      modalController: this.popover,
      cancel_text: await this.translateService.instant('cancel'),
      submit_text: await this.translateService.instant('confirm'),
    };
    var modal = await this.toastUtils.popOverBuilder(
      componentProps,
      ConfirmModalComponent,
      ['pop-over-style-confirm']
    );
    const { data } = await modal.onWillDismiss();
    return data?.confirmed;


  }
}
