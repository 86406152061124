import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  DoCheck,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { CashflowSignleEntrySummaryComponent } from '../../cashflow/cashflow-signle-entry-summary/cashflow-signle-entry-summary.component';
import { AHOROS_ACCOUNTING_ENTRY_CATEGORY, HOGAR_ACCOUNTING_ENTRIE_CATEGORIE_ID } from 'src/app/utils/consts';
@Component({
  selector: 'app-casflow-summary',
  templateUrl: './casflow-summary.component.html',
  styleUrls: ['./casflow-summary.component.scss'],
})
export class CasflowSummaryComponent implements OnInit, OnChanges, DoCheck {
  @Input() summary;
  @Input() showDetails;
  @Input() nav;
  sho;
  lang$;
  MAX_PERCENT = 5000000000;
  perCentIngresos = '0';
  percentEgresos = '0';
  AHOROS_ACCOUNTING_ENTRY_CATEGORY = AHOROS_ACCOUNTING_ENTRY_CATEGORY
  HOGAR_ACCOUNTING_ENTRIE_CATEGORIE_ID = HOGAR_ACCOUNTING_ENTRIE_CATEGORIE_ID
  constructor(public translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {}
  ngDoCheck(): void {}
  async ngOnInit() {
    this.lang$ = of(this.translateService.currentLang);
  }

  onClick() {
    this.nav.setRoot(CashflowSignleEntrySummaryComponent, {
      id: this.summary.id,
      name: this.summary.title,
      name_en: this.summary.title_en,
      summary: this.summary,
    });
  }
}
