import {
  mapLangeTitleObjects,
  orderAlphabaticallyByAttribute,
  pushOtrosToEndByAttributeName,
} from '../../../../utils/array-filtrer';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MaterialProductAPI } from 'src/app/apis/material_product.service';
import { ANIMAL_IDS, MORPH_MaterialCategorie } from 'src/app/utils/consts';
import { convertToMetricSystem } from 'src/app/utils/unit-convert';
import routes from '../../../../config/routing.json';
import { dev } from '../../../../config/offlines_keys.json';
import { Storage } from '@capacitor/storage';
import { UserInfo } from 'src/app/apis/users-info.service';
import { ProductionAPI } from 'src/app/apis/FarmAPI/productions.service';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';
import { Good } from 'src/app/models/good.model';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';
import { selectAllAnimals } from 'src/app/utils/states-management/reducers/animals.reducer';
import { cloneDeep } from 'lodash';
import { addAnimals } from 'src/app/utils/states-management/actions/animals.action';

@Component({
  selector: 'app-animals-form',
  templateUrl: './animals-form.component.html',
  styleUrls: ['./animals-form.component.scss'],
})
export class AnimalesFormComponent implements OnInit {
  isDashboardView;
  @Input() modalController;
  categories;
  is_list_view = true;
  selected_categories: any[] = null;
  lang;
  base_url = 'assets';
  has_showed_popover = false;
  constructor(
    private router: Router,
    private userInfo: UserInfo,
    private materialProductAPI: MaterialProductAPI,
    private productionAPI: ProductionAPI,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private routingConfig: RoutingConfig,
    private store: Store<{ animals: any }>
  ) {}

  async ngOnInit() {
    const cropConfig = await this.routingConfig.getCropFormConfig();
    this.isDashboardView = this.router.url.includes(routes.dashboard.home);

    this.store.select('animals').subscribe((value:any) => {
      this.categories = cloneDeep(Array.from(Object.values(value.entities)));

      if (cropConfig.crop_entity.includes('material_product')) {
        this.setUpCrop(cropConfig);
      }
      this.categories.map(
        (value) =>
          (value.title = this.translateService.instant(
            'material_category.' + value?.id
          ))
      );
      this.categories = mapLangeTitleObjects(
        this.categories,
        'title',
        this.translateService
      );

      // this.categories = pushOtrosToEndByAttributeName(
      //   categories,
      //   'title',
      //   this.translateService.instant('other_key')
      // );
    });
    this.showPopOver();
    this.lang = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );
  }
  handelTitleChanged(ev, animal) {
    this.categories[
      this.categories.findIndex((element) => element.id == 15)
    ].comment = ev;
  }

  setUpCrop(cropConfig) {
    this.categories = this.categories.map((category) => {
      var crop = category.childrens.find((element) =>
        cropConfig.material_crop_id.includes(element.id)
      );
      if (crop) {
        crop.is_crop = true;
        crop.is_checked = true;
        category.is_crop = true;
        category.is_checked = true;
      }
      return category;
    });
  }

  showList() {
    this.is_list_view = !this.is_list_view;
    this.selected_categories = null;
  }

  categoriesSelected(event) {
    this.selected_categories = event;
    this.is_list_view = !this.is_list_view;
  }

  unitChanged(event, product) {
    product.unit = event.target.value;
  }

  valueChanged(event, product) {
    const value = event.target.value;
    if (product.unit && product.qty)
      product.equivalent_in_metric = convertToMetricSystem(product.unit, value);
  }

  async submit() {
    // if (this.is_list_view) {
    const other = this.categories.find((element) => element?.id == 15);
    if (other && other.is_checked && !other.comment) {
      this.showError('label.required_input_no_html');
      return;
    }
    this.selected_categories = this.categories.filter((element) => {
      if (element.comment)
        this.lang == 'es'
          ? (element.title = element.comment)
          : (element.title_en = element.comment);
      if (element.is_checked) return element;
    });
    this.selected_categories = orderAlphabaticallyByAttribute(
      this.selected_categories,
      'title',
      this.lang
    );
    if (this.selected_categories.length > 0) {
      // this.is_list_view = !this.is_list_view;
    } else {
      this.showError('errors.no_animal');
    }
    await this.submitFormInsertDataAndCloseModal();

    // } else {
    //   await this.submitFormInsertDataAndCloseModal();
    // }
  }

  async showError(message_label: string) {
    await this.toastUtils
      .toastBuilder(
        await this.translateService
          .instant(message_label),
        '',
        ''
      );
  }

  async submitFormInsertDataAndCloseModal() {
    const farm = await lastValueFrom(this.store.select(getFarm).pipe(take(1)));
    const owner = await this.userInfo.getOwner();
    const insertionsProduct = new Array<ProductionsPerfarm>();
    this.selected_categories.forEach((categorie) => {
      const newProduction = new ProductionsPerfarm();
      newProduction.goods = new Array<Good>();
      newProduction.is_animal = 1;
      newProduction.is_crop = categorie.is_crop ? 1 : 0;
      newProduction.farm_id = farm?.id;
      newProduction.morphable_type = MORPH_MaterialCategorie;
      newProduction.morphable_id = categorie.id;
      newProduction.morphable = categorie;
      if (categorie.comment) newProduction.comment = categorie.comment;
      categorie.childrens.forEach((product) => {
        if (ANIMAL_IDS.includes(product.id)) {
          let good = new Good();
          good.material_product_id = product.id;
          good.material_product = product;
          good.qty = product?.qty ? product.qty : 0;
          good.unit = 'unit';
          good.owner_id = owner?.id;
          newProduction.goods.push(good);
        } else {
          if (product.is_checked) {
            let good = new Good();
            good.material_product_id = product.id;
            good.material_product = product;

            good.qty = product?.qty ? product.qty : 0;
            good.owner_id = owner?.id;
            good.unit = product.unit ? product.unit : null;
            newProduction.goods.push(good);
          }
        }
      });
      insertionsProduct.push(newProduction);
    });

    var has_error = false;
    insertionsProduct.forEach((producitons) => {
      producitons.goods.forEach((element) => {
        if (
          element.qty &&
          !ANIMAL_IDS.includes(element.material_product_id) &&
          !element.unit
        ) {
          has_error = true;
        }
      });
    });
    if (has_error) {
      await this.showError('errors.missing_fields');
    } else {
      this.store.dispatch(addAnimals({ productions: insertionsProduct }));
      this.modalController.dismiss();

      // this.productionAPI
      //   .insertMultipleProduction(insertionsProduct)
      //   .then((value) => {
      //     this.modalController.dismiss();
      //   });
    }
  }

  selectProduct(product) {
    product.is_checked = !product.is_checked;
  }
  dismiss() {
    this.modalController.dismiss({ canceled: true });
  }

  async showPopOver(has_showed_popover = true) {
    let componentProps = {
      key1: await this.translateService.get('animals_form_info').toPromise(),
      close_button: await this.translateService.get('submit').toPromise(),
    };

    const popover = await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
    this.has_showed_popover = has_showed_popover;
  }
}
