import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FilterChoice } from '../../cashflow/filters/analyses-filter/analyses-filter.component';

@Component({
  selector: 'app-categories-selection-popover',
  templateUrl: './categories-selection-popover.component.html',
  styleUrls: ['./categories-selection-popover.component.scss'],
})
export class CategoriesSelectionPopoverComponent implements OnInit {
  selected_accounting_categorie: Array<number>;
  country;
  datechanged = false;
  @Input() modalId = '';
  @Input() showDates = true;
  @Input() list;
  filter: FilterChoice = {
    sort_by: null,
    start_date: new Date(new Date().getFullYear(), 0, 1).toISOString(),
    end_date: new Date().toISOString(),
    monthly_yearly: 'm-Y'
  };

  indeterminateState: boolean;
  checkParent: boolean;
  Checkboxes = [];


  constructor(
    public translateService: TranslateService,
    public modalController: ModalController
  ) { }
  dateChanged() {
    if (this.filter.start_date != this.filter.end_date) {
      this.datechanged = true;
    }
  }
  Check(item) {
    this.Checkboxes.forEach((element) => {
      if (item.id == element.id) {
        element.isItemChecked = !element.isItemChecked;
      }
    });
  }
  orderAccountings() {
    this.Checkboxes.sort((a, b) => {
      return this.translateService
        .instant('accounting_entries.' + a.id)
        .normalize('NFD')
        .toLowerCase() ===
        this.translateService
          .instant('accounting_entries.' + b.id)
          .normalize('NFD')
          .toLowerCase()
        ? 0
        : this.translateService
          .instant('accounting_entries.' + a.id)
          .normalize('NFD')
          .toLowerCase() <
          this.translateService
            .instant('accounting_entries.' + b.id)
            .normalize('NFD')
            .toLowerCase()
          ? -1
          : 1;
    });
  }

  HandelDateChange(value) {
    this.filter.start_date = value.start_date;
    this.filter.end_date = value.end_date;
    this.datechanged = true;
  }

  ngOnInit() {
    this.Checkboxes = this.list;
    this.Checkboxes.forEach((element) => {
      element.isItemChecked = true;
    });
    this.orderAccountings()
  }

  checkCheckbox() {
    setTimeout(() => {
      this.Checkboxes.forEach((item) => {
        item.isItemChecked = this.checkParent;
      });
    });
  }
  verifyEvent() {
    const allItems = this.Checkboxes.length;
    let selected = 0;
    this.Checkboxes.map((item) => {
      if (item.isItemChecked) selected++;
    });
    if (selected > 0 && selected < allItems) {
      this.indeterminateState = true;
      this.checkParent = false;
    } else if (selected == allItems) {
      this.checkParent = true;
      this.indeterminateState = false;
    } else {
      this.indeterminateState = false;
      this.checkParent = false;
    }
  }
  cancelPressed() {
    this.modalController.dismiss();
  }
  submitPressed() {
    let dataChecked = this.Checkboxes.filter((val) => val.isItemChecked);
    this.modalController.dismiss(
      {
        data: dataChecked.length > 0 ? dataChecked : [],
        dateFilter: this.datechanged ? this.filter : null,
      },
      '',
      this.modalId
    );
  }
}
