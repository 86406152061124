import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { BookPageRoutingModule } from './book-routing.module';

import { BookPage } from './book.page';
import { HomeFilterComponent } from '../widgets/home-filter/home-filter.component';
import { TranslateModule } from '@ngx-translate/core';
import { FooterModule } from 'src/app/widgets/footer-component/header-footer.module';
import {
  GetFirstActivityLevelPipe,
  IsHarvestingActivity,
} from 'src/app/utils/pipes-utils/activity_pipe';
import { BookSingleEntryComponent } from '../widgets/book-single-entry/book-single-entry.component';
import { EmptyListComponent } from 'src/app/widgets/empty-list/empty-list.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    BookPageRoutingModule,
    TranslateModule.forChild(),
    FooterModule,
    
  ],
  declarations: [
    BookPage,
    BookSingleEntryComponent,
    IsHarvestingActivity,
    GetFirstActivityLevelPipe,
  ],
  exports: [

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BookPageModule {}
