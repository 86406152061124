import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BankAccountsComponent } from './bank-accounts/bank-accounts.component';
import { MaterialGoodFormComponent } from './material-good-form/material-good-form.component';
import { MaterialGoodsComponent } from './material-goods/material-goods.component';
import { ModalGarenteeComponent } from './modal-garentee/modal-garentee.component';
import { ModalMoneyLoanComponent } from './modal-money-loan/modal-money-loan.component';
import { MoneyLoanComponent } from './money-loan/money-loan.component';
import { GoodsRoutingModule } from './goods-routing.module';
import { MoneyReportingComponent } from './money-reporting/money-reporting.component';
import { FooterModule } from 'src/app/widgets/footer-component/header-footer.module';
import { MatTabsModule } from '@angular/material/tabs';
import { GarenteeComponent } from './garentee/garentee.component';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { GoodsPage } from './goods.page';
import { MaterialProductSelectorComponent } from './widgets/material-product-selector/material-product-selector.component';
import { ModalBankAccountComponent } from './modal-bank-account/modal-bank-account.component';
import { ListMaterialGoodsComponent } from './list-material-goods/list-material-goods.component';
import { ObjectivesListComponent } from '../objectives/objectives-list/objectives-list.component';
import { ObjectivesFormsComponent } from '../objectives/objectives-forms/objectives-forms.component';
import { ObjectivesComponent } from '../objectives/objectives.component';
import { CashflowModule } from '../cashflow/cashflow.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    BankAccountsComponent,
    MaterialGoodFormComponent,
    MaterialGoodsComponent,
    ModalGarenteeComponent,
    ModalMoneyLoanComponent,
    MoneyLoanComponent,
    ModalBankAccountComponent,
    MoneyReportingComponent,
    GarenteeComponent,
    GoodsPage,
    ObjectivesComponent,
    ObjectivesFormsComponent,
    ObjectivesListComponent,
    MaterialProductSelectorComponent,
    ListMaterialGoodsComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  imports: [
    FooterModule,
    CommonModule,
    IonicModule,
    MatTabsModule,
    ReactiveFormsModule,
    FormsModule,
    CashflowModule,
    TranslateModule.forChild(),
  ],
  exports: [
    ObjectivesComponent,
    ObjectivesFormsComponent,
    ObjectivesListComponent,
    GoodsRoutingModule,
    BankAccountsComponent,
    MaterialGoodFormComponent,
    MaterialGoodsComponent,
    ModalGarenteeComponent,
    ModalMoneyLoanComponent,
    MoneyLoanComponent,
    ModalBankAccountComponent,
    MoneyReportingComponent,
    GarenteeComponent,
    GoodsPage,
    MaterialProductSelectorComponent,
    ListMaterialGoodsComponent,
  ],
})
export class GoodsModule {}
