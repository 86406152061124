import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Unit } from '../models/Unit';
import { dev } from '../config/endpoints.json';

@Injectable({ providedIn: 'root' })
export class UnitsApi {
  base_url = environment.appRoot + '/api/';

  constructor(private httpClinet: HttpClient) {}

  loadUnits(): Observable<Array<Unit>> {
    return this.httpClinet.get<Array<Unit>>(
      this.base_url + dev.endpoint.units.get_units_for_user_country
    );
  }
}
