import { dev } from 'src/app/config/offlines_keys.json';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {  ModalController } from '@ionic/angular';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { AssistanceModalComponent } from './assistance-modal/assistance-modal.component';
import { ScrollableElementsListComponent } from './scrollable-elements-list/scrollable-elements-list.component';
import { Storage } from '@capacitor/storage';
import { environment } from 'src/environments/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { RoutingConfig } from 'src/app/utils/routing-config';
import { lastValueFrom, take } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss'],
})
export class ProgramComponent implements OnInit {
  @Input() program;
  modeleoptions: boolean[] = [true, false, false, false];

  country;
  currency: string;
  videos = [];
  toggle_element_2_subElement_1: boolean = false;
  toggle_element_2_subElement_2: boolean = false;

  @ViewChild('slideWithNav1', { static: false }) slideWithNav1: any;
  @ViewChild('slideWithNav2', { static: false }) slideWithNav2: any;
  @ViewChild('slideWithNav3', { static: false }) slideWithNav3: any;
  @ViewChild('slideWithNav4', { static: false }) slideWithNav4: any;
  @ViewChild('slideWithNav5', { static: false }) slideWithNav5: any;

  sliderOne: any;
  currentSlideIndex: number = 0;

  selectedForestryModalValue: string = 'Ver Lista';
  selectedproductive_speciesModalValue: string = 'Ver Lista';

  list_1;
  list_2;
  programInfos;
  lots;
  selectedLot;
  subsidyPerTree_after3Months;
  subsidyPerTree_after6Months;

  treesUnit;
  treesPerUnit = 0.33;
  totalSubsidies = 0;
  totalNumberOfTrees = 0;
  subsidyPerTree: number = 0;
  subsidySelectedOption;
  subsidySelectedsubOption;

  base_url = environment.appRoot;

  slideOptsOne = {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: true,
  };
  lang;
  constructor(
    private modalController: ModalController,
    private toastUtils: ToastUtils,
    public appConfig: RoutingConfig,
    private store:Store,
    public translateService: TranslateService
  ) {
    translateService.onLangChange.subscribe((lang) => {
      this.lang = lang;
    });
    this.sliderOne = {
      isBeginningSlide: true,
      isEndSlide: false,
      slidesItems: [
        {
          id: '1',
          url: '../../assets/slide_1.jpeg',
        },
        {
          id: '1A',
          url: '../../assets/slide_1A.jpeg',
        },
        {
          id: '1B',
          url: '../../assets/slide_1B.jpeg',
        },
        {
          id: '1C',
          url: '../../assets/slide_1C.jpeg',
        },
        {
          id: '2',
          url: '../../assets/slide_2.jpeg',
        },
        {
          id: '3',
          url: '../../assets/slide_3.jpeg',
        },
      ],
    };
  }

  async ngOnInit(): Promise<void> {
    console.log(this.program)
    this.translateService.onLangChange.subscribe(
      async (event: LangChangeEvent) => {
        this.lang = event.lang;
        this.programInfos = await this.translateService
          .get(this.program?.title_translate_key + '_' + this.country.id)
          .toPromise();
          console.log(this.programInfos)
      }
    );
    this.program = this.program?.config;
    await this.getCountry();
    this.getCurrency();
    this.programInfos = await this.translateService
      .get(this.program?.title_translate_key + '_' + this.country.id)
      .toPromise();
    this.lots = JSON.parse((await Storage.get({ key: dev.LOT_KEY })).value);
    if (this.lots.length > 0) {
      this.selectedLot = this.lots[0]
    }

    this.subsidyPerTree =  this.country.id === 2 ? 1100 : 192
    this.subsidyPerTree_after3Months = this.country.id === 2 ? 605 : 99
    this.subsidyPerTree_after6Months = this.country.id === 2 ? 495 : 99

    this.videos = [
      {
        id: '1',
        videoId: this.program?.sections[0]?.video?.youtubeId,
      },
      {
        id: '2',
        videoId: this.program?.sections[4]?.video?.youtubeId,
      },
    ];

  }
  prevSliderClicked(sliderId){
    this[sliderId].slidePrev();
    this[sliderId].startAutoplay();
  }
  nextSliderClicked(sliderId){
    this[sliderId].slideNext();
    this[sliderId].startAutoplay();


  }
  modeleoptionSelected(optionNumber?: number, subOptionNumber?: number) {

    if (optionNumber || optionNumber === 0) {
      this.subsidySelectedOption = optionNumber
      if (subOptionNumber) { this.subsidySelectedsubOption = subOptionNumber } else { subOptionNumber = this.subsidySelectedsubOption }
      this.modeleoptions = [false, false, false, false];
      this.modeleoptions[optionNumber] = true;
    } else {
      optionNumber = this.subsidySelectedOption
      if (this.subsidySelectedsubOption) { subOptionNumber = this.subsidySelectedsubOption }
      this.modeleoptions = [false, false, false, false];
      this.modeleoptions[optionNumber] = true;
    }
    // this.modeleoptions = [false, false, false, false];
    // this.modeleoptions[optionNumber] = true;
   

    // if (!this.selectedLot) {
    //   // TODO: display please select plot to display calculations
    //   // return
    // }
    switch(optionNumber) {
      case 0:
        this.treesUnit = 'tree_meter';
        this.treesPerUnit = 0.33;
        if (this.selectedLot?.perimeters) {
          this.totalNumberOfTrees = this.treesPerUnit * this.selectedLot.perimeters
          this.totalSubsidies = this.totalNumberOfTrees * this.subsidyPerTree
        } else {
          this.totalNumberOfTrees = 0
          this.totalSubsidies = 0
        }
        break;
      case 1:
        this.treesUnit = 'tree_ha';
        if (subOptionNumber === 1) this.treesPerUnit = 70;
        if (subOptionNumber === 2) this.treesPerUnit = 200;
        this.calculateSubsidiesWithArea()
        break;
        
      case 2:
        this.treesUnit = 'tree_ha';
        this.treesPerUnit = 500;
        this.calculateSubsidiesWithArea()
        break;
      case 3:
        this.treesUnit = 'tree_ha';
        this.treesPerUnit = 1111;
        this.calculateSubsidiesWithArea()
        break;
    }

  }

  calculateSubsidiesWithArea() {
    if (!this.selectedLot) {
      return
    }
    this.totalNumberOfTrees = (this.selectedLot.superficie * this.treesPerUnit)
    this.totalSubsidies = (this.totalNumberOfTrees * this.subsidyPerTree)
  }

  slideNext(object, slideView) {
    slideView.slideNext(500).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });
  }

  slidePrev(object, slideView) {
    slideView.slidePrev(500).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });
  }

  SlideDidChange(object, slideView) {
    slideView.getActiveIndex().then((data) => {
      this.currentSlideIndex = data;
    });
    this.checkIfNavDisabled(object, slideView);
  }

  checkIfNavDisabled(object, slideView) {
    this.checkisBeginning(object, slideView);
    this.checkisEnd(object, slideView);
  }

  checkisBeginning(object, slideView) {
    slideView.isBeginning().then((istrue) => {
      object.isBeginningSlide = istrue;
    });
  }
  checkisEnd(object, slideView) {
    slideView.isEnd().then((istrue) => {
      object.isEndSlide = istrue;
    });
  }

  async getCountry() {
    this.country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
  }

  async getCurrency() {
    let country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    this.currency = country?.currency;
  }

  async openlistmodal(elementsList: string[], title: string) {
    let componentProps = {
      modalController: this.modalController,
      modalTitle: title,
      itemsList: elementsList,
    };

    let modal = await this.toastUtils.modalBuilder(
      ScrollableElementsListComponent,
      componentProps,
      ['scrollable-list-modal-css', 'modal-wrapper']
    );

    const { data } = await modal.onWillDismiss();
    if (!!data.selectedValue && data.title == 'forestry') {
      this.selectedForestryModalValue = data.selectedValue;
    } else if (!!data.selectedValue && data.title == 'productive_species') {
      this.selectedproductive_speciesModalValue = data.selectedValue;
    }
  }

  async opensupport() {
    let componentProps = {
      modalController: this.modalController,
      program: this.program,
      programInfos: this.programInfos,
    };

    let modal = await this.toastUtils.modalBuilder(
      AssistanceModalComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper']
    );

    modal.present();
  }


  lotSelected(event) {
    console.log('event.detail.value', event.detail)
    
    this.selectedLot = this.lots.find(
      (lot) => lot.lot_number == event.detail.value.slice(0, -1)
    );


    if (this.subsidySelectedOption === undefined) {
      this.modeleoptionSelected(0)
    } else { 
      this.modeleoptionSelected() 
    }
    
  }

  SubElementSelected(element) {
    if (element == 1) {
      this.toggle_element_2_subElement_1 = true;
      this.toggle_element_2_subElement_2 = false;
      this.modeleoptionSelected(1,1)
    } else if (element == 2) {
      this.toggle_element_2_subElement_1 = false;
      this.toggle_element_2_subElement_2 = true;
      this.modeleoptionSelected(1,2)
    }
  }
}
