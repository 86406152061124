import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Countrie } from 'src/app/models/countrie.model';
import {
  dashboardDataLoaded,
  loadHarvestSummarySuccess,
  loadInsumosBoughtSuccess,
  loadTotalAccountingCategories,
  loadTotalAccountingCategoriesSuccess,
  loadWorkForceStatsSuccess,
} from '../actions/dashboard.action';

export interface DashboardState extends EntityState<any> {
  totalsAccountingCategories: Array<any>;
  statisticsData: any;
  materialProductKpis: any;
  productKpis: any;
  cafeCerezKpi: any;
  harvestSummary: any;
  insumosBought: any;
  workForceStats: any;
  totalsAccountingCategoriesLoading:boolean;
}

export function selectedCountryId(a: any): number {
  return a.id ? a.id : 0;
}

export const dashboardAdapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: selectedCountryId,
});
export const initialDashboardState: DashboardState =
  dashboardAdapter.getInitialState({
    totalsAccountingCategories: [],
    statisticsData: null,
    materialProductKpis: null,
    productKpis: null,
    cafeCerezKpi: null,
    harvestSummary:null,
    insumosBought:null,
    workForceStats:null,
    totalsAccountingCategoriesLoading:false
  });

export const dashboardReducer = createReducer(
  initialDashboardState,
  on(loadTotalAccountingCategories, (state, result) => {
    return {
      ...state,
      totalsAccountingCategoriesLoading:false
    };
  }),
  on(loadTotalAccountingCategoriesSuccess, (state, result) => {
    return {
      ...state,
      totalsAccountingCategories: result.totalsAccountingCategories,
      totalsAccountingCategoriesLoading:false
    };
  }),
  on(loadHarvestSummarySuccess, (state, result) => {
    return {
      ...state,
      harvestSummary: result.harvestSummary,
    };
  }),
  on(loadInsumosBoughtSuccess, (state, result) => {
    return {
      ...state,
      insumosBought: result.insumosBought,
    };
  }),
  on(loadWorkForceStatsSuccess, (state, result) => {
    return {
      ...state,
      workForceStats: result.workForceStats,
    };
  }),
  on(dashboardDataLoaded, (state, { data }) => {
    return {
      ...state,
      statisticsData: data.statisticsData,
      materialProductKpis: data.materialProductKpis,
      cafeCerezKpi: data.cafeCerezKpi,
      productKpis: data.productKpis,
    };
  })
);
