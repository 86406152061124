import { RoutingConfig } from 'src/app/utils/routing-config';
import { getCurrentMonthAndYear } from 'src/app/utils/pipes-utils/date_formatter';
import { Component, Input, OnInit } from '@angular/core';
import { dev } from '../../../../config/offlines_keys.json';
import { MoneyReportingsAPI } from '../../../../apis/money_reportings.service';
import { Storage } from '@capacitor/storage';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, take } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-bank-account-widget',
  templateUrl: './bank-account-widget.component.html',
  styleUrls: ['./bank-account-widget.component.scss'],
  providers: [],
})
export class BankAccountWidgetComponent implements OnInit {
  total_amount_label;
  total_amount_value_dollars_bank;
  total_amount_value_colones_bank;
  total_amount_cash_dolars;
  total_pension_plan;
  colones_label;
  dollars_label;
  total_cash_other;
  date_label;
  monthYear = getCurrentMonthAndYear(new Date());
  today = new Date();
  selectedDate = { date: new Date().toISOString() };
  date;
  monthNames = [];
  is_today = true;

  month: number;
  is_current_month: boolean;
  title: string;
  year: number;
  @Input() routingConfig;
  constructor(
    public translateService: TranslateService,
    private moneyReportingsAPI: MoneyReportingsAPI,
    private store:Store
  ) {}

  async ngOnInit() {
    const lang = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );
    this.langageChanger(lang);
    this.monthNames = await this.translateService.get('monthNames').toPromise();
    await this.fetchResumee(new Date().toISOString());
  }

  ngDoCheck(): void {
    if (
      this.today.getDate() == new Date(this.selectedDate.date).getDate() &&
      this.today.getMonth() == new Date(this.selectedDate.date).getMonth()
    ) {
      this.is_today = true;
    } else {
      this.is_today = false;
    }
  }

  async fetchResumee(date = new Date().toISOString()) {

    const country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)));
    const currency = country && 'currency' in country ? country.currency : null;
    
    this.colones_label = currency;
    this.moneyReportingsAPI
      .getTotalAmountData(currency, date)
      .subscribe((value) => {
        this.total_amount_value_dollars_bank = value.bank_dollares;
        this.total_amount_value_colones_bank = value.bank_other;
        this.total_amount_cash_dolars = value.cash_dolars;
        this.total_cash_other = value.cash_other;
        this.total_pension_plan = value.pension_plan;
      });
  }

  async nextPress() {
    var data = new Date(this.selectedDate.date);
    data.setDate(data.getDate() + 1);
    this.selectedDate.date = data.toISOString();
    await this.fetchResumee(this.selectedDate.date);
  }

  async backPress() {
    var data = new Date(this.selectedDate.date);
    data.setDate(data.getDate() - 1);
    this.selectedDate.date = data.toISOString();
    await this.fetchResumee(this.selectedDate.date);
  }

  async selectedFromCalendar(event) {
    var data = new Date(event);
    this.selectedDate.date = data.toISOString();
    await this.fetchResumee(this.selectedDate.date);
  }

  async langageChanger(lang) {
    if (lang == 'es') {
      this.date_label = 'Fecha';
    } else {
      this.date_label = 'Date';
    }
    this.total_amount_label = await this.translateService
      .get('total_amount_label')
      .toPromise();
    this.dollars_label ==
      (await this.translateService.get('titles.dollars').toPromise());
  }
}
