import { DateFilterComponent } from './../../widgets/date-filter/date-filter.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DashboardComponent, FilterArrayPerfil } from './dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { ModalCashflowEntryListComponent } from './modal-cashflow-entry-list/modal-cashflow-entry-list.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { UpdateAccessWidgetComponent } from '../../widgets/update-access-widget/update-access-widget.component';
import { NewAccessModalComponent } from '../../widgets/new-access-modal/new-access-modal.component';
import { FooterModule } from '../../widgets/footer-component/header-footer.module';
import { DeleteAccountComponent } from '../../components/delete-account/delete-account.component';
import { FamilyAccessComponent } from '../../components/family-access/family-access.component';
import { SummaryComponent } from './summary/summary.component';
import { CasflowSummaryComponent } from './widgets/casflow-summary/casflow-summary.component';
import { BankAccountWidgetComponent } from './widgets/bank-account-widget/bank-account-widget.component';
import { CardSummaryRegroupedComponent } from './widgets/card-summary-regrouped/card-summary-regrouped.component';
import { SummaryProductionCostComponent } from 'src/app/widgets/summary-production-cost/summary-production-cost.component';
import { ShortcutsComponent } from 'src/app/widgets/shortcuts/shortcuts.component';
import { PreProgComponent } from '../programs/pre-prog/pre-prog.component';
import { ProgramsComponent } from '../programs/programs.component';
import { ProgramsModalComponent } from '../programs/programs-modal/programs-modal.component';
import { RatingModalComponent } from 'src/app/widgets/rating-modal/rating-modal.component';
import { BookComponent } from './cashflow/book/book.component';
import { DateFilterModalComponent } from 'src/app/modules/dashboard/widgets/date-filter-modal/date-filter-modal.component';
import { CoffeeSalesComponent } from '../coffee-sales/coffee-sales.component';
import { AmbassadorCofidentialityComponent } from '../ambassador-cofidentiality/ambassador-cofidentiality.component';
import { CashflowSignleEntrySummaryComponent } from './cashflow/cashflow-signle-entry-summary/cashflow-signle-entry-summary.component';
import { ProductionLossWidgetComponent } from './widgets/production-loss-widget/production-loss-widget.component';
import { CashflowSummaryDiaryComponent } from './cashflow/cashflow-summary-diary/cashflow-summary-diary.component';
import { CashflowModule } from '../cashflow/cashflow.module';
import { OperationsOverviewComponent } from './operations-overview/operations-overview.component';
import { GoodsModule } from '../goods/goods.module';
import { PensionPlanTrackerComponent } from './widgets/pension-plan-tracker/pension-plan-tracker.component';
import { MatTabsModule } from '@angular/material/tabs';
import { PensionPlanUpdatederComponent } from './widgets/pension-plan-updateder/pension-plan-updateder.component';
import { PensionPlanFormComponent } from './widgets/pension-plan-form/pension-plan-form.component';
import { CashflowSelectorComponent } from './widgets/cashflow-selector/cashflow-selector.component';
import { ProductionSalesOtherComponent } from './cashflow/production-sales-other/production-sales-other.component';
import { ProductCashflowListItemComponent } from './widgets/product-cashflow-list-item/product-cashflow-list-item.component';
import { PartnerModule } from '../partner/partner.module';
import { AccountingEntryCategorieStatsComponent } from './widgets/accounting-entry-categorie-stats/accounting-entry-categorie-stats.component';
import { ProductionPerdidasProductsStatsComponent } from './widgets/production-perdidas-products-stats/production-perdidas-products-stats.component';
import { CoffeProductionViewComponent } from './coffee-production-view/coffee-production-view.component';
import { CoffeeTypeSelectorComponent } from './widgets/coffee-type-selector/coffee-type-selector.component';
import { AgricolProductKpisComponent } from './kpis/agricol-product-kpis/agricol-product-kpis.component';
import { AnimalKpisComponent } from './kpis/animal-kpis/animal-kpis.component';
import { CoffeeKpisComponent } from './kpis/coffee-kpis/coffee-kpis.component';
import { ProductionSalesOtherHeaderComponent } from './cashflow/production-sales-other-header/production-sales-other-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { LineChartExpensesChartComponent } from './widgets/line-chart-expenses-chart/line-chart-expenses-chart.component';
import { SkeletonLoaderModule } from 'src/app/widgets/skeleton-loader/skeleton-loader.module';
import { CategoriesSelectionPopoverComponent } from './widgets/categories-selection-popover/categories-selection-popover.component';
import { FooterComponentComponent } from 'src/app/widgets/footer-component/footer-component.component';
import { AnalysisCashflowComponent } from './cashflow/analysis-cashflow/analysis-cashflow.component';
import { AnalysisWorkforceComponent } from './cashflow/analysis-workforce/analysis-workforce.component';
import { AnalysisHarvestComponent } from './cashflow/analysis-harvest/analysis-harvest.component';
import { AnalysisInputsComponent } from './cashflow/analysis-inputs/analysis-inputs.component';
import { AnalysisInsumosBarchartComponent } from './cashflow/analysis-insumos-barchart/analysis-insumos-barchart.component';
import { AnalysesFilterComponent } from './cashflow/filters/analyses-filter/analyses-filter.component';
import { WorkdayAnalyseFitlerComponent } from './cashflow/filters/workday-analyse-fitler/workday-analyse-fitler.component';
import { KpisFiltersComponent } from './cashflow/filters/kpis-filters/kpis-filters.component';
import { InputsFilterComponent } from './cashflow/filters/inputs-filter/inputs-filter.component';
import { EmptyListModule } from 'src/app/widgets/empty-list/empty-list.module';
import { EffectsModule } from '@ngrx/effects';
import { OwnerEffect } from 'src/app/utils/states-management/effects/owner.effect';
import { CashflowEffect } from 'src/app/utils/states-management/effects/cashflow.effect';
import { CalculetteComponentComponent } from './calculette-component/calculette-component.component';
import { AnalysisInputsUsageComponent } from './cashflow/analysis-inputs-usage/analysis-inputs-usage.component';
import { GetFirstActivityLevelPipe, IsHarvestingActivity } from 'src/app/utils/pipes-utils/activity_pipe';
import { QuestionAnswersComponent } from './question-answers/question-answers.component';
import { AccordionQuestionComponent } from 'src/app/widgets/accordion-question/accordion-question.component';
import { HomeFilterComponent } from '../home/widgets/home-filter/home-filter.component';
import { BookPageModule } from '../home/book/book.module';
import { AnalysisPageModule } from '../home/analysis/analysis.module';
@NgModule({
  imports: [
    TranslateModule.forChild(),
    CommonModule,
    FormsModule,
    IonicModule,
    DashboardRoutingModule,
    MatExpansionModule,
    FooterModule,
    CashflowModule,
    GoodsModule,
    MatTabsModule,
    PartnerModule,
    SkeletonLoaderModule,
    EmptyListModule,
    EffectsModule.forFeature([CashflowEffect, OwnerEffect]),
    AnalysisPageModule,
    // BookPageModule
   ],
  providers: [FilterArrayPerfil,    
   ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    CalculetteComponentComponent,
    FilterArrayPerfil,
    AnalysisInputsComponent,
    DashboardComponent,
    ModalCashflowEntryListComponent,
    // UpdateAccessWidgetComponent,
    NewAccessModalComponent,
    DeleteAccountComponent,
    FamilyAccessComponent,
    ProductionPerdidasProductsStatsComponent,
    SummaryComponent,
    CasflowSummaryComponent,
    BankAccountWidgetComponent,
    CardSummaryRegroupedComponent,
    SummaryProductionCostComponent,
    ShortcutsComponent,
    PreProgComponent,
    ProgramsComponent,
    ProgramsModalComponent,
    RatingModalComponent,
    BookComponent,
    DateFilterModalComponent,
    CoffeeSalesComponent,
    AmbassadorCofidentialityComponent,
    CashflowSignleEntrySummaryComponent,
    ProductionLossWidgetComponent,
    CashflowSummaryDiaryComponent,
    OperationsOverviewComponent,
    PensionPlanTrackerComponent,
    PensionPlanUpdatederComponent,
    PensionPlanFormComponent,
    CashflowSelectorComponent,
    ProductionSalesOtherComponent,
    ProductCashflowListItemComponent,
    AccountingEntryCategorieStatsComponent,
    CoffeProductionViewComponent,
    CoffeeTypeSelectorComponent,
    AgricolProductKpisComponent,
    AnimalKpisComponent,
    CoffeeKpisComponent,
    ProductionSalesOtherHeaderComponent,
    LineChartExpensesChartComponent,
    CategoriesSelectionPopoverComponent,
    AnalysisWorkforceComponent,
    AnalysisHarvestComponent,
    AnalysisInsumosBarchartComponent,
    AnalysesFilterComponent,
    WorkdayAnalyseFitlerComponent,
    KpisFiltersComponent,
    InputsFilterComponent,
    DateFilterComponent,
    AnalysisInputsUsageComponent,
    QuestionAnswersComponent,
    AccordionQuestionComponent,
    // HomeFilterComponent
  ],
  exports: [
    CalculetteComponentComponent,
    // AnalysisCashflowComponent,
    KpisFiltersComponent,
    // BookSingleEntryListItemComponent,
    DashboardComponent,
    ModalCashflowEntryListComponent,
    // UpdateAccessWidgetComponent,
    NewAccessModalComponent,
    DeleteAccountComponent,
    FamilyAccessComponent,
    SummaryComponent,
    CasflowSummaryComponent,
    BankAccountWidgetComponent,
    CardSummaryRegroupedComponent,
    SummaryProductionCostComponent,
    ShortcutsComponent,
    PreProgComponent,
    ProgramsComponent,
    ProgramsModalComponent,
    RatingModalComponent,
    BookComponent,
    DateFilterModalComponent,
    CoffeeSalesComponent,
    AmbassadorCofidentialityComponent,
    CashflowSignleEntrySummaryComponent,
    ProductionLossWidgetComponent,
    CashflowSummaryDiaryComponent,
    OperationsOverviewComponent,
    PensionPlanTrackerComponent,
    PensionPlanUpdatederComponent,
    PensionPlanFormComponent,
    CashflowSelectorComponent,
    ProductCashflowListItemComponent,
    AccountingEntryCategorieStatsComponent,
    ProductionPerdidasProductsStatsComponent,
    CoffeProductionViewComponent,
    CoffeeTypeSelectorComponent,
    AnimalKpisComponent,
    AgricolProductKpisComponent,
    CoffeeKpisComponent,
    ProductionSalesOtherHeaderComponent,
    LineChartExpensesChartComponent,
    FooterComponentComponent,
    AnalysisWorkforceComponent,
    AnalysisHarvestComponent,
    AnalysisInputsComponent,
    AnalysisInsumosBarchartComponent,
    AnalysesFilterComponent,
    AnalysisInsumosBarchartComponent,
    WorkdayAnalyseFitlerComponent,
    InputsFilterComponent,
    AnalysisInputsUsageComponent,
  ],
})
export class DashboardModule {}
