import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterModule } from 'src/app/widgets/footer-component/header-footer.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { YoutubeVideoComponent } from './youtube-video/youtube-video.component';



@NgModule({
  declarations: [
    YoutubeVideoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    YoutubeVideoComponent
  ]
})
export class VideosModule { }
