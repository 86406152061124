import { dev } from '../../config/endpoints.json';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';

@Injectable({ providedIn: 'root' })
export class ProductionAPI {
  base_endpoint = environment.appRoot + '/api/';

  constructor(private httpClient: HttpClient) {}

  insertMultipleProduction(productions: Array<ProductionsPerfarm>) {
    const url =
      this.base_endpoint + dev.endpoint.productions.insert_many_productions;
    let formData = new FormData();
    formData.append('productions', JSON.stringify(productions));
    return this.httpClient.post(url, formData).toPromise();
  }

  getProductionByFarmAndType(is_animal) {
    const url =
      this.base_endpoint +
      dev.endpoint.productions.get_production_by_farm_and_type +
      '/' +
      is_animal
    return this.httpClient.get(url);
  }
  deleteFarmProduction(id) {
    const url =
      this.base_endpoint +
      dev.endpoint.productions.delete_farm_production +
      '/' +
      id;
    return this.httpClient.delete(url);
  }
  getAllFarmProduction() {
    const ulr =
      this.base_endpoint + dev.endpoint.productions.get_all_farm_production;

    return this.httpClient.get(ulr);
  }
  updateProduction(productions: ProductionsPerfarm) {
    const url =
      this.base_endpoint + dev.endpoint.productions.update_farm_production;
    let formData = new FormData();
    formData.append('productions', JSON.stringify(productions));
    return this.httpClient.post(url, formData);
  }
}
