import { RoutingConfig } from 'src/app/utils/routing-config';
import { map, switchMap, take } from 'rxjs/operators';
import {
  MORPH_FamilyMember,
  MORPH_Farm,
  MORPH_Owner,
} from './../../../utils/consts';
import { PopoverImgComponent } from './../../../widgets/popover-img/popover-img.component';
import { Component, Input, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../config/offlines_keys.json';
import {
  LoadingController,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { OperationsAPI } from '../../../apis/operations.service';
import Operation from 'src/app/models/operation.model';
import { Family_membersAPI } from '../../../apis/family_members.service';
import { PickerOptionFormater } from '../../../utils/pipes-utils/date_formatter';
import { ToastUtils } from '../../../utils/toast_builder';
import routes from '../../../config/routing.json';
import { Router } from '@angular/router';
import { UserInfo } from 'src/app/apis/users-info.service';
import { TranslateService } from '@ngx-translate/core';
import { OperationRelatedToCashflowBusinessRules } from 'src/app/utils/business-rules/operations_related_to_cashflow_business_rules';
import { lastValueFrom, of } from 'rxjs';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { Store } from '@ngrx/store';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';

@Component({
  selector: 'app-modal-debt',
  templateUrl: './modal-debt.component.html',
  styleUrls: ['./modal-debt.component.scss'],
  providers: [OperationsAPI, Family_membersAPI],
})
export class ModalDebtComponent implements OnInit {
  isDashboardView;
  @Input() accounting_entry_category;
  @Input() cashflow_category_id;
  productions;
  products: any[] = new Array();
  @Input() id: number;
  farm_id;
  qst = '0';
  listf = new Array();
  you = 'Usted';
  currency;
  country;
  currentYear;
  dateString;
  loadinModal;
  operation = new Operation();
  selectedFamilyMember;
  owner;
  selectedSegmentValue;
  selectedCurrency;
  today = new Date().toISOString().split('T')[0];
  customPickerOptions;
  customPickerOptions_debt_due_date;
  monthNames;
  formConfig;
  constructor(
    private family_membersAPI: Family_membersAPI,
    public modalController: ModalController,
    private router: Router,
    private pickerOPtion: PickerOptionFormater,
    public translateService: TranslateService,
    private progressModal: LoadingController,
    public popoverController: PopoverController,
    private operationAPI: OperationsAPI,
    private userInfo: UserInfo,
    private toastUtils: ToastUtils,
    private operationRule: OperationRelatedToCashflowBusinessRules,
    private routingConfig: RoutingConfig,
    private store: Store
  ) {}

  async ngOnInit() {
    let ClassName = 'ModalDebtComponent';
    this.formConfig = await this.routingConfig.getPageFormConfig(ClassName);
    this.products = new Array();
    this.loadinModal = await this.progressModal.create().then((value) => value);
    this.customPickerOptions = this.pickerOPtion.custom_picker_options(
      this.operation,
      'creation_date'
    );
    this.customPickerOptions_debt_due_date =
      this.pickerOPtion.custom_picker_options(this.operation, 'due_date');
    this.currentYear = new Date();
    this.dateString = new Date(
      this.currentYear.getTime() - this.currentYear.getTimezoneOffset() * 60000
    );
    this.dateString = this.dateString.toISOString().split('T')[0];
    this.operation.creation_date = this.dateString;

    this.operation.due_date = this.dateString;
    let farm = await lastValueFrom(this.store.select(getFarm).pipe(take(1)));
    this.farm_id = farm.id;

    if (!this.products || this.products.length == 0)
      this.products = await this.operationRule.setUpOperationReasons(
        this.translateService.currentLang,
        await this.translateService.get('operation_reason').toPromise()
      );
    await this.getowner_id();
    if (!this.operation.id) {
      await this.getcountrie_currency();
      this.operation.currency = this.currency;
    }
    this.operation.owner_id = this.owner.id;
    //updating
    if (this.id != undefined) {
      //recuppérer
      this.operationAPI.getOneOperation(this.id).subscribe((value: any) => {
        this.operation = value;
        if (this.operation.is_interest) {
          this.qst = '1';
        } else {
          this.qst = '0';
        }
        if (this.operation.morphable_type.includes('Owner')) {
          this.selectedFamilyMember = {
            name: this.owner.first_name,
            id: this.owner.id,
          };

          this.selectedSegmentValue = 'FamilyMember';
        } else if (this.operation.morphable_type.includes('Family')) {
          this.selectedFamilyMember = value.morphable;
          this.selectedSegmentValue = 'FamilyMember';
        } else {
          this.selectedSegmentValue = 'Farm';
        }
        if (this.operation.cashflows && this.operation.cashflows.length > 0) {
          let cashflow = this.operation.cashflows.find(
            (value) => value.operation_id == this.operation.id
          );
          this.operation['payment_methods'] = cashflow.payment_methods;
          this.operation['money_reporting_id'] = cashflow.money_reporting_id;
        }
      });
    }

    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
  }

  async getowner_id() {
    this.owner = await this.userInfo.getOwner();
    this.family_membersAPI.getFamilyMembers().subscribe((value) => {
      this.listf = value;
      this.listf.push({
        name: this.owner.first_name,
        id: this.owner.id,
        file: this.owner.file,
        birth_date: this.owner.birth_date,
      });
    });
  }

  async getcountrie_currency() {
    let country=await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    this.currency = country.currency;
  }

  handelChangeCurrecy(value) {
    this.operation.currency = value;
  }
  confirm_method() {
    this.submitForm();
  }

  async showErrorToast(message) {
    await this.toastUtils
      .toastBuilder(
        await this.translateService.instant(message),
        'bottom',
        message,
        'danger'
      );
  }

  async submitForm() {
    if (this.qst === '0') {
      this.operation.is_interest = 0;
      this.operation.interest_amount = 0;
    }
    const token = await Storage.get({ key: dev.ACCESS_TOKEN }).then(
      (value) => value.value
    );
    if (this.selectedSegmentValue == 'Farm') {
      this.operation.morphable_type = MORPH_Farm;
      this.operation.morphable_id = this.farm_id;
    }
    if (!this.operation.amount || this.operation.amount == 0) {
      this.showErrorToast('errors.all_fields');
    } else if (!this.operation.creation_date) {
      this.showErrorToast('errors.all_fields');
    } else if (!this.operation.morphable_type) {
      this.showErrorToast('errors.all_fields');
    } else if (
      this.operation.morphable_type === MORPH_FamilyMember &&
      !this.operation.morphable_id
    ) {
      this.showErrorToast('errors.all_fields');
    } else if (!this.operation.operation_reason) {
      this.showErrorToast('errors.all_fields');
    } else if (!this.operation.third_part_name) {
      this.showErrorToast('errors.all_fields');
    } else if (!this.operation.due_date) {
      this.showErrorToast('errors.all_fields');
    } else if (!this.qst) {
      this.showErrorToast('errors.all_fields');
    } else if (this.qst === '1' && !this.operation.interest_amount) {
      this.showErrorToast('errors.all_fields');
    } else if (
      this.qst === '1' &&
      this.operation.interest_amount != undefined &&
      (this.operation.interest_amount < 0 ||
        this.operation.interest_amount > 100)
    ) {
      this.showErrorToast('errors.all_fields');
    } else {
      if (this.selectedSegmentValue == 'Farm') {
        this.operation.morphable_type == MORPH_Farm;
        this.operation.morphable_id = this.farm_id;
      }
      this.operation.operation_type = 'debt';

      if (!this.operation.interest_amount) {
        this.operation.is_interest = 0;
        this.operation.interest_amount = 0;
      } else {
        this.operation.is_interest = 1;
      }
      this.loadinModal.present();
      if (this.operation.id) {
        this.operationAPI.updateOperation(this.operation, this.id).subscribe(
          async (value) => {
            value.payment_methods = this.operation['payment_methods'];
            value.money_reporting_id = this.operation['money_reporting_id'];
            await this.operationRule.updateCashflowAfterOperationUpdate(
              value,
              this.modalController,
              this.loadinModal,
              this.popoverController,
              null,
              this.isDashboardView,
              this.products
            );
            await this.operationRule.updateCashflowAfterOperationUpdate(
              value,
              this.modalController,
              this.loadinModal,
              this.popoverController,
              null,
              this.isDashboardView,
              this.products
            );
            this.loadinModal.dismiss();
            this.modalController.dismiss();
          },
          (e) => {
            this.loadinModal.dismiss();
            this.modalController.dismiss();
            console.log(e);
          }
        );
      } else {
        this.operationAPI
          .storeOperation(this.operation)
          .pipe(
            take(1),
            map((value: any) => {
              value.payment_methods = this.operation['payment_methods'];
              value.money_reporting_id = this.operation['money_reporting_id'];
              return value;
            }),
            switchMap((value) => {
              return of(
                this.operationRule.saveOperationCashflow(
                  value,
                  this.farm_id,
                  this.loadinModal,
                  this.modalController,
                  !this.isDashboardView,
                  this.popoverController,
                  null,
                  this.isDashboardView,
                  this.products
                )
              );
            }),
            map((value) => value)
          )
          .subscribe(
            (value) => value,
            (e) => {
              this.loadinModal.dismiss();
              this.modalController.dismiss();
            }
          );
      }
    }
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  setAmount(amount) {
    this.operation.amount = amount;
  }

  async familyMemberSelectedChange(ev) {
    let componentProps = {
      data: this.listf,
      component: 'mano-de-obra',
    };
    const popover = await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverImgComponent,
      'pop-over-style',
      ev
    );
    const { data } = await popover.onDidDismiss();
    if (data) {
      this.selectedFamilyMember = data.data;
      if (this.selectedFamilyMember.name === this.owner.first_name) {
        this.operation.morphable_type = MORPH_Owner;
      } else {
        this.operation.morphable_type = MORPH_FamilyMember;
      }

      this.operation.morphable_id = this.selectedFamilyMember.id;
    }
  }

  compareWith(e, e2) {
    return e && e2 ? e?.name === e2?.name : e === e2;
  }

  setPaidAmount(event) {
    this.operation.paid_so_far = event;
  }

  pop() {
    const perso = this.products.indexOf(
      (element) => element.value == 'personal'
    );
    if (!isNaN(perso)) this.products.splice(perso, 1);
    this.operation.operation_reason = null;
  }

  splice() {
    const perso = this.products.find((element) => element.value === 'personal');
    if (!perso) this.products.push({ label: 'Personal', value: 'personal' });

    this.operation.operation_reason = null;
  }
}
