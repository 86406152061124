import { createAction, props } from "@ngrx/store";
import { Countrie } from "src/app/models/countrie.model";

export const LOAD_TOTAL_ACCOUNTING_CATEGORIES = 'LOAD_TOTAL_ACCOUNTING_CATEGORIES';
export const LOAD_TOTAL_ACCOUNTING_CATEGORIES_SUCCESS = 'LOAD_TOTAL_ACCOUNTING_CATEGORIES_SUCCESS';
export const LOAD_STATISTICS_DATA = 'LOAD_STATISTICS_DATA';
export const LOAD_STATISTICS_DATA_SUCCESS = 'LOAD_STATISTICS_DATA_SUCCESS';
export const LOAD_HARVEST_SUMMARY = 'LOAD_HARVEST_SUMMARY';
export const LOAD_HARVEST_SUMMARY_SUCCESS = 'LOAD_HARVEST_SUMMARY_SUCCESS';
export const LOAD_INSUMOS_BOUGHT = 'LOAD_INSUMOS_BOUGHT';
export const LOAD_INSUMOS_BOUGHT_SUCCESS = 'LOAD_INSUMOS_BOUGHT_SUCCESS';
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const DASHBOARD_DATA_LOADED = 'DASHBOARD_DATA_LOADED';
export const DASHBOARD_DATA_ERROR = 'DASHBOARD_DATA_ERROR';
export const LOAD_WORKFROCE_STATS = 'LOAD_WORKFROCE_STATS';
export const LOAD_WORKFROCE_STATS_SUCCESS = 'LOAD_WORKFROCE_STATS_SUCCESS';

export const loadTotalAccountingCategories = createAction(
  LOAD_TOTAL_ACCOUNTING_CATEGORIES,
  props<{ data: any }>()
);
export const loadStatisticsData = createAction(
  LOAD_STATISTICS_DATA,
  props<{ data: any }>()
);
export const loadStatisticsDataSuccess = createAction(
  LOAD_STATISTICS_DATA_SUCCESS,
  props<{ data: any }>()
);

  export const loadTotalAccountingCategoriesSuccess = createAction(
    LOAD_TOTAL_ACCOUNTING_CATEGORIES_SUCCESS,
    props<{ totalsAccountingCategories: Array<any> }>()
  );

  export const loadHarvestSummary= createAction(
    LOAD_HARVEST_SUMMARY,
    props<{ data: any }>()
  );
  export const loadHarvestSummarySuccess = createAction(
    LOAD_HARVEST_SUMMARY_SUCCESS,
    props<{ harvestSummary: Array<any> }>()
  );
  export const loadWorkForceStats= createAction(
    LOAD_WORKFROCE_STATS,
    props<{ data: any }>()
  );
  export const loadWorkForceStatsSuccess = createAction(
    LOAD_WORKFROCE_STATS_SUCCESS,
    props<{ workForceStats: Array<any> }>()
  );
  export const loadInsumosBought= createAction(
    LOAD_INSUMOS_BOUGHT,
    props<{ data: any }>()
  );
  export const loadInsumosBoughtSuccess = createAction(
    LOAD_INSUMOS_BOUGHT_SUCCESS,
    props<{ insumosBought: Array<any> }>()
  );
  export const getDashboardData = createAction(GET_DASHBOARD_DATA,
    props<{ data: any }>());
  export const dashboardDataLoaded = createAction(DASHBOARD_DATA_LOADED,
    props<{ data: any }>());
  export const dashboardDataError = createAction(DASHBOARD_DATA_ERROR,
    props<{ error: any }>());
