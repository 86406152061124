import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { from, Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { CashflowAPI } from 'src/app/apis/cashflow.service';
import { EmployeesAPI } from 'src/app/apis/employees.service';
import { ProductionAPI } from 'src/app/apis/FarmAPI/productions.service';
import { GoodsAPI } from 'src/app/apis/goods.service';
import { LotAPI } from 'src/app/apis/lot.service';
import { MoneyReportingsAPI } from 'src/app/apis/money_reportings.service';
import { ObjectiveApi } from 'src/app/apis/objectives.service';
import { OwnerAPI } from 'src/app/apis/owners.service';
import { UserInfo } from 'src/app/apis/users-info.service';
import {
  CashFlow,
  isArbolesTransaction,
} from 'src/app/models/cash-flow/cashFlow';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';
import { Good } from 'src/app/models/good.model';
import MoneyReporting from 'src/app/models/money_reporting.model';
import {
  AHOROS_ACCOUNTING_ENTRY_CATEGORY,
  CAFE_ACCOUNTING_ENTRY_CATEGORY,
  
  CASHFLOW_FIRST_SALE_COFFEE_ID,
  
  LOT_TRANSACTION_CASHFLOW_CATEGORIES,
  MORPH_Lot,
  OTROS_ACCOUNTING_ENTRY_CATEGORY,
  PERDIDA_ARBOLES_CASHFLOW_CATEGORIES,
  PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY,
  PRODUCT_MORPHABLE,
  PROD_IDS,
  VENTA_ARBOLES_CASHFLOW_CATEGORIES,
  VENTA_ARBOLES_COFFE_CASHFLOW_CATEGORIES,
} from '../consts';
import { handleResponse } from '../user_data_storage';
import { OperationRelatedToCashflowBusinessRules } from './operations_related_to_cashflow_business_rules';
import { updateGoodAfterCashflowSubmitOtrosProducts } from './post_submit_cashflow_goods_business';
import { SalaryBusinessRules } from './salary-related-cashflow';
import Owner from 'src/app/models/owner.model';

@Injectable()
export class CashflowPriorApiTreatment {
  constructor(
    private cashflowAPI: CashflowAPI,
    private operationRelatedToCashflowBusinessRules: OperationRelatedToCashflowBusinessRules,
    private employeeAPI: EmployeesAPI,
    private goodAPIs: GoodsAPI,
    private productionAPI: ProductionAPI,
    private objectiveAPI: ObjectiveApi,
    private moneyReportingAPI: MoneyReportingsAPI,
    private lotAPI: LotAPI,
    private ownerAPI: OwnerAPI,
    private modalController: ModalController,
    private salaryRules: SalaryBusinessRules,
    private userInfo: UserInfo,
    private store: Store<{ owner: Owner }>,

  ) {}

  updateCashflow(
    componentCashflow: CashFlow,
    secondCellCertif: any = undefined,
    selected_production: any = undefined,
    savingObjective: any = undefined,
    pensionsPanls: Array<MoneyReporting> = undefined,
    parsedFormulaireRequirement: any = null
  ) {
    if (secondCellCertif) secondCellCertif.cash_flow_id = componentCashflow.id;

    return this.cashflowAPI.updateCashFlow(componentCashflow).pipe(
      map((cashflow: CashFlow) => cashflow),
      take(1),
      // check value is returned from server after update
      filter((cashflow: CashFlow) => cashflow.id),
      //Cashflow has operation linked to it
      switchMap((cashflow: CashFlow) =>
        cashflow.operation_id
          ? this.operationRelatedToCashflowBusinessRules.updateOperationAfterCashflowUpdate(
              cashflow
            )
          : of(componentCashflow)
      ),
      // Cashflow Has salary attached to it
      switchMap((cashFlow: CashFlow) =>
        componentCashflow.morphable_type &&
        componentCashflow.morphable_type.toLowerCase().includes('salarie')
          ? this.employeeAPI.updateSalary(cashFlow.salary)
          : of(cashFlow)
      ),
      // Cashflow is related to good production
      switchMap((cashFlow: CashFlow) => {
        return PROD_IDS.includes(componentCashflow.cash_flow_categorie_id)
          ? updateGoodAfterCashflowSubmitOtrosProducts(
              componentCashflow,
              this.goodAPIs,
              componentCashflow.production_per_farm
            )
          : of(cashFlow);
      }),
      // Cashflow is related to a coffe second sell
      // switchMap((cashFlow: CashFlow) =>
      //   (componentCashflow.certifications &&
      //     componentCashflow['selectedCategory'] == 14) ||
      //   (componentCashflow.certifications &&
      //     componentCashflow['selectedCategory'] == 13)
      //     ? this.cashflowAPI.saveCertification(secondCellCertif)
      //     : of(cashFlow)
      // ),
      // cashflow is related to tree update
      switchMap((cashflow: CashFlow) => {
        if (isArbolesTransaction(cashflow)) {
          if (
            [
              VENTA_ARBOLES_COFFE_CASHFLOW_CATEGORIES,
              PERDIDA_ARBOLES_CASHFLOW_CATEGORIES,
              VENTA_ARBOLES_CASHFLOW_CATEGORIES,
            ].includes(cashflow.cash_flow_categorie_id)
          ) {
            cashflow.production_per_farm.trees_numbers -=
              cashflow.quantity - cashflow.initial_value;
          } else
            cashflow.production_per_farm.trees_numbers +=
              cashflow.quantity - cashflow.initial_value;

          return this.productionAPI.updateProduction(
            cashflow.production_per_farm
          );
        } else return of(cashflow);
      }),
      // Cashflow related to product or material prodcut agricols production or loss
      switchMap((cashflow: CashFlow) => {
        return componentCashflow.modelBeingUpdated &&
          cashflow.quantity &&
          cashflow.unity &&
          (cashflow.product_id || cashflow.material_product_id)
          ? updateGoodAfterCashflowSubmitOtrosProducts(
              componentCashflow,
              this.goodAPIs,
              selected_production
            )
          : of(cashflow);
      }),
      // Ahoros update
      switchMap((cashflow: CashFlow) => {
        if (
          cashflow.accounting_entries_categories ===
          AHOROS_ACCOUNTING_ENTRY_CATEGORY
        ) {
          if (!parsedFormulaireRequirement && savingObjective) {
            savingObjective.gather_so_far +=
              parseFloat(cashflow.amount) - cashflow.initial_value;
            return this.objectiveAPI.updateObjective(savingObjective);
          } else {
            let moneyReporting;
            if (Array.isArray(pensionsPanls)) {
              if (
                parsedFormulaireRequirement.name &&
                parsedFormulaireRequirement.name.includes('gov pension plan')
              )
                moneyReporting = pensionsPanls.find(
                  (element) => element.type === 'pension_plan_gov'
                );
              else
                moneyReporting = pensionsPanls.find(
                  (element) => element.type === 'pension_plan'
                );
            }
            if (moneyReporting) {
              moneyReporting.amount =
                moneyReporting.amount -
                componentCashflow.initial_value +
                componentCashflow.amount;
              return this.moneyReportingAPI.updateMoneyReporting(
                moneyReporting
              );
            }
          }
        }
        return of(cashflow);
      }),
      // Resolve result
      map((anyValue: any) => anyValue)
    );
  }

  saveNewCashflow(
    cashflow: CashFlow,
    secondSellCertif = null
  ): Observable<any> {
    // cashflow related to seconde sell of coffee
    if (
      cashflow['selectedCategory'] == 14 ||
      cashflow['selectedCategory'] == 13
    ) {
      return this.cashflowAPI.saveCashFlow(cashflow).pipe(
        // switchMap((serverData: CashFlow) => {
        //   secondSellCertif.cash_flow_id = serverData.id;
        //   return this.cashflowAPI.saveCertification(secondSellCertif);
        // }),
        map((value: any) => {
          return value;
        })
      );
    } else if (cashflow.new_operation) {
      // Cashflow related to new operation (money loan, debt , caution)
      return this.operationRelatedToCashflowBusinessRules.insertCashflowAndOperation(
        cashflow
      );
    } else if (cashflow.salary) {
      // Cashflow related to salary payment
      return this.salaryRules.saveSalaryPriorTocashflow(cashflow);
    } else if (cashflow.operation_id) {
      // Cashflow related to debt, money loan , caution payment
      return this.cashflowAPI.saveCashFlow(cashflow).pipe(
        switchMap((cashflow: CashFlow) =>
          this.operationRelatedToCashflowBusinessRules.updateOperationAfterCashflowUpdate(
            cashflow
          )
        ),
        map((value) => of(value))
      );
    } else if (isArbolesTransaction(cashflow)) {
      // Cashflow related to Trees
      return this.cashflowAPI.saveCashFlow(cashflow).pipe(
        map((cashflow: CashFlow) => cashflow),
        take(1),
        switchMap((value) => {
          if (
            [
              VENTA_ARBOLES_COFFE_CASHFLOW_CATEGORIES,
              PERDIDA_ARBOLES_CASHFLOW_CATEGORIES,
              VENTA_ARBOLES_CASHFLOW_CATEGORIES,
            ].includes(cashflow.cash_flow_categorie_id)
          ) {
            cashflow.production_per_farm.trees_numbers -=
              cashflow.quantity - cashflow.initial_value;
          } else
            cashflow.production_per_farm.trees_numbers +=
              cashflow.quantity - cashflow.initial_value;

          return this.productionAPI.updateProduction(
            cashflow.production_per_farm
          );
        })
      );
    } else if (
      // cashflow related to goods table
      !LOT_TRANSACTION_CASHFLOW_CATEGORIES.includes(
        cashflow.cash_flow_categorie_id
      ) &&
      (CASHFLOW_FIRST_SALE_COFFEE_ID.includes(cashflow.cash_flow_categorie_id) ||
        PROD_IDS.includes(cashflow.cash_flow_categorie_id) ||
        cashflow.product_id ||
        cashflow.material_product_id)
    ) {
      return this.cashflowAPI.saveCashFlow(cashflow).pipe(
        map((serverData: CashFlow) => serverData),
        switchMap((serverData: CashFlow) => {
          if (
            (cashflow.modelBeingUpdated && cashflow.modelBeingUpdated.id) ||
            cashflow.accounting_entries_categories ==
              OTROS_ACCOUNTING_ENTRY_CATEGORY
          ) {
            return updateGoodAfterCashflowSubmitOtrosProducts(
              cashflow,
              this.goodAPIs,
              cashflow?.production_per_farm
            );
          } else if (
            cashflow.accounting_entries_categories ===
            CAFE_ACCOUNTING_ENTRY_CATEGORY
          ) {
            this.updateOwnerConfig()
            return this.initNewProductionPerFarm(cashflow);
          } else if (
            cashflow.accounting_entries_categories ==
            PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY
          ) {
            return updateGoodAfterCashflowSubmitOtrosProducts(
              cashflow,
              this.goodAPIs,
              null
            );
          } else return of(cashflow);
        }),
        switchMap((value) => {
          if (
            !CASHFLOW_FIRST_SALE_COFFEE_ID.includes(cashflow.cash_flow_categorie_id) &&
            cashflow.product_id != 16 &&
            cashflow.accounting_entries_categories ===
              CAFE_ACCOUNTING_ENTRY_CATEGORY
          ) {
            if (cashflow.usedQty.id) {
              cashflow.modelBeingUpdated = cashflow.usedQty;
              cashflow.modelBeingUpdated.qty_update =
                cashflow.modelBeingUpdated.qty;
              return updateGoodAfterCashflowSubmitOtrosProducts(
                cashflow,
                this.goodAPIs,
                null
              );
            } else if (
              cashflow.accounting_entries_categories ===
              CAFE_ACCOUNTING_ENTRY_CATEGORY
            ) {
              cashflow.quantity = cashflow.usedQty.qty;
              cashflow.product_id = 16;
              return this.initNewProductionPerFarm(cashflow);
            }
          } else return of(value);
        })
      );
    } else if (
      cashflow.morphable_type == MORPH_Lot &&
      cashflow.morphable_id &&
      cashflow.is_income
    ) {
      return this.cashflowAPI.saveCashFlow(cashflow).pipe(
        map((cashflow) => cashflow),
        switchMap((cashflow) => this.lotAPI.sellLot(cashflow.morphable_id)),
        map((val) => {
          return val;
        }),
        switchMap((lot) => this.ownerAPI.getOwnerById()),
        map((response) => response),
        switchMap((ownerByIdResponse) => {
          return from(handleResponse(ownerByIdResponse));
        }),
        map((val) => {
          val;
        })
      );
    }

    // Cashflow with no third model impact
    else
      return this.cashflowAPI
        .saveCashFlow(cashflow)
        .pipe(map((cashflow) => cashflow));
  }
  async updateOwnerConfig(){
   let owner= this.store.pipe(take(1)).subscribe(s => s);

  }
  async initNewProductionPerFarm(cashflow: CashFlow) {
    let production = new ProductionsPerfarm();
    production.farm_id = cashflow.farm_id;
    production.is_animal = 0;
    production.is_crop = 1;
    production.morphable_id = cashflow.product_id;
    production.trees_numbers = cashflow.product_id === 16 ? 0 : -1;
    production.morphable_type = PRODUCT_MORPHABLE;
    production.goods = new Array();
    let good = new Good();
    // good.unit = cashflow.unity;
    const owner = await this.userInfo.getOwner();
    good.owner_id = owner?.id;
    good.product_id = cashflow.product_id;
    good.qty = cashflow.quantity;
    production.goods.push(good);
    return from(this.productionAPI.insertMultipleProduction([production]));
  }
}
