import { HttpClient } from '@angular/common/http';
import { dev } from '../config/endpoints.json';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { KPI } from '../modules/dashboard/kpis/coffee-kpis/coffee-kpis.component';
import { Observable } from 'rxjs';

@Injectable()
export class StatisticsAPI {
  base_url = environment.appRoot + '/api/';
  headers;
  constructor(private http: HttpClient) {}
  fetchStatsData(
    starting_date,
    ending_date,
    groupeBy
  ) {
    const url =
      this.base_url +
      dev.endpoint.statistics.fetch_statistics_data +
      '/' +
      starting_date.split('T')[0] +
      '/' +
      ending_date.split('T')[0]+
      '/' +
      groupeBy

    return this.http.get(url);
  }
  fetchStatsByCashflowCategories(
    starting_date,
    ending_date,
    accounting_entry_category
  ) {
    const url =
      this.base_url +
      dev.endpoint.statistics.fetch_stats_by_cashflow_categories +
      '/' +
      starting_date.split('T')[0] +
      '/' +
      ending_date.split('T')[0] +
      '/' +
      accounting_entry_category 

    return this.http.get(url);
  }

  fetchStatsByMorphable(
    starting_date,
    ending_date,
    accounting_entry_category,
    is_income
  ) {
    const url =
      this.base_url +
      dev.endpoint.statistics.fetch_staty_by_cashflow_morphable +
      '/' +
      starting_date.split('T')[0] +
      '/' +
      ending_date.split('T')[0] +
      '/' +
      accounting_entry_category +
      '/' +
      is_income;
    return this.http.get(url);
  }

  getProductKpis(from, to) {
    const url =
      this.base_url +
      dev.endpoint.statistics.get_product_kpis +
      '/' +
      from.split('T')[0] +
      '/' +
      to.split('T')[0];
    return this.http.get(url);
  }
  getMaterialOroductKpis(from, to) {
    const url =
      this.base_url +
      dev.endpoint.statistics.get_material_product_kpis +
      '/' +
      from.split('T')[0] +
      '/' +
      to.split('T')[0];
    return this.http.get(url);
  }
  getCafeCerezKpi(from, to, accountingId: number): Observable<Array<KPI>> {
    const url =
      this.base_url +
      dev.endpoint.statistics.get_cafe_cerez_kpi +
      '/' +
      from.split('T')[0] +
      '/' +
      to.split('T')[0] +
      '/' +
      accountingId;
    return this.http.get<Array<KPI>>(url);
  }
  getMaterialProductStats(start_date, end_date, accounting, is_income) {
    const url =
      this.base_url +
      dev.endpoint.statistics.get_material_product_stats +
      '/' +
      start_date.split('T')[0] +
      '/' +
      end_date.split('T')[0] +
      '/' +
      accounting +
      '/' +
      is_income;
    return this.http.get(url);
  }
  getCashflowProductionPerdidasByProduct(product_id, is_animal, lang) {
    const url =
      this.base_url +
      dev.endpoint.statistics.get_cashflow_production_perdidas_by_product +
      '/' +
      product_id +
      '/' +
      is_animal;
    return this.http.get(url);
  }
  getCashflowByExpenses(
    accounting_entry_category,
    from,
    to,
    lang,
    activity_ids = []
  ) {
    const url =
      this.base_url +
      dev.endpoint.statistics.get_cashflow_by_expenses +
      '/' +
      accounting_entry_category +
      '/' +
      from.split('T')[0] +
      '/' +
      to.split('T')[0] +
      '/' +
      lang;
    return this.http.get(url);
  }

  fetchGlobalOverviewPerAccountingEntryCategories(
    accounting_categorie_id: Array<number>,
    from: string,
    to: string,
    monthly_yearly: string
  ) {
    const ids_params = JSON.stringify(accounting_categorie_id);
    const url =
      this.base_url +
      dev.endpoint.statistics
        .fetch_global_overview_per_accounting_entry_categorie +
      '/' +
      from.split('T')[0] +
      '/' +
      to.split('T')[0] +
      '/' +
      ids_params +
      '/' +
      monthly_yearly;
    return this.http.get(url);
  }

  fetchWorkForceStats(data) {
    let {
      start_date,
      end_date,
      accountingEntries,
      cashflowCategories,
      cashflowExpenses,
      familial_filter,
      permanent_filter,
      temporal_filter,
      per_day,
      per_harvested,
      per_month,
      per_kind,
      per_no_payment,
    } = data;
    const url =
      this.base_url +
      dev.endpoint.statistics.workforce_stats +
      '/' +
      start_date.split('T')[0] +
      '/' +
      end_date.split('T')[0] +
      '/' +
      JSON.stringify(accountingEntries) +
      '/' +
      JSON.stringify(cashflowCategories) +
      '/' +
      JSON.stringify(cashflowExpenses) +
      '/' +
      familial_filter +
      '/' +
      permanent_filter +
      '/' +
      temporal_filter +
      '/' +
      per_day +
      '/' +
      per_harvested +
      '/' +
      per_month +
      '/' +
      per_kind +
      '/' +
      per_no_payment;

    return this.http.get(url);
  }

  harvestSummary(
    start_date: string,
    end_date: string,
    accountingEntryCateogry: number
  ) {
    const url =
      this.base_url +
      dev.endpoint.statistics.harvesting_statistics +
      '/' +
      start_date.split('T')[0] +
      '/' +
      end_date.split('T')[0] +
      '/' +
      accountingEntryCateogry;

    return this.http.get(url);
  }

  insumosBought(data) {
    let { start_date, end_date, accounting_entry, cashflowExpenses } = data;
    const url =
      this.base_url +
      dev.endpoint.statistics.insumos_bought +
      '/' +
      start_date.split('T')[0] +
      '/' +
      end_date.split('T')[0] +
      '/' +
      accounting_entry +
      '/' +
      JSON.stringify(cashflowExpenses);
    return this.http.get(url);
  }

  insumosUsage(
    start_date: string,
    end_date: string,
    expenses_id: number,
    accountingEntryID: number
  ) {
    const url =
      this.base_url +
      dev.endpoint.statistics.insumos_usage +
      '/' +
      start_date.split('T')[0] +
      '/' +
      end_date.split('T')[0] +
      '/' +
      expenses_id +
      '/' +
      accountingEntryID;
    return this.http.get(url);
  }

  getInputsDetails(
    statrtDate: string,
    endDate: string,
    expenseId: number,
    unitType: string,
    accountingEntryID: number
  ) {
    let formData = new FormData();
    formData.append('start_date', statrtDate);
    formData.append('end_date', endDate);
    formData.append('expensesId', expenseId.toString());
    formData.append('unityType', unitType);
    formData.append('accountingEntryID', accountingEntryID.toString());
    return this.http.post(
      this.base_url + dev.endpoint.statistics.get_inputs_details,
      formData
    );
  }
}
