import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TeamModalPage } from 'src/app/modules/team/team-modal/team-modal.page';
import { EmployeesAPI } from '../../../apis/employees.service';
import routes from '../../../config/routing.json';
import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import { PopoverController } from '@ionic/angular';
import { PriorDashboardComponent } from '../../prior-dashboard/prior-dashboard.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-team-temporel',
  templateUrl: './team-temporel.component.html',
  styleUrls: ['./team-temporel.component.scss'],
})
export class TeamTemporelComponent implements OnInit {
  farm;
  @Input() temporel = [];
  submit_label;
  @Input() isDashboardView;
  @Input() rootStack;
  @Input() nav;

  main_endpoint = environment.appRoot;

  @Output() hasTemptEvent: EventEmitter<Boolean> = new EventEmitter();
  constructor(
    public modalController: ModalController,
    public empAPI: EmployeesAPI,
    private popover: PopoverController,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private router: Router
  ) {}

  async ngOnInit() {
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
    if (this.isDashboardView) this.fetch();
  }
  async showPopOver() {
    const popover = await this.popover.create({
      component: PopoverComponent,
      cssClass: 'popover-custom',
      animated: true,
      componentProps: {
        key1: await this.translateService
          .get('screens.equipe_temporal.info_bulle')
          .toPromise(),
      },
    });
    popover.present();
  }

  fetch() {
    this.empAPI.getPermanentOrTomporalData().subscribe((value: any) => {
      if (Array.isArray(value))
        this.temporel = value.filter(
          (element) => !element.morphable_type && !element.is_permanent
        );
    });
  }

  async deleteEmployee(event) {
    // await this.empAPI.deleteEmployee(event).toPromise();
    this.fetch();
  }

  async openModal(val, action) {
    let componentProps = {
      componentSource: 'temporal',
      action: action,
      paramID: val, //null if user is employee
      paramTitle: await this.translateService
        .get('screens.equipe_temporal.header_modal_temporel')
        .toPromise(),
    };
    let modal = await this.toastUtils.modalBuilder(
      TeamModalPage,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper']
    );

    modal.onDidDismiss().then((dataReturned) => {
      const { data } = dataReturned;

      if (data.role == 'store') {
        if (!this.temporel) this.temporel = new Array();
        this.temporel.push(data.value);
      } else if (data.role == 'update') {
        let i = this.temporel.findIndex((value) => value.id == data.value.id);
        this.temporel[i] = data.value;
      }
    });
  }

  goBackToDashBoard() {
    this.nav.setRoot(this.rootStack, {
      nav: this.nav,
      rootStack: PriorDashboardComponent,
    });
  }
}
