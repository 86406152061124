import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input() input;
  @Input() secondObjectVariable;
  @Input() key = 'name';
  @Input() enableDelete = false;
  @Input() enableSelection = true;
  @Input() allProduct = false;
  @Input() is_animal = false;
  @Input() is_selector = false;
  @Output() deleteClicked: EventEmitter<any> = new EventEmitter();
  categorie;
  other_selected = false;
  base_url = environment.appRoot;
  constructor(public translateService: TranslateService) {}

  ngOnInit() {
    if (this.input?.material_product && !this.allProduct) {
      this.categorie = this.input?.material_product;
    } else if (this.input?.morphable && !this.allProduct) {
      this.categorie = this.input?.morphable;
    } else {
      this.categorie = this.input;
    }

  }

  selectProduct(product) {
    product.is_checked = !product.is_checked;
    if (product.is_checked && product.id == 15) {
      this.other_selected = true;
    } else if (!product.is_checked && product.id == 15) {
      this.other_selected = false;
    }
  }
  handelDelete(categorie) {
 
    this.deleteClicked.emit(this.input);


  }
  getCategorieName(categorie) {
    var label = '';

    if (this.allProduct && categorie?.morphable_type && categorie?.id) {
      if (categorie?.morphable_type == 'App\\Models\\Product') {
        label = 'product.' + categorie?.morphable_id;
      } else if (
        categorie?.morphable_type == 'App\\Models\\MaterialCategorie'
      ) {
        label = 'material_category.' + categorie?.morphable_id;
      }
    } else {
      var selector = categorie?.id ? categorie?.id : categorie?.product_id;
      this.is_animal
        ? (label = this.is_selector
            ? 'material_category.' + categorie?.id
            : 'material_products.' + categorie?.id)
        : (label = 'product.' + selector);
    }
    return this.translateService.instant(label);
  }
  getCategorieLength(categorie) {
    return this.getCategorieName(categorie)?.length;
  }
}
