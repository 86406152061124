import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-perons-data-screens',
  templateUrl: './perons-data-screens.component.html',
  styleUrls: ['./perons-data-screens.component.scss'],
})
export class PeronsDataScreensComponent implements OnInit {
  is_personal_data_view = true;
  is_image_view = !this.is_personal_data_view;

  constructor() {}

  ngOnInit() {}
}
