import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss'],
})
export class EmptyListComponent implements OnInit {

  @Input() isSearchResult: boolean=false;
  @Input() headerLabel: string='empty_list_header';
  @Input() bodyLabel: string='empty_list_body';
  @Input() iconName: string;
  urlIcon
  constructor() {

   }

  ngOnInit() {
    if(this.iconName){
      this.urlIcon = "/assets/icon/app-icon/"+this.iconName+".svg"
    }else{  
      this.urlIcon = "/assets/icon/app-icon/icn-loop.svg"

    }
  }

}
