import { Store } from '@ngrx/store';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IonNav, ModalController, PopoverController } from '@ionic/angular';
import { BookComponent } from '../cashflow/book/book.component';
import { TranslateService } from '@ngx-translate/core';
import { UnifiedCashflowInputComponentComponent } from '../../cashflow/unified-cashflow-input-component/unified-cashflow-input-component.component';
import { orderAlphabaticallyByAttribute } from 'src/app/utils/array-filtrer';
import Owner from 'src/app/models/owner.model';
import { AccountingEntryCategory } from 'src/app/models/cash-flow/cashflow_categories';
import { accountingEntriesLoading } from 'src/app/utils/states-management/actions/accountings-entries.action';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { getTotalsAccountingCategoriesResult } from 'src/app/utils/states-management/selector/dashboard.selector';
import { getAccountingCategories } from 'src/app/utils/states-management/reducers/accounting-entries.reducer';
import { loadTotalAccountingCategories } from 'src/app/utils/states-management/actions/dashboard.action';

@Component({
  selector: 'app-modal-cashflow-entry-list',
  templateUrl: './modal-cashflow-entry-list.component.html',
  styleUrls: ['./modal-cashflow-entry-list.component.scss'],
})
export class ModalCashflowEntryListComponent implements OnInit, OnDestroy {
  @Input() nav: IonNav;
  @Input() modalController: PopoverController;
  accountingEntriesCategories = [];
  profile_info;
  country_id: number | undefined;
  loading: boolean = false;
  subscriptions: Array<Subscription> = [];
  constructor(
    private secondaryModalController: ModalController,
    public translateService: TranslateService,
    private store: Store<{
      owner: Owner;
      accountingEntries: Array<AccountingEntryCategory>;
    }>
  ) {}

  ngOnInit() {
    this.loading = true;
    this.subscriptions.push(
      this.store.select('owner').subscribe((owner: Owner) => {
        this.profile_info = JSON.parse(owner.profile_info);
        this.country_id = owner.country_id;
      })
    );
    this.subscriptions.push(
      this.store
        .select(getTotalsAccountingCategoriesResult)
        .subscribe(({ data }) => {
          if (data.length == 0 && this.country_id) {
            this.store.dispatch(loadTotalAccountingCategories({ data: {} }));
          } else {
            this.loading = false;

            var accountingEntriesCategories = [];

            data.forEach((element) => {
              if (element?.morphable_type && element?.id) {
                let cropExist = this.profile_info.cropConfig.find(
                  (item) =>
                    item.id == element.morphable_id &&
                    element.morphable_type.includes(item.morph)
                );
                if (cropExist) {
                  accountingEntriesCategories.push(element);
                }
              } else {
                accountingEntriesCategories.push(element);
              }
            });
            this.accountingEntriesCategories = accountingEntriesCategories;
            this.orderAccountings();
            // this.accountingEntriesCategories = orderAlphabaticallyByAttribute(
            //   accountingEntriesCategories,
            //   'title',
            //   this.translateService.currentLang
            // );
          }
        })
    );
  }

  close() {
    this.modalController.dismiss();
  }

  async presentModal(cashFlowEntrieName) {
    const modal = await this.secondaryModalController.create({
      component: UnifiedCashflowInputComponentComponent,
      cssClass: ['my-custom-modal-css', 'modal-wrapper'],
      componentProps: {
        accountingEntryCategories: cloneDeep(cashFlowEntrieName),
        modalController: this.secondaryModalController,
        nav: this.nav,
      },
      id: '1',
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    if (this.nav) {
      if (data?.navigateTo) {
        this.nav.setRoot(data.navigateTo, { nav: this.nav });
      } else {
        this.nav.setRoot(BookComponent, { nav: this.nav });
      }
    }
    this.modalController.dismiss();
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((element: Subscription) =>
      element.unsubscribe()
    );
  }

  orderAccountings() {
    this.accountingEntriesCategories.sort((a, b) => {
      return this.translateService
        .instant('accounting_entries.' + a.id)
        .normalize('NFD')
        .toLowerCase() ===
        this.translateService
          .instant('accounting_entries.' + b.id)
          .normalize('NFD')
          .toLowerCase()
        ? 0
        : this.translateService
            .instant('accounting_entries.' + a.id)
            .normalize('NFD')
            .toLowerCase() <
          this.translateService
            .instant('accounting_entries.' + b.id)
            .normalize('NFD')
            .toLowerCase()
        ? -1
        : 1;
    });
  }
}
