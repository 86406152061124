import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CashflowAPI } from 'src/app/apis/cashflow.service';
import { AnimationUtils } from 'src/app/utils/animations';
import { mapLangeNameObjects, mapLangeTitleObjects } from 'src/app/utils/array-filtrer';
export interface FilterInsumos {
  start_date: string;
  end_date: string;
  cashflowExpenses: Array<number>;
  sort_by: string;
  monthly_yearly:string;

}
@Component({
  selector: 'app-inputs-filter',
  templateUrl: './inputs-filter.component.html',
  styleUrls: ['./inputs-filter.component.scss'],
})
export class InputsFilterComponent implements OnInit {
  filter: FilterInsumos = {
    start_date: new Date(new Date().getFullYear(), 0, 1).toISOString(),
    end_date: new Date().toISOString(),
    cashflowExpenses: [],
    sort_by:null,
    monthly_yearly:null
  };
  inputs: any = [];
  profileInfo: any;
  constructor(
    private modalController: ModalController,
    private cashflowAPI: CashflowAPI,
    public translateService: TranslateService,
    private animationUtils: AnimationUtils,
    private store: Store<{ owner: any }>
  ) { }

  ngOnInit() {
    this.store.select('owner').subscribe((value) => {
      if (value) {
        this.profileInfo = JSON.parse(value.profile_info);
      }
    });
    this.cashflowAPI
      .getCashflowExpensesParent([40, 27, 23])
      .subscribe((values: any) => {
        this.inputs = values?.map(element => {
          element.title = this.translateService.instant("cash_flow_expenses." + element.id)
          element.cash_flow_expense_sub_categories = element.cash_flow_expense_sub_categories.map(value => {
            value.title = this.translateService.instant("cash_flow_expenses." + value.id)
            return value
          })
          element.cash_flow_expense_sub_categories = mapLangeTitleObjects(element.cash_flow_expense_sub_categories, 'title', this.translateService)

          return element
        });
        this.inputs = mapLangeTitleObjects(this.inputs, 'title', this.translateService)
        this.inputs.forEach(async (element) => {
          element.cash_flow_expense_sub_categories = mapLangeTitleObjects(
            element.cash_flow_expense_sub_categories,
            'title',
            this.translateService
          );
          element.check_all_subs = true
          this.checkAllSubsChange(element)
        });

      });
  }

  HandelDateChange(value) {
    this.filter.start_date = value.start_date;
    this.filter.end_date = value.end_date;
  }

  checkAllSubsChange(expenses) {
    if (expenses.check_all_subs) {
      expenses.cash_flow_expense_sub_categories.map((element) => {
        element.is_checked = expenses.check_all_subs;
        return element;
      });
    } else {
      var NotChecked = expenses.cash_flow_expense_sub_categories.find(
        (element) => !element.is_checked
      );
      if (!NotChecked) {
        expenses.cash_flow_expense_sub_categories.map((element) => {
          element.is_checked = expenses.check_all_subs;
          return element;
        });
      }
    }
  }

  inCheckParent(input) {
    input.cash_flow_expense_sub_categories.forEach((element) => {
      if (!element.is_checked) {
        input.check_all_subs = false;
      }
    });
  }

  async expand(expenses) {
    if (expenses.is_expanded) {
      expenses.is_expanded = false;
      await this.animationUtils.collapsAnimation({ id: expenses.id });
    } else {
      expenses.is_expanded = true;
      await this.animationUtils.expandAnimation({ id: expenses.id });
    }
  }

  cancel() {
    this.modalController.dismiss();
  }

  confirm() {
    const choosedActivities = Array<number>();
    this.inputs.forEach((element) => {
      if (element.is_checked) choosedActivities.push(element.id);
      element.cash_flow_expense_sub_categories.forEach((subExpense) => {
        if (subExpense.is_checked) choosedActivities.push(subExpense.id);
      });
    });
    this.filter.cashflowExpenses = choosedActivities;
    this.modalController.dismiss({ filters: this.filter });
  }
}
