import { PopoverController } from '@ionic/angular';
import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Storage } from '@capacitor/storage';
import { dev as offlinesKeys } from '../../../../config/offlines_keys.json';
import { MapsAPILoader } from '@agm/core';
import { Farm } from 'src/app/models/farm/farm.model';
import * as turf from '@turf/turf';
import { Geolocation } from '@capacitor/geolocation';
import { Store } from '@ngrx/store';
import {
  PlotState,
  selectAllPlots,
} from 'src/app/utils/states-management/reducers/plot.reducer';
import { lastValueFrom, take } from 'rxjs';
@Component({
  selector: 'app-map-widget',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnChanges {
  googleMap: google.maps.Map;
  poly: google.maps.Polyline;
  @ViewChild('map') map;
  @Input() allow_draw = false;
  @Output() drawAreaFinished: EventEmitter<any> = new EventEmitter();
  @Output() drawPoints: EventEmitter<any> = new EventEmitter();
  @Output() perimeterChanged: EventEmitter<any> = new EventEmitter();
  @Output() locationChangedEvent: EventEmitter<any> = new EventEmitter();
  @Output() showSurfaceInputEvent: EventEmitter<any> = new EventEmitter();
  @Output() doItLaterEvent: EventEmitter<any> = new EventEmitter();
  @Input() is_lots_view = false;
  @Input() address_view;
  @Input() lati;
  @Input() long;
  @Input() allowLotSelection = false;
  @Input() heigth = '400px';
  // @Input() heigth = '100vh';
  @Input() zoom = 16;
  wait: any;
  @Output() selectedLotEvent: EventEmitter<any> = new EventEmitter<any>();
  zoomOn;
  lots = [];
  pointList = [];
  pointListRecording: { latitude: number; longitude: number }[] = [];
  selectedArea = 0;
  is_farm_captured = false;
  @Input() lotType;
  recording = false;
  color;
  polyList = [];
  drawingManager;
  show_map = false;
  gmarkers = [];
  hideCurrentPosition = false;
  clicked_lot_option = {
    editable: false,
    draggable: false,
    fillColor: 'yellow',
    strokeColor: 'yellow',
  };
  original_coffe_lot_option = {
    editable: false,
    draggable: false,
    fillColor: COLORS.PROD_CAFE.FILL,
    strokeColor: COLORS.PROD_CAFE.OUTLINE,
  };
  original_no_prod_coffe_lot_option = {
    editable: false,
    draggable: false,
    fillColor: COLORS.NO_PROD_CAFE.FILL,
    strokeColor: COLORS.NO_PROD_CAFE.OUTLINE,
  };
  original_other_prod_option = {
    editable: false,
    draggable: false,
    fillColor: COLORS.OTHER_PROD.FILL,
    strokeColor: COLORS.OTHER_PROD.OUTLINE,
  };
  original_other_use_option = {
    editable: false,
    draggable: false,
    fillColor: COLORS.OTHER_USER.FILL,
    strokeColor: COLORS.OTHER_USER.OUTLINE,
  };
  Geolocation;
  altitude;
  @Input() farm: Farm;
  currentLat;
  currentLong;
  icon = {
    url: '/assets/dot.png',
    scaledSize: {
      width: 20,
      height: 20,
    },
  };
  constructor(
    public ngZone: NgZone,
    public popoverController: PopoverController,
    private mapsAPILoader: MapsAPILoader,
    private store: Store<{ plots: PlotState }>
  ) {
    this.Geolocation = Geolocation;
  }
  isMapReady = false
  async ngOnInit() {
    const permission = await this.Geolocation.checkPermissions();
      if (
        permission?.location !== 'granted' &&
        permission?.coarseLocation !== 'granted'
      ) {
        navigator.geolocation.watchPosition(
          (position) => {
            if (position) this.show_map = true;

          },
          (error) => {
            console.log(error);
          }
        );
        await this.Geolocation.requestPermissions();
      }
    if (
      this.farm &&
      this.farm.latitude &&
      this.farm.longitude &&
      (this.address_view || this.is_lots_view)
    ) {
      this.currentLat = this.farm.latitude;
      this.currentLong = this.farm.longitude;

      this.zoomOn = [this.currentLong, this.currentLat];
      this.show_map = true;
      this.locationChangedEvent.emit([+this.currentLat, +this.currentLong]);
    } else if (
      (!this.is_lots_view && !this.address_view) ||
      (this.farm && !this.farm.latitude && !this.farm.longitude)
    ) {
      const permission = await this.Geolocation.checkPermissions();
      if (
        permission?.location !== 'granted' &&
        permission?.coarseLocation !== 'granted'
      ) {
        await this.Geolocation.requestPermissions();
      }
      await this.printCurrentPosition();
      this.show_map = true;
    }

    this.color = this.lotType === 'agricol' ? '#3880ff' : '#e67e22';
  }

  doLater() {
    this.doItLaterEvent.emit();
  }

  async printCurrentPosition() {
    try {
      let wait = await this.Geolocation.watchPosition(
        {
          timeout: 100,
          enableHighAccuracy: true,
          maximumAge: 0,
        },
        (coordinates) => {
          this.ngZone.run(() => {
            this.altitude = coordinates?.coords?.altitude
              ? coordinates.coords.altitude.toString()
              : null;

            if (
              // -->Uncomment this section to center map in the current position
              // !coordinates?.coords?.latitude &&
              // !coordinates?.coords?.longitude &&
              this.farm.latitude &&
              this.farm.longitude
            ) {
              this.currentLat = +this.farm.latitude;
              this.currentLong = +this.farm.longitude;
              this.zoomOn = [40, 40];
              this.show_map = true;

              this.hideCurrentPosition = true;
              this.locationChangedEvent.emit([
                +this.currentLat,
                +this.currentLong,
                this.altitude,
              ]);
            } else if (
              coordinates?.coords?.longitude &&
              coordinates?.coords?.latitude
            ) {
              this.currentLong = +coordinates.coords.longitude;
              this.currentLat = +coordinates.coords.latitude;
              this.hideCurrentPosition = false;
              this.zoomOn = [
                coordinates.coords.longitude,
                coordinates.coords.latitude,
              ];
              this.show_map = true;
              Geolocation.clearWatch({ id: wait });

              this.locationChangedEvent.emit([
                +this.currentLat,
                +this.currentLong,
                this.altitude,
              ]);
            }
          });
        }
      );
    } catch (e) {}
  }

  showMap(longitude, latitude) {
    this.long = +longitude;
    this.lati = +latitude;
    this.locationChangedEvent.emit([+this.lati, +this.long, this.altitude]);
    this.zoomOn = [longitude, latitude];
    this.show_map = true;
  }
  calculatePerimeter(val) {
    let line = [];
    if (Array.isArray(val)) {
      val.forEach((element) => {
        line.push([element.longitude, element.latitude]);
      });
    }

    const lineString = turf.lineString(line);
    return turf.length(lineString, { units: 'meters' }).toFixed(2);
  }

  async removeMarkers() {
    if (this.poly) this.poly.setMap(null);

    this.gmarkers.forEach((marker) => {
      marker.setMap(null);
      marker = null;
    });
    this.gmarkers = [];

    this.pointList = [];
    this.mapsAPILoader.load().then(async () => {
      await this.onMapReady(this.googleMap);
      this.isMapReady = true;

    });
  }

  async track() {
    if (this.gmarkers || this.pointList) this.removeMarkers();

    this.poly = new google.maps.Polygon({
      strokeColor: '#000000',
      strokeOpacity: 1.0,
      strokeWeight: 3,
    });
    this.recording = true;
    const self = this;
    this.drawAreaFinished.emit(0);
    this.pointListRecording = [];

    this.wait = await this.Geolocation.watchPosition(
      {
        timeout: 10,
        enableHighAccuracy: true,
        maximumAge: 0,
      },
      (position) => {
        this.ngZone.run(() => {
          const path = this.poly.getPath();
          if (position?.coords) {
            this.pointListRecording.push({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
            var myLatlng = new google.maps.LatLng(
              parseFloat(position.coords.latitude),
              parseFloat(position.coords.longitude)
            );
          }
          this.googleMap.setCenter(myLatlng);
          if (this.pointListRecording.length > 2) {
            this.perimeterChanged.emit(
              this.calculatePerimeter(this.pointListRecording)
            );

            this.poly = this.instantiatePolygone(
              COLORS.OTHER_PROD.FILL,
              this.pointListRecording,
              COLORS.OTHER_PROD.OUTLINE
            );

            self.updatePointList(path);
          }

          var myMarker = new google.maps.Marker({
            position: myLatlng,
            title: '#' + path?.getLength(),
            map: this.googleMap,
            icon: {
              url: 'assets/dot.png',
              scaledSize: new google.maps.Size(20, 20),
            },
          });
          this.gmarkers.push(myMarker);
        });
      }
    );
  }

  stopTracking() {
    this.Geolocation.clearWatch({ id: this.wait });
    this.recording = false;
    this.drawAreaFinished.emit(this.selectedArea);
    this.drawPoints.emit(this.pointListRecording);
    this.poly.setMap(this.googleMap);
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.googleMap) {
      this.mapsAPILoader.load().then(async () => {
        await this.onMapReady(this.googleMap);
        this.isMapReady = true;

      });
    }
  }
  async onMapReady(map) {
    map.setOptions({
      streetViewControl: false,
      mapTypeId: 'hybrid',
    });

    if (this.allow_draw) this.initDrawingManager(map);
    if (this.farm.longitude && this.farm.latitude && !this.allow_draw) {
      map.setCenter(
        new google.maps.LatLng(
          +this.farm.latitude,
          +this.farm.longitude
        ).toJSON()
      );
      map.setZoom(this.zoom);
      this.is_farm_captured = true;
    } else {
      map.setCenter(new google.maps.LatLng(+this.lati, +this.long).toJSON());
      map.setZoom(this.zoom);
      this.is_farm_captured = true;
    }
    if (this.address_view && this.farm.longitude && this.farm.latitude) {
      map.setCenter(
        new google.maps.LatLng(
          +this.farm.latitude,
          +this.farm.longitude
        ).toJSON()
      );
      map.setZoom(this.zoom);
      google.maps.event.addListener(map, 'click', (event) => {
        this.farm.latitude = +event.latLng.lat();
        this.farm.longitude = +event.latLng.lng();
        map.setCenter(
          new google.maps.LatLng(
            +this.farm.latitude,
            +this.farm.longitude
          ).toJSON()
        );
        this.locationChangedEvent.emit([
          +this.farm.latitude,
          +this.farm.longitude,
          this.altitude,
        ]);
      });
    }
    this.drawPolygones(map);
    this.googleMap = await map;

    this.googleMap.setCenter(
      new google.maps.LatLng(+this.lati, +this.long).toJSON()
    );
    this.isMapReady = true;

  }

  initDrawingManager = (map: any) => {
    const self = this;
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingControlOptions: {},
      polygonOptions: {
        draggable: true,
        editable: true,
        fillColor: this.color,
        strokeColor: this.color,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
    });

    this.drawingManager.setMap(map);
    google.maps.event.addListener(
      this.drawingManager,
      'overlaycomplete',
      (event) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          const paths = event.overlay.getPaths();
          this.poly = event.overlay;
          for (let p = 0; p < paths.getLength(); p++) {
            google.maps.event.addListener(paths.getAt(p), 'set_at', () => {
              if (!event.overlay.drag) {
                self.updatePointList(event.overlay.getPath());
              }
            });
            google.maps.event.addListener(paths.getAt(p), 'insert_at', () => {
              self.updatePointList(event.overlay.getPath());
            });
            google.maps.event.addListener(paths.getAt(p), 'remove_at', () => {
              self.updatePointList(event.overlay.getPath());
            });
          }
          self.updatePointList(event.overlay.getPath());
        }
        if (event.type !== google.maps.drawing.OverlayType.MARKER) {
          self.drawingManager.setDrawingMode(null);
          self.drawingManager.setOptions({
            drawingControl: false,
          });
        }
      }
    );
  };

  updatePointList(path) {
    this.pointList = new Array();
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      this.pointList.push(path.getAt(i).toJSON());
    }
    this.selectedArea = google.maps.geometry.spherical.computeArea(path);
    this.drawAreaFinished.emit(this.selectedArea);

    this.drawPoints.emit(this.pointList);
  }

  instantiatePolygone(colors, points, fillColors): google.maps.Polygon {
    const paths = [];
    points.forEach((element) => {
      paths.push({ lat: +element.latitude, lng: +element.longitude });
    });

    const polygon = new google.maps.Polygon({
      paths: paths,
      editable: false,
      draggable: false,
      fillColor: fillColors,
      strokeColor: colors,
    });
    return polygon;
  }

  async drawPolygones(map) {
    // this.lots = this.farm.lots
    this.lots = await lastValueFrom(
      this.store.select(selectAllPlots).pipe(take(1))
    );
    if (Array.isArray(this.lots)) {
      this.lots.forEach(async (element) => {
        switch (element.type) {
          case LOT_TYPE.agricol:
            var poly = this.instantiatePolygone(
              COLORS.OTHER_PROD.FILL,
              element.lands,
              COLORS.OTHER_PROD.OUTLINE
            );
            poly.setMap(map);
            google.maps.event.addListener(poly, 'click', async (e) => {});
            if (this.allowLotSelection)
              this.prepareLotSelectionView(poly, element);
            break;

          case LOT_TYPE.other:
            var poly = this.instantiatePolygone(
              COLORS.OTHER_USER.FILL,
              element.lands,
              COLORS.OTHER_USER.OUTLINE
            );
            poly.setMap(map);
            google.maps.event.addListener(poly, 'click', (event) => {});
            if (this.allowLotSelection)
              this.prepareLotSelectionView(poly, element);
            break;
        }
      });
    }
  }

  prepareLotSelectionView(polygone, lot) {
    this.polyList.push({ type: lot.type, poly: polygone });
    polygone.addListener('click', (event) => {
      this.polyList.forEach((element: any) => {
        switch (element.type) {
          case LOT_TYPE.agricol:
            element.poly.setOptions(this.original_other_prod_option);
            break;
          case LOT_TYPE.other:
            element.poly.setOptions(this.original_other_use_option);
            break;
        }
      });
      this.selectedLotEvent.emit(lot);
      polygone.setOptions(this.clicked_lot_option);
    });
  }
}

const COLORS = {
  PROD_CAFE: {
    FILL: '#e74c3c',
    OUTLINE: '#c0392b',
  },
  NO_PROD_CAFE: {
    FILL: '#2ecc71',
    OUTLINE: '#27ae60',
  },
  OTHER_PROD: {
    FILL: '#3498db',
    OUTLINE: '#2980b9',
  },
  OTHER_USER: {
    FILL: '#e67e22',
    OUTLINE: '#e67e22',
  },
};

const LOT_TYPE = {
  agricol: 'agricol',
  other: 'other_use',
};
