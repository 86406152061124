import { TranslateService } from '@ngx-translate/core';
import { LicensePolicyAPI } from './../../../apis/license_policy.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(
    private licenseAgreementService: LicensePolicyAPI,
    public translateService:TranslateService
  ) {}
  privacyPolicyText;
  lang;

  ngOnInit() {
    this.lang = this.translateService.currentLang;

    this.licenseAgreementService.getPrivacyPolicy(this.lang).subscribe((value) => {
      this.privacyPolicyText = value.privacy_policy;
    });
  }
}
