import { Component, Input, OnInit } from '@angular/core';
import { SummaryComponent } from 'src/app/modules/dashboard/summary/summary.component';
import { DebtFromComponent } from 'src/app/modules/debts/debt-from/debt-from.component';
import { MoneyLoanComponent } from 'src/app/modules/goods/money-loan/money-loan.component';
import { OperationsAPI } from 'src/app/apis/operations.service';
import {
  DEBT_OPERATION,
  GARENTEE_OPERATION,
  MONEY_LOAN_OPERATION,
} from 'src/app/utils/consts';
import { GarenteeComponent } from 'src/app/modules/goods/garentee/garentee.component';
import { ObjectiveApi } from 'src/app/apis/objectives.service';
import { UserInfo } from 'src/app/apis/users-info.service';
import { PensionPlanTrackerComponent } from 'src/app/modules/dashboard/widgets/pension-plan-tracker/pension-plan-tracker.component';
@Component({
  selector: 'app-shortcuts',
  templateUrl: './shortcuts.component.html',
  styleUrls: ['./shortcuts.component.scss'],
})
export class ShortcutsComponent implements OnInit {
  @Input() nav;
  operationsAPI;
  ownerDebts: any[];
  ownerCaucions: any[];
  ownerMoneyLaon: any[];
  objectiveList: any[];
  currency;
  MaxHeigthList;
  MaxHeigth;
  country;
  constructor(
    private userInfo: UserInfo,
    private operationAPI: OperationsAPI,
    private objectiveAPI: ObjectiveApi
  ) {}

  async ngOnInit() {
    const lang = await this.userInfo.getSelectedLangage();
    this.country = await this.userInfo.getCountry();
    const user = await this.userInfo.getOwner();

    await this.operationAPI
      .getOperation()
      .subscribe(async (value: any) => {
        this.ownerDebts = await value
          .filter((element) => element.operation_type === DEBT_OPERATION)
          .map((element) => {
            element.calculatedPercent = this.calculatePercent(
              element.amount,
              element.paid_so_far
            );

            this.currency = element.currency;
            return element;
          });

        this.ownerCaucions = await value
          .filter((element) => element.operation_type === GARENTEE_OPERATION)
          .map((element) => {
            element.calculatedPercent = this.calculatePercent(
              element.amount,
              element.paid_so_far
            );

            return element;
          });

        this.ownerMoneyLaon = await value
          .filter((element) => element.operation_type == MONEY_LOAN_OPERATION)
          .map((element) => {
            element.calculatedPercent = this.calculatePercent(
              element.amount,
              element.paid_so_far
            );

            return element;
          });
        this.setListHeigth();
      });

    this.objectiveAPI.getObjectivesWithMoneyRepporting().subscribe(
      (values: any) => {
        if (Array.isArray(values))
          this.objectiveList = values.map((element) => {
            element.calculatedPercent = this.calculatePercent(
              element.pension_plan_objective,
              element.amount
            );
            return element;
          });
        this.setListHeigth();
      },
      (e) => console.log(e)
    );
  }

  setListHeigth() {
    let ArrayLength: any = [
      this.ownerDebts?.length,
      this.ownerCaucions?.length,
      this.ownerMoneyLaon?.length,
      this.objectiveList?.length,
    ];
    this.MaxHeigthList = Math.max(...ArrayLength);
    if (this.MaxHeigthList > 3) {
      this.MaxHeigthList = 3;
    }

    this.MaxHeigth =
      (this.MaxHeigthList == 2 ? 50 : 0) +
      (this.MaxHeigthList == 1 ? 110 : 0) +
      this.MaxHeigthList * 135;
  }
  debts() {
    this.navTo(DebtFromComponent, 'debt');
  }
  loan() {
    this.navTo(MoneyLoanComponent, 'money loan');
  }

  navTo(componts, props = '') {
    this.nav.setRoot(componts, {
      nav: this.nav,
      rootStack: SummaryComponent,
      isDashboardView: true,
      operation_type: props,
    });
  }

  objectives() {
    this.navTo(PensionPlanTrackerComponent);
  }

  garentee() {
    this.navTo(GarenteeComponent, GARENTEE_OPERATION);
  }

  houseHold() {}

  calculatePercent(required_amount, gathered_amount) {
    if (!gathered_amount) {
      gathered_amount = 0;
    }
    return gathered_amount / required_amount;
  }
}
