import { cloneDeep } from 'lodash';
import {
  deleteSelectedAgriProduct,
  loadSelectedAgriProduct,
  addAgriProduct,
  loadAgriProduct,
} from './../../../../utils/states-management/actions/agri-product.action';
import { PopoverComponent } from './../../../../widgets/popover/popover.component';
import { CAFE_IDS, MORPH_Product } from './../../../../utils/consts';
import { UnifiedCashflowInputComponentComponent } from 'src/app/modules/cashflow/unified-cashflow-input-component/unified-cashflow-input-component.component';
import { RoutingConfig } from 'src/app/utils/routing-config';
import {
  PRODUCT_MORPHABLE,
  CAFE_ACCOUNTING_ENTRY_CATEGORY,
} from 'src/app/utils/consts';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { OTROS_ACCOUNTING_ENTRY_CATEGORY } from '../../../../utils/consts';
import { Component, Input, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../../config/offlines_keys.json';
import { Router } from '@angular/router';
import {
  IonNav,
  LoadingController,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import routes from '../../../../config/routing.json';
import { PriorDashboardComponent } from '../../../prior-dashboard/prior-dashboard.component';
import { ProductFormComponent } from '../product-form/product-form.component';
import { ProductionSalesOtherComponent } from '../../../dashboard/cashflow/production-sales-other/production-sales-other.component';
import { showConfirmModal } from 'src/app/utils/pop_over_maker';
import { UserInfo } from 'src/app/apis/users-info.service';
import { Good } from 'src/app/models/good.model';
import { ProductionAPI } from 'src/app/apis/FarmAPI/productions.service';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { GoodsAPI } from 'src/app/apis/goods.service';
import { map, switchMap, take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { lastValueFrom } from 'rxjs';
import { OtrosDisplayableListItem } from 'src/app/models/cash-flow/otros_displayable_list_item';

interface CoffeProduct {
  is_cafe: boolean;
  name: string;
  products: Array<any>;
}

@Component({
  selector: 'app-pre-product-selector',
  templateUrl: './pre-product-selector.component.html',
  styleUrls: ['./pre-product-selector.component.scss'],
})
export class PreProductSelectorComponent implements OnInit {
  modal;
  productList: ProductionsPerfarm[] = new Array();
  coffe_title = 'Café';
  @Input() nav: IonNav;
  @Input() rootStack;
  isDashboardView: Boolean;
  is_coffe_mapped = false;
  loadingModal;
  coffeProduct: CoffeProduct = {
    is_cafe: true,
    name: this.coffe_title,
    products: new Array(),
  };
  selected_langage;
  base_url = 'assets';
  formConfig;
  cropProducts;
  cropConfig;
  is_loading: boolean = false;
  update_at;
  constructor(
    private router: Router,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private userInfo: UserInfo,
    public translateService: TranslateService,
    private loadingController: LoadingController,
    private productionAPI: ProductionAPI,
    private toastUtils: ToastUtils,
    private goodsAPi: GoodsAPI,
    private routingConfig: RoutingConfig,
    private store: Store<{
      otrosCategories: Array<OtrosDisplayableListItem>;
      agriculturalProduct: any;
    }>
  ) {}

  async ngOnInit() {
    this.cropConfig = await this.routingConfig.getCropFormConfig();
    this.loadingModal = await this.loadingController.create();
    this.isDashboardView = this.router.url.includes(routes.dashboard.home);
    await this.translate();
    await this.fetchProductionsPerFarm();

    this.store.select('agriculturalProduct').subscribe(async (value) => {
      this.productList = cloneDeep(value.selectedProducts);
      // console.log(this.productList)
      this.getLastUpdate(this.productList);
    });

    this.cropProducts = JSON.parse(
      (await Storage.get({ key: dev.CROP_PRODUCT }))?.value
    );
    this.showPopOver('infobull.infobull_agriculture_product');
  }

  async translate() {
    if (this.selected_langage == 'es') {
      this.coffe_title = 'Café';
    } else {
      this.coffe_title = 'Coffee';
    }
  }

  async fetchProductionsPerFarm() {
    this.store.dispatch(loadAgriProduct());
    this.store.dispatch(loadSelectedAgriProduct());
  }

  getLastUpdate(products) {
    if (products.length > 0) {
      var maxDate = new Date(
        Math.max(
          ...products.map((element) => {
            return new Date(element.created_at);
          })
        )
      );
      var minDate = new Date(
        Math.min(
          ...products.map((element) => {
            return new Date(element.created_at);
          })
        )
      );

      if (minDate?.getHours() + 1 < maxDate?.getHours()) {
        this.update_at = '';
      } else {
        this.update_at = maxDate;
      }
    }
  }
  async getCashflowRequestConfirmation(msg): Promise<Boolean> {
    if (!msg)
      msg = await this.translateService
        .get('add_cashflow')
        .toPromise()
        .then((value) => value);
    const modal = await showConfirmModal(
      this.popoverController,
      msg,
      null,
      'Sí',
      'No'
    );
    const { data } = await modal.onWillDismiss();
    return data?.confirmed;
  }

  async previous() {
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());
  }

  async next() {
    this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }

  async add_production() {
    let componentProps = {
      modalController: this.modalController,
      categorie_id: 1,
      products: this.productList,
      is_preproduct_view: true,
      title: await this.translateService
        .instant('label.add_production_agricola_farm'),
    };
    let modal = await this.toastUtils.modalBuilder(
      ProductFormComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper']
    );

    const { data } = await modal.onWillDismiss();

    // if (Array.isArray(data?.products) && data?.products.length > 0) {
    //   await this.saveProductions(data.products);
    // }
  }
  async showPopOver(message) {
    let componentProps = {
      key1: await this.translateService
        .get(message)
        .toPromise()
        .then((value) => value),
    };

    await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
  }

  // async saveProductions(products) {
  //   const farm = await lastValueFrom(this.store.select(getFarm).pipe(take(1)));
  //   const owner_id = await this.userInfo.getOwner().then((value) => value.id);
  //   if (Array.isArray(products) && products.length > 0) {
  //     products = products.map((element) => {
  //       let productions = new ProductionsPerfarm();
  //       productions.goods = new Array<Good>();
  //       productions.goods.push(new Good());
  //       productions.morphable_type = MORPH_Product;
  //       productions.morphable = element;
  //       productions.morphable_id = element.product_id;
  //       if (element.trees && element.trees.has_trees) {
  //         productions.trees_numbers = element.trees.trees_number;
  //       } else {
  //         productions.trees_numbers = -1;
  //       }
  //       if (element.qty) {
  //         productions.goods[0].qty = element.qty;
  //       }
  //       if (element.unit) {
  //         productions.goods[0].unit = element.unit;
  //       }
  //       if (element.comment) productions.comment = element.comment;
  //       else productions.comment = null;
  //       productions.goods[0].owner_id = owner_id;
  //       productions.is_crop = element.is_crop ? 1 : 0;
  //       productions.is_animal = 0;
  //       productions.farm_id = farm.id;
  //       return productions;
  //     });
  //   }
  //   this.store.dispatch(addAgriProduct({ productions: products }));

  //   // this.productionAPI
  //   //   .insertMultipleProduction(products)
  //   //   .then(async (value: ProductionsPerfarm[]) => {
  //   //     this.productList = value;
  //   //     await Storage.set({
  //   //       key: dev.PRESELECTED_PRODUCT_KEY,
  //   //       value: JSON.stringify(value),
  //   //     });
  //   //     this.fetchProductionsPerFarm();
  //   //   });
  // }

  async delete(item: any) {
    await this.proceedWithDelete(item);
    this.sortArray();
  }

  async proceedWithDelete(item: ProductionsPerfarm) {
    this.productionAPI
      .deleteFarmProduction(item.id)
      .subscribe(async (value) => {
        await this.fetchProductionsPerFarm();
      });
  }

  navBack() {
    this.nav.setRoot(this.rootStack, {
      nav: this.nav,
      rootStack: PriorDashboardComponent,
    });
  }
  async requestUserToInsertCashflow(msg, item) {
    const modal = await showConfirmModal(
      this.popoverController,
      msg,
      null,
      await this.translateService
        .get('yes')
        .toPromise()
        .then((value) => value),

      await this.translateService
        .get('no')
        .toPromise()
        .then((value) => value)
    );
    const { data } = await modal.onWillDismiss();
    if (data?.confirmed) {
      await this.showOtorosInputComponent(item);
      await this.fetchProductionsPerFarm();
    }
    return data?.confirmed;
  }

  async sortArray() {
    this.productList.forEach((product, index) => {
      if (product.comment) {
        product.morphable.name = product.comment;
        product.morphable.name_en = product.comment;
      }
    });

    if (this.selected_langage === 'es')
      this.productList.sort((a, b) => {
        if (
          a.morphable.name?.normalize('NFD').toLowerCase() >
          b.morphable.name?.normalize('NFD').toLowerCase()
        ) {
          return 1;
        }
        if (
          a.morphable.name?.normalize('NFD').toLowerCase() <
          b.morphable.name?.normalize('NFD').toLowerCase()
        ) {
          return -1;
        }
        return 0;
      });
    else
      this.productList.sort((a, b) => {
        if (
          a.morphable['name_' + this.selected_langage]
            ?.normalize('NFD')
            .toLowerCase() >
          b.morphable['name_' + this.selected_langage]
            ?.normalize('NFD')
            .toLowerCase()
        ) {
          return 1;
        }
        if (
          a.morphable['name_' + this.selected_langage]
            ?.normalize('NFD')
            .toLowerCase() <
          b.morphable['name_' + this.selected_langage]
            ?.normalize('NFD')
            .toLowerCase()
        ) {
          return -1;
        }
        return 0;
      });
  }

  details(item) {
    this.nav.setRoot(ProductionSalesOtherComponent, {
      product: item,
      nav: this.nav,
    });
  }

  async showOtorosInputComponent(products: ProductionsPerfarm) {
    const cashFlowCategFiltred = JSON.parse(
      (
        await Storage.get({
          key: dev.CASHFLOW_CATEGORIES,
        })
      ).value
    );

    const comp = UnifiedCashflowInputComponentComponent;
    let componentProps = {
      accountingEntryCategories: CAFE_IDS.includes(products.morphable_id)
        ? cashFlowCategFiltred.find(
            (value) => value.id === CAFE_ACCOUNTING_ENTRY_CATEGORY
          )
        : cashFlowCategFiltred.find(
            (value) => value.id === OTROS_ACCOUNTING_ENTRY_CATEGORY
          ),
    };
    let modal = await this.toastUtils.modalBuilder(
      comp,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper'],
      true,
      '1'
    );

    await modal.onWillDismiss();
  }

  async deletePressed(object) {
    let farmProduction: ProductionsPerfarm = object;
    this.store.dispatch(deleteSelectedAgriProduct({ product: farmProduction }));
  }

  // deleteProduction(productionDelete: ProductionsPerfarm) {
  //   this.productionAPI.deleteFarmProduction(productionDelete.id).subscribe(
  //     async (value) => {
  //       this.fetchAnimals().subscribe();
  //       await this.fetchProductionsPerFarm();
  //     },
  //     (e: HttpErrorResponse) => {
  //       if (e.status == 200) this.fetchAnimals().subscribe();
  //     }
  //   );
  // }

  // deleteFromGoodsTable(good: Good) {
  //   this.goodsAPi
  //     .deleteGood(good.id)
  //     .pipe(
  //       map((deleteResult) => deleteResult),
  //       switchMap((deleteResult) => this.fetchAnimals()),
  //       map((finalResult) => finalResult)
  //     )
  //     .subscribe();
  // }

  // fetchAnimals() {
  //   this.is_loading = true;
  //   return this.productionAPI.getProductionByFarmAndType(0).pipe(
  //     map((values: Array<ProductionsPerfarm>) => {
  //       this.productList = values;
  //       this.getLastUpdate(this.productList);
  //       this.is_loading = false;
  //     })
  //   );
  // }
}
