import { RoutingConfig } from 'src/app/utils/routing-config';
import { Component, OnInit } from '@angular/core';
import { dev } from '../../../../config/offlines_keys.json';
import { Storage } from '@capacitor/storage';
import { HttpErrorResponse } from '@angular/common/http';
import { FarmAPI } from 'src/app/apis/farm.service';
import { Router } from '@angular/router';
import { Farm } from '../../../../models/farm/farm.model';
import routes from '../../../../config/routing.json';
@Component({
  selector: 'app-farm-ownership',
  templateUrl: './farm-ownership.component.html',
  styleUrls: ['./farm-ownership.component.scss'],
})
export class FarmOwnershipComponent implements OnInit {
  owner_nature;
  isDashboardView;
  owner_nature_other = '';
  constructor(
    private router: Router,
    private farmAPI: FarmAPI,
    private routingConfig: RoutingConfig
  ) {}

  async ngOnInit() {
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
    const lang = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );
    this.owner_nature = await Storage.get({ key: dev.FARM_KEY }).then(
      async (value) => {
        if (JSON.parse(value.value)?.nature == 'other') {
          this.owner_nature_other = await (
            await Storage.get({ key: 'other-nature-owner' })
          ).value;
        }
        return JSON.parse(value.value)?.nature;
      }
    );
  }

  async previous() {
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());
  }

  async submit(navigate = true) {
    let f = await Storage.get({ key: dev.FARM_KEY }).then((value) =>
      JSON.parse(value.value)
    );
    let owners = await Storage.get({ key: dev.USER_KEY }).then((value) =>
      JSON.parse(value.value)
    );
    let farm = new Farm(f);
    if (this.owner_nature == 'other') {
    }

    farm.nature = this.owner_nature;
    if (farm.nature == 'other') {
      Storage.set({
        key: 'other-nature-owner',
        value: this.owner_nature_other,
      });
    } else {
      Storage.set({ key: 'other-nature-owner', value: '' });
    }
    if (!farm?.id) {
      var ownerId = owners?.id;
      this.farmAPI
        .insertFarmAndLinkItToUser({ farm, ownerId })
        .subscribe(async (value) => {
          await Storage.set({
            key: dev.FARM_KEY,
            value: JSON.stringify(value),
          });
          if (!this.isDashboardView && navigate)
            this.router.navigateByUrl(await this.routingConfig.getNextUrl());
        });
    } else {
      var ownerId = owners?.id;
      this.farmAPI.upDateFarm(farm).subscribe(async (value) => {
        value;
        await Storage.set({
          key: dev.FARM_KEY,
          value: JSON.stringify(farm),
        });

        if (!this.isDashboardView && navigate)
          this.router.navigateByUrl(await this.routingConfig.getNextUrl());
      });
    }
  }
  ownershipChange() {
    this.submit(false);
  }
}
