import { HttpClient } from '@angular/common/http';
import { dev } from '../config/endpoints.json';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class MaterialProductAPI {
  main_endpoint = environment.appRoot + '/api/';
  headers;
  constructor(private httpClient: HttpClient) {}

  getProducts(country_id = 1) {
    const url =
      this.main_endpoint +
      dev.endpoint.material_product.get_material_categories +
      '/' +
      country_id;
    return this.httpClient.get(url, { headers: this.headers });
  }

  getAnimalCategories() {
    const url =
      this.main_endpoint +
      dev.endpoint.material_product.get_animal_categories 
    return this.httpClient.get(url);
  }
  getAllAnimalCategories() {
    const url =
      this.main_endpoint +
      dev.endpoint.material_product.get_all_animal_categories
    return this.httpClient.get(url);
  }
  
  postMultipleProduct(products) {
    const url =
      this.main_endpoint + dev.endpoint.material_product.post_animal_products;
    let formData = new FormData();
    formData.append('products', JSON.stringify(products));

    return this.httpClient.post(url, formData);
  }

 
  getCategoriesByName(title) {
    const url =
      this.main_endpoint +
      dev.endpoint.material_product.get_categories_by_name +
      '/' +
      title;

    return this.httpClient.get(url);
  }
  getCashflowByMaterialProductId(id) {
    const url =
      this.main_endpoint +
      dev.endpoint.material_product.get_cashflow_by_material_product_id +
      '/' +
      id;
    return this.httpClient.get(url);
  }
}
