import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { WalletPageRoutingModule } from './wallet-routing.module';

import { WalletPage } from './wallet.page';
import { SectionBalanceComponent } from './section-balance/section-balance.component';
import { SectionCashflowComponent } from './section-cashflow/section-cashflow.component';
import { SectionSavingsComponent } from './section-savings/section-savings.component';
import { TranslateModule } from '@ngx-translate/core';
import { BottomSheetOverviewComponent } from '../widgets/bottom-sheet-overview/bottom-sheet-overview.component';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheet,
} from '@angular/material/bottom-sheet';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DataLoaderBarComponent } from 'src/app/widgets/data-loader-bar/data-loader-bar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    WalletPageRoutingModule,
    TranslateModule.forChild(),
    OverlayModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    WalletPage,
    SectionBalanceComponent,
    SectionCashflowComponent,
    SectionSavingsComponent,
    BottomSheetOverviewComponent,
    DataLoaderBarComponent
  ],
  providers: [
    { provide: MatBottomSheet },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WalletPageModule {}
