import {
  getTotalsAccountingCategories,
  getTotalsAccountingCategoriesSuccess,
} from './../actions/accountings-entries.action';
import { createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import { AccountingEntryCategory } from 'src/app/models/cash-flow/cashflow_categories';
import {
  accountingEntriesLoadedSuccessfully,
  resetLoadedAccountings,
} from '../actions/accountings-entries.action';

export const accountingInitialEntriesState: Array<AccountingEntryCategory> = [];
export const TotalsAccountingCategories: Array<any> = [];

export const accountingEntriesReducer = createReducer(
  accountingInitialEntriesState,
  on(accountingEntriesLoadedSuccessfully, (state, { accountingEntries }) => {
    state = accountingEntries;
    return accountingEntries;
  }),
  on(resetLoadedAccountings, (state) => [])
);
export const connectionFeatureSelector =
  createFeatureSelector<Array<AccountingEntryCategory>>('accountingEntries');

  export const getAccountingCategories = createSelector(
    connectionFeatureSelector,
    (state) => state
  );

export const TotalAccountingsEntriesReducer = createReducer(
  TotalsAccountingCategories,
  on(getTotalsAccountingCategories, (state, action) => {
    return {
      ...state
        };
  }),
  on(getTotalsAccountingCategoriesSuccess, (state, action) => {
    state = action.TotalsAccountingCategories;
    return state
  })
);
