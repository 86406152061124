import { TranslateService } from '@ngx-translate/core';
import { PopoverController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Storage } from '@capacitor/storage';
import { dev } from '../../config/offlines_keys.json';
import { calculateAge } from 'src/app/utils/pipes-utils/date_formatter';
import { UserInfo } from 'src/app/apis/users-info.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-popover-img',
  templateUrl: './popover-img.component.html',
  styleUrls: ['./popover-img.component.scss'],
})
export class PopoverImgComponent implements OnInit {
  constructor(
    public popover: PopoverController,
    private userInfo: UserInfo,
    public translateService: TranslateService
  ) {}
  @Input() data: any;
  @Input() component: any;
  @Input() employee_is;
  close;
  base_endpoint = environment.appRoot;
  userFile;
  language;

  async ngOnInit() {
    this.userFile = await Storage.get({ key: dev.FILE_KEY }).then((value) =>
      JSON.parse(value.value)
    );
    const owner = await this.userInfo.getOwner();

    this.language = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );
    
    if (Array.isArray(this.data))
       this.data = cloneDeep(this.data)
      this.data.forEach((element) => {
        if (element.morphable && element.morphable.birth_date)
          element.age = calculateAge(new Date(element.morphable.birth_date));
        else if (element.birth_date)
          element.age = calculateAge(new Date(element.birth_date));

        if (
          isNaN(element.age) &&
          (element?.birth_date || element?.morphable?.birth_date)
        ) {
          element.age = 0;
        }

        if (
          owner &&
          element?.id === owner.id &&
          element?.name === owner?.first_name
        ) {
          element.file = this.userFile;
        }
      });
  }

  ClosePopover(data) {
    this.popover.dismiss({ data: data });
  }
}
