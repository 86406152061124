import { cloneDeep } from 'lodash';
import {
  addOfflineOperation,
  updateConnectionStatus,
} from './../utils/states-management/actions/offline.action';
import { ConnectionState } from './../utils/states-management/reducers/offline.reducer';
import { Store } from '@ngrx/store';
import { Injectable, Injector } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap, tap, take, catchError } from 'rxjs/operators';
import { from } from 'rxjs';
import { Storage } from '@capacitor/storage';
import { dev } from '../config/offlines_keys.json';
import { ToastUtils } from '../utils/toast_builder';
import { Router } from '@angular/router';
import { Network } from '@capacitor/network';
import { LOGOUT } from '../utils/states-management/actions/owner.action';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authSessionRepository: Observable<string> = null;
  private isOnline: boolean = false;
  connectionObserver;
  constructor(
    public router: Router,
    private store: Store<{ connection: ConnectionState }>,
  ) {
    this.store.select('connection').subscribe((value) => {
      this.isOnline = value.isOnline;
    });
  }

  private static getRequestWithAuthorizationHeaders(
    request: HttpRequest<any>,
    accessToken: string
  ): any {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken} `,
      },
    });
  }

  private static performRequestWithAuthorization(
    request: HttpRequest<any>,
    next: HttpHandler,
    accessToken: string
  ): Observable<HttpEvent<any>> {
    const authorizedRequest =
      TokenInterceptor.getRequestWithAuthorizationHeaders(request, accessToken);
    return next.handle(authorizedRequest);
  }

  addOfflineOperation(request) {
    if (
      request.method === 'DELETE' &&
      (request.url.includes('offline-') || request.url.includes('undefined'))
    )
      return;

    let formDataObject = null;
    
    if (request?.body instanceof FormData) {
      formDataObject = {};
      for (const [key, value] of request?.body?.entries()) {
        formDataObject[key] = value;
      }
    }

    if (formDataObject) {
      request.formDataObject = formDataObject;
      if (
        (request.method === 'POST' || request.method === 'PUT') &&
        formDataObject?.id?.includes('offline-')
      )
        return;
    }

    this.store.dispatch(addOfflineOperation({ operation: request }));
  }

  intercept(request: any, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authSessionRepository = from(
      Storage.get({ key: dev.ACCESS_TOKEN }).then((value) => value.value)
    );
    if (
      request.url.includes('/auth/token') ||
      request.url.includes('https://us1.locationiq.com/v1/reverse')
    ) {
      return next.handle(request);
    }

    return this.authSessionRepository.pipe(
      switchMap((token: string) => {
        // if (!token) {
        //   this.store.dispatch({ type: LOGOUT, redirection_path: '/' });
        // }

        if (
          !this.isOnline &&
          ['POST', 'DELETE', 'PUT'].includes(request.method)
        ) {
          this.addOfflineOperation(request);
          return;
        } else if (this.isOnline) {
          return TokenInterceptor.performRequestWithAuthorization(
            request,
            next,
            token
          );
        }
      }),
      tap(
        (event) => {
        },
        // ,
        async (error) => {
          //   if (error instanceof HttpErrorResponse) {
              // if (error.status == 500) {
                // this.toastUtils
                //   .toastBuilder(
                //     await this.translateService
                //       .get('errors.internal_server_error')
                //       .toPromise(),
                //     null,
                //     null,
                //     null
                //   )
                //   .then((value) => value.present());
          //     } else if (error.status === 404) {
          //       // this.toastUtils
          //       //   .toastBuilder(
          //       //     await this.translateService
          //       //       .get('errors.element_not_found')
          //       //       .toPromise(),
          //       //     null,
          //       //     null,
          //       //     null
          //       //   )
          //       //   .then((value) => value.present());
          //     } else
          if (error.status == 401) {
            // this.toastUtils
            //   .toastBuilder(
            //     await this.translateService.instant('errors.token_not_valid'),
            //     null,
            //     null,
            //     null
            //   )
            //   .then((value) => value.present());
            this.store.dispatch({ type: LOGOUT, redirection_path: '/' });
            return of(false);

          }
          return of(false);

          //  else if (error.status === 422) {
          //     }
          //   }
        }
      )
      // ,
      // catchError((err) => {
      //   console.log('Token interceptor error:', err);
      //   return of(err);
      // })
    );
  }
}
