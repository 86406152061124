import { RoutingConfig } from 'src/app/utils/routing-config';
import {
  MORPH_Farm,
  MORPH_FamilyMember,
  MORPH_Owner,
  MORPH_Garentee,
} from './../../../utils/consts';
import { PopoverImgComponent } from 'src/app/widgets/popover-img/popover-img.component';
import { Component, Input, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../config/offlines_keys.json';
import {
  LoadingController,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { OperationsAPI } from '../../../apis/operations.service';
import Operation from 'src/app/models/operation.model';
import { Family_membersAPI } from '../../../apis/family_members.service';
import { PickerOptionFormater } from '../../../utils/pipes-utils/date_formatter';
import { ToastUtils } from '../../../utils/toast_builder';
import { Router } from '@angular/router';
import routes from '../../../config/routing.json';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import { CashflowAPI } from 'src/app/apis/cashflow.service';
import { UserInfo } from 'src/app/apis/users-info.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductionAPI } from 'src/app/apis/FarmAPI/productions.service';
import { OperationRelatedToCashflowBusinessRules } from 'src/app/utils/business-rules/operations_related_to_cashflow_business_rules';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';

@Component({
  selector: 'app-modal-garentee',
  templateUrl: './modal-garentee.component.html',
  styleUrls: ['./modal-garentee.component.scss'],
  providers: [OperationsAPI, Family_membersAPI],
})
export class ModalGarenteeComponent implements OnInit {
  @Input() accounting_entry_category;
  @Input() cashflow_category_id;
  @Input() id: number;

  loadinModal;
  farm_id;
  currency;
  country;
  listf: any[];
  isDashboardView;
  productions;
  you = 'Usted';
  products: any[] = [];
  selectedFamilyMember;
  currentYear;
  dateString;
  operation = new Operation();
  owner;
  selectedSegmentValue;
  token;
  customPickerOptions;
  formConfig;
  constructor(
    public modalController: ModalController,
    private router: Router,
    private progressModal: LoadingController,
    public popoverController: PopoverController,
    private pickerOptions: PickerOptionFormater,
    private family_membersAPI: Family_membersAPI,
    private operationAPI: OperationsAPI,
    private cashflowAPI: CashflowAPI,
    private userInfo: UserInfo,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private operationRule: OperationRelatedToCashflowBusinessRules,
    private routingConfig: RoutingConfig,
    private store:Store
  ) {}

  async ngOnInit() {
    let ClassName = 'ModalGarenteeComponent';
    this.formConfig = await this.routingConfig.getPageFormConfig(ClassName);

    this.customPickerOptions = this.pickerOptions.custom_picker_options(
      this.operation.due_date
    );
    this.loadinModal = await this.progressModal.create().then((value) => value);
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
    this.currentYear = new Date();
    this.dateString = new Date(
      this.currentYear.getTime() - this.currentYear.getTimezoneOffset() * 60000
    );
    this.dateString = this.dateString.toISOString().split('T')[0];
    this.operation.creation_date = this.dateString;

    const lang = await this.userInfo.getSelectedLangage();

    this.langageChanger(lang);

    let farm = await lastValueFrom(this.store.select(getFarm).pipe(take(1)))
    this.farm_id =farm.id;

    await this.getowner_id();

    await this.getcountrie_currency();
    this.operation.currency = this.currency;

    this.operation.owner_id = this.owner.id;
    if (!this.products || this.products.length == 0)
      this.products = await this.operationRule.setUpOperationReasons(
        this.translateService.currentLang,
        await this.translateService.get('operation_reason').toPromise()
      );
    if (this.id != undefined) {
      await this.operationAPI
        .getOneOperation(this.id)
        .subscribe((value: Operation) => {
          this.operation = value;
          if (this.operation.morphable_type.includes('Owner')) {
            this.selectedFamilyMember = {
              name: this.owner.first_name,
              id: this.owner.id,
            };
            this.selectedSegmentValue = 'FamilyMember';
          } else if (this.operation.morphable_type.includes('Family')) {
            this.selectedFamilyMember = value.morphable;
            this.selectedSegmentValue = 'FamilyMember';
          } else {
            this.selectedSegmentValue = 'Farm';
          }
          let cashflow = this.operation?.cashflows?.find(
            (value) => value.operation_id == this.operation.id
          );
          this.operation['payment_methods'] = cashflow.payment_methods;
          this.operation['money_reporting_id'] = cashflow.money_reporting_id;
        });
    }

    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
  }

  async getowner_id() {
    this.owner = await this.userInfo.getOwner();
    this.family_membersAPI.getFamilyMembers().subscribe((value) => {
      this.listf = value;
      this.listf.push({
        name: this.owner.first_name,
        id: this.owner.id,
        file: this.owner.file,
        birth_date: this.owner.birth_date,
      });
    });
  }

  async getcountrie_currency() {
     let country=await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
     this.currency = country.currency
     this.operation.currency = this.currency;
  }

  async get_one_countrie() {
    let country =await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    this.country = country?.id;
  }

  async langageChanger(lang) {
    if (lang == 'es') {
      this.you = 'Usted';
    } else {
      this.you = 'You';
    }
  }

  confirm_method() {
    this.submitForm();
  }

  async showErrorToast(message) {
    await this.toastUtils
      .toastBuilder(
        await this.translateService
          .get(message)
          .toPromise()
          .then((vali) => vali),
        'bottom',
        '',
        'danger'
      );
  }

  handelChangeCurrecy(value) {
    this.operation.currency = value;
  }
  async submitForm() {
    const token = await Storage.get({ key: dev.ACCESS_TOKEN }).then(
      (value) => value.value
    );
    if (this.selectedSegmentValue == 'Farm') {
      this.operation.morphable_type = MORPH_Farm;
      this.operation.morphable_id = this.farm_id;
    }
    if (!this.operation.amount || this.operation.amount == 0) {
      await this.showErrorToast('errors.all_fields');
    } else if (!this.operation.creation_date) {
      await this.showErrorToast('errors.all_fields');
    } else if (!this.operation.morphable_type) {
      await this.showErrorToast('errors.all_fields');
    } else if (
      this.operation.morphable_type === MORPH_FamilyMember &&
      !this.operation.morphable_id
    ) {
      await this.showErrorToast('errors.all_fields');
    } else if (!this.operation.operation_reason) {
      await this.showErrorToast('errors.all_fields');
    } else if (!this.operation.third_part_name) {
      await this.showErrorToast('errors.all_fields');
    } else {
      if (this.selectedSegmentValue == 'Farm') {
        this.operation.morphable_type == MORPH_Farm;
        this.operation.morphable_id = this.farm_id;
      }
      this.operation.operation_type = MORPH_Garentee;
      if (!this.operation.interest_amount) {
        this.operation.is_interest = 0;
        this.operation.interest_amount = 0;
      } else {
        this.operation.is_interest = 1;
      }
      this.operation.title = null;
      this.operation.due_date = '';
      this.loadinModal.present();
      if (this.operation.id) {
        //TODO offline
        this.operationAPI.updateOperation(this.operation, this.id).subscribe(
          async (value: any) => {
            value.payment_methods = this.operation['payment_methods'];
            value.money_reporting_id = this.operation['money_reporting_id'];
            this.operationRule.updateCashflowAfterOperationUpdate(
              value,
              this.modalController,
              this.loadinModal,
              this.popoverController,
              await this.translateService
                .get('operation_post_update')
                .toPromise(),
              this.isDashboardView,
              this.products
            );
          },
          (e) => {
            this.loadinModal.dismiss();
            this.loadinModal.dismiss();
          }
        );
      } else {
        this.operationAPI
          .storeOperation(this.operation)
          .subscribe(async (value: any) => {
            value.payment_methods = this.operation['payment_methods'];
            value.money_reporting_id = this.operation['money_reporting_id'];
            this.operationRule.saveOperationCashflow(
              value,
              this.farm_id,
              this.loadinModal,
              this.modalController,
              !this.isDashboardView,
              this.popoverController,
              await this.translateService
                .get('auto_entry')
                .toPromise()
                .then((value) => value),
              this.isDashboardView,
              this.products
            );
          });
      }
    }
  }

  async familyMemberSelectedChange(ev) {
    let componentProps = {
      data: this.listf,
      component: 'mano-de-obra',
    };
    const popover = await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverImgComponent,
      'pop-over-style',
      ev
    );
    const { data } = await popover.onDidDismiss();
    if (data) {
      this.selectedFamilyMember = data.data;
      if (this.selectedFamilyMember.name === this.owner.first_name) {
        this.operation.morphable_type = MORPH_Owner;
      } else {
        this.operation.morphable_type = MORPH_FamilyMember;
      }

      this.operation.morphable_id = this.selectedFamilyMember.id;
    }
  }

  compareWith(e, e2) {
    return e && e2 ? e?.name === e2?.name : e === e2;
  }
  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  setAmount(value) {
    this.operation.amount = value;
  }

  async saveCashFlow(value) {
    const token = await Storage.get({ key: dev.ACCESS_TOKEN }).then(
      (value) => value.value
    );

    let cashflow = new CashFlow();
    cashflow.amount = value.amount;
    cashflow.currency = this.currency;
    cashflow.is_income = 0;
    cashflow.accounting_entries_categories = this.accounting_entry_category;
    cashflow.cash_flow_categorie_id = this.cashflow_category_id;
    cashflow.operation_id = value.id;
    cashflow.date = new Date().toISOString();
    cashflow.farm_id = this.farm_id;

    //TODO
    this.cashflowAPI.saveCashFlow(cashflow).subscribe(
      (val) => {
        this.loadinModal.dismiss();
        this.modalController.dismiss({
          dismissed: true,
          loan: value,
        });
      },
      (e) => {
        this.loadinModal.dismiss();

        this.modalController.dismiss({
          dismissed: true,
          loan: value,
        });
      }
    );
  }

  pop() {
    const perso = this.products.indexOf(
      (element) => (element.value = 'personal')
    );
    if (!isNaN(perso)) this.products.splice(perso, 1);
    this.operation.operation_reason = null;
  }

  splice() {
    const perso = this.products.find((element) => element.label === 'Personal');
    if (!perso) this.products.push({ label: 'Personal', value: 'personal' });

    this.operation.operation_reason = null;
  }
}
