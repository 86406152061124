import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { Storage } from '@capacitor/storage';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-question-interface-team',
  templateUrl: './question-interface-team.component.html',
  styleUrls: ['./question-interface-team.component.scss'],
})
export class QuestionInterfaceTeamComponent implements OnInit, OnChanges {
  @Input() empType: string;
  language;
  yes_clicked = false;
  no_clicked = false;
  

  @Input() is_perm = true;
  @Output() answeredEvent: EventEmitter<Boolean> = new EventEmitter();

  constructor(
    public router: Router,
    public translateService: TranslateService
  ) {
    this.language = this.translateService
      .get('screens.question_person')
      .subscribe((value) => (this.language = value));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.yesChanges();
  }
  async ngOnInit() {
    await this.langageChanger();
  }

  async langageChanger() {}

  async answerClick(val) {
    if (val) {
      this.yes_clicked = true;
      this.no_clicked = false;
      if (this.is_perm) {
        await Storage.set({ key: 'has_perm', value: '1' });
      } else if (this.is_perm === false) {
        await Storage.set({ key: 'has_temp', value: '1' });
      }
    } else {
      this.yes_clicked = false;
      this.no_clicked = true;
      if (this.is_perm) {
        await Storage.set({ key: 'has_perm', value: '0' });
      } else if (this.is_perm === false) {
        await Storage.set({ key: 'has_temp', value: '0' });
      }
    }
    this.answeredEvent.emit(val);
  }

  async yesChanges() {
    if (this.is_perm) {
      const has_perm = await Storage.get({ key: 'has_perm' }).then(
        (value) => value.value
      );
      if (has_perm === '1') {
        this.answerClick(true);
      } else if (has_perm === '0') {
        this.answerClick(false);
      }
    } else if (this.is_perm === false) {
      const has_perm = await Storage.get({ key: 'has_temp' }).then(
        (value) => value.value
      );
      if (has_perm === '1') {
        this.answerClick(true);
      } else if (has_perm === '0') {
        this.answerClick(false);
      }
    }
  }
}
