import { createAction, props } from '@ngrx/store';
import { Programas } from 'src/app/models/programas';

export const LOAD_PROGRAMS = 'LOAD PROGRAMS';
export const LOAD_PROGRAMS_SUCCESSFULY = 'LOAD PROGRAMS SUCCESSFULY';


export const loadProgramas = createAction(
    LOAD_PROGRAMS,
    props<{ countryId: number}>()
  );
  export const loadProgramSuccessfuly = createAction(
    LOAD_PROGRAMS_SUCCESSFULY,
    props<{ programas: Array<Programas> }>()
  );