import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DebtFromComponent } from './debt-from/debt-from.component';
import { DebtsComponent } from './debts/debts.component';
import appRoutes from '../../config/routing.json';
const routes: Routes = [
  {
    path: '',
    component: DebtsComponent,
  },
  {
    path: appRoutes.debts.debt_form,
    component: DebtFromComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DebtsRoutingModule {}

/*
,
*/
