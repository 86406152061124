import { MORPH_FamilyMember, MORPH_Owner } from '../../../../utils/consts';
import { OperationsAPI } from '../../../../apis/operations.service';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ModalController,
  PopoverController,
  LoadingController,
} from '@ionic/angular';
import {
  CashFlow,
  isArbolesTransaction,
} from 'src/app/models/cash-flow/cashFlow';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../../config/offlines_keys.json';
import { ObjectiveApi } from 'src/app/apis/objectives.service';
import { Good } from 'src/app/models/good.model';
import { GoodsAPI } from 'src/app/apis/goods.service';
import {
  AHOROS_ACCOUNTING_ENTRY_CATEGORY,
  AHORO_PARA_VEJEZ_CASHFLOW_CATEGORIE,
  APORTES_A_PENSION_CASHFLOW_CATEGORIE,
  BEPS_PENSION_PLAN_CASHFLOW_CATEGORIE,
  DEBT_OPERATION,
  LANDING_CASHFLOW_ENTRY,
  OTROS_ACCOUNTING_ENTRY_CATEGORY,
  PERDIDA_ARBOLES_CASHFLOW_CATEGORIES,
  VENTA_ARBOLES_CASHFLOW_CATEGORIES,
  VENTA_ARBOLES_COFFE_CASHFLOW_CATEGORIES,
} from 'src/app/utils/consts';
import { showConfirmModal } from 'src/app/utils/pop_over_maker';
import { LotAPI } from 'src/app/apis/lot.service';
import { EmployeesAPI } from 'src/app/apis/employees.service';
import { UserInfo } from 'src/app/apis/users-info.service';
import { updateGoodAfterCashflowSubmitOtrosProducts } from 'src/app/utils/business-rules/post_submit_cashflow_goods_business';
import { ProductionAPI } from 'src/app/apis/FarmAPI/productions.service';
import { TranslateService } from '@ngx-translate/core';
import { getQtyUpdateDelete } from 'src/app/utils/unit-convert';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';
import { UnifiedCashflowInputComponentComponent } from 'src/app/modules/cashflow/unified-cashflow-input-component/unified-cashflow-input-component.component';
import { Store } from '@ngrx/store';
import { getCashflowById } from 'src/app/utils/states-management/actions/cashflow.action';
import { CAFE_ACCOUNTING_ENTRY_CATEGORY } from '../../../../utils/consts';
import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { CurrencyPipe } from '@angular/common';
import { cloneDeep } from 'lodash';
import { environment } from 'src/environments/environment';
import { lastValueFrom, take } from 'rxjs';
import { getTotalsAccountingCategoriesResult } from 'src/app/utils/states-management/selector/dashboard.selector';
@Component({
  selector: 'app-book-single-entry',
  templateUrl: './book-single-entry.component.html',
  styleUrls: ['./book-single-entry.component.scss'],
})
export class BookSingleEntryComponent implements OnInit, OnChanges {
  @Input() entry: CashFlow = new CashFlow();
  @Input() country;
  main_endpoint = environment.appRoot;

  @Output() deleteMePlease: EventEmitter<any> = new EventEmitter();
  @Input() production: Array<ProductionsPerfarm> = new Array();
  started: boolean = false;
  LANDING_CASHFLOW_GENERATED_ENTRIES = LANDING_CASHFLOW_ENTRY;
  AHOROS_ACCOUNTING_ENTRY_CATEGORY = AHOROS_ACCOUNTING_ENTRY_CATEGORY;
  user;
  cashflowList;
  selectedLangage;
  unit;
  lang;
  MORPH_FamilyMember = MORPH_FamilyMember;
  MORPH_Owner = MORPH_Owner;
  loadingModal;
  CAFE_ACCOUNTING_ENTRY_CATEGORY = CAFE_ACCOUNTING_ENTRY_CATEGORY;
  constructor(
    private secondaryModalController: ModalController,
    private popover: PopoverController,
    private goodsAPI: GoodsAPI,
    private objectiveApi: ObjectiveApi,
    private userInfo: UserInfo,
    private lotApi: LotAPI,
    private operationAPI: OperationsAPI,
    private productionAPI: ProductionAPI,
    public translateService: TranslateService,
    private employeeAPI: EmployeesAPI,
    private store: Store,
    private loadingCtrl: LoadingController,
    private toastUtils: ToastUtils,
    private currencyPipe: CurrencyPipe
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (this.production) {
      if (this.entry.product_id) {
        this.entry.production_per_farm = this.production.find(
          (element) =>
            element.morphable_id === this.entry.product_id && !element.is_animal
        );
      } else if (
        this.entry.material_category &&
        this.entry.accounting_entries_categories ===
          OTROS_ACCOUNTING_ENTRY_CATEGORY
      ) {
        this.entry.production_per_farm = this.production.find(
          (element) =>
            element.morphable_id === this.entry.material_category.id &&
            element.is_animal
        );
      }
    }
  }

  async ngOnInit() {
    await this.translate();
    this.user = await Storage.get({ key: dev.USER_KEY }).then((value) =>
      JSON.parse(value.value)
    );

    if (this.lang == 'es') {
      this.unit = 'Unidad(es)';
    } else {
      this.unit = 'Unit';
    }
  }
  harvertQTY() {
    let msg =
      this.translateService.instant('coffe_harvested') +
      ' ' +
      this.currencyPipe.transform(
        this.entry?.morphable?.quantity_product
          ? this.entry?.morphable?.quantity_product
          : this.entry?.quantity
          ? this.entry?.quantity
          : '',
        undefined,
        '',
        '1.2-2',
        'fr-FR'
      ) +
      ' ' +
      this.translateService.instant('kilo');
    this.toastUtils.popOverBuilder(
      {
        key1: msg,
      },
      PopoverComponent,
      'popover-custom'
    );
  }

  async translate() {
    this.lang = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );
  }

  async updateElement(entry) {
    // this.store.dispatch(getCashflowById({ id: comment.id }));
    await this.presentModal(entry);
  }

  async presentModal(cashflow: CashFlow) {
    let comp = UnifiedCashflowInputComponentComponent;
    const { data } = await lastValueFrom(
      this.store.select(getTotalsAccountingCategoriesResult).pipe(take(1))
    );
    const modal = await this.secondaryModalController.create({
      component: comp,
      cssClass: ['my-custom-modal-css ', 'modal-wrapper'],
      componentProps: {
        cashFlowEntrieName: cashflow.accounting_entries_categories,
        modalController: this.secondaryModalController,
        cashflow: cloneDeep(cashflow),
        is_update: true,
        accountingEntryCategories: data?.find(
          (element) => element.id === cashflow.accounting_entries_categories
        ),
      },
    });

    await modal.present();
    this.loadingModal = await this.loadingCtrl.create({
      spinner: 'bubbles',
      mode: 'ios',
    });

    await modal.onWillDismiss();
    // this.loadingModal.present();

    this.deleteMePlease.emit(null);
    // setTimeout(() => {
    //   this.loadingModal.dismiss();
    // }, 1000);
  }

  async getCategories(cashFlowEntrieName) {
    return await Storage.get({
      key: dev.CASHFLOW_CATEGORIES,
    }).then((value) => {
      let categories = JSON.parse(value.value);
      return categories[cashFlowEntrieName.toString()];
    });
  }

  async deleteAhorosCashflow() {
    const is_confirmed = await this.showModalAndDelete([this.entry]);
    if (!is_confirmed) return;
    if (
      ![
        APORTES_A_PENSION_CASHFLOW_CATEGORIE,
        AHORO_PARA_VEJEZ_CASHFLOW_CATEGORIE,
        BEPS_PENSION_PLAN_CASHFLOW_CATEGORIE,
      ].includes(this.entry.cash_flow_categorie_id)
    ) {
      let objective = this.entry.morphable;
      this.objectiveApi.getObjectives().subscribe((val) => {
        if (Array.isArray(val)) {
          const object = val.find((element) => element.id === objective.id);
          object.gather_so_far -= this.entry.amount;
          this.objectiveApi.updateObjective(object).subscribe((val) => {
            val;
            this.deleteMePlease.emit(this.entry);
          });
        }
      });
    }
  }

  async deleteCashfLowNoPostActionRequired() {
    if (this.entry.operation) {
      if (
        (this.entry.operation.operation_type === DEBT_OPERATION &&
          this.entry.is_income === 1) ||
        (this.entry.operation.operation_type !== DEBT_OPERATION &&
          this.entry.is_income === 0)
      ) {
        this.operationAPI.getOperation().subscribe((item: any) => {
          this.cashflowList = item.filter((value) => {
            return (
              value.operation_type == this.entry.operation.operation_type &&
              value.id == this.entry.operation.id
            );
          });
          this.showModalAndDelete(this.cashflowList);
        });
      } else {
        this.showModalAndDelete([this.entry]);
      }
    } else if (
      this.entry.morphable &&
      this.entry.morphable_type.toLowerCase().includes('sala')
    ) {
      this.deleteSalary();
    } else {
      this.showModalAndDelete([this.entry]);
    }
  }

  async deleteCoffeeCashflow() {
    if (isArbolesTransaction(this.entry)) {
      this.entry.cash_flow_categorie_id ===
      VENTA_ARBOLES_COFFE_CASHFLOW_CATEGORIES
        ? (this.entry.production_per_farm.trees_numbers += this.entry.quantity)
        : (this.entry.production_per_farm.trees_numbers -= this.entry.quantity);
      await this.productionAPI
        .updateProduction(this.entry.production_per_farm)
        .toPromise();
    } else if (this.entry.product_id && this.entry.quantity)
      await this.updateGoodsTable();
    this.deleteCashflow();
  }

  async deletePropiedadesCashflow() {
    const is_confirmed = await this.showModalAndDelete([this.entry]);
    if (!is_confirmed) return;
    if (this.entry.id && this.entry.material_product_id) {
      const allGoods = await this.goodsAPI.getGoodsByUser().toPromise();
      let good = allGoods.find(
        (element) =>
          element.material_product_id === this.entry.material_product_id
      );
      if (good) {
        if (!this.entry.is_income) {
          good.qty -= this.entry.quantity;
        } else {
          good.qty += this.entry.quantity;
        }
        await this.goodsAPI.updateGood(good).toPromise();
      }
    } else if (this.entry.id && this.entry.morphable_id) {
      if (this.entry.is_income) {
        if (this.entry.morphable && !this.entry.morphable.is_owned) {
          this.lotApi
            .unsellLot(this.entry.morphable_id)
            .subscribe(async (val) => {
              await this.persistLotUpdateLocalStorage(val);
            });
        } else {
          this.showModalAndDelete([this.entry]);
        }
      } else {
        this.lotApi
          .deleteLot(this.entry.morphable_id)
          .subscribe(async (val) => {
            val;
            await this.removeFromLocalStorage(this.entry.morphable_id);
          });
      }
    } else {
      this.deleteCashflow();
    }
  }

  async persistLotUpdateLocalStorage(updatedLot) {
    const lot_full_list = await Storage.get({
      key: dev.LOT_KEY,
    }).then((val) => JSON.parse(val.value));

    lot_full_list.forEach((element, index, array) => {
      if (element.id === updatedLot.id) {
        lot_full_list.splice(index, 1);
      }
    });
    lot_full_list.push(updatedLot);
    await Storage.set({
      key: dev.LOT_KEY,
      value: JSON.stringify(lot_full_list),
    });
  }

  deleteCashflow() {
    this.deleteMePlease.emit(this.entry);
  }

  async showModalAndDelete(cashflow, isCoffe = false) {
    let modal = await showConfirmModal(
      this.popover,
      await this.translateService
        .get('confirm_delete_cashflow_entry')
        .toPromise()
        .then((value) => value),
      cashflow,
      await this.translateService.get('confirm').toPromise(),
      await this.translateService.get('cancel').toPromise()
    );
    const { data } = await modal.onWillDismiss();
    if (data?.confirmed === true && !isCoffe) {
      this.deleteCashflow();
      return true;
    } else if (data?.confirmed === true && isCoffe) {
      this.deleteCoffeeCashflow();
      return true;
    }
    return false;
  }

  async removeFromLocalStorage(lot_id) {
    const lot_full_list = await Storage.get({
      key: dev.LOT_KEY,
    }).then((val) => JSON.parse(val.value));

    lot_full_list.forEach((element, index, array) => {
      if (element.id === lot_id) {
        lot_full_list.splice(index, 1);
      }
    });
    await Storage.set({
      key: dev.LOT_KEY,
      value: JSON.stringify(lot_full_list),
    });
  }

  async deleteSalary() {
    await this.employeeAPI.deleteSalary(this.entry.morphable_id).toPromise();
    this.deleteCashflow();
  }

  updateMoneyReporting() {
    this.deleteMePlease.emit(this.entry);
  }

  async deleteOtros() {
    if (this.entry.operation) this.deleteCashfLowNoPostActionRequired();
    else if (isArbolesTransaction(this.entry)) {
      if (
        [
          PERDIDA_ARBOLES_CASHFLOW_CATEGORIES,
          VENTA_ARBOLES_CASHFLOW_CATEGORIES,
        ].includes(this.entry.cash_flow_categorie_id)
      ) {
        this.entry.production_per_farm.trees_numbers += this.entry.quantity;
      } else {
        this.entry.production_per_farm.trees_numbers -= this.entry.quantity;
      }
      this.productionAPI
        .updateProduction(this.entry.production_per_farm)
        .subscribe((value) => {
          this.deleteCashflow();
        });
    } else {
      await this.updateGoodsTable();
      this.deleteCashflow();
    }
  }

  async updateGoodsTable() {
    const owner = await this.userInfo.getOwner();

    this.goodsAPI.getGoodsByUser().subscribe(async (values: Array<Good>) => {
      if (this.entry.product_id)
        this.entry.modelBeingUpdated = values.find(
          (element) => element.product_id === this.entry.product_id
        );
      else if (this.entry.material_product_id)
        this.entry.modelBeingUpdated = values.find(
          (element) =>
            element.material_product_id === this.entry.material_product_id
        );

      this.entry.modelBeingUpdated.qty_update = getQtyUpdateDelete(this.entry);
      updateGoodAfterCashflowSubmitOtrosProducts(
        this.entry,
        this.goodsAPI,
        null
      ).subscribe((val) => {
        return val;
      });
    });
  }
  async showPopOver() {
    let componentProps = {
      key1: await this.translateService.instant('offline'),
    };
    await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
  }
}
