import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {  createReducer, on } from '@ngrx/store';
import { Countrie } from 'src/app/models/countrie.model';
import { loadCountriesSuccessfully, setUserCountry } from '../actions/countries.action';

export interface CountriesState extends EntityState<Countrie> {
    user_country: Countrie;
    list_countries : Array<Countrie>
  }

  export function selectedCountryId(a: Countrie): number {
    return a.id ? a.id : 0;
  }
  

  export const countriesAdapter: EntityAdapter<Countrie> = createEntityAdapter<Countrie>({
    selectId: selectedCountryId,
  });
  export const initialCountriesState: CountriesState = countriesAdapter.getInitialState({
    user_country: null,
    list_countries : []
  });
  
  export const countriesReducer = createReducer(
    initialCountriesState,
    on(loadCountriesSuccessfully, (state, result) => {
        
        return  {
          ...state,
          list_countries : result.list_countries,
        }
      }),
      on(setUserCountry, (state, result) => {
        return  {
          ...state,
          user_country : result.country,
        }
      }),
  )