import { RoutingConfig } from 'src/app/utils/routing-config';
import { MORPH_FamilyMember, MORPH_Owner } from './../../../utils/consts';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, Input, OnInit } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../config/offlines_keys.json';
import { OperationsAPI } from '../../../apis/operations.service';
import { ModalMoneyLoanComponent } from '../modal-money-loan/modal-money-loan.component';
import { Router } from '@angular/router';
import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import { PriorDashboardComponent } from '../../prior-dashboard/prior-dashboard.component';
import { environment } from '../../../../environments/environment';
import { CashflowSelectorComponent } from '../../dashboard/widgets/cashflow-selector/cashflow-selector.component';
import { TranslateService } from '@ngx-translate/core';
import { MORPH_Farm } from 'src/app/utils/consts';

@Component({
  selector: 'app-money-loan',
  templateUrl: './money-loan.component.html',
  styleUrls: ['./money-loan.component.scss'],
  providers: [OperationsAPI],
})
export class MoneyLoanComponent implements OnInit {
  @Input() nav: IonNav;
  @Input() rootStack;
  @Input() isDashboardView;
  has_prestamo = false;
  list: any;
  you = 'usted';
  owner_id: number;
  main_endpoint = environment.appRoot;
  MORPH_Farm = MORPH_Farm;
  MORPH_FamilyMember = MORPH_FamilyMember;
  MORPH_Owner = MORPH_Owner;
  constructor(
    public modalController: ModalController,
    public translateService: TranslateService,
    private router: Router,
    private operationAPI: OperationsAPI,
    private toastUtils: ToastUtils,
    private routingConfig: RoutingConfig
  ) {}

  async presentModal() {
    let modal = await this.toastUtils.modalBuilder(
      ModalMoneyLoanComponent,
      {},
      ['my-custom-modal-css', 'modal-wrapper']
    );

    modal.onDidDismiss().then(() => this.fetchData());
  }

  async presentupdateModal($id: number) {
    let modal = await this.toastUtils.modalBuilder(
      ModalMoneyLoanComponent,
      { id: $id },
      ['my-custom-modal-css', 'modal-wrapper']
    );
    modal.onDidDismiss().then(() => this.fetchData());
  }
  async getowner_id() {
    await Storage.get({ key: dev.USER_KEY }).then((value) => {
      this.owner_id = JSON.parse(value.value)?.id;
    });
  }

  async ngOnInit() {
    const lang = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );
    this.langageChanger(lang);
    await this.getowner_id();
    this.fetchData();
    this.showPopOver();

    if (this.isDashboardView) this.has_prestamo = true;
  }

  async showPopOver() {
    let componentProps = {
      key1: await this.translateService
        .get('bubble_desc_loan')
        .toPromise()
        .then((value) => value),
    };

    await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
  }

  langageChanger(lang) {
    if (lang == 'es') {
      this.you = 'usted';
    } else {
      this.you = 'you';
    }
  }

  async fetchData() {
    this.operationAPI.getOperation().subscribe((item) => {
      if (Array.isArray(item)) {
        this.list = item.filter((value) => {
          return value.operation_type == 'money loan';
        });
        this.prepareList(this.list);
      }
    });
  }
  prepareList(val) {
    if (Array.isArray(val)) {
      val.forEach((element) => {
        element.calculatedPercent = this.calculatePercent(
          element.amount,
          element.paid_so_far
        );
        element.display_percent =
          (100 * this.calculatePercent(element.amount, element.paid_so_far))
            .toFixed(2)
            .toString() + '%';
      });
    }
  }

  calculatePercent(required_amount, gathered_amount) {
    if (!gathered_amount) {
      gathered_amount = 0;
    }
    return gathered_amount / required_amount;
  }

  async delete(item) {
    if (this.isDashboardView) {
      let componentProps = {
        modalController: this.modalController,
        cashflows: item.cashflows,
        operation: item,
      };

      if (item.cashflows.length > 0) {
        let modal = await this.toastUtils.modalBuilder(
          CashflowSelectorComponent,
          componentProps,
          ['my-custom-modal-css', 'modal-wrapper']
        );

        modal.present();
        const { data } = await modal.onWillDismiss();
        if (data.deleteLegth) {
          if (
            Array.isArray(item.cashflows) &&
            data.deleteLegth === item.cashflows.length &&
            data.deleteLegth !== 0
          ) {
            this.operationAPI.deleteOperation(item.id).subscribe(
              (value) => {
                this.fetchData();
              },
              (e) => {
                if (e.status == 200) this.fetchData();
              }
            );
          }
        }
      } else await this.deleteOp(item);
    } else await this.deleteOp(item);
  }
  async deleteOp(item) {
    this.operationAPI.deleteOperation(item.id).subscribe(
      (value) => {
        this.fetchData();
      },
      (e) => {
        if (e.status == 200) this.fetchData();
      }
    );
  }
  updateForm($id: number) {
    this.presentupdateModal($id);
  }

  async mnext() {
    this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }
  async mreturn() {
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());
  }

  goBackToDashBoard() {
    this.nav.setRoot(this.rootStack, {
      nav: this.nav,
      rootStack: PriorDashboardComponent,
    });
  }

  answeredEventChange(value) {
    this.has_prestamo = value;
    if (!value) this.mnext();
  }

  // pop() {
  //   const perso = this.products.find((element) => element === 'Personal');
  //   if (perso) this.products.shift();
  // }
  // splice() {
  //   const perso = this.products.find((element) => element === 'Personal');
  //   if (!perso) this.products.unshift('Personal');
  // }
}
