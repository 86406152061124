import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements OnInit {
  @Input() start_date = new Date(new Date().getFullYear(), 0, 1).toISOString();
  @Input() end_date =  new Date(new Date().getFullYear(), 12, 31).toISOString();
  @Input() disabled =  false
  @Output() DateChange: EventEmitter<any> = new EventEmitter();
  today;
  start_date_limit;
  end_date_limit;
  constructor(public translateService: TranslateService) {}
  async ngOnInit() {
    this.today = new Date();

    if (!this.start_date) {
      this.start_date = new Date().toISOString();
    }
    if (!this.end_date) {
      this.end_date = new Date().toISOString();
    }
  }
  handelDateChange(e, data_type) {
    if (data_type == 'start_date') {
      if (this.start_date > this.end_date) this.end_date = null;
    } else if (data_type == 'end_date') {
      if (this.end_date < this.start_date) this.start_date = null;
    }
    this.DateChange.emit({
      start_date: this.start_date,
      end_date: this.end_date,
    });
  }
}
