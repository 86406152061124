import { filter } from 'rxjs/operators';
import { addEmployee, deleteEmployee } from './../actions/employee.action';
import { cloneDeep } from 'lodash';
import {
  addFamilyMember,
  addFamilyMemberSuccess,
  deleteFamilyMember,
  loadFamilyMember,
  updateFamilyMember,
  updateFamilyMemberSuccess,
} from './../actions/family-members.action';
import { Family_member } from 'src/app/models/family_member.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  deleteFamilyMemberSuccess,
  loadFamilyMemberSuccess,
} from '../actions/family-members.action';

export interface FamilyMemberState extends EntityState<Family_member> {
 //  additional entity state properties
  selectedFamilyMemberId: number | null;
  loading: boolean;
  error: any;
}

export function selectMemberById(a: Family_member): string {
  //In this case this would be optional since primary key is id
  return a.id || new Date().getTime();
}
export const familyMemberAdapter: EntityAdapter<Family_member> =
  createEntityAdapter<Family_member>({
    selectId: selectMemberById,
  });
export const initialState: FamilyMemberState =
  familyMemberAdapter.getInitialState({
    // additional entity state properties
    selectedFamilyMemberId: null,
    loading: false,
    error: null,
  });
export const familyMembersReducer = createReducer(
  initialState,
  on(loadFamilyMember, (state, action) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),
  on(loadFamilyMemberSuccess, (state, action) => {
    return familyMemberAdapter.setAll(action.familyMembers, {
      ...state,
      loading: false,
    });
  }),
  on(addFamilyMember, (state, action: any) => {
    let familyMember = cloneDeep(action.data);
    familyMember.id = 'offline-'+action?.meta?.offlineId;
    return familyMemberAdapter.addOne(familyMember, {
      ...state,
      loading: true,
    });
  }),
  on(addFamilyMemberSuccess, (state, action: any) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  }),
  on(updateFamilyMember, (state, action: any) => {
    let updatedData = cloneDeep(action.data);
    updatedData.offlineOperationId = action?.meta?.offlineId;
    return familyMemberAdapter.updateOne(
      { id: action.data.id, changes: updatedData },
      {
        ...state,
        loading: true,
      }
    );
  }),

  on(updateFamilyMemberSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  }),
  on(deleteFamilyMember, (state, action) => {
    return familyMemberAdapter.removeOne(action.id, {
      ...state,
      loading: true,
    });
  }),
  on(deleteFamilyMemberSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  }),

  on(addEmployee, (state, action) => {

    return familyMemberAdapter.updateOne(
      { id: action.data.id, changes: action.data },
      {
        ...state,
        loading: false,
      }
    );
  }),
  on(deleteEmployee, (state, action) => {
    console.log(action)
    let data =  cloneDeep(action.data)
    data['employee'] = null

    return familyMemberAdapter.updateOne(
      { id: action.data.id, changes: data },
      {
        ...state,
        loading: false,
      }
    );
  }),
  on(addEmployee, (state, action) => {

    return familyMemberAdapter.updateOne(
      { id: action.data.id, changes: action.data },
      {
        ...state,
        loading: false,
      }
    );
  }),
);
