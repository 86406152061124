import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IonNav } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import { StatisticsAPI } from 'src/app/apis/statistics.service';
import { getDisplayableUnit } from 'src/app/utils/unit-convert';

interface FilterParams {
  start_date: string;
  end_date: string;
}

@Component({
  selector: 'app-analysis-insumos-barchart',
  templateUrl: './analysis-insumos-barchart.component.html',
  styleUrls: ['./analysis-insumos-barchart.component.scss'],
})
export class AnalysisInsumosBarchartComponent implements OnInit, AfterViewInit {
  @Input() nav: IonNav;
  @Input() expenses_id;
  @Input() accounting_id
  result: any;
  @ViewChild('lineChart') lineChartCanvas: ElementRef;
  lineChart;
  params: FilterParams = {
    // start_date: new Date().toISOString(),
    // end_date: new Date().toISOString(),
    start_date: '2017-1-1',
    end_date: '2023-1-1',
  };
  constructor(
    public translateService: TranslateService,
    private statistic: StatisticsAPI
  ) { }

  ngOnInit() { }

  fetchData() {
    this.statistic
      .insumosUsage(
        this.params.start_date,
        this.params.end_date,
        this.expenses_id, 
        this.accounting_id
      )
      .subscribe(async (value) => await this.drawBubbleChart(value));
  }
  ngAfterViewInit(): void {
    this.fetchData();
  }

  async drawBubbleChart(value) {
    if (this.lineChart) this.lineChart.destroy();
    const labels = new Array<string>();
    let dataSets = new Array();
    const objectKeys = Object.keys(value);
    var formatedValues = new Array<{
      value: Array<number>;
      unitName: string;
    }>();

    objectKeys.forEach((dataKeys) => {
      var keys = Object.keys(value[dataKeys]);
      labels.push(dataKeys);
      var label = '';
      keys.forEach((unitName) => {
        label = unitName;
        const alreadyExist = formatedValues.find(
          (element) => element.unitName == unitName
        );
        if (!alreadyExist)
          formatedValues.push({
            value: [value[dataKeys][unitName]],
            unitName: unitName,
          });
        else alreadyExist.value.push(value[dataKeys][unitName]);
      });
    });
    formatedValues.forEach((formatValue) => {
      dataSets.push({
        label: this.translateService.instant(getDisplayableUnit(formatValue.unitName).value),
        data: formatValue.value,
        backgroundColor: [this.getRandomRgb()],
        borderColor: [this.getRandomRgb()],
        borderWidth: 1,
      });
    });
    this.translateService.onLangChange;
    const data = {
      labels: objectKeys,
      datasets: dataSets,
    };
    this.lineChart = new Chart(this.lineChartCanvas.nativeElement, {
      type: 'bar',
      data: data,
      options: {
        plugins: {
          title: {
            display: true,
            text: await this.translateService.get('label.inputs').toPromise(),
            // 'this.chartsLabels.workforce_stats',
          },
        },
        responsive: true,
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            beginAtZero: true,
          },
        },
      },
    });
  }
  getRandomRgb() {
    var num = Math.round(0xffffff * Math.random());
    var r = num >> 16;
    var g = (num >> 8) & 255;
    var b = num & 255;
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}
