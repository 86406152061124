import { CurrencySelectorComponent } from './../currency-selector/currency-selector.component';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FooterComponentComponent } from './footer-component.component';
import { LandingHeaderComponent } from '../landing-header/landing-header.component';
import { ModalHeaderComponent } from '../modal-header/modal-header.component';
import { FooterDashboardComponent } from '../footer-dashboard/footer-dashboard.component';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { FormatNumber } from 'src/app/utils/number_formatter';
import { YesNoQuestionWidgetsComponent } from '../yes-no-question-widgets/yes-no-question-widgets.component';
import { CurrencyInputDirective } from 'src/app/utils/currency_input';
import { CurrencyInputComponenetComponent } from '../currency-input-componenet/currency-input-componenet.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  ConvertValueToMetric,
  GetDisplayableUnit,
  GetRealGoodTransactionValues,
  GetUnitKey,
  GetUnitListByProductID,
} from 'src/app/utils/unit-convert';
import { FieldsDynamicChecker } from 'src/app/utils/dynamic-forms/FieldsDynamicChecker';
import { LastUpdatedAtComponent } from '../last-updated-at/last-updated-at.component';
import { ConfirmNumberUpdateComponent } from '../confirm-number-update/confirm-number-update.component';
import { OwnerEffect } from 'src/app/utils/states-management/effects/owner.effect';
import { EffectsModule } from '@ngrx/effects';
import { SanitizeHtmlPipe } from 'src/app/utils/pipes-utils/sanitizeHtml';
import { DateFormatPipe, DateFormatShort } from 'src/app/utils/pipes-utils/date_formatter';
import { AutofocusDirective } from 'src/app/utils/autofocus.directive';
import { ItemListComponent } from '../item-list/item-list.component';
import { AnalysisPageModule } from 'src/app/modules/home/analysis/analysis.module';
import { HomeFilterComponent } from 'src/app/modules/home/widgets/home-filter/home-filter.component';
import { BookPageModule } from 'src/app/modules/home/book/book.module';
@NgModule({
  declarations: [
    SanitizeHtmlPipe,
    GetRealGoodTransactionValues,
    FooterComponentComponent,
    // LandingHeaderComponent,
    ModalHeaderComponent,
    FooterDashboardComponent,
    ConfirmModalComponent,
    FormatNumber,
    YesNoQuestionWidgetsComponent,
    CurrencyInputDirective,
    CurrencyInputComponenetComponent,
    CurrencySelectorComponent,
    GetDisplayableUnit,
    GetUnitListByProductID,
    FieldsDynamicChecker,
    ConvertValueToMetric,
    LastUpdatedAtComponent,
    ConfirmNumberUpdateComponent,
    DateFormatPipe,
    DateFormatShort,
    AutofocusDirective,
    ItemListComponent,
    HomeFilterComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    FormsModule,
    IonicModule.forRoot({ animated: true }),
    EffectsModule.forFeature([OwnerEffect]),

  ],
  exports: [
    SanitizeHtmlPipe,
    FieldsDynamicChecker,
    GetRealGoodTransactionValues,
    GetUnitListByProductID,
    FooterComponentComponent,
    // LandingHeaderComponent,
    ModalHeaderComponent,
    FooterDashboardComponent,
    ConfirmModalComponent,
    FormatNumber,
    YesNoQuestionWidgetsComponent,
    CurrencyInputDirective,
    CurrencyInputComponenetComponent,
    CurrencySelectorComponent,
    GetDisplayableUnit,
    ConvertValueToMetric,
    ConfirmNumberUpdateComponent,
    LastUpdatedAtComponent,
    DateFormatPipe,
    DateFormatShort,
    AutofocusDirective,
    ItemListComponent,
    HomeFilterComponent
  ],
  providers: [
    CurrencyPipe,
    FormatNumber,
    ConvertValueToMetric,
    SanitizeHtmlPipe,
    DateFormatPipe,
    DateFormatShort,
    GetUnitListByProductID
  ],
})
export class FooterModule {}
