import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MaterialProductAPI } from '../../../../apis/material_product.service';
import { UserInfo } from 'src/app/apis/users-info.service';
import { AnimationUtils } from 'src/app/utils/animations';
@Component({
  selector: 'app-material-product-selector',
  templateUrl: './material-product-selector.component.html',
  styleUrls: ['./material-product-selector.component.scss'],
})
export class MaterialProductSelectorComponent implements OnInit {
  @Output() itemSelectedEvent: EventEmitter<any> = new EventEmitter();
  values: any;
  lang;
  constructor(
    private materialProductAPI: MaterialProductAPI,
    private animationUtils: AnimationUtils,
    private userInfo: UserInfo
  ) {}

  async ngOnInit() {
    this.lang = await this.userInfo.getSelectedLangage();
    const country = await this.userInfo.getCountry();
    this.materialProductAPI.getProducts(country.id).subscribe((value: any) => {
      this.values = value;
      this.values.forEach((element, index) => {
        element.material_products.forEach((products, index) => {
          if (products.name.toLowerCase().includes('otr')) {
            element.material_products.splice(index, 1);
            element.material_products.push(products);
          }
        });
        element.is_expanded = false;
      });
    });
  }

  selected(item) {
    item.unit = 'unit';
    item.qty = 0;
    this.itemSelectedEvent.emit(item);
  }

  async itemClicked(item) {
    if (!item.is_expanded) {
      await this.animationUtils.expandAnimation({ id: item.title + 'title' });
      item.is_expanded = true;
    } else {
      await this.animationUtils.collapsAnimation({ id: item.title + 'title' });
      item.is_expanded = false;
    }
  }
}
