import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  familyMemberAdapter,
  FamilyMemberState,
} from '../reducers/family.reducer';
import { OwnerState } from '../reducers/owner.reducer';
import { ownerState } from './owner.selector';
import { state } from '@angular/animations';

export const familySelector =
  createFeatureSelector<FamilyMemberState>('familyMembers');

export const selectAllFamilyMembers = createSelector(
  familySelector,
  familyMemberAdapter.getSelectors().selectAll
);

export const selectEmployeesFamilyMembers = createSelector(
  familySelector,
  (familyMember) =>
    Array.from(Object.values(familyMember.entities)).filter(
      (x) => x?.employee && x?.id && !x?.id?.toString().includes('offline')
    )
);

export const selectEmployees = createSelector(
  selectEmployeesFamilyMembers,
  ownerState,
  (state1, state2) => {
    let Employees = [];
    state1.forEach((val) => Employees.push(val.employee));
    if(state2?.employee)  Employees.push(state2.employee);
    return Employees;
  }
);

export const selectFamilyMemberById = (id: number) =>
  createSelector(familySelector, (allItems: any) => {
    if (allItems?.entities[id]) {
      return allItems?.entities[id];
    } else {
      return null;
    }
  });
