import { Component, Input, OnInit } from '@angular/core';
import {MatExpansionModule} from '@angular/material/expansion';
import { IonNav } from '@ionic/angular';

@Component({
  selector: 'app-question-answers',
  templateUrl: './question-answers.component.html',
  styleUrls: ['./question-answers.component.scss'],
})
export class QuestionAnswersComponent implements OnInit {
  @Input() rootParams;
  tab;
  constructor() { }
  listQuestion = []
  ngOnInit() {
    this.tab= this.rootParams.title
    this.listQuestion = this.rootParams?.config
  }

  tabChanged(){
    
  }
}
