import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { ClustersAPI } from 'src/app/apis/clusters.service';
import { dev } from '../../config/offlines_keys.json';
import { PopoverChecklistComponent } from 'src/app/widgets/popover-checklist/popover-checklist.component';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, take } from 'rxjs';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-ambassador-cofidentiality',
  templateUrl: './ambassador-cofidentiality.component.html',
  styleUrls: ['./ambassador-cofidentiality.component.scss'],
})
export class AmbassadorCofidentialityComponent implements OnInit {
  ambassador;
  checkedList = '';
  list;
  lang;

  constructor(
    private clusterAPI: ClustersAPI,
    private toastUtils: ToastUtils,
    public translateService: TranslateService,
    private store:Store
  ) {}

  async ngOnInit() {
    Storage.get({ key: 'embasador_permission' }).then((value) => {
      if (value.value) {
        this.checkedList = value.value;
      }
    });
    const val = await lastValueFrom(this.store.select(getFarm).pipe(take(1)))
    this.clusterAPI
      .getAmbassadorById(val?.ambassador_id)
      .subscribe((val: any) => {
        this.ambassador = val?.name;
      });
    this.lang = this.translateService.currentLang;
    this.list = [
      'check_list_items.no_information',
      'check_list_items.Information_only_about_farm',
      'check_list_items.Information_about_farm_and_home',
    ];
  }

  async handelChangeCheck(ev) {
    const popover = await this.toastUtils.popOverBuilder(
      {
        data: this.list,
        title: 'share_with_ambassadeur',
      },
      PopoverChecklistComponent,
      'pop-over-style-check',
      ev
    );

    const { data } = await popover.onDidDismiss();
    if (data && data.selectedValue) {
      this.checkedList = data.selectedValue;
      Storage.set({ key: 'embasador_permission', value: this.checkedList });
    } else {
      this.checkedList = '';
    }
  }
}
