import { Countrie } from './../../models/countrie.model';
import { filter, take } from 'rxjs/operators';
import { environment } from './../../../environments/environment.prod';
import { PopoverImgComponent } from './../popover-img/popover-img.component';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { UserInfo } from 'src/app/apis/users-info.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { cloneDeep } from 'lodash';
import { Store } from '@ngrx/store';
import Owner from 'src/app/models/owner.model';

@Component({
  selector: 'app-lang-popover',
  templateUrl: './lang-popover.component.html',
  styleUrls: ['./lang-popover.component.scss'],
})
export class LangPopoverComponent implements OnInit {
  list: any = [
    { name: 'Español', name_en: 'Español', key: 'es', file:{
      path :'/storage/images/flags/es.png'
    }},
    { name: 'English', name_en: 'English', key: 'en', file:{
      path :'/storage/images/flags/uk.png'
    } },
    { name: 'Français', name_en: 'Français', key: 'fr', file:{
      path :'/storage/images/flags/fra.png'
    }
  }
  ];
  selectedLang
  select_langage
  base_endpoint = environment.appRoot;
  @Input() country;
  selectedLand
  constructor(public popover: PopoverController,
    public userInfo:UserInfo,
    public translateService:TranslateService,
    public store:Store<{owner:Owner}>,
    public modalController:PopoverController) {
   
    }
  async handelChangeCountrieName(ev: any) {
    const popover = await this.modalController.create({
      component: PopoverImgComponent,
      event: ev,
      translucent: true,
      componentProps: { data: this.list, component: 'lang' },
    });

    popover.present();
    const { data } = await popover.onDidDismiss();
    if (data) {
      this.selectedLand = data.data.name;
      this.select_langage =  data.data.key
      this.translateService.use(this.select_langage);
      this.handelLangage();
      this.modalController.dismiss({langKey : this.select_langage});

    }
  }
  async ngOnInit() {
    // this.selectedLang = this.translateService.currentLang;
    let owner = cloneDeep(
      await this.store.select('owner').pipe(take(1)).toPromise()
    );
    let config = this.country?.config ? JSON.parse(this.country?.config): owner.countrie ? JSON.parse(owner.countrie?.config) : {}
    this.list = this.list.filter((value)=> config?.language.includes(value?.key))
    // this.selectedLand = this.list.find(l => l.key === this.translateService.currentLang)
  }
  

  ClosePopover() {
    this.popover.dismiss({ closed : true });
  }

  handelChangeLang(item) {
    if (item === 'Español') {
      this.select_langage = 'es';
      this.translateService.use('es');
      this.handelLangage();
    } else if (item === 'English') {
      this.select_langage = 'en';
      this.translateService.use('en');
      this.handelLangage();
    }
     this.modalController.dismiss();
  }
  show(item) {
    this.handelChangeLang(item);
  }

  async handelLangage() {
    await this.userInfo.setSelectedLangage(this.select_langage);
  }

}
