import { TotalsAccountingCategories } from './../reducers/accounting-entries.reducer';
import { createAction, props } from '@ngrx/store';
import { AccountingEntryCategory } from 'src/app/models/cash-flow/cashflow_categories';

export const ACCOUNTING_ENTRIES_LOADING = 'ACCOUNTING_ENTRIES_LOADING';
export const ACCOUNTING_ENTIRES_LOADING_SUCCEESS =
  'ACCOUNTING_ENTIRES_LOADING_SUCCEESS';
export const GET_TOTAL_ACCOUNTING_ENTIRES_LOADING =
  'GET_TOTAL_ACCOUNTING_ENTIRES_LOADING';
  
export const GET_TOTAL_ACCOUNTING_ENTIRES_SUCCEESS =
  'GET_TOTAL_ACCOUNTING_ENTIRES_LOADING_SUCCEESS';
export const RESTE_LOADED_ACCOUNTING = 'RESTE_LOADED_ACCOUNTING';



export const accountingEntriesLoading = createAction(
  ACCOUNTING_ENTRIES_LOADING,
  props<{ country_id: number }>()
);

export const getTotalsAccountingCategories = createAction(
  GET_TOTAL_ACCOUNTING_ENTIRES_LOADING,
  props<{ startDate:string, endDate:string }>()
);

export const getTotalsAccountingCategoriesSuccess = createAction(
  GET_TOTAL_ACCOUNTING_ENTIRES_SUCCEESS,
  props<{ TotalsAccountingCategories: Array<AccountingEntryCategory> }>()
  );


export const accountingEntriesLoadedSuccessfully = createAction(
  ACCOUNTING_ENTIRES_LOADING_SUCCEESS,
  props<{ accountingEntries: Array<AccountingEntryCategory> }>()
);
export const resetLoadedAccountings = createAction(RESTE_LOADED_ACCOUNTING);
