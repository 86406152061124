import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';
import { dev } from '../../../../config/offlines_keys.json';
import { Storage } from '@capacitor/storage';
import { CashflowAPI } from 'src/app/apis/cashflow.service';
import { LoadingController } from '@ionic/angular';
import { OperationsAPI } from 'src/app/apis/operations.service';
import { DEBT_OPERATION } from 'src/app/utils/consts';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { take } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-cashflow-selector',
  templateUrl: './cashflow-selector.component.html',
  styleUrls: ['./cashflow-selector.component.scss'],
})
export class CashflowSelectorComponent implements OnInit {
  @Input() modalController;
  @Input() cashflows: any[];
  @Input() pension_plan;
  @Input() operation;
  has_selected_creation = false;
  farm;
  has_selected_all = true;
  loadingModal;

  constructor(
    private cashflowAPI: CashflowAPI,
    private operationsAPI: OperationsAPI,
    private loadingCtrl: LoadingController,
    public translateService:TranslateService,
    private store: Store

  ) {}

  async ngOnInit() {
    this.loadingModal = await this.loadingCtrl.create({ spinner: 'bubbles',
      mode: 'ios',
    });
    this.farm = await lastValueFrom(this.store.select(getFarm).pipe(take(1)))
  }

  dismiss() {
    this.modalController.dismiss({ deleteLegth: 0 });
  }

  creationSelected() {
    this.has_selected_creation
      ? (this.has_selected_creation = false)
      : (this.has_selected_creation = true);
    this.has_selected_all = !this.has_selected_all;
    this.cashflows.forEach((element) => {
      if (this.has_selected_all && !element.is_creation_cashflow) {
        element.is_selected = false;
      } else if (!this.has_selected_all && !element.is_creation_cashflow) {
        element.is_selected = true;
      }
    });
  }

  selectAll() {
    this.has_selected_all = !this.has_selected_all;
    this.cashflows.forEach((element) => {
      if (this.has_selected_all) {
        element.is_selected = false;
      } else {
        element.is_selected = true;
      }
    });
  }

  confirmMethod() {
    this.loadingModal.present();
    const ids = [];
    this.cashflows.forEach((cashflow: any) => {
      if (cashflow.is_selected === true) ids.push(cashflow.id);
    });
    if (Array.isArray(ids) && ids.length > 0) {
      if (this.pension_plan && this.pension_plan.cashflow.length >= ids.length)
        this.deleteCashflow(ids);
      else if (this.operation) {
        if (!this.has_selected_creation) this.updateOperation(ids);
        else {
          this.loadingModal.dismiss();
          this.modalController.dismiss({
            deleteLegth: this.operation.cashflows.length,
          });
        }
      } else this.deleteCashflow(ids);
    } else {
      this.loadingModal.dismiss();
      this.modalController.dismiss({ deleteLegth: ids.length });
    }
  }
  //TODO offline

  deleteCashflow(ids) {
    this.cashflowAPI.deleteMultipleCashflows(ids).subscribe((response) => {
      this.loadingModal.dismiss();
      this.modalController.dismiss({
        deleteLegth: ids.length,
        response: response,
      });
    });
  }

  async updateOperation(ids) {
    const user = await Storage.get({ key: dev.USER_KEY }).then((value) =>
      JSON.parse(value.value)
    );
    var totalAmount = 0;
    this.cashflows.forEach((cashflow) => {
      if (cashflow.is_selected === true) totalAmount += cashflow.amount;
    });

    if (this.operation.operation_type === DEBT_OPERATION) {
      this.cashflows.forEach((element) => {
        if (element.is_selected) {
          if (element.is_income) this.operation.amount -= element.amount;
          else this.operation.paid_so_far -= element.amount;
        }
      });
    } else {
      this.cashflows.forEach((element) => {
        if (element.is_selected) {
          if (!element.is_income) this.operation.amount -= element.amount;
          else this.operation.paid_so_far -= element.amount;
        }
      });
    }

    this.operation.owner_id = user.id;
    this.operationsAPI
      .updateOperation(this.operation, this.operation.id)
      .subscribe((value) => {
        value;
        this.deleteCashflow(ids);
      });
  }
}
