import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { dev } from '../config/endpoints.json';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  base_url = environment.appRoot + '/api/';

  constructor(private http: HttpClient) {}

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
          `body was: ${JSON.stringify(error.error)}`
      );
    }
    return throwError('');
  }

  getLanguage(
    lang,
  ) {
    const url =
      this.base_url +
      dev.endpoint.login.get_language_content +'/'+ lang;
    return this.http.get(url);
  }

  register(form): Observable<any> {
    let url = this.base_url + dev.endpoint.login.register;
    return this.http.post<any>(url, form);
  }
  login(form): Observable<any> {
    let url = this.base_url + dev.endpoint.login.login;

    return this.http
      .post<any>(url, form)
      .pipe(retry(2), catchError(this.handleError));
  }
  resendSms(num, prefix, lang): Observable<any> {
    const data = new FormData();
    data.append('mobile_number', num);
    data.append('prefix', prefix);
    data.append('lang', lang);

    let url = this.base_url + dev.endpoint.login.resendSms;

    return this.http
      .post<any>(url, data)
      .pipe(retry(2), catchError(this.handleError));
  }



  validateAndLogin(mobile_number, prefix, code, network): Observable<any> {
    let url = this.base_url + dev.endpoint.login.validateAndLogin;
    return this.http
      .post<any>(url, {
        mobile_number: mobile_number,
        prefix: prefix,
        sms_code: code,
        network:network,
      })
  }

  validateAndLoginExistingAccount(mobile_number, prefix): Observable<any> {
    let url =
      this.base_url + dev.endpoint.login.validateAndLoginExistingAccount;
    return this.http
      .post<any>(url, { mobile_number: mobile_number, prefix: prefix })
      .pipe(retry(2), catchError(this.handleError));
  }
}
