import { HttpClient } from '@angular/common/http';
import { dev } from '../config/endpoints.json';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ClustersAPI {
  base_url = environment.appRoot + '/api/';
  headers;

  constructor(private http: HttpClient) {
  }

  fetchClusterByCountryID(id: Number) {
    const url =
      this.base_url +
      dev.endpoint.cluster.select_cluster_ambassadors_by_country_id +
      id;
    return this.http.get(url);
  }
  
  getAmbassadorById(id) {
    const url = this.base_url + dev.endpoint.cluster.get_ambassador_by_id + '/' + id;
    return this.http.get(url);
  }
}
