import { ToastUtils } from 'src/app/utils/toast_builder';
import { CAFE_ACCOUNTING_ENTRY_CATEGORY } from '../../../utils/consts';
import { showConfirmModal } from 'src/app/utils/pop_over_maker';
import { UserInfo } from 'src/app/apis/users-info.service';
import { Component, Input, OnInit } from '@angular/core';
import { dev } from '../../../config/offlines_keys.json';
import { Storage } from '@capacitor/storage';
import { ModalController, PopoverController } from '@ionic/angular';
import { CoffeeTypeSelectorComponent } from '../widgets/coffee-type-selector/coffee-type-selector.component';
import { ProductionSalesOtherComponent } from '../cashflow/production-sales-other/production-sales-other.component';
import { ProductionAPI } from 'src/app/apis/FarmAPI/productions.service';
import { getDisplayableUnit } from 'src/app/utils/unit-convert';
import { TranslateService } from '@ngx-translate/core';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';
import { UnifiedCashflowInputComponentComponent } from '../../cashflow/unified-cashflow-input-component/unified-cashflow-input-component.component';
import { lastValueFrom, take } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-coffee-production-view',
  templateUrl: './coffee-production-view.component.html',
  styleUrls: ['./coffee-production-view.component.scss'],
})
export class CoffeProductionViewComponent implements OnInit {
  products: Array<ProductionsPerfarm>;
  selected_langage;
  coffe_title;
  @Input() nav;
  country;
  can_add_coffe_product;
  constructor(
    private modalController: ModalController,
    private popoverController: PopoverController,
    private userInfo: UserInfo,
    private productionAPI: ProductionAPI,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private store:Store
  ) {}

  async ngOnInit() {
    this.country = await lastValueFrom(
      this.store.select(getCountry).pipe(take(1))
    );

    this.fetchCafeProductions();
    this.translate();
  }

  fetchCafeProductions() {
    this.productionAPI
      .getProductionByFarmAndType(0)
      .subscribe((value: Array<ProductionsPerfarm>) => {
        this.products = value.filter((element) => element.is_crop === 1);

        this.products = this.products.map((element) => {
          element.goods = element.goods.map((elment) => {
            elment.displayable_unit = getDisplayableUnit(elment.unit);
            return elment;
          });
          return element;
        });
      });
  }

  async translate() {
    this.selected_langage = await this.userInfo.getSelectedLangage();
  }

  async addCafeProd() {
    let modal = await this.modalController
      .create({
        component: CoffeeTypeSelectorComponent,
        componentProps: {
          preSelectedProduct: this.products,
        },
      })
      .then((value) => {
        return value;
      });

    await modal.present();
    await modal.onWillDismiss();
    this.fetchCafeProductions();
  }

  async getCashflowRequestConfirmation(): Promise<Boolean> {
    const modal = await showConfirmModal(
      this.popoverController,
      await this.translateService
        .get('add_cashflow_entry')
        .toPromise()
        .then((value) => value),
      null,
      'Sí',
      'No'
    );
    const { data } = await modal.onWillDismiss();
    return data?.confirmed;
  }

  async getCategories(cashFlowEntrieName) {
    return await Storage.get({
      key: dev.CASHFLOW_CATEGORIES,
    }).then((value) => {
      let categories = JSON.parse(value.value);
      return categories[cashFlowEntrieName.toString()];
    });
  }

  async delete(item) {
    const confirm = await this.getCashflowRequestConfirmation();
    const cashFlowCategFiltred = await this.getCategories(
      CAFE_ACCOUNTING_ENTRY_CATEGORY
    );
    if (confirm) {
      await this.showDialogCashflow(cashFlowCategFiltred);
    } else {
      if (item.goods && item.goods.length > 0 && item.goods[0].qty > 0) {
        const modal = await showConfirmModal(
          this.popoverController,
          await this.translateService
            .get('redirect_cashflow_entry_product_coffee')
            .toPromise(),
          null,
          await this.translateService
            .get('yes')
            .toPromise()
            .then((value) => value),

          await this.translateService
            .get('no')
            .toPromise()
            .then((value) => value)
        );
        const { data } = await modal.onWillDismiss();
        if (data?.confirmed) {
          await this.showDialogCashflow(cashFlowCategFiltred);
          await this.fetchCafeProductions();
        }
      } else {
        this.productionAPI.deleteFarmProduction(item.id).subscribe(
          (value) => {
            this.fetchCafeProductions();
          },
          (e) => {
            if (e.status == 200) this.fetchCafeProductions();
          }
        );
      }
    }
  }

  async showDialogCashflow(cashFlowCategFiltred) {
    const comp = UnifiedCashflowInputComponentComponent;
    let componentProps = {
      cashFlowEntrieName: CAFE_ACCOUNTING_ENTRY_CATEGORY,
      accountingEntryCategories: cashFlowCategFiltred,
      modalController: this.modalController,
    };
    await this.toastUtils.modalBuilder(
      comp,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper'],
      true,
      '1'
    );
  }

  details(item) {
    this.nav.setRoot(ProductionSalesOtherComponent, {
      product: item,
      nav: this.nav,
    });
  }
}
