import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { ClustersAPI } from '../../../apis/clusters.service';
import { Farm } from '../../../models/farm/farm.model';
import { dev } from '../../../config/offlines_keys.json';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';

@Component({
  selector: 'app-clusters-ambassadors-selectors',
  templateUrl: './clusters-ambassadors-selectors.component.html',
  styleUrls: ['./clusters-ambassadors-selectors.component.scss'],
  providers: [ClustersAPI],
})
export class ClustersAmbassadorsSelectorsComponent implements OnInit {
  country_id: Number = 1;

  @Input() farm: any;
  @Output() farmChange = new EventEmitter<Farm>();
  clusters: any = [];
  ambassadors = [];
  @Input() label;
  defaultClusterValue;
  selected_cluster;
  selected_ambassador;
  token;
  owner;
  clusterClicked;
  constructor(private clustAPI: ClustersAPI, private store: Store) {}

  async ngOnInit() {
    this.owner = JSON.parse((await Storage.get({ key: dev.USER_KEY })).value);
    this.token = await Storage.get({ key: dev.ACCESS_TOKEN }).then(
      (value) => value.value
    );
    let country=await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    this.country_id = country.id
    this.clustAPI.fetchClusterByCountryID(this.country_id).subscribe(
      async (value: any) => {
        this.clusters = value;
        await this.orderOldData();
      });
    this.defaultClusterValue = this.farm.cluster_id;
  }

  async orderOldData() {
    const farm = await lastValueFrom(this.store.select(getFarm).pipe(take(1)))

    this.selected_cluster = this.clusters.find(
      (element) => element.id === farm?.cluster_id
    );
    if (this.selected_cluster) {
      this.ambassadors = this.selected_cluster.ambassadors;
      this.selected_ambassador = this.ambassadors.find(
        (element) => element.id === farm.ambassador_id
      );

      if (this.selected_ambassador) {
      }
      this.onChangeCluster(this.selected_cluster);
      // this.onAmbassadorChange(this.selected_ambassador);
    }
  }

  onChangeCluster(evt) {
    if (this.selected_ambassador && this.clusterClicked) {
      this.onAmbassadorChange(null);
    }
    this.ambassadors = evt.ambassadors;

    this.selected_cluster = evt;
    this.farm.cluster_id = evt.id;
    // this.farm.ambassador_id = undefined;
  }
  onClickCluster() {
    this.clusterClicked = true;
  }
  onAmbassadorChange(evt) {
    this.selected_ambassador = evt;
    this.farm.ambassador_id = evt?.id;
    this.farmChange.emit(this.farm);
  }

  compareFnCluster(e1, e2): boolean {
    return e1 === e2 && e1.id === e2.id;
  }

  compareFnAmbassador(e1: Farm, e2: Farm): boolean {
    return e1.ambassador_id && e2.ambassador_id
      ? e1.ambassador_id == e2.ambassador_id
      : e1 == e2;
  }
}
