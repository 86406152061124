import { RoutingConfig } from 'src/app/utils/routing-config';
import {
  MORPH_FamilyMember,
  MORPH_Owner,
  MORPH_Garentee,
} from './../../../utils/consts';
import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../config/offlines_keys.json';
import { OperationsAPI } from '../../../apis/operations.service';
import { Router } from '@angular/router';
import { ModalGarenteeComponent } from '../modal-garentee/modal-garentee.component';
import { PriorDashboardComponent } from '../../prior-dashboard/prior-dashboard.component';
import { environment } from '../../../../environments/environment';
import { CashflowSelectorComponent } from '../../dashboard/widgets/cashflow-selector/cashflow-selector.component';
import { TranslateService } from '@ngx-translate/core';
import { MORPH_Farm } from 'src/app/utils/consts';
import Operation from 'src/app/models/operation.model';

@Component({
  selector: 'app-garentee',
  templateUrl: './garentee.component.html',
  styleUrls: ['./garentee.component.scss'],
  providers: [OperationsAPI],
})
export class GarenteeComponent implements OnInit {
  owner_id: number;

  @Input() isDashboardView;
  @Input() nav;
  @Input() rootStack;
  you = 'Usted';
  has_garentee = false;
  list: any;
  main_endpoint = environment.appRoot;
  MORPH_Farm = MORPH_Farm;
  MORPH_FamilyMember = MORPH_FamilyMember;
  MORPH_Owner = MORPH_Owner;
  constructor(
    public modalController: ModalController,
    private router: Router,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private operationAPI: OperationsAPI,
    private routingConfig: RoutingConfig
  ) {}

  async presentModal() {
    let modal = await this.toastUtils.modalBuilder(ModalGarenteeComponent, {}, [
      'my-custom-modal-css',
      'modal-wrapper',
    ]);

    modal.onDidDismiss().then(() => this.getOperations());
  }

  async presentupdateModal($id: number) {
    let modal = await this.toastUtils.modalBuilder(
      ModalGarenteeComponent,
      { id: $id },
      ['my-custom-modal-css', 'modal-wrapper']
    );

    modal.onDidDismiss().then(() => this.getOperations());
  }

  async getowner_id() {
    await Storage.get({ key: dev.USER_KEY }).then((value) => {
      this.owner_id = JSON.parse(value.value)?.id;
    });
  }

  async ngOnInit() {
    this.langageChanger(localStorage.getItem('CapacitorStorage.LANGAGE'));
    await this.getowner_id();
    this.getOperations();
    this.showPopOver();
    if (this.isDashboardView) this.has_garentee = true;
  }

  async showPopOver() {
    let componentProps = {
      key1: await this.translateService
        .get('bubble_desc_garentee')
        .toPromise()
        .then((value) => value),
    };
    await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
  }

  langageChanger(lang) {
    if (lang == 'es') {
      this.you = 'Usted';
    } else {
      this.you = 'You';
    }
  }

  getOperations() {
    this.operationAPI.getOperation().subscribe((value: Operation[]) => {
      this.list = value.filter((value) => {
        return value.operation_type == MORPH_Garentee;
      });
      this.prepareList(this.list);
    });
  }
  calculatePercent(required_amount, gathered_amount) {
    if (!gathered_amount) {
      gathered_amount = 0;
    }
    return gathered_amount / required_amount;
  }
  prepareList(val) {
    if (Array.isArray(val)) {
      val.forEach((element) => {
        element.calculatedPercent = this.calculatePercent(
          element.amount,
          element.paid_so_far
        );
        element.display_percent =
          (100 * this.calculatePercent(element.amount, element.paid_so_far))
            .toFixed(2)
            .toString() + '%';
      });
    }
  }

  async delete(item) {
    if (this.isDashboardView) {
      if (item.cashflows.length > 0) {
        let componentProps = {
          modalController: this.modalController,
          cashflows: item.cashflows,
          operation: item,
        };
        let modal = await this.toastUtils.modalBuilder(
          CashflowSelectorComponent,
          componentProps,
          ['my-custom-modal-css', 'modal-wrapper']
        );

        modal.present();
        const { data } = await modal.onWillDismiss();
        if (data.deleteLegth) {
          if (
            Array.isArray(item.cashflows) &&
            data.deleteLegth === item.cashflows.length &&
            data.deleteLegth !== 0
          ) {
            this.operationAPI.deleteOperation(item.id).subscribe((value) => {
              value;
              this.getOperations();
            });
          }
        }
      } else await this.deleteOp(item);
    } else await this.deleteOp(item);
  }

  async deleteOp(item) {
    this.operationAPI.deleteOperation(item.id).subscribe((value) => {
      value;
      this.getOperations();
    });
  }

  updateForm($id: number) {
    this.presentupdateModal($id);
  }

  async mnext() {
    this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }

  async mreturn() {
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());
  }

  goBackToDashBoard() {
    this.nav.setRoot(this.rootStack, {
      nav: this.nav,
      rootStack: PriorDashboardComponent,
    });
  }

  answeredEventChange(val) {
    this.has_garentee = val;
    if (!val) this.mnext();
  }
}
