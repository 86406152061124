import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { Observable, from, of } from 'rxjs';
import { map, switchMap, catchError, filter, concatMap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import {
  executeOperation,
  loadAppData,
  markOperationExecuted,
  markOperationExecutedWithError,
  syncData,
} from '../actions/offline.action';
import { ConnectionState } from '../reducers/offline.reducer';
import { StorageSyncActions, fetchState } from '../storage-sync.module';
import { LOAD_FAMILY_MEMBER, loadFamilyMember } from '../actions/family-members.action';
import { LOAD_FARM, loadFarm } from '../actions/farm.action';
import { LOAD_PLOTS, loadPlots } from '../actions/plots.action';
import { LOAD_AGRI_PRODUCT, LOAD_SELECTED_AGRI_PRODUCT, loadAgriProduct, loadSelectedAgriProduct } from '../actions/agri-product.action';
import { LOAD_ANIMAL, LOAD_SELECTED_ANIMAL, loadAnimal, loadSelectedAnimal } from '../actions/animals.action';
import { LOAD_PARTNER, loadPartner, loadPartnerType } from '../actions/partner.action';
import { LOAD_PROGRAMS } from '../actions/programas.action';
import { GET_DASHBOARD_DATA, LOAD_TOTAL_ACCOUNTING_CATEGORIES, getDashboardData, loadTotalAccountingCategories } from '../actions/dashboard.action';
import { PAGINATE_CASHFLOW, paginateCashflow } from '../actions/cashflow.action';
import { loadUnit } from '../actions/units-action';

@Injectable()
export class OfflineEffect {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<{
      connection: ConnectionState;
    }>
  ) {}

  // Effect to hydrate the state from storage
  hydrate$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(StorageSyncActions.NGRX_EFFECT_INIT),
        switchMap(() =>
          from(fetchState()).pipe(
            map((state) => ({
              type: StorageSyncActions.HYDRATED,
              payload: state,
            })),
            catchError((e) => {
              console.warn(
                `error fetching data from store for hydration: ${e}`
              );
              return of({
                type: StorageSyncActions.HYDRATED,
                payload: {},
              });
            })
          )
        )
      )
  );

  // Effect to execute operations
  executeOperations$ = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(syncData),
        switchMap(() =>
          this.store.select('connection').pipe(
            map((operations) =>
              operations.isOnline
                ? Array.from(Object.values(operations.entities))
                : []
            ),
            filter((operations) => operations?.length > 0),
            map((data) => data.sort((a, b) => (a.id < b.id ? -1 : 1))),
            switchMap((operations: any) => {
              return of(executeOperation({ operation: operations[0] }));
            })
          )
        )
      )
  );
  loadAppData$ = createEffect(() : Observable<Action>=>
  this.actions$.pipe(
    ofType(loadAppData),
    concatMap(() => {
      const startDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        1
      ).toISOString();
      const endDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        0 + 1
      ).toISOString();

      const date = { startDate: startDate, endDate: endDate };
      let dashboardData = {
        startDate: new Date(
          new Date().getFullYear(),
          1,
          1
        ).toISOString(),
        endDate: new Date(
          new Date().getFullYear(),
          11,
          31
        ).toISOString(),
        cropId: 1,
        groupBy: 'm-Y',
      };
      return[
      loadFarm(),
      loadTotalAccountingCategories({ data: date }),
      loadFamilyMember(),
      loadAgriProduct(),
      loadAnimal(),
      loadSelectedAgriProduct(),
      loadSelectedAnimal(),
      loadPlots(),
      loadPartnerType(),
      loadPartner(),
      loadUnit(),
      getDashboardData({ data: dashboardData })
    ]})
  )
);
  // Effect to send HTTP request
  sendRequest$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(executeOperation),
        switchMap((value: any) => {
          const operation = value.operation;
          const {  headers, responseType } = operation || {};
          var body = new FormData();
          for (const key in operation.formDataObject) {
            body.append(key, operation.formDataObject[key]);
          }
          let request$: Observable<any>;

          switch (operation.method.toUpperCase()) {
            case 'POST':
              request$ = this.http.post(operation.url, body);
              break;
            case 'PUT':
              request$ = this.http.put(operation.url, body);
              break;
            case 'DELETE':
              request$ = this.http.delete(operation.url);
              break;
          }
  
          return request$.pipe(
              map((response) => {
                return markOperationExecuted({
                  id: operation?.id ?? null,
                  error: null,
                  data: response,
                });
              }),
              catchError((error) => {
                return of(
                  markOperationExecutedWithError({
                    id: operation?.id ?? null,
                    error: error.error,
                    data: {},
                  })
                );
              })
            );
        })
      )
  );
}
