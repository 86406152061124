import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonNav, NavController } from '@ionic/angular';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { Location } from '@angular/common';
import { MatTabGroup } from '@angular/material/tabs';
import { lastValueFrom, take } from 'rxjs';
import Owner from 'src/app/models/owner.model';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input() nav: IonNav;
  @Input() selectedTab;
  @Input() tabs;
  @ViewChild('tabGroup') matTab: MatTabGroup;
  tab;
  constructor(
    private routingConfig: RoutingConfig,
    private store: Store<{ owner: Owner }>
  ) {}
  formConfig;
  has_crop;
  owner;
  async ngOnInit() {
    this.formConfig = await this.routingConfig.getProfileFormConfig();
    this.matTab.selectedIndex = this.selectedTab;
    this.store.select('owner').pipe(take(1)).subscribe((val) => {
      this.owner = cloneDeep(val);
      this.has_crop =
        JSON.parse(this.owner?.profile_info)?.cropConfig?.length > 0
          ? true
          : false;


        this.tabs[0][1].menu_items[0]['disabled'] = !this.owner.is_owner 
        this.tabs[0][1].menu_items[1]['disabled'] = !this.owner.is_owner 
        this.tabs[0][1].menu_items[2]['disabled'] = !this.owner.is_owner 
        this.tabs[0][1].menu_items[4]['disabled'] = !this.owner.is_owner 
        this.tabs[0][1].menu_items[5]['disabled'] = !this.owner.is_owner 
    });

  }
}
