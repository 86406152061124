import { Component, Input, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { MoneyReportingsAPI } from 'src/app/apis/money_reportings.service';
import MoneyReporting from 'src/app/models/money_reporting.model';
import {
  PENSION_PLAN_TYPE_GOV,
  PENSION_PLAN_TYPE_NO_GOV,
} from 'src/app/utils/consts';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { dev } from '../../../../config/offlines_keys.json';
import { lastValueFrom, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';

@Component({
  selector: 'app-pension-plan-form',
  templateUrl: './pension-plan-form.component.html',
  styleUrls: ['./pension-plan-form.component.scss'],
})
export class PensionPlanFormComponent implements OnInit {
  @Input() allowed_options;
  @Input() modalController;
  moneyReporting = new MoneyReporting();
  country;
  loadingModal;
  GOV_PENSION = PENSION_PLAN_TYPE_GOV;
  NO_GOV_PENSION = PENSION_PLAN_TYPE_NO_GOV;
  lang;
  constructor(
    private moneyReportingsAPI: MoneyReportingsAPI,
    private loadingModalController: LoadingController,
    private transalteService: TranslateService,
    private toastUtils: ToastUtils,
    private store:Store
  ) {}

  async ngOnInit() {
    this.loadingModal = await this.loadingModalController.create();
    this.country =await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    await Storage.get({ key: dev.USER_KEY }).then((value) => {
      const user = JSON.parse(value.value);
      this.moneyReporting.owner_id = user?.id;
    });
    this.moneyReporting.amount = 0;
    this.moneyReporting.currency = this.country?.currency;
    this.lang = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );

    if (this.allowed_options.length == 1) {
      this.moneyReporting.type = this.allowed_options[0];
      this.setPensionName();
    }
    this.moneyReporting.pension_plan_objective = 0;
  }

  setPensionPlanAmount(amount) {
    this.moneyReporting.pension_plan_objective = amount;
  }

  dismiss() {
    this.modalController.dismiss({}, '', '5');
  }
  setPensionName() {
    if (this.moneyReporting.type == this.NO_GOV_PENSION) {
      if (this.country.id === 2)
        this.moneyReporting.money_reporting_name =
          'Ahorro para la vejez (BEPS)';
      else this.moneyReporting.money_reporting_name = 'Ahorro para la vejez';
    }
  }
  async confirm_method() {
    const is_valid = await this.reviewData();
    if (is_valid) {
      this.setPensionName();
      this.loadingModal.present();
      this.moneyReportingsAPI
        .storeMoneyReporting(this.moneyReporting)
        .subscribe(
          (val) => {
            this.loadingModal.dismiss();
            this.modalController.dismiss({ pension: val });
          },
          (e) => {
            this.loadingModal.dismiss();
          }
        );
    }
  }

  async reviewData() {
    if (this.moneyReporting.type === PENSION_PLAN_TYPE_GOV) {
      if (
        !this.moneyReporting.money_reporting_name ||
        this.moneyReporting.pension_plan_objective === 0
      ) {
        await this.showToastError();
        return false;
      }
    } else if (
      this.moneyReporting.type === PENSION_PLAN_TYPE_NO_GOV &&
      this.moneyReporting.pension_plan_objective === 0
    ) {
      await this.showToastError();
      return false;
    } else if (!this.moneyReporting.type) {
      await this.showToastError();
      return false;
    }
    return true;
  }

  async showToastError() {
    await this.toastUtils
      .toastBuilder(
        await this.transalteService.instant('missing_fields'),
        '',
        '',
        ''
      );
  }
}
