import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import { PaginationResult } from 'src/app/models/pagination-result.model';
import {
  cashflowListAdapter,
  CashFlowListState,
} from '../reducers/cashflow-lists.reducer';
export const cashflowListSelector =
  createFeatureSelector<CashFlowListState>('cashflowList');

export const getSelectedSaleId = (state: CashFlowListState) => state;

export const selectCurrentCashflow = createSelector(
  cashflowListSelector,
  getSelectedSaleId
);
export const selectCurrentUser = createSelector(
  cashflowListAdapter.getSelectors().selectEntities,
  selectCurrentCashflow,
  (userEntities, userId) => userId && userEntities[userId.toString()]
);

export const getSaleById = (id: number) =>
  createSelector(cashflowListSelector, (allItems: any) => {
    if (allItems?.entities[id.toString()]) {
      return allItems?.entities[id.toString()];
    } else {
      return null;
    }
  });

export const getCashflowPaginationResult = createSelector(
  cashflowListSelector,
  (state: CashFlowListState) =>
  Object.entries(state.entities)
);
export const getAllListableCashflows = createSelector(
  cashflowListSelector,
  (state: CashFlowListState) => cashflowListAdapter.getSelectors().selectAll(state)
);
