import {
  ServiceWorkerModule,
  SwRegistrationOptions,
  SwUpdate,
} from '@angular/service-worker';

import {
  effects,
  metaReducers,
  reducers,
} from './utils/states-management/reducers/app-meta-reducer.reducer';
import {
  syncData,
  updateConnectionStatus,
} from './utils/states-management/actions/offline.action';
import { EmptyListModule } from './widgets/empty-list/empty-list.module';
import { LangPopoverComponent } from './widgets/lang-popover/lang-popover.component';
import { DarkModeToggleComponent } from './widgets/dark-mode-toggle/dark-mode-toggle.component';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { SplashComponent } from './modules/splash/splash.component';
import { CommunityModule } from './modules/community/community.module';
import { PopoverImgComponent } from './widgets/popover-img/popover-img.component';
import { SmsValidationComponent } from './modules/sms-validation/sms-validation.component';
import { TermesConditionsComponent } from './modules/termes-conditions/termes-conditions.component';
import {
  NgModule,
  ErrorHandler,
  CUSTOM_ELEMENTS_SCHEMA,
  APP_INITIALIZER,
  OnInit,
} from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { CommonModule } from '@angular/common';
import { Router, RouteReuseStrategy } from '@angular/router';
import {
  IonicModule,
  IonicRouteStrategy,
  PopoverController,
} from '@ionic/angular';
import { PersonalDataComponent } from './components/personal-data/personal-data.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PeronsDataScreensComponent } from './modules/perons-data-screens/perons-data-screens.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePicker } from '@ionic-native/date-picker/ngx';
import { ProfilePhotoComponent } from './components/personal-data/profile-photo/profile-photo.component';
import { PageCountriesComponent } from './modules/page-countries/page-countries.component';
import { LoginComponent } from './modules/login/login.component';
import { CreateAccountComponent } from './modules/create-account/create-account.component';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { PopoverComponent } from './widgets/popover/popover.component';
import { ModaSelectComponent } from './moda-select/moda-select.component';
import { PriorDashboardComponent } from './modules/prior-dashboard/prior-dashboard.component';
import { GoodsModule } from './modules/goods/goods.module';
import { FamilyModule } from './modules/family/family.module';
import { FarmModule } from './modules/farm/farm.module';
import { TeamModule } from './modules/team/team.module';
import { DebtsModule } from './modules/debts/debts.module';
import { FooterModule } from './widgets/footer-component/header-footer.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { TermsModalPageModule } from './components/terms-modal/terms-modal.module';
import { LicenseAgreementComponent } from './widgets/license-agreement/license-agreement.component';
import { CashflowModule } from './modules/cashflow/cashflow.module';
import { NgChartsModule } from 'ng2-charts';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { PhotoEditorComponent } from './components/personal-data/profile-photo/photo-editor/photo-editor.component';
import { ProfileInfoComponent } from './components/personal-data/profile-info/profile-info.component';
import { PopoverChecklistComponent } from './widgets/popover-checklist/popover-checklist.component';
import { LOCALE_ID, isDevMode } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import { StoreModule, Store } from '@ngrx/store';
import { TokenInterceptor } from './apis/token.interceptor';
import { UserInfo } from './apis/users-info.service';
import { AccountingCategoriesAPI } from './apis/accounting_categories.service';
import { CashflowAPI } from './apis/cashflow.service';
import { ClustersAPI } from './apis/clusters.service';
import { FarmAPI } from './apis/farm.service';
import { GoodsAPI } from './apis/goods.service';
import { LoginService } from './apis/login.service';
import { LotAPI } from './apis/lot.service';
import { MaterialProductAPI } from './apis/material_product.service';
import { MoneyReportingsAPI } from './apis/money_reportings.service';
import { ObjectiveApi } from './apis/objectives.service';
import { OperationsAPI } from './apis/operations.service';
import { OwnerAPI } from './apis/owners.service';
import { MyPartnersAPI } from './apis/partners.service';
import { ProductAPI } from './apis/product.service';
import { ProgramasAPI } from './apis/programas.service';
import { StatisticsAPI } from './apis/statistics.service';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { ExistingAccountsComponent } from './modules/login/existing-accounts/existing-accounts.component';
import { AnimationUtils } from './utils/animations';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  DateFormatPipe,
  PickerOptionFormater,
} from './utils/pipes-utils/date_formatter';
import { OperationRelatedToCashflowBusinessRules } from './utils/business-rules/operations_related_to_cashflow_business_rules';
import { SalaryBusinessRules } from './utils/business-rules/salary-related-cashflow';
import { GetResult, Storage } from '@capacitor/storage';
import { dev } from './config/offlines_keys.json';
import { CashflowPriorApiTreatment } from './utils/business-rules/cashflow-saver';
import { from, from as fromPromise, Observable } from 'rxjs';
import {
  catchError,
  concatMap,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { SmsRetriever } from '@ionic-native/sms-retriever/ngx';
import { QuestionControlService } from './utils/dynamic-forms/QuestionControlService';
import { IntroductoryvideoComponent } from './modules/introductoryvideo/introductoryvideo.component';
import { ProgrammesInfoModule } from './modules/programmes-Info/programmes-Info.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GetRealGoodTransactionValues } from './utils/unit-convert';
import { ToastUtils } from './utils/toast_builder';
import { EmployeesAPI } from './apis/employees.service';
import { Family_membersAPI } from './apis/family_members.service';
import { environment } from '../environments/environment';
import { Network } from '@capacitor/network';
import { PopoverInfoComponent } from './widgets/popover-info/popover-info.component';
import { Device } from '@capacitor/device';
import { LandingSwipeStepComponent } from './widgets/landing-swipe-step/landing-swipe-step.component';
import { EffectsModule } from '@ngrx/effects';
import { ItemListComponent } from './widgets/item-list/item-list.component';
import { FormlyModule } from '@ngx-formly/core';
import { LandingHeaderComponent } from './widgets/landing-header/landing-header.component';
import { UpdateAccessWidgetComponent } from './widgets/update-access-widget/update-access-widget.component';
import { landingDoneComponent } from './widgets/landing_done/landing_done.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DataLoaderBarComponent } from './widgets/data-loader-bar/data-loader-bar.component';
import Userback from '@userback/widget';

// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http,'/assets/i18n/','.json');

//   // return new TranslateHttpLoader(http, 'https://management-tool-backend.terrakodo.com/api/get_language_content/', '');

// }

export class CustomTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    return from(caches.open('lang-cache-v1')).pipe(
      switchMap((cache) => from(cache.match(`/assets/i18n/${lang}.json`))),
      switchMap((response) => {
        if (response) {
          return from(response.json());
        } else {
          return this.fetchAndCacheLanguageFile(lang);
        }
      }),
      catchError((error) => {
        console.error(
          `Échec du chargement de la traduction pour ${lang}.`,
          error
        );
        return this.fetchAndCacheLanguageFile('en');
      })
    );
  }

  private fetchAndCacheLanguageFile(lang: string): Observable<any> {
    const languages = ['es', 'en', 'fr'];

    // return from(lang).pipe(
    //   tap((lang) => {
    //     console.log(lang);
    return this.http.get(`/assets/i18n/${lang}.json`).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(
          `Échec du chargement de la traduction pour ${lang}.`,
          error
        );
        // Fallback à la langue par défaut (en)
        return this.http.get(`/assets/i18n/en.json`);
      }),
      switchMap((data: any) => {
        const responseInit = {
          status: 200,
          statusText: 'OK',
          headers: { 'Content-Type': 'application/json' },
        };
        const response = new Response(JSON.stringify(data), responseInit);
        return from(caches.open('lang-cache-v1')).pipe(
          map((cache) => {
            return from(cache.put(`/assets/i18n/${lang}.json`, response));
          }),
          map(() => data)
        );
      })
    );
    //   })
    // );
  }
}

export function initLangage(translateService: TranslateService) {
  const availableLanguages = ['en', 'es', 'fr'];
  translateService.addLangs(availableLanguages);

  return () =>
    new Promise<string>((resolve, reject) => {
      from(Storage.get({ key: dev.LANGAGE_KEY }))
        .pipe(
          switchMap(async (value: GetResult) => {
            let currentLang = 'en';
            let phoneLang = await Device.getLanguageCode();
            let langExist = availableLanguages.indexOf(phoneLang.value) != -1;

            if (langExist) {
              currentLang = phoneLang.value;
            } else if (value?.value) {
              currentLang = value.value;
            } else {
              Storage.set({ key: dev.LANGAGE_KEY, value: currentLang });
              localStorage.setItem('lang', currentLang);
            }

            availableLanguages.forEach((lang) => {
              translateService.setDefaultLang(lang);
            });
            translateService.setDefaultLang('en');

            return from(translateService.use(currentLang));
          })
        )
        .subscribe(
          () => {
            resolve(translateService.currentLang);
          },
          (error) => {
            reject(error);
          }
        );
    });
}
registerLocaleData(localeFr);
registerLocaleData(localeEs);
@NgModule({
  declarations: [
    PriorDashboardComponent,
    AppComponent,
    LoginComponent,
    PageCountriesComponent,
    PersonalDataComponent,
    PeronsDataScreensComponent,
    ProfilePhotoComponent,
    TermesConditionsComponent,
    CreateAccountComponent,
    SmsValidationComponent,
    PopoverComponent,
    PopoverImgComponent,
    ModaSelectComponent,
    LangPopoverComponent,
    LicenseAgreementComponent,
    PhotoEditorComponent,
    ProfileInfoComponent,
    PopoverChecklistComponent,
    ExistingAccountsComponent,
    SplashComponent,
    IntroductoryvideoComponent,
    DarkModeToggleComponent,
    PopoverInfoComponent,
    LandingSwipeStepComponent,
    // ItemListComponent
    LandingHeaderComponent,
    UpdateAccessWidgetComponent,
    landingDoneComponent,
    // DataLoaderBarComponent
  ],
  // entryComponents: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ProgrammesInfoModule,
    CommonModule,

    IonicModule.forRoot({
      animated: true,
      mode: 'md',
    }),
    ServiceWorkerModule.register('sw.js', {
      enabled: true,
    }),
    // EmptyListModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatStepperModule,
    MatTabsModule,
    BrowserAnimationsModule,
    GoodsModule,
    FamilyModule,
    FarmModule,
    TeamModule,
    DebtsModule,
    FooterModule,
    // DashboardModule,
    TermsModalPageModule,
    CashflowModule,
    NgChartsModule,
    AngularCropperjsModule,
    BackButtonDisableModule.forRoot(),
    CommunityModule,
    EffectsModule.forRoot(effects),
    StoreModule.forRoot(reducers, {
      metaReducers,
      initialState: {
        hydrated: false,
      },
    }),
    FooterModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      logOnly: !environment.production,
    }),
    // FormlyIonicModule,
    FormlyModule.forRoot(),
    MatSnackBarModule,
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    SplashScreen,
    QuestionControlService,
    CashflowPriorApiTreatment,
    SalaryBusinessRules,
    PickerOptionFormater,
    DateFormatPipe,
    DatePicker,
    Family_membersAPI,
    EmployeesAPI,
    FarmAPI,
    GoodsAPI,
    LoginService,
    LotAPI,
    MaterialProductAPI,
    MoneyReportingsAPI,
    ObjectiveApi,
    OperationsAPI,
    OwnerAPI,
    OperationRelatedToCashflowBusinessRules,
    MyPartnersAPI,
    ProductAPI,
    ProgramasAPI,
    StatisticsAPI,
    UserInfo,
    AccountingCategoriesAPI,
    CashflowAPI,
    ClustersAPI,
    AnimationUtils,
    ToastUtils,
    PopoverController,
    SmsRetriever,
    GetRealGoodTransactionValues,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },

    {
      provide: APP_INITIALIZER,
      useFactory: initLangage,
      multi: true,
      deps: [TranslateService],
    },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    // {
    //   provide: SwRegistrationOptions,
    //   useFactory: () => ({enabled: location.search.includes('sw=true')}),
    // },
  ],
  exports: [
    ExistingAccountsComponent,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatStepperModule,
    MatTabsModule,
    LicenseAgreementComponent,
    ProfileInfoComponent,
    SplashComponent,
    DarkModeToggleComponent,
    // EmptyListModule,
    LandingSwipeStepComponent,
    MatSnackBarModule,
    // DataLoaderBarComponent
    // ItemListComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule implements OnInit {
  constructor(
    private store: Store<{
      connection: boolean;
    }>,
    private http: HttpClient,
    public translateService: TranslateService,
    public popoverController: PopoverController,
    private swUpdate: SwUpdate,
  ) {

    // Userback('34570|66635|bN7yiPocCv0hJFZ656a0asFlo', {
    //   is_live: false,
    //   widget_settings: { language: 'en' },
    // }).then((ub) => {
    //   ub.show();
    // });

    // this.preloadLanguageFiles();
  }

  // async preloadLanguageFiles(): Promise<void> {
  //   const languages = ['en', 'es', 'fr'];
  //   languages.forEach((lang) => {
  //     this.http.get(`/assets/i18n/${lang}.json`).subscribe(
  //       (data: any) => {
  //         // if(lang=='en'){
  //         this.translateService.use(lang);
  //         // }
  //         // Cache language data or process it as needed
  //       },
  //       (error) => {
  //         console.error(`Failed to preload ${lang} language file.`);
  //       }
  //     );
  //   });
  //   // this.translateService.use('en')
  //   let lang = (await Storage.get({ key: dev.LANGAGE_KEY })).value;

  //   if (lang) {
  //     this.translateService.use(lang);
  //   }
  // }

  async ngOnInit(): Promise<void> {
    let CProps = {
      key1: this.translateService.instant('offline_notification'),
      canBeClosed: false,
      is_confirm_needed: false,
    };
    var offlinePopOver = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: 'popover-custom',
      animated: true,
      componentProps: CProps,
      backdropDismiss: false,
    });
    offlinePopOver.present();

    this.swUpdate.activated.subscribe((val) => {
      // `event.isUpdate` will be true if another version of the service
      // worker was controlling the page when this version was registered.
      console.log('Service worker installing!!!!!');
      console.log(event);
      // if (!event.isUpdate) {
      console.log('Service worker activated for the first time!');
      offlinePopOver.dismiss();
      // If your service worker is configured to precache assets, those
      // assets should all be available now.
      // }
    });
    self.addEventListener('installed', (event) => {
      console.log('[ServiceWorker] installed !');
    });
    // Register the service worker after event listeners have been added.
    // wb.register();
    // Userback.destroy(); // Destroy the existing widget

    // Userback('34570|66635|bN7yiPocCv0hJFZ656a0asFlo', {
    //   is_live: false,
    //   widget_settings: { language: 'fr' },
    // }).then((ub) => {
    //   ub.show();

    //   // document
    //   //   .querySelector('button.show-feedback')
    //   //   .addEventListener('click', function () {
    //   //     ub.show();
    //   //   });
    // });
  }
}
