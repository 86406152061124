import { dev } from 'src/app/config/offlines_keys.json';
import { Component, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';

@Component({
  selector: 'app-dark-mode-toggle',
  templateUrl: './dark-mode-toggle.component.html',
  styleUrls: ['./dark-mode-toggle.component.scss'],
})
export class DarkModeToggleComponent implements OnInit {
  is_dark

  constructor() { }

  async ngOnInit() {
    await Storage.get({ key: dev.IS_DARK }).then(async (value) => {
      if(!value.value){
        Storage.set({ key: dev.IS_DARK, value: JSON.stringify(false) });
        this.is_dark = false

      }else{
        this.is_dark = JSON.parse(value.value)
      }
    })
  }
  handleToggle(){

    this.is_dark=!this.is_dark;
    Storage.set({ key: dev.IS_DARK, value: JSON.stringify(this.is_dark) });
    
        const prefersDark = window.matchMedia(`(prefers-color-scheme: ${!this.is_dark ? 'dark': 'light' }`);
    toggleDarkTheme(prefersDark.matches);
    function toggleDarkTheme(shouldAdd) {
      document.body.classList.toggle('dark', shouldAdd);
    }
  }
}
