import { nextOfflineIndex } from './../reducers/offline.reducer';
import { createAction, props } from '@ngrx/store';
import { Family_member } from 'src/app/models/family_member.model';

export const LOAD_FAMILY_MEMBER = 'LOAD_FAMILY_MEMBER';
export const LOAD_FAMILY_MEMBER_SUCCESS = 'LOAD_FAMILY_MEMBER_SUCCESS';
export const DELETE_FAMILY_MEMBER = 'DELETE_FAMILY_MEMBER';
export const DELETE_FAMILY_MEMBER_SUCCESS = 'DELETE_FAMILY_MEMBER_SUCCESS';
export const ADD_FAMILY_MEMBER = 'ADD_FAMILY_MEMBER';
export const ADD_FAMILY_MEMBER_SUCCESS = 'ADD_FAMILY_MEMBER_SUCCESS';
export const UPDATE_FAMILY_MEMBER = 'UPDATE_FAMILY_MEMBER';
export const UPDATE_FAMILY_MEMBER_SUCCESS = 'UPDATE_FAMILY_MEMBER_SUCCESS';

export const loadFamilyMember = createAction(LOAD_FAMILY_MEMBER);
export const loadFamilyMemberSuccess = createAction(
  LOAD_FAMILY_MEMBER_SUCCESS,
  props<{ familyMembers: Array<Family_member> }>()
);

export const deleteFamilyMember = createAction(
  DELETE_FAMILY_MEMBER,
  props<{ id: number }>()
);
export const deleteFamilyMemberSuccess = createAction(
  DELETE_FAMILY_MEMBER_SUCCESS,
  props<{ id: number }>()
);
export const addFamilyMember = createAction(
  ADD_FAMILY_MEMBER,
  props<{ data: Family_member; props: Object }>()
);
export const addFamilyMemberSuccess = createAction(
  ADD_FAMILY_MEMBER_SUCCESS,
  props<{ data: Family_member; offlineId: string; }>()
);

export const updateFamilyMember = createAction(
  UPDATE_FAMILY_MEMBER,
  props<{ data: Family_member }>()
);

export const updateFamilyMemberSuccess = createAction(
  UPDATE_FAMILY_MEMBER_SUCCESS,
  props<{ data: Family_member }>()
);
