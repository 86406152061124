import { HttpClient } from '@angular/common/http';
import { dev } from '../config/endpoints.json';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ProductAPI {
  base_url = environment.appRoot + '/api/';
  headers;
  constructor(private httpClient: HttpClient) {}

  getProductByCategoriesId(is_coffe) {
    const url =
      this.base_url +
      dev.endpoint.product.get_product_by_categorie_id +
      is_coffe;
    return this.httpClient.get(url);
  }
  getProductById(productsId) {
    const url =
      this.base_url +
      dev.endpoint.product.get_product_by_id
      const formData = new FormData();
      formData.append('productsId', productsId);
    return this.httpClient.post(url,formData);
  }
  getProductGenetic(product_id, is_animal) {
    const url =
      this.base_url +
      dev.endpoint.product.get_product_genetics +
      '/' +
      product_id +
      '/' +
      is_animal;
    return this.httpClient.get(url);
  }
  filteredProduct(is_animal) {
    const url = this.base_url + dev.endpoint.productions.filtered_product+'/'+is_animal;
    return this.httpClient.get(url);
  }

  getAgriculturalCategoriesAndProduct() {
    const url = this.base_url + dev.endpoint.product.get_categories;
    return this.httpClient.get(url);
  }

  getCashflowByProductId(id) {
    const url =
      this.base_url +
      dev.endpoint.product.get_cashflow_by_product_id +
      '/' +
      id;

    return this.httpClient.get(url);
  }
}
