import { ToastUtils } from 'src/app/utils/toast_builder';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Storage } from '@capacitor/storage';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import { dev } from '../../../../config/offlines_keys.json';
import { lastValueFrom, take } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-card-summary-regrouped',
  templateUrl: './card-summary-regrouped.component.html',
  styleUrls: ['./card-summary-regrouped.component.scss'],
})
export class CardSummaryRegroupedComponent implements OnInit, OnChanges {
  title;
  @Input() month;
  @Input() year;
  currency;
  total_objective = 0;
  @Input() farmsSummaries;
  @Input() summaryPersonal;
  @Input() summaryHogar;
  @Input() summaryAhoros;
  @Input() routingConfig;

  mearge_brut_coffe = 0;
  global_farm = 0.0;
  saldo_mese = 0.0;
  @Output() calendarSelection: EventEmitter<any> = new EventEmitter();
  @Output() emitBackPress: EventEmitter<void> = new EventEmitter();
  @Output() emitNextPress: EventEmitter<void> = new EventEmitter();
  is_current_month = false;
  selectedDate = new Date().toISOString();
  c = (value) => {
    this.selectedDate = new Date().toISOString();
    this.month = value.month.value;
    this.year = value.year.value;
    this.calendarSelection.emit(new Date(this.selectedDate));
    this.month = value.month.value;
    this.year = value.year.value;
    this.setTitle();
    return true;
  };
  potentialSavings =0
  constructor(
    public translateService: TranslateService,
    public toastUtils: ToastUtils,
    private store:Store
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    this.global_farm = 0;
    this.saldo_mese = 0;
    if (Array.isArray(this.farmsSummaries)) {
      this.farmsSummaries.forEach((element) => {
        this.global_farm += element.net;
      });
      this.orderAccountings();
    }
    this.saldo_mese= this.global_farm + this.summaryHogar.net + this.summaryPersonal.net;
    if (this.saldo_mese > 0) this.potentialSavings = this.saldo_mese;
    else this.potentialSavings = 0;

    // this.total_objective = this.saldo_mese;
    if (this.total_objective < 0) this.total_objective = 0;
    this.summaryAhoros.net = Math.abs(this.summaryAhoros.net);
    this.setTitle();
  }

  async ngOnInit() {
    this.setTitle();
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setTitle();
    });
    const country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)));
    this.currency = country && 'currency' in country ? country.currency : null;
    
    await Storage.get({ key: dev.OBJECTIVES_KEY }).then((value) => {
      const obj = JSON.parse(value.value);
      if (Array.isArray(obj)) {
        obj.forEach((element) => {
          this.total_objective += element?.required_amount;
        });
      } else this.total_objective = 0;
    });
  }

  async showPopOver(label) {
    let componentProps = {
      key1: label,
      close_button: await this.translateService.get('submit').toPromise(),
    };
    const popover = await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
  }
  orderAccountings() {
    this.farmsSummaries.sort((a, b) => {
      return this.translateService
        .instant('accounting_entries.' + a.id)
        .normalize('NFD')
        .toLowerCase() ===
        this.translateService
          .instant('accounting_entries.' + b.id)
          .normalize('NFD')
          .toLowerCase()
        ? 0
        : this.translateService
            .instant('accounting_entries.' + a.id)
            .normalize('NFD')
            .toLowerCase() <
          this.translateService
            .instant('accounting_entries.' + b.id)
            .normalize('NFD')
            .toLowerCase()
        ? -1
        : 1;
    });
  }
  async setTitle() {
    this.month === new Date().getMonth() + 1 &&
    this.year == new Date().getFullYear()
      ? (this.is_current_month = true)
      : (this.is_current_month = false);
    this.title =
      (await this.translateService
        .get('monthNames')
        .toPromise()
        .then(
          (value) => value[new Date(this.year, this.month, 0).getMonth()]
        )) +
      ' ' +
      new Date(this.year, this.month, 0).getFullYear();
  }

  emitPreviousClick() {
    this.emitBackPress.emit();
  }
  emitNextClick() {
    this.emitNextPress.emit();
  }
  showCalendar(value) {
    this.calendarSelection.emit(value);
  }
}
