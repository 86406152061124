import { dev } from 'src/app/config/offlines_keys.json';

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@capacitor/storage';
import routes from '../../../config/routing.json';
@Component({
  selector: 'app-partner-main-view',
  templateUrl: './partner-main-view.component.html',
  styleUrls: ['./partner-main-view.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: false, displayDefaultIndicatorType: false },
    },
  ],
})
export class PartnerMainViewComponent implements OnInit {
  isDashboardView;
  url;
  owner;
  cropConfig;
  step;
  nextUrl;
  backUrl;
  cropProducts;
  constructor(private router: Router, private routingConfig: RoutingConfig) {}
  async ngOnInit() {
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)

    this.nextUrl = await this.routingConfig.getNextUrl();
    this.backUrl = await this.routingConfig.getBackUrl();
    this.cropConfig = await this.routingConfig.getCropFormConfig();
    this.owner = await JSON.parse(
      (
        await Storage.get({ key: dev.USER_KEY })
      ).value
    );
    if (this.owner)
      this.cropProducts = JSON.parse(this.owner?.profile_info).cropConfig;
    else this.cropProducts = [];
  }

  async mnext() {
    this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }
  async mreturn() {
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());
  }
  onStepChange(ev) {
    this.step = ev.selectedIndex;
  }
}
