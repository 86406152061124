import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import routes from '../../config/routing.json';
@Component({
  selector: 'app-last-updated-at',
  templateUrl: './last-updated-at.component.html',
  styleUrls: ['./last-updated-at.component.scss'],
})
export class LastUpdatedAtComponent implements OnInit {
  @Input() update_at: string;
  isDashboardView: boolean;
  constructor(private router: Router,public translateService:TranslateService) {}

  ngOnInit() {
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)

  }
}
