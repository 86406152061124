import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, take } from 'rxjs';
import { UserInfo } from 'src/app/apis/users-info.service';
import Owner from 'src/app/models/owner.model';
import { connectionStatus } from 'src/app/utils/states-management/reducers/offline.reducer';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent implements OnInit {
  listLang: any = [
    {
      name: 'Español',
      name_en: 'Español',
      key: 'es',
      file: {
        path: '/storage/images/flags/es.png',
      },
    },
    {
      name: 'English',
      name_en: 'English',
      key: 'en',
      file: {
        path: '/storage/images/flags/uk.png',
      },
    },
    {
      name: 'Français',
      name_en: 'Français',
      key: 'fr',
      file: {
        path: '/storage/images/flags/fra.png',
      },
    },
  ];
  constructor(
    private store: Store<{ owner: Owner }>,
    public translateService: TranslateService,
    public userInfo:UserInfo
  ) {}
  owner;
  searchInput;
  selectedLang;
  base_url = environment.appRoot

  async ngOnInit() {
    this.owner = await lastValueFrom(this.store.select('owner').pipe(take(1)));
    let config = JSON.parse(this.owner?.countrie?.config);
    this.listLang = this.listLang.filter((value) =>
      config?.language.includes(value?.key)
    );
    this.FullArray = this.listLang;
    this.selectedLang = this.translateService.currentLang;
  }
  FullArray;
  handelSearch() {
    if (this.searchInput) {
      this.listLang = this.listLang.filter((val) =>
        this.translateService
          .instant('language.' + val.key)
          .includes(this.searchInput)
      );
    } else {
      this.listLang = this.FullArray;
    }
  }
  select_langage
  async handelChangeCountrieName(event){
    this.select_langage = event.target.value;
    this.translateService.use(this.select_langage);
    let isOnline=await lastValueFrom(
      this.store.select(connectionStatus).pipe(take(1))
    )
    // if(!isOnline){
    //   window.location.reload();
    // }
    await this.userInfo.setSelectedLangage(event.target.value);

  }



}
