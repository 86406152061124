export class Objective {
  owner_id;
  id;
  cash_flow_categories_id;
  libelle;
  required_amount;
  gather_so_far;

  toFormData() {
    const formData = new FormData();
    this.id ? formData.append('id', this?.id) : null;
    formData.append('owner_id', this.owner_id);
    formData.append('cash_flow_categories_id', this.cash_flow_categories_id);
    formData.append('libelle', this.libelle);
    formData.append('required_amount', this.required_amount);
    formData.append('gather_so_far', this.gather_so_far);
    return formData
  }
}
