export default class Employee {
  
  id?
  is_permanent?
  is_receiving_salary?
  is_admin?
  sex?
  name?
  farm_id?
  morphable_type?
  morphable_id?
  constructor(){}

}
