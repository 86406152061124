import { Injectable } from '@angular/core';
import {
  LoadingController,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { ProductionAPI } from 'src/app/apis/FarmAPI/productions.service';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import { CashflowAPI } from '../../apis/cashflow.service';
import { OperationsAPI } from '../../apis/operations.service';
import Operation from '../../models/operation.model';
import { PopoverComponent } from '../../widgets/popover/popover.component';
import { orderAlphabaticallyByAttribute } from '../array-filtrer';
import {
  ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY,
  CAFE_ACCOUNTING_ENTRY_CATEGORY,
  CAFE_IDS,
  DEBT_OPERATION,
  GARENTEE_OPERATION,
  LANDING_CASHFLOW_ENTRY,
  MONEY_LOAN_OPERATION,
  MORPH_FamilyMember,
  MORPH_Farm,
  MORPH_Garentee,
  MORPH_Owner,
  OPERATION_UPDATER_CASHFLOW_CATEGORIES,
  OTROS_ACCOUNTING_ENTRY_CATEGORY,
  PAGO_CAUTION_CASHFLOW_CATEGORIE_ADMIN,
  PAGO_CAUTION_CASHFLOW_CATEGORIE_CAFE,
  PAGO_CAUTION_CASHFLOW_CATEGORIE_OTROS,
  PAGO_CAUTION_CASHFLOW_CATEGORIE_PERSONAL,
  PAGO_CAUTION_COFFE_CASHFLOW_EXPENSES,
  PAGO_DE_PRESTAMO_CASHFLOW_CATEGORIE_ADMIN,
  PAGO_DE_PRESTAMO_CASHFLOW_CATEGORIE_PERSONAL,
  PERSONAL_ACCOUNTING_ENTRY_CATEGORY,
  REASON_OPERATION_ADMIN_ARRAY,
  REASON_OPERATION_CAFE,
  REASON_OPERATION_PERSONAL,
  RECIBO_DE_DEUDA_CASHFLOW_CATEGORIE_ADMIN,
  RECIBO_DE_DEUDA_CASHFLOW_CATEGORIE_CAFE,
  RECIBO_DE_DEUDA_CASHFLOW_CATEGORIE_OTROS,
  RECIBO_DE_DEUDA_CASHFLOW_CATEGORIE_PERSONAL,
} from '../consts';
import { makePopover } from '../pop_over_maker';

@Injectable()
export class OperationRelatedToCashflowBusinessRules {
  constructor(
    private operationAPI: OperationsAPI,
    private cashflowAPI: CashflowAPI,
    private productionAPI: ProductionAPI
  ) {}

  updateOperationAfterCashflowUpdate = (cashflow): Observable<any> => {
    return this.operationAPI.getOneOperation(cashflow.operation_id).pipe(
      map((operation: Operation) => {
        if (operation.cashflows) {
          let updatadablteCashflow = operation.cashflows.find(
            (cashflowOperation: any) => {
              if (cashflowOperation.id === cashflow.id)
                return cashflowOperation;
            }
          );
          if (
            updatadablteCashflow &&
            !updatadablteCashflow.is_creation_cashflow
          ) {
            cashflow.initial_amount
              ? (operation.paid_so_far =
                  parseFloat(operation.paid_so_far.toString()) +
                  parseFloat(cashflow.amount) -
                  cashflow.initial_amount)
              : (operation.paid_so_far =
                  parseFloat(operation.paid_so_far.toString()) +
                  parseFloat(cashflow.amount));
          } else {
            operation.amount = updatadablteCashflow.amount;
          }
        }
        return operation;
      }),
      take(1),
      map((operation: Operation) => {
        return this.operationAPI.updateOperation(operation, operation.id);
      }),
      take(1),
      switchMap((operation) => {
        return operation;
      })
    );
  };

  checkValueInputAndShowPopOver = async (
    cashFlow,
    popover: PopoverController
  ) => {
    var msg;
    if (cashFlow.initial_value) {
      const is_not_fine =
        cashFlow.initial_value.amount - cashFlow.initial_value.paid_so_far <
        cashFlow.amount;
      if (is_not_fine) {
        switch (cashFlow?.operation?.query) {
          case 'money loan':
            if (cashFlow.is_income) {
              msg = 'money loan - is_income';
            } else {
              msg = 'money loan - !is_income';
            }

            break;
          case 'debt':
            if (cashFlow.is_income) {
              msg = 'debt - is_income';
            } else {
              msg = 'debt - !is_income';
            }

            break;
          case MORPH_Garentee:
            if (cashFlow.is_income) {
              msg = 'garentee - is_income';
            } else {
              msg = 'garentee - !is_income';
            }
            break;

          default:
            break;
        }

        const pop = await popover.create({
          component: PopoverComponent,
          cssClass: 'popover-custom',
          animated: true,
          componentProps: { key1: msg },
        });
        return pop;
      } else return null;
    }
  };

  insertCashflowAndOperation = (cashflow): Observable<any> => {
    return this.operationAPI.storeOperation(cashflow.new_operation).pipe(
      map((operation: Operation): CashFlow => {
        cashflow.operation_id = operation.id;
        cashflow.amount = operation.amount;
        return cashflow;
      }),
      take(1),
      map((cashflow: CashFlow) => {
        return this.cashflowAPI.saveCashFlow(cashflow);
      }),
      switchMap((cashflow) => cashflow)
    );
  };

  saveCashFlow = async (
    operation: Operation,
    cashFlow: CashFlow,
    cashflowAPI: CashflowAPI,
    loadinModal,
    modalController
  ) => {
    let cashflow2 = new CashFlow();
    cashflow2.amount = operation.amount;
    cashflow2.currency = cashFlow.currency;
    cashflow2.is_income = 0;
    cashflow2.accounting_entries_categories =
      cashFlow.accounting_entries_categories;
    cashflow2.operation_id = operation.id;
    cashflow2.date = new Date().toISOString();
    cashflow2.farm_id = cashFlow.farm_id;
    cashflow2.payment_methods = cashFlow.payment_methods;
    if (cashFlow.payment_methods != 'cash') {
      cashflow2.money_reporting_id = cashFlow.money_reporting_id;
    }
    if (
      operation.morphable_type === MORPH_FamilyMember ||
      operation.morphable_type === MORPH_Owner
    ) {
      operation.morphable_type === MORPH_Owner
        ? (cashflow2.morphable_type = 'Owner')
        : (cashflow2.morphable_type = 'Family_member');
      cashflow2.morphable_id = operation.morphable_id;
      cashflow2.accounting_entries_categories =
        PERSONAL_ACCOUNTING_ENTRY_CATEGORY;
      if (operation.operation_type === DEBT_OPERATION) {
        cashflow2.is_income = 1;
        cashflow2.cash_flow_categorie_id =
          RECIBO_DE_DEUDA_CASHFLOW_CATEGORIE_PERSONAL;
      } else if (operation.operation_type === MONEY_LOAN_OPERATION) {
        cashflow2.is_income = 0;
        cashflow2.cash_flow_categorie_id =
          PAGO_DE_PRESTAMO_CASHFLOW_CATEGORIE_PERSONAL;
      } else {
        cashflow2.is_income = 0;
        cashflow2.cash_flow_categorie_id =
          PAGO_CAUTION_CASHFLOW_CATEGORIE_PERSONAL;
      }
    } else if (operation.morphable_type === MORPH_Farm) {
      cashflow2.accounting_entries_categories =
        ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY;
      if (operation.operation_type === DEBT_OPERATION) {
        cashflow2.is_income = 1;
        cashflow2.cash_flow_categorie_id =
          RECIBO_DE_DEUDA_CASHFLOW_CATEGORIE_ADMIN;
      } else if (operation.operation_type === MONEY_LOAN_OPERATION) {
        cashflow2.is_income = 0;
        cashflow2.cash_flow_categorie_id =
          PAGO_DE_PRESTAMO_CASHFLOW_CATEGORIE_ADMIN;
      } else {
        cashflow2.is_income = 0;
        cashflow2.cash_flow_categorie_id =
          PAGO_CAUTION_CASHFLOW_CATEGORIE_ADMIN;
      }
    }
    cashflowAPI.saveCashFlow(cashflow2).subscribe(
      (val) => {
        loadinModal.dismiss();
        modalController.dismiss({
          dismissed: true,
          loan: operation,
        });
      },
      (e) => {
        loadinModal.dismiss();
        console.log(e);
        modalController.dismiss({
          dismissed: true,
          loan: operation,
        });
      }
    );
  };

  saveOperationCashflow = async (
    operation: any,
    farm_id,
    loadinModal: LoadingController,
    modalController: ModalController,
    require_tag = false,
    popoverController: PopoverController,
    message: String,
    isDashboardView: boolean,
    products
  ) => {
    var cashflow = new CashFlow();

    cashflow.date = operation.creation_date;
    cashflow.amount = operation.amount;
    cashflow.currency = operation.currency;
    cashflow.money_reporting_id = operation.money_reporting_id;
    cashflow.payment_methods = operation.payment_methods;
    cashflow = await this.cashflowSetUpAccountingCatAndCashflowCat(
      cashflow,
      operation,
      products
    );
    require_tag
      ? (cashflow.comment = LANDING_CASHFLOW_ENTRY)
      : (cashflow.comment = null);
    cashflow.operation_id = operation.id;
    cashflow.farm_id = farm_id;
    this.cashflowAPI.saveCashFlow(cashflow).subscribe(
      async (value) => {
        loadinModal.dismiss();
        if (isDashboardView) {
          const popover: HTMLIonPopoverElement = await makePopover(
            popoverController,
            message
          );
          await popover.onWillDismiss();
        }
        modalController.dismiss({
          dismissed: true,
          loan: value,
        });
      },
      (e) => {
        console.log(e);
        loadinModal.dismiss();
        modalController.dismiss({
          dismissed: true,
        });
      }
    );
  };

  async cashflowSetUpAccountingCatAndCashflowCat(
    cashflow,
    operation,
    products
  ): Promise<CashFlow> {
    if (
      operation.morphable_type === MORPH_FamilyMember ||
      operation.morphable_type === MORPH_Owner
    ) {
      operation.morphable_type === MORPH_Owner
        ? (cashflow.morphable_type = 'Owner')
        : (cashflow.morphable_type = 'Family_member');
      cashflow.morphable_id = operation.morphable_id;
      if (operation.operation_type === DEBT_OPERATION) {
        cashflow.is_income = true;

        if (REASON_OPERATION_PERSONAL.includes(operation.operation_reason)) {
          cashflow.accounting_entries_categories =
            PERSONAL_ACCOUNTING_ENTRY_CATEGORY;
          cashflow.cash_flow_categorie_id =
            RECIBO_DE_DEUDA_CASHFLOW_CATEGORIE_PERSONAL;
        } else if (
          REASON_OPERATION_ADMIN_ARRAY.includes(operation.operation_reason)
        ) {
          cashflow.accounting_entries_categories =
            ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY;
          cashflow.cash_flow_categorie_id =
            RECIBO_DE_DEUDA_CASHFLOW_CATEGORIE_ADMIN;
        } else if (REASON_OPERATION_CAFE.includes(operation.operation_reason)) {
          cashflow.accounting_entries_categories =
            CAFE_ACCOUNTING_ENTRY_CATEGORY;
          cashflow.cash_flow_categorie_id =
            RECIBO_DE_DEUDA_CASHFLOW_CATEGORIE_CAFE;
          // cashflow.cash_flow_expense_id = RECI;
        } else {
          cashflow.cash_flow_categorie_id =
            RECIBO_DE_DEUDA_CASHFLOW_CATEGORIE_OTROS;
          cashflow.accounting_entries_categories =
            OTROS_ACCOUNTING_ENTRY_CATEGORY;
          cashflow = await this.setUpCashflowProductId(
            cashflow,
            operation,
            products
          );
        }
      } else if (operation.operation_type === MONEY_LOAN_OPERATION) {
        cashflow.is_income = false;
        cashflow.cash_flow_categorie_id =
          PAGO_DE_PRESTAMO_CASHFLOW_CATEGORIE_PERSONAL;
        cashflow.accounting_entries_categories =
          PERSONAL_ACCOUNTING_ENTRY_CATEGORY;
      } else if (operation.operation_type === GARENTEE_OPERATION) {
        cashflow.is_income = false;
        if (REASON_OPERATION_PERSONAL.includes(operation.operation_reason)) {
          cashflow.cash_flow_categorie_id =
            PAGO_CAUTION_CASHFLOW_CATEGORIE_PERSONAL;
          cashflow.accounting_entries_categories =
            PERSONAL_ACCOUNTING_ENTRY_CATEGORY;
        } else if (
          REASON_OPERATION_ADMIN_ARRAY.includes(operation.operation_reason)
        ) {
          cashflow.cash_flow_categorie_id =
            PAGO_CAUTION_CASHFLOW_CATEGORIE_ADMIN;
          cashflow.accounting_entries_categories =
            ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY;
        } else if (REASON_OPERATION_CAFE.includes(operation.operation_reason)) {
          cashflow.cash_flow_categorie_id =
            PAGO_CAUTION_CASHFLOW_CATEGORIE_CAFE;
          cashflow.accounting_entries_categories =
            CAFE_ACCOUNTING_ENTRY_CATEGORY;
          cashflow.cash_flow_expense_id = PAGO_CAUTION_COFFE_CASHFLOW_EXPENSES;
        } else {
          cashflow.cash_flow_categorie_id =
            PAGO_CAUTION_CASHFLOW_CATEGORIE_OTROS;

          cashflow.accounting_entries_categories =
            OTROS_ACCOUNTING_ENTRY_CATEGORY;
          cashflow = await this.setUpCashflowProductId(
            cashflow,
            operation,
            products
          );
        }
      }
    } else if (operation.morphable_type === MORPH_Farm) {
      if (operation.operation_type === DEBT_OPERATION) {
        cashflow.is_income = true;

        if (REASON_OPERATION_ADMIN_ARRAY.includes(operation.operation_reason)) {
          cashflow.cash_flow_categorie_id =
            RECIBO_DE_DEUDA_CASHFLOW_CATEGORIE_ADMIN;
          cashflow.accounting_entries_categories =
            ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY;
        } else if (REASON_OPERATION_CAFE.includes(operation.operation_reason)) {
          cashflow.cash_flow_categorie_id =
            RECIBO_DE_DEUDA_CASHFLOW_CATEGORIE_CAFE;
          cashflow.accounting_entries_categories =
            CAFE_ACCOUNTING_ENTRY_CATEGORY;
          cashflow.cash_flow_expense_id = PAGO_CAUTION_COFFE_CASHFLOW_EXPENSES;
        } else {
          cashflow.cash_flow_categorie_id =
            RECIBO_DE_DEUDA_CASHFLOW_CATEGORIE_OTROS;
          cashflow.accounting_entries_categories =
            OTROS_ACCOUNTING_ENTRY_CATEGORY;
          cashflow = await this.setUpCashflowProductId(
            cashflow,
            operation,
            products
          );
        }
      } else if (operation.operation_type === MONEY_LOAN_OPERATION) {
        cashflow.is_income = false;
        cashflow.cash_flow_categorie_id =
          PAGO_DE_PRESTAMO_CASHFLOW_CATEGORIE_ADMIN;
        cashflow.accounting_entries_categories =
          ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY;
      } else if (operation.operation_type === GARENTEE_OPERATION) {
        cashflow.is_income = false;
        if (REASON_OPERATION_ADMIN_ARRAY.includes(operation.operation_reason)) {
          cashflow.cash_flow_categorie_id =
            PAGO_CAUTION_CASHFLOW_CATEGORIE_ADMIN;
          cashflow.accounting_entries_categories =
            ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY;
        } else if (REASON_OPERATION_CAFE.includes(operation.operation_reason)) {
          cashflow.cash_flow_categorie_id =
            PAGO_CAUTION_CASHFLOW_CATEGORIE_CAFE;
          cashflow.accounting_entries_categories =
            CAFE_ACCOUNTING_ENTRY_CATEGORY;
          cashflow.cash_flow_expense_id = PAGO_CAUTION_COFFE_CASHFLOW_EXPENSES;
        } else {
          cashflow.cash_flow_categorie_id =
            PAGO_CAUTION_CASHFLOW_CATEGORIE_OTROS;

          cashflow.accounting_entries_categories =
            OTROS_ACCOUNTING_ENTRY_CATEGORY;
          cashflow = await this.setUpCashflowProductId(
            cashflow,
            operation,
            products
          );
        }
      }
    }

    return cashflow;
  }

  updateCashflowAfterOperationUpdate = async (
    operation: any,
    modalController: ModalController,
    loadingController: LoadingController,
    popoverController: PopoverController,
    message,
    isDashboardView,
    products
  ) => {
    let cashflow;
    if (operation.cashflows) {
      operation.operation_type === DEBT_OPERATION
        ? (cashflow = operation.cashflows.find(
            (element: CashFlow) => element.is_income === 1
          ))
        : (cashflow = operation.cashflows.find((element: CashFlow) => {
            if (element.is_income === 0) return element;
          }));
    }
    if (cashflow) {
      cashflow.amount = operation.amount;
      cashflow.currency = operation.currency;
      cashflow.date = operation.creation_date;
      cashflow.morphable_type = operation.morphable_type;
      cashflow.morphable_id = operation.morphable_id;
      cashflow.money_reporting_id = operation.money_reporting_id;
      cashflow.payment_methods = operation.payment_methods;
      cashflow = await this.cashflowSetUpAccountingCatAndCashflowCat(
        cashflow,
        operation,
        products
      );
      this.cashflowAPI.updateCashFlow(cashflow).subscribe(
        async (updatedCashflow) => {
          updatedCashflow;
          loadingController.dismiss();
          if (isDashboardView) {
            const popover = await makePopover(popoverController, message);

            await popover.onWillDismiss().then((value) => {
              modalController.dismiss();
            });
          } else modalController.dismiss();
        },
        (e) => {
          console.log(e);
          loadingController.dismiss();
          modalController.dismiss();
        }
      );
    } else {
      loadingController.dismiss();
      modalController.dismiss();
    }
  };

  setUpCashflowProductId = async (
    cashflow: CashFlow,
    operation: Operation,
    products
  ) => {
    const findProduct = products.find(
      (product) => product.value === operation.operation_reason
    );

    if (findProduct && !findProduct.is_animal) {
      cashflow.product_id = findProduct.product_id;
    } else if (findProduct) {
      cashflow.material_categorie_id = findProduct.product_id;
    }
    return cashflow;
  };

  verifCashflowOperationUpdate = (cashflow): Boolean => {
    if (
      OPERATION_UPDATER_CASHFLOW_CATEGORIES.includes(
        cashflow.cash_flow_categorie_id
      ) &&
      (!cashflow.amount || !cashflow.operation_id)
    )
      return true;
    return false;
  };

  async setUpOperationReasons(currentLang: string, products) {
    let returnableProducts = new Array();
    this.productionAPI
      .getAllFarmProduction()
      .toPromise()
      .then((value) => {
        if (Array.isArray(value)) {
          value.forEach((element) => {
            if (!CAFE_IDS.includes(element.morphable_id)) {
              if (
                element.comment &&
                element.comment != '' &&
                !products.find((val) => val.product_id == element.morphable_id)
              ) {
                if (
                  !products.find((element) => element.label == element.comment)
                )
                  products.push({
                    label: element.comment,
                    value: element.name,
                    product_id: element.morphable_id,
                    is_animal: element.is_animal,
                  });
              } else if (
                !products.find((val) => val.product_id == element.morphable_id)
              ) {
                currentLang === 'es'
                  ? products.push({
                      label: element.name,
                      value: element.name,
                      product_id: element.morphable_id,
                      is_animal: element.is_animal,
                    })
                  : products.push({
                      label: element['name_' + currentLang],
                      value: element['name_' + currentLang],
                      product_id: element.morphable_id,
                      is_animal: element.is_animal,
                    });
              }
            }
          });
        }
      });
    returnableProducts = products;

    return orderAlphabaticallyByAttribute(returnableProducts, 'label', 'es');
  }
}
