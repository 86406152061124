import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { dev } from '../../config/offlines_keys.json';
import { Storage } from '@capacitor/storage';

import { StatisticsAPI } from 'src/app/apis/statistics.service';
import { UserInfo } from 'src/app/apis/users-info.service';
import { IonNav, ModalController } from '@ionic/angular';

import {
  CoffeeKpisComponent,
  KPI,
} from 'src/app/modules/dashboard/kpis/coffee-kpis/coffee-kpis.component';
import { AgricolProductKpisComponent } from 'src/app/modules/dashboard/kpis/agricol-product-kpis/agricol-product-kpis.component';
import { AnimalKpisComponent } from 'src/app/modules/dashboard/kpis/animal-kpis/animal-kpis.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { PopoverComponent } from '../popover/popover.component';
import { Store } from '@ngrx/store';
import { CAFE_ACCOUNTING_ENTRY_CATEGORY } from 'src/app/utils/consts';
import { UnifiedCashflowInputComponentComponent } from 'src/app/modules/cashflow/unified-cashflow-input-component/unified-cashflow-input-component.component';
import { AccountingEntryCategorie } from 'src/app/models/accounting/accounting_entries_categories';
import { lastValueFrom, take,map, switchMap  } from 'rxjs';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import Owner from 'src/app/models/owner.model';
import { cloneDeep } from 'lodash';
import { LotManagerComponent } from 'src/app/modules/farm/lots-components/lot-manager/lot-manager.component';
import { USAGE_TYPE } from 'src/app/models/lots.model';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { getTotalsAccountingCategoriesResult } from 'src/app/utils/states-management/selector/dashboard.selector';
import { getDashboardData } from 'src/app/utils/states-management/actions/dashboard.action';

@Component({
  selector: 'app-summary-production-cost',
  templateUrl: './summary-production-cost.component.html',
  styleUrls: ['./summary-production-cost.component.scss'],
})
export class SummaryProductionCostComponent implements OnInit, OnChanges {
  @Input() nav: IonNav;
  currency;
  coffe_rendement: any;
  prod_cost;
  total_coffe;
  other_prod: any = [];
  animal_prod: any = [];
  user;
  farm;
  coffe_unit_label;
  coffe_unit;
  toDay;
  title;
  no_quantity;
  missing_prod_lot;
  coffe_prod_per_ha;
  coffe_unprod_per_ha;
  coffe_productive_tress;
  coffe_unproductive_tress;
  coffee_incomes;
  productivity_cafe_cereza;
  average_sell_price_of_coffee_cereza;
  production_cost_cereza;
  distribution_sale_coffee_cereza;
  margin_brut_cereza;
  productionKPI: Array<KPI> = [];
  accountingSelected: AccountingEntryCategorie;
  selectedKpiType = 'per_kg'
  @Input() from = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    0
  ).toISOString();
  @Input() to = new Date().toISOString();
  constructor(
    private userInfo: UserInfo,
    public translateService: TranslateService,
    private statisticsAPI: StatisticsAPI,
    private toastUtils: ToastUtils,
    private store: Store<{
      accountingEntries: Array<AccountingEntryCategorie>;
      owner: Owner;
      dashboard: any;
    }>,
    private modalController: ModalController
  ) { }
  accountingEntries;
  async ngOnChanges(changes: SimpleChanges) {
    this.setupotherProdList();
    this.toDay = await this.setTitle();
  }

  async ngOnInit() {
    const country:any = await lastValueFrom(
      this.store.select(getCountry).pipe(take(1)));
    this.store
      .select('accountingEntries')
      .pipe(
        switchMap((value) => {
          this.accountingSelected = cloneDeep(value).filter(
            (elment) => elment.morphable_type
          )[0];
          return this.statisticsAPI.getCafeCerezKpi(
            this.from,
            this.to,
            this.accountingSelected?.id
          );
        })
      )
      .subscribe((value) => {
        this.productionKPI = value.filter(
          (elment) => elment.type == 'production'
        );
        // this.handleCoffeKPIResponse(value);
      });
    this.currency = country?.currency;
    this.toDay = await this.setTitle();
    this.user = await this.userInfo.getOwner();
    this.user = this.user?.user;
    this.store
      .select(getTotalsAccountingCategoriesResult)
      .pipe(take(1))
      .subscribe(({data}) => (this.accountingEntries = cloneDeep(data)));
  }

  async setupotherProdList() {

    //TODO: to check offline

    // this.store.dispatch(getDashboardData());

    // this.store.select('dashboard').subscribe((value) => {
    //   let val =cloneDeep(value)
    //   this.mapAnimalProduct(val.materialProductKpis);
    //   this.mapProduct(val.productKpis);
    //   this.coffeKPI = val.cafeCerezKpi;
    // });
    this.statisticsAPI.getProductKpis(this.from, this.to).subscribe(
      (value) => {
        this.mapProduct(value);
      },
      (e) => console.log(e)
    );
  }
  
  async openHarvestingCashflow() {

    let cashflow = new CashFlow();
    cashflow.date = new Date().toISOString();
    cashflow.farm_id = this.user?.owner?.farm_id;
    cashflow.accounting_entry_categories = this.accountingSelected;
    cashflow.is_income = false;
    if (this.accountingSelected.id == 10) {
      cashflow.cash_flow_expense_id = 63;
      cashflow.cashflow_expenses_categorie =
        this.accountingSelected?.cashflow_category
          .find((value) => value.id == 299)
          ?.cash_flow_expenses.find((value) => value.id == 63);

      cashflow.cashflow_categories = this.accountingSelected?.cashflow_category.find(
        (value) => value.id == 299
      );
      cashflow.cash_flow_categorie_id = 299;
    } else {
      cashflow.cash_flow_categorie_id = 148;
      cashflow.cash_flow_expense_id = 63;
      cashflow.cashflow_expenses_categorie =
        this.accountingSelected?.cashflow_category
          .find((value) => value.id == 292)
          ?.cash_flow_expenses.find((value) => value.id == 63);
      cashflow.cashflow_categories = this.accountingSelected?.cashflow_category.find(
        (value) => value.id == 292
      );
      cashflow.cash_flow_categorie_id = 292;
    }
    const modal = await this.modalController.create({
      component: UnifiedCashflowInputComponentComponent,
      cssClass: ['my-custom-modal-css ', 'modal-wrapper'],
      componentProps: {
        // cashFlowEntrieName: sell.accounting_entries_categories,
        accountingEntryCategories: this.accountingSelected,
        modalController: this.modalController,
        cashflow: cashflow,
        is_update: false,
        popOverContent: this.translateService.instant('select_harvesting'),
      },
    });
    modal.present();
    await modal.onWillDismiss();
    this.setupotherProdList();
  }

  async redirectToPlots() {
    this.nav.setRoot(LotManagerComponent, {
      ...this.nav,
      used_for: USAGE_TYPE.agricol,
      title: await this.translateService.instant('titles.coffe_prod'),
      isDashboardView: true,
    });
  }
  mapProduct(val) {
    this.other_prod = val?.sort((a, b) => b.neto - a.neto);
  }

  mapAnimalProduct(val) {
    this.animal_prod = val?.sort((a, b) => b.neto - a.neto);
  }

  async navigateTo(val: number, label: string) {
    let comp;
    if (val === 1) {
      comp = CoffeeKpisComponent;
    } else if (val === 2) {
      comp = AgricolProductKpisComponent;
    } else if (val === 3) {
      comp = AnimalKpisComponent;
    }
    this.nav.setRoot(comp, {
      label: label,
    });
  }
  async setTitle() {
    return (
      (await this.translateService
        .get('monthNames')
        .toPromise()
        .then((value) => value[new Date(this.from).getMonth()])) +
      ' ' +
      new Date(this.from).getFullYear()
    );
  }

  // handleCoffeKPIResponse(value) {
  //   if (value.total_harvested_quantity && value.total_production_area) {
  //     this.coffe_rendement = (
  //       value.total_harvested_quantity / value.total_production_area
  //     ).toFixed(2);
  //     this.coffe_unit_label = 'KG / Hec';
  //   }
  //   if (value.total_harvested_quantity && value.total_expense) {
  //     this.prod_cost = (
  //       value.total_expense / value.total_harvested_quantity
  //     ).toFixed(2);
  //   }
  //   if (value.total_harvested_quantity && value.total_income) {
  //     this.coffee_incomes = value.total_income / value.total_harvested_quantity;
  //   }
  //   if (value.total_unproductive_area && value.total_unproductive_tress)
  //     this.coffe_unprod_per_ha =
  //       value.total_unproductive_tress / value.total_unproductive_area;

  //   if (value.total_production_area && value.total_productive_trees)
  //     this.coffe_prod_per_ha =
  //       value.total_productive_trees / value.total_production_area;
  //   this.total_coffe = value.gross_margin;

  //   if (value.total_production_area && value.total_harvested_quantity)
  //     this.productivity_cafe_cereza = value.average_sell_price_cereza

  //   this.average_sell_price_of_coffee_cereza = value.average_cereza_sell_price;

  //   this.production_cost_cereza = value.coffe_cerez_average_production_cost;

  //   this.distribution_sale_coffee_cereza =
  //     value.distribution_sale_coffee_cereza;
  //   this.margin_brut_cereza = value.margin_brut_cereza;
  // }

  async showPopover(option: string) {
    let message = '';
    let alertMsg = '';
    message = await this.translateService.instant(option);
    // switch (option) {
    //   case 1:
    //     message = await this.translateService.instant(
    //       'label.coffee_productivity_formula'
    //     );
    //     break;
    //   case 2:
    //     message = await this.translateService.instant(
    //       'label.average_sell_price_cereza_formula'
    //     );

    //     break;
    //   case 3:
    //     message = await this.translateService.instant(
    //       'label.coffee_cereza_production_cost_formula'
    //     );
    //     break;

    //   case 4:
    //     message = await this.translateService.instant(
    //       'label.distribution_and_sale_coffe_cereza_formula'
    //     );
    //     break;
    //   case 5:
    //     message = await this.translateService.instant(
    //       'label.margen_bruto_cereza_formula'
    //     );
    //     alertMsg = 'label.not_included';
    //     break;
    // }

    this.toastUtils.popOverBuilder(
      {
        key1: message,
        alertMsg: alertMsg,
      },
      PopoverComponent,
      'popover-custom'
    );
  }
}
