
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { dataSyncWithServerError } from '../actions/owner.action';
import { of } from 'rxjs';
import { ProductionAPI } from 'src/app/apis/FarmAPI/productions.service';
import { addAnimals, addAnimalsSuccess, deleteAnimalGood, deleteAnimalGoodSuccess, deleteSelectedAnimal, deleteSelectedAnimalSuccess, loadAnimal, loadAnimalSuccess, loadSelectedAnimal, loadSelectedAnimalSuccess } from '../actions/animals.action';
import { MaterialProductAPI } from 'src/app/apis/material_product.service';
import { GoodsAPI } from 'src/app/apis/goods.service';

@Injectable()
export class AnimalsEffect {
  constructor(
    private actions$: Actions,
    private materialProductAPI: MaterialProductAPI,
    private goodsAPi: GoodsAPI,
    private productionAPI: ProductionAPI //   public translateService: TranslateService,
  ) {}
  loadAnimal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAnimal),
      switchMap((action) => {
        return this.materialProductAPI.getAnimalCategories();
      }),
      map((data: any) => {
        return loadAnimalSuccess({ animals: data });
      }),
      catchError((err) => of(dataSyncWithServerError(err)))
    )
  );
  loadSelectedAnimal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSelectedAnimal),
      switchMap((action) => {
        return this.productionAPI.getProductionByFarmAndType(1);
      }),
      map((data: any) => {
        return loadSelectedAnimalSuccess({
          selectedAnimals: data,
        });
      })
      //   catchError((err) => of(dataSyncWithServerError(err)))
    )
  );
  deleteSelectedAnimal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSelectedAnimal),
      switchMap((action) => {
        return this.productionAPI.deleteFarmProduction(action.product.id);
      }),
      map((data: any) => {
        return deleteSelectedAnimalSuccess();
      }),
    //   catchError((err) => of(dataSyncWithServerError(err)))
    )
  );
  deleteAnimalGood$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAnimalGood),
      switchMap((action) => {
        return this.goodsAPi.deleteGood(action.good.id);
      }),
      map((data: any) => {
        return deleteAnimalGoodSuccess();
      }),
    //   catchError((err) => of(dataSyncWithServerError(err)))
    )
  );
  addAnimalSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addAnimals),
      switchMap((action) => {
        return this.productionAPI.insertMultipleProduction(action.productions);
      }),
      map((data: any) => {
        return addAnimalsSuccess({
          productions: data.filter((value) => value.is_animal == 1),
        });
      }),
      catchError((err) => of(dataSyncWithServerError(err)))
    )
  );
}
