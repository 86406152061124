import { filter } from 'rxjs/operators';
import { addEmployee, deleteEmployee } from '../actions/employee.action';
import { cloneDeep } from 'lodash';
import {
  addPartner,
  addPartnerSuccess,
  deletePartner,
  loadPartner,
  updatePartner,
  updatePartnerSuccess,
  deletePartnerSuccess,
  loadPartnerSuccess,
  loadPartnerType,
  loadPartnerTypeSuccess,
} from '../actions/partner.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import Partner from 'src/app/models/partner.model';

export interface PartnerState extends EntityState<Partner> {
  //  additional entity state properties
  selectedPartnerId: number | null;
  loading: boolean;
  partnersType: any;
  error: any;
}

export function selectPartnerById(a: Partner): number {
  //In this case this would be optional since primary key is id
  return a.id || new Date().getTime();
}
export const partnerAdapter: EntityAdapter<Partner> =
  createEntityAdapter<Partner>({
    selectId: selectPartnerById,
  });
export const initialState: PartnerState = partnerAdapter.getInitialState({
  // additional entity state properties
  selectedPartnerId: null,
  loading: false,
  partnersType: [],
  error: null,
});
export const partnerReducer = createReducer(
  initialState,
  on(loadPartner, (state, action) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),
  on(loadPartnerSuccess, (state, action) => {
    return partnerAdapter.setAll(action.partners, {
      ...state,
      loading: false,
    });
  }),
  on(loadPartnerType, (state, action) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),
  on(loadPartnerTypeSuccess, (state, action) => {
    return {
      ...state,
      partnersType: action.partners_type,
      loading: false,
    };
  }),
  on(addPartner, (state, action: any) => {
    let partner = cloneDeep(action.data);
    partner.id = 'offline-'+action?.meta?.offlineId;
    return partnerAdapter.addOne(partner, {
      ...state,
      loading: true,
    });
  }),
  on(addPartnerSuccess, (state, action: any) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  }),
  on(updatePartner, (state, action: any) => {
    let updatedData = cloneDeep(action.data);
    updatedData.offlineOperationId = action?.meta?.offlineId;
    return partnerAdapter.updateOne(
      { id: action.data.id, changes: updatedData },
      {
        ...state,
        loading: true,
      }
    );
  }),

  on(updatePartnerSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  }),
  on(deletePartner, (state, action) => {
    return partnerAdapter.removeOne(action.id, {
      ...state,
      loading: true,
    });
  }),
  on(deletePartnerSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  })
);
