import { Component, OnInit, Input } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import routes from '../../../../config/routing.json';
import { Router } from '@angular/router';
import { AnimationUtils } from 'src/app/utils/animations';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { mapLangeTitleObjects } from 'src/app/utils/array-filtrer';

@Component({
  selector: 'app-agricultural-product-widget',
  templateUrl: './agricultural-product-widget.component.html',
  styleUrls: ['./agricultural-product-widget.component.scss'],
})
export class AgriculturalProductWidgetComponent implements OnInit {
  @Input() values: any = null;
  @Input() modalController: ModalController;
  @Input() is_preproduct_view;
  @Input() title;
  isDashboardView;
  loading;
  lang;
  base_url = 'assets';
  cropConfig;
  showOtherproductinput: boolean = false;
  constructor(
    private router: Router,
    private animationUtils: AnimationUtils,
    private progressDialog: LoadingController,
    public translateService: TranslateService
  ) {}

  async ngOnInit() {
    this.lang = this.translateService.currentLang;
    this.loading = await this.progressDialog
      .create({
        animated: true,
      })
      .then((value) => value);
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)

    this.values.forEach(element => {
      element.categories_name
      = this.translateService.instant("category."+element.id)
    });

    this.values = mapLangeTitleObjects(
      this.values,
      'categories_name',
      this.translateService
    );
  }

  submit() {
    const return_products = new Array();
    this.values = mapLangeTitleObjects(
      this.values,
      'name',
      this.translateService
    );
    this.values.forEach((element) => {
      if (Array.isArray(element.products)) {
        element.products =  mapLangeTitleObjects(
          element.products,
          'name',
          this.translateService
        );
        element.products.forEach((elem) => {
          if (elem.is_checked) return_products.push(elem);
        });
      }
    });
    this.modalController.dismiss({
      products: return_products,
    });
  }

  cancel() {
    this.modalController.dismiss({
      products: null,
    });
  }
  handelTitleChanged(ev){

  }

  handelSelection(product){
    product.is_checked = !product.is_checked
  }
  async itemClicked(item) {
    if (!item.is_expanded) {
      await this.animationUtils.expandAnimation(item);
      item.is_expanded = true;
    } else {
      await this.animationUtils.collapsAnimation(item);
      item.is_expanded = false;
    }
  }

  changed(item) {
    if (Array.isArray(item.products) && item.products.length > 1) {
      item.has_checked = true;
    }
  }

  selectProduct(product) {
    product.is_checked = !product.is_checked;
    if (product.is_commentable) {
      this.showOtherproductinput = !this.showOtherproductinput;
    }
  }

  getCategorieLength(categorie) {
    return this.translateService.currentLang === 'es'
      ? categorie.name.length
      : categorie['name_' + this.translateService.currentLang].length;
  }

  getCategorieName(categorie) {
    var label = '';

    if (categorie?.morphable_type && categorie?.id) {
      if (categorie?.morphable_type == 'App\\Models\\Product') {
        label = 'product.' + categorie?.morphable_id;
      } else if (
        categorie?.morphable_type == 'App\\Models\\MaterialCategorie'
      ) {
        label = 'material_category.' + categorie?.morphable_id;
      }
    } else {
      var selector = categorie?.id ? categorie?.id : categorie?.product_id;
      categorie.is_animal
        ? (label = 'material_category.' + categorie?.id
            // ? 'material_category.' + categorie?.id
            // : 'material_products.' + categorie?.id
            )
        : (label = 'product.' + selector);
    }
    return this.translateService.instant(label);
  }
}
