import { getAllListableCashflows } from './../../../utils/states-management/selector/cashflow-selector';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AccountingEntryCategorie } from 'src/app/models/accounting/accounting_entries_categories';
import { getTotalsAccountingCategoriesResult } from 'src/app/utils/states-management/selector/dashboard.selector';
import { ModalCashflowEntryListComponent } from '../../dashboard/modal-cashflow-entry-list/modal-cashflow-entry-list.component';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { PopoverController } from '@ionic/angular';
import { BookCashflowListFilter } from '../../dashboard/cashflow/book/book.component';
import {
  deleteCashflow,
  paginateCashflow,
} from 'src/app/utils/states-management/actions/cashflow.action';
import { Subscription, filter, lastValueFrom, take } from 'rxjs';
import { getCashflowPaginationResult } from 'src/app/utils/states-management/selector/cashflow-selector';
import { cloneDeep } from 'lodash';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-book',
  templateUrl: './book.page.html',
  styleUrls: ['./book.page.scss'],
})
export class BookPage implements OnInit {
  accountingCategories: Array<AccountingEntryCategorie>;
  @Input() bookCashflowListFilter: BookCashflowListFilter = {
    startDate: null,
    endDate: null,
    filterCategory: [],
    filterType: [],
    categorieIsFiltred: null,
    cashflowTypeIsFiltred: null,
    is_loading: true,
    isFiltred: false,
    page: 1,
    page_size: 100,
    isHarvestingFilter: false,
  };

  subscriptions: Array<Subscription> = [];

  constructor(
    private popOverController: PopoverController,
    private toastUtils: ToastUtils,
    private store: Store,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['idCategory']) {
        this.bookCashflowListFilter.filterCategory.push(params['idCategory']);
      }
    });
    this.bookCashflowListFilter.is_loading = true;
    let { data } = await lastValueFrom(
      this.store.select(getTotalsAccountingCategoriesResult).pipe(take(1))
    );
    this.accountingCategories = data;
    this.store.dispatch(
      paginateCashflow({
        startDate: this.bookCashflowListFilter.startDate,
        endDate: this.bookCashflowListFilter.endDate,
        page: this.bookCashflowListFilter.page,
        page_size: this.bookCashflowListFilter.page_size,
        accountings_entries: this.bookCashflowListFilter.filterCategory,
        natures: this.bookCashflowListFilter.filterType,
        is_harvesting_filter: this.bookCashflowListFilter.isHarvestingFilter,
      })
    );
    this.prepareList();
  }
  handelFilterChange(value) {
    this.bookCashflowListFilter.is_loading = true;

    this.store.dispatch(
      paginateCashflow({
        startDate: value.startDate,
        endDate: value.endDate,
        page: this.bookCashflowListFilter.page,
        page_size: this.bookCashflowListFilter.page_size,
        accountings_entries: value.filterCategory,
        natures: this.bookCashflowListFilter.filterType,
        is_harvesting_filter: this.bookCashflowListFilter.isHarvestingFilter,
      })
    );
  }
  async presentCashFlowInputModal() {
    // this.isAddBtnClicked = true;
    let componentProps = {
      modalController: this.popOverController,
    };
    const popover = await this.toastUtils.popOverBuilder(
      componentProps,
      ModalCashflowEntryListComponent,
      ['popover_class', 'diario']
    );
    await popover.onWillDismiss();
    // this.bookCashflowListFilter.page = 1;
    // this.isAddBtnClicked = false;
  }
  paginationResult;
  entries = [];
  isAddBtnClicked;
  productionsPerfarm;
  country;
  async prepareList() {
    this.bookCashflowListFilter.is_loading = true;
    this.subscriptions.push(
      this.store
        .select(getAllListableCashflows)
        .pipe(filter((value) => (value && Array.isArray(value) ? true : false)))
        .subscribe((value) => {
          this.paginationResult = cloneDeep(value);
          this.bookCashflowListFilter.is_loading = false;
          var filtredEntries = cloneDeep(this.paginationResult);
          this.showBottomProgress = false;
          this.entries = filtredEntries;
          // if (
          //   this.paginationResult &&
          //   this.paginationResult.meta &&
          //   this.paginationResult.meta.current_page == 1
          // ) {
          //   // this.entries = [];
          //   this.entries = filtredEntries;
          // } else {
          //   if (Array.isArray(this.entries))
          //     this.entries = this.entries.concat(filtredEntries);
          //   else {
          //     this.entries = cloneDeep(filtredEntries);
          //   }
          // }

          this.bookCashflowListFilter.is_loading = false;
        })
    );
  }
  showBottomProgress: boolean = false;

  async logScrollEnd(event) {
    let pos = event.target.scrollTop + event.target.offsetHeight;
    let max = event.target.scrollHeight;
    if (Math.round(pos) >= max) {
      if (
        this.bookCashflowListFilter.page + 1 <=
        this.paginationResult.meta.last_page
      ) {
        this.bookCashflowListFilter.page += 1;
        this.showBottomProgress = true;
        // this.bookCashflowListFilter.is_loading = true
        this.store.dispatch(
          paginateCashflow({
            startDate: this.bookCashflowListFilter.startDate,
            endDate: this.bookCashflowListFilter.endDate,
            page: this.bookCashflowListFilter.page,
            page_size: this.bookCashflowListFilter.page_size,
            accountings_entries: this.bookCashflowListFilter.filterCategory,
            natures: this.bookCashflowListFilter.filterType,
            is_harvesting_filter: false,
          })
        );
      }
    }
  }

  async deleteEntry(value) {
    this.bookCashflowListFilter.page = 1;
    if (value && value.id) {
      this.store.dispatch(deleteCashflow({ id: value.id }));
    }
  }
}
