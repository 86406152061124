import { element } from 'protractor';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Directive,
  ElementRef,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  AfterContentChecked,
} from '@angular/core';
import { FormatNumber } from 'src/app/utils/number_formatter';
import { MaxTwoDecimal } from 'src/app/modules/dashboard/calculette-component/calculette-component.component';
import { IonInput } from '@ionic/angular';

@Directive({
  selector: '[autofocus]',
})
export class AutofocusDirective {
  constructor(private host: ElementRef) {}

  ngAfterViewInit() {
    this.host.nativeElement.focus();
  }
}
@Component({
  selector: 'app-currency-input-component',
  templateUrl: './currency-input-componenet.component.html',
  styleUrls: ['./currency-input-componenet.component.scss'],
})
export class CurrencyInputComponenetComponent
  implements OnInit, AfterContentChecked,OnChanges
{
  @Input() currency = '';
  @Input() placeholder;
  @Input() label;
  @Input() valueInput;
  @Input() is_owner = true;
  @Input() isWhiteItem = false;
  @Output() amountEventChange: EventEmitter<Number> =
    new EventEmitter<Number>();
  @Input() is_required = false;
  is_fraction_needed = true;
  @Input() size = 10;
  @Input() isFarmView;
  @Input() cashflow: CashFlow = null;


  constructor(
    private ref: ChangeDetectorRef,
    private currencyPipe: FormatNumber
  ) {}

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }
ngOnChanges(changes: SimpleChanges): void {
  this.value = this.formatInputValue(this.valueInput);

}
  

  async ngOnInit() {
   
  }


  value;
  formatInputValue(value: string): string {
    return MaxTwoDecimal.formatInputValue(value);
  }
  @ViewChild('ionInputEl', { static: true }) ionInputEl!: IonInput;

  handleInput(event: Event): void {
    const target = event.target;
    if (target instanceof HTMLInputElement) {
      const filteredValue = target.value.replace(/[^0-9,.]+/g, '');
      
      let value = filteredValue.replace(/,+/g, ',').replace('.', ',');
      
      let parts = value.split(',');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        if(parts[0].length==0){
          parts[0]='0';
        }
        value = parts.join(',');
      }
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        value = parts.join(',');
      }
      
      this.value = value;
      this.amountEventChange.emit(
        isNaN(parseFloat(value.replace(',', '.'))) ? 0 : parseFloat(value.replace(',', '.')))
    }
  }
  
}
