import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { dev } from '../config/endpoints.json';
import { environment } from '../../environments/environment';
import { Good } from '../models/good.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GoodsAPI {
  main_endpoint = environment.appRoot + '/api/';
  constructor(private httpClient: HttpClient) {}

  getGoodsByUser(): Observable<any> {
    return this.httpClient.get(
      this.main_endpoint + dev.endpoint.good.get_users_goods
    );
  }

  updateGood(good: Good) {
    let formData = new FormData();
    if(good.unit){
      formData.append('qty', good?.qty.toString());
      formData.append('unit', good?.unit.toString());
    }else{
      formData.append('qty', '0');
      formData.append('unit', 'unit');
    }
 
    if (good.production_per_farm_id)
      formData.append(
        'production_per_farm_id',
        good.production_per_farm_id.toString()
      );
    if (good.material_product_id)
      formData.append(
        'material_product_id',
        good.material_product_id.toString()
      );
    if (good.product_id)
      formData.append('product_id', good.product_id.toString());

    formData.append('id', good.id.toString());

    return this.httpClient.post(
      this.main_endpoint + dev.endpoint.good.updat_good,
      formData
    );
  }

  insertGood(goods: Array<Good>) {
    let formData = new FormData();
    formData.append('products', JSON.stringify(goods));
    return this.httpClient.post(
      this.main_endpoint + dev.endpoint.good.insert_good,
      formData
    );
  }

  deleteGood(id) {
    return this.httpClient.delete(
      this.main_endpoint + dev.endpoint.good.delete_good + id
    );
  }

  getMaterialPropreties() {
    return this.httpClient.get(
      this.main_endpoint + dev.endpoint.good.get_material_propreties
    );
  }
}
