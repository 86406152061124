import { Injectable } from '@angular/core';
import { Farm } from '../models/farm/farm.model';
import Owner from '../models/owner.model';
import { Storage } from '@capacitor/storage';
import { dev } from '../config/offlines_keys.json';
import { Countrie } from '../models/countrie.model';
import { Store } from '@ngrx/store';
import { lastValueFrom, take } from 'rxjs';
import { getCountry } from '../utils/states-management/selector/countries.selector';
import { updatePreferredLanguage } from '../utils/states-management/actions/owner.action';
@Injectable({ providedIn: 'root' })
export class UserInfo {
  farm: Farm;
  owner: Owner;
  country: Countrie;
  selected_langage;

  constructor(private store: Store<{ owner: Owner }>) {}
  async getSelectedLangage() {
    return await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );
  }
  async setSelectedLangage(language) {
    this.selected_langage = language;
    await Storage.set({ key: dev.LANGAGE_KEY, value: language });
    localStorage.setItem('lang', language);
    this.store.dispatch(updatePreferredLanguage({ lang: language }));
  }
  async getOwner() {
    return await lastValueFrom(this.store.select('owner').pipe(take(1)));
  }
  async getCountry() {
    return await lastValueFrom(this.store.select(getCountry).pipe(take(1)));
  }
}
