import { Injectable } from '@angular/core';
import {
  COMPRA_ARBOLES_CASHFLOW_CATEGORIES,
  COMPRA_ARBOLES_COFFE_CASHFLOW_CATEGORIES_EXPENSES,
  PERDIDA_ARBOLES_CASHFLOW_CATEGORIES,
  PRODCUTION_ARBOLES_CAFE,
  PRODUCTION_ARBOLES,
  VENTA_ARBOLES_CASHFLOW_CATEGORIES,
  VENTA_ARBOLES_COFFE_CASHFLOW_CATEGORIES,
} from 'src/app/utils/consts';
import { ProductionsPerfarm } from '../farm/production_per_farm';
import { Good } from '../good.model';
import MoneyReporting from '../money_reporting.model';
import Operation from '../operation.model';
import { SecondCellCertif } from '../seconde_cell_certif';
import { CashflowCategorie, CashflowExpenses } from './cashflow_categories';
import { Certification } from '../Certification';
import { Farm } from '../farm/farm.model';
@Injectable()
export class CashFlow {
  id;
  amount: any;
  currency: string;
  date: string;
  inputName: string;
  quantity: number;
  payment_methods: string;
  donor_organization_name: string;
  comment: string;
  morphable_type: string;
  morphable_id: number;
  farm_id: number;
  farm: Farm;
  money_reporting_id: number;
  cash_flow_categorie_id: number;
  cash_flow_expense_id: number;
  unity: number;
  accounting_entries_categories: number;
  product_id: number;
  material_product_id: number;
  partners_id: number;
  is_first_sale: any;
  material_categorie_id;
  morphable: any;
  operation_id;
  cashflow_categories: CashflowCategorie;
  cashflow_expenses_categorie: CashflowExpenses;
  modelBeingUpdated: Good;
  initial_value = 0;
  production_per_farm: ProductionsPerfarm;
  production_per_farm_id: any;
  material_category: any;
  money_reporting: MoneyReporting;
  operation: Operation;
  displayable_unit: any;
  salary: any;
  new_operation: Operation;
  is_update: boolean;
  certifications: Array<Certification>;
  selectedCategory: number;
  availableGoodsList: any;
  availbleQTY: any;
  initial_unit: string;
  is_a_loss: number;
  is_operation: boolean;
  TotalPropriadades: number;
  goToProduction;
  is_income;
  usedQty;
  initial_amount;
  is_creation_cashflow;
  activity_id;
  activity;
  accounting_entry_categories: any;
  created_at
  constructor() {
    this.initial_amount = 0;
  }
}

export const isArbolesTransaction = (cashflow: CashFlow) => {
  return (
    [
      PERDIDA_ARBOLES_CASHFLOW_CATEGORIES,
      VENTA_ARBOLES_CASHFLOW_CATEGORIES,
      COMPRA_ARBOLES_CASHFLOW_CATEGORIES,
      VENTA_ARBOLES_COFFE_CASHFLOW_CATEGORIES,
      PRODUCTION_ARBOLES,
      PRODCUTION_ARBOLES_CAFE,
    ].includes(cashflow.cash_flow_categorie_id) ||
    cashflow.cash_flow_expense_id ===
      COMPRA_ARBOLES_COFFE_CASHFLOW_CATEGORIES_EXPENSES
  );
};
