import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  addOfflineOperation,
  updateConnectionStatus,
  resetOfflineOperation,
  markOperationExecuted,
  markOperationExecutedWithError,
  syncData,
} from './../actions/offline.action';
import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { OfflineOperation } from 'src/app/models/offlineOperation.model';
import { cloneDeep } from 'lodash';

export const OFFLINE_OPERATIONS = 'OFFLINE_OPERATIONS';

export interface ConnectionState extends EntityState<OfflineOperation> {
  isOnline: boolean;
  isSyncOnProgress: boolean;
  isSyncDone: boolean;
  nextOfflineIndex: number;
  failedSyncOperations: Array<any>;
}

export const offlineAdapter: EntityAdapter<OfflineOperation> =
  createEntityAdapter<OfflineOperation>({
    selectId: (operation) => operation.id,
  });

export const initialState: ConnectionState = offlineAdapter.getInitialState({
  isOnline: true,
  nextOfflineIndex: new Date().getTime(),
  failedSyncOperations: [],
  isSyncOnProgress: false,
  isSyncDone: true,
});

export const connectionFeatureSelector =
  createFeatureSelector<ConnectionState>('connection');

export const connectionStatus = createSelector(
  connectionFeatureSelector,
  (state) => state.isOnline
);
export const isAppSyncData = createSelector(connectionFeatureSelector, (state) =>
  Array.from(Object.values(state.entities)).length ? true : false
);
export const nextOfflineIndex = createSelector(
  connectionFeatureSelector,
  (state) => 'offline-' + state.nextOfflineIndex
);
export const offlineOperations = createSelector(
  connectionFeatureSelector,
  (state) => state.entities
);
export const connectionReducer = createReducer(
  initialState,
  on(addOfflineOperation, (state, action) => {
    return offlineAdapter.addOne(
      {
        ...action.operation,
        id: state.nextOfflineIndex,
        synced: false,
      },
      {
        ...state,
        nextOfflineIndex: new Date().getTime(),
      }
    );
  }),
  on(syncData, (state, action) => {
    return {
      ...state,
      isSyncOnProgress: Array.from(Object.values(state.entities)).length > 1,
      isSyncDone: Array.from(Object.values(state.entities)).length == 0,
    };
  }),
  on(markOperationExecuted, (state, action: any) => {
    return offlineAdapter.removeOne(action.id, {
      ...state,
      isSyncOnProgress: Array.from(Object.values(state.entities)).length > 1,
      isSyncDone: Array.from(Object.values(state.entities)).length == 0,
    });
  }),
  on(markOperationExecutedWithError, (state, action) => {
    let failedSyncOperations = cloneDeep(state.failedSyncOperations);
    failedSyncOperations.push(action.error);
    return offlineAdapter.removeOne(action.id, {
      ...state,
      failedSyncOperations: failedSyncOperations,
      isSyncOnProgress: Array.from(Object.values(state.entities)).length > 1,
      isSyncDone: Array.from(Object.values(state.entities)).length == 1,
    });
  }),
  on(resetOfflineOperation, (state) => {
    return offlineAdapter.removeAll({
      ...state,
      state: [],
    });
  }),

  on(updateConnectionStatus, (state, action) => {
    return {
      ...state,
      isOnline: action.isOnline,
      isSyncDone: Array.from(Object.values(state.entities)).length == 0,
    };
  })
);
export const { selectAll } = offlineAdapter.getSelectors();
