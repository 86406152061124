import { ToastUtils } from './../../../utils/toast_builder';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { Component, Input, OnInit } from '@angular/core';
import { ProgramComponent } from '../program/program.component';
import { ProgrammesFilter } from '../programmes-filter/programmes-filter.component';

@Component({
  selector: 'app-programmes-list',
  templateUrl: './programmes-list.component.html',
  styleUrls: ['./programmes-list.component.scss'],
})
export class ProgrammesListComponent implements OnInit {
  @Input() nav;
  programmes: any;
  filtredProgrammes
  isFiltred = false

  

  constructor(public appConfig:RoutingConfig,
    private modalController: ModalController,
    public translateService: TranslateService,
    public toastUtils: ToastUtils
    ) { }

  async ngOnInit() {
    this.programmes = await this.appConfig.getListProgrammesConfig()
    this.filtredProgrammes = this.programmes

  }
  async filter(){
    this.filtredProgrammes = this.programmes

    let componentProps = {
      modalController: this.modalController,
      programmes:this.programmes
    };
    let modal = await this.toastUtils.modalBuilder(
      ProgrammesFilter,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper'],
      true,
      '2'
    );
    const { data } = await modal.onWillDismiss();
      if(data){
        if(data.selected_programme && !data.number_vote){
          this.filtredProgrammes = this.filtredProgrammes.filter(p => p.id === data?.selected_programme.id);
        }else if(data.number_vote && !data.selected_programme ){
          this.filtredProgrammes = this.filtredProgrammes.filter(p => p?.vote?.stars_number === data?.number_vote);
        }else if(data.number_vote && data.selected_programme){
          this.filtredProgrammes = this.filtredProgrammes.filter(p => p?.vote?.stars_number === data?.number_vote && p.id === data?.selected_programme.id);
        }else{
        this.filtredProgrammes = this.programmes
        }
        this.isFiltred = true;
      }else{
        this.filtredProgrammes = this.programmes
        this.isFiltred = false

      }
  }
  routeToProgram(program) {
    this.nav.setRoot(ProgramComponent, {
      program: program,
    });
  }

}
