import { Store } from '@ngrx/store';
import { orderAlphabaticallyByAttribute } from 'src/app/utils/array-filtrer';
import { ToastUtils } from 'src/app/utils/toast_builder';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ModalController, IonNav } from '@ionic/angular';
import { DateFilterModalComponent } from 'src/app/modules/dashboard/widgets/date-filter-modal/date-filter-modal.component';
import { CashflowSignleEntrySummaryComponent } from '../cashflow-signle-entry-summary/cashflow-signle-entry-summary.component';
import { AccountingCategoriesAPI } from 'src/app/apis/accounting_categories.service';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../../config/offlines_keys.json';
import Owner from 'src/app/models/owner.model';
import { getCurrentMonthAndYear } from 'src/app/utils/pipes-utils/date_formatter';
import { loadTotalAccountingCategories } from 'src/app/utils/states-management/actions/dashboard.action';
import { getTotalsAccountingCategoriesResult } from 'src/app/utils/states-management/selector/dashboard.selector';
import { lastValueFrom, take } from 'rxjs';
import { LineChartExpensesChartComponent } from '../../widgets/line-chart-expenses-chart/line-chart-expenses-chart.component';
@Component({
  selector: 'app-cashflow-summary-diary',
  templateUrl: './cashflow-summary-diary.component.html',
  styleUrls: ['./cashflow-summary-diary.component.scss'],
})
export class CashflowSummaryDiaryComponent implements OnInit {
  @Input() nav: IonNav;
  @ViewChild('LineChartExpensesChartComponent')
  lineChartExpensesChartComponent: LineChartExpensesChartComponent;

  accountingCategories = new Array();
  profile_info;
  monthYear = getCurrentMonthAndYear(new Date());
  
  rootParams = {};
  constructor(
    private modalController: ModalController,
    private toastUtils: ToastUtils,
    private accountingCategoriesAPI: AccountingCategoriesAPI,
    public translateService: TranslateService,
    private store: Store<{ owner: Owner }>,
    private changeDetector: ChangeDetectorRef
  ) {
    this.rootParams = {
      nav: this.nav,
      // accountingDetails: accounting,
      rootStack: CashflowSummaryDiaryComponent,
    };
  }
  params = {
    start_date: new Date(this.monthYear.year, 0, 1, + 1).toISOString(),
    end_date: new Date(
      this.monthYear.year,
      this.monthYear.month,
      0 + 1
    ).toISOString(),
    cashflowExpenses: [],
    sort_by: null,
    monthly_yearly: null,
  };
  async ngOnInit() {
  
    this.store.select('owner').subscribe(async (owner: Owner) => {
      if (owner && typeof owner.profile_info == 'string')
        this.profile_info = JSON.parse(owner.profile_info);
    });

    this.getAccountingCategoriesList(
      this.params.start_date,
      this.params.end_date
    );
  }
  details(accounting) {
    let rootParams = {
      nav: this.nav,
      accountingDetails: accounting,
      rootStack: CashflowSummaryDiaryComponent,
      startingDate:this.params.start_date,
      endingDate: this.params.end_date
    };

    this.nav.setRoot(LineChartExpensesChartComponent, rootParams);
  }
  handelDateChange(event){

    this.params.start_date=event.startDate
    this.params.end_date = event.endDate
  }
  async handelFilterChange(value) {
    await this.getAccountingCategoriesList(value.startDate, value.endDate);
    // this.lineChartExpensesChartComponent.fetchData();
  }
  // handelFilterClicked(ev){
  //   this.filterAccordion = {
  //     general: ev,
  //     selection: ev,
  //     category: ev,
  //   };
  // }
  handelFilterClicked(ev) {
    this.lineChartExpensesChartComponent.handelFilterClicked(ev);
  }
  getAccountingCategoriesList(startDate, endDate) {
    // let data = { startDate: startDate, endDate: endDate };
    // this.store.dispatch(
    //   loadTotalAccountingCategories({ data :data })
    // );
    this.store
      .select(getTotalsAccountingCategoriesResult)
      .subscribe(async ({data}: any) => {
        var totalsAccountingCategories = [];
        data.forEach((element) => {
          if (element?.morphable_type != null && element?.id != null) {
            let cropExist = this.profile_info.cropConfig.find(
              (item) =>
                item.id == element.morphable_id &&
                element.morphable_type.includes(item.morph)
            );
            if (cropExist) {
              totalsAccountingCategories.push(element);
            }
          } else {
            totalsAccountingCategories.push(element);
          }
        });
        this.accountingCategories = totalsAccountingCategories;
        this.orderAccountings();
      });
  }

  orderAccountings() {
    this.accountingCategories.sort((a, b) => {
      return this.translateService
        .instant('accounting_entries.' + a.id)
        .normalize('NFD')
        .toLowerCase() ===
        this.translateService
          .instant('accounting_entries.' + b.id)
          .normalize('NFD')
          .toLowerCase()
        ? 0
        : this.translateService
            .instant('accounting_entries.' + a.id)
            .normalize('NFD')
            .toLowerCase() <
          this.translateService
            .instant('accounting_entries.' + b.id)
            .normalize('NFD')
            .toLowerCase()
        ? -1
        : 1;
    });
  }
  async filter() {
    await this.toastUtils.modalBuilder(
      DateFilterModalComponent,
      {
        modalController: this.modalController,
      },
      ['my-custom-modal-css', 'modal-wrapper']
    );
  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  async categorySelected(item, name, name_en, iconPath) {
    let { data } = await lastValueFrom(
      this.store.select(getTotalsAccountingCategoriesResult).pipe(take(1))
    );
    const summary = orderAlphabaticallyByAttribute(
      data.find((element) => element.id === item),
      'title',
      this.translateService.currentLang
    );
    this.nav.setRoot(CashflowSignleEntrySummaryComponent, {
      id: item,
      name: name,
      name_en: name_en,
      summary: summary,
      iconPath: iconPath,
      nav: this.nav,
    });
  }
}
