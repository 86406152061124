import { CashFlow } from '../../../models/cash-flow/cashFlow';
import { Component, Input, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { MoneyReportingsAPI } from '../../../apis/money_reportings.service';
import { dev } from '../../../config/offlines_keys.json';
import { LoadingController, ModalController } from '@ionic/angular';
import { ToastUtils } from '../../../utils/toast_builder';
import { Router } from '@angular/router';
import routes from '../../../config/routing.json';
import { CashflowAPI } from 'src/app/apis/cashflow.service';
import {
  GAB_CALCULATION_EXPENSE,
  GAB_CALCULATION_INCOME,
  GAP_CALCULATION_ACCOUNTING_ENTRIE_CATEGORIE_ID,
  INITIAL_SOLD_CASHFLOW_CATEGORIE_ID,
  INTITIAL_SOLD_ACCOUNTING_ENTRIE_CATEGORIE_ID,
} from 'src/app/utils/consts';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import MoneyReporting from '../../../models/money_reporting.model';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';

@Component({
  selector: 'app-modal-bank-account',
  templateUrl: './modal-bank-account.component.html',
  styleUrls: ['./modal-bank-account.component.scss'],
  providers: [MoneyReportingsAPI],
})
export class ModalBankAccountComponent implements OnInit {
  title;

  confirm;
  cancel;
  owner_id: number;
  country_device;

  country;
  isDashboardView;
  required_input;
  disableConfirm = false;
  bank_account = new MoneyReporting();
  farm;
  fecha;
  @Input() bank_type: string;
  @Input() id: number;
  @Input() formConfig;

  loadingModal;
  constructor(
    public modalController: ModalController,
    private router: Router,
    private cashflowAPI: CashflowAPI,
    private moneyReportingAPI: MoneyReportingsAPI,
    private loadingController: LoadingController,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private store:Store
  ) {}

  async ngOnInit() {
    this.loadingModal = await this.loadingController.create();
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)

    this.fecha = new Date().toISOString();
    await this.getowner_id();
    await this.get_one_countrie();
    this.bank_account.owner_id = this.owner_id;

    this.farm= await lastValueFrom(this.store.select(getFarm).pipe(take(1)))
    if (this.id != undefined) {
      this.moneyReportingAPI
        .getOneMoneyReporting(this.id)
        .subscribe((value) => {
          this.bank_account.amount = value.amount;
          this.bank_account.currency = value.currency;
          this.bank_account.money_reporting_name = value.money_reporting_name;
          this.bank_account.type = value.type;
          this.bank_account.cashflow = value.cashflow;
          this.bank_account.initial_amount = value.amount;
        });
    }
  }

  async getowner_id() {
    await Storage.get({ key: dev.USER_KEY }).then((value) => {
      this.owner_id = JSON.parse(value.value)?.id;
    });
  }

  async get_one_countrie() {
    await this.langageChanger('');
    let country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    this.country_device = country?.currency;
    this.country = country?.id;
  }

  async langageChanger(lang) {
    this.title = await this.translateService
      .get('Money_reporting_form_title')
      .toPromise();
    if (this.bank_type === 'cash_money') {
      this.title = await this.translateService
        .get('Money_reporting_type0_button_add')
        .toPromise();
    } else if (this.bank_type === 'current_account') {
      this.title = await this.translateService
        .get('Money_reporting_form1_title')
        .toPromise();
    } else if (this.bank_type === 'investment_account') {
      this.title = await this.translateService
        .get('Money_reporting_form2_title')
        .toPromise();
    } else {
      this.title = await this.translateService
        .get('Money_reporting_form3_title')
        .toPromise();
    }
  }

  confirm_method() {
    if (this.id == undefined) {
      this.submitForm();
    } else {
      this.updateForm();
    }
  }
  handelChangeCurrecy(value) {
    this.bank_account.currency = value;
  }
  async submitForm() {
    this.loadingModal.present();
    if (
      !this.bank_account.money_reporting_name &&
      this.bank_type != 'cash_money'
    ) {
     await this.toastUtils
        .toastBuilder(
          await this.translateService
            .instant('errors.money_reporting_req'),          '',
          '',
          ''
        )
    } else if (!this.bank_account.amount) {
     await this.toastUtils
        .toastBuilder(
          await this.translateService
            .get('errors.money_reporting_req'),
          '',
          '',
          ''
        )
    } else if (!this.bank_account.currency) {
      this.toastUtils
        .toastBuilder(
          await this.translateService
            .instant('errors.money_reporting_req'),
          '',
          '',
          ''
        );    } else {
      if (this.bank_type === 'cash_money') {
        this.bank_account.money_reporting_name = null;
      }
      this.bank_account.type = this.bank_type;
      this.disableConfirm = true;
      this.moneyReportingAPI
        .storeMoneyReporting(this.bank_account)
        .pipe(
          map((moneyReporting: MoneyReporting) => moneyReporting),
          switchMap((moneyReporting) => {
            if (!this.isDashboardView)
              return this.saveInicialSold(moneyReporting);
            else {
              this.bank_account.id = moneyReporting.id;
              this.bank_account.initial_amount = 0;
              return this.saveCalibrationCashflow();
            }
          }),
          map((result) => result)
        )
        .subscribe((value) => {
          this.loadingModal.dismiss();
          this.modalController.dismiss({
            dismissed: true,
            account: value,
          });
        });
    }
    this.loadingModal.dismiss();
  }

  async updateForm() {
    if (
      !this.bank_account.money_reporting_name &&
      this.bank_type != 'cash_money'
    ) {
      await this.toastUtils
        .toastBuilder(
          await this.translateService
            .instant('errors.money_reporting_req'),
          '',
          '',
          'danger'
        );    } else if (!this.bank_account.amount) {
      await this.toastUtils
        .toastBuilder(
          await this.translateService
            .instant('errors.money_reporting_req'),
          '',
          '',
          'danger'
        );
          } else if (!this.bank_account.currency) {
     await this.toastUtils
        .toastBuilder(
          await this.translateService
            .instant('errors.money_reporting_req'),
          '',
          '',
          'danger'
        );
    } else {
      this.bank_account.id = this.id;
      this.moneyReportingAPI
        .updateMoneyReporting(this.bank_account)
        .pipe(
          take(1),
          // map((serverMoneyReporting) => {
          //   const initial_amount = this.bank_account.initial_amount;
          //   this.bank_account = serverMoneyReporting;
          //   this.bank_account.initial_amount = initial_amount;
          //   return serverMoneyReporting;
          // }),
          switchMap((moneyReporting: MoneyReporting) => {
            if (this.isDashboardView) {
              return this.saveCalibrationCashflow();
            } else return this.updateInicialSold(this.bank_account);
          }),
          map((value) => {
            return value;
          })
        )
        .subscribe(() => {
          this.loadingModal.dismiss();
          this.modalController.dismiss({
            dismissed: true,
            account: this.bank_account,
          });
        });
    }
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
      canceled: true,
    });
  }

  setAmount(value) {
    this.bank_account.amount = parseFloat(value);
  }

  updateInicialSold(value): Observable<any> {
    if (value.cashflow.length > 0) {
      let cashflow = new CashFlow();
      cashflow = value.cashflow[0];
      cashflow.amount = value.amount;
      return this.cashflowAPI.updateCashFlow(cashflow);
    } else return this.saveInicialSold(value);
  }

  saveInicialSold(value) {
    if (!this.isDashboardView) {
      const cashflow = new CashFlow();
      cashflow.is_income = 1;
      cashflow.farm_id = this.farm.id;
      cashflow.amount = this.bank_account?.amount;
      cashflow.currency = this.bank_account?.currency;
      cashflow.cash_flow_categorie_id = INITIAL_SOLD_CASHFLOW_CATEGORIE_ID;
      cashflow.accounting_entries_categories =
        INTITIAL_SOLD_ACCOUNTING_ENTRIE_CATEGORIE_ID;
      cashflow.date = new Date().toISOString();
      if (this.bank_account.type === 'cash_money') {
        cashflow.comment = 'Efectivo';
      } else if (this.bank_account.type === 'current_account') {
        cashflow.comment = 'Cuenta corriente';
      } else if (this.bank_account.type === 'investment_account') {
        cashflow.comment = 'Cuenta de inversión';
      } else if (this.bank_account.type === 'savings_account') {
        cashflow.comment = 'Cuenta de ahorro';
      }
      cashflow.money_reporting_id = value.id;
      return this.cashflowAPI.saveCashFlow(cashflow);
    }
  }

  saveCalibrationCashflow() {
    if (!this.bank_account.initial_amount) this.bank_account.initial_amount = 0;
    if (this.bank_account.amount - this.bank_account.initial_amount != 0) {
      const cashflow = new CashFlow();
      cashflow.amount = Math.abs(
        this.bank_account.amount - this.bank_account.initial_amount
      );

      cashflow.payment_methods =
        this.bank_account.type == 'cash_money' ? 'cash' : 'bank_transfer';
      cashflow.is_income =
        this.bank_account.amount - this.bank_account.initial_amount > 0 ? 1 : 0;
      cashflow.farm_id = this.farm.id;
      cashflow.money_reporting_id = this.bank_account.id;
      cashflow.currency = this.bank_account.currency;
      cashflow.accounting_entries_categories =
        GAP_CALCULATION_ACCOUNTING_ENTRIE_CATEGORIE_ID;
      cashflow.cash_flow_categorie_id = cashflow.is_income
        ? GAB_CALCULATION_INCOME
        : GAB_CALCULATION_EXPENSE;
      cashflow.date = new Date().toISOString();
      return this.cashflowAPI.saveCashFlow(cashflow);
    } else return of(this.bank_account);
  }
}
