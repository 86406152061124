import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-introductoryvideo',
  templateUrl: './introductoryvideo.component.html',
  styleUrls: ['./introductoryvideo.component.scss'],
})
export class IntroductoryvideoComponent implements OnInit {
  constructor(public popover: PopoverController) {}
  @Input('optionSrc') optionSrc=null
  ngOnInit() {
  }
  dismissPopover() {
    this.popover.dismiss();
  }
}
