import { createAction, props } from '@ngrx/store';
import { Unit } from 'src/app/models/Unit';

export const LOAD_UNITS = 'LOAD_UNITS';
export const LOAD_UNITS_SUCCESS = 'LOAD_UNITS_SUCCESS';

export const loadUnit = createAction(LOAD_UNITS);
export const loadUnitsSuccess = createAction(
  LOAD_UNITS_SUCCESS,
  props<{ units: Array<Unit> }>()
);
