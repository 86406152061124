import { ToastUtils } from 'src/app/utils/toast_builder';
import { PopoverComponent } from '../../../widgets/popover/popover.component';
import { Component, OnInit } from '@angular/core';
import { dev } from '../../../config/offlines_keys.json';
import { Storage } from '@capacitor/storage';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-community-permission-component',
  templateUrl: './community-permission-component.component.html',
  styleUrls: ['./community-permission-component.component.scss'],
})
export class CommunityPermissionComponentComponent implements OnInit {
  list_permission = [];
  list_permission_storage;
  constructor(
    public translateService: TranslateService,
    private toastUtils: ToastUtils
  ) {}

  async ngOnInit() {
    await this.langageChanger();
    Storage.get({ key: dev.COMUNIDAD }).then((value) => {
      if (value.value) {
        this.list_permission = JSON.parse(value.value);
      } else {
        Storage.set({
          key: dev.COMUNIDAD,
          value: JSON.stringify(this.list_permission_storage),
        });
        this.list_permission = this.list_permission_storage;
      }
    });
  }

  handelChecked(item) {
    if (item.id == 1) {
      item.isChecked = !item.isChecked;
      this.showPopOver('message');
    } else {
      Storage.get({ key: dev.COMUNIDAD }).then((value) => {
        var choice = [];

        choice = JSON.parse(value.value);
        choice.forEach((value) => {
          if (item.id == value.id) {
            value.isChecked = item.isChecked;
          }
        });
        Storage.set({ key: dev.COMUNIDAD, value: JSON.stringify(choice) });
      });
    }
  }

  async showPopOver(message) {
  
    const popover = await this.toastUtils.popOverBuilder(
      { key1: message },
      PopoverComponent,
      'popover-custom'
    )
  }

  async langageChanger() {
    this.list_permission_storage = [
      {
        id: 1,
        label: await this.translateService
          .get('your_cell_phone_number')
          .toPromise(),
          // .then((val) => val),
        disabled: true,
        isChecked: true,
      },
      {
        id: 2,
        label: await this.translateService
          .get('your_photo')
          .toPromise(),
        disabled: false,
        isChecked: false,
      },
      {
        id: 3,
        label: await this.translateService
          .get('your_name')
          .toPromise(),

        disabled: false,
        isChecked: false,
      },
      {
        id: 4,
        label: await this.translateService
          .get('your_last_name')
          .toPromise(),
        disabled: false,
        isChecked: false,
      },
    ];
  }
}
