export default class MoneyReporting {
  id;
  amount;
  currency;
  type;
  money_reporting_name;
  owner_id;
  pension_plan_objective = 0;
  cashflow;
  initial_amount = 0;
  constructor() {}
}
