import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ProgramasAPI } from 'src/app/apis/programas.service';
import {  LOAD_PROGRAMS, LOAD_PROGRAMS_SUCCESSFULY, loadProgramSuccessfuly } from '../actions/programas.action';
import { Programas } from 'src/app/models/programas';
import { LOAD_LIST_COUNTRIES, loadCountriesSuccessfully } from '../actions/countries.action';
import { CountriesService } from 'src/app/apis/countries.service';
import { Countrie } from 'src/app/models/countrie.model';


@Injectable()
export class CountriesEffect {

  loadCountries$ = createEffect(() =>
    this.actions$.pipe(
        ofType(LOAD_LIST_COUNTRIES),
        switchMap(() => {   
            
          return this.CountriesService.getCountries().pipe(
            map((values : Array<Countrie>) => {    
                                      
              return loadCountriesSuccessfully({ list_countries:values });
            })
          );
        })
      )
    );

    constructor(private actions$: Actions, private CountriesService: CountriesService) { }
}