import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CountriesState, countriesAdapter } from '../reducers/countries.reducer';
import { Countrie } from 'src/app/models/countrie.model';

export const countriesSelector =
  createFeatureSelector<CountriesState>('countries');

export const getAllCounties = createSelector(
  countriesSelector,
  (state: CountriesState):Array<Countrie>=>
    state.list_countries
);
export const getCountry = createSelector(
  countriesSelector,
  (state: CountriesState):Countrie=>
    state.user_country
);

export const getAppConfig = createSelector(
  countriesSelector,
  (state: CountriesState):Countrie=>{
  if(state?.user_country)
    return JSON.parse(state?.user_country?.config)
    else
    return null;
});
export const getProductGenetics = createSelector(
  countriesSelector,
  (state: CountriesState):Countrie=>{
    if(state?.user_country?.genetics)
    return state?.user_country?.genetics
    else
    return null;
});