import { orderAlphabaticallyUnit } from '../../../../utils/array-filtrer';
import { Component, Input, OnInit } from '@angular/core';
import { dev } from '../../../../config/offlines_keys.json';
import { Storage } from '@capacitor/storage';
import { ModalController } from '@ionic/angular';
import units from '../../../../config/unit.json';
import { UserInfo } from 'src/app/apis/users-info.service';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';
import { Good } from 'src/app/models/good.model';
import { ProductionAPI } from 'src/app/apis/FarmAPI/productions.service';
import { PRODUCT_MORPHABLE } from 'src/app/utils/consts';
import { TranslateService } from '@ngx-translate/core';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
@Component({
  selector: 'app-coffee-type-selector',
  templateUrl: './coffee-type-selector.component.html',
  styleUrls: ['./coffee-type-selector.component.scss'],
})
export class CoffeeTypeSelectorComponent implements OnInit {
  displayableCategories: any;
  token;
  unit;
  lang;
  @Input() preSelectedProduct: Array<ProductionsPerfarm>;
  constructor(
    private modalController: ModalController,
    private productionAPI: ProductionAPI,
    private userInfo: UserInfo,
    public translateService: TranslateService,
    private store:Store
  ) {}

  async ngOnInit() {
    const country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    this.lang = this.translateService.currentLang;
    country?.id === 1
      ? (this.unit = units.costa_rica)
      : (this.unit = units.colombia);
    this.unit = orderAlphabaticallyUnit(
      units.colombia.filter((element) => element.type === 'p'),
      this.lang
    );
    await this.pushCafeProductToList(country);
    var selectedIds = this.preSelectedProduct?.map(function (a) {
      return a.morphable_id;
    });
    this.displayableCategories.products =
      this.displayableCategories.products.filter((value) => {
        return !selectedIds?.includes(value.product_id);
      });
  }

  async pushCafeProductToList(country) {
    const coffe_cherry_label = await this.translateService
      .get('label.coffe_cherry')
      .toPromise();
    const coffe_wet_label = await this.translateService
      .get('label.coffe_wet')
      .toPromise();
    const coffe_dry_label = await this.translateService
      .get('label.coffe_dry')
      .toPromise();
    const coffe_label = await this.translateService
      .get('label.cafe')
      .toPromise();
    if (country?.id === 1)
      this.displayableCategories = {
        id: 1,
        categories_name: coffe_cherry_label,
        products: [
          {
            product_id: 16,
            name: coffe_cherry_label,
            is_animal: false,
            is_checked: true,
            is_cafe: true,
            percent_in_lot: 0,
            used_surface: 0,
            trees: {
              has_trees: true,
              trees_number: 0,
            },
          },
        ],
        is_animal: false,
        is_checked: true,
        percent_in_lot: 0,
        used_surface: 0,
        is_cafe: true,
        is_expanded: false,
      };
    else {
      this.displayableCategories = {
        id: 1,
        categories_name: coffe_label,

        products: [
          {
            product_id: 16,
            name: coffe_cherry_label,
            is_animal: false,
            is_checked: true,
            is_cafe: true,
            percent_in_lot: 0,
            used_surface: 0,
            trees: {
              has_trees: true,
              trees_number: 0,
            },
          },
          {
            product_id: 17,
            name: coffe_wet_label,
            is_animal: false,
            is_checked: false,
            is_cafe: true,
            percent_in_lot: 0,
            used_surface: 0,

          },
          {
            product_id: 18,
            name: coffe_dry_label,
            is_animal: false,
            is_cafe: true,
            is_checked: false,
            percent_in_lot: 0,
            used_surface: 0,

          },
        ],
        is_cafe: true,
        is_expanded: false,
      };
    }
  }

  async submit() {
    const owner = await this.userInfo.getOwner();
    const farm = await lastValueFrom(this.store.select(getFarm).pipe(take(1)))
    var filtreedProduct = this.displayableCategories.products.map((element) => {
      if (!element.qty) element.qty = 0;
      return element;
    });
    if (Array.isArray(filtreedProduct) && filtreedProduct.length > 0) {
      filtreedProduct = filtreedProduct.map((element) => {
        let productions = new ProductionsPerfarm();
        productions.goods = new Array<Good>();
        productions.goods.push(new Good());
        productions.morphable_type = PRODUCT_MORPHABLE;
        productions.morphable_id = element.product_id;
        if (element.trees && element.trees.has_trees) {
          productions.trees_numbers = element.trees.trees_number;
        } else {
          productions.trees_numbers = -1;
        }
        if (element.qty) {
          productions.goods[0].qty = element.qty;
        }
        if (element.unit) {
          productions.goods[0].unit = element.unit;
        }
        if (element.comment) productions.comment = element.comment;
        else productions.comment = null;
        productions.goods[0].owner_id = owner.id;
        productions.is_crop = element.is_cafe ? 1 : 0;
        productions.is_animal = 0;
        productions.farm_id = farm.id;
        return productions;
      });
    }

    this.productionAPI
      .insertMultipleProduction(filtreedProduct)
      .then(async (value: ProductionsPerfarm[]) => {
        await Storage.set({
          key: dev.PRESELECTED_PRODUCT_KEY,
          value: JSON.stringify(value),
        });
        this.cancel();
      });
  }

  cancel() {
    this.modalController.dismiss();
  }
}
