import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, take, lastValueFrom } from 'rxjs';
import Owner from './models/owner.model';
import { cloneDeep } from 'lodash';
import { RoutingConfig } from './utils/routing-config';

@Injectable({
  providedIn: 'root',
})
export class RoutingConfigGuard implements CanActivate {
  owner;
  constructor(
    private store: Store<{ owner: Owner }>,
    private routingConfig: RoutingConfig
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    let routingConfig =await this.routingConfig.getConfigObject();
    // return routingConfig?.landing.routingConfig.some((val)=>val.route.includes(state.url));
    return true
  }

}
