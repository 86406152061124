import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-footer-dashboard',
  templateUrl: './footer-dashboard.component.html',
  styleUrls: ['./footer-dashboard.component.scss'],
})
export class FooterDashboardComponent implements OnInit {
  @Input() label_1;
  @Input() label_2;
  @Input() isLogoutFooter = false;
  @Input() show_next = true;
  @Output() back_pressedChange: EventEmitter<any> = new EventEmitter();
  @Output() next_pressedChange: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  back_pressed() {
    this.back_pressedChange.emit();
  }
  next_pressed() {
    this.next_pressedChange.emit();
  }
}
