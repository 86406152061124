import { createAction, props } from '@ngrx/store';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import { PaginationResult } from 'src/app/models/pagination-result.model';

const GET_CASHFLOW_BY_ID = 'GET_CASHFLOW_BY_ID';
const GET_CASHFLOW_BY_ID_SUCCESS = 'GET_CASHFLOW_BY_ID_SUCCESS';
const CLEAR_CASHFLOW_SELECTION = 'CLEAR_CASHFLOW_SELECTION';
const PAGINATE_CASHFLOW_SUCCESS = 'PAGINATE_CASHFLOW_SUCCESS';
export const PAGINATE_CASHFLOW = 'PAGINATE_CASHFLOW';
const DELETE_CASHFLOW = 'DELETE_CASHFLOW';
const DELETE_CASHFLOW_SUCCESS = 'DELETE_CASHFLOW_SUCCESS';
const SAVE_CASHFLOW = 'SAVE_CASHFLOW';
const SAVE_CASHFLOW_SUCCESS = 'SAVE_CASHFLOW_SUCCESS';
const UPDATE_CASHFLOW_SUCCESS = 'UPDATE_CASHFLOW_SUCCESS';
const UPDATE_CASHFLOW = 'UPDATE_CASHFLOW';

export const updateCashflow = createAction(
  UPDATE_CASHFLOW,
  props<{ data: CashFlow }>()
);
export const updateCashflowSuccess = createAction(
  UPDATE_CASHFLOW_SUCCESS,
  props<{ data: CashFlow }>()
);


export const getCashflowById = createAction(
  GET_CASHFLOW_BY_ID,
  props<{ id: number }>()
);
export const getCashflowByIdSuccess = createAction(
  GET_CASHFLOW_BY_ID_SUCCESS,
  props<{ data: CashFlow }>()
);
export const paginateCashflow = createAction(
  PAGINATE_CASHFLOW,
  props<{
    startDate: string | null ;
    endDate: string | null;
    page: number;
    page_size: number;
    accountings_entries: Array<number>;
    natures: Array<number>;
    is_harvesting_filter:boolean ;
  }>()
);

export const paginateCashflowSuccess = createAction(
  PAGINATE_CASHFLOW_SUCCESS,
  props<{ paginationResult: PaginationResult<CashFlow> }>()
);
export const deleteCashflow = createAction(
  DELETE_CASHFLOW,
  props<{ id: number }>()
);

export const deleteCashflowSuccess = createAction(
  DELETE_CASHFLOW_SUCCESS,
  props<{ id: number }>()
);

export const saveCashflow = createAction(
  SAVE_CASHFLOW,
  props<{ data: CashFlow }>()
);

export const saveCashflowSuccess = createAction(
  SAVE_CASHFLOW_SUCCESS,
  props<{ data: CashFlow; offlineId: string; }>()
);

export const clearCashflowSelection = createAction(CLEAR_CASHFLOW_SELECTION);
