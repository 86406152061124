import { Lot } from '../lots.model';
import { Productions } from './productions.model';

export class Farm {
  name: string;
  ambassador_id: string;
  cluster_number: string;
  cluster_id: string;
  nature: string;
  locality: string;
  postal_code: any;
  street: string;
  department: string;
  municipality: string;
  province: string;
  altitude: string;
  id?: any;
  employees;
  longitude;
  latitude;
  number_permanent_employee;
  number_temporal_employee;
  updated_at: string;
  created_at: string;
  productions: Array<Productions>;
  lots: Array<Lot>;
  constructor(values = null) {
    this.instantiateFromObject(values);
  }

  instantiateFromObject(values = null) {
    this.name = values?.name != 'undefined' ? values?.name : '';
    this.ambassador_id =
      values?.ambassador_id != 'undefined' ? values?.ambassador_id : '';
    this.cluster_number =
      values?.cluster_number != 'undefined' ? values?.cluster_number : '';
    this.cluster_id =
      values?.cluster_id != 'undefined' ? values?.cluster_id : '';
    this.nature = values?.nature != 'undefined' ? values?.nature : '';
    this.locality = values?.locality != 'undefined' ? values?.locality : '';
    this.postal_code =
      values?.postal_code != 'undefined' ? values?.postal_code : '';
    this.street = values?.street != 'undefined' ? values?.street : '';
    this.department =
      values?.department != 'undefined' ? values?.department : '';
    this.municipality =
      values?.municipality != 'undefined' ? values?.municipality : '';
    this.province = values?.province != 'undefined' ? values?.province : '';
    this.altitude = values?.altitude != 'undefined' ? values?.altitude : '';
    this.longitude = values?.longitude != 'undefined' ? values?.longitude : '';
    this.latitude = values?.latitude != 'undefined' ? values?.latitude : '';
    this.number_permanent_employee =
      values?.number_permanent_employee != 'undefined'
        ? values?.number_permanent_employee
        : '';
    this.number_temporal_employee =
      values?.number_temporal_employee != 'undefined'
        ? values?.number_temporal_employee
        : '';
    this.id = values?.id != 'undefined' ? values?.id : '';
    this.productions = values?.productions ? values.productions : null;
    this.updated_at = values?.updated_at;
    this.created_at = values?.created_at;
  }

  prepareFormData(id): FormData {
    let postData = new FormData();
    postData.append('name', this.name ? this.name : 'no_name_farm');
    postData.append('altitude', this.altitude ? this.altitude : '0');
    postData.append('locality', this.locality ? this.locality : 'undefined');
    postData.append('postal_code', this.postal_code ? this.postal_code : '0');
    postData.append('street', this.street ? this.street : 'undefined');
    postData.append(
      'municipality',
      this.municipality ? this.municipality : 'undefined'
    );
    postData.append('province', this.province ? this.province : 'undefined');
    postData.append('nature', this.nature ? this.nature : 'undefined');
    postData.append(
      'cluster_number',
      this.cluster_number ? this.cluster_number : 'undefined'
    );
    postData.append(
      'ambassador_id',
      this.ambassador_id ? this.ambassador_id : 'undefined'
    );
    postData.append('longitude', this.longitude);
    postData.append('latitude', this.latitude);

    postData.append(
      'number_permanent_employee',
      this.number_permanent_employee
    );
    postData.append('number_temporal_employee', this.number_temporal_employee);
    postData.append(
      'cluster_id',
      this.cluster_id ? this.cluster_id : 'undefined'
    );
    postData.append('department', this.department ? this.department : '0');
    postData.append('owner_id', id);
    this?.id ? postData.append('id', this.id) : null;
    //if (this.productions) postData.append('productions', this.productions);
    return postData;
  }
}
