import { createAction, props } from '@ngrx/store';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';
import { Good } from 'src/app/models/good.model';

export const LOAD_ANIMAL = 'LOAD_ANIMAL';
export const LOAD_ANIMAL_SUCCESS = 'LOAD_ANIMAL_SUCCESS';
export const LOAD_SELECTED_ANIMAL = 'LOAD_SELECTED_ANIMAL';
export const LOAD_SELECTED_ANIMAL_SUCCESS =
  'LOAD_SELECTED_ANIMAL_SUCCESS';
export const DELETE_ANIMAL = 'DELETE_ANIMAL';
export const DELETE_ANIMAL_SUCCESS = 'DELETE_ANIMAL_SUCCESS';
export const ADD_ANIMALS = 'ADD_ANIMAL';
export const ADD_ANIMALS_SUCCESS = 'ADD_ANIMAL_SUCCESS';
export const DELETE_ANIMAL_GOOD = 'DELETE_ANIMAL_GOOD';
export const DELETE_ANIMAL_GOOD_SUCCESS = 'DELETE_ANIMAL_GOOD_SUCCESS';


export const loadAnimal = createAction(LOAD_ANIMAL);
export const loadAnimalSuccess = createAction(
  LOAD_ANIMAL_SUCCESS,
  props<{ animals: Array<any> }>()
);

export const loadSelectedAnimal = createAction(LOAD_SELECTED_ANIMAL);
export const loadSelectedAnimalSuccess = createAction(
  LOAD_SELECTED_ANIMAL_SUCCESS,
  props<{ selectedAnimals: Array<any> }>()
);
export const deleteSelectedAnimal = createAction(
  DELETE_ANIMAL,
  props<{ product: ProductionsPerfarm }>()
);
export const deleteSelectedAnimalSuccess = createAction(
  DELETE_ANIMAL_SUCCESS,
);
export const deleteAnimalGood = createAction(
  DELETE_ANIMAL_GOOD,
  props<{ product: ProductionsPerfarm,good:Good }>()
);
export const deleteAnimalGoodSuccess = createAction(
  DELETE_ANIMAL_GOOD_SUCCESS,
);
export const addAnimals = createAction(
  ADD_ANIMALS,
  props<{ productions: Array<ProductionsPerfarm> }>()
);
export const addAnimalsSuccess = createAction(
  ADD_ANIMALS_SUCCESS,
  props<{ productions: Array<ProductionsPerfarm> }>()
);
