import { HttpClient, HttpHeaders } from '@angular/common/http';
import { dev } from '../config/endpoints.json';
import { Inject, Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { Objective } from '../models/objective.model';

@Injectable({ providedIn: 'root' })
export class ObjectiveApi {
  base_url = environment.appRoot + '/api/';
  headers;

  constructor(private http: HttpClient) {}

  insertObjective(objective: Objective) {
    const url = this.base_url + dev.endpoint.objective.insertObjective;
    return this.http.post(url, objective.toFormData());
  }

  getObjectiveCategories() {
    const url = this.base_url + dev.endpoint.objective.getObjectivesCategories;
    return this.http.get(url);
  }

  getObjectives() {
    const url = this.base_url + dev.endpoint.objective.getObjectives;
    return this.http.get(url);
  }
  getObjectivesWithMoneyRepporting() {
    const url =
      this.base_url + dev.endpoint.objective.getObjectivesWithMoneyReporting;
    return this.http.get(url);
  }
  deleteObjective(id) {
    const url =
      this.base_url + dev.endpoint.objective.deleteObjective + '/' + id;
    return this.http.delete(url);
  }

  updateObjective(objective) {
    const url = this.base_url + dev.endpoint.objective.updateObjective;
    const formData = new FormData();
    formData.append('id', objective.id);
    formData.append('libelle', objective.libelle);
    formData.append('required_amount', objective.required_amount);
    formData.append(
      'gather_so_far',
      objective.gather_so_far ? objective.gather_so_far : 0
    );
    formData.append(
      'cash_flow_categories_id',
      objective.cash_flow_categories_id
    );
    return this.http.post(url, formData);
  }

  getObjectiveByCategorieAndOwner(cashflow_id) {
    const url =
      this.base_url +
      dev.endpoint.objective.get_objective_by_categorie_and_owner +
      '/' +
      cashflow_id;
    return this.http.get(url);
  }
}
