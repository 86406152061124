import { loadFarm, loadFarmFromLocalStorage } from './../../../../utils/states-management/actions/farm.action';
import { Farm } from './../../../../models/farm/farm.model';
import { TranslateService } from '@ngx-translate/core';
import { AnimationUtils } from './../../../../utils/animations';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { Component, OnInit, Input } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../../config/offlines_keys.json';
import { Router } from '@angular/router';
import { IonNav } from '@ionic/angular';
import routes from '../../../../config/routing.json';
import { PriorDashboardComponent } from '../../../prior-dashboard/prior-dashboard.component';
import * as turf from '@turf/turf';
import { USAGE_TYPE } from '../../../../models/lots.model';
import { LotAPI } from 'src/app/apis/lot.service';
import { Geolocation } from '@capacitor/geolocation';
import { Store } from '@ngrx/store';
import { selectAllPlots } from 'src/app/utils/states-management/reducers/plot.reducer';
import { FarmAddressDetailsComponent } from '../../farm-details/farm-address-details/farm-address-details.component';
import { take } from 'rxjs';
import { environment } from 'src/environments/environment';

interface FormConfig {
  is_map_showable: boolean;
}

@Component({
  selector: 'app-lots-overview',
  templateUrl: './lots-overview.component.html',
  styleUrls: ['./lots-overview.component.scss'],
})
export class LotsOverviewComponent implements OnInit {
  selectedLangage;
  total: number = 0;
  lots: Array<any>;
  lotNoProdCafe: number = 0;
  lotProdcafe: number = 0;
  lotOtherUse: number = 0;
  lotOtherProd: number = 0;
  lot_agricole: Array<any>;
  lot_other_use: Array<any>;
  lotNoProdCafeP: number = 0;
  lotProdcafeP: number = 0;
  lotOtherUseP: number = 0;
  lotOtherProdP: number = 0;
  totalP: number = 0;
  farm;
  isDashboardView: Boolean;
  @Input() nav: IonNav;
  @Input() rootStack;
  is_lots_view = true;
  polygones = new Array();
  extendable = {
    is_agricultural_expanded: true,
    is_other_expanded: true,
  };
  lati;
  long;
  formConfig: FormConfig = {
    is_map_showable: false,
  };
  Geolocation;
  base_url = environment.appRoot
  constructor(
    private router: Router,
    private routingConfig: RoutingConfig,
    private animationUtils: AnimationUtils,
    public translateService: TranslateService,
    private lotAPI: LotAPI,
    private store: Store<{ farm: Farm }>
  ) {}

  async ngOnInit() {
    this.Geolocation = Geolocation;

    this.translate();
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)

    this.loadData()
  }


  loadData(){
    this.store.select('farm').subscribe(async (value: Farm) => {
      this.farm = value;
  
      await this.initLots(this.farm?.lots);
      this.configForm();
    });
    this.store.dispatch(loadFarm());
    this.store.select(selectAllPlots).subscribe(async (value) => {
      await this.initLots(value);
      this.calculateTotalArea();
    });
    this.store.dispatch(loadFarmFromLocalStorage());

  }
  
  redirectToFarmLocation() {
    this.router.navigateByUrl('/farm');
    //     this.nav.setRoot(FarmAddressDetailsComponent, {
    //   ...this.nav,
    //   isDashboardView: true,
    // });
  }
  async configForm() {
    this.long = parseFloat(this.farm.longitude);
    this.lati = parseFloat(this.farm.latitude);
    if (isNaN(this.long) || isNaN(this.lati)) {
      await this.Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
      })
        .then(async (value: GeolocationPosition) => {
          this.long = +value.coords.longitude;
          this.lati = +value.coords.latitude;
        })

        .catch((err) => {
          console.log(err);
        });
    }
  }
ngOnDestroy(): void {
  //Called once, before the instance is destroyed.
  //Add 'implements OnDestroy' to the class.
  
}
  async handelCardClicked(type) {
    if (type === 'agricol' && this.lot_agricole.length > 0) {
      if (this.extendable.is_agricultural_expanded) {
        await this.animationUtils.collapsAnimation({ id: type });
      } else {
        await this.animationUtils.expandAnimation({ id: type });
      }
      this.extendable.is_agricultural_expanded =
        !this.extendable.is_agricultural_expanded;
    } else if (type === 'other' && this.lot_other_use.length > 0) {
      if (this.extendable.is_other_expanded) {
        await this.animationUtils.collapsAnimation({ id: type });
        this.extendable.is_other_expanded = !this.extendable.is_other_expanded;
      } else {
        await this.animationUtils.expandAnimation({ id: type });
        this.extendable.is_other_expanded = !this.extendable.is_other_expanded;
      }
    }
  }

  async translate() {
    this.selectedLangage = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );
  }

  async initLots(lots) {
    this.lots = lots;
    this.lot_agricole = lots?.filter((value) => value.type == 'agricol');
    this.lot_other_use = lots?.filter((value) => value.type == 'other_use');
    this.calculateSuperficie();
    // this.total = this.lotOtherUse + this.lotOtherProd;
    // this.totalP = this.lotOtherUseP + this.lotOtherProdP;
  }

  calculateSuperficie() {
    if (Array.isArray(this.lots)) {
      // this.total = 0;
      // this.totalP = 0;
      this.lotOtherProd = 0;
      this.lotOtherProdP = 0;
      this.lotOtherUse = 0;
      this.lots.forEach((element) => {
        switch (element.type) {
          case USAGE_TYPE.agricol:
            this.lotOtherProd += element.superficie;
            this.lotOtherProdP += parseFloat(element.perimeters);
            break;

          case USAGE_TYPE.other:
            this.lotOtherUse += element.superficie;
            this.lotOtherUseP += parseFloat(element.perimeters);
            break;
        }
      });
    }

    this.total = this.lotOtherUse + this.lotOtherProd;
    this.totalP = this.lotOtherUseP + this.lotOtherProdP;
  }

  async previous() {
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());
  }

  async done() {
    this.loadData()

    this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }

  navBack() {

    this.loadData()

    this.nav.setRoot(this.rootStack, {
      nav: this.nav,
      rootStack: PriorDashboardComponent,
    });
  }

  calculateTotalArea() {
    const polygones = new Array();
    if (Array.isArray(this.lots)) {
      this.lots.forEach((element) => {
        if (Array.isArray(element.lands) && element.lands.length > 1) {
          var polygone = new Array();
          element.lands.forEach((points) => {
            polygone.push([points.longitude, points.latitude]);
          });
          polygone.push(polygone[0]);
          polygones.push(turf.polygon([polygone]));
        }
      });
      if (polygones.length > 0) {
        const multiPolygons = polygones.reduce((combined, multiPolygon) => {
          return turf.union(combined, multiPolygon);
        });

        // this.total = 0;
        // this.totalP = 0;

        const areaSquareMeter = turf.area(multiPolygons);
        // this.total = turf.convertArea(areaSquareMeter, 'meters', 'hectares');
        const multiLines = turf.polygonToLine(multiPolygons);
        this.totalP = turf.length(multiLines, { units: 'meters' });
      }
    }
  }
}
