import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Pipe, PipeTransform } from '@angular/core';
import { PickerController } from '@ionic/angular';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  constructor(public translateService:TranslateService){

  }
  transform(value: any, ...args: any[]): any {
    var date = new Date(value);
     let formatedDate = date.toLocaleDateString(args[0], {
        day: '2-digit'
      })+ "-" +
      date.toLocaleDateString(args[0], {
        month: 'long'
      })+ "-" +
      date.toLocaleDateString(args[0], {
        year: 'numeric'
      })
      
    return formatedDate;
  }
}
@Pipe({
  name: 'dateFormatShort',
})
export class DateFormatShort implements PipeTransform {
  constructor(public translateService:TranslateService){

  }
  transform(value: any, ...args: any[]): any {
    var date = new Date(value);
    let day = date.toLocaleDateString(args[0], {
      day: '2-digit'
    });
    let month = date.toLocaleDateString(args[0], {
      month: 'short'
    })
    let year = date.toLocaleDateString(args[0], {
      year: 'numeric'
    })

     let formatedDate = day+ " " + month[0].toUpperCase() + month.slice(1) + " " + year
      
      
    return formatedDate;
  }
}

export function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  //alert([year, month, day].join('-'));
  return [year, month, day].join('-');
}

export function getCurrentMonthAndYear(date) {
  var d = new Date(date);
  return { year: d.getFullYear(), month: d.getMonth() + 1 };
}

export function calculateAge(birthday) {
  // birthday is a date
  var ageDifMs = Date.now() - birthday;
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

@Injectable()
export class PickerOptionFormater {
  cancelText = 'Cancelar';
  submitText = 'Confirmar';
  constructor(public translateService: TranslateService,private pickerController: PickerController) {
    this.translateService.get('submit').subscribe(
      (value) => (this.submitText = value),
      (e) => console.log(e)
    );
    this.translateService.get('cancel').subscribe(
      (value) => (this.cancelText = value),
      (e) => console.log(e)
    );
  }

  custom_picker_options = (updateble: any, attribut_name: string = '') => {
    return {
      buttons: [
        {
          text: this.cancelText,
          cssClass: 'cancel-btn',

          handler: (value) => {
            updateble[attribut_name] =
              value.year.value +
              '-' +
              value.month.value +
              '-' +
              value.day.value;
          },
        },
        {
          text: this.submitText,
          cssClass: 'confirm-btn',
          handler: (value) => {
            updateble[attribut_name] =
              value.year.value +
              '-' +
              value.month.value +
              '-' +
              value.day.value;
            return true;
          },
        },
      ],
    };
  };


   
  async openPicker(selectedValue=null,numColumns = 1, numOptions = 101) {

    const picker = await this.pickerController.create({
      columns: this.getColumns(numColumns, numOptions),
      cssClass:'percentagePicker',
      buttons: [
        {
          text: await this.translateService.get('submit').toPromise(),
          cssClass: 'confirm-btn',
        },
      ],
    });
    if(selectedValue){
      picker.columns[0].selectedIndex = selectedValue
    }
    await picker.present();
    let data = await picker.onWillDismiss();
    return data
  }

   getColumns(numColumns, numOptions) {
    let columns = [];
    for (let i = 0; i < numColumns; i++) {
      columns.push({
        name: `${i}`,
        options: this.getColumnOptions(numOptions),
      });
    }

    return columns;
  }

   getColumnOptions(numOptions) {
    let options = [];
    for (let i = 0; i < numOptions; i++) {
      options.push({
        text:  i +'%',
        value: i,
      });
    }

    return options;
  }
}
