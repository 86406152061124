import { Pipe } from '@angular/core';

@Pipe({ name: 'FormatNumber', pure: false })
export class FormatNumber {
  transform(number) {

    var num = Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(
      number
    );
    return num;
  }
}

