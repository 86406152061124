import { Component, OnInit, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { AnalysisCashflowComponent } from '../../dashboard/cashflow/analysis-cashflow/analysis-cashflow.component';
import { SummaryComponent } from '../../dashboard/summary/summary.component';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.page.html',
  styleUrls: ['./analysis.page.scss'],
})
export class AnalysisPage implements OnInit {
  @ViewChild('nav') nav: IonNav;
  rootParams
  constructor() { }
  ngAfterViewInit(): void {
    this.rootParams = {
      nav: this.nav,
      rootStack: AnalysisCashflowComponent,
      isDashboardView: true,
    };
    this.nav.setRoot(AnalysisCashflowComponent, {
      nav: this.nav,
      // rootStack: SummaryComponent,
    });
  }
  ngOnInit() {
  }

}
