import { Component, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { lastValueFrom, take } from 'rxjs';
import Owner from 'src/app/models/owner.model';
import { loadTotalAccountingCategories } from 'src/app/utils/states-management/actions/dashboard.action';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {

  constructor(
    private store:Storage
  ) { }
  owner:Owner
  async ngOnInit() {
 
    this.owner = cloneDeep(await lastValueFrom(this.store.select('owner').pipe(take(1))));
    console.log(this.owner)
    // this.store
    // .select('owner')
    // .subscribe((value: any) => {
    //   this.owner = cloneDeep(value);
    //   if (value.first_name && value.last_name) {
    //     this.user_name = value.first_name + '  ' + value.last_name;
    //     this.country_id = value.country_id;
    //   }
    //   if (value.profile_info)
    //     this.profile_info = JSON.parse(value.profile_info);

    //   if (value.file && value.file != null && value.file?.path) {
    //     this.image = environment.appRoot + value.file?.path;
    //   } else {
    //     this.image = '/assets/icon/app-icon/icn-profile-pic.svg';
    //   }
    //   if (
    //     value &&
    //     value?.checkpoint_path &&
    //     value?.checkpoint_path != '/dashboard'
    //   ) {
    //     this.store.dispatch(setCheckPointRoute({ url: '/dashboard' }));
    //   }
    //   if (value && value.country_id)
    //     this.store.dispatch(
    //       accountingEntriesLoading({ country_id: value.country_id })
    //     );
    // });
  }
  

}
