import { RoutingConfig } from 'src/app/utils/routing-config';
import { MORPH_Owner } from './../../../utils/consts';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { OperationsAPI } from '../../../apis/operations.service';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../config/offlines_keys.json';
import { Router } from '@angular/router';
import { ModalDebtComponent } from '../modal-debt/modal-debt.component';
import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import routes from '../../../config/routing.json';
import { PriorDashboardComponent } from '../../prior-dashboard/prior-dashboard.component';
import { environment } from '../../../../environments/environment';
import { CashflowSelectorComponent } from '../../dashboard/widgets/cashflow-selector/cashflow-selector.component';
import { TranslateService } from '@ngx-translate/core';
import { MORPH_FamilyMember, MORPH_Farm } from 'src/app/utils/consts';

@Component({
  selector: 'app-debt-from',
  templateUrl: './debt-from.component.html',
  styleUrls: ['./debt-from.component.scss'],
  providers: [OperationsAPI],
})
export class DebtFromComponent implements OnInit {
  @Input() isDashboardView;
  @Input() nav;
  @Input() rootStack;
  list: any;
  owner_id: number;
  you = 'Usted';
  has_debts = false;
  main_endpoint = environment.appRoot;
  MORPH_Farm = MORPH_Farm;
  MORPH_FamilyMember = MORPH_FamilyMember;
  MORPH_Owner = MORPH_Owner;
  constructor(
    public modalController: ModalController,
    private router: Router,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private operationAPI: OperationsAPI,
    private routingConfig: RoutingConfig
  ) {}

  async ngOnInit() {
    const lang = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );
    this.langageChanger(lang);
    await this.getowner_id();
    this.getOperations();
    this.showPopOver();
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
    if (this.isDashboardView) this.has_debts = true;
  }

  async presentModal() {
    let modal = await this.toastUtils.modalBuilder(ModalDebtComponent);
    modal.onDidDismiss().then(() => this.getOperations());
  }

  async presentupdateModal($id: number) {
    let modal = await this.toastUtils.modalBuilder(
      ModalDebtComponent,
      { id: $id },
      ['my-custom-modal-css', 'modal-wrapper']
    );

    modal.onDidDismiss().then(() => this.getOperations());
  }

  async getowner_id() {
    await Storage.get({ key: dev.USER_KEY }).then((value) => {
      this.owner_id = JSON.parse(value.value)?.id;
    });
  }

  async showPopOver() {
    let componentProps = {
      key1: await this.translateService
        .get('bubble_desc_debt')
        .toPromise()
        .then((value) => value),
    };
    await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
  }

  langageChanger(lang) {
    if (lang == 'es') {
      this.you = 'Usted';
    } else {
      this.you = 'You';
    }
  }

  getOperations() {
    this.operationAPI.getOperation().subscribe((value: any) => {
      this.list = value?.filter((value) => {
        return value.operation_type == 'debt';
      });
      this.prepareList(this.list);
    });
  }
  prepareList(val) {
    if (Array.isArray(val)) {
      val.forEach((element) => {
        element.calculatedPercent = this.calculatePercent(
          element.amount,
          element.paid_so_far
        );
        element.display_percent =
          (100 * this.calculatePercent(element.amount, element.paid_so_far))
            .toFixed(2)
            .toString() + '%';
      });
    }
  }

  calculatePercent(required_amount, gathered_amount) {
    if (!gathered_amount) {
      gathered_amount = 0;
    }
    return gathered_amount / required_amount;
  }
  async delete(item) {
    if (this.isDashboardView) {
      if (item.cashflows.length > 0) {
        let modal = await this.toastUtils.modalBuilder(
          CashflowSelectorComponent,
          {
            modalController: this.modalController,
            cashflows: item.cashflows,
            operation: item,
          },
          ['my-custom-modal-css', 'modal-wrapper']
        ); // this.loadingModal.dismiss();
        const { data } = await modal.onWillDismiss();
        if (data.deleteLegth) {
          if (
            Array.isArray(item.cashflows) &&
            data.deleteLegth === item.cashflows.length &&
            data.deleteLegth !== 0
          ) {
            this.operationAPI.deleteOperation(item.id).subscribe((value) => {
              value;
              this.getOperations();
            });
          }
        }
      } else await this.deleteOp(item);
    } else await this.deleteOp(item);
  }

  async deleteOp(item) {
    this.operationAPI.deleteOperation(item.id).subscribe((value) => {
      value;
      this.getOperations();
    });
  }

  updateForm($id: number) {
    this.presentupdateModal($id);
  }

  async mnext() {
    this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }

  async mreturn() {
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());
  }

  goBackToDashBoard() {
    this.nav.setRoot(this.rootStack, {
      nav: this.nav,
      rootStack: PriorDashboardComponent,
    });
  }
  answeredEventChange(value) {
    this.has_debts = value;
    if (!this.has_debts) this.mnext();
  }
}
