import { dev } from 'src/app/config/offlines_keys.json';
import { RoutingConfig } from 'src/app/utils/routing-config';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';
import { Storage } from '@capacitor/storage';

@Component({
  selector: 'app-footer-partner',
  templateUrl: './footer-partner.component.html',
  styleUrls: ['./footer-partner.component.scss'],
})
export class FooterPartnerComponent implements OnInit, OnChanges {
  previousUrl: any;
  @Input('matStepperNext') matStepperNext: any;
  @Input('steps') steps: MatStepper;
  @Input() step;
  @Input() cropProducts;
  @Input() nextUrl;
  @Input() backUrl;
  @Output() nextClicked: EventEmitter<any> = new EventEmitter();

  next_button;
  ConfigIndex;
  constructor(public router: Router, private routingConfig: RoutingConfig) {
    // router.events
    //   .pipe(filter((event) => event instanceof NavigationEnd))
    //   .subscribe((event: NavigationEnd) => {
    //     this.previousUrl = event;
    //   });
  }

  async ngOnChanges(changes: SimpleChanges) {
    // await this.ngOnInit();
  }
  
  async ngOnInit() {
    // this.cropProducts = JSON.parse(
    //   (await Storage.get({ key: dev.CROP_PRODUCT })).value
    // );
    //   if(this.formConfig)
    //    var arrConfig = Object.entries(this.formConfig);
    //   else
    //    var arrConfig = Object.entries({});

    // if (arrConfig.length == 3) {
    //   this.ConfigIndex = 0;
    // } else if (arrConfig.length == 2) {
    //   this.ConfigIndex = 1;
    // } else if (arrConfig.length == 1) {
    //   this.ConfigIndex = 3;
    // }
  }
  async next() {
    // var arrConfig = Object.entries(this.formConfig);
    // this.steps.next();
    if(this.steps.selectedIndex<this.cropProducts.length-1)
    this.steps.next();
    else
    this.router.navigateByUrl(this.nextUrl);

  }

  async previous() {
    if(this.steps.selectedIndex>0)
    this.steps.previous();
    else
    this.router.navigateByUrl(this.backUrl);
  }
}
