import { FooterPartnerComponent } from './footer-partner/footer-partner.component';
import { MatStepperModule } from '@angular/material/stepper';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { FooterModule } from '../../widgets/footer-component/header-footer.module';
import { PartnerRoutingModule } from './partner-routing.module';
import { PartnerMainViewComponent } from './partner-main-view/partner-main-view.component';
import { PartnerViewOneComponent } from './partner-view-one/partner-view-one.component';
import { ModalPartnerComponent } from './modal-partner/modal-partner.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    PartnerMainViewComponent,
    PartnerViewOneComponent,
    ModalPartnerComponent,
    FooterPartnerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  imports: [
    FooterModule,
    TranslateModule.forChild(),
    PartnerRoutingModule,
    MatTabsModule,
    CommonModule,
    TranslateModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    MatStepperModule

  ],
  exports: [
    PartnerRoutingModule,
    PartnerMainViewComponent,
    PartnerViewOneComponent,
    ModalPartnerComponent,

  ],
})
export class PartnerModule {}
