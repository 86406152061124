import { Component, Input, OnInit } from '@angular/core';


import { Capacitor } from '@capacitor/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-youtube-video',
  templateUrl: './youtube-video.component.html',
  styleUrls: ['./youtube-video.component.scss'],
})
export class YoutubeVideoComponent implements OnInit {

  @Input() youtubevideoId 
  @Input() youtubeDivId 

  constructor(
    public popover: PopoverController
  ) {}

  async ngOnInit() {
    if (Capacitor.getPlatform() === 'web') {
      await this.initializeYoutubePlayerPluginWeb();
    } else { // Native
      this.initializeYoutubePlayerPluginNative();
    }
  }


  // dismissPopover() {
  //   this.popover.dismiss()
  // }

  async ngAfterViewInit() {

  }

  async initializeYoutubePlayerPluginWeb() {
    const options = {playerId: 'youtube-player-'+ this.youtubeDivId, playerSize: {width: 640, height: 360}, videoId: this.youtubevideoId, debug: false};
    // const result = await YoutubePlayer.initialize(options);
  }

  async destroyYoutubePlayerPluginWeb() {
    // const result = await YoutubePlayer.destroy('youtube-player');
  }

  async initializeYoutubePlayerPluginNative() {

    const options = {width: 640, height: 360, videoId: this.youtubevideoId};
  }
}
