import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GarenteeComponent } from './garentee/garentee.component';
import { MoneyReportingComponent } from './money-reporting/money-reporting.component';
import appRoutes from '../../config/routing.json';
import { MaterialGoodsComponent } from './material-goods/material-goods.component';
import { BankAccountsComponent } from './bank-accounts/bank-accounts.component';
import { MoneyLoanComponent } from './money-loan/money-loan.component';

const routes: Routes = [
  {
    path: '',
    component: MoneyReportingComponent,
  },
  {
    path: appRoutes.goods.bank_accounts,
    component: BankAccountsComponent,
  },
  {
    path: appRoutes.goods.material_goods,
    component: MaterialGoodsComponent,
  },
  {
    path: appRoutes.goods.money_loan,
    component: MoneyLoanComponent,
  },
  { path: appRoutes.goods.garentee, component: GarenteeComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GoodsRoutingModule {}
