import { HttpClient } from '@angular/common/http';
import { dev } from '../config/endpoints.json';
import { Inject, Injectable } from '@angular/core';
import Operation from '../models/operation.model';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class OperationsAPI {
  base_url = environment.appRoot + '/api/';
  headers;
  constructor(private http: HttpClient) {}
  storeOperation(operation: Operation): Observable<unknown> {
    const url = this.base_url + dev.endpoint.operation.operation;
    const formData = new FormData();

    formData.append('amount', operation.amount);
    formData.append('currency', operation.currency);
    formData.append('is_interest', operation.is_interest);
    formData.append('interest_amount', operation.interest_amount);
    formData.append('due_date', operation.due_date);
    formData.append('third_part_name', operation.third_part_name);
    formData.append('creation_date', operation.creation_date);
    formData.append('title', operation.title);
    formData.append('operation_reason', operation.operation_reason);
    formData.append('operation_type', operation.operation_type);
    formData.append('morphable_type', operation.morphable_type);
    formData.append('morphable_id', operation.morphable_id);
    formData.append('owner_id', operation.owner_id);
    formData.append('paid_so_far', operation.paid_so_far.toString());

    return this.http.post(url, formData);
  }

  getOperation(): Observable<unknown> {
    const url = this.base_url + dev.endpoint.operation.operations_one_owner;

    return this.http.get(url);
  }

  deleteOperation($id: number) {
    const url = this.base_url + dev.endpoint.operation.operation;
    return this.http.delete(url + '/' + $id);
  }
  getOneOperation($id: number): Observable<unknown> {
    const url = this.base_url + dev.endpoint.operation.operation;

    return this.http.get(url + '/' + $id);
  }

  updateOperation(operation: Operation, $id: number): Observable<any> {
    const url = this.base_url + dev.endpoint.operation.operation;
    const formData = new FormData();

    formData.append('amount', operation.amount);
    formData.append('currency', operation.currency);
    formData.append('is_interest', operation.is_interest);
    formData.append('interest_amount', operation.interest_amount);
    formData.append('due_date', operation.due_date);
    formData.append('third_part_name', operation.third_part_name);
    formData.append('creation_date', operation.creation_date);
    formData.append('title', operation.title);
    formData.append('operation_reason', operation.operation_reason);
    formData.append('operation_type', operation.operation_type);
    formData.append('morphable_type', operation.morphable_type);
    formData.append('morphable_id', operation.morphable_id);
    formData.append('owner_id', operation.owner_id);
    formData.append('paid_so_far', operation.paid_so_far.toString());
    return this.http.post(url + '/' + $id + '?_method=PATCH', formData);
  }
}
