import { HttpClient } from '@angular/common/http';
import { dev } from '../config/endpoints.json';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Money_reporting from '../models/money_reporting.model';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class MoneyReportingsAPI {
  base_url = environment.appRoot + '/api/';
  constructor(private http: HttpClient) {}

  storeMoneyReporting(m_reporting: Money_reporting): Observable<any> {
    const url = this.base_url + dev.endpoint.money_reporting.money_reporting;
    const formData = new FormData();

    formData.append('amount', m_reporting.amount);
    formData.append('currency', m_reporting.currency);
    formData.append('type', m_reporting.type);
    formData.append('money_reporting_name', m_reporting.money_reporting_name);
    formData.append('owner_id', m_reporting.owner_id);
    if (m_reporting.pension_plan_objective)
      formData.append(
        'pension_plan_objective',
        m_reporting.pension_plan_objective.toString()
      );
    return this.http.post(url, formData);
  }

  getMoneyReporting(): Observable<any> {
    const url =
      this.base_url + dev.endpoint.money_reporting.money_reportings_one_owner;
    return this.http.get(url);
  }

  deleteMoneyReporting(id: number): Observable<any> {
    const url = this.base_url + dev.endpoint.money_reporting.money_reporting;

    return this.http.delete(url + '/' + id);
  }
  getOneMoneyReporting(id: number): Observable<any> {
    const url = this.base_url + dev.endpoint.money_reporting.money_reporting;

    return this.http.get(url + '/' + id);
  }

  updateMoneyReporting(m_reporting: Money_reporting): Observable<any> {
    const url = this.base_url + dev.endpoint.money_reporting.money_reporting;
    const formData = new FormData();
    formData.append('id', m_reporting.id);
    formData.append('amount', m_reporting.amount);
    formData.append('currency', m_reporting.currency);
    formData.append('type', m_reporting.type);
    formData.append('money_reporting_name', m_reporting.money_reporting_name);
    if (m_reporting.pension_plan_objective)
      formData.append(
        'pension_plan_objective',
        m_reporting.pension_plan_objective.toString()
      );
    return this.http.post(
      url + '/' + m_reporting.id + '?_method=PATCH',
      formData
    );
  }
  getTotalAmountData( currency, date): Observable<any> {
    const url =
      this.base_url + dev.endpoint.money_reporting.total_amount_one_user;
    return this.http.get(url + '/' + currency + '/' + date);
  }
}
