import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PartnerState, partnerAdapter } from '../reducers/partner.reducer';

export const PartnerSelector =
  createFeatureSelector<PartnerState>('partners');

export const selectAllPartners = createSelector(
  PartnerSelector,
  partnerAdapter.getSelectors().selectAll
);

export const selectPartnerById = (id: number) =>
  createSelector(PartnerSelector, (allItems: any) => {
    if (allItems?.entities[id]) {
      return allItems?.entities[id];
    } else {
      return null;
    }
  });
