import { cloneDeep } from 'lodash';
import { dev } from 'src/app/config/offlines_keys.json';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { Observable, from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { OwnerAPI } from 'src/app/apis/owners.service';
import { ToastUtils } from '../../toast_builder';
import {
  dataSyncWithServerError,
  dataSyncWithServerErrorShowed,
  deleteProfilePhoto,
  getOwnerById,
  getOwnerByIdSucess,
  logOut,
  logOutSuccess,
  setCheckPointRoute,
  setCheckPointRouteSuccess,
  storeOwnerPhoto,
  updateOwnerAndFarmCluster,
  updateOwnerData,
  updateOwnerDataSuccess,
  updateOwnerPhoto,
} from '../actions/owner.action';
import routes from '../../../config/routing.json';
import { StorageSyncActions, fetchState } from '../storage-sync.module';
import { Storage } from '@capacitor/storage';
import { UserInfo } from 'src/app/apis/users-info.service';
import Owner from 'src/app/models/owner.model';

@Injectable()
export class OwnerEffect {
  isDashboardView: boolean;

  hydrate$ = createEffect(
    (): Observable<any> =>
      this.actions.pipe(
        ofType(StorageSyncActions.NGRX_EFFECT_INIT),
        switchMap((value) => {
          return from(fetchState()).pipe(
            map((state) => {
              return {
                type: StorageSyncActions.HYDRATED,
                payload: state,
              };
            }),
            catchError((e) => {
              console.warn(
                `error fetching data from store for hydration: ${e}`
              );
              return of({
                type: StorageSyncActions.HYDRATED,
                payload: {},
              });
            })
          );
        })
      )
  );

  $logOut = createEffect(() => {
    return this.actions.pipe(
      ofType(logOut),
      switchMap(async (action: any) => {
        const lang = await Storage.get({ key: dev.LANGAGE_KEY }).then(
          (value) => value.value
        );
        await Promise.all([
          Storage.clear(),
          this.userInfo.setSelectedLangage(lang)
        ]);
        this.userInfo.owner = null;
        this.router.navigateByUrl(action.redirection_path);
        return logOutSuccess();

      }),
    );
  });

  $getUserById = createEffect(() => {
    return this.actions.pipe(
      ofType(getOwnerById),
      switchMap((action: any) =>
        this.ownerAPI.getOwnerById().pipe(
          map((value: any) => getOwnerByIdSucess({ owner: value }))
          // catchError((err) => of(dataSyncWithServerError(err)))
        )
      )
    );
  });

  updateOwnerAndFarmCluster$ = createEffect(() => {
    return this.actions.pipe(
      ofType(updateOwnerAndFarmCluster),
      switchMap((action) =>
        this.ownerAPI
          .updateOwnerAndFarmCluster(action.data, action.profile_info)
          .pipe(
            map((value: any) => {
              this.isDashboardView =
                this.router.url === '/' + routes.dashboard.profile;
                if (this.isDashboardView) {
                  this.toastUtils
                    .toastBuilder(
                      this.translateService.instant('change_done'),
                      null,
                      null,
                      'success'
                    );
                }
              return getOwnerByIdSucess({ owner: value });
            })
            // catchError((err) => of(dataSyncWithServerError(err)))
          )
      )
    );
  });

  $setCehckPoint = createEffect(() =>
    this.actions.pipe(
      ofType(setCheckPointRoute),
      switchMap((action: any) =>
        this.ownerAPI.setCheckpointRoute(action.url).pipe(
          map((value: any) =>
            setCheckPointRouteSuccess({ url: value?.checkpoint_path })
          ),

          catchError((err) => of(dataSyncWithServerError(err)))
        )
      )
    )
  );

  $deleteProfilePhoto = createEffect(() => {
    return this.actions.pipe(
      ofType(deleteProfilePhoto),
      switchMap((action: any) =>
        this.ownerAPI.deleteOwnerPhoto().pipe(
          map((value) => getOwnerById()),
          catchError((err) => of(dataSyncWithServerError(err)))
        )
      )
    );
  });

  $storeOwnerPhoto = createEffect(() => {
    return this.actions.pipe(
      ofType(storeOwnerPhoto),
      switchMap(({ file }) =>
        this.ownerAPI.storeOwnerProfilePhoto(file).pipe(
          map((value) => getOwnerById()),
          catchError((err) => of(dataSyncWithServerError(err)))
        )
      )
    );
  });

  $updateOwnerPhoto = createEffect(() => {
    return this.actions.pipe(
      ofType(updateOwnerPhoto),
      switchMap((action) =>
        this.ownerAPI.updateOwnerPhoto(action.id, action.file).pipe(
          map((value) => {
            return value;
          }),
          catchError((err) => of(dataSyncWithServerError(err)))
        )
      ),
      map((value) => {
        this.isDashboardView =
          this.router.url === '/' + routes.dashboard.profile;

        if (this.isDashboardView) {
          // this.toastUtils
          //   .toastBuilder(
          //     this.translateService.instant('change_done'),
          //     null,
          //     null,
          //     null
          //   )
          //   .then((value) => value.present());
        }
        return getOwnerById();
      })
    );
  });

  // $updateOwnerData = createEffect(() => {
  //   return this.actions.pipe(
  //     ofType(updateOwnerData),
  //     switchMap(({ owner }) =>
  //       this.ownerAPI.updateOwnerData(owner).pipe(
  //         map((values) => {
  //           let ownerData = cloneDeep(values);
  //           ownerData.file = owner.file;
  //           return {ownerData}
  //         })
  //         // ,
  //         // catchError((err) => of(dataSyncWithServerError(err)))
  //       )
  //     ),
  //     map(({ownerData}) => {
  //       return updateOwnerDataSuccess({ owner: ownerData });

  //     })
  //   );
  // });
  $updateOwnerData = createEffect(() =>
  this.actions.pipe(
    ofType(updateOwnerData),
    switchMap(({owner}) => {
      return this.ownerAPI.updateOwnerData(owner).pipe(
        map((result) => {
           let ownerData = cloneDeep(result);
           this.isDashboardView =
           this.router.url === '/' + routes.dashboard.profile;
           if (this.isDashboardView) {
             this.toastUtils
               .toastBuilder(
                 this.translateService.instant('change_done'),
                 null,
                 null,
                 'success'
               );
           }
            // ownerData.file = owner.file;
          return { ownerData };
        })
      );
    }),

    map(({ ownerData }) => {
    
      return updateOwnerDataSuccess({ owner: ownerData });

    })
  )
);
  // $showError = createEffect(() =>
  //   this.actions.pipe(
  //     ofType(dataSyncWithServerError),
  //     switchMap((action) => {
  //       if (action?.err?.status != 401)
  //         return from(
  //           this.toastUtils.toastBuilder(
  //             this.translateService.instant('sync_error'),
  //             null,
  //             null,
  //             'danger'
  //           )
  //         );
  //     }),
  //     map((value) => value.present()),
  //     map(() => dataSyncWithServerErrorShowed())
  //   )
  // );

  constructor(
    private actions: Actions,
    private ownerAPI: OwnerAPI,
    private toastUtils: ToastUtils,
    public translateService: TranslateService,
    private router: Router,
    private userInfo: UserInfo
  ) {}
}
