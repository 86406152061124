import { TranslateService } from '@ngx-translate/core';
import { UserInfo } from '../apis/users-info.service';

export const pushOtrosToEnd = (val: any, query) => {
  if (Array.isArray(val))
    val.forEach((element, index) => {
      if (element.title.toLowerCase().includes(query)) {
        val.splice(index, 1);
        val.push(element);
      }
    });

  return val;
};

export const orderAlphabaticallyUnit = (val, lang) => {
  if (Array.isArray(val)) {
    val = val.sort(function (a, b) {
      if (lang !== 'es')
        return a['label_' + lang] === b['label_' + lang]
          ? 0
          : a['label_' + lang] < b['label_' + lang]
          ? -1
          : 1;
      else return a.label === b.label ? 0 : a.label < b.label ? -1 : 1;
    });
  }
  // if (lang == 'en') {
  //   val.forEach((element) => {
  //     element.label = element.label_en;
  //   });
  // }

  return val;
};
export const orderAlphabaticallyTitles = (val, lang) => {
  if (Array.isArray(val)) {
    if (lang != 'es')
      val = val.sort(function (a, b) {
        return a['title_' + lang] === b['title_' + lang]
          ? 0
          : a['title_' + lang] < b['title_' + lang]
          ? -1
          : 1;
      });
    else
      val = val.sort(function (a, b) {
        return a.title === b.title ? 0 : a.title < b.title ? -1 : 1;
      });
  }
  return val;
};

export const orderAlphabaticallyComment = (val) => {
  if (Array.isArray(val)) {
    val = val.sort(function (a, b) {
      return a.comment === b.comment ? 0 : a.comment < b.comment ? -1 : 1;
    });
  }
  return val;
};

export const orderAlphabaticallyNames = (val, lang = 'es') => {
  if (Array.isArray(val)) {
    val = val.sort(function (a, b) {
      if (lang === 'es')
        return a.name?.normalize('NFD') === b.name?.normalize('NFD')
          ? 0
          : a.name?.normalize('NFD') < b.name?.normalize('NFD')
          ? -1
          : 1;
      else
        return a['name_' + lang]?.normalize('NFD') ===
          b['name_' + lang]?.normalize('NFD')
          ? 0
          : a['name_' + lang]?.normalize('NFD') <
            b['name_' + lang]?.normalize('NFD')
          ? -1
          : 1;
    });
  }
  return val;
};

export const mapLangeNameObjects = (val, lang: string) => {
  var array = val;
  if (Array.isArray(val)) {
    array = val.map((elment) => {
      if (lang == 'en') {
        elment.name = elment.name_en;
      }
      return elment;
    });
    return orderAlphabaticallyNames(array, lang);
  }
};

export const mapLangeTitleObjects = (
  val: Array<any>,
  attribute_name: string,
  translateService: TranslateService
) => {
  var array = val;
  if (Array.isArray(val)) {
    array = orderAlphabaticallyByAttribute(
      array,
      attribute_name,
      translateService.currentLang
    );
    array = pushOtrosToEndByAttributeName(
      array,
      attribute_name,
      translateService.instant('other_key')
    );
    return array;
  }
};
export const pushOtrosToEndByAttributeName = (
  val: any,
  attribute_name: string,
  query: string
) => {
  if (Array.isArray(val))
    val.forEach((element, index) => {
      // let firstThree = element[attribute_name]?.substring(0, 3);
      // if (element[attribute_name] && element[attribute_name]?.toLowerCase().includes(query)) {
      // if (query?.toLowerCase().includes(firstThree?.toLowerCase())) {
      if (
        element[attribute_name]?.toLowerCase().includes(query?.toLowerCase())
      ) {
        val.splice(index, 1);
        val.push(element);
      }
    });
  return val;
};

export function orderAlphabaticallyByAttribute(
  val,
  attribute_name: string,
  lang = 'es'
) {
  if (Array.isArray(val)) {
    // if (lang === 'es')
    val = val.sort(function (a, b) {
      return a[attribute_name].normalize('NFD').toLowerCase() ===
        b[attribute_name].normalize('NFD').toLowerCase()
        ? 0
        : a[attribute_name].normalize('NFD').toLowerCase() <
          b[attribute_name].normalize('NFD').toLowerCase()
        ? -1
        : 1;
    });
    // else
    //   val = val.sort(function (a, b) {
    //     return a[attribute_name + '_' + lang]
    //       ?.normalize('NFD')
    //       ?.toLowerCase() ===
    //       b[attribute_name + '_' + lang]?.normalize('NFD').toLowerCase()
    //       ? 0
    //       : a[attribute_name + '_' + lang]?.normalize('NFD').toLowerCase() <
    //         b[attribute_name + '_' + lang]?.normalize('NFD').toLowerCase()
    //       ? -1
    //       : 1;
    //   });
  }
  return val;
}
