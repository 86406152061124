import { Injectable } from '@angular/core';
import {
  PopoverController,
  ToastController,
  ModalController,
  AnimationController,
} from '@ionic/angular';
@Injectable({ providedIn: 'root' })
export class ToastUtils {
  // modalController;
  constructor(
    private modalController: ModalController,
    private popoverController: PopoverController,
    private animationCtrl: AnimationController,
    private toastController: ToastController
  ) {}
  enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = this.animationCtrl
      .create()
      .addElement(root.querySelector('.modal-wrapper')!)
      .keyframes([
        { offset: 0, opacity: '0', transform: 'scale(0)' },
        { offset: 1, opacity: '0.99', transform: 'scale(1)' },
      ]);

    return this.animationCtrl
      .create()
      .addElement(baseEl)
      .easing('ease-out')
      .duration(500)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  leaveAnimation = (baseEl: HTMLElement) => {
    return this.enterAnimation(baseEl).direction('reverse');
  };
  async toastBuilder(
    message,
    position,
    header,
    color = 'dark',
    duration = 1000
  ) {

    let toast = await this.toastController.create({
      duration: 1000,
      animated: false,
      header: header,
      message: message,
      position: 'top',
      color: 'dark',
      mode: 'md',
    });
    await toast.present();

  }

  async popOverBuilder(
    // popoverController,
    componentProps,
    component,
    cssClass,
    event = null,
    isBackdropDismiss = true,
    isPopOverPresent = true
  ): Promise<HTMLIonPopoverElement> {
    const popover = await this.popoverController.create({
      component: component,
      cssClass: cssClass,
      event: event,
      animated: true,
      componentProps: componentProps,
      backdropDismiss: isBackdropDismiss,
    });
    if (isPopOverPresent) {
      popover.present();
    }

    return popover;
  }

  async modalBuilder(
    component,
    componentProps = {},
    cssClass = ['my-custom-modal-css', 'modal-wrapper'],
    showBackdrop = true,
    id = null
  ): Promise<HTMLIonModalElement> {
    const modal = await this.modalController.create({
      component: component,
      cssClass: cssClass,
      showBackdrop: showBackdrop,
      animated: true,
      componentProps: componentProps,
      id: id,
      // enterAnimation:this.enterAnimation ,
      // leaveAnimation:this.leaveAnimation
    });
    modal.present();
    return modal;
    //  return popover;
  }
}
