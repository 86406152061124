import { PopoverController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-popover-checklist',
  templateUrl: './popover-checklist.component.html',
  styleUrls: ['./popover-checklist.component.scss'],
})
export class PopoverChecklistComponent implements OnInit {
  @Input() data:any;
  @Input() title;
  arrayToCheck=[];
  constructor(public popover: PopoverController) { }
 
  langage_package;
  selectedValue="";
  ngOnInit() {
    if(this.data){
      this.data.forEach(element => {
          this.arrayToCheck.push({value : element , isChecked: false})
      });
    } 
  }
  ClosePopover() {
    this.popover.dismiss({selectedValue : ""});
  }
  save(){
    this.popover.dismiss({selectedValue : this.selectedValue});
  }

}
