import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-production-loss-widget',
  templateUrl: './production-loss-widget.component.html',
  styleUrls: ['./production-loss-widget.component.scss'],
})
export class ProductionLossWidgetComponent implements OnInit, OnChanges {
  @Input() summary;
  @Input() name;

  @Input() productionTotal = 0;
  @Input() perdidasTotal = 0;
  MAX_PERCENT = 500000000; // to discuss with ines
  perdidasPercent;
  prodduccionPercent;
  constructor() {}

  ngOnInit() {
    this.calculate();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.calculate();
  }
  calculate() {
  
    this.prodduccionPercent =
      Math.abs(this.productionTotal / this.MAX_PERCENT) * 100;
    this.perdidasPercent =
      Math.abs(this.perdidasTotal / this.MAX_PERCENT) * 100;
  }
}
