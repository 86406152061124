import { Directive, ElementRef, Input, NgZone } from '@angular/core';
import { CashFlow } from '../models/cash-flow/cashFlow';

@Directive({
  selector: '[appAutofocus]',
})
export class AutofocusDirective {
  private screenHeight = 0;
  private oldScroll = 0;
  @Input('appAutofocus') content: any;
  @Input('openedFromCashflow') openedFromCashflow=false

  constructor(private elementRef: ElementRef, private ngZone: NgZone) {}
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }
  ngDoCheck(): void {
    const contentHeight = this.elementRef.nativeElement.scrollHeight;
    if (
      !this.content||
      !this.openedFromCashflow &&
      (!this.content?.id ) ||
      (this.content?.id &&
        this.content?.production_per_farm &&
        !this.content?.form_answer && !this.content?.accounting_entry_categories)
    ) {

      let interval = setTimeout(() => {
        if (contentHeight != this.oldScroll) {

          this.elementRef?.nativeElement?.scroll({
            top: contentHeight + 700,
            behavior: 'smooth',
          });
          this.oldScroll = contentHeight;

          // clearTimeout(interval);
        }
      }, 300);
    }
  }
}
