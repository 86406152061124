import { cloneDeep } from 'lodash';
import {
  addPlot,
  addPlotSuccess,
  deletePlot,
  deletePlotSuccess,
  loadPlots,
  loadPlotsSuccess,
  updatePlot,
  updatePlotSuccess,
} from './../actions/plots.action';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { Lot } from 'src/app/models/lots.model';

export interface PlotState extends EntityState<Lot> {
  // additional entity state properties
  selectedPlotId: number | null;
  loading: boolean;
  error: any;
}

export function selectPlotById(a: Lot): number {
  //In this case this would be optional since primary key is id
  return a.id || new Date().getTime();
}
export const plotsAdapter: EntityAdapter<Lot> = createEntityAdapter<Lot>(
  {
    selectId: selectPlotById,
  }
);
export const initialState: PlotState = plotsAdapter.getInitialState({
  // additional entity state properties
  selectedPlotId: null,
  loading: false,
  error: null,
});


export const PlotsSelector =
  createFeatureSelector<PlotState>('plots');

export const selectAllPlots = createSelector(
  PlotsSelector,
  plotsAdapter.getSelectors().selectAll
);

export const plotsReducer = createReducer(
  initialState,
  on(loadPlots, (state, action) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),
  on(loadPlotsSuccess, (state, action) => {
    return plotsAdapter.setAll(action.plots, {
      ...state,
      loading: false,
    });
  }),
  on(addPlot, (state, action: any) => {
    let plot = cloneDeep(action.data);
    plot.id = 'offline-'+action?.meta?.offlineId;
    return plotsAdapter.addOne(plot, {
      ...state,
      loading: true,
    });
  }),
  on(addPlotSuccess, (state, action: any) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  }),
  on(updatePlot, (state, action: any) => {
    let updatedDate = cloneDeep(action.data);
    updatedDate.offlineOperationId = action?.meta?.offlineId;
    return plotsAdapter.updateOne(
      { id: action.data.id, changes: updatedDate },
      {
        ...state,
        loading: true,
      }
    );
  }),

  on(updatePlotSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  }),
  on(deletePlot, (state, action) => {
    return plotsAdapter.removeOne(action.lot.id, {
      ...state,
      loading: true,
    });
  }),
  on(deletePlotSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  })
);
