import { selectAllFamilyMembers } from 'src/app/utils/states-management/selector/family-members.selector';
import { Family_member } from 'src/app/models/family_member.model';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { calculateAge } from 'src/app/utils/pipes-utils/date_formatter';
import { Component, Input, OnInit, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalPageComponent } from '../modal-page/modal-page.component';
import { Family_membersAPI } from '../../../apis/family_members.service';
import { Router } from '@angular/router';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../config/offlines_keys.json';
import routes from '../../../config/routing.json';
import { PriorDashboardComponent } from '../../prior-dashboard/prior-dashboard.component';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  deleteFamilyMember,
  loadFamilyMember,
} from 'src/app/utils/states-management/actions/family-members.action';
import Owner from 'src/app/models/owner.model';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-family-view-two',
  templateUrl: './family-view-two.component.html',
  styleUrls: ['./family-view-two.component.scss'],
  providers: [Family_membersAPI],
})
@Injectable({
  providedIn: 'root',
})
export class FamilyViewTwoComponent implements OnInit {
  title;
  family_list_add_btn;
  owner_id: number;
  next;
  return;
  isDashboardView;
  list: any = [];
  main_endpoint = environment.appRoot;
  @Input() nav;
  @Input() rootStack;
  year;
  token;
  owner;
  currentLang;
  constructor(
    public modalController: ModalController,
    private router: Router,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private routingConfig: RoutingConfig,
    private store: Store<{
      owner: Owner;
    }>
  ) {}

  async presentModal() {
    let modal = await this.toastUtils.modalBuilder(ModalPageComponent);
    modal.onDidDismiss().then(async (value) => {
      if (value?.data?.person) {
        if (!this.list) this.list = [];
        this.store.dispatch(loadFamilyMember());
      }
    });
  }

  async presentupdateModal(item: Family_member) {
    let modal = await this.toastUtils.modalBuilder(
      ModalPageComponent,
      {
        id: item.id,
        oldFamilyMember: item,
      },
      ['my-custom-modal-css', 'modal-wrapper']
    );

    modal
      .onDidDismiss()
      .then(() => {
        this.store.dispatch(loadFamilyMember());
      })
      .catch(console.log);
  }

  async getowner_id() {
    this.owner = await lastValueFrom(this.store.select('owner').pipe(take(1)))
    this.owner_id = this.owner?.id
  }

  async ngOnInit() {
    await this.getowner_id();
    this.store.select(selectAllFamilyMembers).subscribe((value) => {
      this.list = value;
    });
    this.store.dispatch(loadFamilyMember());
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
    this.currentLang = this.translateService.currentLang;
  }

  async delete(id: number) {
    this.store.dispatch(deleteFamilyMember({ id }));
  }

  async mreturn() {
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());
  }

  async mnext() {
    this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }

  submitForm() {
    this.nav.setRoot(this.rootStack, {
      nav: this.nav,
      rootStack: PriorDashboardComponent,
    });
  }
}
