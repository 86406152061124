import { Component, Input, OnInit } from '@angular/core';
import { MoneyReportingsAPI } from 'src/app/apis/money_reportings.service';
@Component({
  selector: 'app-pension-plan-updateder',
  templateUrl: './pension-plan-updateder.component.html',
  styleUrls: ['./pension-plan-updateder.component.scss'],
})
export class PensionPlanUpdatederComponent implements OnInit {
  @Input() plan_pension;
  @Input() modalController;
  constructor(private moneyReportingsAPI: MoneyReportingsAPI) {}

  async ngOnInit() {}

  setContribution(event) {
    this.plan_pension.pension_plan_objective = event;
  }

  async save() {
    this.moneyReportingsAPI.updateMoneyReporting(this.plan_pension).subscribe(
      (val) => this.modalController.dismiss(),
      (e) => {
        this.modalController.dismiss();
      }
    );
  }

  cancel() {
    this.modalController.dismiss();
  }
}
