import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product-animal-interface',
  templateUrl: './product-animal-interface.component.html',
  styleUrls: ['./product-animal-interface.component.scss'],
})
export class ProductAnimalInterfaceComponent implements OnInit {
  constructor(public translateService: TranslateService) {}
  @ViewChild('tabGroup') matTab: MatTabGroup;
  @Input() nav;
  ngOnInit() {}
  tabChanged(e) {}
}
