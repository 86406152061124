import { Component, Input, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ProductAPI } from 'src/app/apis/product.service';
import { orderAlphabaticallyByAttribute } from 'src/app/utils/array-filtrer';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { dev } from '../../../../config/offlines_keys.json';
import { Store } from '@ngrx/store';
import { getProductGenetics } from 'src/app/utils/states-management/selector/countries.selector';
import { take } from 'rxjs';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-coffe-gen-selector',
  templateUrl: './coffe-gen-selector.component.html',
  styleUrls: ['./coffe-gen-selector.component.scss'],
})
export class CoffeGenSelectorComponent implements OnInit {
  @Input() isDashboardView;
  @Input() modalController: ModalController;
  @Input() previouslySelected = new Array();
  @Input() production;
  title = 'Variedades de café cultivadas';
  displayProducts = new Array();

  constructor(
    private productAPI: ProductAPI,
    private routingConfig: RoutingConfig,
    public translateService: TranslateService,
    public store:Store
  ) {}

  async ngOnInit() {
    const cropConfig = await this.routingConfig.getCropFormConfig();
    this.store.select(getProductGenetics).pipe(take(1))
      .subscribe((categories: any) => {
        this.displayProducts = orderAlphabaticallyByAttribute(
          cloneDeep(categories),
          'name',
          this.translateService.currentLang
        );
        if (
          Array.isArray(this.previouslySelected) &&
          this.previouslySelected.length > 0
        ) {
          this.displayProducts.forEach((element) => {
            if (this.previouslySelected.some((item) => item.id == element.id)) {
              element.is_selected = true;
            }
          });
        }
      });


    this.title = cropConfig?.crops_form.find(
      (value) =>
        value.crop_id == this.production?.morphable_id &&
        this.production.morphable_type.toLowerCase().includes(value.crop_entity)
    ).title;
  }

  reset() {
    this.displayProducts.forEach((element) => {
      element.is_selected = false;
      element.percent = 0;
    });
  }

  cancel() {
    this.modalController.dismiss({ canceled: true });
  }

  submit() {
    const selectedProd = this.displayProducts.filter(
      (element) => element.is_selected
    );
    this.modalController.dismiss({ canceled: false, products: selectedProd });
  }
}
