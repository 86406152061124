import { LicenseAgreementComponent } from './license-agreement/license-agreement.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TermsModalPageRoutingModule } from './terms-modal-routing.module';

import { TermsModalPage } from './terms-modal.page';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ModalHeaderComponent } from 'src/app/widgets/modal-header/modal-header.component';
import { FooterModule } from 'src/app/widgets/footer-component/header-footer.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TermsModalPageRoutingModule,
    FooterModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    TermsModalPage,
    LicenseAgreementComponent,
    PrivacyPolicyComponent
  ],
  exports: [TermsModalPage, LicenseAgreementComponent, PrivacyPolicyComponent],
})
export class TermsModalPageModule {}
