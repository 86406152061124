import { HttpClient } from '@angular/common/http';
import { dev } from '../config/endpoints.json';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Employee from '../models/employee.model';
import Salarie from '../models/salarie.model';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class EmployeesAPI {
  base_url = environment.appRoot + '/api/';

  constructor(private http: HttpClient) {}

  updateEmployee(employee: Employee): Observable<Employee> {
    const id = employee.id;

    const url = this.base_url + dev.endpoint.employees.updateEmployee;
    const formData = new FormData();
    formData.append('is_permanent', employee.is_permanent);
    formData.append('is_receiving_salary', employee.is_receiving_salary);
    formData.append('is_admin', employee.is_admin);
    formData.append('sex', employee.sex);
    formData.append('name', employee.name);
    formData.append('morphable_type', employee.morphable_type);
    formData.append('morphable_id', employee.morphable_id);

    return this.http.post(url, formData);
  }

  getPermanentOrTomporalData(): Observable<Employee> {
    const url =
      this.base_url +
      dev.endpoint.employees.employees +
      dev.endpoint.employees.get_data_employee;

    return this.http.get(url);
  }

  deleteEmployee(data): Observable<Employee> {
    const url =
      this.base_url +
      dev.endpoint.employees.employees +
      dev.endpoint.employees.delete_employee;
    const formData = new FormData();
    formData.append('morphable_type', data.employee.morphable_type);
    formData.append('morphable_id', data.employee.morphable_id);

    return this.http.post(url, formData);
  }

  saveSalary(salary: Salarie): Observable<any> {
    const url = this.base_url + dev.endpoint.employees.save_salary;
    const formData = new FormData();
    formData.append('salary_amount', salary.salary_amount?.toString());
    formData.append('working_hours', salary.working_hours?.toString());
    formData.append('admin_hour', salary.admin_hour?.toString());
    formData.append('coffe_hour', salary.coffe_hour?.toString());
    formData.append('other_hour', salary.other_hour?.toString());
    formData.append('extra', salary.extra ? salary.extra : 'N/A');
    formData.append(
      'social_charges_amount',
      salary.social_charges_amount?.toString()
    );
    formData.append('payment_type', salary.payment_type);
    formData.append('journals_number', salary.journals_number?.toString());

    if (salary.unit && salary.quantity_product) {
      formData.append('quantity_product', salary.quantity_product?.toString());
      formData.append('unit', salary.unit.toString());
    }
    if (salary.employee_id)
      formData.append('employee_id', salary.employee_id?.toString());

    if (salary.total_paid)
      formData.append('total_paid', salary.total_paid?.toString());
    return this.http.post(url, formData);
  }

  saveEmployee(data): Observable<any> {
    const url = this.base_url + dev.endpoint.employees.saveEmployee;
    const formData = new FormData();
    let { employee } = data;
    formData.append('is_permanent', employee.is_permanent);
    formData.append('is_receiving_salary', employee.is_receiving_salary);
    formData.append('is_admin', employee.is_admin);
    formData.append('sex', employee.sex);
    formData.append('name', employee.name);
    formData.append('morphable_type', employee.morphable_type);
    formData.append('morphable_id', employee.morphable_id);
    return this.http.post(url, formData);
  }
  storeEmployeeProfilePhoto(id, file: Blob) {
    const url = this.base_url + dev.endpoint.employees.store_employee_photo;
    const formData = new FormData();
    formData.append('image', file, new Date().getUTCMilliseconds().toString());
    formData.append('id', id);
    return this.http.post(url, formData);
  }

  updateEmployeeMemberPhoto(id, file: Blob) {
    const url = this.base_url + dev.endpoint.employees.update_employee_photo;
    const formData = new FormData();
    formData.append('image', file, new Date().getUTCMilliseconds().toString());
    formData.append('id', id);
    return this.http.post(url, formData);
  }

  getFamiliarLarbor() {
    const url = this.base_url + dev.endpoint.employees.get_familiar_larbor;
    return this.http.get(url);
  }

  updateSalary(salary: Salarie) {
    const url = this.base_url + dev.endpoint.employees.update_salary;
    const formData = new FormData();
    formData.append('payment_type', salary.payment_type);
    formData.append('id', salary.id.toString());
    formData.append('salary_amount', salary.salary_amount?.toString());
    formData.append('extra', salary.extra);
    formData.append('journals_number', salary.journals_number?.toString());
    formData.append(
      'social_charges_amount',
      salary.social_charges_amount?.toString()
    );
    if (salary.unit && salary.quantity_product) {
      formData.append('quantity_product', salary.quantity_product?.toString());
      formData.append('unit', salary.unit.toString());
    }
    if (salary.total_paid)
      formData.append('total_paid', salary.total_paid?.toString());
    return this.http.post(url, formData);
  }

  deleteSalary(id) {
    const url = this.base_url + dev.endpoint.employees.delete_salary + '/' + id;
    return this.http.get(url);
  }
}
