import { ToastUtils } from 'src/app/utils/toast_builder';
import { Router } from '@angular/router';
import { AnimalesComponent } from '../../../farm/animals/animals/animals.component';
import { CoffeProductionViewComponent } from '../../coffee-production-view/coffee-production-view.component';
import { PreProductSelectorComponent } from 'src/app/modules/farm/others-agricol-product-components/pre-product-selector/pre-product-selector.component';
import { Component, Input, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import {
  IonNav,
  LoadingController,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { MaterialProductAPI } from 'src/app/apis/material_product.service';
import { ProductAPI } from 'src/app/apis/product.service';
import {
  ANIMAL_IDS,
  CAFE_IDS,
  OTROS_ACCOUNTING_ENTRY_CATEGORY,
} from 'src/app/utils/consts';
import { showConfirmModal } from 'src/app/utils/pop_over_maker';
import { dev } from '../../../../config/offlines_keys.json';
import routes from '../../../../config/routing.json';
import { BookComponent } from '../book/book.component';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';
import { Good } from 'src/app/models/good.model';
import { GoodsAPI } from 'src/app/apis/goods.service';
import { TranslateService } from '@ngx-translate/core';
import { isArbolesTransaction } from 'src/app/models/cash-flow/cashFlow';
import { lastValueFrom, take } from 'rxjs';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-production-sales-other',
  templateUrl: './production-sales-other.component.html',
  styleUrls: ['./production-sales-other.component.scss'],
})
export class ProductionSalesOtherComponent implements OnInit {
  @Input() product: ProductionsPerfarm;
  farm;
  filtreListProduccion: any[];
  filtreListVentas: any[];
  filtreListPerdidas: any[];

  @Input() nav: IonNav;
  @Input() rootStack;
  isDashboardView;
  selectedPorduct;
  filtreListPurchases;
  allCashflows: any[];
  loadingModal: HTMLIonLoadingElement;
  is_loading = false;
  lang;
  is_arboles = false;
  is_animal = false;
  title_label;
  trees_update;
  constructor(
    private pogressModalController: LoadingController,
    private popoverController: PopoverController,
    private modalController: ModalController,
    private productAPI: ProductAPI,
    private materialProductAPI: MaterialProductAPI,
    private goodAPI: GoodsAPI,
    private router: Router,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private store:Store
  ) {}

  async ngOnInit() {
    this.product.goods?.forEach((good: Good) => {
      good.formConfig = {
        is_qty_editable: false,
        is_unit_editable: false,
      };
    });

    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
    this.lang = this.translateService.currentLang;
    this.loadingModal = await this.pogressModalController.create({});

    this.farm =await lastValueFrom(
      this.store.select(getFarm).pipe(take(1))
    );

    if (!this.product.is_animal) {
      this.selectedPorduct = this.product.morphable;
      this.selectPorduct({
        good: this.product.goods[0],
        boolean: false,
        name: this.translateService.instant(
          'product.' + this.product.morphable_id
        ),
      });
    }
  }

  fetchCashflows() {
    this.is_loading = true;
    if (this.selectedPorduct && !this.product.is_animal)
      this.productAPI
        .getCashflowByProductId(this.selectedPorduct.product_id)
        .subscribe(
          (value: any) => {
            this.is_loading = false;
            this.allCashflows = value;
            this.changeList(
              value.filter((elment) => !isArbolesTransaction(elment))
            );
          },
          (e) => {
            this.is_loading = false;
          }
        );
    else if (this.selectedPorduct && this.product.is_animal)
      this.materialProductAPI
        .getCashflowByMaterialProductId(
          this.selectedPorduct.material_product.id
        )
        .subscribe(
          (value: any) => {
            this.is_loading = false;
            this.changeList(value);
          },
          (e) => {
            this.is_loading = false;
          }
        );
  }

  changeList(val) {
    this.filtreListPurchases = val.filter(
      (cashflow) =>
        cashflow.cashflow_categories.is_a_loss === null &&
        cashflow.cashflow_categories.is_spent === 1
    );

    this.filtreListPerdidas = val.filter(
      (cashflow) =>
        cashflow.cashflow_categories.is_a_loss === 1 &&
        cashflow.cashflow_categories.is_spent === null
    );
    this.filtreListProduccion = val.filter(
      (cashflow) =>
        cashflow.cashflow_categories.is_a_loss === 0 &&
        cashflow.cashflow_categories.is_spent === null
    );

    this.filtreListVentas = val.filter(
      (cashflow) =>
        cashflow.cashflow_categories.is_a_loss === null &&
        cashflow.cashflow_categories.is_spent === 0
    );
  }

  selectPorduct(event) {
    this.is_arboles = false;
    this.is_animal = false;
    let material_product = event.good;
    let is_coffe_tree = event.boolean ? event.boolean : false;
    let label = event.name ? event.name : '';

    if (!label) {
      this.title_label = this.translateService.instant(
        'material_products.' + material_product.id
      );
    } else {
      this.title_label = label;
    }

    this.selectedPorduct = material_product;
    if (material_product.material_product_id) {
      this.selectedPorduct.name = material_product?.material_product?.name;
      this.is_animal = ANIMAL_IDS.includes(
        this.selectedPorduct.material_product_id
      );
    }

    this.selectedPorduct.is_coffe_tree = is_coffe_tree;
    this.fetchCashflows();
  }

  async updateGood(good: Good) {
    this.loadingModal.present();

    this.goodAPI.updateGood(good).subscribe(
      (value) => {
        this.loadingModal.dismiss();
      },
      async (e) => {
        this.loadingModal.dismiss();
      }
    );
  }

  materialProductUnitChange(event) {
    let good = new Good();
    good.qty = event.new_qty;
    good.unit = event.materialProductUnit;
    good.id = event.good.id;
    this.updateGood(good);
  }

  async getCashflowRequestConfirmation(): Promise<Boolean> {
    const modal = await showConfirmModal(
      this.popoverController,
      await this.translateService.get('add_cashflow').toPromise(),
      null,
      'Sí',
      'No'
    );
    const { data } = await modal.onWillDismiss();
    return data?.confirmed;
  }

  async updateAnimal(good: Good) {
    // if (this.isDashboardView && !good.formConfig.is_qty_editable) {
    //   const confirm = await this.getCashflowRequestConfirmation();
    //   if (confirm) {
    //     await this.showDialogCashflow(
    //       Unif
    //       CAFE_IDS.includes(good.product_id) ? await this.getCategories(1) : []
    //     );
    //     this.nav.setRoot(BookComponent, {
    //       nav: this.nav,
    //     });
    //   } else {
    //     good.formConfig.is_qty_editable = !good.formConfig.is_qty_editable;
    //   }
    // } else if (this.isDashboardView && good.formConfig.is_qty_editable) {
    //   this.updateGood(good);
    //   good.formConfig.is_qty_editable = !good.formConfig.is_qty_editable;
    // }
  }

  async updateTrees(good: Good) {
    // if (this.isDashboardView && !this.trees_update) {
    //   const confirm = await this.getCashflowRequestConfirmation();
    //   if (confirm) {
    //     await this.showDialogCashflow(
    //       CAFE_IDS.includes(good.product_id)
    //         ? CoffeeInputComponent
    //         : OtrosInputComponent,
    //       CAFE_IDS.includes(good.product_id) ? await this.getCategories(1) : []
    //     );
    //     this.nav.setRoot(BookComponent, {
    //       nav: this.nav,
    //     });
    //   } else {
    //     this.trees_update = !this.trees_update;
    //   }
    // } else if (this.isDashboardView && this.trees_update) {
    //   this.updateGood(good);
    //   this.trees_update = !this.trees_update;
    // }
  }

  async showDialogCashflow(comp, cashFlowCategFiltred) {
    let componentProps = {
      cashFlowEntrieName: OTROS_ACCOUNTING_ENTRY_CATEGORY,
      cashFlowCategFiltred: cashFlowCategFiltred,
      modalController: this.modalController,
    };
    let modal = await this.toastUtils.modalBuilder(
      comp,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper'],
      true,
      '1'
    );
    return modal.onWillDismiss();
  }

  async fetchArboles() {
    this.title_label = await this.translateService.get('trees').toPromise();
    this.is_arboles = true;
    this.changeList(
      this.allCashflows.filter((element) => isArbolesTransaction(element))
    );
  }

  async getCategories(cashFlowEntrieName) {
    let categories = JSON.parse(
      (await Storage.get({ key: dev.CASHFLOW_CATEGORIES })).value
    );
    return categories[cashFlowEntrieName.toString()];
  }

  backToProductionView(event) {
    if (this.product.is_animal)
      this.nav.setRoot(AnimalesComponent, {
        nav: this.nav,
      });
    else if (!this.product.is_animal && this.product.is_crop)
      this.nav.setRoot(CoffeProductionViewComponent, {
        nav: this.nav,
      });
    else
      this.nav.setRoot(PreProductSelectorComponent, {
        nav: this.nav,
      });
  }
}
