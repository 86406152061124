import { addEmployee, addEmployeeSuccess } from './../actions/employee.action';
import { Store } from '@ngrx/store';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap, filter, shareReplay, take, mergeMap } from 'rxjs/operators';

import { nextOfflineIndex } from '../reducers/offline.reducer';
import { UpdateSync } from '../actions/offline.action';
import {
  deleteEmployee,
  deleteEmployeeSuccess,
  updateEmployee,
  updateEmployeeSuccess,
} from '../actions/employee.action';
import { EmployeesAPI } from 'src/app/apis/employees.service';
import { addOwnerEmployee, deleteOwnerEmployee } from '../actions/owner.action';

@Injectable()
export class EmployeeEffect {
  deleteEmployee$ = createEffect(() =>
    this.actions.pipe(
      ofType(deleteEmployee,deleteOwnerEmployee),
      switchMap((action:any) => {
        return this.employeeAPI.deleteEmployee(action.data);
      }),
      map((value) => {
        return deleteEmployeeSuccess({ id: value?.id });
      })
      // catchError((err) => of(dataSyncWithServerError(err)))
    )
  );

  updateEmployee$ = createEffect(() =>
    this.actions.pipe(
      ofType(updateEmployee),
      switchMap((action) => {
        return this.employeeAPI.updateEmployee(action.data).pipe(
          map((result) => {
            return { result };
          })
        );
      }),

      map(({ result }) => {
        return updateEmployeeSuccess({ data: result });
      })
    )
  );

  addEmployee$ = createEffect(() =>
    this.actions.pipe(
      ofType(addEmployee,addOwnerEmployee),
      switchMap((action) => {
        return this.employeeAPI.saveEmployee(action.data);
      }),
      switchMap(async (result : any) => {
        return addEmployeeSuccess({
          data: result,
          offlineId: await this.store
            .select(nextOfflineIndex)
            .pipe(take(1))
            .toPromise(),
        });
      })
    )
  );
  // addEmployeeSuccess$ = createEffect(() =>
  //   this.actions.pipe(
  //     ofType(addEmployeeSuccess),
  //     map((action: any) => {
  //       console.log(action)
  //       return UpdateSync({
  //         data: action.data,
  //         offlineId: action.offlineId,
  //         stateName: 'familyMembers',
  //       });
  //     })
  //   )
  // );

  constructor(
    private employeeAPI: EmployeesAPI,
    private actions: Actions,
    public translateService: TranslateService,
    private store: Store<{ connection: any; familyMembers: any }>
  ) {
    // this.state$ = this.store.select('familyMembers').pipe(
    //   filter(Boolean), // Ignore the initial null state
    //   shareReplay(1) // Share the state across multiple subscribers
    // );
  }
}
