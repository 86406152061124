import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  DoCheck,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Storage } from '@capacitor/storage';
import { IonNav, ModalController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { from, lastValueFrom, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { CashflowAPI } from 'src/app/apis/cashflow.service';
import { EmployeesAPI } from 'src/app/apis/employees.service';
import { MyPartnersAPI } from 'src/app/apis/partners.service';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import { OtrosDisplayableListItem } from 'src/app/models/cash-flow/otros_displayable_list_item';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';
import Partner from 'src/app/models/partner.model';
import { SecondCellCertif } from 'src/app/models/seconde_cell_certif';
import { SalaryBusinessRules } from 'src/app/utils/business-rules/salary-related-cashflow';
import {
  AHOROS_ACCOUNTING_ENTRY_CATEGORY,
  ANIMALES_ACCOUNTING_ENTRY_CATEGORY,
  CAFE_ACCOUNTING_ENTRY_CATEGORY,
  CAFE_IDS,
  CASHFLOW_FIRST_SALE_COFFEE_ID,
  CASHFLOW_SECOND_COFFEE_SELLS_ID,
  COCOA_ACCOUNTING_ENTRY_CATEGORY,
  HARVEST_ACTIVITY,
  MORPH_Salarie,
  OTROS_ACCOUNTING_ENTRY_CATEGORY,
  PERSONAL_ACCOUNTING_ENTRY_CATEGORY,
  PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY,
} from 'src/app/utils/consts';
import { PickerOptionFormater } from 'src/app/utils/pipes-utils/date_formatter';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { dev } from '../../../config/offlines_keys.json';
import { ModalPartnerComponent } from '../../partner/modal-partner/modal-partner.component';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { PreProductSelectorComponent } from '../../farm/others-agricol-product-components/pre-product-selector/pre-product-selector.component';
import { AnimalesComponent } from '../../farm/animals/animals/animals.component';
import { CategoriesSelectionPopoverComponent } from '../../dashboard/widgets/categories-selection-popover/categories-selection-popover.component';
import {
  mapLangeTitleObjects,
  orderAlphabaticallyByAttribute,
} from 'src/app/utils/array-filtrer';
import { Store } from '@ngrx/store';
import { cloneDeep, isSet } from 'lodash';
import {
  clearCashflowSelection,
  paginateCashflow,
  saveCashflow,
  updateCashflow,
  updateCashflowSuccess,
} from 'src/app/utils/states-management/actions/cashflow.action';
import { AccountingEntryCategory } from 'src/app/models/cash-flow/cashflow_categories';
import { Unit } from 'src/app/models/Unit';
import {
  selectAllUnits,
  selectUnitsByType,
} from 'src/app/utils/states-management/reducers/units.reducer';
import { LOAD_UNITS } from 'src/app/utils/states-management/actions/units-action';
import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import { element } from 'protractor';
import { loadOtrosListableItems } from 'src/app/utils/states-management/actions/prodcuction-cashflow-categories.action';
import {
  agriProductSelector,
  selectedAgriProduct,
} from 'src/app/utils/states-management/reducers/agri-product.reducer';
import {
  selectAllAnimals,
  selectedAnimalProducts,
} from 'src/app/utils/states-management/reducers/animals.reducer';
import { selectAllPartners } from 'src/app/utils/states-management/selector/partners.selector';
import { loadPartner } from 'src/app/utils/states-management/actions/partner.action';
import { ProductAnimalInterfaceComponent } from '../../home/widgets/product-animal-interface/product-animal-interface.component';
import { loadSelectedAgriProduct } from 'src/app/utils/states-management/actions/agri-product.action';
import { loadSelectedAnimal } from 'src/app/utils/states-management/actions/animals.action';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { AnimalesFormComponent } from '../../farm/animals/animals-form/animals-form.component';
import { ProductFormComponent } from '../../farm/others-agricol-product-components/product-form/product-form.component';

interface FormConfig {
  is_egresos_needed: boolean;
  is_ingresos_needed: boolean;
  is_activities_showable: boolean;
  is_show_partner: boolean;
  is_show_products_categories: boolean;
  is_show_type_transaction_button: boolean;
  is_show_certifs_1: boolean;
  is_show_certifs_2: boolean;
  show_comment: boolean;
  has_certification: number;
  is_show_confirm_button: boolean;
  show_payment_form: boolean;
  labels: any;
}
@Component({
  selector: 'app-unified-cashflow-input-component',
  templateUrl: './unified-cashflow-input-component.component.html',
  styleUrls: ['./unified-cashflow-input-component.component.scss'],
})
export class UnifiedCashflowInputComponentComponent
  implements OnInit, DoCheck, OnChanges
{
  @Input() accountingEntryCategories: AccountingEntryCategory;
  @Input() cashflow: CashFlow = new CashFlow();
  @Input() is_salary_needed = false;
  @Input() popOverContent = '';
  @Input() nav: IonNav;
  @ViewChild('content') content: any;
  units: Array<Unit> = [];
  current_required_form_json;
  customPickerOptions: any;
  displayableCategories;
  partnerList: Array<Partner>;
  selected_partner: Partner;
  // cashFlowCategFiltred: Array<OtrosDisplayableListItem>;
  // TODO change any with animal and
  cashFlowCategFiltred: any;
  selected_production: ProductionsPerfarm;
  custom_product_name = null;
  country;
  selectedCertif;
  formConfigs;
  selected_certifications = '';
  selected_certifications_2 = '';
  secondCellCertif: SecondCellCertif = new SecondCellCertif();
  formConfig: FormConfig = {
    is_egresos_needed: false,
    is_ingresos_needed: false,
    is_activities_showable: false,
    is_show_partner: false,
    is_show_products_categories: false,
    is_show_type_transaction_button: true,
    is_show_certifs_1: false,
    is_show_certifs_2: false,
    show_comment: false,
    has_certification: 0,
    is_show_confirm_button: false,
    show_payment_form: false,
    labels: null,
  };
  loadingModal;
  certifications;
  isCharchingContaint;
  constructor(
    public translateService: TranslateService,
    private pickerOption: PickerOptionFormater,
    private modalController: ModalController,
    private cashflowAPI: CashflowAPI,
    private toastUtils: ToastUtils,
    private partnersAPI: MyPartnersAPI,
    private salaryBusinessRules: SalaryBusinessRules,
    private employeeAPI: EmployeesAPI,
    private changeDetector: ChangeDetectorRef,

    private store: Store<{
      cashflow: CashFlow;
      agriculturalProduct: Array<OtrosDisplayableListItem>;
    }>,
    private loadingCtrl: LoadingController
  ) {}
  showContent = false;
  async ngOnInit() {
    if (this.cashflow?.id) {
      this.loadingModal = await this.loadingCtrl.create({
        spinner: 'bubbles',
        mode: 'ios',
      });
      this.loadingModal.present();
      var temp = 500;

      let interval = setInterval(async () => {
        var currentHeight = document.getElementById('content')?.scrollHeight;
        if (currentHeight == this.oldContentHeight) {
          await this.loadingModal.dismiss();
          clearInterval(interval);
          this.showContent = true;
        } else {
          this.oldContentHeight = currentHeight;
        }
      }, temp);
    } else {
      this.showContent = true;
    }

    if (this.popOverContent) {
      this.toastUtils.popOverBuilder(
        {
          key1: this.popOverContent,
        },
        PopoverComponent,
        'popover-custom'
      );
    }
    this.store
      .select(
        selectUnitsByType(
          this.current_required_form_json?.external_model_required,
          this.translateService
        )
      )
      .subscribe((value) => (this.units = cloneDeep(value)));
    if (this.is_salary_needed) this.egresosClickedEvent();

    this.country = JSON.parse((await Storage.get({ key: dev.COUNTRY })).value);
    this.customPickerOptions = this.pickerOption.custom_picker_options(
      this.cashflow,
      'date'
    );

    this.formConfig.is_egresos_needed =
      this.accountingEntryCategories.cashflow_category.filter(
        (element) => element.is_spent === 1
      )?.length > 0;

    this.formConfig.is_ingresos_needed =
      this.accountingEntryCategories.cashflow_category.filter(
        (element) => element.is_spent === 0
      )?.length > 0;

    if (
      [AHOROS_ACCOUNTING_ENTRY_CATEGORY].includes(
        this.accountingEntryCategories.id
      )
    ) {
      this.formConfig.is_show_type_transaction_button = false;
      this.egresosClickedEvent();
    }

    if (
      [
        ANIMALES_ACCOUNTING_ENTRY_CATEGORY,
        OTROS_ACCOUNTING_ENTRY_CATEGORY,
      ].includes(this.accountingEntryCategories.id)
    ) {
      await this.fetchOtherAndAnimalesCategories();
    }
    if (!this.cashflow.id) {
      await this.initCashflow();
    } else {
      this.store
        .select(
          selectUnitsByType(
            this.current_required_form_json?.external_model_required,
            this.translateService
          )
        )
        .pipe(
          map((value) => {
            this.units = cloneDeep(value);
          }),
          switchMap((a) => of(this.cashflow)),
          map((value: CashFlow) => {
            if (value.id) {
              this.cashflow = cloneDeep(value);
              this.convertOnLoadCashflowUnit();
              if (
                Array.isArray(this.cashflow?.certifications) &&
                this.cashflow?.certifications?.length > 0
              ) {
                this.formConfig.has_certification = 1;
                let certifs1 = [];
                let certifs2 = [];
                this.cashflow.certifications.forEach((value) => {
                  if (
                    value?.sub_certificates &&
                    value?.sub_certificates?.length > 0
                  ) {
                    certifs1.push(value);
                  } else {
                    certifs2.push(value);
                  }
                });
                this.secondCellCertif.certifs1 = certifs1;
                this.secondCellCertif.certifs2 = certifs2;
              }
              if (this.secondCellCertif.certifs2)
                this.formConfig.is_show_certifs_2 = true;
              this.cashflow.is_income
                ? this.ingresosClickedEvent()
                : this.egresosClickedEvent();
            }
          })
        )
        .subscribe();
    }
    this.store.dispatch(loadPartner());

    this.store.select(selectAllPartners).subscribe((value: Array<Partner>) => {
      this.partnerList = cloneDeep(value)?.filter(
        (val) => val?.id && !val?.id?.toString().includes('offline')
      );

      if (this.cashflow.id && this.cashflow.partners_id) {
        this.selected_partner = this.partnerList.find(
          (element) => element.id === this.cashflow.partners_id
        );
      }
    });
  }
  oldContentHeight;
  convertOnSaveCashflowUnit() {
    if (
      this.cashflow.unity &&
      this.cashflow.quantity &&
      this.cashflow.accounting_entries_categories !=
        PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY
    ) {
      const convertedQuantity =
        this.cashflow.quantity *
        this.units.find((element) => element.id == this.cashflow.unity)
          ?.equivalent_in_metric;

      if (typeof convertedQuantity == 'number')
        this.cashflow.quantity = convertedQuantity;
    }
  }

  convertOnLoadCashflowUnit() {
    if (
      this.cashflow.unity &&
      this.cashflow.quantity &&
      this.cashflow.accounting_entries_categories !=
        PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY
    ) {
      this.cashflow.quantity =
        this.cashflow.quantity /
        this.units.find((element) => element.id == this.cashflow.unity)
          ?.equivalent_in_metric;
      console.log(this.cashflow.quantity);

      // if (typeof convertedQuantity == 'number')
      // this.cashflow.quantity = convertedQuantity;
    }
  }

  ngDoCheck(): void {
    this.configForm();
  }
  configForm() {
    const {
      cashflow,
      formConfig,
      accountingEntryCategories,
      selected_production,
      secondCellCertif,
      country,
    } = this;

    const isCafeCategory =
      cashflow.accounting_entries_categories === CAFE_ACCOUNTING_ENTRY_CATEGORY;
    const isFirstCoffeeSells = CASHFLOW_FIRST_SALE_COFFEE_ID.includes(
      cashflow.cash_flow_categorie_id
    );
    const isSecondCoffeeSells = CASHFLOW_SECOND_COFFEE_SELLS_ID.includes(
      cashflow.cash_flow_categorie_id
    );

    formConfig.is_show_confirm_button = this.canSubmitCashflow();
    formConfig.is_activities_showable = this.checkActivities();
    formConfig.is_show_partner = CASHFLOW_FIRST_SALE_COFFEE_ID.includes(
      cashflow.cash_flow_categorie_id
    );
    formConfig.is_show_products_categories = [
      ANIMALES_ACCOUNTING_ENTRY_CATEGORY,
      OTROS_ACCOUNTING_ENTRY_CATEGORY,
    ].includes(accountingEntryCategories?.id);

    formConfig.is_show_type_transaction_button =
      (selected_production &&
        [
          ANIMALES_ACCOUNTING_ENTRY_CATEGORY,
          OTROS_ACCOUNTING_ENTRY_CATEGORY,
        ].includes(accountingEntryCategories?.id)) ||
      ![
        ANIMALES_ACCOUNTING_ENTRY_CATEGORY,
        OTROS_ACCOUNTING_ENTRY_CATEGORY,
        AHOROS_ACCOUNTING_ENTRY_CATEGORY,
      ].includes(accountingEntryCategories?.id);

    this.formConfig.show_payment_form =
      (!isFirstCoffeeSells && !isSecondCoffeeSells) ||
      (isFirstCoffeeSells && this.cashflow?.partners_id != undefined) ||
      (isSecondCoffeeSells &&
        this.cashflow.morphable_id &&
        this.cashflow.morphable_type &&
        this.formConfig.has_certification == 1 &&
        this?.secondCellCertif?.certifs2?.length > 0) ||
      (this.cashflow.morphable_id &&
        this.cashflow.morphable_type &&
        this.formConfig.has_certification == 0);


    //  &&
    // cashflow.accounting_entries_categories != PERSONAL_ACCOUNTING_ENTRY_CATEGORY ||
    // cashflow.accounting_entries_categories == PERSONAL_ACCOUNTING_ENTRY_CATEGORY &&
    // cashflow.morphable_id != undefined &&
    // cashflow.morphable_id != null &&
    // cashflow.morphable_id != 0
    // ;

    if (isCafeCategory && isSecondCoffeeSells) {
      formConfig.is_show_certifs_1 =
        (isSecondCoffeeSells &&
          country?.id != 1 &&
          cashflow.morphable_id != undefined &&
          cashflow.morphable_id != null &&
          cashflow.morphable_id != 0 &&
          ((formConfig.has_certification == 1 &&
            secondCellCertif?.certifs1?.length > 0 &&
            secondCellCertif?.certifs2?.length > 0) ||
            formConfig.has_certification == 0)) ||
        (country?.id == 1 &&
          cashflow.morphable_id != undefined &&
          cashflow.morphable_id != null);
    }

    formConfig.show_comment =
      (isCafeCategory && !cashflow.is_income && cashflow.activity_id) ||
      cashflow.amount;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.is_salary_needed) this.egresosClickedEvent();
  }

  async fetchOtherAndAnimalesCategories() {
    if (this.accountingEntryCategories.id === OTROS_ACCOUNTING_ENTRY_CATEGORY) {
      if (!this.cashFlowCategFiltred || this.cashFlowCategFiltred.length == 0) {
        this.store.dispatch(loadSelectedAgriProduct());
      }

      this.store.select(selectedAgriProduct).subscribe((val) => {
        this.cashFlowCategFiltred = cloneDeep(val).filter(
          (value) => !CAFE_IDS.includes(value.morphable_id)
        );
      });

      if (this.cashflow.id) {
        const val = this.cashFlowCategFiltred.find(
          (element) =>
            element.production_per_farm_id ==
            this.cashflow.production_per_farm_id
        );

        this.mainAgriculturalCategoryChange(val);
      }
    }
    // if (
    //   this.accountingEntryCategories.id === ANIMALES_ACCOUNTING_ENTRY_CATEGORY
    // ) {
    //   // this.cashFlowCategFiltred = .filter(
    //   //   (value) => !CAFE_IDS.includes(value.id)
    //   // );
    //   //       if (this.cashflow.id) {
    //   //   const val = this.cashFlowCategFiltred.find(
    //   //     (element) =>
    //   //       element.production_per_farm_id ==
    //   //       this.cashflow.production_per_farm_id
    //   //   );
    //   //   if (val) this.mainAgriculturalCategoryChange(val);
    //   // }
    // }

    if (
      this.accountingEntryCategories.id === ANIMALES_ACCOUNTING_ENTRY_CATEGORY
    ) {
      this.store.select(selectedAnimalProducts).subscribe((value) => {
        this.cashFlowCategFiltred = cloneDeep(value);
      });
      if (this.cashflow.id) {
        const val = this.cashFlowCategFiltred.find(
          (element) =>
            element.production_per_farm_id ==
            this.cashflow.production_per_farm_id
        );

        this.mainAgriculturalCategoryChange(val);
      }
    }
    // this.store
    //   .select(agriProductSelector)
    //   .subscribe((otherAndAnimals: any) => {
    //     console.log(otherAndAnimals);
    //     if (
    //       this.accountingEntryCategories.id ===
    //       ANIMALES_ACCOUNTING_ENTRY_CATEGORY
    //     ) {
    //       this.cashFlowCategFiltred = cloneDeep(otherAndAnimals)?.filter(
    //         (value) => value.is_animal
    //       );
    //     } else if (
    //       this.accountingEntryCategories.id === OTROS_ACCOUNTING_ENTRY_CATEGORY
    //     ) {
    //       this.cashFlowCategFiltred = cloneDeep(
    //         otherAndAnimals.selectedProducts
    //       )?.filter(
    //         (value) => !value.is_animal && !CAFE_IDS.includes(value.id)
    //       );
    //     }
    //     if (this.cashflow.id) {
    //       const val = this.cashFlowCategFiltred.find(
    //         (element) =>
    //           element.production_per_farm_id ==
    //           this.cashflow.production_per_farm_id
    //       );
    //       if (val) this.mainAgriculturalCategoryChange(val);
    //     }
    //     this.cashFlowCategFiltred = this.cashFlowCategFiltred.map((element) => {
    //       if (
    //         element.comment &&
    //         element.comment != '' &&
    //         element.comment != ' '
    //       ) {
    //         element.name = element.comment;
    //         element.name_en = element.comment;
    //       }
    //       return element;
    //     });
    this.cashFlowCategFiltred.map((element) =>
      !element.comment
        ? (element.name = element?.is_animal
            ? this.translateService.instant('material_category.' + element.id)
            : this.translateService.instant('product.' + element.id))
        : (element.name = element.comment)
    );
    this.cashFlowCategFiltred = orderAlphabaticallyByAttribute(
      this.cashFlowCategFiltred,
      'name',
      this.translateService.currentLang
    );
    // });
  }

  async initCashflow() {
    const farm = await lastValueFrom(this.store.select(getFarm).pipe(take(1)));
    const country = await lastValueFrom(
      this.store.select(getCountry).pipe(take(1))
    );
    this.cashflow.farm = farm;
    this.cashflow.farm_id = farm.id;
    this.cashflow.accounting_entries_categories =
      this.accountingEntryCategories.id;
    this.cashflow.currency = country.currency;
  }

  egresosClickedEvent() {
    // console.log(this.selected_production)
    this.reset();
    if (
      this.formConfig.is_show_products_categories &&
      this.selected_production &&
      this.accountingEntryCategories.id == ANIMALES_ACCOUNTING_ENTRY_CATEGORY
    ) {
      this.displayableCategories =
        this.selected_production.cash_flow_categories.filter(
          (element) => element.is_spent === 1
        );
    } else {
      this.displayableCategories =
        this.accountingEntryCategories.cashflow_category.filter(
          (element) => element.is_spent === 1
        );
    }
    this.cashflow.is_income = false;
  }

  ingresosClickedEvent() {
    this.reset();
    if (
      this.formConfig.is_show_products_categories &&
      this.selected_production &&
      this.accountingEntryCategories.id == ANIMALES_ACCOUNTING_ENTRY_CATEGORY
    ) {
      this.displayableCategories =
        this.selected_production.cash_flow_categories.filter(
          (element) => element.is_spent === 0
        );
    } else {
      this.displayableCategories =
        this.accountingEntryCategories.cashflow_category.filter(
          (element) => element.is_spent === 0
        );
    }
    this.cashflow.is_income = true;
  }

  cancelPressed() {
    this.store.dispatch(clearCashflowSelection());
    this.modalController.dismiss();
  }

  async submitPressed() {
    this.loadingModal = await this.loadingCtrl.create({
      spinner: 'bubbles',
      mode: 'ios',
    });

    this.loadingModal.present();

    if (this.canSubmitCashflow()) {
      this.convertOnSaveCashflowUnit();
      this.cashflow.id ? this.updateCashflow() : this.saveCashflow();
    } else {
      await this.loadingModal.dismiss();

      await this.toastUtils.toastBuilder(
        await this.translateService.instant('errors.all_fields'),
        null,
        null,
        'danger'
      );
    }
  }

  checkActivities(): boolean {
    return (
      this.accountingEntryCategories?.activities &&
      this.accountingEntryCategories?.activities.length > 0 &&
      !this.cashflow.is_income &&
      //for insert
      (this.cashflow.amount ||
        (this.cashflow.salary &&
          !this.cashflow.salary.quantity_product &&
          !this.cashflow.salary.unit &&
          this.cashflow.salary.journals_number != 0) ||
        //for update
        (this.cashflow.morphable_type == 'App\\Models\\Salarie' &&
          this.cashflow?.morphable &&
          ['UNPAID', 'PAYMENT_IN_KIND'].includes(
            this.cashflow.morphable.payment_type
          ) &&
          this.cashflow.morphable.journals_number != 0))
    );
  }

  cashflowChange(event) {
    if (event.key && event.value) {
      this.cashflow[event.key] = event.value;
    }
    // else {
    //   this.cashflow = event;
    // }

    // try {
    //   // this.content.el.scrollTop += this.content.el.scrollHeight;
    // } catch (err) {}
  }

  forceCategorieChange(e) {
    this.modalController.dismiss().then(async (value) => {
      const accountingEntriesCategories = JSON.parse(
        (await Storage.get({ key: dev.CASHFLOW_CATEGORIES })).value
      );
      const accountingNeeded = accountingEntriesCategories.find((accounting) =>
        accounting.cashflow_category.some(
          (cashflowCategory) => cashflowCategory.id == e
        )
      );

      let cashflow = new CashFlow();
      // cashflow.date = this.cashflow.date;
      cashflow.farm_id = this.cashflow.farm_id;
      cashflow.is_income = true;
      cashflow.cash_flow_categorie_id = e;
      cashflow.accounting_entries_categories = accountingNeeded.id;
      cashflow.cashflow_categories = {
        id: e,
        is_a_loss: null,
        is_spent: 0,
        title: null,
        accounting_entries_categorie_id: accountingNeeded.id,
        cash_flow_categorie_id: e,
        cash_flow_expenses: null,
        cash_flow_sub_category: null,
        formulaire_requirement: null,
        has_food_impact: 0,
        title_en: null,
      };
      await this.modalController

        .create({
          component: UnifiedCashflowInputComponentComponent,
          cssClass: ['my-custom-modal-css', 'modal-wrapper'],
          componentProps: {
            is_salary_needed: false,
            accountingEntryCategories: accountingNeeded,
            modalController: this.modalController,
            cashflow: cashflow,
          },
          id: '1',
        })
        .then((value) => value.present());
    });
  }

  canSubmitCashflow(): boolean {
    const cashflow = this.cashflow;
    if (!cashflow) {
      return false;
    }

    const isUnpaid =
      cashflow?.morphable?.payment_type === 'UNPAID' ||
      cashflow?.salary?.payment_type == 'UNPAID';
    const isPaymentInKind =
      cashflow.salary?.payment_type === 'PAYMENT_IN_KIND' ||
      cashflow?.salary?.payment_type == 'PAYMENT_IN_KIND';
    const isPaymentPerHarvestedQuantity =
      cashflow.salary?.payment_type === 'PAYMENT_PER_HARVESTED_QUANTITY';
    const isPaymentPerNumberOfDay =
      cashflow.salary?.payment_type === 'PAYMENT_PER_NUMBER_OF_DAY';

    return (
      (cashflow.morphable && isUnpaid && cashflow.amount == 0) ||
      (cashflow.salary && isUnpaid && cashflow.amount == 0) ||
      (cashflow.date &&
        ((cashflow.amount !== undefined &&
          cashflow.amount !== null &&
          cashflow.amount !== 0) ||
          cashflow.salary) &&
        cashflow.accounting_entries_categories &&
        cashflow.farm_id &&
        cashflow.cash_flow_categorie_id &&
        (([
          CAFE_ACCOUNTING_ENTRY_CATEGORY,
          COCOA_ACCOUNTING_ENTRY_CATEGORY,
        ].includes(this.accountingEntryCategories.id) &&
          !cashflow.is_income &&
          (cashflow.activity_id ||
            (cashflow.activity && cashflow.activity.id))) ||
          ([
            CAFE_ACCOUNTING_ENTRY_CATEGORY,
            COCOA_ACCOUNTING_ENTRY_CATEGORY,
          ].includes(this.accountingEntryCategories.id) &&
            cashflow.is_income) ||
          ![
            CAFE_ACCOUNTING_ENTRY_CATEGORY,
            COCOA_ACCOUNTING_ENTRY_CATEGORY,
          ].includes(this.accountingEntryCategories.id)) &&
        ((CASHFLOW_FIRST_SALE_COFFEE_ID.includes(
          cashflow.cash_flow_categorie_id
        ) &&
          this.isIdSet('partners_id')) ||
          !CASHFLOW_FIRST_SALE_COFFEE_ID.includes(
            cashflow.cash_flow_categorie_id
          )) &&
        ((CASHFLOW_SECOND_COFFEE_SELLS_ID.includes(
          cashflow.cash_flow_categorie_id
        ) &&
          cashflow.morphable_id !== 0 &&
          cashflow.morphable_id !== null &&
          cashflow.morphable_id !== undefined) ||
          !CASHFLOW_SECOND_COFFEE_SELLS_ID.includes(
            cashflow.cash_flow_categorie_id
          )) &&
        ((cashflow.accounting_entries_categories ===
          PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY &&
          cashflow.quantity !== undefined &&
          cashflow.quantity !== 0 &&
          cashflow.unity !== undefined &&
          cashflow.unity !== null) ||
          cashflow.accounting_entries_categories !==
            PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY) &&
        (([149, 150].includes(cashflow.cash_flow_categorie_id) &&
          cashflow.comment !== '' &&
          cashflow.comment !== null &&
          cashflow.comment !== undefined &&
          cashflow.comment !== ' ') ||
          ![149, 150].includes(cashflow.cash_flow_categorie_id)) &&
        ((cashflow.accounting_entries_categories ===
          PERSONAL_ACCOUNTING_ENTRY_CATEGORY &&
          cashflow.morphable_id &&
          (cashflow.morphable_type !== '' ||
            cashflow.morphable_type !== null ||
            cashflow.morphable_type !== undefined) &&
          ((cashflow.cash_flow_categorie_id === 171 && cashflow.comment) ||
            cashflow.cash_flow_categorie_id !== 171)) ||
          cashflow.accounting_entries_categories !==
            PERSONAL_ACCOUNTING_ENTRY_CATEGORY) &&
        ((isPaymentInKind && cashflow.salary.journals_number) ||
          (isPaymentPerHarvestedQuantity &&
            cashflow.salary.journals_number &&
            cashflow.salary.quantity_product &&
            cashflow.salary.unit &&
            cashflow.salary.salary_amount) ||
          (isPaymentPerNumberOfDay &&
            cashflow.salary.journals_number &&
            cashflow.salary.salary_amount) ||
          (isUnpaid && cashflow.salary) ||
          !cashflow.salary))
    );
  }

  isIdSet(label: string): boolean {
    return (
      this.cashflow[label] != 0 &&
      this.cashflow[label] != undefined &&
      this.cashflow[label] != null
    );
  }

  async addSocios() {
    let Crop = await JSON.parse(
      (
        await Storage.get({ key: dev.CROP_PRODUCT })
      ).value
    );
    let componentCrop = {
      cropProduct: Crop[0],
      openedFromCashflow: true,
    };

    let modal = await this.toastUtils.modalBuilder(
      ModalPartnerComponent,
      componentCrop
    );

    await modal.onWillDismiss();
    this.store.select(selectAllPartners).subscribe((value: Array<Partner>) => {
      this.partnerList = cloneDeep(value)
      // ?.filter(
      //   (val) => val?.id && !val?.id?.toString().includes('offline')
      // );
    });
    this.store.dispatch(loadPartner());
  }

  modelChange(val) {
    this.cashflow.partners_id = val.id;
  }

  mainAgriculturalCategoryChange(value: any) {
    this.displayableCategories = null;
    this.selected_production = value;
    this.cashflow.production_per_farm = value;
    this.cashflow.production_per_farm_id = value.production_per_farm_id;
    if (!this.cashflow.id) {
      this.cashflow.quantity = 0;
      this.cashflow.unity = null;
      this.cashflow.material_categorie_id = null;
      this.cashflow.product_id = null;
      this.cashflow.material_product_id = null;
      if (this.cashflow.is_income != null)
        this.cashflow.is_income
          ? this.ingresosClickedEvent()
          : this.egresosClickedEvent();
    }
    if (value.is_animal) {
      this.cashflow.material_categorie_id = value.morphable_id;
    } else this.cashflow.product_id = value.morphable_id;
  }

  certifsChange(value, certif) {
    let arrayCertif = value.target.value;
    this.certifications = arrayCertif[0].sub_certificates;
    // this.secondCellCertif[certif] = '';
    // if (Array.isArray(arrayCertif) && arrayCertif.length > 0) {
    //   arrayCertif.forEach((element, index) => {
    //     index === 0
    //       ? (this.secondCellCertif[certif] += element)
    //       : (this.secondCellCertif[certif] += ', ' + element);
    //   });
    // }
    // if (certif=='certifs1' && this.secondCellCertif[certif] || certif=='certifs2')
    //   this.formConfig.is_show_certifs_2 = true;
    // else this.formConfig.is_show_certifs_2 = false;
  }

  compareWith(o1, o2) {
    if (!o1 || !o2) {
      return o1 === o2;
    }

    if (Array.isArray(o2)) {
      return o2.some((o) => o.id === o1.id);
    }

    return o1.id === o2.id;
  }

  // certifs2Change(value) {
  //   this.secondCellCertif.certifs2 = '';
  //   if (Array.isArray(value) && value.length > 0) {
  //     value.forEach((element, index) =>
  //       index === 0
  //         ? (this.secondCellCertif.certifs2 += element.title)
  //         : (this.secondCellCertif.certifs2 += ', ' + element.title)
  //     );
  //   }
  // }

  async saveCashflow() {
    if (
      CASHFLOW_FIRST_SALE_COFFEE_ID.includes(
        this.cashflow.cash_flow_categorie_id
      )
    )
      this.cashflow.is_first_sale = true;

    if (this.cashflow?.salary && !this.cashflow?.salary?.id) {
      this.cashflow.comment = this.cashflow.salary.comment
        ? this.cashflow.salary.comment
        : undefined;
      if (
        this.cashflow?.salary?.quantity_product &&
        this.cashflow.salary?.unit
      ) {
        const convertedQuantity =
          this.units.find(
            (element) => element?.id == this.cashflow?.salary?.unit
          )?.equivalent_in_metric * this.cashflow.salary.quantity_product;
        if (typeof convertedQuantity == 'number')
          this.cashflow.salary.quantity_product = convertedQuantity;
        if (this.cashflow.activity_id == HARVEST_ACTIVITY) {
          this.cashflow.quantity = this.cashflow?.salary?.quantity_product;
          this.cashflow.unity = this.cashflow?.salary?.unit;
        }
      }
      this.cashflow.amount = this.cashflow?.salary?.total_paid;
      // cashFlow.salary = salary;
      this.cashflow.morphable_type = MORPH_Salarie;
      // await this.loadingModal.dismiss();

      // return;
      // this.calculateCashflowQuantityFromSalary();
      // this.salaryBusinessRules
      //   .saveSalaryPriorTocashflow(this.cashflow)
      //   .subscribe({next:async (vale) => {
      //     this.store.dispatch(clearCashflowSelection());
      //     await this.loadingModal.dismiss();

      //     this.modalController.dismiss();
      //   },error:async(error) => {
      //     this.store.dispatch(clearCashflowSelection());
      //     await this.loadingModal.dismiss();

      //     this.modalController.dismiss();
      //   }});
    }
    if (this.secondCellCertif?.certifs1 && this.secondCellCertif?.certifs2) {
      this.cashflow.certifications = this.pluck(
        this.secondCellCertif.certifs1,
        'id'
      ).concat(this.pluck(this.secondCellCertif.certifs2, 'id'));
    }
    this.cashflow.accounting_entry_categories = this.accountingEntryCategories;

    this.store.dispatch(saveCashflow({ data: this.cashflow }));
    await this.loadingModal.dismiss();

    this.modalController.dismiss();
  }
  pluck = (arr, key) => arr.map((i) => i[key]);
  async updateCashflow() {
    if (
      this.cashflow.morphable_type &&
      this.cashflow.morphable &&
      this.cashflow.morphable_type.toLowerCase().includes('salarie') &&
      this.cashflow.morphable.quantity_product &&
      this.cashflow.morphable.unit
    ) {
      const convertedQuantity =
        this.units.find((element) => element.id == this.cashflow.morphable.unit)
          ?.equivalent_in_metric * this.cashflow.morphable.quantity_product;

      if (typeof convertedQuantity == 'number')
        this.cashflow.morphable.quantity_product = convertedQuantity;

      if (this.cashflow.activity_id == HARVEST_ACTIVITY) {
        this.cashflow.quantity = this.cashflow.morphable.quantity_product;
        this.cashflow.unity = this.units.find(
          (element) => element.id == this.cashflow.morphable.unit
        ).id;
      }
    }
    this.store.dispatch(updateCashflow({ data: this.cashflow }));
    await this.loadingModal.dismiss();

    this.modalController.dismiss({ updated_cashflow: this.cashflow });

    // this.cashflowAPI
    //   .updateCashFlow(this.cashflow)
    //   .pipe(
    //     map((cashflow: CashFlow) => cashflow),
    //     take(1),
    //     // filter((cashflow: CashFlow) => cashflow.id),
    //     switchMap((cashflow: CashFlow) => {
    //       if (
    //         this.cashflow.morphable_type &&
    //         this.cashflow.morphable &&
    //         this.cashflow.morphable_type.toLowerCase().includes('salarie')
    //       ) {
    //         return this.employeeAPI.updateSalary(this.cashflow.morphable);
    //       } else return of(cashflow);
    //     }),
    //     map((result) => {
    //       return result;
    //     })
    //   )
    //   .subscribe((val: any) => {
    //     this.store.dispatch(updateCashflowSuccess({ cashflow: val }));
    //     this.store.dispatch(clearCashflowSelection());
    //     var d = new Date();
    //     d.setMonth(d.getMonth() - 1);
    //     this.store.dispatch(
    //       paginateCashflow({
    //         startDate: null,
    //         endDate: null,
    //         page: 1,
    //         page_size: 10,
    //         accountings_entries: [],
    //         natures: [],
    //         is_harvesting_filter: false,
    //       })
    //     );
    //     this.modalController.dismiss({ updated_cashflow: this.cashflow });
    //   });
  }

  async addProduciton(cashFlowCategFiltred) {
    let componentProps;
    if (
      this.accountingEntryCategories.id == ANIMALES_ACCOUNTING_ENTRY_CATEGORY
    ) {
      componentProps = {
        modalController: this.modalController,
        lists: cashFlowCategFiltred,
      };
    } else {
      componentProps = {
        modalController: this.modalController,
        categorie_id: 1,
        is_preproduct_view: true,
        title: await this.translateService.instant(
          'label.add_production_agricola_farm'
        ),
      };
    }

    let modal = await this.toastUtils.modalBuilder(
      this.accountingEntryCategories.id == ANIMALES_ACCOUNTING_ENTRY_CATEGORY
        ? AnimalesFormComponent
        : ProductFormComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper']
    );

    // this.modalController.dismiss().then((value) => {
    //   this.cashflow.accounting_entries_categories ==
    //   OTROS_ACCOUNTING_ENTRY_CATEGORY
    //     ? this.nav.setRoot(ProductAnimalInterfaceComponent)
    //     : this.nav.setRoot(ProductAnimalInterfaceComponent);
    // });
  }
  // async handelChangeCertif(array,certif) {
  //   // var list = await this.translateService.get(strings).toPromise();
  //   // if (Array.isArray(list))
  //   //   list = list.map((element, index) => {
  //   //     return {
  //   //       id: index,
  //   //       title: element,
  //   //       title_en: element,
  //   //     };
  //   //   });
  //   // else return;
  //   // let componentProps = {
  //   //   list: list,
  //   //   showDates: false,
  //   //   modalId: strings,
  //   // };

  //   // const popover = await this.toastUtils.modalBuilder(
  //   //   CategoriesSelectionPopoverComponent,
  //   //   componentProps,
  //   //   ['custom-modal-mini', 'modal-wrapper'],
  //   //   false,
  //   //   strings
  //   // );
  //   // const { data } = await popover.onDidDismiss();
  //   // if (data?.data) {
  //     // this.selected_certifications = '';
  //     // // if (data?.data.length > 0) {
  //       if (certif == 'certifs_1') this.certifs1Change(array.target.value);
  //       else this.certifs2Change(array.target.value);
  //     // }
  //   // }
  // }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  async hasFormJSON(val) {
    if (val) {
      this.formConfig.show_payment_form = true;
      const strig = val;
      strig.replace(/\s+/g, '').trim();
      const first_parse = JSON.parse(val);
      console.log(first_parse)

      if (typeof first_parse === 'object')
        this.current_required_form_json = first_parse;
      else this.current_required_form_json = JSON.parse(first_parse);
      if (this.current_required_form_json?.labels)
        this.formConfig.labels = this.current_required_form_json?.labels;

      // this.translateService.currentLang === 'es'
      //   ? (this.formConfig.labels = this.current_required_form_json.labels.es)
      //   : (this.formConfig.labels =
      //       this.current_required_form_json.labels.en);

      if (
        this.current_required_form_json.external_model_required &&
        this.current_required_form_json.external_model_required.model_name
      ) {
        if (
          this.current_required_form_json.external_model_required.model_name ==
          'material_product'
        ) {
          this.cashflow.material_product_id =
            this.current_required_form_json.external_model_required.id;
        } else if (
          this.current_required_form_json.external_model_required.model_name ==
          'product'
        ) {
          this.cashflow.product_id =
            this.current_required_form_json.external_model_required.id;
        }
      }
    } else if (!val) {
      this.formConfig.show_payment_form = false;
      this.current_required_form_json = undefined;
    }
  }

  async reset() {
    if (!this.cashflow.id) {
      this.cashflow.activity_id = null;
      this.cashflow.amount = 0;
      this.cashflow.comment = null;
      this.cashflow.quantity = 0;
      this.current_required_form_json = null;
      this.formConfig.show_payment_form = false;
    }
  }
}
