import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import { CashflowExpenses } from 'src/app/models/cash-flow/cashflow_categories';
import {
  mapLangeTitleObjects,
  orderAlphabaticallyByAttribute,
  pushOtrosToEndByAttributeName,
} from 'src/app/utils/array-filtrer';

@Component({
  selector: 'app-cashflow-expenses-selectors',
  templateUrl: './cashflow-expenses-selectors.component.html',
  styleUrls: ['./cashflow-expenses-selectors.component.scss'],
})
export class CashflowExpensesSelectorsComponent implements OnInit, OnChanges {
  @Input() cashflow: CashFlow;
  @Output() cashflowCategoriesChange: EventEmitter<CashFlow> =
    new EventEmitter<CashFlow>();
  @Input() categories: Array<CashflowExpenses>;
  @Input() show_title: boolean = false;
  @Output() resetForm: EventEmitter<void> = new EventEmitter();
  @Output() fromulaireRequirementChange: EventEmitter<string> =
    new EventEmitter<string>();
  selected_categorie: CashflowExpenses;
  has_expenses: boolean = false;
  constructor(public translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.categories &&
      changes.categories.isFirstChange() == false &&
      !this.cashflow.id
    ) {
      this.selected_categorie = null;
      this.has_expenses = false;
      this.cashflow.cash_flow_expense_id = null;
      this.cashflowCategoriesChange.emit(this.cashflow);
    }
  }
  ngOnInit() {
    this.categories = cloneDeep(this.categories);
    let categories = [...this.categories];

    categories.forEach(
      (element) =>{
        Object.defineProperties(element, {
          title: {writable: true}
        });
        (element.title = this.translateService.instant(
          'cash_flow_expenses.' + element.id
        ))
      }
    );
    this.categories = mapLangeTitleObjects(
      categories,
      'title',
      this.translateService
    );
    if (this.cashflow.cash_flow_expense_id) this.loadData();
  }

  onChangeCategory(value: CashflowExpenses) {
    this.resetForm.emit();
    this.selected_categorie = value;

    if (
      Array.isArray(value?.cashflow_expenses_sub_category) &&
      value?.cashflow_expenses_sub_category?.length > 0
    )
      this.has_expenses = true;
    else {
      this.cashflow.cash_flow_expense_id = value?.id;
      this.cashflowCategoriesChange.emit(this.cashflow);
    }
    if (value?.formulaire_requirement)
      this.fromulaireRequirementChange.emit(value.formulaire_requirement);
  }

  childChange(cashflow: CashFlow) {
    this.cashflow = cashflow;
    this.cashflowCategoriesChange.emit(this.cashflow);
  }

  childFormChange(value) {
    if (this.cashflow.cash_flow_expense_id)
      this.fromulaireRequirementChange.emit(value);
  }

  loadData() {
    // console.log(this.categories)
    this.categories.forEach((cashflowExpenses: CashflowExpenses) => {
      if (cashflowExpenses?.id == this.cashflow?.cash_flow_expense_id) {
        this.onChangeCategory(cashflowExpenses);
      } else {
        if (Array.isArray(cashflowExpenses?.cashflow_expenses_sub_category)) {
          cashflowExpenses?.cashflow_expenses_sub_category.forEach(
            (cashflowSubExpenses: CashflowExpenses) => {
              if (
                cashflowSubExpenses?.id == this.cashflow?.cash_flow_expense_id
              ) {
                this.onChangeCategory(cashflowExpenses);
              } else {
                if (
                  Array.isArray(
                    cashflowSubExpenses?.cashflow_expenses_sub_category
                  )
                ) {
                  cashflowSubExpenses?.cashflow_expenses_sub_category.forEach(
                    (cashflowSubSubExpenses: CashflowExpenses) => {
                      if (
                        cashflowSubSubExpenses?.id ==
                        this.cashflow?.cash_flow_expense_id
                      ) {
                        this.onChangeCategory(cashflowExpenses);
                      }
                    }
                  );
                }
              }
            }
          );
        }
      }
    });
  }
}
