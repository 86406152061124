import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AccountingEntryCategorie } from 'src/app/models/accounting/accounting_entries_categories';
import Owner from 'src/app/models/owner.model';
import { accountingEntriesLoading } from 'src/app/utils/states-management/actions/accountings-entries.action';
import { CoffeeKpisComponent } from '../../kpis/coffee-kpis/coffee-kpis.component';
import { AnalysisHarvestComponent } from '../analysis-harvest/analysis-harvest.component';
import { AnalysisInputsComponent } from '../analysis-inputs/analysis-inputs.component';
import { AnalysisWorkforceComponent } from '../analysis-workforce/analysis-workforce.component';
import { CashflowSummaryDiaryComponent } from '../cashflow-summary-diary/cashflow-summary-diary.component';
import { getTotalsAccountingCategoriesResult } from 'src/app/utils/states-management/selector/dashboard.selector';
import { LineChartExpensesChartComponent } from '../../widgets/line-chart-expenses-chart/line-chart-expenses-chart.component';
import { AHOROS_ACCOUNTING_ENTRY_CATEGORY } from 'src/app/utils/consts';

@Component({
  selector: 'app-analysis-cashflow',
  templateUrl: './analysis-cashflow.component.html',
  styleUrls: ['./analysis-cashflow.component.scss'],
})
export class AnalysisCashflowComponent implements OnInit, OnDestroy {
  @Input() nav: IonNav;
  profile_info;
  country_id: number = null;
  subscriptions: Array<Subscription> = [];
  savingAccounting;
  constructor(
    private modalController: ModalController,
    private store: Store<{
      owner: Owner;
      accountingEntries: Array<AccountingEntryCategorie>;
    }>
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store.select('owner').subscribe((value) => {
        if (value.id && value.profile_info)
          this.profile_info = JSON.parse(value.profile_info);

        if (value.country_id) this.country_id = value.country_id;
      })
    );
    this.subscriptions.push(
      this.store
        .select(getTotalsAccountingCategoriesResult)
        .subscribe(({data}) => {
          this.savingAccounting = data.find(
            (val) => val.id == AHOROS_ACCOUNTING_ENTRY_CATEGORY
          );
          if (data && data.length == 0 && this.country_id)
            this.store.dispatch(
              accountingEntriesLoading({ country_id: this.country_id })
            );
        })
    );
  }

  async navigateTo(component: string) {
    switch (component) {
      case 'AnalysisWorkforceComponent':
        this.nav.setRoot(AnalysisWorkforceComponent, { nav: this.nav });
        break;
      case 'AnalysisHarvestComponent':
        this.nav.setRoot(AnalysisHarvestComponent, { nav: this.nav });
        break;
      case 'AnalysisInputsComponent':
        this.nav.setRoot(AnalysisInputsComponent, { nav: this.nav });
        break;
      case 'CoffeeKpisComponent':
        this.nav.setRoot(CoffeeKpisComponent, { nav: this.nav });
        break;
      case 'CashflowSummaryDiaryComponent':
        this.nav.setRoot(CashflowSummaryDiaryComponent, { nav: this.nav });
        break;
      case 'LineChartExpensesChartComponent':
        this.nav.setRoot(LineChartExpensesChartComponent, {
          nav: this.nav,
          accountingDetails: this.savingAccounting,
        });
        break;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((element) => {
      element.unsubscribe();
    });
  }
}
