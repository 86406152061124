import { HARVEST_ACTIVITY, MORPH_Salarie } from '../consts';
import { CashflowAPI } from '../../apis/cashflow.service';
import { EmployeesAPI } from '../../apis/employees.service';
import { Injectable } from '@angular/core';

import { map, switchMap, take } from 'rxjs/operators';
import { Unit } from 'src/app/models/Unit';
import { Store } from '@ngrx/store';
import { selectAllUnits } from '../states-management/reducers/units.reducer';

@Injectable()
export class SalaryBusinessRules {
  units: Array<Unit>;
  constructor(
    private employeesAPI: EmployeesAPI,
    private cashflowAPI: CashflowAPI,
    private store: Store
  ) {
    this.store
      .select(selectAllUnits)
      .subscribe((value) => (this.units = value));
  }

  saveSalaryPriorTocashflow(cashFlow) {
    cashFlow.comment = cashFlow.salary.comment
      ? cashFlow.salary.comment
      : undefined;
    if (cashFlow.salary.quantity_product && cashFlow.salary.unit) {
      const convertedQuantity =
        this.units.find((element) => element.id == cashFlow.salary.unit)
          ?.equivalent_in_metric * cashFlow.salary.quantity_product;
      if (typeof convertedQuantity == 'number')
        cashFlow.salary.quantity_product = convertedQuantity;
      if (cashFlow.activity_id == HARVEST_ACTIVITY) {
        cashFlow.quantity = cashFlow.salary.quantity_product;
        cashFlow.unity = cashFlow.salary.unit;
      }
    }
    return this.employeesAPI.saveSalary(cashFlow.salary).pipe(
      map((salary) => {
        cashFlow.amount = cashFlow.salary.total_paid;
        cashFlow.salary = salary;
        cashFlow.morphable_type = MORPH_Salarie;
        cashFlow.morphable_id = salary.id;
        return cashFlow;
      }),
      take(1),
      switchMap((cashFlow) => this.cashflowAPI.saveCashFlow(cashFlow))
    );
  }
}
