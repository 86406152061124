import { Observable } from 'rxjs';
import { GoodsAPI } from '../../apis/goods.service';
import {
  CashFlow,
  isArbolesTransaction,
} from '../../models/cash-flow/cashFlow';
import { Good } from '../../models/good.model';

export const updateGoodAfterCashflowSubmitOtrosProducts = (
  cashFlow: CashFlow,
  goodAPI: GoodsAPI,
  otrosDisplayableListItem: any
): Observable<any> => {
  if (isArbolesTransaction(cashFlow)) return;
  let good = new Good();
  if (cashFlow.modelBeingUpdated) {
    good.id = cashFlow.modelBeingUpdated.id;
    // good.unit = cashFlow.modelBeingUpdated.unit
    //   ? cashFlow.modelBeingUpdated.unit
    //   : cashFlow.unity;
    good.qty = cashFlow.modelBeingUpdated.qty_update;
  } else {
    // good.unit = cashFlow.unity;
    good.qty =
      !cashFlow.is_income || !cashFlow.is_a_loss
        ? cashFlow.quantity
        : -cashFlow.quantity;
  }
  if (cashFlow.product_id) good.product_id = cashFlow.product_id;
  else good.material_product_id = cashFlow.material_product_id;

  if (otrosDisplayableListItem)
    good.production_per_farm_id =
      otrosDisplayableListItem.production_per_farm_id;
  return good.id ? goodAPI.updateGood(good) : goodAPI.insertGood([good]);
};
