import { nextOfflineIndex } from './../reducers/offline.reducer';
import { createAction, props } from '@ngrx/store';
import { Family_member } from 'src/app/models/family_member.model';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';

export const LOAD_AGRI_PRODUCT = 'LOAD_AGRI_PRODUCT';
export const LOAD_AGRI_PRODUCT_SUCCESS = 'LOAD_AGRI_PRODUCT_SUCCESS';
export const LOAD_SELECTED_AGRI_PRODUCT = 'LOAD_SELECTED_AGRI_PRODUCT';
export const LOAD_SELECTED_AGRI_PRODUCT_SUCCESS =
  'LOAD_SELECTED_AGRI_PRODUCT_SUCCESS';
export const DELETE_AGRI_PRODUCT = 'DELETE_AGRI_PRODUCT';
export const DELETE_AGRI_PRODUCT_SUCCESS = 'DELETE_AGRI_PRODUCT_SUCCESS';
export const ADD_AGRI_PRODUCT = 'ADD_AGRI_PRODUCT';
export const ADD_AGRI_PRODUCT_SUCCESS = 'ADD_AGRI_PRODUCT_SUCCESS';

export const loadAgriProduct = createAction(LOAD_AGRI_PRODUCT);
export const loadAgriProductSuccess = createAction(
  LOAD_AGRI_PRODUCT_SUCCESS,
  props<{ agriculturalProducts: Array<any> }>()
);

export const loadSelectedAgriProduct = createAction(LOAD_SELECTED_AGRI_PRODUCT);
export const loadSelectedAgriProductSuccess = createAction(
  LOAD_SELECTED_AGRI_PRODUCT_SUCCESS,
  props<{ selectedAgriculturalProducts: Array<any> }>()
);
export const deleteSelectedAgriProduct = createAction(
  DELETE_AGRI_PRODUCT,
  props<{ product: ProductionsPerfarm }>()
);
export const deleteSelectedAgriProductSuccess = createAction(
  DELETE_AGRI_PRODUCT_SUCCESS,
);
export const addAgriProduct = createAction(
  ADD_AGRI_PRODUCT,
  props<{ productions: Array<ProductionsPerfarm> }>()
);
export const addAgriProductSuccess = createAction(
  ADD_AGRI_PRODUCT_SUCCESS,
  props<{ productions: Array<ProductionsPerfarm> }>()
);
