import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  GET_DASHBOARD_DATA,
  LOAD_HARVEST_SUMMARY,
  LOAD_INSUMOS_BOUGHT,
  LOAD_TOTAL_ACCOUNTING_CATEGORIES,
  LOAD_WORKFROCE_STATS,
  dashboardDataError,
  dashboardDataLoaded,
  loadHarvestSummarySuccess,
  loadInsumosBoughtSuccess,
  loadTotalAccountingCategoriesSuccess,
  loadWorkForceStatsSuccess,
} from '../actions/dashboard.action';
import { AccountingCategoriesAPI } from 'src/app/apis/accounting_categories.service';
import { getCurrentMonthAndYear } from '../../pipes-utils/date_formatter';
import { forkJoin, of } from 'rxjs';
import { StatisticsAPI } from 'src/app/apis/statistics.service';
import { RoutingConfig } from '../../routing-config';

@Injectable()
export class DashboardEffect {
  loadTotalAccountingCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_TOTAL_ACCOUNTING_CATEGORIES),
      switchMap((action: any) => {
        var startDate;
        var endDate;
        if (action?.data?.startDate && action?.data?.endDate) {
          var startDate = action?.data?.startDate;
          var endDate = action?.data?.endDate;
        } else {
          startDate = new Date(
            getCurrentMonthAndYear(new Date()).year,
            getCurrentMonthAndYear(new Date()).month - 1,
            1
          ).toISOString();
          endDate = new Date(
            getCurrentMonthAndYear(new Date()).year,
            getCurrentMonthAndYear(new Date()).month,
            0 + 1
          ).toISOString();
        }
        return this.accountingCategoriesAPI
          .getTotalsAccountingCategories(
            action?.data?.startDate,
            action?.data?.endDate
          )
          .pipe(
            map((values: Array<any>) => {
              return loadTotalAccountingCategoriesSuccess({
                totalsAccountingCategories: values,
              });
            })
          );
      })
    )
  );

  loadDashboardData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_DASHBOARD_DATA),
      switchMap((action: any) => {
        var startDate;
        var endDate;
        if (action?.data?.startDate && action?.data?.endDate) {
          startDate = action?.data?.startDate;
          endDate = action?.data?.endDate;
        } else {
          startDate = new Date(
            getCurrentMonthAndYear(new Date()).year,
            getCurrentMonthAndYear(new Date()).month - 1,
            1
          ).toISOString();
          endDate = new Date(
            getCurrentMonthAndYear(new Date()).year,
            getCurrentMonthAndYear(new Date()).month,
            0 + 1
          ).toISOString();
        }
        var groupeBy = 'm-Y';
        if (action?.data?.groupeBy) {
          groupeBy = action?.data?.groupeBy;
        }

        return forkJoin([
          this.statisticsAPI.fetchStatsData(startDate, endDate, groupeBy),
          this.statisticsAPI.getMaterialOroductKpis(startDate, endDate),
          this.statisticsAPI.getProductKpis(startDate, endDate),
          this.statisticsAPI.getCafeCerezKpi(
            startDate,
            endDate,
            action?.data?.cropId
          ), //TODO fix param
        ]).pipe(
          map(
            ([
              statisticsDataResponse,
              materialProductKpisResponse,
              productKpisResponse,
              cafeCerezKpiResponse,
            ]) => {
              return {
                statisticsData: statisticsDataResponse,
                materialProductKpis: materialProductKpisResponse,
                productKpis: productKpisResponse,
                cafeCerezKpi: cafeCerezKpiResponse,
              };
            }
          ),
          map((data) => dashboardDataLoaded({ data })),
          catchError((error) => of(dashboardDataError({ error })))
        );
      })
    )
  );

  loadHarvestSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_HARVEST_SUMMARY),
      switchMap((action: any) => {
        var startDate;
        var endDate;
        if (action?.data?.startDate && action?.data?.endDate) {
          var startDate = action?.data?.startDate;
          var endDate = action?.data?.endDate;
        } else {
          startDate = new Date(
            getCurrentMonthAndYear(new Date()).year,
            getCurrentMonthAndYear(new Date()).month - 1,
            1
          ).toISOString();
          endDate = new Date(
            getCurrentMonthAndYear(new Date()).year,
            getCurrentMonthAndYear(new Date()).month,
            0 + 1
          ).toISOString();
        }
        return this.statisticsAPI
          .harvestSummary(
            action?.data?.startDate,
            action?.data?.endDate,
            action?.data?.cropId
          )
          .pipe(
            map((values: Array<any>) => {
              return loadHarvestSummarySuccess({
                harvestSummary: values,
              });
            })
          );
      })
    )
  );

  loadInsumosBought$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_INSUMOS_BOUGHT),
      switchMap((action: any) => {
        return this.statisticsAPI.insumosBought(action.data).pipe(
          map((values: Array<any>) => {
            return loadInsumosBoughtSuccess({
              insumosBought: values,
            });
          })
        );
      })
    )
  );

  loadWorkForceStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_WORKFROCE_STATS),
      switchMap((action: any) => {
        return this.statisticsAPI.fetchWorkForceStats(action.data).pipe(
          map((values: Array<any>) => {
            return loadWorkForceStatsSuccess({
              workForceStats: values,
            });
          })
        );
      })
    )
  );
  constructor(
    private actions$: Actions,
    public statisticsAPI: StatisticsAPI,
    private accountingCategoriesAPI: AccountingCategoriesAPI,
    public routingConfig: RoutingConfig
  ) {}
}
