import { RoutingConfig } from 'src/app/utils/routing-config';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import routes from '../../../config/routing.json';

@Component({
  selector: 'app-money-reporting',
  templateUrl: './money-reporting.component.html',
  styleUrls: ['./money-reporting.component.scss'],
})
export class MoneyReportingComponent implements OnInit {
  goods;
  start;
  return;
  constructor(
    private router: Router,
    public translateService: TranslateService,
    private routingConfig: RoutingConfig
  ) {}

  async ngOnInit() {
    await this.langageChanger(localStorage.getItem('CapacitorStorage.LANGAGE'));
  }
  async langageChanger(lang) {
    this.start = await this.translateService
      .get('start')
      .toPromise()
      .then((valu) => valu);
    this.return = await this.translateService
      .get('return')
      .toPromise()
      .then((valu) => valu);
    this.goods = await this.translateService
      .get('titles.goods')
      .toPromise()
      .then((valu) => valu);
  }
  async mreturn() {
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());  
  }
  async mstart() {
    this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }
}
