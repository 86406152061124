import { CommunityPermissionComponentComponent } from './community-permission-component/community-permission-component.component';
import { FooterModule } from '../../widgets/footer-component/header-footer.module';
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FooterModule,
    TranslateModule.forChild(),
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  declarations: [CommunityPermissionComponentComponent],
  exports: [],
})
export class CommunityModule {}
