import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({ name: 'fieldsDynamicChecker', pure: false })
export class FieldsDynamicChecker implements PipeTransform {
  constructor() {}

  public transform(field_request, form: FormGroup, key: string): boolean {
    let x = [];
    let isEligible = false;
    if (Array.isArray(field_request)) {
      x = field_request
        .map((element) => {
          if (
            form.get(element.key)?.value != null &&
            ((element.value != undefined &&
              form.get(element.key).value == element.value) ||
              element.value == undefined)
          )
            return element;
        })
        .filter((e) => e);
      isEligible = x.length == field_request.length;
    }
    // if (!isEligible) form.get(key)?.setValue(null);
    return isEligible;
  }
}
