import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {  createReducer, on } from '@ngrx/store';
import {  loadProgramSuccessfuly,  } from '../actions/programas.action';
import { PaginationResult } from 'src/app/models/pagination-result.model';
import { Programas } from 'src/app/models/programas';

export interface ProgramasState extends EntityState<Programas> {
    selectProgramId: number | null;
    allItems : Array<Programas>
  }

  export function selectedProgramId(a: Programas): number {
    return a.id ? a.id : 0;
  }
  

  export const programasAdapter: EntityAdapter<Programas> = createEntityAdapter<Programas>({
    selectId: selectedProgramId,
  });
  export const initialProgramState: ProgramasState = programasAdapter.getInitialState({
    selectProgramId: null,
    allItems : []
  });
  
  export const programReducer = createReducer(
    initialProgramState,
    on(loadProgramSuccessfuly, (state, result) => {
        
        return programasAdapter.addMany(result.programas, {
          ...state,
          result,
          allItems : result.programas,
        });
      }),
  )