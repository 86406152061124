import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { EmployeesAPI } from 'src/app/apis/employees.service';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap, filter, shareReplay, take } from 'rxjs/operators';
import { Family_membersAPI } from 'src/app/apis/family_members.service';

import { nextOfflineIndex } from '../reducers/offline.reducer';
import { UpdateSync } from '../actions/offline.action';
import Partner from 'src/app/models/partner.model';
import {
  addPartner,
  addPartnerSuccess,
  deletePartner,
  deletePartnerSuccess,
  loadPartner,
  loadPartnerSuccess,
  loadPartnerType,
  loadPartnerTypeSuccess,
  updatePartner,
} from '../actions/partner.action';
import { MyPartnersAPI } from 'src/app/apis/partners.service';

@Injectable()
export class PartnerEffect {
  adapter: EntityAdapter<Partner> = createEntityAdapter<Partner>();

  loadPartnerType$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadPartnerType),
      switchMap((action) => {
        return this.partnerAPI.getPartnersType();
      }),
      map((playLoad: any) =>
        loadPartnerTypeSuccess({ partners_type: playLoad })
      )
    )
  );
  loadPartner$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadPartner),
      switchMap((action) => {
        return this.partnerAPI.getPartner();
      }),
      map((playLoad: any) => loadPartnerSuccess({ partners: playLoad }))
    )
  );

  deletePartner$ = createEffect(() =>
    this.actions.pipe(
      ofType(deletePartner),

      switchMap((action) => this.partnerAPI.deletePartner(action.id)),
      map((value: Partner) => {
        return deletePartnerSuccess({ id: value?.id });
      })
      // catchError((err) => of(dataSyncWithServerError(err)))
    )
  );

  addPartner$ = createEffect(() =>
    this.actions.pipe(
      ofType(addPartner),

      switchMap((action) => {
        return this.partnerAPI.storePartner(action.data).pipe(
          map((result: Partner) => {
            return { result, props: action };
          })
        );
      }),

      switchMap(async ({ result, props }) => {
        return addPartnerSuccess({
          data: result,
          offlineId: await this.store
            .select(nextOfflineIndex)
            .pipe(take(1))
            .toPromise(),
        });
      })
    )
  );

  addPartnerSuccess$ = createEffect(() =>
    this.actions.pipe(
      ofType(addPartnerSuccess),

      map((action: any) => {
        return UpdateSync({
          data: action.data,
          offlineId: action.offlineId,
          stateName: 'partners',
        });
      })
    )
  );
  updateFamilyMember$ = createEffect(() =>
    this.actions.pipe(
      ofType(updatePartner),
      switchMap((action) => {
        return this.partnerAPI.updatePartner(action.data,null).pipe(
          map((result) => {
            return { result };
          })
        );
      }),

      map(({ result }:any) => {
        // return updateFamilyMemberSuccess({ data: result });
        return UpdateSync({
          data: result[1],
          offlineId: result[1].id,
          stateName: 'partners',
        });
      })
    )
  );
  private state$: any;

  constructor(
    private partnerAPI: MyPartnersAPI,
    private actions: Actions,
    public translateService: TranslateService,
    private store: Store<{ connection: any; partners: any }>
  ) {
    this.state$ = this.store.select('partners').pipe(
      filter(Boolean), // Ignore the initial null state
      shareReplay(1) // Share the state across multiple subscribers
    );
  }
}
