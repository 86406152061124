import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { dev } from '../../../../config/offlines_keys.json';
import { Storage } from '@capacitor/storage';
import MoneyReporting from 'src/app/models/money_reporting.model';
import { MoneyReportingsAPI } from 'src/app/apis/money_reportings.service';
import routes from '../../../../config/routing.json';

import {
  CASH_DEPOSI_TYPE,
  PENSION_PLAN_TYPE_GOV,
  PENSION_PLAN_TYPE_NO_GOV,
} from 'src/app/utils/consts';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-bank-account-related-selector',
  templateUrl: './bank-account-related-selector.component.html',
  styleUrls: ['./bank-account-related-selector.component.scss'],
})
export class BankAccountRelatedSelectorComponent implements OnInit {
  selected_langage;
  @Input() cashflow;
  @Input() size = 12;
  @Output() cashflowChangeEmitter: EventEmitter<any> = new EventEmitter();
  @Input() is_operation = false;
  moneyReportings: MoneyReporting[] = new Array();
  owner;
  show_account_select = false;
  isDashboardView;
  constructor(
    private router: Router,
    private moneyReportingAPI: MoneyReportingsAPI,
    public translateService: TranslateService
  ) {}

  async ngOnInit() {
    this.selected_langage = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)

    if (!this.cashflow.id)
      this.cashflow.payment_methods = await this.translateService
        .get('means')
        .toPromise()
        .then((value) => value[0].value);
    this.owner = await Storage.get({ key: dev.USER_KEY }).then((value) =>
      JSON.parse(value.value)
    );
    this.moneyReportingAPI.getMoneyReporting().subscribe((moneyreportings) => {
      if (Array.isArray(moneyreportings)) {
        this.moneyReportings = moneyreportings.filter(
          (element) =>
            element.type !== PENSION_PLAN_TYPE_GOV &&
            element.type !== PENSION_PLAN_TYPE_NO_GOV &&
            element.type !== CASH_DEPOSI_TYPE
        );
      }
    });
  }
  changed(value) {
    value && value !== 'cash' && this.isDashboardView
      ? (this.show_account_select = true)
      : (this.show_account_select = false);
    this.cashflow.payment_methods = value;
    this.cashflowChangeEmitter.emit(this.cashflow);
  }

  moneyReportingChanged(value) {
    this.cashflow.money_reporting_id = value.id;
    this.cashflowChangeEmitter.emit(this.cashflow);
  }
  compareWith(e, e2) {
    return e && e2 ? e.id === e2 : e === e2;
  }
}
