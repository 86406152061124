import { Component, OnInit, Input } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { TranslateService } from '@ngx-translate/core';
import { StatisticsAPI } from 'src/app/apis/statistics.service';
import { dev } from '../../../../config/offlines_keys.json';
import { lastValueFrom, take } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { Store } from '@ngrx/store';
import { getMaterialOroductKpis } from 'src/app/utils/states-management/selector/dashboard.selector';
import { getDashboardData } from 'src/app/utils/states-management/actions/dashboard.action';

@Component({
  selector: 'app-animal-kpis',
  templateUrl: './animal-kpis.component.html',
  styleUrls: ['./animal-kpis.component.scss'],
})
export class AnimalKpisComponent implements OnInit {
  from = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  ).toISOString();
  to = new Date().toISOString();
  lang;
  coffe_unit_label;
  coffe_unit;
  coffe_rendement;
  missingLotDefinition;
  prod_cost;
  total_coffe;
  other_prod: any[] = new Array();
  currency;
  @Input() label;

  customPickerOptionsStart = {
    buttons: [
      {
        text: this.translateService.get('cancel').subscribe((value) => value),
        cssClass: 'cancel-btn',

        handler: (value) => {
          return true;
        },
      },
      {
        text: this.translateService.get('submit').subscribe((value) => value),
        cssClass: 'confirm-btn',
        handler: (value) => {
          this.from = new Date(
            value.year.value + '-' + value.month.value + '-' + value.day.value
          ).toISOString();

          return true;
        },
      },
    ],
  };
  customPickerOptionsEnd = {
    buttons: [
      {
        text: this.translateService.get('cancel').subscribe((value) => value),
        cssClass: 'cancel-btn',

        handler: (value) => {
          return true;
        },
      },
      {
        text: this.translateService.get('submit').subscribe((value) => value),
        cssClass: 'confirm-btn',
        handler: (value) => {
          this.to = new Date(
            value.year.value + '-' + value.month.value + '-' + value.day.value
          ).toISOString();

          return true;
        },
      },
    ],
  };
  constructor(
    private statisticsAPI: StatisticsAPI,
    public translateService: TranslateService,
    private store:Store
  ) {}

  async ngOnInit() {

    this.currency = await lastValueFrom(
      this.store.select(getCountry).pipe(take(1))
    );
    this.getProductKPI(this.from, this.to);
  }

  getProductKPI(from, to) {

    //TODO : to check offline
    let data = { startDate: from, endDate: to };
    this.store.dispatch(getDashboardData({ data :data }));
    this.store.select(getMaterialOroductKpis)
      .subscribe(
        (value) => {
          this.mapProduct(value);
        },
        (e) => console.log(e)
      );
    // this.statisticsAPI.getMaterialOroductKpis(from, to).subscribe((value) => {
    //   this.mapProduct(value);
    // });
  }
  filter() {
    this.getProductKPI(this.from, this.to);
  }

  mapProduct(val) {
    const keys = Object.keys(val);
    this.other_prod = new Array();

    keys.forEach((element, index) => {
      this.other_prod.push(val[element]);
    });
    this.other_prod = this.sortArra(this.other_prod);
  }

  sortArra(array) {
    if (Array.isArray(array))
      return array.sort((b, a) => {
        if (a.mergen_bruto < b.mergen_bruto) return -1;
        if (a.mergen_bruto > b.mergen_bruto) return 1;
        return 0;
      });
    else return new Array();
  }
}
