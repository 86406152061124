import { Component, Input, OnInit } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-accordion-question',
  templateUrl: './accordion-question.component.html',
  styleUrls: ['./accordion-question.component.scss'],
})
export class AccordionQuestionComponent implements OnInit {
  panelOpenState =false
  @Input('question') question
  @Input('tab') tab
  @Input() nav: IonNav;

  constructor(
    public translateService:TranslateService
  ) { }

  ngOnInit() {
  }

}
