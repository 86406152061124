import { filter, take } from 'rxjs/operators';
import { mapLangeNameObjects } from 'src/app/utils/array-filtrer';
import { Component, Input, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { dev } from '../../config/offlines_keys.json';
import { OwnerAPI } from '../../apis/owners.service';
import {
  LoadingController,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { ToastUtils } from '../../utils/toast_builder';
import { Family_membersAPI } from '../../apis/family_members.service';
import { ConfirmModalComponent } from 'src/app/widgets/confirm-modal/confirm-modal.component';
import { PriorDashboardComponent } from 'src/app/modules/prior-dashboard/prior-dashboard.component';
import { PopoverImgComponent } from 'src/app/widgets/popover-img/popover-img.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { FamilyViewTwoComponent } from 'src/app/modules/family/family-view-two/family-view-two.component';
import Owner from 'src/app/models/owner.model';
import { Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';
import { getAllCounties } from 'src/app/utils/states-management/selector/countries.selector';
import { ModalPageComponent } from 'src/app/modules/family/modal-page/modal-page.component';
import { loadFamilyMember } from 'src/app/utils/states-management/actions/family-members.action';
import { selectAllFamilyMembers } from 'src/app/utils/states-management/selector/family-members.selector';
import { cloneDeep } from 'lodash';
import { connectionStatus } from 'src/app/utils/states-management/reducers/offline.reducer';

@Component({
  selector: 'app-family-access',
  templateUrl: './family-access.component.html',
  styleUrls: ['./family-access.component.scss'],
})
export class FamilyAccessComponent implements OnInit {
  accessKeys: any = [];
  @Input() rootParams;
  @Input() nav;
  @Input() rootStack;
  family: any = [];
  family_access: any = [];
  countries;
  authNumber;
  prefix;
  selected_family_member = null;
  base_endpoint = environment.appRoot;
  has_access = false;
  has_changed = false;
  owner;

  country;
  base_url = 'assets';
  isOnline = true;
  constructor(
    private familyMemberAPI: Family_membersAPI,
    private modalController: PopoverController,
    public translateService: TranslateService,
    private ownerService: OwnerAPI,
    private toastUtils: ToastUtils,
    private store: Store<{ owner: Owner }>,
    private loadingCtrl: LoadingController
  ) {}
  loadingModal;
  async ngOnInit() {
    this.owner = cloneDeep(
      await lastValueFrom(this.store.select('owner').pipe(take(1)))
    );
    this.countries = cloneDeep(
      await lastValueFrom(this.store.select(getAllCounties).pipe(take(1)))
    );
    this.countries = mapLangeNameObjects(
      this.countries,
      this.translateService.currentLang
    );
    this.store.select(connectionStatus).subscribe((val: boolean) => {
      this.isOnline = val;
    });
 

    this.fetchFamily();

  }

  async handelChangeCountrie(ev) {
    let componentProps = { data: this.countries, component: 'login' };
    const popover = await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverImgComponent,
      'pop-over-style-login-prefix',
      ev
    );

    const { data } = await popover.onDidDismiss();
    if (data && data.data) {
      this.country = this.countries.find((val) => val.id == data.data.id);
      this.prefix = this.country.indicatif;
    }
  }
  async addFamilyMember() {
    // this.nav.setRoot(FamilyViewTwoComponent, { nav: this.nav, sub_tab: true,info_label:'titles.family_list_title' });
    let modal = await this.toastUtils.modalBuilder(ModalPageComponent);

    modal
      .onDidDismiss()
      .then(() => {
        this.store.dispatch(loadFamilyMember());
      })
      .catch(console.log);
  }
  async fetchFamily() {
    this.family_access = new Array();
    this.family = new Array();
    this.accessKeys = new Array();
    this.store.dispatch(loadFamilyMember());
    this.store.select(selectAllFamilyMembers).subscribe((value) => {
      this.family = value;
      this.selected_family_member = this.family.find((value) => value?.user);

      if (this.selected_family_member && this.selected_family_member?.user) {
        this.has_access = true;
        this.authNumber = this.selected_family_member?.user?.mobile_number;
        this.prefix = this.selected_family_member?.user?.prefix;
        this.country = this.countries.find(
          (val) => val?.indicatif == this.prefix
        );
      }
    });
    // this.fetchUserAccess();
  }

  async fetchUserAccess() {
    this.ownerService.getAccessByOwners().subscribe((values: any) => {
      this.selected_family_member = this.family.find(
        (value) =>
          value?.user?.id &&
          values.filter(
            (acces) => value?.user?.mobile_number == acces.mobile_number
          )
      );

      if (this.selected_family_member && this.selected_family_member?.user) {
        this.has_access = true;
        this.authNumber = this.selected_family_member?.user?.mobile_number;
        this.prefix = this.selected_family_member?.user?.prefix;
        // this.country = this.countries.find(
        //   (val) => val.id == this.selected_family_member?.user?.county.id
        // );
      }
    });
  }

  async addAccess(prefix, access, action) {
    let message =
      action == 'add'
        ? await this.translateService.instant('are_you_sure_invite')
        : await this.translateService.instant(
            'are_you_sure_update_mobile_number'
          );

    const confirmation = await this.getConfirmation(
      message + ' +' + prefix + access + '?'
    );
    if (confirmation) {
      this.loadingModal = await this.loadingCtrl.create({
        spinner: 'bubbles',
        mode: 'ios',
      });
      this.loadingModal.present();
      if (action == 'add') {
        await this.ownerService
          .addAccessToUser(
            prefix,
            access,
            await this.translateService.instant('sms_invitation'),
            this.selected_family_member?.id
          )
          .subscribe(async (value: any) => {
            await this.loadingModal.dismiss();

            this.showErrorToast(
              value.message,
              value.status == 200 ? 'success' : 'danger'
            );
            if (value.status == 200) this.fetchFamily();
          });
      } else if (action == 'update') {
        await this.ownerService
          .updateFamilyMemberMobileAndAccess(
            this.authNumber,
            this.prefix,
            this.selected_family_member?.user?.id,
            this.owner?.id
          )
          .subscribe(async (value: any) => {
            await this.loadingModal.dismiss();

            this.showErrorToast(
              value.message,
              value.status == 200 ? 'success' : 'danger'
            );
            this.fetchFamily();
          });
      }
    }
  }

  async deleteAccess() {
    let msg =
      this.translateService.instant('stop_sharing') +
      ' ' +
      this.selected_family_member.name +
      (this.translateService.currentLang == 'es'
        ? this.translateService.instant('your_account')
        : '') +
      ' ?';
    const confirmation = await this.getConfirmation(msg);
    if (confirmation) {
      this.loadingModal = await this.loadingCtrl.create({
        spinner: 'bubbles',
        mode: 'ios',
      });
      this.loadingModal.present();
      this.ownerService
        .deleteAccess(this.selected_family_member?.user.id)
        .subscribe(async (value: any) => {
          if (value.status == 204) {
            this.selected_family_member = null;
            this.authNumber = null;
            this.showErrorToast(value.message, 'success');

            // this.prefix=null;
          }
          this.fetchFamily();
          await this.loadingModal.dismiss();
        });
    }
  }

  async getConfirmation(text) {
    let componentProps = {
      modalController: this.modalController,
      confirmation_text: text,
      title: await this.translateService
        .get('share_with_family_member')
        .toPromise(),
      cancel_text: await this.translateService.get('cancel').toPromise(),
      submit_text: await this.translateService.get('submit').toPromise(),
    };
    var modal = await this.toastUtils.popOverBuilder(
      componentProps,
      ConfirmModalComponent,
      ['pop-over-style-confirm']
    );
    const { data } = await modal.onWillDismiss();
    return data?.confirmed;
  }

  return() {
    this.nav.setRoot(this.rootStack, {
      nav: this.nav,
      rootStack: PriorDashboardComponent,
    });
  }

  async showErrorToast(message, alert) {
    this.toastUtils.toastBuilder(
      await this.translateService.instant(message),
      'bottom',
      '',
      alert
    );
  }

  async share() {
    if (
      isNaN(this.authNumber) ||
      this.authNumber.toString().length > 10 ||
      this.authNumber.toString().length < 5
    ) {
      this.toastUtils.toastBuilder(
        '',
        'bottom',
        await this.translateService.instant('errors.mobile_number'),
        'dark'
      );
      return;
    }
    if (!this.prefix) {
      this.toastUtils.toastBuilder(
        '',
        'bottom',
        await this.translateService.instant('errors.all_fields'),
        'dark'
      );

      return;
    }
    if (this.prefix && this.authNumber) {
      this.addAccess(this.prefix.toString(), this.authNumber.toString(), 'add');
    } else {
      this.showErrorToast('errors.missing_field', 'danger');
    }
  }
  async update() {
    if (
      isNaN(this.authNumber) ||
      this.authNumber.toString().length > 10 ||
      this.authNumber.toString().length < 5
    ) {
      this.toastUtils.toastBuilder(
        '',
        'bottom',
        await this.translateService.instant('errors.mobile_number'),
        'dark'
      );
      return;
    }
    if (!this.prefix) {
      this.toastUtils.toastBuilder(
        '',
        'bottom',
        await this.translateService.instant('errors.all_fields'),
        'dark'
      );

      return;
    }
    if (this.prefix && this.authNumber) {
      this.addAccess(
        this.prefix.toString(),
        this.authNumber.toString(),
        'update'
      );
    } else {
      this.showErrorToast('errors.missing_field', 'danger');
    }
  }

  async selectFamilyMember(ev) {
    const popover = await this.toastUtils.popOverBuilder(
      {
        data: this.family,
        component: 'mano-de-obra',
      },
      PopoverImgComponent,
      'pop-over-style',
      ev
    );
    const { data } = await popover.onDidDismiss();
    if (data?.data) {
      if (data?.data) {
        this.selected_family_member = data.data;
        if (this.selected_family_member?.user) {
          this.has_changed = false;

          // this.prefix = data.data?.user?.prefix
          //   ? data?.data?.user?.prefix
          //   : this.owner.prefix;
          // this.authNumber = data.data?.user?.mobile_number
          //   ? data?.data?.user?.mobile_number
          //   : '';
        } else {
          // this.prefix = this.owner?.user?.prefix;
          // this.authNumber = '';
          this.has_changed = true;
        }
      }
    }
  }
}
