import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { EmployeesAPI } from 'src/app/apis/employees.service';
import { Family_member } from 'src/app/models/family_member.model';
import {
  addFamilyMember,
  addFamilyMemberSuccess,
  deleteFamilyMember,
  updateFamilyMember,
  updateFamilyMemberSuccess,
} from './../actions/family-members.action';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap, filter, shareReplay, take, catchError } from 'rxjs/operators';
import { Family_membersAPI } from 'src/app/apis/family_members.service';
import { calculateAge } from '../../pipes-utils/date_formatter';
import {
  deleteFamilyMemberSuccess,
  loadFamilyMember,
  loadFamilyMemberSuccess,
} from '../actions/family-members.action';
import { nextOfflineIndex } from '../reducers/offline.reducer';
import { UpdateSync } from '../actions/offline.action';
import { of } from 'rxjs';

@Injectable()
export class FamilyMemberEffect {
  adapter: EntityAdapter<Family_member> = createEntityAdapter<Family_member>();

  loadFmiliesMembers$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadFamilyMember),
      switchMap((action) => {
        return this.familyMemberAPI.getFamilyMembers();
      }),
      map((playLoad) => {
        playLoad.map((element) => {
          if (element.birth_date)
            element.age = calculateAge(new Date(element.birth_date));
          else element.age = 0;
          return element;
        });
        return loadFamilyMemberSuccess({ familyMembers: playLoad });
      })
    )
  );

  deleteFamilyMember$ = createEffect(() =>
    this.actions.pipe(
      ofType(deleteFamilyMember),

      switchMap((action) => this.familyMemberAPI.deleteFamilyMember(action.id)),
      map((value) => {
        return deleteFamilyMemberSuccess({ id: value?.id });
      })
      // catchError((err) => of(dataSyncWithServerError(err)))
    )
  );

  updateFamilyMember$ = createEffect(() =>
    this.actions.pipe(
      ofType(updateFamilyMember),
      switchMap((action) => {
        return this.familyMemberAPI.updateFamilyMembers(action.data).pipe(
          map((result) => {
            return { result };
          })
        );
      }),

      map(({ result }) => {
        // return updateFamilyMemberSuccess({ data: result });
        return UpdateSync({
          data: result,
          offlineId: result.id,
          stateName: 'familyMembers',
        });
      })
    )
  );

  addFamilyMember$ = createEffect(() =>
    this.actions.pipe(
      ofType(addFamilyMember),

      switchMap((action) => {
        return this.familyMemberAPI.storeFamilyMembers(action.data).pipe(
          switchMap(async (result) => {
            return addFamilyMemberSuccess({
              data: result,
              offlineId: await this.store
                .select(nextOfflineIndex)
                .pipe(take(1))
                .toPromise(),
            });
          })
        );
      })
    )
  );
  addFamilyMemberSuccess$ = createEffect(() =>
    this.actions.pipe(
      ofType(addFamilyMemberSuccess),

      switchMap((action: any) => {
        this.store.dispatch(
          UpdateSync({
            data: action.data,
            offlineId: action.offlineId,
            stateName: 'familyMembers',
          })
        );
        if (action?.props?.should_add_employee) {
          let member = action.data;
          member.farm_id = action?.props.farm_id;
          member.morphable_type = 'FamilyMember';
          member.morphable_id = member.id;
          return this.employeeAPI.saveEmployee(member);
        } else {
          // return of(loadFamilyMember());
          return;
        }
      })
      // switchMap(({ result, props }) => {
      //   return of(loadFamilyMember());
      // })
    )
  );
  private state$: any;

  constructor(
    private familyMemberAPI: Family_membersAPI,
    private actions: Actions,
    public translateService: TranslateService,
    private employeeAPI: EmployeesAPI,
    private store: Store<{ connection: any; familyMembers: any }>
  ) {
    this.state$ = this.store.select('familyMembers').pipe(
      filter(Boolean), // Ignore the initial null state
      shareReplay(1) // Share the state across multiple subscribers
    );
  }
}
