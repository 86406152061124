import { RoutingConfig } from 'src/app/utils/routing-config';
import { ToastUtils } from './../../../utils/toast_builder';
import { Component, Input, OnInit } from '@angular/core';
import { PhotoService, PhotoTaken } from '../../../services/photo.service';
import { OwnerAPI } from '../../../apis/owners.service';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../config/offlines_keys.json';
import { Router } from '@angular/router';
import routes from '../../../config/routing.json';
import { environment } from '../../../../environments/environment';
import { IonNav, ModalController } from '@ionic/angular';
import { PriorDashboardComponent } from 'src/app/modules/prior-dashboard/prior-dashboard.component';
import { SummaryComponent } from '../../../modules/dashboard/summary/summary.component';
import { DomSanitizer } from '@angular/platform-browser';
import { PhotoEditorComponent } from './photo-editor/photo-editor.component';
import { TranslateService } from '@ngx-translate/core';
import { from, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import Owner from 'src/app/models/owner.model';
import {
  DELETE_PROFILE_PHOTO,
  getOwnerById,
  storeOwnerPhoto,
  updateOwnerPhoto,
} from 'src/app/utils/states-management/actions/owner.action';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'app-profile-photo',
  templateUrl: './profile-photo.component.html',
  styleUrls: ['./profile-photo.component.scss'],
  providers: [OwnerAPI],
})
export class ProfilePhotoComponent implements OnInit {
  image_path: any;
  selectedLangage;
  isDashboardView;
  hasSelectedPhoto = false;
  imageId;
  cropConfig;
  @Input() nav: IonNav;
  owner;
  constructor(
    private store: Store<{ owner: Owner }>,
    public photoService: PhotoService,
    private router: Router,
    private ownerAPI: OwnerAPI,
    private sanitizer: DomSanitizer,
    private modalController: ModalController,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private routingConfig: RoutingConfig
  ) {}

  async ngOnInit() {
    this.store.select('owner').subscribe((value)=>{
    this.owner = cloneDeep(value)
    })

    this.translate();
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
    this.cropConfig = await this.routingConfig.getCropFormConfig();
    from(Storage.get({ key: dev.FILE_KEY }))
      .pipe(
        map((value: any) => {
          return JSON.parse(value.value);
        }),
        take(1),
        switchMap((value) => {
          this.imageId = value;

          if (value?.path) {
            return of(environment.appRoot + value.path);
          } else {
            return of('assets/photo-placeholder.svg');
          }
        }),
        map((value: any) => value)
      )
      .subscribe((value) => {
        this.image_path = value;
      });
  }

  async addPhotoToGallery() {
    from(this.photoService.addNewToGallery())
      .pipe(
        map((value: PhotoTaken) => value),
        take(1),
        switchMap((value: PhotoTaken) => {
          if (value?.webviewPath) {
            this.hasSelectedPhoto = true;

            return from(
              this.showModificationModal(
                this.sanitizer.bypassSecurityTrustResourceUrl(value.webviewPath)
              )
            );
          } else {
            this.hasSelectedPhoto = false;
          }
        })
      )
      .subscribe();
  }

  async imgDelete() {
    this.image_path = 'assets/photo-placeholder.svg';
    this.hasSelectedPhoto = false;
    this.store.dispatch({ type: DELETE_PROFILE_PHOTO });

  }

  async cancelar() {
    this.image_path = await Storage.get({ key: dev.FILE_KEY }).then((value) => {
      const savedPath = JSON.parse(value.value);
      if (savedPath?.path) {
        return environment.appRoot + JSON.parse(value.value).path;
      } else return 'assets/photo-placeholder.svg';
    });
    this.hasSelectedPhoto = false;
  }

  async translate() {
    this.selectedLangage = this.translateService.currentLang;
  }

  async goBack() {
    this.router.navigateByUrl('/person/profile-permission');
  }

  async submitOwnerForm() {
    this.imageId = JSON.parse((await Storage.get({ key: dev.FILE_KEY })).value);

    if (
      this.image_path != 'assets/photo-placeholder.svg' &&
      !this.imageId?.id &&
      this.hasSelectedPhoto
    ) {
      await this.insertImage();
    } else if (
      !this.image_path.includes(this.imageId?.path) &&
      this.hasSelectedPhoto
    ) {
      await this.updateImage(this.imageId?.id);
    }
    this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }

  async dashboardSubmit() {
    this.imageId = JSON.parse((await Storage.get({ key: dev.FILE_KEY })).value);
    if (!this.imageId?.id) await this.insertImage();
    else {
      if (this.image_path.includes(this.imageId?.path))
        this.nav.setRoot(SummaryComponent, {
          nav: this.nav,
          rootStack: PriorDashboardComponent,
        });
      else await this.updateImage(this.imageId?.id);
      this.hasSelectedPhoto = false;
    }
  }

  async insertImage() {
    const file = await fetch(this.image_path).then((result) => result.blob());
    this.store.dispatch(storeOwnerPhoto({ file: file }));

  }

  async updateImage(id) {
    const file = await fetch(this.image_path).then((result) => result.blob());
    this.store.dispatch(updateOwnerPhoto({ id: id, file: file }));
    // this.ownerAPI
    //   .updateOwnerPhoto(id, await file)
    //   .pipe(
    //     map((result) => result),
    //     switchMap((result) => this.ownerAPI.getOwnerById()),
    //     map((getOwnerResult) => getOwnerResult),
    //     switchMap((getOwnerResult) => from(handleResponse(getOwnerResult))),
    //     map((finishedWork) => finishedWork)
    //   )
    //   .subscribe();
  }

  async showModificationModal(image_path) {
    let componentProps = {
      image_path: image_path,
      modalController: this.modalController,
      cancel: await this.translateService.instant('cancel'),
      confirm: await this.translateService.instant('confirm'),
    };
    let modal = await this.toastUtils.modalBuilder(
      PhotoEditorComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper']
    );

    const { data } = await modal.onWillDismiss();
    if (data?.cancel) this.cancelar();
    if (data?.image && data?.img64String) {
      if (data.img64String.length * 2 > 10 * 2 ** 40) {
       await this.toastUtils
          .toastBuilder(
            '',
            'bottom',
            await this.translateService.instant('errors.img_size'),
            'danger'
          );
      } else {
        this.image_path = data?.image;
      }
    }
  
  }
}
