import { MORPH_Farm } from 'src/app/utils/consts';
import { MORPH_FamilyMember, MORPH_Owner } from './../utils/consts';
import { CashflowAPI } from './cashflow.service';
import { ProgramasAPI } from './programas.service';
import { OperationsAPI } from './operations.service';
import { MaterialProductAPI } from './material_product.service';
import { GoodsAPI } from './goods.service';
import { MoneyReportingsAPI } from './money_reportings.service';
import { MyPartnersAPI } from './partners.service';
import { EmployeesAPI } from './employees.service';
import { OwnerAPI } from './owners.service';
import { LotAPI } from './lot.service';
import { ProductAPI } from './product.service';
import { FarmAPI } from './farm.service';
import { ClustersAPI } from './clusters.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { dev } from '../config/offlines_keys.json';
import { Family_membersAPI } from './family_members.service';
import { environment } from '../../environments/environment';
import { from, Observable, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Network } from '@capacitor/network';
@Injectable()
export class OfflineSyncService {
  owner_id;
  token;
  online = true;

  constructor(private httpClient: HttpClient) {
    Network.addListener('networkStatusChange', async (status) => {
      let base_url = environment.appRoot + '/api/test-connection';
      this.httpClient.get(base_url).subscribe(
        (value) => {
          if (status.connected && value) {
            this.online = true; //true
            this.dispatch();
          } else {
            this.online = false;
          }
        },
        (e) => {
          this.online = false;
        }
      );
    });
  }

  entitySubject: Subject<any[]> = new Subject<any[]>();
  entityData: any;
  createdDate: any;

  async doItLater(operation, service, data, fn): Promise<any> {
    let timestamp = new Date();
    let key;
    if (operation != 'remove') {
      key = `offline:${service}:${operation}:${fn}:${
        data.id
      }:${timestamp.getTime()}`;
    } else {
      if (
        service == 'GoodsAPI' ||
        fn == 'deleteVenteCoffeCashflow' ||
        fn == 'deleteProductionCoffe'
      ) {
        data = data.id;
      }
      key = `offline:${service}:${operation}:${fn}:${data}:${timestamp.getTime()}`;
      //  Storage.get({key : `offline`}).then((value)=>{
      //   })
    }

    Storage.get({ key: `offline` }).then((value) => {
      var tab = JSON.parse(value.value);
      var Findex = -1;

      if (operation == 'remove') {
        if (tab) {
          tab.forEach(async (value, index) => {
            if (value.key.startsWith(`offline:`)) {
              const segments = value.key.split(':');
              const id = segments[4];
              const operation = segments[2];
              // if(service == "GoodsAPI"){
              //   data = data.id
              // }
              if (data == id && operation == 'create') {
                Findex = index;
              }
            }
          });
          if (Findex != -1) {
            tab.splice(Findex, 1);
            Storage.set({ key: `offline`, value: JSON.stringify(tab) });
          } else {
            tab.push({ key: key, value: data });

            Storage.set({ key: `offline`, value: JSON.stringify(tab) });
          }
        } else {
          var newTab = [];
          newTab.push({ key: key, value: data });
          Storage.set({ key: `offline`, value: JSON.stringify(newTab) });
        }
      } else {
        if (tab) {
          tab.push({ key: key, value: data });

          Storage.set({ key: `offline`, value: JSON.stringify(tab) });
        } else {
          var newTab = [];
          newTab.push({ key: key, value: data });
          Storage.set({ key: `offline`, value: JSON.stringify(newTab) });
        }
      }
    });

    return data;
  }

  doUpdateLater(operation, service, fn, id, data): Promise<any> {
    let timestamp = new Date();
    const key = `offline:${service}:${operation}:${fn}:${id}:${timestamp.getTime()}`;
    var Findex = -1;
    var Fkey = '';

    Storage.get({ key: `offline` }).then((value) => {
      var tab = JSON.parse(value.value);

      tab.forEach(async (value, index) => {
        if (value.key.startsWith(`offline:`)) {
          const segments = value.key.split(':');
          const id = segments[4];
          const operation = segments[2];
          if (data.id == id && operation == 'create') {
            Findex = index;
            Fkey = value.key;
          }
        }
      });

      if (Findex != -1) {
        tab[Findex].value = data;
        tab[Findex].key = Fkey;

        Storage.set({ key: `offline`, value: JSON.stringify(tab) });
      } else if (tab) {
        tab.push({ key: key, value: data });

        Storage.set({ key: `offline`, value: JSON.stringify(tab) });
      } else {
        var newTab = [];
        newTab.push({ key: key, value: data });
        Storage.set({ key: `offline`, value: JSON.stringify(newTab) });
      }
    });
    return data;
  }
  async getToken() {
    this.token = await Storage.get({ key: dev.ACCESS_TOKEN }).then(
      (value) => value.value
    );
  }
  async create(data: any, service: string, fn: string): Promise<void> {
    var copyData = data;
    let result: Promise<any>;

    this.entityData = [];
    // IF CALLED BY doItLater, DATA ALREADY HAS AN ID
    if (!this.online) {
      data.id = data.id ? data.id : Math.floor(1000 * Math.random());
      if (service == 'GoodsAPI' && data.product) {
        var newData = data.product;
        newData['material_product_id'] = data.product.id;

        newData.id = data.id;
        newData['ownerId'] = data.ownerId;
        delete newData.material_categorie_id;
        result = this.doItLater('create', service, newData, fn);
      } else {
        result = this.doItLater('create', service, data, fn);
      }
    } else {
      if (service == 'GoodsAPI' && !data.product) {
        let sendData = {};
        sendData['product'] = data;
        sendData['product'].id = data.material_product_id;
        sendData['ownerId'] = data.ownerId;
        data = sendData;
      }

      //Generic fn to Call all imported fn form services
      result = this.SwitchFunction(service, fn, data)
        .toPromise()
        .then(async (value) => {
          if (value.ambassador_id) {
            await Storage.set({
              key: `cache:${service}`,
              value: JSON.stringify(value),
            }).then((val) => val);
            await Storage.set({
              key: dev.FARM_KEY,
              value: JSON.stringify(value),
            }).then((val) => val);
          } else if (service == 'LotAPI') {
            // resultFromresult = value;
            // this.createdDate = value
            return value;
          }
          return value;
        })

    }
    await result.then((value) => {
      data = value;
    });
    if (service == 'ProgramasAPI') {
      service = 'Vote';
    }
    if (fn != 'insertFarmAndLinkItToUser') {
      await Storage.get({ key: `cache:${service}` }).then(async (val) => {
        if (JSON.parse(val.value) && JSON.parse(val.value).length > 0) {
          this.entityData = JSON.parse(val.value);
        } else {
          // this.entityData.push(result);

          await Storage.set({
            key: `cache:${service}`,
            value: JSON.stringify(this.entityData),
          }).then((val) => val);
        }
      });
    }
    var exist;
    //check if service is EmployeesAPI then search exist by morphable_type & morphable_id
    //else find by id
    if (service == 'EmployeesAPI' && data.morphable_id) {
      exist = this.entityData.some(
        (el) =>
          el.morphable_type == data.morphable_type &&
          el.morphable_id == data.morphable_id
      );
    } else {
      exist = this.entityData.some((el) => el.id === data.id);
    }

    if (!exist) {
      if (!data.farm) {
        // if(service =="GoodsAPI" && data.product){
        //   await this.entityData.push(data.product);

        // }else{

        if (service == 'GoodsAPI' && copyData.product) {
          data['name'] = copyData.product.name;
        }

        if (service == 'OperationsAPI') {
          if (!data.morphable) {
            if (data.morphable_type == 'farm') {
              const farm = await Storage.get({ key: dev.FARM_KEY }).then(
                (value) => value.value
              );
              data['morphable'] = JSON.parse(farm);
            } else if (data.morphable_type == 'owner') {
              const owner = await Storage.get({ key: dev.USER_KEY }).then(
                (value) => value.value
              );
              data['morphable'] = JSON.parse(owner);
            } else {
              const familyMembers = await Storage.get({
                key: 'cache:Family_members',
              }).then((value) => value);
              let FM = JSON.parse(familyMembers.value).find(
                (value) => value.id == data.morphable_id
              );
              data['morphable'] = FM;
            }
          } else {
          }
          // await Storage.set({key : `cache:${service}`, value : JSON.stringify(dataFromCache)})
        }

        this.entityData.push(data);

        await Storage.set({
          key: `cache:${service}`,
          value: JSON.stringify(this.entityData),
        }).then((val) => val);
      }
    } else if (exist && service == 'Vote') {
      let i = this.entityData.findIndex((obj) => obj.id == data.id);
      this.entityData[i] = data;
      // this.entityData.((el) => el.id === data.id)
      data.stars_number = parseInt(data.stars_number);
      await Storage.set({
        key: `cache:${service}`,
        value: JSON.stringify(this.entityData),
      }).then((val) => val);
    }
    return result;
  }

  list(service: string, fn, data, token = ''): Observable<any> {
    if (token) {
      this.token = token;
    }

    this.entityData = [];
    if (service == 'ProgramasAPI' && fn == 'getVotes') {
      service = 'Vote';
    }
    if (
      service == 'ProductAPI' &&
      fn == 'getAgriculturalCategoriesAndProduct'
    ) {
      service = 'ProductAPI-agricultural';
    }

    // TODO : add Goods service and function to storage key

    var newKey;
    if (
      service == 'CashflowAPI' &&
      fn != 'getCashflowByDate' &&
      fn != 'getCashflow'
    ) {
      newKey = `cache:${service}:${fn}`;
    } else {
      newKey = `cache:${service}`;
    }
    return from(Storage.get({ key: newKey })).pipe(
      switchMap((cache) => {
        // SYNC CACHE WITH DATABASE

        var result;
        var operationData;
        if (this.online) {
          if (service == 'Vote' && fn == 'getVotes') {
            service = 'ProgramasAPI';
          }
          if (service == 'ProductAPI-agricultural') {
            service = 'ProductAPI';
          }
          this.getToken().then(async () => {
            result = await this.SwitchFunction(service, fn, data); // Data = owner_id  //Generic fn to Call all imported fn form services
            result.subscribe((value) => {
              if (JSON.parse(cache.value) != value) {
                if (service == 'ProgramasAPI' && fn == 'getVotes') {
                  service = 'Vote';
                }
                if (
                  service == 'ProductAPI' &&
                  fn == 'getAgriculturalCategoriesAndProduct'
                ) {
                  service = 'ProductAPI-agricultural';
                }
                // if(service != 'MoneyReportingsAPI'){
                Storage.set({
                  key: newKey,
                  value: JSON.stringify(value),
                });
                // }
              }
            });
          });
        }
        if (JSON.parse(cache.value)) {
          return of(cache);
        } else {
          if (service == 'ProgramasAPI' && fn == 'getVotes') {
            service = 'Vote';
          }

          if (
            service == 'ProductAPI' &&
            fn == 'getAgriculturalCategoriesAndProduct'
          ) {
            service = 'ProductAPI-agricultural';
          }
          Storage.set({ key: newKey, value: '[]' });
          return of(cache);
        }
      })
    );
  }

  async remove(service: string, fn, data): Promise<void> {
    let result: Promise<any>;
    this.token = await Storage.get({ key: dev.ACCESS_TOKEN }).then(
      (value) => value.value
    );

    let user = await Storage.get({ key: dev.USER_KEY }).then((value) =>
      JSON.parse(value.value)
    );

    if (
      (service == 'GoodsAPI' && !data.id) ||
      fn == 'deleteVenteCoffeCashflow' ||
      fn == 'deleteProductionCoffe'
    ) {
      data = {
        id: data,
        id_user: user.id,
      };
      // this.SwitchFunction(service,fn,newData) // Data = id
    }
    if (!this.online) {
      result = this.doItLater('remove', service, data, fn);
    } else {
      //Generic fn to Call all imported fn form services

      this.SwitchFunction(service, fn, data); // Data = id
    }

    await Storage.get({ key: `cache:${service}` }).then((val) => {
      this.entityData = JSON.parse(val.value);
    });

    if (service == 'EmployeesAPIx') {
      this.entityData = this.entityData.filter(
        (el) =>
          el.morphable_type != data.morphable_type &&
          el.morphable_id != data.morphable_id
      );
    } else {
      if (
        service == 'GoodsAPI' ||
        fn == 'deleteVenteCoffeCashflow' ||
        fn == 'deleteProductionCoffe'
      ) {
        data = data.id;
      }
      this.entityData = this.entityData.filter((el) => el.id != data);
    }
    await Storage.remove({ key: `cache:${service}` }).then(async () => {
      await Storage.set({
        key: `cache:${service}`,
        value: JSON.stringify(this.entityData),
      });
    });

    return result;
  }

  async detail(service: string, fn, id: any): Promise<any> {
    if (!id) {
      return null;
    }
    if (service == 'ProgramasAPI') {
      service = 'Vote';
    }
    const cache = await Storage.get({ key: `cache:${service}` }).then(
      (value) => {
        if (service == 'Vote') {
          this.entityData = JSON.parse(value.value).find(
            (el) => el.id == id.id_prog
          );
        } else if (service == 'EmployeesAPI') {
          this.entityData = JSON.parse(value.value).find(
            (el) => el.id == id.id
          );
        } else {
          this.entityData = JSON.parse(value.value).find((el) => el.id == id);
        }
      }
    );
    if (this.entityData) {
      return this.entityData;
    } else {
      if (service == 'Vote') {
        service = 'ProgramasAPI';
      }

      return this.SwitchFunction(service, fn, id); // Data = id
    }
  }

  async update(data: any, id, service: string, fn: string): Promise<void> {
    let result: Promise<any>;
    this.token = await Storage.get({ key: dev.ACCESS_TOKEN }).then(
      (value) => value.value
    );
    var storage = await Storage.get({ key: `cache:${service}` });
    this.entityData = JSON.parse(storage.value);
    if (service == 'OperationsAPI') {
      if (!data.morphable) {
        if (
          data.morphable_type == 'farm' ||
          data.morphable_type == MORPH_Farm
        ) {
          const farm = await Storage.get({ key: dev.FARM_KEY }).then(
            (value) => value.value
          );
          data['morphable_type'] = MORPH_Farm;
          data['morphable'] = JSON.parse(farm);
        } else if (
          data.morphable_type == 'owner' ||
          data.morphable_type == MORPH_Owner
        ) {
          const owner = await Storage.get({ key: dev.USER_KEY }).then(
            (value) => value.value
          );
          data['morphable_type'] = MORPH_Owner;

          data['morphable'] = JSON.parse(owner);
        } else {
          const familyMembers = await Storage.get({
            key: 'cache:Family_members',
          }).then((value) => value);
          let FM = JSON.parse(familyMembers.value).find(
            (value) => value.id == data.morphable_id
          );
          data['morphable_type'] = MORPH_FamilyMember;

          data['morphable'] = FM;
        }
      }
      // await Storage.set({key : `cache:${service}`, value : JSON.stringify(dataFromCache)})
    }

    if (!this.online) {
      result = this.doUpdateLater('update', service, fn, id, data);
      if (fn != 'upDateFarm') {
        let i = this.entityData.findIndex((obj) => obj.id == id);
        this.entityData[i] = data;
        this.entityData[i].id = id;
      } else {
        this.entityData = data;
      }
    } else {
      if (fn != 'upDateFarm') {
        data.id = id;
        let i = this.entityData.findIndex((obj) => obj.id == id);
        this.entityData[i] = data;
        this.SwitchFunction(service, fn, data);
      } else {
        result = this.SwitchFunction(service, fn, data)
          .toPromise()
          .then((value) => {
            value;
          });

        this.entityData = data;
        await Storage.set({
          key: dev.FARM_KEY,
          value: JSON.stringify(this.entityData),
        }).then((val) => val);
      }
    }
    await Storage.set({
      key: `cache:${service}`,
      value: JSON.stringify(this.entityData),
    }).then((val) => val);

    return result;
  }

  async dispatch() {
    const keysToRemoveFromStorage = [];
    var offline = [];

    //check this
    await Storage.get({ key: 'offline' }).then((value) => {
      offline = JSON.parse(value.value);
    });

    if (offline) {
      offline.forEach(async (value, index) => {
        if (value.key.startsWith(`offline:`)) {
          const segments = value.key.split(':');
          const id = segments[4];
          const fn = segments[3];
          const timestamp = segments[5];
          const operation = segments[2];
          const service = segments[1];
          keysToRemoveFromStorage.push(value);
          if (operation === 'create') {
            this.create(value.value, service, fn).then(async (value) => {
              if (service == 'LotAPI') {
                let data = await Storage.get({ key: dev.LOT_KEY }).then(
                  async (val) => {
                    var newData = JSON.parse(val.value).filter(
                      (el) => el.id != id
                    );
                    newData.push(value);
                    await Storage.set({
                      key: dev.LOT_KEY,
                      value: JSON.stringify(newData),
                    }).then((val) => val);
                  }
                );
              }

              let data = await Storage.get({ key: `cache:${service}` }).then(
                async (val) => {
                  return JSON.parse(val.value);
                }
              );

              var newData = data.filter((el) => el.id != id);
              // newData.push(value)
              await Storage.set({
                key: `cache:${service}`,
                value: JSON.stringify(newData),
              }).then((val) => val);
            });
          } else if (operation === 'update') {
            this.update(value.value, id, service, fn);
          } else if (operation === 'remove') {
            this.remove(service, fn, id);
          }
        }
      });
    }

    Storage.set({ key: `offline`, value: JSON.stringify([]) });
  }

  SwitchFunction(service, fn, data): Observable<any> {
    let f_memberAPI = new Family_membersAPI(this.httpClient);
    let clusterApi = new ClustersAPI(this.httpClient);
    let farmAPI = new FarmAPI(this.httpClient);
    let prodcutApi = new ProductAPI(this.httpClient);
    let lotAPi = new LotAPI(this.httpClient);
    let ownerAPI = new OwnerAPI(this.httpClient);
    let employeAPI = new EmployeesAPI(this.httpClient);
    let partnerAPI = new MyPartnersAPI(this.httpClient);
    let money_reportingAPI = new MoneyReportingsAPI(this.httpClient);
    let goodApi = new GoodsAPI(this.httpClient);
    let materialProductAPI = new MaterialProductAPI(this.httpClient);
    let operationAPI = new OperationsAPI(this.httpClient);
    let programasAPI = new ProgramasAPI(this.httpClient);
    let cashflowApi = new CashflowAPI(this.httpClient);

    switch (service) {
      case 'Family_members':
        switch (fn) {
          case 'storeFamilyMembers':
            return f_memberAPI.storeFamilyMembers(data);
            break;
          case 'getFamilyMembers':
            return f_memberAPI.getFamilyMembers();
            break;
          case 'getFamilyMember':
            return f_memberAPI.getFamilyMember(data);
            break;
          case 'deleteFamilyMember':
            return f_memberAPI.deleteFamilyMember(data);
            break;
          // case 'deleteFamilyMembers': return f_memberAPI.deleteFamilyMembers(data); break;
          case 'updateFamilyMembers':
            return f_memberAPI.updateFamilyMembers(data);
            break;
        }

        break;
      case 'ClustersAPI':
        switch (fn) {
          case 'fetchClusterByCountryID':
            return clusterApi.fetchClusterByCountryID(data);
            break;
        }
      case 'FarmAPI':
        switch (fn) {
          case 'insertFarmAndLinkItToUser':
            return farmAPI.insertFarmAndLinkItToUser(data);
            break;
          case 'upDateFarm':
            // return farmAPI.upDateFarm(data, data.id);
            break;
        }
      case 'ProductAPI':
        switch (fn) {
          //tofix
          case 'getAgriculturalCategoriesAndProduct':
            return prodcutApi.getAgriculturalCategoriesAndProduct();
            break;
          case 'getProductByCategoriesId':
            return prodcutApi.getProductByCategoriesId(data);
            break;
          // case 'getProductByCategoriesId':
          //   return prodcutApi.getCoffeProduction(data); //toFix
          //   break;
        }
      case 'LotAPI':
        switch (fn) {
          case 'insertLot':
            return lotAPi.insertLot(data);
            break;
          case 'updateLot':
            return lotAPi.updateLot(data);
            break;
          case 'deleteLot':
            return lotAPi.deleteLot(data);
            break;
        }
      case 'OwnerAPI':
        switch (fn) {
          case 'getOwnerById':
            return ownerAPI.getOwnerById();
            break;
        }
      case 'EmployeesAPI':
        switch (fn) {
          //to complete and fix
          case 'saveEmployee':
            return employeAPI.saveEmployee(data);
            break;
          // case 'getEmplyeeData':
          //   return employeAPI.getEmplyeeData();
          //   break;
          case 'getPermanentOrTomporalData':
            return employeAPI.getPermanentOrTomporalData();
            break;
          case 'deleteEmployee':
            return employeAPI.deleteEmployee(data);
            break;
          case 'updateEmployee':
            return employeAPI.updateEmployee(data);
            break;
        }
      case 'MyPartnersAPI':
        switch (fn) {
          case 'getOnePartner':
            return partnerAPI.getOnePartner(data);
            break;
          case 'getPartner':
            return partnerAPI.getPartner();
            break;
          case 'deletePartner':
            return partnerAPI.deletePartner(data);
            break;

          case 'storePartner':
            return partnerAPI.storePartner(data);
            break;
          case 'updatePartner':
            return partnerAPI.updatePartner(data, this.token);
            break;
        }
      case 'MoneyReportingsAPI':
        switch (fn) {
          case 'storeMoneyReporting':
            return money_reportingAPI.storeMoneyReporting(data);
            break;
          case 'updateMoneyReporting':
            return money_reportingAPI.updateMoneyReporting(data);
            break;
          case 'getMoneyReporting':
            return money_reportingAPI.getMoneyReporting();
            break;
          case 'deleteMoneyReporting':
            return money_reportingAPI.deleteMoneyReporting(data);
            break;
        }
      case 'GoodsAPI':
        switch (fn) {
        }
      case 'MaterialProductAPI':
        switch (fn) {
          case 'getProducts':
            return materialProductAPI.getProducts();
            break;
        }
      case 'OperationsAPI':
        switch (fn) {
          case 'getOneOperation':
            return operationAPI.getOneOperation(data);
            break;
          case 'getOperation':
            return operationAPI.getOperation();
            break;
          case 'deleteOperation':
            return operationAPI.deleteOperation(data);
            break;

          case 'storeOperation':
            return operationAPI.storeOperation(data);
            break;
          case 'updateOperation':
            return operationAPI.updateOperation(data, data.id);
            break;
        }
      case 'ProgramasAPI':
        switch (fn) {
          case 'getVotes':
            return programasAPI.getVotes();
            break;
          case 'insertUpdatVotes':
            return programasAPI.insertUpdatVotes(data);
            break;
        }
        break;

      case 'CashflowAPI':
        switch (fn) {
          case 'getCashflowByCountry':
            return cashflowApi.getCashflowByCountry(data); //
            break;
          case 'saveCashFlow':
            return cashflowApi.saveCashFlow(data);
            break;
          case 'updateCashFlow':
            return cashflowApi.updateCashFlow(data);
            break;
          // case 'getAnimals':
          //   return cashflowApi.getAnimals(); //
          //   break;
          case 'getPropertiesCashflowCategories':
            return cashflowApi.getPropertiesCashflowCategories(data); //
            break;
          case 'getAgriculturalProductWithCashflowCategories':
            return cashflowApi.getAgriculturalProductWithCashflowCategories(); //
            break;
          case 'getFirstSalesByOwnerId':
            return cashflowApi.getFirstSalesByOwnerId(); //
            break;
          case 'saveCertification':
            return cashflowApi.saveCertification(data); //
            break;
          case 'getCashflowByDate':
            // return cashflowApi.getCashflowByDate(data); //
            break;
          case 'getCoffeSells':
            // return cashflowApi.getProductSells(); //
            break;
          // case 'getExpensesByAccountingCategorieId':
          //   return cashflowApi.getExpensesByAccountingCategorieId(data); //
          //   break;
          case 'deleteCashflow':
            return cashflowApi.deleteCashflow(data); //
            break;
        }
        break;
      default:
        'x';
        break;
    }
  }
}
