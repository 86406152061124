import { RoutingConfig } from 'src/app/utils/routing-config';
import { Component, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../config/offlines_keys.json';
import { Router } from '@angular/router';
import routes from '../../../config/routing.json';
import { lastValueFrom, take } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { Store } from '@ngrx/store';
import { Geolocation } from '@capacitor/geolocation';

@Component({
  selector: 'app-farm-screens-activity-start',
  templateUrl: './farm-screens-activity-start.component.html',
  styleUrls: ['./farm-screens-activity-start.component.scss'],
})
export class FarmScreensActivityStartComponent implements OnInit {
  state;
  constructor(private router: Router, private routingConfig: RoutingConfig,private store:Store) {}
  country_id: Number = 1;
  owner;
  show_map_button = false;
  async ngOnInit() {
    await this.checkMapPermissions();
    await this.checkBrowserLocationPermission(this);
    this.owner = await Storage.get({ key: dev.USER_KEY }).then((value) =>
      JSON.parse(value.value)
    );
    let country=await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    this.country_id = country.id
  }
  async checkMapPermissions() {
    const permission = await Geolocation.checkPermissions();
    if (permission?.location !== 'granted') {
      await Geolocation.requestPermissions()
        .then(async (value) => {
          // console.log(value);
          // this.show_map_button = true;
        })
        .catch(async (e) => {
          // this.show_map_button = false;
        });
    } else {
      // this.show_map_button = true;
    }
  }
  checkBrowserLocationPermission(that) {
    let geo = navigator.geolocation.watchPosition(
      function (position) {
        if (position) {
          that.show_map_button = true;
        }
      },
      function (error) {}
    );
  }

  // ngOnDestroy(): void {
  //   //Called once, before the instance is destroyed.
  //   //Add 'implements OnDestroy' to the class.

  // }
  async previous() {
    await Storage.get({ key: dev.USER_KEY }).then((value) => {
      let has_family =
        JSON.parse(JSON.parse(value.value)?.profile_info)?.has_family || false;
      if (JSON.parse(has_family)) {
        this.router.navigateByUrl(routes.family.family_members);
      } else {
        this.router.navigateByUrl(routes.family.family_situation);
      }
    });
  }
  async next() {
    // let profile;
    // if (this.owner.profile_info) profile = JSON.parse(this.owner.profile_info);
    this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }
}
