import { filter } from 'rxjs/operators';
import { from } from 'rxjs';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  CountriesState,
  countriesAdapter,
} from '../reducers/countries.reducer';
import { Countrie } from 'src/app/models/countrie.model';
import { DashboardState } from '../reducers/dashboard.reducer';
import { OwnerState } from '../reducers/owner.reducer';

export const dashboardSelector =
  createFeatureSelector<DashboardState>('dashboard');

export const getTotalsAccountingCategoriesResult = createSelector(
  dashboardSelector,
  (state: DashboardState) => {
    return { data: state.totalsAccountingCategories,isLoading:state.totalsAccountingCategoriesLoading };
  }
);
export const getStatisticsResult = createSelector(
  dashboardSelector,
  (state: DashboardState): Array<any> => {
    return state.statisticsData;
  }
);
export const getMaterialOroductKpis = createSelector(
  dashboardSelector,
  (state: DashboardState): Array<any> => state.materialProductKpis
);
export const getProductKpis = createSelector(
  dashboardSelector,
  (state: DashboardState): Array<any> => state.productKpis
);

export const getCafeCerezKpi = createSelector(
  dashboardSelector,
  (state: DashboardState): Array<any> => state.cafeCerezKpi
);
export const getHarvestSummary = createSelector(
  dashboardSelector,
  (state: DashboardState): Array<any> => state.harvestSummary
);
export const getInsumosBought = createSelector(
  dashboardSelector,
  (state: DashboardState): Array<any> => state.insumosBought
);
export const getWorkForceStats = createSelector(
  dashboardSelector,
  (state: DashboardState): Array<any> => state.workForceStats
);
