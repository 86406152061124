export class QuestionBase<T> {
  value: T | undefined;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string;
  type: string;
  placeholder: string;
  options: { key: string; value: string }[];
  depend_on_value: any;
  depend_on_key: string;
  span: string;
  col_size: number;
  disabled;
  name: string;
  src: string | any;
  class: string;
  alignItem: string;
  sizeItem: string;
  subItems: Array<QuestionBase<T>>;
  ngClasses: any;
  ngStyle: any;
  hidden: any;
  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      required?: boolean;
      order?: number;
      controlType?: string;
      type?: string;
      options?: { key: string; value: any }[];
      depend_on_value?: any;
      depend_on_key?: string;
      span?: string;
      col_size?: number;
      disabled?: boolean;
      name?: string;
      src?: string;
      subItems?: Array<QuestionBase<T>>;
      alignItem?: string;
      sizeItem?: string;
      class?: string;
    } = {}
  ) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.options = options.options || [];
    this.depend_on_value = options.depend_on_value;
    this.depend_on_key = options.depend_on_key;
    this.span = options.span;
    this.col_size = options.col_size;
    this.disabled = options.disabled || false;
    this.name = options.name || '';
    this.src = options.src || '';
    this.subItems = options.subItems || [];
    this.alignItem = '';
    this.sizeItem = '';
    this.class = options.class || 'col-12';
  }
}

export class DropdownQuestion extends QuestionBase<string> {
  controlType = 'dropdown';
}

export class TextboxQuestion extends QuestionBase<string> {
  controlType = 'textbox';
}

export class NumberBoxQuestion extends QuestionBase<string> {
  controlType = 'number';
}

export class DateBoxQuestion extends QuestionBase<string> {
  controlType = 'date';
}

export class SliderBoxQuestion extends QuestionBase<string> {
  controlType = 'slider';
}

export class NumberPrecisionWidget extends QuestionBase<string> {
  controlType = 'number_prcision';
}
