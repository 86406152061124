import { AnimationUtils } from './../../utils/animations';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { ConfirmModalComponent } from 'src/app/widgets/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';
import { UnifiedCashflowInputComponentComponent } from '../cashflow/unified-cashflow-input-component/unified-cashflow-input-component.component';
import { PaginationResult } from 'src/app/models/pagination-result.model';
import { ProductSale } from 'src/app/models/cash-flow/sale.model';
import { Store } from '@ngrx/store';
import {
  deleteSales,
  paginateSales,
} from 'src/app/utils/states-management/actions/product-sale.action';
import { cloneDeep } from 'lodash';
import { getCashflowById } from 'src/app/utils/states-management/actions/cashflow.action';
import { ProductSaleState } from 'src/app/utils/states-management/reducers/product-sales.reducer';
import { AccountingEntryCategorie } from 'src/app/models/accounting/accounting_entries_categories';
import { CAFE_ACCOUNTING_ENTRY_CATEGORY } from 'src/app/utils/consts';
import { Unit } from 'src/app/models/Unit';
import { selectAllUnits } from 'src/app/utils/states-management/reducers/units.reducer';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { lastValueFrom, take } from 'rxjs';
import { getTotalsAccountingCategoriesResult } from 'src/app/utils/states-management/selector/dashboard.selector';
import { accountingEntriesLoading } from 'src/app/utils/states-management/actions/accountings-entries.action';
export interface SaleFilter {
  start_date: string;
  end_date: string;
  page_size: number;
  ids: Array<number>;
  page: number;
  is_animal: number;
}

@Component({
  selector: 'coffee-sales',
  templateUrl: './coffee-sales.component.html',
  styleUrls: ['./coffee-sales.component.scss'],
})
export class CoffeeSalesComponent implements OnInit {
  currency;
  typeCoffe: any = [];
  monthYear = new Date();
  selles: Array<ProductSale> = [];
  pagination_result: PaginationResult<ProductSale>;
  @Input() product_id: Array<number>;
  @Input() mainUnit;
  @Input() productionPerFarm: ProductionsPerfarm;
  accountingEntries: Array<AccountingEntryCategorie>;
  infiniteScrollEvent;
  units: Array<Unit> = [];
  saleFilter: SaleFilter = {
    end_date: '2050-1-1',
    ids: [16, 17, 18],
    is_animal: 0,
    page: 1,
    page_size: 20,
    start_date: '2016-1-1',
  };
  @Output() selectProductEventEmitter: EventEmitter<any> = new EventEmitter();
  constructor(
    private modalController: PopoverController,
    private secondaryModalController: ModalController,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private animationUtils: AnimationUtils,
    private store: Store<{
      productSales: any;
      accountingEntries: Array<AccountingEntryCategorie>;
    }>
  ) {
    this.store
      .select(selectAllUnits)
      .subscribe((value) => (this.units = cloneDeep(value)));
  }
country
  async ngOnInit() {
    this.country = await lastValueFrom(
      this.store.select(getCountry).pipe(take(1))
    );

      this.mainUnit = this.translateService.instant('kilo');
    
    this.currency = this.country.currency;
    this.selles = [];
    this.pagination_result = null;
    this.store.select('productSales').subscribe((val: ProductSaleState) => {
      this.pagination_result = cloneDeep(val.paginationResult);
      this.selles = cloneDeep(val.allValues);
      this.selles.forEach((val) => (val.is_expanded = true));
      if (this.infiniteScrollEvent) {
        this.infiniteScrollEvent.target.complete();
        this.infiniteScrollEvent.target.disabled = true;
      }
    });
    console.log( this.selles)
    this.store.dispatch(paginateSales(this.saleFilter));
    this.store
      .select(getTotalsAccountingCategoriesResult)
      .subscribe(({data}) => {
        if (data.length == 0) {
          this.store.dispatch(
            accountingEntriesLoading({ country_id: this.country.id })
          );
          return;
        } else this.accountingEntries = cloneDeep(data);
      });
  }
  async itemClicked(item) {
    if (!item.is_expanded) {
      this.animationUtils.expandAnimation(item);
      item.is_expanded = true;
    } else {
      this.animationUtils.collapsAnimation(item);
      item.is_expanded = false;
    }
  }

  async delete(second_sell_id: number | null, first_sale_id: number | null) {
    const ids = [];
    if (second_sell_id) ids.push(second_sell_id);
    if (first_sale_id) ids.push(first_sale_id);
    let componentProps = {
      confirmation_text: await this.translateService.instant('delete'),
      modalController: this.modalController,
      cancel_text: await this.translateService.instant('cancel_text'),
      submit_text: await this.translateService.instant('submit_text'),
    };
    let modal = await this.toastUtils.popOverBuilder(
      componentProps,
      ConfirmModalComponent,
      'pop-over-style-confirm'
    );

    modal.onWillDismiss().then(async (value) => {
      if (value.data.confirmed) {
        var saleFilterCopy = cloneDeep(this.saleFilter);
        this.saleFilter.page = 1;
        this.store.dispatch(deleteSales({ ids: ids, filter: saleFilterCopy }));
      }
    });
  }

  async update(sell) {
    console.log(sell)
    const cashFlowCategFiltred = this.accountingEntries.find(
      (element) => element.id == sell.accounting_entries_categories
    );
    this.store.dispatch(getCashflowById({ id: sell.id }));
    const modal = await this.secondaryModalController.create({
      component: UnifiedCashflowInputComponentComponent,
      cssClass: ['my-custom-modal-css ', 'modal-wrapper'],
      componentProps: {
        cashFlowEntrieName: sell.accounting_entries_categories,
        accountingEntryCategories: cashFlowCategFiltred,
        modalController: this.secondaryModalController,
        cashflow: sell,
        is_update: true,
      },
    });
    modal.present();
    await modal.onWillDismiss();
    this.store.dispatch(paginateSales(this.saleFilter));
  }

  selectPorduct(good, boolean, name) {
    this.selectProductEventEmitter.emit({ good, boolean, name });
  }

  logScrollEnd(event) {
    this.saleFilter.page += 1;
    this.store.dispatch(paginateSales(this.saleFilter));
    this.infiniteScrollEvent = event;
  }
}
