import { HttpClient } from '@angular/common/http';
import { dev } from '../config/endpoints.json';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Family_member } from '../models/family_member.model';

@Injectable({ providedIn: 'root' })
export class Family_membersAPI {
  base_url = environment.appRoot + '/api/';
  headers;
  constructor(private http: HttpClient) {}

  storeFamilyMembers(familyMember: Family_member): Observable<any> {
    const url = this.base_url + dev.endpoint.family_members.family_member;
    const formData = new FormData();
    if (familyMember?.blobFile) {
      formData.append(
        'image',
        familyMember?.blobFile,
        new Date().getUTCMilliseconds().toString()
      );
    }
    formData.append('name', familyMember.name);
    formData.append('birth_date', familyMember.birth_date);
    formData.append('sex', familyMember.sex);
    if (familyMember.relation_type)
      formData.append('relation_type', familyMember.relation_type);
    formData.append('is_receiving_salary', familyMember.is_receiving_salary);
    formData.append('owner_id', familyMember.owner_id);
    if (familyMember.user_id) {
      formData.append('user_id', familyMember.user_id);
    }

    return this.http.post(url, formData);
  }

  getFamilyMembers(): Observable<any> {
    const url =
      this.base_url + dev.endpoint.family_members.family_members_one_owner;
    return this.http.get(url);
  }
  getFamilyMember(id: number): Observable<any> {
    const url = this.base_url + dev.endpoint.family_members.family_member;
    return this.http.get(url + '/' + id);
  }
  deleteFamilyMember(id: number): Observable<any> {
    const url =
      this.base_url + dev.endpoint.family_members.delete_members_one_owner;
    return this.http.delete(url + '/' + id);
  }
  deleteFamilyMembers(): Observable<any> {
    const url =
      this.base_url + dev.endpoint.family_members.delete_all_family_members;

    return this.http.post(
      url,
      {},
      {
        headers: this.headers,
      }
    );
  }

  updateFamilyMembers(familyMember: Family_member): Observable<any> {
    const url =
      this.base_url + dev.endpoint.family_members.update_family_members;
    const formData = new FormData();
    formData.append('id', familyMember?.id); 
    formData.append('name', familyMember.name);
    formData.append('birth_date', familyMember.birth_date);
    formData.append('sex', familyMember.sex);
    formData.append('relation_type', familyMember.relation_type);
    formData.append('is_receiving_salary', familyMember.is_receiving_salary);
    formData.append('owner_id', familyMember.owner_id);
    if (familyMember.user_id) {
      formData.append('user_id', familyMember.user_id);
    }
    if (familyMember?.blobFile) {
      formData.append(
        'image',
        familyMember?.blobFile,
        new Date().getUTCMilliseconds().toString()
      );
    }
    return this.http.post(url, formData);
  }

  storeFamilyMemberProfilePhoto(id, file: Blob) {
    const url =
      this.base_url + dev.endpoint.family_members.store_family_member_photo;
    const formData = new FormData();
    formData.append('image', file, new Date().getUTCMilliseconds().toString());
    formData.append('id', id);
    return this.http.post(url, formData);
  }

  updateFamilyMemberPhoto(id, file: Blob) {
    const url = this.base_url + dev.endpoint.family_members.update_family_photo;
    const formData = new FormData();
    formData.append('image', file, new Date().getUTCMilliseconds().toString());
    formData.append('id', id);
    return this.http.post(url, formData);
  }
}
