import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { MoneyReportingsAPI } from 'src/app/apis/money_reportings.service';
import { dev } from '../../../../config/offlines_keys.json';
import { environment } from 'src/environments/environment';
import { LoadingController, ModalController } from '@ionic/angular';
import { PensionPlanUpdatederComponent } from '../pension-plan-updateder/pension-plan-updateder.component';
import {
  PENSION_PLAN_TYPE_GOV,
  PENSION_PLAN_TYPE_NO_GOV,
} from 'src/app/utils/consts';
import { PensionPlanFormComponent } from '../pension-plan-form/pension-plan-form.component';
import { CashflowSelectorComponent } from '../cashflow-selector/cashflow-selector.component';
import Owner from 'src/app/models/owner.model';
import { map, switchMap, take } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-pension-plan-tracker',
  templateUrl: './pension-plan-tracker.component.html',
  styleUrls: ['./pension-plan-tracker.component.scss'],
})
export class PensionPlanTrackerComponent implements OnInit {
  pension_plans: Array<any>;
  display_percent;
  base_endpoint = environment.appRoot;
  image;
  user: Owner;
  country;
  file;
  loadingModal: HTMLIonLoadingElement;

  constructor(
    private modalController: ModalController,
    private loadingController: LoadingController,
    private moneyReportingAPI: MoneyReportingsAPI,
    private toastUtils: ToastUtils,
    private store:Store
  ) {}
  async ngOnInit() {
    this.loadingModal = await this.loadingController.create();

    this.file = await Storage.get({ key: dev.FILE_KEY }).then((value) =>
      JSON.parse(value.value)
    );
    if (this.file) this.image = this.base_endpoint + this.file?.path;

    Storage.get({ key: dev.USER_KEY }).then(
      (value) => (this.user = JSON.parse(value.value))
    );
    this.country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)))

    this.fetchPensionPlans().subscribe();
  }

  fetchPensionPlans() {
    return this.moneyReportingAPI.getMoneyReporting().pipe(
      take(1),
      map((val) => {
        if (Array.isArray(val)) {
          this.pension_plans = val.filter((moneyReportings) =>
            moneyReportings.type.includes('pension_plan')
          );
          this.pension_plans = this.pension_plans.map((pension_plan) => {
            pension_plan.amount = Math.abs(pension_plan.amount);
            pension_plan.display_percent =
              100 *
              this.calculatPercent(
                pension_plan.amount,
                pension_plan.pension_plan_objective
              );
            pension_plan.calculatedPercent = this.calculatPercent(
              pension_plan.amount,
              pension_plan.pension_plan_objective
            );
            return pension_plan;
          });
        }
      })
    );
  }

  calculatPercent(amount, pension_plan_objective) {
    if (!amount) amount = 0;
    if (!pension_plan_objective) pension_plan_objective = 0;
    if (pension_plan_objective == 0) {
      return 0;
    } else {
      return amount / pension_plan_objective;
    }
  }

  async updatePensionPlan(val) {
    const modal = await this.modalController
      .create({
        component: PensionPlanUpdatederComponent,
        componentProps: {
          plan_pension: val,
          modalController: this.modalController,
        },
      })
      .then((value) => value);
    modal.present();
    await modal.onWillDismiss();
    this.fetchPensionPlans().subscribe();
  }

  async deletePensionPlan(val) {
    this.loadingModal.present();
    // TODO ofllines not working
    if (Array.isArray(val.cashflow) && val.cashflow.length > 0)
      await this.showCashflowsIfAny(val);
    else {
      this.moneyReportingAPI
        .deleteMoneyReporting(val.id)
        .pipe(
          take(1),
          map((result) => result),
          switchMap((result) => this.fetchPensionPlans()),
          map((value) => value)
        )
        .subscribe(() => this.loadingModal.dismiss());
    }
  }

  async showCashflowsIfAny(val) {
    let componentProps = {
      modalController: this.modalController,
      cashflows: val.cashflow,
      pension_plan: val,
    };
    let modal = await this.toastUtils.modalBuilder(
      CashflowSelectorComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper']
    );

    this.loadingModal.dismiss();
    const { data } = await modal.onWillDismiss();
    if (data.deleteLegth) {
      if (
        Array.isArray(val.cashflow) &&
        data.deleteLegth === val.cashflow.length &&
        data.deleteLegth !== 0
      ) {
        this.moneyReportingAPI
          .deleteMoneyReporting(val.id)
          .pipe(
            take(1),
            map((value) => value),
            switchMap((value) => {
              return this.fetchPensionPlans();
            }),
            map((value) => value)
          )
          .subscribe(() => this.loadingModal.dismiss());
      } else this.fetchPensionPlans().subscribe();
    }
  }

  async addPensionPlan() {
    let componentProps;
    if (Array.isArray(this.pension_plans) && this.pension_plans.length == 0) {
      componentProps = {
        allowed_options: [PENSION_PLAN_TYPE_GOV, PENSION_PLAN_TYPE_NO_GOV],
        modalController: this.modalController,
      };
    } else if (
      Array.isArray(this.pension_plans) &&
      this.pension_plans.length == 1
    ) {
      const insertedPesionPlan = this.pension_plans.find(
        (element) => element.type === PENSION_PLAN_TYPE_GOV
      );
      insertedPesionPlan
        ? (componentProps = {
            allowed_options: [PENSION_PLAN_TYPE_NO_GOV],
            modalController: this.modalController,
          })
        : (componentProps = {
            allowed_options: [PENSION_PLAN_TYPE_GOV],
            modalController: this.modalController,
          });
    }
    let modal = await this.toastUtils.modalBuilder(
      PensionPlanFormComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper'],
      true,
      '5'
    );
    await modal.onWillDismiss();
    this.fetchPensionPlans().subscribe();
  }
}
