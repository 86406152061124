import { ActionReducer } from '@ngrx/store';

import { Storage } from '@capacitor/storage';
const STORAGE_KEY = 'NSIS_APP_STATE';


export function fetchState(): Promise<{}> {
  return Storage.get({ key: STORAGE_KEY })
    .then((s: any) =>{
        return JSON.parse(s.value) || {}})
    .catch((err: any) => {});
}
function saveState(state: any, keys: string[]): Promise<void> {
  // Pull out the portion of the state to save.
  if (keys) {
    state = keys.reduce((acc, k) => {
      const val = getNested(state, k);
      if (val) {
        setNested(acc, k, val);
      }
      return acc;
    }, {});
  }

  return Storage.set({ key: STORAGE_KEY, value: JSON.stringify(state) });
}

export const StorageSyncActions = {
  HYDRATED: 'NSIS_APP_HYDRATED',
  NGRX_STORE_INIT :'@ngrx/store/init',
  NGRX_EFFECT_INIT :'@ngrx/effects/init',
  NGRX_STORE_UPDATE_REDUCERS :'@ngrx/store/update-reducers',
};



export interface StorageSyncOptions {
  keys?: string[];
  ignoreActions?: string[];
  hydratedStateKey?: string;
  onSyncError?: (err: any) => void;
}

const defaultOptions: StorageSyncOptions = {
  keys: [],
  ignoreActions: [],
  hydratedStateKey: null,
  onSyncError: () => {},
};

export function storageSync(options?: StorageSyncOptions  ) {

  const { keys, ignoreActions, hydratedStateKey, onSyncError } = {
    ...defaultOptions,
    ...(options || {}),
  };

  ignoreActions.push(StorageSyncActions.HYDRATED);
  ignoreActions.push(StorageSyncActions.NGRX_STORE_INIT);
  ignoreActions.push(StorageSyncActions.NGRX_EFFECT_INIT);
  ignoreActions.push(StorageSyncActions.NGRX_STORE_UPDATE_REDUCERS);

  const hydratedState: any = {};

  return function storageSyncReducer(reducer: ActionReducer<any>) {
    return  (state: any, action: any) => {
      const { type, payload } = action;
      if (type === StorageSyncActions.HYDRATED) {
        state = { ...state, ...payload };
        if (hydratedStateKey) {
          hydratedState[hydratedStateKey] = true;

        }
      }
      const nextState = { ...reducer(state, action), ...hydratedState };

      if (ignoreActions.indexOf(type) === -1) {
        saveState(nextState, keys).catch((err) => onSyncError(err));
      }
      return nextState;


    };
  };
}


export function getNested(obj: any, path: string): any {
  if (obj !== null && path) {
    const parts = path.split('.').reverse();
    while (obj != null && parts.length) {
      obj = obj[parts.pop()];
    }
  }
  return obj;
}

export function setNested(obj: any, path: string, value: any): any {
  if (obj != null && path) {
    const pieces = path.split('.');
    const length = pieces.length;
    let current = obj;
    let piece;
    let i;

    for (i = 0; i < length; i++) {
      piece = pieces[i];
      if (i === length - 1) {
        current[piece] = value;
      } else if (!current[piece]) {
        current[piece] = {};
      }
      current = current[piece];
    }
  }

  return obj;
}
