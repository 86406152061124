import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-data-loader-bar',
  templateUrl: './data-loader-bar.component.html',
  styleUrls: ['./data-loader-bar.component.scss'],
})
export class DataLoaderBarComponent implements OnInit {

  constructor(public translateService: TranslateService) {}
  async ngOnInit() {

  }

}
