import { MORPH_Garentee } from './../../../../utils/consts';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OperationsAPI } from 'src/app/apis/operations.service';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import { ModalDebtComponent } from 'src/app/modules/debts/modal-debt/modal-debt.component';
import { ModalGarenteeComponent } from 'src/app/modules/goods/modal-garentee/modal-garentee.component';

@Component({
  selector: 'app-money-operations-selector',
  templateUrl: './money-operations-selector.component.html',
  styleUrls: ['./money-operations-selector.component.scss'],
})
export class MoneyOperationsSelectorComponent implements OnInit {
  @Input() title;
  @Input() owner_id;
  operations = [];
  @Input() cashFlow: CashFlow;
  @Input() operation_type;
  @Input() operation_placeholder;
  selected_operation;
  @Output() operationSelectedChange: EventEmitter<CashFlow> =
    new EventEmitter<CashFlow>();
  constructor(
    private secondaryModalController: ModalController,
    private operationAPI: OperationsAPI
  ) {}

  async ngOnInit() {
    this.getOperations();
  }

  async addOperation() {
    if (this.operation_type === 'debt') {
      await this.presentOperationDialog(ModalDebtComponent);
    } else if (this.operation_type === MORPH_Garentee) {
      await this.presentOperationDialog(ModalGarenteeComponent);
    }
  }

  async presentOperationDialog(component) {
    const modal = await this.secondaryModalController.create({
      component: component,
      cssClass: ['my-custom-modal-css ', 'modal-wrapper'],
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    if (Array.isArray(data?.operation)) {
      this.operations.push(data?.operation[1]);
      this.operationChange(data?.operation[1]);
      this.selected_operation = data?.operation[1];
    }
  }

  getOperations() {
    this.operationAPI.getOperation().subscribe((value) => {
      this.operations = value[0];
    });
  }

  operationChange(val) {
    this.selected_operation = val;
    this.cashFlow.morphable_type = MORPH_Garentee;
    this.cashFlow.morphable_id = val?.id;
    this.cashFlow.amount = val?.amount;
    this.operationSelectedChange.emit(this.cashFlow);
  }
}
