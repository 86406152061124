import Employee  from 'src/app/models/employee.model';
import { nextOfflineIndex } from './../reducers/offline.reducer';
import { createAction, props } from '@ngrx/store';


export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';

export const deleteEmployee = createAction(
  DELETE_EMPLOYEE,
  props<{ data: Employee }>()
);
export const deleteEmployeeSuccess = createAction(
  DELETE_EMPLOYEE_SUCCESS,
  props<{ id: number }>()
);
export const addEmployee = createAction(
  ADD_EMPLOYEE,
  props<{ data: Employee; props: Object }>()
);
export const addEmployeeSuccess = createAction(
  ADD_EMPLOYEE_SUCCESS,
  props<{ data: Employee; offlineId: string }>()
);

export const updateEmployee = createAction(
  UPDATE_EMPLOYEE,
  props<{ data: Employee }>()
);

export const updateEmployeeSuccess = createAction(
  UPDATE_EMPLOYEE_SUCCESS,
  props<{ data: Employee }>()
);
