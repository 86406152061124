import { TranslateService } from '@ngx-translate/core';
import { orderAlphabaticallyByAttribute } from 'src/app/utils/array-filtrer';
import { dev } from 'src/app/config/offlines_keys.json';
import { UserInfo } from 'src/app/apis/users-info.service';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PickerOptionFormater } from 'src/app/utils/pipes-utils/date_formatter';
import { Storage } from '@capacitor/storage';
import { BookCashflowListFilter } from '../../cashflow/book/book.component';
import { Store } from '@ngrx/store';
import Owner from 'src/app/models/owner.model';
import { AccountingEntryCategorie } from 'src/app/models/accounting/accounting_entries_categories';
import { accountingEntriesLoading } from 'src/app/utils/states-management/actions/accountings-entries.action';
import { cloneDeep } from 'lodash';
import { lastValueFrom, take } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { getTotalsAccountingCategoriesResult } from 'src/app/utils/states-management/selector/dashboard.selector';
@Component({
  selector: 'app-date-filter-modal',
  templateUrl: './date-filter-modal.component.html',
  styleUrls: ['./date-filter-modal.component.scss'],
})
export class DateFilterModalComponent implements OnInit {
  @Input() modalController: ModalController;
  @Input() startDate;
  @Input() endDate;
  startLimitDate
  endLimitDate
  from = { date: new Date(2016, 12, 31).toISOString() };
  to = { date: new Date().toISOString() };
  customPickerOptionsFrom;
  customPickerOptionsTo;
  lang;
  cashflowCategories;
  bookCashflowListFilter: BookCashflowListFilter = {
    startDate: null,
    endDate: null,
    filterCategory: [],
    filterType: [],
    categorieIsFiltred: null,
    cashflowTypeIsFiltred: null,
    is_loading: true,
    isFiltred: false,
    page: 1,
    page_size: 10,
    isHarvestingFilter: false
  };
  constructor(
    private userInfo: UserInfo,
    private pickerOptionFormater: PickerOptionFormater,
    public translateService: TranslateService,
    private store: Store<{
      owner: Owner;
      accountingEntries: Array<AccountingEntryCategorie>;
    }>
  ) { }
  HandelDateChange(value) {
    this.bookCashflowListFilter.startDate = value.start_date;
    this.bookCashflowListFilter.endDate = value.end_date;
  }

  cashflowType = [
    {
      id: 1,
      title: 'income',
      title_en: 'Revenues',
      isChecked: false,
      is_income: 1,
      is_a_loss: null,
    },
    {
      id: 0,
      title: 'expenses',
      title_en: 'Expenses',
      isChecked: false,
      is_income: 0,
      is_a_loss: null,
    },
  ];

  async ngOnInit() {
    this.bookCashflowListFilter.startDate = this.startDate;
    this.bookCashflowListFilter.endDate = this.endDate;
    const country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    this.store.select(getTotalsAccountingCategoriesResult).subscribe(({data}) => {
      if (data.length == 0)
        this.store.dispatch(
          accountingEntriesLoading({ country_id: country.id })
        );
      else {
        this.cashflowCategories = cloneDeep(data);
        // this.cashflowCategories = orderAlphabaticallyByAttribute(
        //   this.cashflowCategories,
        //   'title',
        //   this.translateService.currentLang
        // );
        this.orderAccountings()
        this.cashflowCategories.forEach((category) => {
          category.isChecked = false;
        });
      }
    });
    this.customPickerOptionsFrom =
      this.pickerOptionFormater.custom_picker_options(this.from,'date');    this.customPickerOptionsTo =
      this.pickerOptionFormater.custom_picker_options(this.to, 'date');

    this.lang = await this.userInfo.getSelectedLangage();
  }
  orderAccountings() {
    this.cashflowCategories.sort((a, b) => {
      return this.translateService
        .instant('accounting_entries.' + a.id)
        .normalize('NFD')
        .toLowerCase() ===
        this.translateService
          .instant('accounting_entries.' + b.id)
          .normalize('NFD')
          .toLowerCase()
        ? 0
        : this.translateService
          .instant('accounting_entries.' + a.id)
          .normalize('NFD')
          .toLowerCase() <
          this.translateService
            .instant('accounting_entries.' + b.id)
            .normalize('NFD')
            .toLowerCase()
          ? -1
          : 1;
    });
  }
  dismiss() {
    this.modalController.dismiss();
  }

  confirm_method() {
    this.modalController.dismiss({
      bookCashflowListFilter: this.bookCashflowListFilter,
    });
  }
  is_show_confirm_button =false;
ngDoCheck(): void {
  //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
  //Add 'implements DoCheck' to the class.
if(
  this.bookCashflowListFilter.filterCategory.length>0 && this.bookCashflowListFilter.filterType.length>0
){
  this.is_show_confirm_button = true
}else{
  this.is_show_confirm_button = false

}
}
  Check(item, arr) {
    item.isChecked = !item.isChecked;

    // arr.push
    if(item.isChecked){
      arr.push(item.id)
    }else{
      arr.splice(
        arr.indexOf(item.id),
        1
      )
    }
   
  }
}
