import { createAction, props } from '@ngrx/store';
import { Lot } from 'src/app/models/lots.model';

export const LOAD_PLOTS = 'LOAD_PLOTS';
export const LOAD_PLOTS_SUCCESS = 'LOAD_PLOTS_SUCCESS';
export const DELETE_PLOTS = 'DELETE_PLOTS';
export const DELETE_PLOTS_SUCCESS = 'DELETE_PLOTS_SUCCESS';
export const ADD_PLOTS = 'ADD_PLOTS';
export const ADD_PLOTS_SUCCESS = 'ADD_PLOTS_SUCCESS';
export const UPDATE_PLOTS = 'UPDATE_PLOTS';
export const UPDATE_PLOTS_SUCCESS = 'UPDATE_PLOTS_SUCCESS';

export const loadPlots = createAction(LOAD_PLOTS);
export const loadPlotsSuccess = createAction(
  LOAD_PLOTS_SUCCESS,
  props<{ plots: Array<Lot> }>()
);

export const deletePlot = createAction(
  DELETE_PLOTS,
  props<{ lot: Lot }>()
);
export const deletePlotSuccess = createAction(
  DELETE_PLOTS_SUCCESS,
  props<{ id: number }>()
);
export const addPlot = createAction(
  ADD_PLOTS,
  props<{ data: Lot; props: Object }>()
);
export const addPlotSuccess = createAction(
  ADD_PLOTS_SUCCESS,
  props<{ data: Lot; offlineId: string }>()
);

export const updatePlot = createAction(
  UPDATE_PLOTS,
  props<{ data: Lot; props: Object  }>()
);

export const updatePlotSuccess = createAction(
  UPDATE_PLOTS_SUCCESS,
  props<{ data: Lot }>()
);
