import { Component, OnInit, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { MenuComponent } from './menu/menu.component';
import { RoutingConfig } from 'src/app/utils/routing-config';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage implements OnInit {
  @ViewChild('nav') nav: IonNav;
  rootParams;
  showHeader = false;
  titleHeader = '';
  activeTab;
  profileConfig 
  selectedTab                                                           
  constructor(
    private routingConfig: RoutingConfig,

  ) {}
  async ngAfterViewInit(): Promise<void> {
    this.profileConfig = await this.routingConfig.getProfileFormConfig();

    this.rootParams = {
      nav: this.nav,
      rootStack: MenuComponent,
      isDashboardView: true,
    };
    this.nav.setRoot(MenuComponent, {
      nav: this.nav,
      selectedTab: 0,
      tabs:  Object?.entries(this.profileConfig)
    });
    this.nav.ionNavDidChange.subscribe(async (val) => {
      this.activeTab = await this.nav.getActive();
      this.titleHeader = this.activeTab?.params?.title;
      this.activeTab?.params?.sub_tab
        ? (this.showHeader = this.activeTab?.params?.sub_tab)
        : (this.showHeader = false);
    });
  }
  async goBack() {
    this.nav.setRoot(MenuComponent, {
      nav: this.nav,
      selectedTab: this.activeTab?.params?.tab,
      tabs:  Object.entries(this.profileConfig)
    });
  }
  async ngOnInit() {
    // this.nav.ionNavDidChange.subscribe((val)=>{
    //   console.log(val)
    // })
    // this.nav.ionNavWillChange.subscribe((val)=>console.log(val))
  }
}
