import { Certification } from "./Certification";

export class SecondCellCertif {
  id;
  certifs1:Array<Certification>;
  certifs2:Array<Certification>;
  cash_flow_id;
  constructor() {
      
  }
}
