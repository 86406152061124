import { HttpClient } from '@angular/common/http';
import { dev } from '../config/endpoints.json';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Partner from '../models/partner.model';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class MyPartnersAPI {
  base_url = environment.appRoot + '/api/';
  constructor(private http: HttpClient) {}

  storePartner(partner: Partner): Observable<unknown> {
    const url = this.base_url + dev.endpoint.partner.partner;
    const formData = new FormData();
    formData.append('name', partner.name);
    formData.append('partner_id', partner?.id?partner?.id?.toString():null);
    if (partner.type) formData.append('type', partner.type);
    formData.append('partners_type_id', partner.partners_type_id?.toString());
    formData.append(
      'personal_identification_number',
      partner.personal_identification_number
    );
    formData.append('owner_id', partner.owner_id);
    formData.append('morphable_type', partner.morphable_type);
    formData.append('morphable_id', partner.morphable_id);
    formData.append('crop_id', partner.crop_id);
    if (partner?.blobFile) {
      formData.append(
        'image',
        partner?.blobFile,
        new Date().getUTCMilliseconds().toString()
      );
    }   
    return this.http.post(url, formData);
  }

  getPartner(crop?): Observable<unknown> {
    let url;
    if (crop)
      url =
        this.base_url +
        dev.endpoint.partner.partners_one_owner +
        '/' +
        crop.id +
        '/' +
        crop.morph;
        
    else url = this.base_url + dev.endpoint.partner.partners_one_owner;

    return this.http.get(url);
  }

  deletePartner(id: number): Observable<unknown> {
    const url = this.base_url + dev.endpoint.partner.partner;

    return this.http.delete(url + '/' + id);
  }
  getOnePartner(id: number): Observable<unknown> {
    const url = this.base_url + dev.endpoint.partner.partner;

    return this.http.get(url + '/' + id);
  }
  updatePartner(partner: Partner, token: any): Observable<unknown> {
    const url = this.base_url + dev.endpoint.partner.partner;
    const formData = new FormData();

    formData.append('name', partner.name);
    formData.append('partners_type_id', partner.partners_type_id?.toString());
    if (partner.type) formData.append('type', partner.type);
    formData.append(
      'personal_identification_number',
      partner.personal_identification_number
    );
    if (partner?.blobFile) {
      formData.append(
        'image',
        partner?.blobFile,
        new Date().getUTCMilliseconds().toString()
      );
    }    formData.append('morphable_type', partner.morphable_type);
    formData.append('morphable_id', partner.morphable_id);
    formData.append('owner_id', partner.owner_id);
    return this.http.post(url + '/' + partner.id + '?_method=PATCH', formData);
  }
  getPartnersType() {
    const url = this.base_url + dev.endpoint.partner.get_partners_type;
    return this.http.get(url);
  }

  getPartnersList(selectedPartnerType, cropId, morph) {
    const url =
      this.base_url +
      dev.endpoint.partner.get_partners_list +
      '/' +
      selectedPartnerType +
      '/' +
      cropId +
      '/' +
      morph;
    return this.http.get(url);
  }

  storePartnerProfilePhoto(id, file: Blob) {
    const url = this.base_url + dev.endpoint.partner.store_partner_photo;
    const formData = new FormData();
    formData.append('image', file, new Date().getUTCMilliseconds().toString());
    formData.append('id', id);
    return this.http.post(url, formData);
  }

  updatePartnerMemberPhoto(id, file: Blob) {
    const url = this.base_url + dev.endpoint.partner.update_partner_photo;
    const formData = new FormData();
    formData.append('image', file, new Date().getUTCMilliseconds().toString());
    formData.append('id', id);

    return this.http.post(url, formData);
  }
}
