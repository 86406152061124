import { createAction, props } from "@ngrx/store";
import { Countrie } from "src/app/models/countrie.model";

export const LOAD_LIST_COUNTRIES = 'LOAD COUNTRIES';
export const LOAD_LIST_COUNTRIES_SUCCESSFULLY = 'LOAD COUNTRIES SUCCESSFULY';
export const SET_USER_COUNTRY = 'SET_USER_COUNTRY';


export const loadCountries = createAction(
    LOAD_LIST_COUNTRIES  );

  export const loadCountriesSuccessfully = createAction(
    LOAD_LIST_COUNTRIES_SUCCESSFULLY,
    props<{ list_countries: Array<Countrie> }>()
  );
  export const setUserCountry = createAction(
    SET_USER_COUNTRY,
    props<{ country: Countrie }>()
  );

