export default class Salarie {
  id?: number;
  salary_amount: number;
  working_hours: number;
  admin_hour: number;
  coffe_hour: number;
  other_hour: number;
  social_charges_amount: number;
  journals_number: number;
  payment_type: string;
  employee_id: number;
  total_paid: number;
  extra: any;
  quantity_product: number;
  unit: number;
  comment: string;
  constructor() {}
}
export const SALARIES_KIND = [
  {
    id: 1,
    value: 'UNPAID',
    label: 'paid_type_1',
  },
  {
    id: 2,
    value: 'PAYMENT_IN_KIND',
    label: 'paid_type_2',
  },
  { id: 3, value: 'PAYMENT_PER_HARVESTED_QUANTITY', label: 'paid_type_4' },

  { id: 4, value: 'PAYMENT_PER_NUMBER_OF_DAY', label: 'paid_type_5' },
];

export const SALARIES_KIND_ADMINISTRATIVOS = [
  {
    id: 1,
    value: 'UNPAID',
    label: 'paid_type_1',
  },
  {
    id: 2,
    value: 'PAYMENT_IN_KIND',
    label: 'paid_type_2',
  },

  { id: 4, value: 'PAYMENT_PER_NUMBER_OF_DAY', label: 'paid_type_5' },
];
