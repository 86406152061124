import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AccountingEntryCategorie } from 'src/app/models/accounting/accounting_entries_categories';
import { FilterChoice } from 'src/app/modules/dashboard/cashflow/filters/analyses-filter/analyses-filter.component';
import { FilterWidgetComponent } from '../filter-widget/filter-widget.component';
import { AnalysisCashflowComponent } from 'src/app/modules/dashboard/cashflow/analysis-cashflow/analysis-cashflow.component';
import { CashflowSummaryDiaryComponent } from 'src/app/modules/dashboard/cashflow/cashflow-summary-diary/cashflow-summary-diary.component';

@Component({
  selector: 'app-home-filter',
  templateUrl: './home-filter.component.html',
  styleUrls: ['./home-filter.component.scss'],
})
export class HomeFilterComponent implements OnInit, OnChanges {
  @ViewChild('accordionGroup', { static: true }) accordionGroup: any;
  @Input('accountingCategories')
  accountingCategories: Array<AccountingEntryCategorie>;
  @Input() interfaceName = '';
  @Input() nav;
  @Input() isInternFilterNeeded = false;
  @Output() handelFilterChange: EventEmitter<any> = new EventEmitter();
  @Output() handelFilterClicked: EventEmitter<any> = new EventEmitter();

  constructor(
    public translateService: TranslateService,
    public router: Router,
    private modalController: ModalController,
    private routerOutlet: IonRouterOutlet
  ) {}
  filterOpened = true;
  @Input('filter') filter: FilterChoice = {
    sort_by: 'cashflowcategories',
    start_date: new Date(new Date().getFullYear(), 0, 1).toISOString(),
    end_date: new Date(new Date().getFullYear(), 11, 31).toISOString(),
    monthly_yearly: 'm-Y',
  };
  filterCategory: number[];
  ngOnInit() {
    this.filterCategory = this.accountingCategories?.map((val) => val.id);
    if (this.isInternFilterNeeded) {
      this.filterOpened = false;
    }
  }
  async goBack() {
    let rootParams = await this.nav.getActive();
    this.nav.setRoot(
      rootParams?.params?.rootStack
        ? rootParams?.params?.rootStack
        : AnalysisCashflowComponent,
      {
        nav: this.nav,
      }
    );
  }

  async openFilterSheet(): Promise<void> {
    if (!this.isInternFilterNeeded) {
      this.filterOpened = !this.filterOpened;

      this.handelFilterClicked.emit(this.filterOpened);
      return;
    }
    let componentProps = {
      filter: this.filter,
      accountingCategories: this.accountingCategories,
      modalController: this.modalController,
      // date: this.date
    };
    const modal = await this.modalController.create({
      component: FilterWidgetComponent,
      componentProps,
      cssClass: ['custom-modal-sheet', 'height-30', 'modal-wrapper'],
      swipeToClose: true,
      presentingElement: this.routerOutlet.nativeEl,
      animated: true,
      mode: 'ios',
      showBackdrop: true,
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    this.handelFilterChange.emit({
      filterCategory: data?.filterCategory,
      startDate: data?.startDate ? data?.startDate : this.filter.start_date,
      endDate: data?.endDate ? data?.endDate : this.filter.end_date,
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    // if(!this.filterCategory?.length){

    this.filterCategory = this.accountingCategories?.map((val) => val.id);
    // }
  }
  filterClicked() {
    this.filterOpened = !this.filterOpened;
    if (!this.isInternFilterNeeded) {
      this.handelFilterClicked.emit(this.filterOpened);
    }
  }

  handelChange(event) {
    this.handelFilterChange.emit({
      filterCategory: this.filterCategory,
      startDate: this.filter.start_date,
      endDate: this.filter.end_date,
    });
  }
}
