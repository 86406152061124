import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { AHOROS_ACCOUNTING_ENTRY_CATEGORY } from 'src/app/utils/consts';

@Component({
  selector: 'app-section-savings',
  templateUrl: './section-savings.component.html',
  styleUrls: ['./section-savings.component.scss'],
})
export class SectionSavingsComponent implements OnChanges {
  @Input() currency;
  @Input() balance;
  @Input() accountingCategories;
  @Input() isDataLoading:boolean;

  color: ThemePalette = 'accent';
  mode: 'determinate';
  value = 50;
  potentialSavings = 0;
  constructor() {}
  percent;
  
  ngOnChanges(changes: SimpleChanges): void {
    this.potentialSavings = this.accountingCategories?.find(
      (val) => val.id == AHOROS_ACCOUNTING_ENTRY_CATEGORY
    ).expenses;
  }
  // ngOnInit() {

  //   // if (this.balance > 0) this.potentialSavings = this.balance;
  //   // else this.potentialSavings = 0;

  //   // this.percent = this.potentialSavings/1000 * 100
  // }
}
