import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-rating-modal',
  templateUrl: './rating-modal.component.html',
  styleUrls: ['./rating-modal.component.scss'],
})
export class RatingModalComponent implements OnInit {
  @Input() modalController: ModalController;
  constructor() {}

  ngOnInit() {}
  vote(vote) {
    this.modalController.dismiss({ vote: vote });
  }
}
