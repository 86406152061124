import { nextOfflineIndex } from '../reducers/offline.reducer';
import { createAction, props } from '@ngrx/store';
import Partner from 'src/app/models/partner.model';

export const LOAD_PARTNER = 'LOAD_PARTNER';
export const LOAD_PARTNER_SUCCESS = 'LOAD_PARTNER_SUCCESS';

export const LOAD_PARTNER_TYPE = 'LOAD_PARTNER_TYPE';
export const LOAD_PARTNER_TYPE_SUCCESS = 'LOAD_PARTNER_TYPE_SUCCESS';
export const DELETE_PARTNER = 'DELETE_PARTNER';
export const DELETE_PARTNER_SUCCESS = 'DELETE_PARTNER_SUCCESS';
export const ADD_PARTNER = 'ADD_PARTNER';
export const ADD_PARTNER_SUCCESS = 'ADD_PARTNER_SUCCESS';
export const UPDATE_PARTNER = 'UPDATE_PARTNER';
export const UPDATE_PARTNER_SUCCESS = 'UPDATE_PARTNER_SUCCESS';

export const loadPartner = createAction(LOAD_PARTNER);
export const loadPartnerSuccess = createAction(
  LOAD_PARTNER_SUCCESS,
  props<{ partners: Array<Partner> }>()
);
export const loadPartnerType = createAction(LOAD_PARTNER_TYPE);
export const loadPartnerTypeSuccess = createAction(
  LOAD_PARTNER_TYPE_SUCCESS,
  props<{ partners_type: Array<Partner> }>()
);

export const deletePartner = createAction(
  DELETE_PARTNER,
  props<{ id: number }>()
);
export const deletePartnerSuccess = createAction(
  DELETE_PARTNER_SUCCESS,
  props<{ id: number }>()
);
export const addPartner = createAction(
  ADD_PARTNER,
  props<{ data: Partner; props: Object }>()
);
export const addPartnerSuccess = createAction(
  ADD_PARTNER_SUCCESS,
  props<{ data: Partner; offlineId: string }>()
);

export const updatePartner = createAction(
  UPDATE_PARTNER,
  props<{ data: Partner  }>()
);

export const updatePartnerSuccess = createAction(
  UPDATE_PARTNER_SUCCESS,
  props<{ data: Partner   }>()
);
