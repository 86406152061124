import { updateCashflow, updateCashflowSuccess } from './../actions/cashflow.action';
import { updateOwnerData } from 'src/app/utils/states-management/actions/owner.action';
import { cloneDeep } from 'lodash';
import { Store } from '@ngrx/store';
import { CAFE_ACCOUNTING_ENTRY_CATEGORY } from 'src/app/utils/consts';
import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { CashflowAPI } from 'src/app/apis/cashflow.service';
import {
  accountingEntriesLoadedSuccessfully,
  accountingEntriesLoading,
} from '../actions/accountings-entries.action';
import {
  deleteCashflow,
  deleteCashflowSuccess,
  getCashflowById,
  getCashflowByIdSuccess,
  paginateCashflow,
  paginateCashflowSuccess,
  saveCashflow,
  saveCashflowSuccess,
} from '../actions/cashflow.action';
import { dataSyncWithServerError } from '../actions/owner.action';
import { dev } from '../../../config/offlines_keys.json';
import { AccountingEntryCategory } from 'src/app/models/cash-flow/cashflow_categories';
import {
  loadOtrosListableItemsSuccess,
  LOAD_OTROS_LISTABLE_ITEMS,
} from '../actions/prodcuction-cashflow-categories.action';
import { OtrosDisplayableListItem } from 'src/app/models/cash-flow/otros_displayable_list_item';
import { PaginationResult } from 'src/app/models/pagination-result.model';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import Owner from 'src/app/models/owner.model';
import { nextOfflineIndex } from '../reducers/offline.reducer';
import { UpdateSync } from '../actions/offline.action';

@Injectable()
export class CashflowEffect {
  getCashflow$ = createEffect(() =>
    this.actions.pipe(
      ofType(getCashflowById),
      switchMap((action) => {
        return this.cashflowAPI.getCashflowById(action.id).pipe(
          map((value) => getCashflowByIdSuccess({ data: value })),
          catchError((err) => of(dataSyncWithServerError(err)))
        );
      })
    )
  );

  getCashflowsCategories$ = createEffect(() =>
    this.actions.pipe(
      ofType(accountingEntriesLoading),

      // switchMap((action) =>
      //   from(Storage.get({ key: dev.CASHFLOW_CATEGORIES })).pipe(
      //     map((value) => {
      //       if (value.value) return JSON.parse(value.value);
      //       else return action.country_id;
      //     })
      //   )
      // ),
      switchMap((value) => {
        // if (typeof value == 'number') {
        return this.cashflowAPI.getCashflowByCountry(value.country_id).pipe(
          map((value: Array<AccountingEntryCategory>) => {
            Storage.set({
              key: dev.CASHFLOW_CATEGORIES,
              value: JSON.stringify(value),
            });
            return accountingEntriesLoadedSuccessfully({
              accountingEntries: value,
            });
          }),
          catchError((err) => of(dataSyncWithServerError(err)))
        );
        // } else return of(value);
      })
    )
  );

  loadOtrosDisplayableListItem$ = createEffect(() => {
    return this.actions.pipe(
      ofType(LOAD_OTROS_LISTABLE_ITEMS),
      switchMap((action) =>
        this.cashflowAPI.getAgriculturalProductWithCashflowCategories().pipe(
          map((apiResponse) => {
            Storage.set({
              key: dev.ALL_AGRICULTURAL_CATEGORIES,
              value: JSON.stringify(apiResponse),
            });

            return apiResponse;
          }),
          catchError((err) => of(dataSyncWithServerError(err)))
        )
      ),
      map((values: Array<OtrosDisplayableListItem>) =>
        loadOtrosListableItemsSuccess({ otrosListablesItems: values })
      )
    );
  });
  paignateCashflow$ = createEffect(() =>
    this.actions.pipe(
      ofType(paginateCashflow),
      switchMap((action) =>
        this.cashflowAPI
          .getCashflowByDate(
            action.startDate,
            action.endDate,
            action.page,
            // action.page_size,
            10,
            action.accountings_entries,
            action.natures
          )
          .pipe(map((endpointResult) => endpointResult))
      ),
      map((val: PaginationResult<CashFlow>) =>
        paginateCashflowSuccess({ paginationResult: val })
      )
    )
  );

  deleteCashflow$ = createEffect(() =>
    this.actions.pipe(
      ofType(deleteCashflow),
      switchMap((value) => {
        return this.cashflowAPI.deleteCashflow(value.id).pipe(
          map((valu) => deleteCashflowSuccess({ id: valu.id })),
          // catchError((err) => of(dataSyncWithServerError(err)))
        );
      })
    )
  );

  saveCashflow$ = createEffect(() =>
    this.actions.pipe(
      ofType(saveCashflow),
      switchMap((action) => {
        return this.cashflowAPI.saveCashFlow(action.data).pipe(
          switchMap(async (result) => {
            return saveCashflowSuccess({
              data: result,
              offlineId: await this.store
                .select(nextOfflineIndex)
                .pipe(take(1))
                .toPromise(),
            });
          })
        );
      })
    )
  );
  saveCashflowSuccess$ = createEffect(() =>
    this.actions.pipe(
      ofType(saveCashflowSuccess),
      map((action: any) => {
        return UpdateSync({
          data: action?.data,
          offlineId: action.offlineId,
          stateName: 'cashflowList',
        });
      })
    )
  );
  updateCashflow$ = createEffect(() =>
    this.actions.pipe(
      ofType(updateCashflow),
      switchMap((action) => {
        return this.cashflowAPI.updateCashFlow(action.data).pipe(
          map((result) => {
            return updateCashflowSuccess({
              data: result?.data
            });
          })
        );
      }) 

    )
  );
  updateCashflowSuccess$ = createEffect(() =>
    this.actions.pipe(
      ofType(updateCashflowSuccess),
      map((action: any) => {
        return UpdateSync({
          data: action.data,
          offlineId: action.data.id,
          stateName: 'cashflowList',
        });
      })
    )
  );
  async updateOwnerProfileInfo() {
    let owner = cloneDeep(
      await this.store.select('owner').pipe(take(1)).toPromise()
    );
    let profile_info = cloneDeep(JSON.parse(owner?.profile_info)) || null;
    profile_info.has_crop_cashflow = true;

    owner.profile_info = JSON.stringify(profile_info);
    this.store.dispatch(updateOwnerData({ owner: owner }));
  }
  constructor(
    private cashflowAPI: CashflowAPI,
    private actions: Actions,
    private store: Store<{ owner: Owner }>
  ) {}
}
