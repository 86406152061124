import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AnimationController } from '@ionic/angular';
import { element } from 'protractor';

@Component({
  selector: 'app-landing-swipe-step',
  templateUrl: './landing-swipe-step.component.html',
  styleUrls: ['./landing-swipe-step.component.scss'],
})
export class LandingSwipeStepComponent implements OnInit {
  constructor(private animationController: AnimationController) {}
  @Input('step') step;
  @Input('id') id;
  @Input('title_step') title_step;
  @Input('description_step') description_step;
  @Input('img1_src') img1_src;
  @Input('img2_src') img2_src;
  @Output() nextClicked: EventEmitter<any> = new EventEmitter();
  @Output() previousClicked: EventEmitter<any> = new EventEmitter();
  interval;
  animation1;
  animation2;
  reverseAnimation1;
  reverseAnimation2;
  is_animation_work = false;

  async ngOnInit() {
    
  }
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.step == this.id && !this.is_animation_work && !this.interval) {
      this.interval = setInterval(async () => {
        this.is_animation_work = true;
        await this.startAnimation('');
      }, 7000);
    } 
    else {
      // this.stopAnimation();
    }
  }

  async handelBackClicked() {
    await this.stopAnimation();

    this.previousClicked.emit(this.step - 1);
  }
  async handelNextClicked() {
    await this.stopAnimation();
    this.previousClicked.emit(this.step + 1);
  }

  async stopAnimation() {
    await Promise.all([
      this.animation1?.stop(),
      this.animation2?.stop(),
      this.reverseAnimation1?.stop(),
      this.reverseAnimation2?.stop(),
    ]);
    this.is_animation_work = false;
    clearInterval(this.interval);
    this.interval = null;
  }
  async startAnimation(selector) {
    this.animation1 = this.animationController
      .create()
      .addElement(document.querySelector('#img2-' + this.id))
      .duration(1200)
      .fromTo(
        'transform',
        'scale(1.4) rotate(0) translateX(0px) translateY(0px)',
        'scale(0.5) rotate(22.5deg) translateX(400px) translateY(150px)'
      ) // Zoom in
      .fromTo('zIndex', '100', '0')
      .afterStyles({ 'animation-timing-function': 'ease-in-out' })
      .beforeStyles({ 'animation-timing-function': 'ease-in-out' });

    this.animation2 = this.animationController
      .create()
      .addElement(document.querySelector('#img1-' + this.id))
      .duration(1200) // Adjust the duration as needed
      .fromTo(
        'transform',
        'scale(1) rotate(-22.5deg) translateX(0px) translateY(0px)',
        'scale(2.3) rotate(0) translateX(60px) translateY(-130px)'
      ) // Zoom in
      .fromTo('zIndex', '0', '999')
      .afterStyles({ 'animation-timing-function': 'ease-in-out' })
      .beforeStyles({ 'animation-timing-function': 'ease-in-out' });

    this.reverseAnimation1 = this.animationController
      .create()
      .addElement(document.querySelector('#img2-' + this.id))
      .duration(1200)
      .fromTo(
        'transform',
        'scale(0.5) rotate(22.5deg) translateX(400px) translateY(150px)',
        `scale(1) rotate(0) translateX(0px) translateY(0px)`
      )
      .fromTo('zIndex', '0', '100')
      .afterStyles({ 'animation-timing-function': 'ease-in-out' })
      .beforeStyles({ 'animation-timing-function': 'ease-in-out' });

    this.reverseAnimation2 = this.animationController
      .create()
      .addElement(document.querySelector('#img1-' + this.id))
      .duration(1200)
      .fromTo(
        'transform',
        'scale(2.3) rotate(0) translateX(60px) translateY(-130px)',
        'scale(1) rotate(-22.5deg) translateX(0px) translateY(0px)'
      )
      .fromTo('left', '0', '30%')
      .fromTo('zIndex', '999', '0')
      .afterStyles({ 'animation-timing-function': 'ease-in-out' })
      .beforeStyles({ 'animation-timing-function': 'ease-in-out' });

    await Promise.all([this.animation1.play(), this.animation2.play()]);

    setTimeout(async () => {
      await Promise.all([
        this.reverseAnimation1.play(),
        this.reverseAnimation2.play(),
      ]);
      this.is_animation_work = false;
    }, 3000);
  }
}
