import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ToastUtils } from 'src/app/utils/toast_builder';
@Component({
  selector: 'app-new-access-modal',
  templateUrl: './new-access-modal.component.html',
  styleUrls: ['./new-access-modal.component.scss'],
})
export class NewAccessModalComponent implements OnInit {
  @Input() langage_package;
  @Input() modalController;
  @Input() countries;
  @Input() id;
  @Input() token;
  prefix;
  authNumber;
  constructor(private httpClient: HttpClient,private toastUtils: ToastUtils,
    ) {}

  ngOnInit() {
  }

  cancel() {
    this.modalController.dismiss();
  }

  submit() {
    const phoneNumber = this.prefix.toString() + this.authNumber.toString();
    ;
  }
  

}
