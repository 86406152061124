import Employee from 'src/app/models/employee.model';
import { Countrie } from './countrie.model';
export default class Owner {
  first_name;
  last_name;
  birth_date;
  sex;
  mobile_number;
  prefix;
  hasSelectedPhoto : boolean = false;
  image_path;
  image_id;
  country_id;
  id;
  blobFile;
  profile_info;
  checkpoint_path: string;
  updated_at: string;
  created_at: string;
  is_owner: boolean;
  user;
  file;
  network;
  countrie;
  preferred_language
  employee;
  constructor() {}

  initFromValues(values: any) {
    this.first_name = values?.first_name;
    this.last_name = values?.last_name;
    if (values?.birth_date && values?.birth_date != '0000-00-00')
      this.birth_date = new Date(values?.birth_date).toISOString();
    else {
      this.birth_date = new Date().toISOString();
    }
    this.sex = values?.sex;
    this.mobile_number = values?.mobile_number;
    this.prefix = values?.prefix;
    this.file = values.file;
    this.country_id = values?.country_id;
    this.id = values?.id;
    this.is_owner = values?.is_owner;
    this.user = values?.user;
    this.updated_at = values?.updated_at;
    this.created_at = values?.created_at;
    this.network = values?.network;
    if (values.birth_date) {
      if (values?.birth_date && values?.birth_date != '0000-00-00') {
        this.birth_date = new Date(values.birth_date).toISOString();
      }
      // else {
      //   this.owner.birth_date = this.dateString;
      // }
    }
  }

  canInsert(): boolean {
    if (
      !this.last_name ||
      !this.first_name ||
      !this.birth_date ||
      this.sex === undefined ||
      this.sex === ''
    ) {
      return false;
    }
    return true;
  }
}
