import { MORPH_Objective } from './../../../utils/consts';
import { ToastUtils } from 'src/app/utils/toast_builder';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Storage } from '@capacitor/storage';
import { ModalController } from '@ionic/angular';
import { CashflowAPI } from 'src/app/apis/cashflow.service';
import { ObjectiveApi } from 'src/app/apis/objectives.service';
import { dev } from '../../../config/offlines_keys.json';
import { CashflowSelectorComponent } from '../../dashboard/widgets/cashflow-selector/cashflow-selector.component';
import { ObjectivesFormsComponent } from '../objectives-forms/objectives-forms.component';
import { Farm } from 'src/app/models/farm/farm.model';
import Owner from 'src/app/models/owner.model';
import { UserInfo } from 'src/app/apis/users-info.service';
import { map, switchMap, take } from 'rxjs/operators';
import { from, lastValueFrom, Observable } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-objectives-list',
  templateUrl: './objectives-list.component.html',
  styleUrls: ['./objectives-list.component.scss'],
})
export class ObjectivesListComponent implements OnInit, OnChanges {
  @Input() objectives;
  currency;
  objectivesList: any;

  constructor(
    private modalController: ModalController,
    private toastUtils: ToastUtils,
    private objectiveAPI: ObjectiveApi,
    private cashflowAPI: CashflowAPI,
    private store:Store
  ) {}

  async ngOnInit() {
    let country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    this.currency = country.currency
  }

  ngOnChanges(changes: SimpleChanges) {
    this.refresh().subscribe();
  }

  async updateObjective(objective) {
    let componentProps = {
      objective: objective,
      modalController: this.modalController,
      objectiveApi: this.objectiveAPI,
    };
    let modal = await this.toastUtils.modalBuilder(
      ObjectivesFormsComponent,
      componentProps
    );

    await modal.onWillDismiss();

    this.refresh().subscribe;
  }

  async deleteObjective(objective) {
    this.objectiveAPI
      .deleteObjective(objective.id)
      .pipe(
        map((response) => response),
        switchMap((response) => this.refresh()),
        switchMap((val) =>
          this.cashflowAPI.getCashflowByMorphableTypeAndMorphableIdAndFarmId(
            MORPH_Objective,
            objective.id
          )
        ),
        switchMap((values) => {
          if (Array.isArray(values) && values.length > 0) {
            let componentProps = {
              cashflows: values,
              modalController: this.modalController,
            };
            return from(
              this.toastUtils.modalBuilder(
                CashflowSelectorComponent,
                componentProps
              )
            );
          }
        }),
        map((modal) => modal)
      )
      .subscribe((modal) => modal.present());
  }

  refresh() {
    return this.objectiveAPI
      .getObjectives()
      .pipe(map((values) => (this.objectivesList = values)));
  }

  calculatePercent(required_amount, gathered_amount) {
    if (!gathered_amount) {
      gathered_amount = 0;
    }
    return gathered_amount / required_amount;
  }
}
