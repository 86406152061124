import { Injectable } from '@angular/core';
import { AnimationController } from '@ionic/angular';
@Injectable()
export class AnimationUtils {
  constructor(private animationCtrl: AnimationController) {}
  async expandAnimation(item) {
    document.getElementById(item?.id).style.display = "block"

    await this.animationCtrl
      .create()
      .addElement(document.getElementById(item.id))
      .duration(200)
      .fromTo('height', '0', '100%!important')
      .keyframes([
        { offset: 0, height: '0px' },
        { offset: 0.1, height: '10px' },
        { offset: 0.25, height: '20px' },
        { offset: 0.5, height: '30px' },
        { offset: 0.55, height: '40px' },
        { offset: 0.65, height: '45px' },
        { offset: 0.7, height: '50px' },
        { offset: 0.75, height: '60px' },
        { offset: 0.85, height: '70px' },
        { offset: 0.9, height: '80px' },
        { offset: 0.95, height: '90px' },
        { offset: 1, height: 'auto' },
      ])
      .play();
  }

  async collapsAnimation(item) {
    await this.animationCtrl
      .create()
      .addElement(document.getElementById(item.id))
      .duration(200)
      .fromTo('height', 'auto', '0px')
      .keyframes([
        { offset: 0, height: 'auto' },
        { offset: 0.1, height: '90px' },
        { offset: 0.25, height: '80px' },
        { offset: 0.5, height: '70px' },
        { offset: 0.55, height: '60px' },
        { offset: 0.65, height: '50px' },
        { offset: 0.7, height: '45px' },
        { offset: 0.75, height: '40px' },
        { offset: 0.85, height: '30px' },
        { offset: 0.9, height: '20px' },
        { offset: 0.95, height: '10px' },
        { offset: 1, height: '0px' },
      ])
      .play();
      if(document.getElementById(item.id)){
        document.getElementById(item.id).style.display = "none"

      }
  }

  async routerAnimation() {
    const CustomPageTransaction = (baseEl: any, opts?: any) => {
      var anim1 = this.animationCtrl
        .create()
        .addElement(opts.leavingEl)
        .duration(200)
        .iterations(1)
        .easing('ease-in')
        .fromTo('opacity', '1', '0.0');

      // var anim2 = this.animationCtrl
      //   .create()
      //   .addElement(opts.enteringEl)
      //   .duration(200)
      //   .iterations(1)
      //   .easing('ease-in')
      //   .fromTo('opacity', '0.0', '1');
      // var anim2 = this.animationCtrl
      //   .create()
      //   .duration(200)
      //   .iterations(1)
      //   .addAnimation([anim1, anim2]);
      var anim2 = this.animationCtrl.create('slide-in-animation')
      .addElement(opts.enteringEl)
      .duration(300) // adjust the duration as needed
      .easing('ease-out')
      .beforeStyles({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      })
      .fromTo('left', '100%', '0%')
      .afterClearStyles(['position', 'top', 'left', 'width']);
      return anim2;
    };
    return CustomPageTransaction;
  }
  async fadeInOutAnimation(id, duration = 1000) {
    await this.animationCtrl
      .create()
      .addElement(document.getElementById(id))
      .duration(duration)
      .iterations(Infinity)
      .fromTo('opacity', 0, 1)
      .keyframes([
        { offset: 0.25, transfrom: 'scale(1)', opacity: 0.2 },
        { offset: 0.5, transfrom: 'scale(1.2)', opacity: 0.5 },
        { offset: 0.75, transfrom: 'scale(1.6)', opacity: 0.7 },
        { offset: 1, transfrom: 'scale(2)', opacity: 1 },
      ])

      .play();
  }
}

export const slideAnimation = (baseEl: HTMLElement, opts?: any) => {
  const animationCtrl = new AnimationController();
  const baseAnimation = animationCtrl.create('slide-in-animation')
    .addElement(baseEl)
    .duration(300) // adjust the duration as needed
    .easing('ease-out')
    .beforeStyles({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
    })
    .fromTo('left', '100%', '0%')
    .afterClearStyles(['position', 'top', 'left', 'width']);

  return baseAnimation;
};