import { RoutingConfig } from 'src/app/utils/routing-config';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-footer-team',
  templateUrl: './footer-team.component.html',
  styleUrls: ['./footer-team.component.scss'],
})
export class FooterTeamComponent implements OnInit, OnChanges {
  previousUrl: any;
  @Input('matStepperNext') matStepperNext: any;
  @Input('steps') steps: any;
  @Input() step;
  @Input() has_permanent = false;
  @Input() has_temporal = false;
  @Input() formConfig;
  @Output() nextClicked: EventEmitter<any> = new EventEmitter();

  next_button;
  ConfigIndex;
  constructor(public router: Router, private routingConfig: RoutingConfig) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event;
      });
  }

  async ngOnChanges(changes: SimpleChanges) {
    // await this.ngOnInit();
  }

  async ngOnInit() {
    if (this.formConfig) var arrConfig = Object.entries(this.formConfig);
    else var arrConfig = Object.entries({});

    if (arrConfig.length == 3) {
      this.ConfigIndex = 0;
    } else if (arrConfig.length == 2) {
      this.ConfigIndex = 1;
    } else if (arrConfig.length == 1) {
      this.ConfigIndex = 3;
    }
  }

  async next() {
    var arrConfig = Object.entries(this.formConfig);

    switch (true) {
      case arrConfig.length == 3:
        if (this.steps.selectedIndex == 4) {
          this.router.navigateByUrl(await this.routingConfig.getNextUrl());
        } else if (this.steps.selectedIndex == 1 && !this.has_permanent) {
          this.steps.selectedIndex = 3;
        } else if (this.steps.selectedIndex == 3 && !this.has_temporal) {
          this.router.navigateByUrl(await this.routingConfig.getNextUrl());
        } else this.steps.selectedIndex+=1;

        break;
      case arrConfig.length == 2:
        if (this.steps.selectedIndex == 2) {
          this.router.navigateByUrl(await this.routingConfig.getNextUrl());
        } else if (this.steps.selectedIndex == 0 && !this.has_permanent) {
          this.steps.selectedIndex = 1;
        } else if (this.steps.selectedIndex == 1 && !this.has_temporal) {
          this.nextClicked.emit('hel');

          this.router.navigateByUrl(await this.routingConfig.getNextUrl());
        } else this.steps.selectedIndex+=1;
        break;
      case arrConfig.length == 1:
        if (
          this.steps.selectedIndex == 0 ||
          (this.steps.selectedIndex == 1 && this.formConfig.employee_fynka_lite)
        )
          this.steps.selectedIndex+=1;
        else {
          this.router.navigateByUrl(await this.routingConfig.getNextUrl());
        }
        break;
    }
  }

  async previous() {
    if (this.steps.selectedIndex == 0) {
      this.router.navigateByUrl(await this.routingConfig.getBackUrl());
    } else if (this.steps.selectedIndex == 4 && !this.has_permanent) {
      this.steps.selectedIndex = 2;
    } else if (this.steps.selectedIndex == 4 && this.has_temporal)
      this.steps.selectedIndex = 2;
    else this.steps.selectedIndex-=1;
  }
}
