import { RoutingConfig } from 'src/app/utils/routing-config';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-family-main-view',
  templateUrl: './family-main-view.component.html',
  styleUrls: ['./family-main-view.component.scss'],
})
export class FamilyMainViewComponent implements OnInit {
  family;
  next;
  return;

  constructor(
    private router: Router,
    public translateService: TranslateService,
    private routingConfig: RoutingConfig
  ) {}

  async ngOnInit() {
    await this.langageChanger(localStorage.getItem('CapacitorStorage.LANGAGE'));
  }
  async langageChanger(lang)  {
    this.next = await this.translateService
      .get('start')
      .toPromise()
    this.return = await this.translateService
      .get('return')
      .toPromise()

  }
  async mnext() {
    this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }
  async mreturn() {
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());  
  }
}
