import { RoutingConfig } from 'src/app/utils/routing-config';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Farm } from '../../../../models/farm/farm.model';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../../config/offlines_keys.json';
import { Router, ActivatedRoute } from '@angular/router';
import routes from '../../../../config/routing.json';
import { IonNav, LoadingController } from '@ionic/angular';
import { FarmAPI } from '../../../../apis/farm.service';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap, take } from 'rxjs/operators';
import { handleResponse } from 'src/app/utils/user_data_storage';
import { from, lastValueFrom } from 'rxjs';
import { OwnerAPI } from 'src/app/apis/owners.service';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-cluster-main-details',
  templateUrl: './cluster-main-details.component.html',
  styleUrls: ['./cluster-main-details.component.scss'],
})
export class ClusterMainDetailsComponent implements OnInit {
  selectedLangage;
  label;
  farm: any = new Farm();
  @Input() nav: IonNav;
  @Input() rootStack;
  @Output() ClusterInformationLoaderEvent: EventEmitter<any> =
    new EventEmitter();

  isDashboardView;
  loadingModal;
  formConfig;
  owner;
  constructor(
    public route: ActivatedRoute,
    private farmAPI: FarmAPI,
    private router: Router,
    private loadingController: LoadingController,
    public translateService: TranslateService,
    private toastController: ToastUtils,
    private ownerApi: OwnerAPI,
    private routingConfig: RoutingConfig,
    private store:Store
  ) {
    this.translateService
      .get('label')
      .subscribe((value) => (this.label = value));
  }

  async ngOnInit() {
    this.owner = await Storage.get({ key: dev.USER_KEY }).then((value) =>
      JSON.parse(value.value)
    );
    let ClassName = 'ClusterMainDetailsComponent';
    this.formConfig = (
      await this.routingConfig.getPageFormConfig(ClassName)
    )?.fields;
    this.loadingModal = await this.loadingController.create();
    this.translate();

    let farm =  await lastValueFrom(this.store.select(getFarm).pipe(take(1)))
    this.farm.instantiateFromObject(farm);
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
  }

  translate() {
    this.selectedLangage = this.translateService.currentLang;
  }

  async redirectToNextScreen() {
    this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }


  async presentToast(message) {
    await this.toastController
      .toastBuilder('', 'bottom', message, 'danger');
  }
  async submitFormDashboardViewForm() {
    this.loadingModal.present();
    const farmId = this.farm?.id;
    const farmCluster_id = this.farm?.cluster_id;
    const farmCluster_number = this?.farm?.cluster_number;
    const farmAmbassador_id = this?.farm.ambassador_id;
    this.farmAPI
      .upDateFarmCluster({
        farmId: farmId,
        clustId: farmCluster_id,
        clusterNumber: farmCluster_number,
        ambassadorId: farmAmbassador_id,
        owner_id: this.owner.id,
      })
      .pipe(
        map((result) => {
          this.farm = result;
          return result;
        }),
        switchMap((result) => this.ownerApi.getOwnerById()),
        map((getOwnerResult) => getOwnerResult),
        switchMap((getOwnerResult) => from(handleResponse(getOwnerResult))),
        map((finishedWork) => finishedWork)
      )
      .subscribe(() => this.loadingModal.dismiss());
  }

  async previous() {
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());
  }
}
