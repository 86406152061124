import { dev } from 'src/app/config/offlines_keys.json';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { lastValueFrom, take } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-currency-selector',
  templateUrl: './currency-selector.component.html',
  styleUrls: ['./currency-selector.component.scss'],
})
export class CurrencySelectorComponent implements OnInit {

  constructor(private store:Store) { }
@Input() currency: String = "";
@Input() label : String = "";
@Output() outputCurrency: EventEmitter<String> =new EventEmitter<String>();
  async ngOnInit() {
    await this.getcountrie_currency();

  }

  getcountrie_currency = async ()=> {
    let country =await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    this.currency =  country?.currency;
      this.outputCurrency.emit(this.currency);

    }


  compareFn(e1, e2): boolean {
    return e1 === e2;
  }

}
