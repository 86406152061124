import { GoodsAPI } from 'src/app/apis/goods.service';
import { orderAlphabaticallyUnit } from './../../../utils/array-filtrer';
import { Component, Input, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../config/offlines_keys.json';
import { ModalController } from '@ionic/angular';
import units from '../../../config/unit.json';
import routes from '../../../config/routing.json';
import { Router } from '@angular/router';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { TranslateService } from '@ngx-translate/core';
import { Good } from 'src/app/models/good.model';
import { lastValueFrom, take } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-material-good-form',
  templateUrl: './material-good-form.component.html',
  styleUrls: ['./material-good-form.component.scss'],
})
export class MaterialGoodFormComponent implements OnInit {
  @Input() productList: any[];
  @Input() modalControllerMain: ModalController;
  @Input() data;

  current_product: any;
  selectedLangage;
  selected_product: any = null;
  measure_unit: any = units;
  isDashboardView;
  is_product_selection_view = true;
  is_update = false;
  is_animal_proprety = false;
  initial_value = 0;
  constructor(
    private router: Router,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private goodAPI: GoodsAPI,
    private store:Store
  ) {}

  async ngOnInit() {
    if (this.data) {
      this.is_update = true;
      this.is_product_selection_view = false;
      this.selected_product = this.data.material_product;
      this.selected_product.qty = this.data.qty;
      this.selected_product.material_product_id = this.data.material_product_id;
      this.initial_value = this.data.qty;
      this.is_animal_proprety =
        this.data?.material_product?.categorie?.parent_categorie?.id === 6;
    }
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
    this.selectedLangage = this.translateService.currentLang;
    this.productList = await this.goodAPI.getGoodsByUser().toPromise();
    this.prepareUnitList();
  }

  async prepareUnitList() {
    const country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    country.name == 'Colombia'
      ? (this.measure_unit = units.colombia)
      : (this.measure_unit = units.costa_rica);

    this.measure_unit = orderAlphabaticallyUnit(
      this.measure_unit,
      this.selectedLangage
    );
  }
  async selectProduct() {
    this.is_product_selection_view = !this.is_product_selection_view;
  }

  dismiss() {
    this.modalControllerMain.dismiss();
  }

  async submut() {
    this.selected_product.qty = parseInt(this.selected_product.qty);
    if (this.selected_product.qty && this.selected_product.qty != 0) {
      this.submitTreatment();
    } else {
      await this.toastUtils
        .toastBuilder(
          await this.translateService.instant('error'),
          '',
          '',
          ''
        )
    }
  }

  productSelected(event) {
    this.selected_product = event;
    this.selected_product.unit = 'unit';
    this.is_product_selection_view = !this.is_product_selection_view;
  }

  submitTreatment() {
    if (this.data) {
      const good = new Good();
      good.material_product_id = this.selected_product.id;
      good.qty = this.selected_product.qty;
      good.unit = 'unit';
      good.id = this.data.id;
      this.updateGood(good);
    } else if (this.productList && this.productList.length > 0) {
      var old_good = this.productList?.find(
        (element) => element.material_product_id === this.selected_product.id
      );
      if (old_good) {
        old_good.qty += this.selected_product.qty;
        this.updateGood(old_good);
      } else this.insertNewGoodEntry();
    } else this.insertNewGoodEntry();
  }

  insertNewGoodEntry() {
    let good = new Good();
    good.qty = this.selected_product.qty;
    good.material_product_id = this.selected_product.id;
    good.unit = 'unit';
    this.goodAPI.insertGood([good]).subscribe((value) => {
      this.modalControllerMain.dismiss({
        product: value,
      });
    });
  }
  updateGood(good) {
    this.goodAPI.updateGood(good).subscribe((value) => {
      this.modalControllerMain.dismiss({
        product: value,
      });
    });
  }
}
