import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonRouterOutlet, IonToolbar, IonicModule } from '@ionic/angular';

import { HomePageRoutingModule } from './home-routing.module';

import { HomePage } from './home.page';
import { TabsMenuComponent } from './widgets/tabs-menu/tabs-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { HomeFilterComponent } from './widgets/home-filter/home-filter.component';
import { DateFormatPipe } from 'src/app/utils/pipes-utils/date_formatter';
import { FooterModule } from 'src/app/widgets/footer-component/header-footer.module';
import { BookSingleEntryComponent } from './widgets/book-single-entry/book-single-entry.component';
import {
  GetFirstActivityLevelPipe,
  IsHarvestingActivity,
} from 'src/app/utils/pipes-utils/activity_pipe';
import { AnalysisPageModule } from './analysis/analysis.module';
import { BookPageModule } from './book/book.module';
import { LanguageComponent } from './widgets/language/language.component';
import { ProductAnimalInterfaceComponent } from './widgets/product-animal-interface/product-animal-interface.component';
import { FarmModule } from '../farm/farm.module';
import { MatTabsModule } from '@angular/material/tabs';
import { FilterWidgetComponent } from './widgets/filter-widget/filter-widget.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HomePageRoutingModule,
    TranslateModule.forChild(),
    FooterModule,
    FarmModule,
    MatTabsModule
  ],
  exports: [
    TabsMenuComponent,

  ],
  providers: [DateFormatPipe, GetFirstActivityLevelPipe, IsHarvestingActivity],
  declarations: [
    HomePage,
    TabsMenuComponent,
    LanguageComponent,
    ProductAnimalInterfaceComponent,
    FilterWidgetComponent
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HomePageModule {}
