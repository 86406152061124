import { CashflowModule } from './../cashflow/cashflow.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FooterModule } from '../../widgets/footer-component/header-footer.module';
import { ModalDebtComponent } from './modal-debt/modal-debt.component';
import { DebtsComponent } from './debts/debts.component';
import { DebtFromComponent } from './debt-from/debt-from.component';
import { DebtsScreensComponent } from './debts-screens.component';
import { DebtsRoutingModule } from './debts-routing.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FooterModule,
    DebtsRoutingModule,
    CashflowModule,
    TranslateModule.forChild(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    DebtsComponent,
    DebtFromComponent,
    DebtsScreensComponent,
    ModalDebtComponent,
  ],
  exports: [
    DebtsComponent,
    DebtFromComponent,
    DebtsScreensComponent,
    ModalDebtComponent,
  ],
})
export class DebtsModule {}
