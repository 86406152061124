import { orderAlphabaticallyUnit } from '../../../../utils/array-filtrer';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import routes from '../../../../config/routing.json';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../../config/offlines_keys.json';
import units from '../../../../config/unit.json';
import { UserInfo } from 'src/app/apis/users-info.service';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';
import { ProductionAPI } from 'src/app/apis/FarmAPI/productions.service';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, take } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-product-update-form',
  templateUrl: './product-update-form.component.html',
  styleUrls: ['./product-update-form.component.scss'],
})
export class ProductUpdateFormComponent implements OnInit {
  @Input() modalController;
  @Input() is_preproduct_view;
  @Input() title;
  @Input() product: ProductionsPerfarm;
  units;
  unitsWithUnit;
  isDashboardView;
  token;
  lang;
  allUnits;
  constructor(
    private router: Router,
    private userInfo: UserInfo,
    private productAPI: ProductionAPI,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private store:Store
  ) {}

  async ngOnInit() {
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
    this.lang = await this.userInfo.getSelectedLangage();
    await this.prepareUnits();
  }
  async prepareUnits() {
    let country=await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
      country?.id === 1
      ? (this.units = units.costa_rica)
      : (this.units = units.colombia);
    this.units = orderAlphabaticallyUnit(this.units, this.lang);
    this.allUnits = this.units;
    this.unitsWithUnit = this.units.filter(
      (element) => element.type === 'p' || element.type === 'n'
    );

    this.units = this.units.filter((element) => element.type === 'p');
  }

  async submit() {
    const owner = await this.userInfo.getOwner();
    if (
      this.product.goods &&
      this.product.goods.length > 0 &&
      this.product.goods[0].qty &&
      !this.product.goods[0].unit
    )
      await this.toastUtils
        .toastBuilder(
          await this.translateService
            .instant('missing_fields'),
          '',
          ''
        );
    else
      this.productAPI.updateProduction(this.product).subscribe(
        (value) => {
          this.modalController.dismiss({ products: [this.product] });
        },
        (e) => {
          this.modalController.dismiss({ products: [this.product] });
        }
      );
    this.units = orderAlphabaticallyUnit(this.units, this.lang);
  }

  unitChanged(event, product) {
    product.unit = event.target.value.value;
  }
  async cancel() {
    this.modalController.dismiss({ products: [this.product] });
  }
}
