import { RoutingConfig } from 'src/app/utils/routing-config';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import routes from '../../../config/routing.json';
@Component({
  selector: 'app-debts',
  templateUrl: './debts.component.html',
  styleUrls: ['./debts.component.scss'],
})
export class DebtsComponent implements OnInit {
  debts;
  start;
  return;
  constructor(
    private router: Router,
    public translateService: TranslateService,
    private routingConfig: RoutingConfig
  ) {}

  async ngOnInit() {
    await this.langageChanger();
  }
  async langageChanger() {
    this.start = await this.translateService.get('start').toPromise();

    this.return = await this.translateService.get('return').toPromise();

    this.debts = await this.translateService.get('debts').toPromise();
  }
  async mreturn() {
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());
  }
  async mstart() {
    this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }
}
