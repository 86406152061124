import { Component, Input, OnInit } from '@angular/core';
import { PriorDashboardComponent } from 'src/app/modules/prior-dashboard/prior-dashboard.component';

@Component({
  selector: 'app-license-agreement-widget',
  templateUrl: './license-agreement.component.html',
  styleUrls: ['./license-agreement.component.scss'],
})
export class LicenseAgreementComponent implements OnInit {
  @Input() nav;
  @Input() rootStack;
  @Input() isLicense;
  @Input() submit;
  
  constructor() {}


  ngOnInit() {}

  goBack() {
    this.nav.setRoot(this.rootStack,{
      nav: this.nav,
      rootStack: PriorDashboardComponent,
    });
  }
}
