import { ProgrammesFilter } from './programmes-filter/programmes-filter.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterModule } from '../../widgets/footer-component/header-footer.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ProgrammesInfoRoutingModule } from './programmes-Info-routing.module';
import { ProgramComponent } from './program/program.component';
import { VideosModule } from 'src/app/utils/videos/videos.module';
import { ProgrammesListComponent } from './programmes-list/programmes-list.component';
import { ScrollableElementsListComponent } from './program/scrollable-elements-list/scrollable-elements-list.component';
import { AssistanceModalComponent } from './program/assistance-modal/assistance-modal.component';
import { SkeletonLoaderModule } from 'src/app/widgets/skeleton-loader/skeleton-loader.module';

@NgModule({
  declarations: [
    ProgramComponent,
    ProgrammesListComponent, 
    ScrollableElementsListComponent,
    AssistanceModalComponent,
    ProgrammesFilter
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild(),
    IonicModule,
    ProgrammesInfoRoutingModule,
    VideosModule,
    FooterModule,
    SkeletonLoaderModule
  ],
})
export class ProgrammesInfoModule {}
