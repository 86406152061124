import { RoutingConfig } from 'src/app/utils/routing-config';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, Input, OnInit } from '@angular/core';
import { dev } from '../../config/offlines_keys.json';
import { Storage } from '@capacitor/storage';
import { ProgramasAPI } from '../../apis/programas.service';
import { ModalController } from '@ionic/angular';
import { ProgramsModalComponent } from './programs-modal/programs-modal.component';
import { Router } from '@angular/router';
import routes from '../../config/routing.json';
import { SummaryComponent } from 'src/app/modules/dashboard/summary/summary.component';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, take } from 'rxjs/operators';
import Vote from 'src/app/models/vote.model';
import { lastValueFrom } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss'],
})
export class ProgramsComponent implements OnInit {
  progs = new Array();
  isDashboardView;
  @Input() nav;
  @Input() rootStack;
  country;
  is_loading: boolean = true;
  constructor(
    private modalController: ModalController,
    private router: Router,
    public translateService: TranslateService,
    private programasAPI: ProgramasAPI,
    private toastUtils: ToastUtils,
    private routingConfig: RoutingConfig,
    private store:Store
  ) {}

  async ngOnInit() {
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
      this.country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    this.dataLoad();
  }

  dataLoad() {
    this.programasAPI
      .getProgramasByCountryID(this.country.id)
      .pipe(take(1))
      .subscribe((value) => {
        this.progs = value;
        this.is_loading = false;
      });
  }

  async progModal(prog, updateClicked = false) {
    let vote = new Vote();

    if (Array.isArray(prog.vote) && prog.vote.length > 0)
      vote = prog.vote[prog?.vote.length - 1];

    if (!vote?.id || updateClicked) {
      let componentProps = {
        prog: prog,
        programasAPI: this.programasAPI,
        modalController: this.modalController,
        vote: vote,
      };
      let modal = await this.toastUtils.modalBuilder(
        ProgramsModalComponent,
        componentProps,
        ['my-custom-modal-css', 'modal-wrapper']
      );

      const { data } = await modal.onWillDismiss();
      if (data?.vote) {
        this.programasAPI.insertUpdatVotes(data.vote).subscribe(
          (value) => {
            this.dataLoad();
          },
          (e) => {
            console.log(e);
          }
        );
      }
    } else {
      this.programasAPI.insertUpdatVotes(vote).subscribe(
        (value) => {
          this.dataLoad();
        },
        (e) => {
          console.log(e);
        }
      );
    }
  }
  handelChecked(prog) {

    if (prog.checked) {
      if (!Array.isArray(prog.vote) || prog.vote.length == 0) {
        this.progModal(prog);
      } else {
        this.programasAPI
          .removeVote(prog?.vote[prog?.vote.length - 1].id)
          .subscribe((data) => {
            this.dataLoad();
          });
      }
    } else {
      if (!Array.isArray(prog.vote) || prog.vote.length == 0) {
        this.progModal(prog);
      } else
        this.programasAPI
          .addSubsciption(prog.vote[0].id)
          .pipe(
            switchMap((val) =>
              this.programasAPI.getProgramasByCountryID(this.country.id)
            )
          )
          .subscribe((value) => {
            this.progs = value;
            this.is_loading = false;
          });
    }
  }
  async return() {
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());
  }
  async next() {
    this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }
  submitForm() {
    this.nav.setRoot(this.rootStack, {
      nav: this.nav,
      rootStack: SummaryComponent,
    });
  }
  counter(i: number) {
    return new Array(i);
  }
}
