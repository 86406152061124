import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FarmScreensActivityStartComponent } from './farm-screens-activity-start/farm-screens-activity-start.component';
import appRoutes from '../../config/routing.json';

import { PreProductSelectorComponent } from './others-agricol-product-components/pre-product-selector/pre-product-selector.component';
import { LotsComponent } from './lots-components/lots/lots.component';
import { LotsOverviewComponent } from './lots-components/lots-overview/lots-overview.component';
import { AnimalesComponent } from './animals/animals/animals.component';
import { FarmOwnershipComponent } from './farm-details/farm-ownership/farm-ownership.component';
import { FarmAddressDetailsComponent } from './farm-details/farm-address-details/farm-address-details.component';
import { ClusterMainDetailsComponent } from './farm-details/cluster-main-details.component/cluster-main-details.component';

const routes: Routes = [
  {
    path: '',
    component: FarmScreensActivityStartComponent,
  },
  {
    path: appRoutes.farm.farm_address_details,
    component: FarmAddressDetailsComponent,
  },
  {
    path: appRoutes.farm.cluster_main_details,
    component: ClusterMainDetailsComponent,
  },
  {
    path: appRoutes.farm.farm_owner_ship,
    component: FarmOwnershipComponent,
  },
  {
    path: appRoutes.farm.farm_production,
    component: PreProductSelectorComponent,
  },
  {
    path: appRoutes.farm.farm_lots,
    component: LotsComponent,
  },
  {
    path: appRoutes.farm.farm_lots_overview,
    component: LotsOverviewComponent,
  },
  { path: appRoutes.farm.animals, component: AnimalesComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FarmRoutingModule {}
