import {
  addOwnerEmployee,
  deleteOwnerEmployee,
  updateOwnerData,
  updateOwnerDataSuccess,
  updatePreferredLanguage,
} from './../actions/owner.action';
import { cloneDeep } from 'lodash';
import {
  createFeatureSelector,
  createReducer,
  createSelector,
  MemoizedSelector,
  on,
} from '@ngrx/store';
import Owner from 'src/app/models/owner.model';
import {
  getOwnerByIdSucess,
  logOut,
  setCheckPointRouteSuccess,
} from '../actions/owner.action';
import { handleResponse } from '../../user_data_storage';

export const initialState: Owner = new Owner();
export interface OwnerState {
  owner: Owner;
}
export const ownerReducer = createReducer(
  initialState,
  on(getOwnerByIdSucess, (state, { owner }) => {
    handleResponse(owner);
    return owner;
  }),
  on(addOwnerEmployee, (state, { data }) => {
    return data;
  }),
  on(updateOwnerData, (state, { owner }) => {
    return owner;
  }),
  on(updateOwnerDataSuccess, (state, { owner }) => {
    return owner;
  }),
  on(addOwnerEmployee, (state, { data }) => {
    return data;
  }),
  on(updatePreferredLanguage, (state, { lang }) => {
    let owner = cloneDeep(state);
    owner.preferred_language = lang;
    return owner;
  }),
  on(deleteOwnerEmployee, (state: any) => {
    let data = cloneDeep(state);
    delete data.employee;
    return data;
  }),

  on(setCheckPointRouteSuccess, (state, { url }) => {
    //  handleResponse(owner);
    let owner = cloneDeep(state);
    owner.checkpoint_path = url;
    return owner;
  }),
  on(logOut, (state, action) => {
    return new Owner();
  })
);

export interface AppState {
  owner: OwnerState;
}
export const selectOwner = (state: AppState) => state.owner;
export const getOwnerState = (state: OwnerState): any => state.owner;
export const selectState: MemoizedSelector<object, OwnerState> =
  createFeatureSelector<OwnerState>('owner');
