import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ProductionAPI } from 'src/app/apis/FarmAPI/productions.service';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';
import { Good } from 'src/app/models/good.model';
import {
  getDisplayableUnit,
  getRealGoodTransactionValues,
} from 'src/app/utils/unit-convert';
import { BookComponent } from '../book/book.component';
@Component({
  selector: 'app-production-sales-other-header',
  templateUrl: './production-sales-other-header.component.html',
  styleUrls: ['./production-sales-other-header.component.scss'],
})
export class ProductionSalesOtherHeaderComponent implements OnInit {
  @Input() product: ProductionsPerfarm;
  @Input() lang;
  @Output() getCashflowRequestConfirmation: EventEmitter<any> =
    new EventEmitter();
  @Input() showDialogCashflow;
  @Input() getCategories;
  @Input() nav;
  @Output() backToAnimalEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() materialProductUnitChangeEventEmitter: EventEmitter<any> =
    new EventEmitter();
  @Output() updateAnimalEventEmitter: EventEmitter<Good> = new EventEmitter();
  @Output() selectProductEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() fetArbolesEventEmiter: EventEmitter<void> = new EventEmitter();
  @Input() trees_update: boolean = false;
  constructor(private productionApi: ProductionAPI) {}

  ngOnInit() {}

  backToProduction() {
    this.backToAnimalEventEmitter.emit();
  }

  materialProductUnitChange(good, materialProductUnit) {
    let new_qty = getRealGoodTransactionValues(
      materialProductUnit,
      good.unit,
      good.qty
    );
    good.unit = materialProductUnit;
    good.displayable_unit = getDisplayableUnit(materialProductUnit);
    good.qty = new_qty;
    this.materialProductUnitChangeEventEmitter.emit({
      good,
      materialProductUnit,
      new_qty,
    });
  }

  updateAnimal(good) {
    this.updateAnimalEventEmitter.emit(good);
  }

  selectPorduct(good, boolean, name) {
    this.selectProductEventEmitter.emit({ good, boolean, name });
  }

  filterTreesCashflow() {
    this.fetArbolesEventEmiter.emit();
  }

  async updateProduction() {
    if (Array.isArray(this.product.goods) && this.product.goods.length > 0)
      this.getCashflowRequestConfirmation.emit(this.product.goods[0]);
  }

  proceedWithUpdate() {
    this.productionApi.updateProduction(this.product).subscribe((val) => {
      this.trees_update = !this.trees_update;
    });
  }

  async showRequiredCashflowModal() {
    // if (this.product.is_cafe) {
    //   const cashFlowCategFiltred = await this.getCategories(
    //     CAFE_ACCOUNTING_ENTRY_CATEGORY
    //   );
    //   await this.showDialogCashflow(CoffeeInputComponent, cashFlowCategFiltred);
    // } else {
    //   await this.showDialogCashflow(OtrosInputComponent, null);
    // }
    // this.nav.setRoot(BookComponent, {
    //   nav: this.nav,
    // });
  }
}
