import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PickerOptionFormater } from '../../pipes-utils/date_formatter';
import { QuestionBase } from '../QuestionBase';

@Component({
  selector: 'app-dynamic-form-questions',
  templateUrl: './dynamic-form-questions.component.html',
  styleUrls: ['./dynamic-form-questions.component.scss'],
})
export class DynamicFormQuestionsComponent implements OnInit {
  @Input() question!: QuestionBase<string>;
  @Input() form!: FormGroup;
  customPickerOptions;
  last_renew;
  get isValid() {
    return this.form.controls[this.question.key].valid;
  }
  constructor(private customPickerOption: PickerOptionFormater) {}
  ngOnInit(): void {
    this.customPickerOptions = this.customPickerOption.custom_picker_options(
      this.last_renew
    );

  }
  percentage = 0;
  async handelPickerClicked() {
    let { data } = await this.customPickerOption.openPicker(this.question.value);
    this.percentage = data[0].value;
    this.question.value = data[0].value;
    this.form.get(this.question.key).setValue(data[0].value)

  }

  precisionInputChange(number, key) {
    this.form.get(key).setValue(number);
  }
}
