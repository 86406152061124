import {
  MORPH_Farm,
  MORPH_FamilyMember,
  MORPH_Owner,
} from './../../../../utils/consts';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { OperationsAPI } from 'src/app/apis/operations.service';
import Operation from 'src/app/models/operation.model';
import {
  ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY,
  CAFE_ACCOUNTING_ENTRY_CATEGORY,
  DEBT_REASON_CAFE,
  DEBT_REASON_FARM_ADMIN,
  DEBT_REASON_OTROS_PRODUCTOS,
  DEBT_REASON_PERSONAL,
  GARENTEE_OPERATION,
  OTROS_ACCOUNTING_ENTRY_CATEGORY,
  PERSONAL_ACCOUNTING_ENTRY_CATEGORY,
} from 'src/app/utils/consts';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { dev } from '../../../../config/offlines_keys.json';
import { CashflowAPI } from 'src/app/apis/cashflow.service';
import { OperationRelatedToCashflowBusinessRules } from 'src/app/utils/business-rules/operations_related_to_cashflow_business_rules';
import { lastValueFrom, take } from 'rxjs';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-operation-caution-view',
  templateUrl: './operation-caution-view.component.html',
  styleUrls: ['./operation-caution-view.component.scss'],
})
export class OperationCautionViewComponent implements OnInit {
  currentYear;
  dateString;
  loadinModal;
  @Input() cashflow;
  @Input() operationType;
  @Output() cashflowChanged: EventEmitter<any> = new EventEmitter();
  @Input() modalController: ModalController;
  products: string[] = [];

  operation = new Operation();
  selectedFamilyMember;
  owner;

  constructor(
    private operationAPI: OperationsAPI,
    private loadingCtrl: LoadingController,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private cashflowAPI: CashflowAPI,
    private operationRule: OperationRelatedToCashflowBusinessRules,
    private store:Store
  ) {}

  async ngOnInit() {
    this.loadinModal = await this.loadingCtrl.create({ spinner: 'bubbles',
      mode: 'ios',
    });

    const owner = await Storage.get({ key: dev.USER_KEY }).then((value) =>
      JSON.parse(value.value)
    );
    const lang = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );
    if (this.cashflow.operation_id && this.cashflow?.operation?.id) {
      this.operation = this.cashflow.operation;
    }
    if (
      this.cashflow.accounting_entries_categories ===
      PERSONAL_ACCOUNTING_ENTRY_CATEGORY
    ) {
      if (this.cashflow.morphable_type === 'Owner')
        this.operation.morphable_type = MORPH_Owner;
      else this.operation.morphable_type = MORPH_FamilyMember;
      this.operation.morphable_id = this.cashflow.morphable_id;
    } else {
      const farm = await lastValueFrom(this.store.select(getFarm).pipe(take(1)))

      this.operation.morphable_id = farm.id;
      this.operation.morphable_type = MORPH_Farm;
    }
    this.operation.owner_id = owner.id;
    this.operation.operation_type = GARENTEE_OPERATION;
    this.operation.currency = this.cashflow.currency;
    this.setUpOperationReason();
    if (!this.operation.id && this.cashflow)
      this.operation.creation_date = this.cashflow.date;
  }

  changed() {
    if (this.cashflow.id) {
      this.cashflow.amount = this.operation.amount;
      this.cashflowChanged.emit(this.cashflow);
    }

    if (!this.modalController && !this.operation.id) {
      this.cashflow.new_operation = this.operation;
      this.cashflowChanged.emit(this.cashflow);
    } else if (!this.modalController && this.operation.id) {
      this.cashflow.operation = this.operation;
      this.cashflowChanged.emit(this.cashflow);
    }
  }
  handelChangeCurrecy(value) {
    this.operation.currency = value;
    this.cashflow.currency = value;
  }
  setAmount(amount) {
    this.operation.amount = amount;
    this.changed();
  }

  setUpOperationReason() {
    if (
      this.cashflow.accounting_entries_categories ===
      PERSONAL_ACCOUNTING_ENTRY_CATEGORY
    ) {
      this.operation.operation_reason = DEBT_REASON_PERSONAL;
    } else if (
      this.cashflow.accounting_entries_categories ===
      ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY
    ) {
      this.operation.operation_reason = DEBT_REASON_FARM_ADMIN;
    } else if (
      this.cashflow.accounting_entries_categories ===
      CAFE_ACCOUNTING_ENTRY_CATEGORY
    ) {
      this.operation.operation_reason = DEBT_REASON_CAFE;
    } else if (
      this.cashflow.accounting_entries_categories ===
      OTROS_ACCOUNTING_ENTRY_CATEGORY
    ) {
      this.operation.operation_reason = DEBT_REASON_OTROS_PRODUCTOS;
    }
  }

  dismiss() {
    this.modalController.dismiss();
  }

  async confirm_method() {
    if (
      this.operation.amount &&
      this.operation.third_part_name &&
      this.operation.operation_reason
    ) {
      this.loadinModal.present();
      //TODO offlines
      this.operationAPI.storeOperation(this.operation).subscribe(
        (value: Operation) => {
          this.operationRule.saveCashFlow(
            value,
            this.cashflow,
            this.cashflowAPI,
            this.loadinModal,
            this.modalController
          );
        },
        (e) => {
          this.modalController.dismiss();
        }
      );
    } else
      await this.toastUtils
        .toastBuilder(
          await this.translateService.instant('errors.all_fields'),
          '',
          ''
        );
  }
  paymentChange(event) {
    this.cashflow.payment_methods = event.payment_methods;
    this.cashflow.money_reporting_id = event.money_reporting_id;
    this.cashflowChanged.emit(this.cashflow);
  }
}
