import { ListableProduction } from './farm/production_per_farm';

export class Lot {
  distance_between_plants: Number;
  id: number;
  lot_number: string;
  type: String;
  superficie: any;
  lands: [];
  productions: Array<ListableProduction>;
  perimeters;
  comment;
  created_at: string;
  updated_at: string;
  is_server_data: Boolean;
  farm_id: Number;
  nature: string;
  nature_comment: String;
  file;
  planting_type='';
  hasSelectedPhoto : boolean = false;
  blobFile;

  constructor() {}

  checkData(lot): String {
    return !lot.lot_number ||
      ((!lot.productions ||
        (Array.isArray(lot.productions) && lot.productions.length == 0)) &&
        lot.type != USAGE_TYPE.other) ||
      !lot.lands ||
      !lot.superficie ||
      !lot.nature
      ? 'label.required_input_no_html'
      : 'ok';
  }
}
export const USAGE_TYPE = {
  agricol: 'agricol',
  other: 'other_use',
};
