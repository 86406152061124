import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CashflowAPI } from 'src/app/apis/cashflow.service';
import { AnimationUtils } from 'src/app/utils/animations';
import {
  ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY,
  ANIMALES_ACCOUNTING_ENTRY_CATEGORY,
  CAFE_ACCOUNTING_ENTRY_CATEGORY,
  OTROS_ACCOUNTING_ENTRY_CATEGORY,
  PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY,
} from 'src/app/utils/consts';
import {
  FilterForm,
  StatRequestParams,
} from '../../analysis-workforce/analysis-workforce.component';
import { AccountingEntryCategorie } from 'src/app/models/accounting/accounting_entries_categories';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-workday-analyse-fitler',
  templateUrl: './workday-analyse-fitler.component.html',
  styleUrls: ['./workday-analyse-fitler.component.scss'],
})
export class WorkdayAnalyseFitlerComponent implements OnInit {
  accountingsCategories: Array<AccountingEntryCategorie> = [];
  OTROS_ACCOUNTING_ENTRY_CATEGORY = OTROS_ACCOUNTING_ENTRY_CATEGORY;
  ANIMALES_ACCOUNTING_ENTRY_CATEGORY = ANIMALES_ACCOUNTING_ENTRY_CATEGORY;
  ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY =
    ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY;

  params: StatRequestParams = {
    start_date: new Date().toISOString(),
    end_date: new Date().toISOString(),
    accountingEntries: [1, 2, 3, 4, 10],
    cashflowCategories: [149, 150, 285, 286, 287],
    cashflowExpenses: [64, 65, 66],
    cashflowActivities: [],
    per_activity: false,
    per_product_checked: false,
    per_animal_checked: false,
    per_cashflow_categories: false,
    per_monthly_salary: false,
    sort_by: null,
    monthly_yearly: null,
  };
  formFilter: FilterForm = {
    coffee_checked: true,
    animals_checked: true,
    other_agricol_products_checked: true,
    administration_checked: true,
    temporal_labor_expenses_checked: true,
    permanent_labor_expenses_checked: true,
    familiar_labor_expenses_checked: true,
    temporal_labor_administrative_checked: true,
    permanent_labor_administrative_checked: true,
    familiar_labor_administrative_checked: true,
    permanent_labor_monthly_checked: true,
    familiar_labor_monthly_checked: true,
    per_activity_checked: false,
    per_product_checked: false,
    per_animal_checked: false,
    per_mano_obra_administrativa: true,
    per_monthly_salary: true,
    familial_filter: true,
    permanent_filter: true,
    temporal_filter: true,
    no_paid_stats: true,
    paid_for_harvested_quantity: true,
    paid_in_spicie_stats: true,
    paid_per_day_stats: true,
    per_salario_mensuales: true,
  };
  activities: any;
  constructor(
    private modalController: ModalController,
    private cashflowAPI: CashflowAPI,
    public translateService: TranslateService,
    public animationUtils: AnimationUtils,
    private store: Store<{ accountingEntries: Array<AccountingEntryCategorie> }>
  ) {}

  async ngOnInit() {
    this.store.select('accountingEntries').subscribe((value) => {
      if (Array.isArray(value)) {
        this.accountingsCategories = cloneDeep(value).filter(
          (element) =>
            element.is_farm_expenses &&
            element.id != PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY
        );
      }
    });
    this.cashflowAPI
      .getActivities(CAFE_ACCOUNTING_ENTRY_CATEGORY)
      .subscribe((value: any) => {
        this.activities = value.map((activity) => {
          activity.is_checked = false;
          activity.sub_activities = activity.sub_activities.map((element) => {
            element.is_checked = false;
            if (element?.sub_activities) {
              element.sub_activities = element.sub_activities.map((val) => {
                element.is_checked = false;
                return val;
              });
            }
            return element;
          });
          return activity;
        });
      });
  }

  inCheckParent(input, itemChecked) {
    input.is_checked = !itemChecked;
    if (input.activity_id && !input.is_checked) {
      this.activities = this.activities.map((act) => {
        if (act?.id == input?.activity_id) {
          act.is_checked = false;
        }
        return act;
      });
    }
  }

  Check(item: number) {
    // this.formFilter[item] = !this.formFilter[item];
    this.params.accountingEntries.includes(item)
      ? this.params.accountingEntries.splice(
          this.params.accountingEntries.indexOf(item),
          1
        )
      : this.params.accountingEntries.push(item);
  }

  HandelDateChange(value) {
    this.params.start_date = value.start_date;
    this.params.end_date = value.end_date;
  }

  submitFilter() {
    var choosedActivities = new Array<number>();
    if (this.activities?.length > 0) {
      this.activities.forEach((element) => {
        if (element.is_checked) choosedActivities.push(element.id);
        element.sub_activities.forEach((subActivity) => {
          if (subActivity.is_checked) choosedActivities.push(subActivity.id);
        });
      });
    }
    this.params.cashflowCategories = new Array<number>();
    // this.params.cashflowCategories = [149, 150, 285, 286, 287];
    this.params.cashflowExpenses = [64, 65, 66];
    if (this.formFilter.per_mano_obra_administrativa)
      this.params.cashflowCategories.push(285, 286, 287);
    if (this.formFilter.per_monthly_salary)
      this.params.cashflowCategories.push(149, 150);

    if (
      this.params.accountingEntries.includes(
        ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY
      )
    )
      this.params.cashflowCategories = [149, 150, 285, 286, 287];

    this.params.cashflowActivities = choosedActivities;

    if (this.formFilter.per_activity_checked) this.params.per_activity = true;
    else this.params.per_activity = false;

    this.modalController.dismiss({ filterParams: this.params });
  }
  dismiss() {
    this.modalController.dismiss({ filter: null });
  }

  perActivityChange() {
    if (this.formFilter.per_activity_checked) {
      this.formFilter.animals_checked = false;
      this.formFilter.other_agricol_products_checked = false;
      this.formFilter.administration_checked = false;
      this.params.per_activity = true;

      this.params.per_product_checked = false;
      this.params.per_animal_checked = false;
    } else {
      this.params.per_activity = false;
    }
  }
  checkAllSubsChange(activity) {
    activity.sub_activities = activity.sub_activities.map((element) => {
      element.is_checked = !activity.is_checked;
      if (element.sub_activities) {
        element.sub_activities = element.sub_activities.map((val) => {
          val.is_checked = element.is_checked;

          return val;
        });
      }
      if (activity.activity_id && activity.is_checked) {
        this.activities = this.activities.map((act) => {
          if (act?.id == activity?.activity_id) {
            act.is_checked = false;
          }
          return act;
        });
      }

      return element;
    });
  }
  expand(activity) {
    activity.is_expanded = !activity.is_expanded;
  }
  perAnimalChange() {
    if (this.formFilter.per_animal_checked) {
      this.formFilter.coffee_checked = false;
      this.formFilter.other_agricol_products_checked = false;
      this.formFilter.administration_checked = false;
      this.params.per_animal_checked = true;
      this.params.per_activity = false;
      this.params.per_product_checked = false;
    } else {
      this.params.per_animal_checked = false;
    }
  }

  perProductChange() {
    if (this.formFilter.per_product_checked) {
      this.formFilter.coffee_checked = false;
      this.formFilter.animals_checked = false;
      this.formFilter.administration_checked = false;
      this.params.per_product_checked = true;

      this.params.per_activity = false;
      this.params.per_animal_checked = false;
    } else {
      this.params.per_product_checked = false;
    }
  }
  perManoDeObraAdministrativaChange() {
    if (
      this.formFilter.per_monthly_salary ||
      this.formFilter.per_mano_obra_administrativa
    ) {
      this.formFilter.coffee_checked = false;
      this.formFilter.animals_checked = false;
      this.formFilter.other_agricol_products_checked = false;
      this.params.per_product_checked = false;
      this.params.per_activity = false;
      this.params.per_animal_checked = false;
      this.params.per_cashflow_categories = false;
      this.params.per_monthly_salary = true;
    }
    if (
      !this.formFilter.per_monthly_salary &&
      !this.formFilter.per_mano_obra_administrativa
    )
      this.params.per_cashflow_categories = false;
  }
  reset() {
    this.params = {
      start_date: new Date().toISOString(),
      end_date: new Date().toISOString(),
      accountingEntries: [],
      cashflowCategories: [149, 150, 285, 286, 287],
      cashflowExpenses: [64, 65, 66],
      cashflowActivities: [],
      per_activity: false,
      per_product_checked: false,
      per_animal_checked: false,
      per_cashflow_categories: false,
      per_monthly_salary: false,
      sort_by: null,
      monthly_yearly: null
    };
    this.formFilter = {
      coffee_checked: true,
      animals_checked: true,
      other_agricol_products_checked: true,
      administration_checked: true,
      temporal_labor_expenses_checked: true,
      permanent_labor_expenses_checked: true,
      familiar_labor_expenses_checked: true,
      temporal_labor_administrative_checked: true,
      permanent_labor_administrative_checked: true,
      familiar_labor_administrative_checked: true,
      permanent_labor_monthly_checked: true,
      familiar_labor_monthly_checked: true,
      per_activity_checked: false,
      per_product_checked: false,
      per_animal_checked: false,
      per_mano_obra_administrativa: false,
      per_monthly_salary: false,
      familial_filter: true,
      permanent_filter: true,
      temporal_filter: true,
      no_paid_stats: true,
      paid_for_harvested_quantity: true,
      paid_in_spicie_stats: true,
      paid_per_day_stats: true,
      per_salario_mensuales: false,
    };
  }
}
