import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AccountingEntryCategorie } from 'src/app/models/accounting/accounting_entries_categories';
import { CAFE_ACCOUNTING_ENTRY_CATEGORY } from 'src/app/utils/consts';

export interface FilterParams {
  start_date: string;
  end_date: string;
  show_productive: boolean;
  show_unproductive: boolean;
  show_cafe_cerez_details: boolean;
  show_cafe_mojado_details: boolean;
  show_cafe_seco_details: boolean;
}
@Component({
  selector: 'app-kpis-filters',
  templateUrl: './kpis-filters.component.html',
  styleUrls: ['./kpis-filters.component.scss'],
})
export class KpisFiltersComponent implements OnInit {
  @Input() filterParams: FilterParams = {
    start_date: null,
    end_date: null,
    show_productive: true,
    show_unproductive: true,
    show_cafe_cerez_details: true,
    show_cafe_mojado_details: true,
    show_cafe_seco_details: true,
  };
  @Input() accountingSelected : AccountingEntryCategorie
  constructor(private modalController: ModalController) {}
  initFilterParams;
  ngOnInit() {
    this.initFilterParams = {
    
      start_date: new Date(new Date().getFullYear(), 0, 1).toISOString(),
      end_date: new Date().toISOString(),
      show_productive: true,
      show_unproductive: true,
      show_cafe_cerez_details: true,
      show_cafe_mojado_details: this.accountingSelected?.id==CAFE_ACCOUNTING_ENTRY_CATEGORY,
      show_cafe_seco_details: this.accountingSelected?.id==CAFE_ACCOUNTING_ENTRY_CATEGORY,
    };
    this.filterParams = this.initFilterParams;
  }
  Check(item) {
    this.filterParams[item] = !this.filterParams[item];
    // item = !item
  }
  save() {
    if (!this.filterParams.start_date) {
      this.filterParams.start_date = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ).toISOString();
    }
    if (!this.filterParams.end_date) {
      this.filterParams.end_date = new Date().toISOString();
    }
    this.modalController.dismiss({ filterParams: this.filterParams });
  }
  dismiss(){
    this.modalController.dismiss();

  }
  HandelDateChange(value){
    this.filterParams.start_date = value.start_date
    this.filterParams.end_date = value.end_date
  }
}
