import { RoutingConfig } from 'src/app/utils/routing-config';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { UserInfo } from 'src/app/apis/users-info.service';
import { ModaSelectComponent } from 'src/app/moda-select/moda-select.component';

import routes from '../../config/routing.json';

@Component({
  selector: 'app-footer-component',
  templateUrl: './footer-component.component.html',
  styleUrls: ['./footer-component.component.scss'],
})
export class FooterComponentComponent implements OnInit {
  @Input() label_1: string;
  @Input() label_2;
  @Input() show_next:boolean;
  @Input() button_1_fill = 'outline';
  @Output() back_pressedChange: EventEmitter<any> = new EventEmitter();
  @Output() next_pressedChange: EventEmitter<any> = new EventEmitter();
  @Input() is_next_needed;
  @Input() is_back_needed=false;
  @Input() is_confirm_needed=false;
  @Input() isModal = false;
  @Input() noProgress = false;
  @Input() isDisabled = false;
  select_langage ;
  
  list = [
    { value: 'Español', key: 'es' },
    { value: 'English', key: 'en' },
  ];
  hideAll = false;
  configObject;
  landingProgressValue ;
  isHomeView
  constructor(
    private router: Router,
    private modalController: PopoverController,
    private userInfo: UserInfo,
    private routingConfig: RoutingConfig
  ) {}

  async ngOnInit() {
    this.label_1 = this.label_1;
    this.label_2 = this.label_2;
    this.isHomeView = this.router.url.includes(routes.dashboard.home);
    this.router.url === '/dashboard'
      ? (this.hideAll = true)
      : (this.hideAll = false);
      this.landingProgressValue = await this.routingConfig.getLandingProgress()
      this.router.events.subscribe((value) => {
        this.isHomeView = this.router.url.includes(routes.dashboard.home);
      });
    }

  async back_pressed() {
    this.back_pressedChange.emit(await this.routingConfig?.getBackUrl());

  }
  async next_pressed() {
    this.next_pressedChange.emit(await this.routingConfig.getNextUrl());

  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: ModaSelectComponent,
      showBackdrop: true,
      cssClass: ['popover_class_modal', 'modal-wrapper'],
      translucent: true,
      componentProps: {
        list: this.list,
      },
    });
    modal.onDidDismiss().then((data) => {
      const item = data['data'];
      if (item === 'Español') {
        this.select_langage = 'es';
      } else if (item === 'English') {
        this.select_langage = 'en';
      }
      this.handelLangage();
    });
  }
  async handelLangage() {
    await this.userInfo.setSelectedLangage(this.select_langage);
  }
}
