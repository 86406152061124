import { getOwnerById } from 'src/app/utils/states-management/actions/owner.action';
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Storage } from '@capacitor/storage';
import { dev } from '../../config/offlines_keys.json';
import { SummaryComponent } from 'src/app/modules/dashboard/summary/summary.component';
import { TranslateService } from '@ngx-translate/core';
import { GoodsAPI } from 'src/app/apis/goods.service';
import { ProgrammesListComponent } from '../programmes-Info/programmes-list/programmes-list.component';
import { Store } from '@ngrx/store';
import Owner from 'src/app/models/owner.model';
import { CalculetteComponentComponent } from '../dashboard/calculette-component/calculette-component.component';
import { lastValueFrom, take } from 'rxjs';

@Component({
  selector: 'app-prior-dashboard',
  templateUrl: './prior-dashboard.component.html',
  styleUrls: ['./prior-dashboard.component.scss'],
})
export class PriorDashboardComponent implements OnInit, AfterViewInit {
  @Input() nav;
  @Input() rootStack;
  owner_first_name;
  owner;
  welcome;
  selectedLangage;
  profile_info;
  src =
    'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y';
  countryName: string;

  constructor(
    public translateService: TranslateService,
    private store: Store<{ owner: Owner }>
  ) {}

  async ngOnInit() {
    this.store.select('owner').subscribe((value) => {
      if (value.first_name) {
        this.owner = value;
        this.initView();
        this.profile_info = JSON.parse(this.owner.profile_info);
        if (this.owner.is_owner) {
          this.owner_first_name = this.owner?.first_name;
        } else if (this.owner?.user?.familyEntity?.name) {
          this.owner_first_name = this.owner?.user?.familyEntity?.name;
        }
        if (this.owner.countrie) {
          this.countryName = this.owner.countrie.name;
        }
      }
    });
  }

  async ngAfterViewInit() {}

  async initView() {
    this.selectedLangage = (await Storage.get({ key: dev.LANGAGE_KEY })).value;
    // this.owner = JSON.parse((await Storage.get({ key: dev.USER_KEY })).value);
    if (this.owner?.sex === 'male' && this.selectedLangage === 'es') {
      this.welcome = await this.translateService
        .instant('welcome')
    } else if (this.owner?.sex === 'female' && this.selectedLangage === 'es') {
      this.welcome = await this.translateService
        .instant('welcome')
    } else if (this.owner?.sex === 'other' && this.selectedLangage === 'es') {
      this.welcome = await this.translateService
        .instant('welcome')
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.owner = await lastValueFrom(this.store.select('owner').pipe(take(1)))
    if (this.owner.is_owner) {
      this.owner_first_name = this.owner?.first_name;
    } else if (this.owner?.user?.name) {
      this.owner_first_name = this.owner?.user?.name;
    }
  }
  cashFlowSummary() {
    this.nav.setRoot(this.rootStack, {
      nav: this.nav,
      rootStack: SummaryComponent,
    });
  }
  routeToProgrammesList() {
    this.nav.setRoot(ProgrammesListComponent, {
      nav: this.nav,
      rootStack: ProgrammesListComponent,
    });
  }
  onClick() {
    this.nav.setRoot(CalculetteComponentComponent, {
      nav: this.nav,
      rootStack: CalculetteComponentComponent,
    });
  }
}
