import { DateFormatPipe } from './../../../../utils/pipes-utils/date_formatter';
import { ToastUtils } from './../../../../utils/toast_builder';
import { Component, Input, OnInit } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { StatisticsAPI } from 'src/app/apis/statistics.service';
import { CAFE_ACCOUNTING_ENTRY_CATEGORY } from 'src/app/utils/consts';
import { AnalysisInsumosBarchartComponent } from '../analysis-insumos-barchart/analysis-insumos-barchart.component';
import {
  FilterInsumos,
  InputsFilterComponent,
} from '../filters/inputs-filter/inputs-filter.component';
import { AnalysisInputsUsageComponent } from '../analysis-inputs-usage/analysis-inputs-usage.component';
import { Store } from '@ngrx/store';
import { loadInsumosBought } from 'src/app/utils/states-management/actions/dashboard.action';
import {
  getInsumosBought,
  getTotalsAccountingCategoriesResult,
} from 'src/app/utils/states-management/selector/dashboard.selector';
import { AccountingEntryCategory } from 'src/app/models/cash-flow/cashflow_categories';
import { map, of, switchMap, take } from 'rxjs';
import { CashflowAPI } from 'src/app/apis/cashflow.service';
import { mapLangeTitleObjects } from 'src/app/utils/array-filtrer';

@Component({
  selector: 'app-analysis-inputs',
  templateUrl: './analysis-inputs.component.html',
  styleUrls: ['./analysis-inputs.component.scss'],
})
export class AnalysisInputsComponent implements OnInit {
  @Input() nav: IonNav;
  result;
  selected_date;
  isFiltred = false;
  accountingID: number;
  prodId;
  params: FilterInsumos = {
    start_date: new Date(new Date().getFullYear(), 0, 1).toISOString(),
    end_date: new Date().toISOString(),
    cashflowExpenses: [],
    sort_by: null,
    monthly_yearly: null,
  };
  constructor(
    private statistAPI: StatisticsAPI,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private datePipe: DateFormatPipe,
    private popover: ModalController,
    private store: Store<{ accountingEntries: Array<AccountingEntryCategory> }>,
    private cashflowAPI: CashflowAPI
  ) {}
  inputs: any = [];

  ngOnInit() {
    this.fetch();
    this.initDate(this.params.start_date, this.params.end_date);
    this.cashflowAPI
      .getCashflowExpensesParent([40, 27, 23])
      .subscribe((values: any) => {
        this.inputs = values?.map((element) => {
          this.filterAccordion[element.id] = true;

          element.title = this.translateService.instant(
            'cash_flow_expenses.' + element.id
          );
          element.cash_flow_expense_sub_categories =
            element.cash_flow_expense_sub_categories.map((value) => {
              value.title = this.translateService.instant(
                'cash_flow_expenses.' + value.id
              );
              return value;
            });
          element.cash_flow_expense_sub_categories = mapLangeTitleObjects(
            element.cash_flow_expense_sub_categories,
            'title',
            this.translateService
          );

          return element;
        });
        this.inputs = mapLangeTitleObjects(
          this.inputs,
          'title',
          this.translateService
        );
        this.inputs.forEach(async (element) => {
          element.cash_flow_expense_sub_categories = mapLangeTitleObjects(
            element.cash_flow_expense_sub_categories,
            'title',
            this.translateService
          );
          element.check_all_subs = true;
          this.checkAllSubsChange(element);
        });
      });
    this.translateService.onLangChange.subscribe((val) => {
      this.initDate(this.params.start_date, this.params.end_date);
      let data = {
        start_date: this.params.start_date,
        end_date: this.params.end_date,
        accounting_entry: CAFE_ACCOUNTING_ENTRY_CATEGORY,
        lang: this.translateService.currentLang,
        cashflowExpenses: this.params.cashflowExpenses,
      };
      this.fetch();
    });
  }

  Check(category) {
    category.is_checked = !category.is_checked;
    let choosedActivities = Array<number>();

    this.inputs.forEach((element) => {
      if (element.is_checked) choosedActivities.push(element.id);
      element.cash_flow_expense_sub_categories.forEach((subExpense) => {
        if (subExpense.is_checked) choosedActivities.push(subExpense.id);
      });
    });
    this.params.cashflowExpenses = choosedActivities;
    this.fetch();
  }
  checkAllSubsChange(expenses) {
    if (expenses.check_all_subs) {
      expenses.cash_flow_expense_sub_categories.map((element) => {
        element.is_checked = expenses.check_all_subs;
        return element;
      });
    } else {
      var NotChecked = expenses.cash_flow_expense_sub_categories.find(
        (element) => !element.is_checked
      );
      if (!NotChecked) {
        expenses.cash_flow_expense_sub_categories.map((element) => {
          element.is_checked = expenses.check_all_subs;
          return element;
        });
      }
    }
  }
  initDate(startDate, endDate) {
    this.selected_date =
      this.datePipe.transform(startDate, this.translateService.currentLang) +
      ' - ' +
      this.datePipe.transform(endDate, this.translateService.currentLang);
  }
  getLengthObject(value) {
    var length = Object.keys(value).length;
    return length;
  }
  handelFilterClicked(ev) {
    for (var propName in this.filterAccordion) {
      if (this.filterAccordion.hasOwnProperty(propName)) {
        this.filterAccordion[propName] = ev;
      }
    }
  }
  fetch() {
    //TODO : offline

    // let data = {
    //   start_date: this.params.start_date,
    //   end_date: this.params.end_date,
    //   accounting_entry: CAFE_ACCOUNTING_ENTRY_CATEGORY,
    //   lang: this.translateService.currentLang,
    //   cashflowExpenses: this.params.cashflowExpenses,
    // };
    // console.log(data)
    // this.store.dispatch(loadInsumosBought({ data :data }))
    // this.store.select(getInsumosBought).subscribe((value: any) => {
    //   this.result = value;
    //   console.log(this.result)
    // });
    this.store
      .select(getTotalsAccountingCategoriesResult)
      .pipe(
        map(({data}) => {
          if (Array.isArray(data)) {
            return data.find((element) => element.morphable);
          } else return undefined;
        }),
        switchMap((value: AccountingEntryCategory | undefined) => {
          if (value) {
            this.accountingID = value.id;
            this.prodId = value.morphable_id;
            let data = {
              start_date: this.params.start_date,
              end_date: this.params.end_date,
              accounting_entry: value.id,
              cashflowExpenses: this.params.cashflowExpenses,
            };

            return this.statistAPI.insumosBought(data);
          } else return of([]);
        })
      )
      .subscribe((value: any) => {
        this.result = value;
      });
  }

  graph(id) {
    this.nav.setRoot(AnalysisInsumosBarchartComponent, {
      nav: this.nav,
      expenses_id: id,
      accounting_id: this.accountingID,
    });
  }
  filterAccordion = {
    general: true,
    selection: true,
  };
  // async showFilterModal() {
  //   let modal = await this.toastUtils.modalBuilder(InputsFilterComponent, {}, [
  //     'my-custom-modal-css',
  //     'modal-wrapper',
  //   ]);
  //   modal.present();
  //   const { data } = await modal.onDidDismiss();
  //   if (data?.filters) {
  //     console.log(data?.filters)
  //     this.params = data.filters;
  //     this.initDate(this.params.start_date, this.params.end_date);

  //     this.fetch();
  //     this.isFiltred = true;
  //   } else {
  //     this.isFiltred = false;
  //     this.params.start_date = new Date(
  //       new Date().getFullYear(),
  //       0,
  //       1
  //     ).toISOString();
  //     this.params.end_date = new Date().toISOString();
  //     this.params.cashflowExpenses = [];
  //     this.initDate(this.params.start_date, this.params.end_date);
  //     this.fetch();
  //   }
  // }
  onFilterClick(filter) {
    this.filterAccordion[filter] = !this.filterAccordion[filter];
  }

  seeDetails(unitType, insumo, item) {
    this.statistAPI
      .getInputsDetails(
        this.params.start_date,
        this.params.end_date,
        insumo.value['id'],
        unitType,
        this.accountingID
      )
      .pipe(take(1))
      .subscribe(async (value) => {
        let modal = await this.toastUtils.modalBuilder(
          AnalysisInputsUsageComponent,
          { datas: value, unitType: unitType, inputName: item },
          ['my-custom-modal-css', 'modal-wrapper']
        );
        // modal.present();
      });
  }
  HandelDateChange(value) {
    this.params.start_date = value.start_date;
    this.params.end_date = value.end_date;
    this.fetch();
  }
}
