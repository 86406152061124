import { dev } from 'src/app/config/offlines_keys.json';
import { TermesConditionsComponent } from './modules/termes-conditions/termes-conditions.component';
import { PageCountriesComponent } from './modules/page-countries/page-countries.component';
import { LoginComponent } from './modules/login/login.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import { PersonalDataComponent } from './components/personal-data/personal-data.component';
import { ProfilePhotoComponent } from './components/personal-data/profile-photo/profile-photo.component';
import { CreateAccountComponent } from './modules/create-account/create-account.component';
import { SmsValidationComponent } from './modules/sms-validation/sms-validation.component';
import { BankAccountWidgetComponent } from './modules/dashboard/widgets/bank-account-widget/bank-account-widget.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import appRoutes from './config/routing.json';
import { ProgramsComponent } from './modules/programs/programs.component';
import { PreProgComponent } from './modules/programs/pre-prog/pre-prog.component';
import { ProfileInfoComponent } from './components/personal-data/profile-info/profile-info.component';
import { PeronsDataScreensComponent } from './modules/perons-data-screens/perons-data-screens.component';
import { SplashComponent } from './modules/splash/splash.component';
import { ModaSelectComponent } from './moda-select/moda-select.component';
import { IntroductoryvideoComponent } from './modules/introductoryvideo/introductoryvideo.component';

import { RoutingConfigGuard } from './routing-config.guard';
import { landingDoneComponent } from './widgets/landing_done/landing_done.component';

const routes: Routes = [
  {
    path: 'splash',
    component: SplashComponent,
  },
  {
    path: appRoutes.auth.country,
    component: PageCountriesComponent,
  },
  {
    path: '',
    component: ModaSelectComponent,
  },
  {
    path: appRoutes.auth.login,
    component: LoginComponent,
  },
  {
    path: 'sms-validation/:code/:mobile_number/:network',
    component: SmsValidationComponent,
  },
  {
    path: 'landing_done',
    component: landingDoneComponent,
  },
  {
    path: 'terms',
    component: TermesConditionsComponent,
  },
  {
    path: 'person',
    component: PeronsDataScreensComponent,
    canActivate: [RoutingConfigGuard],
    children: [
      {
        path: '',
        component: CreateAccountComponent,
      },
      {
        path: 'profile-information',
        component: PersonalDataComponent,
      },
      {
        path: 'profile-permission',
        component: ProfileInfoComponent,
      },
      {
        path: 'profile-photo',
        component: ProfilePhotoComponent,
      },
    ],
  },

  {
    path: appRoutes.family.main_routes,
    canActivate: [RoutingConfigGuard],

    loadChildren: () =>
      import('./modules/family/family.module').then((m) => m.FamilyModule),
  },
  {
    path: appRoutes.farm.main_routes,
    canActivate: [RoutingConfigGuard],

    loadChildren: () =>
      import('./modules/farm/farm.module').then((m) => m.FarmModule),
  },
  {
    path: appRoutes.team.main_routes,
    canActivate: [RoutingConfigGuard],

    loadChildren: () =>
      import('./modules/team/team.module').then((m) => m.TeamModule),
  },
  {
    path: 'partner',
    canActivate: [RoutingConfigGuard],

    loadChildren: () =>
      import('./modules/partner/partner.module').then((m) => m.PartnerModule),
  },
  {
    path: appRoutes.goods.main_routes,
    canActivate: [RoutingConfigGuard],

    loadChildren: () =>
      import('./modules/goods/goods.module').then((m) => m.GoodsModule),
  },
  {
    path: appRoutes.debts.main_routes,
    canActivate: [RoutingConfigGuard],

    loadChildren: () =>
      import('./modules/debts/debts.module').then((m) => m.DebtsModule),
  },
  {
    path: appRoutes.dashboard.main_routes,
    canActivate: [RoutingConfigGuard],

    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: appRoutes.dashboard.home,
    // canActivate: [RoutingConfigGuard],

    loadChildren: () =>
      import('./modules/home/home.module').then(
        (m) => m.HomePageModule
      ),
  },
  /* {
    path: approutes.dashboard.main_routes,
    component: PriorDashboardComponent,
  },*/
  {
    path: 'user_total_amount',
    canActivate: [RoutingConfigGuard],

    component: BankAccountWidgetComponent,
  },
  {
    path: 'dashboard',
    canActivate: [RoutingConfigGuard],
    component: DashboardComponent,
  },

  {
    path: 'terms-modal',
    loadChildren: () =>
      import('./components/terms-modal/terms-modal.module').then(
        (m) => m.TermsModalPageModule
      ),
  },
  { path: 'pre-prog', component: PreProgComponent,
  canActivate: [RoutingConfigGuard],
},
  { path: 'prog', component: ProgramsComponent,
  canActivate: [RoutingConfigGuard],
},
  { path: 'introvideo', component: IntroductoryvideoComponent },
  {
    path: 'programmes-list',
    canActivate: [RoutingConfigGuard],

    loadChildren: () =>
      import('./modules/programmes-Info/programmes-Info.module').then(
        (m) => m.ProgrammesInfoModule
      ),
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then( m => m.HomePageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule  {
}
