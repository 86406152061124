export class Family_member {
  id;
  name;
  birth_date;
  sex;
  relation_type;
  is_receiving_salary;
  owner_id;
  mobile_number;
  prefix;
  file;
  blobFile;
  user_id;
  user;
  age;
  hasSelectedPhoto : boolean = false;
  should_add_employee : boolean = false;
  employee: any;
  constructor() {
    // this.file = img_file;
  }

  initFromValue(value: any) {
    this.id = value?.id || 0;
    this.name = value?.name || '';
    this.birth_date = value?.birth_date;
    this.sex = value.sex;
    this.relation_type = value.relation_type;
    this.is_receiving_salary = value.is_receiving_salary;
    this.owner_id = value.owner_id;
    this.mobile_number = value.mobile_number;
    this.prefix = value.prefix;
    this.file = value.file;
    this.user_id = value.user_id;
    this.user = value.user;
  }
}
