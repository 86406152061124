import { filter } from 'rxjs/operators';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AHOROS_ACCOUNTING_ENTRY_CATEGORY,
  HOGAR_ACCOUNTING_ENTRIE_CATEGORIE_ID,
  PERSONAL_ACCOUNTING_ENTRY_CATEGORY,
} from 'src/app/utils/consts';
import { lastValueFrom } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetOverviewComponent } from '../../widgets/bottom-sheet-overview/bottom-sheet-overview.component';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { getDashboardData } from 'src/app/utils/states-management/actions/dashboard.action';

@Component({
  selector: 'app-section-cashflow',
  templateUrl: './section-cashflow.component.html',
  styleUrls: ['./section-cashflow.component.scss'],
})
export class SectionCashflowComponent implements OnInit, OnChanges {
  @Input() accountingCategories;
  @Output() dateChange: EventEmitter<any> = new EventEmitter();
  @Input() isDataLoading:boolean;

  global_farm = 0;
  global_family = 0;
  isFarmListOpen = false;
  isFamilyListOpen = false;
  constructor(
    private routerOutlet: IonRouterOutlet,
    private modalController: ModalController,
    private translateService: TranslateService,
    private store: Store,
  ) {}
  farmCategories = [];
  FamilyCategories = [];
  selectedDate = new Date().toISOString();

  @Input() currency;
  month = new Date().getMonth() + 1;
  year = new Date().getFullYear();
  is_current_month;
  date;
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    this.farmCategories = this.accountingCategories.filter(
      (val) => val.is_farm_expenses
    );
    this.global_farm = this.farmCategories.reduce((a, b) => a + b.net, 0);
    this.FamilyCategories = this.accountingCategories.filter(
      (val) =>
        !val.is_farm_expenses && val.id != AHOROS_ACCOUNTING_ENTRY_CATEGORY
    );
    this.global_family = this.FamilyCategories.reduce((a, b) => a + b.net, 0);
  }
  async ngOnInit() {
    this.setTitle();
    // this.accountingCategories.forEach((element) => {
    //   if (
    //     element.is_farm_expenses &&
    //     element.id != PERSONAL_ACCOUNTING_ENTRY_CATEGORY &&
    //     element.id != HOGAR_ACCOUNTING_ENTRIE_CATEGORIE_ID
    //   )
    //     this.global_farm += element.net;
    //   else if (
    //     element.id == PERSONAL_ACCOUNTING_ENTRY_CATEGORY ||
    //     element.id == HOGAR_ACCOUNTING_ENTRIE_CATEGORIE_ID
    //   ) {
    //     this.global_family += element.net;
    //   }
    // });
  }

  emitPreviousClick() {}
  emitNextClick() {}
  showCalendar(value) {
    this.setTitle();
    this.dateChange.emit(this.selectedDate);
  }
  async setTitle() {
    const currentMonth = new Date(this.selectedDate).getMonth() + 1;
    const currentYear = new Date(this.selectedDate).getFullYear();
    this.month === currentMonth && this.year === currentYear
      ? (this.is_current_month = true)
      : (this.is_current_month = false);
  
    const monthNames = await this.translateService.get('monthNames').toPromise();
    this.date = monthNames[currentMonth - 1] + ' ' + currentYear;
  }
      // Convertir la chaîne de caractères en objet Date
  previousMonth() {
    const date = new Date(this.selectedDate);
    date.setMonth(date.getMonth() - 1);
    this.selectedDate = date.toISOString();
    this.setTitle();
    this.dateChange.emit(this.selectedDate);
  }
  
  nextMonth() {
    const date = new Date(this.selectedDate);
    date.setMonth(date.getMonth() + 1);
    this.selectedDate = date.toISOString();
    this.setTitle();
    this.dateChange.emit(this.selectedDate);
  }
  
  async openBottomSheet(item): Promise<void> {
    let componentProps = {
      item: item,
      modalController: this.modalController,
      date: this.date,
    };
    const modal = await this.modalController.create({
      component: BottomSheetOverviewComponent,
      componentProps,
      cssClass: ['custom-modal-sheet', 'height-45', 'modal-wrapper'],
      swipeToClose: true,
      presentingElement: this.routerOutlet.nativeEl,
      animated: true,
      mode: 'ios',
      showBackdrop: false,
    });
    return await modal.present();
  }

  handelListClicked(variable) {
    this[variable] = !this[variable];
  }
}


