import { ToastUtils } from 'src/app/utils/toast_builder';
import { MaterialGoodFormComponent } from './../material-good-form/material-good-form.component';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { showConfirmModal } from 'src/app/utils/pop_over_maker';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../config/offlines_keys.json';
import routes from '../../../config/routing.json';
import { AnimationUtils } from 'src/app/utils/animations';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-list-material-goods',
  templateUrl: './list-material-goods.component.html',
  styleUrls: ['./list-material-goods.component.scss'],
})
export class ListMaterialGoodsComponent implements OnInit {
  @Input() productList: any[];
  @Output() deleteChange: EventEmitter<any> = new EventEmitter();
  @Output() fetchData: EventEmitter<void> = new EventEmitter();
  @Input() isDashboardView = false;
  selectedLangage;
  constructor(
    private animationUtils: AnimationUtils,
    private changeCtrl: ChangeDetectorRef,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private router: Router,
    public translateService: TranslateService,
    private toastUtils: ToastUtils
  ) {}

  async ngOnInit() {
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
    this.selectedLangage = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );

    if (Array.isArray(this.productList))
      await this.productList.forEach((element) => {
        element.is_expanded = true;
        this.changeCtrl.detectChanges();
        return element;
      });
  }

  async deleteClicked(item) {
    this.deleteChange.emit(item);
  }

  async update(item) {
    var confirmed = false;
    if (this.isDashboardView) {
      confirmed = await this.getCashflowRequestConfirmation(item);
      confirmed ? null : await this.showUpdateModal(item);
    } else {
      await this.showUpdateModal(item);
    }
  }

  async showUpdateModal(item) {
    let componentProps = {
      modalControllerMain: this.modalController,
      data: item,
    };
    let modal = await this.toastUtils.modalBuilder(
      MaterialGoodFormComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper'],
      true,
      '1'
    );
    await modal.onWillDismiss();
    this.fetchData.emit();
  }

  async expand(item, id) {
    let expandable = { id: id };
    if (item.is_expanded) {
      await this.animationUtils.expandAnimation(expandable);
      item.is_expanded = false;
    } else {
      await this.animationUtils.collapsAnimation(expandable);
      item.is_expanded = true;
    }
  }

  async getCashflowRequestConfirmation(item): Promise<boolean> {
    if (item.material_product?.categorie?.parent_categorie?.id !== 6) {
      var message = await this.translateService
        .get('add_cashflow_entry')
        .toPromise();
      const modal = await showConfirmModal(
        this.popoverController,
        message,
        null,
        await this.translateService.get('yes').toPromise(),
        await this.translateService.get('no').toPromise()
      );
      const { data } = await modal.onWillDismiss();
      return data?.confirmed;
    } else return false;
  }
}
