import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Storage } from '@capacitor/storage';
import { TranslateService } from '@ngx-translate/core';
import { StatisticsAPI } from 'src/app/apis/statistics.service';
import { DateFormatPipe } from 'src/app/utils/pipes-utils/date_formatter';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import { dev } from '../../../../config/offlines_keys.json';
import {
  FilterParams,
  KpisFiltersComponent,
} from '../../cashflow/filters/kpis-filters/kpis-filters.component';
import { lastValueFrom, take } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { getDashboardData } from 'src/app/utils/states-management/actions/dashboard.action';
import {
  getCafeCerezKpi,
  getTotalsAccountingCategoriesResult,
} from 'src/app/utils/states-management/selector/dashboard.selector';
import { Store } from '@ngrx/store';
import { AccountingEntryCategorie } from 'src/app/models/accounting/accounting_entries_categories';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { IonNav } from '@ionic/angular';
export interface KPI {
  type: string;
  unit: string;
  value: string;
  label: string;
  formula: string;
  has_productive_plots: any;
  has_harvested_product: any;
  key: any;
  label_kpi: string;
  icon: string;
  currency_unit: string;
}
@Component({
  selector: 'app-coffee-kpis',
  templateUrl: './coffee-kpis.component.html',
  styleUrls: ['./coffee-kpis.component.scss'],
})
export class CoffeeKpisComponent implements OnInit {
  filterParams: FilterParams = {
    start_date: null,
    end_date: null,
    show_productive: true,
    show_unproductive: true,
    show_cafe_cerez_details: true,
    show_cafe_mojado_details: false,
    show_cafe_seco_details: false,
  };
  filterAccordion = {
    general: true,
    selection: true,
    category: true,
  };
  accountingSelected: AccountingEntryCategorie;
  currency;
  coffe_unit_label;
  coffe_unit = 'KG';
  @Input() label;
  selected_date;
  isFiltred = false;
  productionKPI: Array<KPI> = [];
  landKPI: Array<KPI> = [];
  selectedKpiType = 'per_kg';
  @ViewChild('select') select: any;
  @Input() nav: IonNav;

  constructor(
    private statisticsAPI: StatisticsAPI,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private datePipe: DateFormatPipe,
    private store: Store<{
      accountingEntries: Array<AccountingEntryCategorie>;
    }>,
    public routingConfig: RoutingConfig
  ) {}
  handelFilterClicked(ev) {
    this.filterAccordion = {
      general: ev,
      selection: ev,
      category: ev,
    };
  }
  onFilterClick(filter) {
    this.filterAccordion[filter] = !this.filterAccordion[filter];
  }
  country

  async ngOnInit() {
    let cropConfig = await this.routingConfig.getCropFormConfig();
    // let data = {
    //   startDate: this.filterParams.start_date,
    //   endDate: this.filterParams.end_date,
    //   cropId: 10,
    // };
    // let data = { startDate: start_date, endDate: end_date,cropId: };

    this.country = await lastValueFrom(
      this.store.select(getCountry).pipe(take(1))
    );
    if (this.country && 'currency' in this.country) {
      this.currency = this.country.currency;
    }

    let { data } = await lastValueFrom(
      this.store.select(getTotalsAccountingCategoriesResult).pipe(take(1))
    );
    this.accountingSelected = data?.filter(
      (elment) => elment.morphable_type
    )[0];

    this.store.select(getCafeCerezKpi).subscribe((productionKPI: any) => {
      this.productionKPI = productionKPI?.filter(
        (element) => element.type == 'production'
      );
      this.landKPI = productionKPI?.filter(
        (element) => element.type != 'production'
      );
    });

    this.translateService.onLangChange.subscribe((val) => {
      this.initDate(this.filterParams.start_date, this.filterParams.end_date);
    });

    this.initFilter();
    
  }
  handelKPITypeCliked(ev) {
    this.select.open(ev);
    // let element: ElementRef = this.content.getNativeElement();
    // let select = element.nativeElement('#select');
    // this.select.click();
    //   const popover = await this.toastUtils.popOverBuilder(
    //     {
    //       data: this.family,
    //       component: 'mano-de-obra',
    //     },
    //     PopoverImgComponent,
    //     'pop-over-style',
    //     ev
    //   );
    //   const { data } = await popover.onDidDismiss();
  }

  Check(item) {
    this.filterParams[item] = !this.filterParams[item];
    // item = !item
  }
  initDate(startDate, endDate) {
    this.selected_date =
      this.datePipe.transform(startDate, this.translateService.currentLang) +
      ' - ' +
      this.datePipe.transform(endDate, this.translateService.currentLang);
  }

  HandelDateChange(value) {
    this.filterParams.start_date = value.start_date;
    this.filterParams.end_date = value.end_date;
    this.getKPIS(this.filterParams.start_date, this.filterParams.end_date);
  }
  initFilter() {
    this.filterParams = {
      start_date: null,
      end_date: null,
      show_productive: true,
      show_unproductive: true,
      show_cafe_cerez_details: true,
      show_cafe_mojado_details: false,
      show_cafe_seco_details: false,
    };
    (this.filterParams.start_date = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      2
    ).toISOString()),
      (this.filterParams.end_date = new Date().toISOString());

    this.initDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ).toISOString(),
      this.filterParams.end_date
    );
  }
  getKPIS(start_date, end_date) {
    let data = {
      startDate: start_date,
      endDate: end_date,
      cropId: this.accountingSelected.id,
    };
    this.store.dispatch(getDashboardData({ data: data }));
  }
  async filter() {
    const modal = await this.toastUtils.modalBuilder(KpisFiltersComponent, {
      filterParams: this.filterParams,
      accountingSelected: this.accountingSelected,
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    if (data?.filterParams) {
      this.filterParams = data.filterParams;
      if (!this.filterParams.start_date) {
        this.filterParams.start_date = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ).toISOString();
      }
      if (!this.filterParams.end_date) {
        this.filterParams.end_date = new Date().toISOString();
      }

      this.selected_date =
        this.datePipe.transform(
          this.filterParams.start_date,
          this.translateService.currentLang
        ) +
        ' - ' +
        this.datePipe.transform(
          this.filterParams.end_date,
          this.translateService.currentLang
        );

      this.getKPIS(this.filterParams.start_date, this.filterParams.end_date);
      this.isFiltred = true;
    } else {
      this.initFilter();
      this.isFiltred = false;
    }
  }

  async showPopover(option: string) {
    let message = '';
    let alertMsg = '';
    message = await this.translateService.instant(option);

    this.toastUtils.popOverBuilder(
      {
        key1: message,
        alertMsg: alertMsg,
      },
      PopoverComponent,
      'popover-custom'
    );
  }
}
