import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import { Unit } from 'src/app/models/Unit';
import { loadUnitsSuccess } from '../actions/units-action';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { orderAlphabaticallyTitles } from '../../array-filtrer';

export interface UnitState extends EntityState<Unit> {
  selectedSaleId: number | null;
}

export function selectedunitId(a: Unit): number {
  return a.id ? a.id : 0;
}

export const unitAdapter: EntityAdapter<Unit> = createEntityAdapter<Unit>({
  selectId: selectedunitId,
});
export const initialunitState: UnitState = unitAdapter.getInitialState({
  selectedSaleId: null,
});

export const units = createReducer(
  initialunitState,
  on(loadUnitsSuccess, (state, { units }) => {
    return unitAdapter.setAll(units, state);
  })
);

export const unitsSelector = createFeatureSelector<UnitState>('units');
export const selectAllUnits = createSelector(
  unitsSelector,
  unitAdapter.getSelectors().selectAll
);

export const selectUnitsByType = (type,TS) =>
  createSelector(
    selectAllUnits, 
     (units) => {
      if (Array.isArray(units) && units?.length > 0 && type && type?.units) {
        var countryUnit = cloneDeep(units);
        countryUnit.forEach((element) => {
          element[
            TS.currentLang == 'es'
              ? 'title'
              : 'title_' + TS.currentLang
          ] = TS.instant(element.value);
        });
        countryUnit =  orderAlphabaticallyTitles(
          countryUnit,
          TS.currentLang
        ).filter((element) =>
        type?.units.includes(
          element.type
        )
      );;
        return countryUnit
        //  this.filterUnit();
      }else{
        return units;
      }
  })


