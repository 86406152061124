import { IonicModule } from '@ionic/angular';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyListComponent } from './empty-list.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    EmptyListComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports: [
    EmptyListComponent
  ],
})
export class EmptyListModule { }
