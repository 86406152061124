import { GetUnitListByProductID } from './../../../../utils/unit-convert';
import { take } from 'rxjs/operators';
import { units } from './../../../../utils/states-management/reducers/units.reducer';
import {
  orderAlphabaticallyTitles,
  orderAlphabaticallyUnit,
} from './../../../../utils/array-filtrer';
import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';

import {
  ANIMALES_ACCOUNTING_ENTRY_CATEGORY,
  BUY_AGRICOLAS_LOT,
  GAST_CASHFLOW_CATEGORIES,
  GAST_CASHFLOW_CATEGORIES_ANIMAL,
  MANO_DE_OBRA_ADMINISTRATIVA,
  MANO_DE_OBRA_FAMILIAR,
  MANO_DE_OBRA_PERMANENTE,
  OTROS_ACCOUNTING_ENTRY_CATEGORY,
  SALARIO_MENSUALES_ID,
  TANSFER_TO_CASH,
  TRANSFER_TO_BANK,
} from 'src/app/utils/consts';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { selectAllUnits } from 'src/app/utils/states-management/reducers/units.reducer';
import {
  loadUnit,
  LOAD_UNITS,
} from 'src/app/utils/states-management/actions/units-action';
import { cloneDeep } from 'lodash';
interface FormConfig {
  show_comment: boolean;
  show_currency_input: boolean;
  show_quantiy_input: boolean;
  show_bank_account_selector: boolean;
}

@Component({
  selector: 'app-paid-method-quantity-comment',
  templateUrl: './paid-method-quantity-comment.component.html',
  styleUrls: ['./paid-method-quantity-comment.component.scss'],
})
export class PaidMethodQuantityCommentComponent
  implements OnInit, OnChanges, DoCheck
{
  @Input() current_required_form_json;
  @Input() cashFlow: any;
  @Input() data!: any;
  @Input() quantity_enabled = false;
  unitSystem: any[] = [];
  countryUnit;
  @Input() money_label = '';
  @Input() qunatity_label;
  @Input() comment_label = '';
  @Input() is_paymen_method_required;
  current_cashflow_unit;
  formConfig: FormConfig = {
    show_comment: false,
    show_currency_input: false,
    show_quantiy_input: false,
    show_bank_account_selector: false,
  };
  selectedUnit;
  liquidAndMassUnits;
  selectedLangage;
  quantity_by_unit_only = false;
  show_account_select = false;
  @Input() max_quantity_allowed_value;
  currentCategory;
  @Output() cashFlowChange: EventEmitter<any> = new EventEmitter<any>();
  poids;
  weightAndUnit;
  weightAndVolume;
  isloading = true;
  constructor(
    public translateService: TranslateService,
    private store: Store,
    public getUnitListByProductID: GetUnitListByProductID,
    private changeDetector: ChangeDetectorRef
  ) {
    this.store.select(selectAllUnits).subscribe(async (value) => {
      if (!value.length) {
        this.store.dispatch(loadUnit());
      }
      if (Array.isArray(value) && value.length > 0) {
        this.countryUnit = cloneDeep(value);
        this.countryUnit.forEach((element) => {
          element[
            this.translateService.currentLang == 'es'
              ? 'title'
              : 'title_' + this.translateService.currentLang
          ] = this.translateService.instant(element.value);
        });
        this.countryUnit = await orderAlphabaticallyTitles(
          this.countryUnit,
          this.translateService.currentLang
        );

        this.selectedUnit = this.countryUnit.find(
          (element) => element.id == this.cashFlow.unity
        );
        this.filterUnit();
        this.isloading = false;
      }
    });
  }
  compareWith(e1, e2) {
    return e1 == e2;
  }

  async ngOnInit() {
    this.selectedLangage = this.translateService.currentLang;
    this.currentCategory = this.cashFlow.cash_flow_categorie_id;
  }
  ngAfterContentChecked(): void {
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.
    this.changeDetector.detectChanges();
  }
  async ngOnChanges(changes: SimpleChanges) {
    // console.log(changes)
    this.loadQuantityRow();
  }
  loadQuantityRow() {
    this.isloading = true;
    this.filterUnit();

    setTimeout(() => {
      this.isloading = false;
    }, 100);
  }
  async ngDoCheck() {
    // if (
    //   this.cashFlow.accounting_entries_categories === 3 &&
    //   this.cashFlow.cash_flow_categorie_id === 33 &&
    //   [13, 14, 15, 16, 17, 18, 19, 20].includes(
    //     this.cashFlow.cash_flow_expense_id
    //   )
    // ) {
    //   this.cashFlow.unity = 3;
    //   this.quantity_by_unit_only = true;
    //   // this.cashFlow.unity = null

    //   this.filterUnit()
    // } else {
    //   // this.cashFlow.unity = 3;

    //   this.quantity_by_unit_only = false;
    //   this.filterUnit()
    //   // this.cashFlow.unity = null
    //   // this.cashFlowChange.emit(this.cashFlow);

    // }
    this.configForm();
  }

  // cashflowMorphableChanged(event) {
  //   this.cashFlow = event;
  //   this.changed(this.cashFlow.payment_methods);
  // }

  changed(value, key) {
    this.cashFlowChange.emit({ key: key, value: value });
    this.cashFlow[key] = value;
  }

  // quantityChanged() {
  //   // this.changed(this.cashFlow.payment_methods);
  //   this.cashFlowChange.emit({ key: 'quantity', val: this.cashFlow.quantity });
  // }

  // setAmount(event) {
  //   this.cashFlow.amount = event;
  //   this.cashFlowChange.emit({ key: 'amount', val: this.cashFlow.amount });
  // }
  // setQuantity(event) {
  //   this.cashFlow.quantity = event;
  //   if (this.cashFlow.quantity != event) {
  //     this.cashFlowChange.emit({
  //       key: 'quantity',
  //       val: this.cashFlow.quantity,
  //     });
  //   }
  // }
  configForm() {
    // console.log(this.current_required_form_json)
    this.formConfig.show_comment =
      this.current_required_form_json?.is_comment_required;
    this.formConfig.show_bank_account_selector =
      (this.cashFlow.cash_flow_categorie_id != TANSFER_TO_CASH &&
        this.cashFlow.cash_flow_categorie_id != TRANSFER_TO_BANK &&
        this.cashFlow.cash_flow_categorie_id != MANO_DE_OBRA_FAMILIAR &&
        this.cashFlow.cash_flow_categorie_id != GAST_CASHFLOW_CATEGORIES &&
        this.current_required_form_json.is_money_required) ||
      (this.cashFlow.cash_flow_categorie_id != MANO_DE_OBRA_FAMILIAR &&
        this.cashFlow.cash_flow_categorie_id != MANO_DE_OBRA_ADMINISTRATIVA &&
        this.cashFlow.cash_flow_categorie_id != MANO_DE_OBRA_PERMANENTE &&
        this.cashFlow.cash_flow_categorie_id != SALARIO_MENSUALES_ID &&
        this.is_paymen_method_required) ||
      (this.cashFlow.cash_flow_categorie_id == MANO_DE_OBRA_FAMILIAR &&
        this.cashFlow.salary &&
        this.cashFlow.salary.salary_amount > 0) ||
      (this.cashFlow.cash_flow_categorie_id == GAST_CASHFLOW_CATEGORIES &&
        this.cashFlow.salary &&
        this.cashFlow.salary.salary_amount > 0 &&
        this.cashFlow.salary.payment_type == '5') ||
      (this.cashFlow.cash_flow_categorie_id == GAST_CASHFLOW_CATEGORIES &&
        this.cashFlow.salary &&
        this.cashFlow.salary.salary_amount > 0 &&
        this.cashFlow.quantity > 0 &&
        this.cashFlow.salary.payment_type == '4') ||
      (this.cashFlow.cash_flow_categorie_id ==
        GAST_CASHFLOW_CATEGORIES_ANIMAL &&
        this.cashFlow.salary &&
        this.cashFlow.salary.salary_amount > 0 &&
        this.cashFlow.salary.payment_type == '5') ||
      (this.cashFlow.cash_flow_categorie_id ==
        GAST_CASHFLOW_CATEGORIES_ANIMAL &&
        this.cashFlow.salary &&
        this.cashFlow.salary.salary_amount > 0 &&
        this.cashFlow.quantity > 0 &&
        this.cashFlow.salary.payment_type == '4') ||
      (this.cashFlow.cash_flow_categorie_id == MANO_DE_OBRA_PERMANENTE &&
        this.cashFlow.salary &&
        this.cashFlow.salary.salary_amount > 0) ||
      (this.cashFlow.cash_flow_categorie_id == MANO_DE_OBRA_ADMINISTRATIVA &&
        this.cashFlow.salary &&
        this.cashFlow.salary.salary_amount > 0 &&
        this.cashFlow.salary.payment_type == '5');
    // console.log(this.formConfig.show_comment)
  }

  filterUnit() {
    this.cashFlow = cloneDeep(this.cashFlow);

    this.unitSystem = this.countryUnit;
    if (
      Array.isArray(this.countryUnit) &&
      this.current_required_form_json &&
      this.current_required_form_json?.external_model_required &&
      this.current_required_form_json?.external_model_required?.units
    ) {
      this.unitSystem = this.unitSystem.filter((element) =>
        this.current_required_form_json.external_model_required.units.includes(
          element.type
        )
      );
      this.quantity_by_unit_only = this.unitSystem?.length === 1;
      if (this.quantity_by_unit_only) {
        this.cashFlow.unity = this.unitSystem[0].id;
        this.cashFlowChange.emit({
          key: 'unity',
          value: this.unitSystem[0].id,
        });
      }
    } else if (
      !this.current_required_form_json?.external_model_required?.units &&
      (this.cashFlow.accounting_entries_categories ==
        OTROS_ACCOUNTING_ENTRY_CATEGORY ||
        this.cashFlow.accounting_entries_categories ==
          ANIMALES_ACCOUNTING_ENTRY_CATEGORY)
    ) {
      this.getUnitListByProductID
        .transform(
          this.cashFlow.product_id,
          this.cashFlow.accounting_entries_categories ==
            ANIMALES_ACCOUNTING_ENTRY_CATEGORY
        )
        .then(async (val) => {
          this.unitSystem = val;
        });
    }
  }
}
