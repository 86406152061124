import { TermsModalPage } from '../../components/terms-modal/terms-modal.page';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ToastUtils } from '../../utils/toast_builder';
import { TranslateService } from '@ngx-translate/core';
import { connectionStatus } from 'src/app/utils/states-management/reducers/offline.reducer';
import { Store } from '@ngrx/store';
import Owner from 'src/app/models/owner.model';

@Component({
  selector: 'app-termes-conditions',
  templateUrl: './termes-conditions.component.html',
  styleUrls: ['./termes-conditions.component.scss'],
})
export class TermesConditionsComponent {
  checkbox1;
  checkbox2;
  checkbox3;
  isHomeView;
  constructor(
    private store: Store<{ owner: Owner }>,
    private router: Router,
    public modalController: ModalController,
    public translateService: TranslateService,
    private toastUtils: ToastUtils
  ) {
    this.isHomeView = this.router.url.includes('home');
    if (this.isHomeView) {
      this.checkbox1 = true;
      this.checkbox2 = true;
      this.checkbox3 = true;
    }
  }

  goBack() {
    this.router.navigateByUrl('/login');
  }
  async presentToast() {
    await this.toastUtils
      .toastBuilder(
        '',
        '',
        await this.translateService
          .instant('screens.terms.errors.terms_error'),
        ''
      );
  }

  async check() {
    if (this.checkbox1 && this.checkbox2 && this.checkbox3) {
      this.router.navigate(['/person']);
    } else {
      if (!this.checkbox1) {
        this.checkbox1 = false;
      }
      if (!this.checkbox2) {
        this.checkbox2 = false;
      }
      if (!this.checkbox3) {
        this.checkbox3 = false;
      }
      await this.presentToast();
    }
  }

  async openModal(id, title) {
    let componentProps = {
      paramID: id,
      paramTitle: await this.translateService
        .get(title)
        .toPromise()
        .then((value) => value),
    };
    let modal = await this.toastUtils.modalBuilder(
      TermsModalPage,
      componentProps,
      ['my-custom-modal-css ', 'modal-wrapper']
    );

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
      }
    });
  }
  isOffline(): boolean {
    let isOffline: boolean;
    this.store.select(connectionStatus).subscribe((isOnline) => {
      isOffline = !isOnline;
    });
    return isOffline;
  }
}
