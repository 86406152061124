import { ToastUtils } from 'src/app/utils/toast_builder';
import { environment } from './../../../environments/environment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopoverComponent } from '../popover/popover.component';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent implements OnInit {
  @Input() title;
  @Input() show_trash_button = false;
  @Input() show_update_button = false;
  @Input() cashflowIcon = '';
  @Input() cashflowIconSrc = '';
  @Input() cropIconSrc = '';
  @Input() titleIcon = '';
  @Input() accoutingImg = '';
  @Input() has_info_icon = true;
  @Input() popOverMsg;
  @Input() cashflowIconSrcUpdateButton;
  @Input() cashflowIconSrcDangerButton='trash';
  @Input() permission = {
    geolocationPermitted: false,
    delimitationPermitted: false,
  };

  @Output() trashClickedEvent: EventEmitter<void> = new EventEmitter();
  @Output() infobullClicked: EventEmitter<any> = new EventEmitter();
  @Output() updateClickEvent: EventEmitter<void> = new EventEmitter();
  @Output() closeClickEvent: EventEmitter<void> = new EventEmitter();
  
  constructor(private toastUtils: ToastUtils) {}
  base_url = environment.appRoot;
  ngOnInit() {
  }
  trashClick() {
    this.trashClickedEvent.emit();
  }
  onClose(){
    this.closeClickEvent.emit();
  }
  updateClicked() {
    this.updateClickEvent.emit();
  }
  async showPopOver() {
    // const msg = await this.translateService
    //   .get('infobull.infobulle_3')
    //   .toPromise();
    let componentProps = { key1: this.popOverMsg ,geolocationPermitted: this.permission.geolocationPermitted,delimitationPermitted: this.permission.delimitationPermitted };
    const popover = await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
    const data = await popover.onWillDismiss();
    if (data) {
      this.infobullClicked.emit(data);
    }
  }
}
