import { dev } from 'src/app/config/offlines_keys.json';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { ConfirmModalComponent } from 'src/app/widgets/confirm-modal/confirm-modal.component';
import { PriorDashboardComponent } from '../../modules/prior-dashboard/prior-dashboard.component';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@capacitor/storage';
import Owner from 'src/app/models/owner.model';
import { Store } from '@ngrx/store';
import { OwnerAPI } from 'src/app/apis/owners.service';
import { Router } from '@angular/router';
import { LOGOUT, logOut } from 'src/app/utils/states-management/actions/owner.action';
import { lastValueFrom, take } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { UserInfo } from 'src/app/apis/users-info.service';
import { connectionStatus } from 'src/app/utils/states-management/reducers/offline.reducer';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
})
export class DeleteAccountComponent implements OnInit {
  @Input() nav;
  owner;
  @Input() rootStack;
  isConfirmedChecked;
  constructor(
    private store: Store<{ owner: Owner }>,
    public translateService: TranslateService,
    private ownerAPI: OwnerAPI,
    private router: Router,
    private popover: PopoverController,
    private userInfo: UserInfo,

  ) {}

  async ngOnInit() {
    this.owner = await lastValueFrom(this.store.select('owner').pipe(take(1)));
  }

  cancel() {
    this.nav.setRoot(this.rootStack, {
      nav: this.nav,
      rootStack: PriorDashboardComponent,
    });
  }

  async delete() {
    // let componentProps = {
    //   confirmation_text: await this.translateService
    //     .get('confirmation_text')
    //     .toPromise(),
    //   title: await this.translateService.get('account_delete').toPromise(),
    //   modalController: this.modalController,
    //   cancel_text: await this.translateService.get('cancel').toPromise(),
    //   submit_text: await this.translateService.get('delete').toPromise(),
    // };
    // let modal = await this.toastUtils.modalBuilder(
    //   ConfirmModalComponent,
    //   componentProps,
    //   ['pop-over-style-confirm-logout']
    // );

    const modal = await this.popover.create({
      component: ConfirmModalComponent,
      componentProps: {
        title: await this.translateService.get('account_delete').toPromise(),
        modalController: this.popover,
        confirmation_text: await this.translateService
          .get('confirmation_text')
          .toPromise(),
        cancel_text: await this.translateService.get('cancel').toPromise(),
        submit_text: await this.translateService.get('delete').toPromise(),
      },
      cssClass: ['pop-over-style-confirm-logout'],
    });
    await modal.present();

    modal.onWillDismiss().then((value) => {
      if (value && value.data.confirmed) {
        this.ownerAPI.deleteAccount().subscribe(async (val) => {

          this.store.dispatch({ type: LOGOUT,redirection_path:'/' });
          // this.router.navigateByUrl('/login');
        });
      }
    });
  }
  isOffline(): boolean {
    let isOffline: boolean;
    this.store.select(connectionStatus).subscribe((isOnline) => {
      isOffline = !isOnline;
    });
    return isOffline;
  }
}
