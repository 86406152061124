import { createReducer, on } from '@ngrx/store';

import { Farm } from 'src/app/models/farm/farm.model';
import { farmSuccessFulyLoaded, updateFarm } from '../actions/farm.action';

export const initialState: Farm = new Farm();

export const farmReducer = createReducer(
  initialState,
  on(farmSuccessFulyLoaded, (state, { farm }) => farm),
  on(updateFarm, (state, action ) => {
    return action.data;
  })
);
