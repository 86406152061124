import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@capacitor/storage';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FarmAPI } from 'src/app/apis/farm.service';
import { ToastUtils } from '../../toast_builder';
import {
  farmSuccessFulyLoaded,
  farmUpdateSuccess,
  loadFarm,
  loadFarmFromLocalStorage,
  updateClusterDetails,
  updateFarm,
} from '../actions/farm.action';
import { dev } from '../../../config/offlines_keys.json';
import routes from '../../../config/routing.json';
import { Farm } from 'src/app/models/farm/farm.model';
import { dataSyncWithServerError } from '../actions/owner.action';

@Injectable()
export class FarmEffect {
  $loadFarmFromLocalStorage = createEffect(() =>
    this.actions.pipe(
      ofType(loadFarmFromLocalStorage),
      switchMap(() => from(Storage.get({ key: dev.FARM_KEY }))),
      map((value: any) => {
        return value ? JSON.parse(value.value) : new Farm();
      }),
      map((farm) => {
        return farmSuccessFulyLoaded({ farm });
      })
    )
  );
  $loadFarm = createEffect(() =>
    this.actions.pipe(
      ofType(loadFarm),
      switchMap(() => this.farmAPI.getFarm()),
      map((value: any) => {
        return value ? value : new Farm();
      }),
      map((farm) => {
        return farmSuccessFulyLoaded({ farm });
      })
    )
  );

  $updateFarmClusterDetails = createEffect(() => {
    return this.actions.pipe(
      ofType(updateClusterDetails),
      switchMap((datas) => this.farmAPI.upDateFarmCluster(datas.data)),
      map((value: any) => {
        return farmUpdateSuccess({ data: value });
      }),
      map(() => loadFarmFromLocalStorage()),
      catchError((err) => of(dataSyncWithServerError(err)))
    );
  });

  $updateFarm = createEffect(() => {
    return this.actions.pipe(
      ofType(updateFarm),
      switchMap((data) => this.farmAPI.upDateFarm(data.data)),
      map((value: any) => {
        return farmUpdateSuccess({ data: value });
      }),
    );
  });

  farmUpdateSuccess = createEffect(() =>
    this.actions.pipe(
      ofType(farmUpdateSuccess),
      switchMap((val) => {
        const isDashboardView =
          this.router.url === '/' + routes.dashboard.profile;

        if (isDashboardView) {
          this.toastUtils
            .toastBuilder(
              this.translateService.instant('change_done'),
              null,
              null,
              'success'
            );
        }
        return []
        // return from(
        //   Storage.set({ key: dev.FARM_KEY, value: JSON.stringify(val.data) })
        // );
      }),
      // map(() => loadFarmFromLocalStorage())
    )
  );
  constructor(
    private farmAPI: FarmAPI,
    private actions: Actions,
    private toastUtils: ToastUtils,
    public translateService: TranslateService,
    private router: Router
  ) {}
}
