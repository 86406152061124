import { RoutingConfig } from 'src/app/utils/routing-config';
import { MORPH_Lot } from '../../../../utils/consts';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../../config/offlines_keys.json';
import { Router } from '@angular/router';
import { ToastUtils } from '../../../../utils/toast_builder';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { PopoverComponent } from '../../../../widgets/popover/popover.component';
import { ModalController, PopoverController } from '@ionic/angular';
import routes from '../../../../config/routing.json';
import { USAGE_TYPE } from '../../../../models/lots.model';
import { showConfirmModal } from 'src/app/utils/pop_over_maker';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import { PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY } from 'src/app/utils/consts';
import cashflowCategories from '../../../../config/material_categories_material_cashflow.json';
import { TranslateService } from '@ngx-translate/core';
import { UnifiedCashflowInputComponentComponent } from 'src/app/modules/cashflow/unified-cashflow-input-component/unified-cashflow-input-component.component';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Farm } from 'src/app/models/farm/farm.model';
import { lastValueFrom } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';

@Component({
  selector: 'app-lots',
  templateUrl: './lots.component.html',
  styleUrls: ['./lots.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LotsComponent implements OnInit {
  @Input() modalController: ModalController;
  selectedLangage;
  USAGE_TYPE = USAGE_TYPE;
  label;
  lot_type_label;
  toolbarTitle;
  hide_footer = true;
  hide = true;
  hide2 = false;
  canGoNext = true;
  @ViewChild('tabGroup') matTab: MatTabGroup;
  maxIndex = 3;
  isDashboardView;
  @Input() just_end = false;
  farm :Farm
  constructor(
    private router: Router,
    private popover: PopoverController,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private routingConfig: RoutingConfig,
    private store: Store<{ farm: Farm }>

  ) {
    this.translateService.get('label').subscribe(
      (value) => (this.label = value),
      (e) => console.log(e)
    );
    this.translateService.get('lot_type').subscribe(
      (value) => (this.lot_type_label = value),
      (e) => console.log(e)
    );
    this.translateService.get('titles.lot_prod_cafe').subscribe(
      (value) => (this.toolbarTitle = value),
      (e) => console.log(e)
    );
  
  }

  async ngOnInit() {
    await this.translate();
    this.matTab.selectedIndex = 0;

    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
 
      this.farm =  await lastValueFrom(this.store.select(getFarm).pipe(take(1)))
    }

  async translate() {
    this.selectedLangage = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );

    await this.showPopOver(
      await this.translateService
        .get('infobull.infobull_1')
        .toPromise()
        .then((value) => value)
    );
  }

  async previous() {
    if (this.matTab.selectedIndex == 1) {
      this.matTab.selectedIndex -= 1;
    } else {
      this.router.navigateByUrl(await this.routingConfig.getBackUrl());
    }
  }

  async next() {

    if (this.matTab.selectedIndex < 1) {
      this.matTab.selectedIndex += 1;
    } else if (this.matTab.selectedIndex < 1) {
      const msg = await this.translateService
        .instant('errors.missing_coffee_production');

      this.toastUtils
        .toastBuilder(
          msg,
          '',
          '',
          ''
        );
    } else this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }

  hide_footerChange(hide_footer) {
    this.hide_footer = !this.hide_footer;
  }

  async showPopOver(message) {
    let componentProps = {
      key1: await this.translateService
        .get(message)
        .toPromise()
        .then((value) => value),
    };

    await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
  }

  async tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.hide = true;
    this.hide2 = false;
    if (tabChangeEvent.index != 0) this.hide = false;
    if (tabChangeEvent.index == 1) {
      this.showPopOver(
        await this.translateService
          .get('infobull.infobulle_2')
          .toPromise()
          .then((value) => value)
      );
      this.hide2 = true;
    }
  }

  async cashflowInsertion(): Promise<Boolean> {
    const modal = await showConfirmModal(
      this.popover,
      await this.translateService
        .get('add_cashflow_entry')
        .toPromise()
        .then((value) => value),
      null,
      'Sí',
      'No'
    );
    const { data } = await modal.onWillDismiss();
    return data?.confirmed;
  }

  async initBuyCashflow(categories, id): Promise<CashFlow> {
    const cashflow = new CashFlow();
    cashflow.morphable_id = id;
    cashflow.morphable_type = MORPH_Lot;
    cashflow.cash_flow_categorie_id = cashflowCategories.lotes.buy;
    cashflow.accounting_entries_categories =
      PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY;
    cashflow.is_income = 0;
    cashflow.quantity = 1;
    // cashflow.unity = 'unit';
    cashflow.payment_methods = 'cash';
    let country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    cashflow.currency = country.currency
    cashflow.farm_id = this.farm.id
    cashflow.date = new Date().toISOString();
    cashflow.cashflow_categories = categories.find(
      (element) => element.id === cashflow.cash_flow_categorie_id
    );
    return cashflow;
  }

  async showRelatedPopover(id) {
    const cashFlowCategFiltred = await Storage.get({
      key: dev.CASHFLOW_CATEGORIES,
    }).then((value) =>
      JSON.parse(value.value).find(
        (element) => element.id == PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY
      )
    );
    let componentProps = {
      cashFlow: await this.initBuyCashflow(cashFlowCategFiltred, id),
      accountingEntryCategories: cashFlowCategFiltred,
      modalController: this.modalController,
      no_post_submit_action: true,
    };
    let modal = await this.toastUtils.modalBuilder(
      UnifiedCashflowInputComponentComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper'],
      true,
      '2'
    );
    await modal.onWillDismiss();
  }
  recieveCanGoNext(event) {
    this.canGoNext = event;
  }
}
