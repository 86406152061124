import { cloneDeep } from 'lodash';
import { Network } from '@capacitor/network';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { mapLangeNameObjects } from 'src/app/utils/array-filtrer';
import { PopoverImgComponent } from 'src/app/widgets/popover-img/popover-img.component';
import { Component, OnInit, Input } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { dev } from '../../config/offlines_keys.json';
import { HttpErrorResponse } from '@angular/common/http';
import { OwnerAPI } from '../../apis/owners.service';
import { PopoverController } from '@ionic/angular';
import { ToastUtils } from '../../utils/toast_builder';
import { Router } from '@angular/router';
import { PriorDashboardComponent } from 'src/app/modules/prior-dashboard/prior-dashboard.component';
import { UserInfo } from 'src/app/apis/users-info.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmNumberUpdateComponent } from '../confirm-number-update/confirm-number-update.component';
import { lastValueFrom, take } from 'rxjs';
import { Store } from '@ngrx/store';
import Owner from 'src/app/models/owner.model';
import {
  getAllCounties,
  getCountry,
} from 'src/app/utils/states-management/selector/countries.selector';
import { setUserCountry } from 'src/app/utils/states-management/actions/countries.action';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-update-access-widget',
  templateUrl: './update-access-widget.component.html',
  styleUrls: ['./update-access-widget.component.scss'],
})
export class UpdateAccessWidgetComponent implements OnInit {
  @Input() nav;
  @Input() rootStack;
  countries: any;
  authNumber;
  network;
  prefix;
  owner;

  constructor(
    private ownerAPI: OwnerAPI,
    private router: Router,
    public popoverController: PopoverController,
    private userInfo: UserInfo,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private store: Store<{ owner: Owner }>
  ) {}
  countryName;

  async ngOnInit() {
    this.countries = await lastValueFrom(
      this.store.select(getAllCounties).pipe(take(1))
    );
    // let country = cloneDeep(
    //   await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    // );
    this.owner = cloneDeep(
      await lastValueFrom(this.store.select('owner').pipe(take(1)))
    );
    this.country = this.countries.find((val) => this.owner.prefix == val.indicatif);

  
    this.authNumber = this.owner?.mobile_number;
    this.prefix = this.owner?.prefix;
    this.network = this.owner.network == 'null' ? '' : this.owner.network;
    Storage.get({ key: dev.LIST_COUNTRIES }).then(async (value) => {
      if (Array.isArray(JSON.parse(value.value))) {
        this.countries = await mapLangeNameObjects(
          JSON.parse(value.value),
          this.translateService.currentLang
        );
      }
    });
  }
  code;
  country;

  base_url = environment.appRoot
  async handelChangeCountrieName(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverImgComponent,
      cssClass: 'pop-over-style-login',
      event: ev,
      translucent: true,
      componentProps: { data: this.countries, component: 'create-account2' },
    });
    popover.present();
    const { data } = await popover.onDidDismiss();
    if (data) {
      this.prefix = data.data.indicatif;
      this.country = data.data;
      this.countryName = this.translateService.instant(
        'countries.' + data.data.id
      );
      this.country.file_path = this.base_url + data?.data?.file?.path;
    } else {
      this.countryName = '';
    }
  }
  async handelChangePrefix(ev) {
    let componentProps = { data: this.countries, component: 'login' };
    const popover = await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverImgComponent,
      'pop-over-style',
      ev
    );

    const { data } = await popover.onDidDismiss();
    if (data) {
      console.log(data);
      this.prefix = data.data.indicatif;
      this.country = data.data;
      this.country.file_path = this.base_url + data?.data?.file?.path;
    }
  }
  async updateNumber() {
    const toast = this.toastUtils;

    if (this.authNumber && this.prefix) {
      // const newNumber = this.authNumber.toString();
      // const newPrefix = this.prefix.split(' ')[1];
      this.ownerAPI
        .updateUserMobileAndAccess(
          this.owner.mobile_number,
          this.prefix,
          this.network?this.network:'',
          this.owner?.user?.id,
          this?.owner?.id,
          await this.translateService.instant('label.change_number_pin')
        )
        .subscribe({
          next: async (value: any) => {
            let modal = await this.toastUtils.modalBuilder(
              ConfirmNumberUpdateComponent,
              {
                new_number: this.owner.mobile_number,
                new_prefix: this.prefix,
                new_network: this.network?this.network:'',
                auth_id: this.owner?.id,
                owner_id: this?.owner?.user?.id,
                pin: value?.pin,
                auth: this.owner.user,
                owner: this.owner,
              },
              ['custom-modal-confirm', 'modal-wrapper']
            );
            modal.present();
          },
          error: async (error: HttpErrorResponse) => {
            this.toastUtils
              .toastBuilder(
                this.translateService.instant(
                  'errors.mobile_number_already_exist'
                ),
                '',
                ' ',
                ''
              );
          },
        });
    } else {
      await toast
        .toastBuilder(
          await this.translateService
            .get('login_error')
            .toPromise()
            .then((value) => value),
          '',
          '',
          ''
        );
    }
  }

  async logOut() {
    const lang = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );
    const country = await lastValueFrom(
      this.store.select(getCountry).pipe(take(1))
    );
    await Storage.clear();
    await this.userInfo.setSelectedLangage(lang);
    this.userInfo.owner = null;
    this.store.dispatch(setUserCountry({ country: country }));

    this.router.navigateByUrl('/');
  }

  return() {
    this.nav.setRoot(this.rootStack, {
      nav: this.nav,
      rootStack: PriorDashboardComponent,
    });
  }
}
