import { MORPH_FamilyMember, MORPH_Owner } from './../../../utils/consts';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, Input, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { OperationsAPI } from 'src/app/apis/operations.service';
import { dev } from '../../../config/offlines_keys.json';
import { environment } from 'src/environments/environment';
import { ModalController } from '@ionic/angular';
import { ModalMoneyLoanComponent } from '../../goods/modal-money-loan/modal-money-loan.component';
import { ModalDebtComponent } from '../../debts/modal-debt/modal-debt.component';
import { MORPH_Farm } from 'src/app/utils/consts';

@Component({
  selector: 'app-operations-overview',
  templateUrl: './operations-overview.component.html',
  styleUrls: ['./operations-overview.component.scss'],
})
export class OperationsOverviewComponent implements OnInit {
  @Input() operation_type;
  operations: any[] = [];
  main_endpoint = environment.appRoot;
  you;
  MORPH_Farm = MORPH_Farm;
  MORPH_FamilyMember = MORPH_FamilyMember;
  MORPH_Owner = MORPH_Owner;
  constructor(
    private operationAPI: OperationsAPI,
    private modalController: ModalController,
    private toastUtils: ToastUtils
  ) {}

  async ngOnInit() {
    let lang = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );
    lang === 'es' ? (this.you = 'Usted') : (this.you = 'You');
    //TODO offline
    this.fetchData();
  }

  async fetchData() {
    let owner = await Storage.get({ key: dev.USER_KEY }).then((value) =>
      JSON.parse(value.value)
    );
    this.operationAPI.getOperation().subscribe((value) => {
      if (Array.isArray(value)) {
        this.operations = value
          .filter(
            (operation) => operation.operation_type === this.operation_type
          )
          .map((element) => {
            element.display_percent = this.calculatePercent(
              element.amount,
              element.paid_so_far
            );
            return element;
          });
      }
    });
  }
  calculatePercent(required_amount, gathered_amount) {
    if (!gathered_amount) {
      gathered_amount = 0;
    }
    return (100 * (gathered_amount / required_amount)).toFixed(2);
  }

  async deleteEntry(item) {
    await this.operationAPI.deleteOperation(item.id).toPromise();
    this.fetchData();
  }

  async update(item) {
    let modal;
    if (this.operation_type === 'money loan') {
      let modal = await this.toastUtils.modalBuilder(
        ModalMoneyLoanComponent,
        { id: item.id },
        ['my-custom-modal-css', 'modal-wrapper']
      );
      modal = await this.modalController.create({
        component: ModalMoneyLoanComponent,
        cssClass: ['my-custom-modal-css ', 'modal-wrapper'],
        componentProps: {
          id: item.id,
        },
      });
    } else if (this.operation_type === 'debt') {
      modal = await this.modalController.create({
        component: ModalDebtComponent,
        cssClass: ['my-custom-modal-css ', 'modal-wrapper'],
        componentProps: {
          id: item.id,
        },
      });
    }
    modal.present();
    await modal.onWillDismiss();
    this.fetchData();
  }
}
