import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { UnitsApi } from 'src/app/apis/units.service';
import { loadUnit, loadUnitsSuccess } from '../actions/units-action';
import { Unit } from 'src/app/models/Unit';

@Injectable()
export class UnitsEffects {
  loadUnits$ = createEffect(() =>
    this.action.pipe(
      ofType(loadUnit),
      switchMap(() => this.unitsAPI.loadUnits().pipe(map((value) => value))),
      map((value: Array<Unit>) => loadUnitsSuccess({ units: value }))
    )
  );

  constructor(private action: Actions, private unitsAPI: UnitsApi) {}
  
}
