import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-animals-selector',
  templateUrl: './animals-selector.component.html',
  styleUrls: ['./animals-selector.component.scss'],
})
export class AnimalesSelectorComponent implements OnInit {
  @Input() categories;
  @Output() animalSelectedEventEmitter: EventEmitter<any> = new EventEmitter();
  base_url = environment.appRoot;
  other_selected = false
  other_product = ''
  constructor(public translateService: TranslateService) {}

  async ngOnInit() {    
  }
  selectProduct(product) {
    product.is_checked = !product.is_checked;
    if(product.is_checked && product.id == 15){
      this.other_selected = true
    }else if(!product.is_checked && product.id == 15){
      this.other_selected = false
    }
  }
  handelOtherChange(event){
    this.categories.find(val=>val.id == 15).comment = this.other_product
  }

  getCategorieLength(categorie) {
    return this.translateService.currentLang === 'es' ? categorie.title.length : categorie['title_' + this.translateService.currentLang].length
  }
}
