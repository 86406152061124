import { HttpErrorResponse } from '@angular/common/http';
import { Action, createAction, props } from '@ngrx/store';
import Employee from 'src/app/models/employee.model';
import Owner from 'src/app/models/owner.model';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const GET_OWNER_BY_ID = 'GET_OWNER_BY_ID';
export const GET_OWNER_BY_ID_SUCESS = 'GET_OWNER_BY_ID_SUCESS';
export const SET_CHECKPOINT_ROUTE = 'SET_CHECKPOINT_ROUTE';
export const SET_CHECKPOINT_ROUTE_SUCCESS = 'SET_CHECKPOINT_ROUTE_SUCCESS';
export const DELETE_PROFILE_PHOTO = 'DELETE_PROFILE_PHOTO';
export const STORE_OWNER_PHOTO = 'STORE_OWNER_PHOTO';
export const UPDATE_OWNER_DATA = 'UPDATE_OWNER_DATA';
export const UPDATE_OWNER_DATA_SUCCESS = 'UPDATE_OWNER_DATA_SUCCESS';
export const DATA_SYNC_WITH_SERVER_ERROR = 'DATA_SYNC_WITH_SERVER_ERROR';
export const DATA_SYNC_WITH_SERVER_ERROR_SHOWED =
  'DATA_SYNC_WITH_SERVER_ERROR_SHOWED';
export const UPDATE_OWNER_PHOTO = 'UPDATE_OWNER_PHOTO';
export const UPDATE_OWNER_AND_FARM_CLUST = 'UPDATE_OWNER_AND_FARM_CLUST';

export const DELETE_OWNER_EMPLOYEE = 'DELETE_OWNER_EMPLOYEE';
export const DELETE_OWNER_EMPLOYEE_SUCCESS = 'DELETE_OWNER_EMPLOYEE_SUCCESS';
export const ADD_OWNER_EMPLOYEE = 'ADD_OWNER_EMPLOYEE';
export const ADD_OWNER_EMPLOYEE_SUCCESS = 'ADD_OWNER_EMPLOYEE_SUCCESS';
export const UPDATE_PREFERRED_LANGUAGE = 'UPDATE_PREFERRED_LANGUAGE';

export const updateOwnerPhoto = createAction(
  UPDATE_OWNER_PHOTO,
  props<{ id: number; file: Blob }>()
);
export const getOwnerById = createAction(GET_OWNER_BY_ID);
export const getOwnerByIdSucess = createAction(
  GET_OWNER_BY_ID_SUCESS,
  props<{ owner: Owner }>()
);
export const setCheckPointRoute = createAction(
  SET_CHECKPOINT_ROUTE,
  props<{ url: string }>()
);

export const setCheckPointRouteSuccess = createAction(
  SET_CHECKPOINT_ROUTE_SUCCESS,
  props<{ url: string }>()
);

export const updatePreferredLanguage = createAction(
  UPDATE_PREFERRED_LANGUAGE,
  props<{ lang: string }>()
);
export const storeOwnerPhoto = createAction(
  STORE_OWNER_PHOTO,
  props<{ file: Blob }>()
);

export const deleteProfilePhoto = createAction(DELETE_PROFILE_PHOTO);

export const updateOwnerData = createAction(
  UPDATE_OWNER_DATA,
  props<{ owner: Owner }>()
);
export const updateOwnerDataSuccess = createAction(
  UPDATE_OWNER_DATA_SUCCESS,
  props<{ owner: Owner }>()
);

export const dataSyncWithServerError = createAction(
  DATA_SYNC_WITH_SERVER_ERROR,
  props<{ err: HttpErrorResponse }>()
);
export const dataSyncWithServerErrorShowed = createAction(
  DATA_SYNC_WITH_SERVER_ERROR_SHOWED
);
export const updateOwnerAndFarmCluster = createAction(
  UPDATE_OWNER_AND_FARM_CLUST,
  props<{ data: any; profile_info: any }>()
);

// This exports a const `deleteOwnerEmployee` and assigns it to an action created for deleting an employee.
export const deleteOwnerEmployee = createAction(
  DELETE_OWNER_EMPLOYEE,
  props<{ data: Owner; props: Object }>()
);

// This exports a const `deleteOwnerEmployeeSuccess` and assigns it to an action created for successful deletion of an employee.
export const deleteOwnerEmployeeSuccess = createAction(
  DELETE_OWNER_EMPLOYEE_SUCCESS,
  props<{ id: number }>()
);

export const addOwnerEmployee = createAction(
  ADD_OWNER_EMPLOYEE,
  props<{ data: Owner; props: Object }>()
);

// This exports a const `addOwnerEmployeeSuccess` and assigns it to an action created for successful addition of an employee.
export const addOwnerEmployeeSuccess = createAction(
  ADD_OWNER_EMPLOYEE_SUCCESS,
  props<{ data: Owner; offlineId: string }>()
);

export const logOut = createAction(
  LOGOUT,
  props<{ redirection_path: string }>()
);
export const logOutSuccess = createAction(LOGOUT_SUCCESS);
