import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoodsAPI } from 'src/app/apis/goods.service';
import { MaterialProductAPI } from 'src/app/apis/material_product.service';
import { ANIMAL_IDS } from 'src/app/utils/consts';
import { ToastUtils } from 'src/app/utils/toast_builder';
import routes from '../../../../config/routing.json';
import { UserInfo } from 'src/app/apis/users-info.service';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-update-animal-proprety',
  templateUrl: './update-animal-proprety.component.html',
  styleUrls: ['./update-animal-proprety.component.scss'],
})
export class UpdateAnimalPropretyComponent implements OnInit {
  @Input() modalController;
  @Input() access_token;
  @Input() owner;
  @Input() entry: ProductionsPerfarm;
  isDashboardView;

  values;
  lang;
  constructor(
    private materialProductAPI: MaterialProductAPI,
    private goodsAPI: GoodsAPI,
    private router: Router,
    private userInfo: UserInfo,
    public translateService: TranslateService,
    private toastUtils: ToastUtils
  ) {}

  async ngOnInit() {
    this.lang = await this.userInfo.getSelectedLangage();
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)

    this.materialProductAPI
      .getCategoriesByName(this.entry.morphable.title)
      .subscribe((value: any) => {
        this.values = value.map((categorie) => {
          categorie.material_products.forEach((product) => {
            if (Array.isArray(product.goods) && product.goods.length)
              product.is_checked = true;
          });
          return categorie;
        });
      });
  }

  dismiss() {
    this.modalController.dismiss();
  }

  async submit() {
    let products = new Array();
    this.values.forEach((element) => {
      element.material_products.forEach((product) => {
        if (Array.isArray(product.goods) && product.goods.length > 0) {
          products.push({
            id: product.id,
            qty: product.goods[0].qty,
            unit: product.goods[0].unit,
            production_per_farm_id: this.entry.id,
          });
        } else if (product.is_checked) {
          products.push({
            id: product.id,
            qty: product.qty,
            unit: product.unit,
            production_per_farm_id: this.entry.id,
          });
        }
      });
    });

    var has_error = false;
    products.forEach((element) => {
      if (
        element.qty &&
        !ANIMAL_IDS.includes(element.id) &&
        (!element.unit || element.unit == 'unit')
      ) {
        has_error = true;
      }
    });
    if (has_error)
      await this.toastUtils
        .toastBuilder(
          await this.translateService
            .instant('errors.missing_fields'),
          '',
          ''
        );
    else
      this.materialProductAPI.postMultipleProduct(products).subscribe((val) => {
        this.modalController.dismiss();
      });
  }

  productUnchecked(product) {
    if (!product.is_checked) {
      this.goodsAPI.deleteGood(product.goods[0].id).subscribe(async(value) => {
        await this.toastUtils
          .toastBuilder('Entrada borrada', '', '', '');
        product.goods = new Array();
      });
    }
  }
}
