import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { dev } from '../config/endpoints.json';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  base_url = environment.appRoot + '/api/';

  constructor(private http: HttpClient) {}

  getCountries() {
    let url = this.base_url + dev.endpoint.countries.list_countries;
    return this.http.get(url);
  }
  getSelectableCountries() {
    let url = this.base_url + dev.endpoint.countries.countires_selection; 
    return this.http.get(url); 
  }
}
