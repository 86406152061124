import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import appRoutes from '../../config/routing.json';
import { FamilyMainViewComponent } from './family-main-view/family-main-view.component';
import { FamilyViewOneComponent } from './family-view-one/family-view-one.component';
import { FamilyViewTwoComponent } from './family-view-two/family-view-two.component';

const routes: Routes = [
  {
    path: '',
    component: FamilyMainViewComponent,
  },
  {
    path: appRoutes.family.family_situation,
    component: FamilyViewOneComponent,
  },
  {
    path: appRoutes.family.family_members,
    component: FamilyViewTwoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FamilyRoutingModule {}
