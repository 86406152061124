import { dev } from 'src/app/config/offlines_keys.json';
import { Storage } from '@capacitor/storage';
import { environment } from '../../environments/environment';
import { setUserCountry } from './states-management/actions/countries.action';


export async function handleResponse(response) {
  if (response?.file) this.image = environment.appRoot + response?.file.path;
  this.user_name = response?.first_name + '  ' + response?.last_name;
  if (response.countrie){

    await setToStorage(dev.COUNTRY, response?.countrie);
    //  this.store.dispatch(setUserCountry({country:response?.countrie}))

    }

    // response.preferred_language && await setToStorage(dev.LANGAGE_KEY, response?.preferred_language);

  if (response?.objectives)
    await setToStorage(dev.OBJECTIVES_KEY, response?.objectives);
  if (response?.farm) {
    await setToStorage(dev.LOT_KEY, response?.farm?.lots);

    await setToStorage(dev.FARM_KEY, response?.farm);

    if (response.farm && Array.isArray(response.farm.productions))
      await setToStorage(
        dev.PRESELECTED_PRODUCT_KEY,
        response.farm.productions
      );
    if (response.farm && response.farm.employees)
      await setToStorage(dev.EMPLOYEES_KEY, response?.farm.employees);
  }
  if (response.file && response.file != null)
    await setToStorage(dev.FILE_KEY, response?.file);

  if (
    response?.farm &&
    Array.isArray(response?.farm?.lots) &&
    response?.farm?.lots.length > 0
  ) {
    const productions: any = [];
    response?.farm?.lots.forEach((element) => {
      if (element.type === 'other_prod') {
        if (
          Array.isArray(element.productions) &&
          element.productions.length > 0
        ) {
          element.productions.forEach((elem) => {
            productions.push({
              name: elem?.product?.name,
              product_id: elem?.product?.id,
              id: elem?.id,
              lot_id: elem?.lot_id,
              percent_in_lot: elem?.percent_in_lot,
              used_surface: elem?.used_surface,
              is_productive: elem?.is_productive,
            });
          });
        }
      }
    });

    await setToStorage(dev.PRODUCTION_KEY, productions);
  }

  await setToStorage(dev.USER_KEY, {
    id: response?.id,
    first_name: response?.first_name,
    last_name: response?.last_name,
    birth_date: response?.birth_date,
    mobile_number: response?.mobile_number,
    sex: response?.sex,
    countrie_id: response?.countrie_id,
    profile_info: response?.profile_info,
    farm_id: response?.farm_id,
    file_id: response?.file_id,
    checkpoint_path: response?.checkpoint_path,
    is_owner: response?.is_owner,
    user: response?.user,
    prefix: response?.prefix,
  });
  return response;
}

async function setToStorage(key, value) {
  await Storage.set({
    key: key,
    value: JSON.stringify(value),
  });
}
