import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Storage } from '@capacitor/storage';
import { dev } from '../../config/offlines_keys.json';

@Component({
  selector: 'app-yes-no-question-widgets',
  templateUrl: './yes-no-question-widgets.component.html',
  styleUrls: ['./yes-no-question-widgets.component.scss'],
})
export class YesNoQuestionWidgetsComponent implements OnInit, OnChanges {
  yes_clicked = false;
  no_clicked = false;
  @Input() is_yes = false;
  @Input() targetComponent = '';
  @Input() production: any = {};
  @Input() yesLabel = 'yes';
  @Input() noLabel = 'no';
  @Output() answeredEvent: EventEmitter<Boolean> = new EventEmitter();
  constructor() {}

  async ngOnInit() {
    const lang = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );
    if (
      !this.is_yes &&
      this.targetComponent === 'PorductionEditorComponent' &&
      this.production &&
      !this.production?.is_fully_filled
    ) {
      this.yes_clicked = null;
      this.no_clicked = null;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isYes();
  }
  answerClick(val) {
    if (val) {
      this.yes_clicked = true;
      this.no_clicked = false;
    } else {
      this.yes_clicked = false;
      this.no_clicked = true;
    }
    this.answeredEvent.emit(val);
  }

  isYes() {
    if (this.is_yes && this.targetComponent != 'pension') {
      this.yes_clicked = true;
      this.no_clicked = false;
      this.answeredEvent.emit(true);
    } else if (this.targetComponent == 'pension') {
      this.yes_clicked = false;
      this.no_clicked = true;
      this.answeredEvent.emit(false);
    } else if (
      !this.is_yes &&
      this.targetComponent === 'PorductionEditorComponent'
    ) {
      this.yes_clicked = false;
      this.no_clicked = true;
      // this.answeredEvent.emit(false);
    }
  }
}
