import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AnalysisPageRoutingModule } from './analysis-routing.module';

import { AnalysisPage } from './analysis.page';
import { AnalysisCashflowComponent } from '../../dashboard/cashflow/analysis-cashflow/analysis-cashflow.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HomeFilterComponent } from '../widgets/home-filter/home-filter.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AnalysisPageRoutingModule,
    TranslateModule.forChild(),
  ],
  declarations: [AnalysisPage, AnalysisCashflowComponent],
})
export class AnalysisPageModule {}
