import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingConfig } from 'src/app/utils/routing-config';

@Component({
  selector: 'app-landing_done',
  templateUrl: './landing_done.component.html',
  styleUrls: ['./landing_done.component.scss'],
})
export class landingDoneComponent implements OnInit {

  constructor(public translateService:TranslateService,
    private router: Router,
    private routingConfig: RoutingConfig
    ) {}

  ngOnInit() {

  }

  async handelClick(){
    this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }

 
}
