import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { dev } from 'src/app/config/offlines_keys.json';
import { Storage } from '@capacitor/storage';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-assistance-modal',
  templateUrl: './assistance-modal.component.html',
  styleUrls: ['./assistance-modal.component.scss'],
})
export class AssistanceModalComponent implements OnInit {
  @Input() program;
  @Input() programInfos;
  @Input() modalController: ModalController;
  number: number;
  first_name: string;
  last_name: string;
  Tel: string;
  email: string;
  comments: string
  owner
  constructor(
    public translateService: TranslateService,
  ) { }

  async ngOnInit() {
    this.owner = await JSON.parse((await Storage.get({key : dev.USER_KEY})).value)
      if(this.owner){
          this.first_name = this.owner.first_name;
          this.last_name = this.owner.last_name;
          this.Tel = '+'+this.owner.user.prefix + ' ' + this.owner.user.mobile_number
      }
  }


  submit() {}

  dismiss() {
    this.modalController.dismiss({ cancel: true });
  }

}
