import { RoutingConfig } from 'src/app/utils/routing-config';
import {
  MORPH_Farm,
  MORPH_FamilyMember,
  MORPH_Owner,
} from './../../../utils/consts';
import { OwnerAPI } from 'src/app/apis/owners.service';
import { PopoverImgComponent } from './../../../widgets/popover-img/popover-img.component';
import { Component, Input, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../config/offlines_keys.json';
import {
  LoadingController,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { OperationsAPI } from '../../../apis/operations.service';
import Operation from '../../../models/operation.model';
import { Family_membersAPI } from '../../../apis/family_members.service';
import { PickerOptionFormater } from '../../../utils/pipes-utils/date_formatter';
import { ToastUtils } from '../../../utils/toast_builder';
import routes from '../../../config/routing.json';
import { Router } from '@angular/router';
import { CashflowAPI } from 'src/app/apis/cashflow.service';

import { UserInfo } from 'src/app/apis/users-info.service';
import { TranslateService } from '@ngx-translate/core';
import { OperationRelatedToCashflowBusinessRules } from 'src/app/utils/business-rules/operations_related_to_cashflow_business_rules';
import { Store } from '@ngrx/store';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { take } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';

@Component({
  selector: 'app-modal-money-loan',
  templateUrl: './modal-money-loan.component.html',
  styleUrls: ['./modal-money-loan.component.scss'],
  providers: [OperationsAPI, Family_membersAPI],
})
export class ModalMoneyLoanComponent implements OnInit {
  @Input() accounting_entry_category;
  @Input() cashflow_category_id;
  @Input() id: number;
  loadinModal;

  qst;
  listf: any;
  farm_id: number;
  currency;
  country;
  currentYear;
  dateString;
  operation = new Operation();
  isDashboardView;
  you;
  currentSelectedFamilyMember;
  owner;
  selectedSegmentValue;

  today = new Date();
  customPickerOptions_due_date;
  customPickerOptions;
  formConfig;
  constructor(
    public modalController: ModalController,
    private router: Router,
    private progressModal: LoadingController,
    public popoverController: PopoverController,
    public ownerAPI: OwnerAPI,
    public familyMemberAPI: Family_membersAPI,
    public operationAPI: OperationsAPI,
    public cashflowAPI: CashflowAPI,
    private userInfo: UserInfo,
    public translateService: TranslateService,
    private pickerOption: PickerOptionFormater,
    private toastUtils: ToastUtils,
    private operationRule: OperationRelatedToCashflowBusinessRules,
    private routingConfig: RoutingConfig,
    private store: Store
  ) {}

  async ngOnInit() {
    let ClassName = 'ModalMoneyLoanComponent';
    this.formConfig = await this.routingConfig.getPageFormConfig(ClassName);

    this.customPickerOptions = this.pickerOption.custom_picker_options(
      this.operation,
      'creation_date'
    );
    this.customPickerOptions_due_date = this.pickerOption.custom_picker_options(
      this.operation,
      'due_date'
    );
    this.owner = await this.userInfo.getOwner();
    this.loadinModal = await this.progressModal.create().then((value) => value);
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
    this.currentYear = new Date();
    this.dateString = new Date(
      this.currentYear.getTime() - this.currentYear.getTimezoneOffset() * 60000
    );
    this.dateString = this.dateString.toISOString().split('T')[0];
    this.operation.due_date = this.dateString;
    this.operation.creation_date = this.dateString;

    let farm = await lastValueFrom(this.store.select(getFarm).pipe(take(1)))
    this.farm_id = farm.id;
    await this.getowner_id();

    this.operation.owner_id = this.owner.id;

    if (this.id) {
      this.operationAPI
        .getOneOperation(this.id)
        .subscribe((value: Operation) => {
          this.operation = value;
          if (this.operation.is_interest == 1) {
            this.qst = '1';
          } else {
            this.qst = '0';
          }
          if (this.operation.morphable_type.includes('Owner')) {
            this.currentSelectedFamilyMember = {
              name: this.owner.first_name,
              id: this.owner.id,
            };
            this.selectedSegmentValue = 'FamilyMember';
          } else if (this.operation.morphable_type.includes('Family')) {
            this.currentSelectedFamilyMember = value.morphable;
            this.selectedSegmentValue = 'FamilyMember';
          } else {
            this.selectedSegmentValue = 'Farm';
          }
          if (this.operation.cashflows.length > 0) {
            this.operation['payment_methods'] =
              this.operation.cashflows[0].payment_methods;
          }
        });
      this.operation?.cashflows?.find(
        (value) => value.operation_id == this.operation.id
      );
    }
  }

  async getowner_id() {
    this.familyMemberAPI.getFamilyMembers().subscribe((value) => {
      this.listf = value;
      this.listf.push({
        name: this.owner.first_name,
        id: this.owner.id,
        file: this.owner.file,
        birth_date: this.owner.birth_date,
      });
    });
  }

  async get_one_countrie() {
    let country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    this.country = country.id;
  }

  confirm_method() {
    this.submitForm();
  }

  async showErrorToast(message) {
   await this.toastUtils
      .toastBuilder(
        await this.translateService
          .get(message)
          .toPromise()
          .then((value) => value),
        'bottom',
        '',
        'danger'
      );
  }

  async submitForm() {
    if (this.qst === '0') {
      this.operation.is_interest = 0;
      this.operation.interest_amount = 0;
    }
    const token = await Storage.get({ key: dev.ACCESS_TOKEN }).then(
      (value) => value.value
    );
    if (this.selectedSegmentValue == 'Farm') {
      this.operation.morphable_type = MORPH_Farm;
      this.operation.morphable_id = this.farm_id;
    }
    if (!this.operation.amount || this.operation.amount == 0) {
      this.showErrorToast('errors.all_fields');
    } else if (!this.operation.creation_date) {
      this.showErrorToast('errors.all_fields');
    } else if (!this.operation.due_date) {
      this.showErrorToast('errors.all_fields');
    } else if (!this.operation.morphable_type) {
      this.showErrorToast('errors.all_fields');
    } else if (
      this.operation.morphable_type === MORPH_FamilyMember &&
      !this.operation.morphable_id
    ) {
      this.showErrorToast('errors.all_fields');
    } else if (!this.operation.operation_reason) {
      this.showErrorToast('errors.all_fields');
    } else if (!this.operation.third_part_name) {
      this.showErrorToast('errors.all_fields');
    } else if (!this.qst) {
      this.showErrorToast('errors.all_fields');
    } else if (this.qst === '1' && !this.operation.interest_amount) {
      this.showErrorToast('errors.all_fields');
    } else if (
      this.qst === '1' &&
      this.operation.interest_amount != undefined &&
      (this.operation.interest_amount < 0 ||
        this.operation.interest_amount > 100)
    ) {
      this.showErrorToast(
        await this.translateService
          .get('errors.operation_interest_amount_value')
          .toPromise()
          .then((value) => value)
      );
    } else {
      this.operation.operation_type = 'money loan';

      if (!this.operation.interest_amount) {
        this.operation.is_interest = 0;
        this.operation.interest_amount = 0;
      } else {
        this.operation.is_interest = 1;
      }
      if (this.selectedSegmentValue == 'Farm') {
        this.operation.morphable_type == MORPH_Farm;
        this.operation.morphable_id = this.farm_id;
      }
      this.operation.title = null;

      this.loadinModal.present();
      if (this.operation.id) {
        this.operationAPI.updateOperation(this.operation, this.id).subscribe(
          async (value: any) => {
            value.payment_methods =
              this.operation?.cashflows[0]?.payment_methods;
            value.money_reporting_id = this.operation['money_reporting_id'];
            this.operationRule.updateCashflowAfterOperationUpdate(
              value,
              this.modalController,
              this.loadinModal,
              this.popoverController,
              await this.translateService
                .get('operation_post_update')
                .toPromise()
                .then((value) => value),
              this.isDashboardView,
              []
            );
          },
          (e) => {
            this.loadinModal.dismiss();
            this.loadinModal.dismiss();
          }
        );
      } else {
        //TODO offline
        this.operationAPI
          .storeOperation(this.operation)
          .subscribe(async (value: any) => {
            value.payment_methods = this.operation['payment_methods'];
            value.money_reporting_id = this.operation['money_reporting_id'];
            this.operationRule.saveOperationCashflow(
              value,
              this.farm_id,
              this.loadinModal,
              this.modalController,
              !this.isDashboardView,
              this.popoverController,
              await this.translateService.get('auto_entry').toPromise(),
              this.isDashboardView,
              []
            );
          });
      }
    }
  }
  handelChangeCurrecy(value) {
    this.operation.currency = value;
  }
  async familyMemberSelected(ev) {
    let componentProps = {
      data: this.listf,
      component: 'mano-de-obra',
    };
    const popover = await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverImgComponent,
      'pop-over-style',
      ev
    );
    const { data } = await popover.onDidDismiss();
    if (data) {
      this.currentSelectedFamilyMember = data.data;
      if (this.currentSelectedFamilyMember.name === this.owner.first_name) {
        this.operation.morphable_type = MORPH_Owner;
      } else {
        this.operation.morphable_type = MORPH_FamilyMember;
      }

      this.operation.morphable_id = this.currentSelectedFamilyMember.id;
    }
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  setAmount(event) {
    this.operation.amount = event;
  }
  compareWith(e, e2) {
    return e && e2 ? e?.name === e2?.name : e === e2;
  }

  setPaidAmount(event) {
    this.operation.paid_so_far = event;
  }
}
