import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TeamMainViewComponent } from './team-main-view/team-main-view.component';
import { TeamMainComponent } from './team-main.component';

const routes: Routes = [
  {
    path: '',
    component: TeamMainComponent,
    children: [
      {
        path: '',
        component: TeamMainViewComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TeamRoutingModule {}
