import { MORPH_Lot } from './../utils/consts';
import { HttpClient } from '@angular/common/http';
import { dev } from '../config/endpoints.json';
import { Injectable } from '@angular/core';
import { formatDate } from '../utils/pipes-utils/date_formatter';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LotAPI {
  base_url = environment.appRoot + '/api/';

  constructor(private httpClient: HttpClient) {}

  insertLot(lot) {
     const url = this.base_url + dev.endpoint.lot.insert_lot;
    lot.last_renew = formatDate(lot?.last_renew);
    let formData = new FormData();
    formData.append(MORPH_Lot, JSON.stringify(lot));
    if (lot?.blobFile) {
      formData.append(
        'image',
        lot?.blobFile,
        new Date().getUTCMilliseconds().toString()
      );
    }
    return this.httpClient.post(url, formData);
  }

  updateLot(lot): Observable<any> {
     const url = this.base_url + dev.endpoint.lot.update_lot+'/'+lot.id;
    lot.last_renew = formatDate(lot?.last_renew);
    let formData = new FormData();
    formData.append(MORPH_Lot, JSON.stringify(lot));
    if (lot?.blobFile) {
      formData.append(
        'image',
        lot?.blobFile,
        new Date().getUTCMilliseconds().toString()
      );
    }
    return this.httpClient.post(url, formData);
    
  }

  deleteLot(id): Observable<any> {
    const url = this.base_url + dev.endpoint.lot.delete_lot + '/' + id;
    return this.httpClient.delete(url);
  }

  updateProduction(production) {
    const url = this.base_url + dev.endpoint.lot.update_production;
    let formData = new FormData();
    formData.append('id', production?.id);
    formData.append('form_answer', JSON.stringify(production.form_answer));
    if (production?.plant_genetics)
      formData.append('plant_genetics', JSON.stringify(production?.plant_genetics));
    return this.httpClient.post(url, formData);
  }

  storeLotPhoto(id, file: Blob) {
    const url = this.base_url + dev.endpoint.lot.store_lot_photo;
    const formData = new FormData();
    formData.append('image', file, new Date().getUTCMilliseconds().toString());
    formData.append('id', id);
    return this.httpClient.post(url, formData);
  }

  updateLotPhoto(id, file: Blob) {
    const url = this.base_url + dev.endpoint.lot.update_lot_photo;
    const formData = new FormData();
    formData.append('image', file, new Date().getUTCMilliseconds().toString());
    formData.append('id', id);
    return this.httpClient.post(url, formData);
  }

  getLotByFarm() {
    const url = this.base_url + dev.endpoint.lot.get_lot_by_farm;

    return this.httpClient.get(url);
  }

  sellLot(id) {
    const url = this.base_url + dev.endpoint.lot.sell_lot + '/' + id;

    return this.httpClient.get(url);
  }
  unsellLot(id) {
    const url = this.base_url + dev.endpoint.lot.unsell_lot + '/' + id;

    return this.httpClient.get(url);
  }
}
