import { RatingModalComponent } from './../../../widgets/rating-modal/rating-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { ProgramasAPI } from 'src/app/apis/programas.service';
import { Storage } from '@capacitor/storage';
import { dev } from 'src/app/config/offlines_keys.json';
import Vote from 'src/app/models/vote.model';
import { lastValueFrom } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-programmes-filter',
  templateUrl: './programmes-filter.component.html',
  styleUrls: ['./programmes-filter.component.scss'],
})
export class ProgrammesFilter implements OnInit {
  @Input() nav;
  @Input() programmes: any;
  @Input() modalController: ModalController;
  selected_programme;
  vote = new Vote();
  number_vote;
  array;
  objectif;
  selected_partner;
  country;
  progs = new Array();
  constructor(
    public appConfig: RoutingConfig,
    private secondaryController: PopoverController,
    public translateService: TranslateService,
    private programasAPI: ProgramasAPI,
    private store:Store
  ) {}

  async ngOnInit() {
    await this.parseCountryFromStorage()
    this.dataLoad();
    this.array = new Array();
  }
  async rate() {
    const modal = await this.secondaryController.create({
      component: RatingModalComponent,
      componentProps: {
        modalController: this.secondaryController,
        isFilterPrograms: true
      },
      cssClass: 'popover-custom-rating',
    });
    modal.present();
    const { data } = await modal.onWillDismiss();
    this.vote.stars_number = data?.vote;
    if (data) {
      this.array = new Array(data?.vote);
      this.number_vote = data.vote;
    }
  }
  submit() {
    this.modalController.dismiss({
      number_vote: this.number_vote ? this.number_vote : null,
      selected_programme: this.selected_programme
        ? this.selected_programme
        : null,
    });
  }
  dismiss(){
    this.modalController.dismiss();
  }


  dataLoad() {
    this.programasAPI
      .getProgramasByCountryID(this.country.id)
      .pipe(take(1))
      .subscribe((value) => {
        this.progs = value;
      });
  }

  async parseCountryFromStorage() {
   this.country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
  }
}
