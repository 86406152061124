import { CashFlow } from './cash-flow/cashFlow';

export default class Operation {
  id;
  amount;
  currency;
  is_interest;
  interest_amount;
  due_date;
  third_part_name;
  creation_date;
  title;
  operation_reason;
  operation_type;
  morphable_type;
  morphable_id;
  owner_id;
  paid_so_far = 0;
  cashflows: [CashFlow];
  morphable : Object 

}
