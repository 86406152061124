import { Component, OnInit, Input } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { TranslateService } from '@ngx-translate/core';
import { StatisticsAPI } from 'src/app/apis/statistics.service';
import { dev } from '../../../../config/offlines_keys.json';
import { all } from '../../../../config/unit.json';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { lastValueFrom, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { getDashboardData } from 'src/app/utils/states-management/actions/dashboard.action';
import { getProductKpis } from 'src/app/utils/states-management/selector/dashboard.selector';

@Component({
  selector: 'app-agricol-product-kpis',
  templateUrl: './agricol-product-kpis.component.html',
  styleUrls: ['./agricol-product-kpis.component.scss'],
})
export class AgricolProductKpisComponent implements OnInit {
  from = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  ).toISOString();
  to = new Date().toISOString();
  lang;
  currency;
  coffe_unit_label;
  coffe_unit;
  coffe_rendement;
  missingLotDefinition;
  prod_cost;
  total_coffe;
  other_prod: any[] = new Array();
  @Input() label;

  customPickerOptionsStart = {
    buttons: [
      {
        text: 'cancel',
        cssClass: 'cancel-btn',

        handler: (value) => {
          return true;
        },
      },
      {
        text: this.translateService.get('submit').subscribe((value) => value),
        cssClass: 'confirm-btn',
        handler: (value) => {
          this.from = new Date(
            value.year.value + '-' + value.month.value + '-' + value.day.value
          ).toISOString();

          return true;
        },
      },
    ],
  };
  customPickerOptionsEnd = {
    buttons: [
      {
        text: this.translateService.get('cancel').subscribe((value) => value),
        cssClass: 'cancel-btn',

        handler: (value) => {
          return true;
        },
      },
      {
        text: this.translateService.get('submit').subscribe((value) => value),
        cssClass: 'confirm-btn',
        handler: (value) => {
          this.to = new Date(
            value.year.value + '-' + value.month.value + '-' + value.day.value
          ).toISOString();

          return true;
        },
      },
    ],
  };
  constructor(
    private statisticsAPI: StatisticsAPI,
    public translateService: TranslateService,
    private store:Store
  ) {}

  async ngOnInit() {
    const country = await lastValueFrom(this.store.select(getCountry).pipe(take(1)));
    if (country && 'currency' in country) {
      this.currency = country.currency;
    }
    this.getProductKPI(this.from, this.to);
    
  }

  getProductKPI(from, to) {
    // TODO: offline
    // let data = { startDate: from, endDate: to };
    // this.store.dispatch(getDashboardData({ data :data }));
    // this.store.select(getProductKpis(from, to)).subscribe(
    //   (value) => {
    //     this.mapProduct(value);
    //   },
    //   (e) => console.log(e)
    // );
    this.statisticsAPI.getProductKpis(from, to).subscribe((value) => {
      this.mapProduct(value);
    });
  }
  filter() {
    this.getProductKPI(this.from, this.to);
  }

  mapProduct(val) {
    this.other_prod = val?.sort((a, b) => b.neto - a.neto);
  }
}
