import { QuestionsUtils } from './../../../../utils/dynamic-forms/question_utils';
import {
  MORPH_Category,
  MORPH_Product,
  MORPH_MaterialCategorie,
  MORPH_Lot,
} from '../../../../utils/consts';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { ModalController, PopoverController } from '@ionic/angular';
import { LotsCreatorComponent } from '../../lots-components/lots-creator/lots-creator.component';
import { PorductionEditorComponent } from '../../others-agricol-product-components/porduction-editor/porduction-editor.component';
import { dev } from '../../../../config/offlines_keys.json';
import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import { CashFlow } from 'src/app/models/cash-flow/cashFlow';
import {
  PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY,
  SELL_AGRICOLAS_LOT_CASHFLOW_CATEGORIE,
  SELL_OTRO_USO_LOT_CASHFLOW_CATEGORIE,
} from 'src/app/utils/consts';
import { showConfirmModal } from 'src/app/utils/pop_over_maker';

import { USAGE_TYPE } from 'src/app/models/lots.model';
import { AnimationUtils } from 'src/app/utils/animations';
import { TranslateService } from '@ngx-translate/core';
import { LotAPI } from 'src/app/apis/lot.service';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { FormGroup } from '@angular/forms';
import { UnifiedCashflowInputComponentComponent } from 'src/app/modules/cashflow/unified-cashflow-input-component/unified-cashflow-input-component.component';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { deletePlot } from 'src/app/utils/states-management/actions/plots.action';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { take } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';
import { loadFarm } from 'src/app/utils/states-management/actions/farm.action';
import { Farm } from 'src/app/models/farm/farm.model';

@Component({
  selector: 'app-lot-single-entry',
  templateUrl: './lot-single-entry.component.html',
  styleUrls: ['./lot-single-entry.component.scss'],
})
export class LotSingleEntryComponent implements OnInit {
  @Input() lot;
  @Input() isDashboardView;
  @Output() relodNeeded: EventEmitter<void> = new EventEmitter();
  @Input() add_button;
  @Input() used_for;
  @Output() hasFilledAllCafeDetailsEvent: EventEmitter<boolean> =
    new EventEmitter();
  form: FormGroup;
  modal;
  MORPH_Category = MORPH_Category;
  MORPH_Product = MORPH_Product;
  MORPH_MaterialCategorie = MORPH_MaterialCategorie;
  highlightCrop;
  base_url = environment.appRoot;

  constructor(
    private modalController: ModalController,
    private popover: PopoverController,
    private animationUtils: AnimationUtils,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private lotAPI: LotAPI,
    private routingConfig: RoutingConfig,
    private store: Store<{ plots: any, farm: Farm }>
  ) {}

  async ngOnInit() {
    this.checkChanges();
    const cropConfig = await this.routingConfig.getCropFormConfig();
    this.lot.productions.forEach((production, index) => {
      if (
        (production.morphable_type == MORPH_Product &&
          cropConfig.crop_entity.includes('product') &&
          cropConfig.crop_id.includes(production.morphable_id)) ||
        (cropConfig.material_category_crop_id &&
          cropConfig.material_category_crop_id.includes(
            production.morphable_id
          ) &&
          production.is_animal)
      ) {
        let value = production;
        this.lot.productions.splice(index, 1);
        value.is_crop = true;
        this.lot.productions.unshift(value);
      }
    });
  }

  checkChanges() {
    this.lot.productions.forEach((element) => {
      if (element.form_answer) {
        if (typeof element.form_answer != 'object')
          element.form_answer = JSON.parse(element.form_answer);
        let form_answer = element.form_answer;

        element.is_fully_filled = true;
        for (const [key, value] of Object.entries(form_answer)) {
          if (
            (form_answer.is_productive &&
              (!form_answer.last_renew ||
                !form_answer.last_renew ||
                // form_answer.shadow_per_cent == null ||
                // form_answer.shadow_per_cent == undefined ||
                // form_answer.percent_older_25 == null ||
                // form_answer.percent_older_25 == undefined ||
                !form_answer.last_renew_reason ||
                !form_answer.coffee_trees_number ||
                !form_answer.distance_between_plants )) ||
            (!form_answer.is_productive &&
              (!form_answer.coffee_trees_number ||
                !form_answer.distance_between_plants ||
                !form_answer.type_of_area))
          ) {
            element.is_fully_filled = false;
          }
        }
      }
    });
  }

  async selectedLot(lot,used_for) {
    let componentProps = {
      used_for: used_for,
      lot: lot,
      modalMain: this.modalController,
      title: this.add_button,
      title_header: await this.translateService
      .instant('titles.lot_creator_title_map'),

    };
    let modal = await this.toastUtils.modalBuilder(
      LotsCreatorComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper']
    );

    const { data } = await modal.onWillDismiss();
    if (data?.value) this.lot = data.value;
    this.store.dispatch(loadFarm());

    this.relodNeeded.emit();
  }

  async delete(lot) {
    const msg = await this.translateService.get('label.delete').toPromise();
    let modal = await showConfirmModal(
      this.popover,
      msg,
      null,
      await this.translateService.get('confirm').toPromise(),
      await this.translateService.get('cancel').toPromise()
    );
    const { data } = await modal.onWillDismiss();
    if (data?.confirmed) {
      this.store.dispatch(deletePlot({ lot: lot }));
      // this.lotAPI.deleteLot(lot.id).subscribe((val) => {
      //   val;
      //   this.removeFromLocalStorage(lot);
      // });
    }
    this.store.dispatch(loadFarm());

  }

  // async removeFromLocalStorage(lot) {
  //   const lot_full_list = await Storage.get({
  //     key: dev.LOT_KEY,
  //   }).then((val) => JSON.parse(val.value));

  //   lot_full_list.forEach((element, index, array) => {
  //     if (element.id === lot.id) {
  //       lot_full_list.splice(index, 1);
  //     }
  //   });
  //   await Storage.set({
  //     key: dev.LOT_KEY,
  //     value: JSON.stringify(lot_full_list),
  //   });
  //   // this.relodNeeded.emit();
  // }

  async itemClicked(item) {
    if (!item.is_expanded) {
      await this.animationUtils.expandAnimation(item);
      item.is_expanded = true;
    } else {
      await this.animationUtils.collapsAnimation(item);
      item.is_expanded = false;
    }
  }

  async editProduction(porudction, lot) {
    let componentProps = {
      production: porudction,
      isDashboardView: this.isDashboardView,
      modalController: this.modalController,
      production_superficie:porudction.used_surface,
    };
    let modal = await this.toastUtils.modalBuilder(
      PorductionEditorComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper']
    );

    await modal.onWillDismiss().then(async (data: any) => {
      if (data.prod) {
        porudction = data.prod;
        this.checkChanges();
      }
      await this.updateLocalStorage(porudction, lot);

    });
  }

  async updateLocalStorage(porudction, lot) {
    lot.productions.forEach((element, index) => {
      if (element.id === porudction.id) {
        lot.productions[index] = porudction;
      }
    });
    const lots = JSON.parse((await Storage.get({ key: dev.LOT_KEY })).value);

    lots.forEach((element, index) => {
      if (element.id === lot.id) {
        lots[index] = lot;
      }
    });
    await Storage.set({ key: dev.LOT_KEY, value: JSON.stringify(lots) });
    this.ngOnInit();
  }

  async showPopOver(message) {
    const display_message = await this.translateService
      .get(message)
      .toPromise()
      .then((value) => value);
    const popover = await this.popover.create({
      component: PopoverComponent,
      cssClass: ['popover-custom'],

      animated: true,
      componentProps: { key1: display_message },
    });
    popover.present();
    await popover.onWillDismiss();
  }

  async cashflowInsertion(): Promise<Boolean> {
    const msg = await this.translateService
      .get('add_cashflow_entry')
      .toPromise();
    if (this.isDashboardView) {
      const modal = await showConfirmModal(this.popover, msg, null, 'Sí', 'No');
      const { data } = await modal.onWillDismiss();
      return data?.confirmed;
    } else return false;
  }

  async initBuyCashflow(categories, id): Promise<CashFlow> {
    const cashflow = new CashFlow();
    cashflow.morphable_id = id;
    cashflow.morphable_type = MORPH_Lot;

    cashflow.accounting_entries_categories =
      PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY;
    cashflow.cash_flow_categorie_id =
      this.used_for === USAGE_TYPE.agricol
        ? SELL_AGRICOLAS_LOT_CASHFLOW_CATEGORIE
        : SELL_OTRO_USO_LOT_CASHFLOW_CATEGORIE;

    categories.forEach((element) => {
      if (Array.isArray(element.cash_flow_sub_category))
        element.cash_flow_sub_category.forEach((ele) => {
          if (ele.id === cashflow.cash_flow_categorie_id)
            cashflow.cashflow_categories = ele;
        });
    });
    cashflow.is_income = 0;
    cashflow.quantity = 1;
    // cashflow.unity = 'unit';
    let country=await lastValueFrom(this.store.select(getCountry).pipe(take(1)))
    cashflow.payment_methods = 'cash';
    cashflow.currency = country.currency
    let farm =await lastValueFrom(this.store.select(getFarm).pipe(take(1)))
    cashflow.farm_id = farm.id
    cashflow.date = new Date().toISOString();

    return cashflow;
  }

  async showRelatedPopover(id) {
    const cashFlowCategFiltred = await Storage.get({
      key: dev.CASHFLOW_CATEGORIES,
    }).then((value) =>
      JSON.parse(value.value).find(
        (element) => element.id == PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY
      )
    );

    let componentProps = {
      cashFlow: await this.initBuyCashflow(cashFlowCategFiltred, id),
      accountingEntryCategories: cashFlowCategFiltred,
      modalController: this.modalController,
      no_post_submit_action: true,
    };
    let modal = await this.toastUtils.modalBuilder(
      UnifiedCashflowInputComponentComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper'],
      true,
      '2'
    );

    await modal.onWillDismiss();
    this.relodNeeded.emit();
  }
}
