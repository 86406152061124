import { RoutingConfig } from 'src/app/utils/routing-config';
import { OfflineSyncService } from 'src/app/apis/offline-sync.service';
import { Component, OnInit } from '@angular/core';
import { dev } from '../../../config/offlines_keys.json';
import { Storage } from '@capacitor/storage';
import { Router } from '@angular/router';
@Component({
  selector: 'app-pre-prog',
  templateUrl: './pre-prog.component.html',
  styleUrls: ['./pre-prog.component.scss'],
})
export class PreProgComponent implements OnInit {
  constructor(
    private router: Router,
    private routingConfig: RoutingConfig
  ) {}

  async ngOnInit() {
  }
  async return() {
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());  
  }
  async next() {
    this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }
}
