import { RoutingConfig } from 'src/app/utils/routing-config';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, Input, OnInit } from '@angular/core';
import { GoodsAPI } from 'src/app/apis/goods.service';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../config/offlines_keys.json';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { MaterialGoodFormComponent } from '../material-good-form/material-good-form.component';
import { PopoverComponent } from '../../../widgets/popover/popover.component';
import routes from '../../../config/routing.json';
import { PriorDashboardComponent } from '../../prior-dashboard/prior-dashboard.component';
import { TranslateService } from '@ngx-translate/core';
import Owner from 'src/app/models/owner.model';

@Component({
  selector: 'app-material-goods',
  templateUrl: './material-goods.component.html',
  styleUrls: ['./material-goods.component.scss'],
})
export class MaterialGoodsComponent implements OnInit {
  is_list_view = true;
  productList: Array<any> = [];
  @Input() isDashboardView;
  @Input() nav;
  @Input() rootStack;
  owner: Owner;
  constructor(
    private goodsAPI: GoodsAPI,
    private router: Router,
    private modalController: ModalController,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private routingConfig: RoutingConfig
  ) {}

  async ngOnInit() {
    this.owner = await JSON.parse(
      (
        await Storage.get({ key: dev.USER_KEY })
      ).value
    );

    this.fetchData();
    this.showPopOver(await this.translateService.get('infobul').toPromise());
  }
  async showPopOver(msg) {
    let componentProps = { key1: msg };

    await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
  }

  async addProduct() {
    let modal = await this.toastUtils.modalBuilder(
      MaterialGoodFormComponent,
      { modalControllerMain: this.modalController },
      ['my-custom-modal-css', 'modal-wrapper'],
      true,
      '1'
    );
    await modal.onWillDismiss();

    this.fetchData();
  }
  fetchDataReciver() {
    this.fetchData();
  }

  async fetchData() {
    this.goodsAPI.getMaterialPropreties().subscribe((val: any) => {
      this.productList = val;
    });
  }

  async deleteChange(item) {
    this.goodsAPI.deleteGood(item.id).subscribe((value) => {
      this.fetchData();
    });
  }

  async storeProductinDb() {
    await Storage.set({
      key: dev.MATERIAL_PRODUCT,
      value: JSON.stringify(this.productList),
    });
  }

  async redirect() {
    this.storeProductinDb();
    this.router.navigateByUrl(await this.routingConfig.getNextUrl());
  }

  async mreturn() {
    this.storeProductinDb();
    this.router.navigateByUrl(await this.routingConfig.getBackUrl());
  }

  goBackToDashBoard() {
    this.nav.setRoot(this.rootStack, {
      nav: this.nav,
      rootStack: PriorDashboardComponent,
    });
  }
}
