interface FomrConfig {
  is_qty_editable: boolean;
  is_unit_editable: boolean;
}

export class Good {
  id: number;
  qty: number;
  used_qty: number;
  used_coffe_id: number;
  unit: String;
  production_id: bigint;
  coffee_variety: string;
  material_product_id: number;
  product_id: number;
  owner_id: number;
  country;
  production_per_farm_id: number;
  displayable_unit: any;
  product;
  qty_update;
  material_product;

  formConfig: FomrConfig = {
    is_qty_editable: false,
    is_unit_editable: false,
  };

  constructor() {}
}
