import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MoneyReportingsAPI } from 'src/app/apis/money_reportings.service';
import { ModalBankAccountComponent } from 'src/app/modules/goods/modal-bank-account/modal-bank-account.component';
@Component({
  selector: 'app-transfer-money-reporting-widget',
  templateUrl: './transfer-money-reporting-widget.component.html',
  styleUrls: ['./transfer-money-reporting-widget.component.scss'],
})
export class TransferMoneyReportingWidgetComponent implements OnInit {
  @Input() cashflow;
  @Output() cashflowChange: EventEmitter<any> = new EventEmitter();
  selected_account_type;
  currentAccountsList;
  inverstmentAccountList;
  savingsAccountsList;
  selected_bank_account;
  owner;
  token;
  constructor(
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private moneyReportingAPI: MoneyReportingsAPI
  ) {}

  async ngOnInit() {
    await this.getBanksAccounts();
    if (!this.cashflow.id) this.cashflow.payment_methods = 'bank_transfer';
    else if (this.cashflow.id) {
      this.selected_account_type = await this.translateService
        .get('accounts')
        .toPromise()
        .then((value) =>
          value.accounts.find(
            (element) => element.value == this.cashflow.money_reporting.type
          )
        );
    }
    this.cashflowChange.emit(this.cashflow);
  }

  accountTypeSelectChange(value) {
    this.cashflow.money_reporting_id = value.id;
    this.cashflowChange.emit(this.cashflow);
  }

  async getBanksAccounts() {
    this.moneyReportingAPI.getMoneyReporting().subscribe((value) => {
      this.currentAccountsList = value.filter((e) =>
        e.type.includes('current_account')
      );
      this.inverstmentAccountList = value.filter((e) =>
        e.type.includes('investment_account')
      );
      this.savingsAccountsList = value.filter((e) =>
        e.type.includes('savings_account')
      );
      this.selected_bank_account = value.find(
        (element) => element?.id === this.cashflow.money_reporting?.id
      );
    });
  }
  async addAccount() {
    let componentProps = {
      bank_type: this.selected_account_type.value,
    };
    let modal = await this.toastUtils.modalBuilder(
      ModalBankAccountComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper']
    );

    const { data } = await modal.onWillDismiss();
    if (this.selected_account_type.value === 'current_account') {
      this.currentAccountsList.push(data?.account);
    } else if (this.selected_account_type.value === 'savings_account') {
      this.savingsAccountsList.push(data?.account);
    } else if (this.selected_account_type.value === 'investment_account') {
      this.inverstmentAccountList.push(data?.account);
    }
    this.selected_bank_account = data?.account;
    this.accountTypeSelectChange(data?.account);
  }
}
