import { dev } from '../config/endpoints.json';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LicensePolicyAPI {
  main_endpoint = environment.appRoot + '/api/';
  constructor(private httpClient: HttpClient) {}

  getLicenseAgreement(language): Observable<any> {
    const url = this.main_endpoint + dev.endpoint.license_policy.license_agreement + '/' + language;
    return this.httpClient.get(url);
  }
  getPrivacyPolicy(language): Observable<any> {
    return this.httpClient.get(
      this.main_endpoint + dev.endpoint.license_policy.privacy_policy + '/' + language
    );
  }

}
