import {
  MORPH_Farm,
  MORPH_FamilyMember,
  MORPH_Owner,
} from './../../../../utils/consts';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Storage } from '@capacitor/storage';
import { LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { OperationsAPI } from 'src/app/apis/operations.service';

import Operation from 'src/app/models/operation.model';
import {
  MONEY_LOAN_OPERATION,
  PERSONAL_ACCOUNTING_ENTRY_CATEGORY,
} from 'src/app/utils/consts';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { dev } from '../../../../config/offlines_keys.json';
import { PickerOptionFormater } from 'src/app/utils/pipes-utils/date_formatter';
import { CashflowAPI } from 'src/app/apis/cashflow.service';
import { OperationRelatedToCashflowBusinessRules } from 'src/app/utils/business-rules/operations_related_to_cashflow_business_rules';
import { lastValueFrom, take } from 'rxjs';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-operation-view',
  templateUrl: './operation-view.component.html',
  styleUrls: ['./operation-view.component.scss'],
})
export class OperationViewComponent implements OnInit, OnChanges {
  @Input() cashflow;
  @Input() operationType;
  @Output() cashflowChanged: EventEmitter<any> = new EventEmitter();
  @Input() modalController: ModalController;
  loadinModal;
  operation = new Operation();
  qst;
  monthNames = [];
  currentYear;
  dateString;

  customPickerOptions_due_date;

  constructor(
    private loadingCtrl: LoadingController,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private pickerOption: PickerOptionFormater,
    private operationAPI: OperationsAPI,
    private cashflowAPI: CashflowAPI,
    private operationRule: OperationRelatedToCashflowBusinessRules,
    private store:Store
  ) {
  }

  async ngOnInit() {
    this.customPickerOptions_due_date = this.pickerOption.custom_picker_options(
      this.operation,
      'due_date'
    );
    this.loadinModal = await this.loadingCtrl.create({ spinner: 'bubbles',
      mode: 'ios',
    });
    const owner = await Storage.get({ key: dev.USER_KEY }).then((value) =>
      JSON.parse(value.value)
    );

    if (this.cashflow.operation_id && this.cashflow?.operation?.id) {
      this.operation = this.cashflow.operation;
    } else {
      this.currentYear = new Date();

      this.dateString = new Date(
        this.currentYear.getTime() -
          this.currentYear.getTimezoneOffset() * 60000
      );
      this.dateString = this.dateString.toISOString().split('T')[0];
      this.operation.creation_date = this.dateString;

      this.operation.due_date = this.dateString;
    }
    if (
      this.cashflow.accounting_entries_categories ===
      PERSONAL_ACCOUNTING_ENTRY_CATEGORY
    ) {
      if (this.cashflow.morphable_type === 'Owner')
        this.operation.morphable_type = MORPH_Owner;
      else this.operation.morphable_type = MORPH_FamilyMember;
      this.operation.morphable_id = this.cashflow.morphable_id;
    } else {

            const farm = await lastValueFrom(
        this.store.select(getFarm).pipe(take(1))
      );

      this.operation.morphable_id = farm.id;
      this.operation.morphable_type = MORPH_Farm;
    }
    this.operation.currency = this.cashflow.currency;
    this.operation.currency = this.cashflow.currency;
    this.operation.owner_id = owner.id;
    this.operation.operation_type = MONEY_LOAN_OPERATION;
    if (!this.operation.id && this.cashflow)
      this.operation.creation_date = this.cashflow.date;
  }

  ngOnChanges(changes: SimpleChanges): void {}
  handelChangeCurrecy(value) {
    this.operation.currency = value;
    this.cashflow.currency = value;
  }

  changed(event) {
    if (this.cashflow.id) {
      this.cashflow.amount = this.operation.amount;
      this.cashflowChanged.emit(this.cashflow);
    }

    if (!this.modalController && !this.operation.id) {
      this.cashflow.new_operation = this.operation;
      this.cashflowChanged.emit(this.cashflow);
    } else if (!this.modalController && this.operation.id) {
      this.cashflow.operation = this.operation;
      this.cashflowChanged.emit(this.cashflow);
    }
  }

  paymentChange(event) {
    this.cashflow.payment_methods = event.payment_methods;
    this.cashflow.money_reporting_id = event.money_reporting_id;
    this.cashflowChanged.emit(this.cashflow);
  }

  setAmount(amount) {
    this.operation.amount = amount;
    this.changed(null);
  }

  dismiss() {
    this.modalController.dismiss();
  }

  async confirm_method() {
    if (
      this.operation.amount &&
      this.operation.third_part_name &&
      this.operation.operation_reason
    ) {
      this.loadinModal.present();
      //TODO offlines
      this.operationAPI.storeOperation(this.operation).subscribe(
        (value: Operation) => {
          this.operationRule.saveCashFlow(
            value,
            this.cashflow,
            this.cashflowAPI,
            this.loadinModal,
            this.modalController
          );
        },
        (e) => {
          this.modalController.dismiss();
        }
      );
    } else
      await this.toastUtils
        .toastBuilder(
          await this.translateService.instant('errors.all_fields '),
          '',
          ''
        );
  }
}
