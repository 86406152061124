import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastUtils } from '../../toast_builder';
import { CashflowAPI } from 'src/app/apis/cashflow.service';
import {
  deleteSales,
  paginateSales,
  paginateSalesSuccess,
} from '../actions/product-sale.action';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PaginationResult } from 'src/app/models/pagination-result.model';
import { ProductSale } from 'src/app/models/cash-flow/sale.model';
import { of } from 'rxjs';
import { dataSyncWithServerError } from '../actions/owner.action';
import {
  GetRealGoodTransactionValues,
  getRealGoodTransactionValues,
} from '../../unit-convert';

@Injectable()
export class ProductSaleEffect {
  paginateSales$ = createEffect(() =>
    this.actions.pipe(
      ofType(paginateSales),
      switchMap((action) => {
        return this.cashflowAPI
          .getProductSells(
            action.is_animal,
            action.ids,
            action.page_size,
            action.start_date,
            action.end_date,
            action.page
          )
          .pipe(
            map((values: PaginationResult<ProductSale>) => {
              values.data = values.data.map((element) => {
                element.converted_value =
                  this.getRealGoodTransactionValues.transform(
                    element.quantity,
                    element.unity,
                    5
                  );
                return element;
              });
              return values;
            }),
            map((values: PaginationResult<ProductSale>) =>
              paginateSalesSuccess({ paginationResult: values })
            ),
            // catchError((err) => of(dataSyncWithServerError(err)))
          );
      })
    )
  );

  deleteSales$ = createEffect(() =>
    this.actions.pipe(
      ofType(deleteSales),
      switchMap((action) =>
        this.cashflowAPI.deleteMultipleCashflows(action.ids).pipe(
          map(
            (values) => paginateSales(action.filter),
            // catchError((err) => of(dataSyncWithServerError(err)))
          )
        )
      )
    )
  );

  constructor(
    private actions: Actions,
    private cashflowAPI: CashflowAPI,
    private toastUtils: ToastUtils,
    public translateService: TranslateService,
    private router: Router,
    public getRealGoodTransactionValues: GetRealGoodTransactionValues
  ) {}
}
