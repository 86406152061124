import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { dev } from '../../../../config/offlines_keys.json';
import { Storage } from '@capacitor/storage';
import { Chart } from 'chart.js';
import { GRAPH_COLORS } from 'src/app/utils/consts';
import { StatisticsAPI } from 'src/app/apis/statistics.service';
import { ModalController } from '@ionic/angular';
import { all } from '../../../../config/unit.json';
import { getRealGoodTransactionValues } from 'src/app/utils/unit-convert';
import { UserInfo } from 'src/app/apis/users-info.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';
import { ProductionAPI } from 'src/app/apis/FarmAPI/productions.service';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-production-perdidas-products-stats',
  templateUrl: './production-perdidas-products-stats.component.html',
  styleUrls: ['./production-perdidas-products-stats.component.scss'],
})
export class ProductionPerdidasProductsStatsComponent implements OnInit {
  selecteProductResult: any[];
  @Input() selectedProd;
  @Input() farm;
  @Input() id;
  @Input() token;
  productsAgricol: Array<ProductionsPerfarm> = new Array();
  selectedCategorie;
  barChart;
  title;
  formatedValues;
  table_view = 1;
  currentWorkingUnit;
  bigTotal;
  lang;
  @ViewChild('barCanvas', { read: ElementRef }) barCanvas: ElementRef;

  constructor(
    private modalController: ModalController,
    private userInf: UserInfo,
    public translateService: TranslateService,
    private productionAPI: ProductionAPI,
    private statisticsAPI: StatisticsAPI,
    private store:Store
  ) {}

  async ngOnInit() {
    await this.showProduction();
    this.lang = await this.userInf.getSelectedLangage();
    await this.switchCaseTitle();
  }
  async showProduction() {
    this.productionAPI
      .getAllFarmProduction()
      .toPromise()
      .then((products: Array<ProductionsPerfarm>) => {
        this.productsAgricol =
          this.id == 7
            ? products?.filter(
                (element) => ![16, 17, 18].includes(element.morphable_id)
              )
            : products?.filter(
                (element) =>
                  [16, 17, 18].includes(element.morphable_id) &&
                  !element.is_animal
              );
      });
    if (this.selecteProductResult) {
      this.selectedAnimalProduct(this.selecteProductResult);
    }
  }

  selectedAnimalProduct(val) {
    this.table_view = 0;
    if (this.selectedCategorie.is_animal) {
      this.formatValueForDisplay(val?.value);
    }
  }

  async sortArray() {
    if (!this.lang) this.lang = await this.userInf.getSelectedLangage();
    this.lang === 'es'
      ? this.productsAgricol?.sort((a, b) => {
          if (
            a.morphable.normalize('NFD') > b.morphable.name.normalize('NFD')
          ) {
            return 1;
          }
          if (a.morphable.normalize('NFD') < b.morphable.normalize('NFD')) {
            return -1;
          }
          return 0;
        })
      : this.productsAgricol?.sort((a, b) => {
          if (
            a.morphable['name_' + this.lang]?.normalize('NFD') >
            b.morphable['name_' + this.lang]?.normalize('NFD')
          ) {
            return 1;
          }
          if (
            a.morphable['name_' + this.lang]?.normalize('NFD') <
            b.morphable['name_' + this.lang]?.normalize('NFD')
          ) {
            return -1;
          }
          return 0;
        });
  }

  async barChartBrowser(datas, is_prod, unit) {
    let colors = [];
    let data = [];
    let labels = [];
    if (this.barChart) this.barChart.destroy();
    if (!datas) return;

    datas.forEach((element) => {
      data.push(element.quantity);
      labels.push(element.date);

      colors.push(this.getRandomColor());
    });
    var label = is_prod
      ? await this.translateService
          .get('production')
          .toPromise()
          .then((value) => value)
      : await this.translateService
          .get('loss')
          .toPromise()
          .then((value) => value);
    if (unit) {
      let u;
      this.lang === 'es' ? (u = unit.label) : (u = unit['label_' + this.lang]);
      label += ' ' + u;
    }
    datas.reverse();
    labels.reverse();
    this.barChart = new Chart(this.barCanvas.nativeElement, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            backgroundColor: colors,
            data: data,
            label: label,
            hoverBackgroundColor: colors,
            hoverBorderWidth: 4,
            hoverBorderColor: ['black'],
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: true,
            position: 'top',
            title: {
              text: label,
            },
          },
        },
        aspectRatio: 1,
      },
    });
    this.barChart.canvas.parentNode.style.height = '100%';
    this.barChart.canvas.parentNode.style.width = '100%';
  }

  async porductSelected(value: ProductionsPerfarm) {
    this.table_view = 0;
    this.currentWorkingUnit = all.find((element) => {
      if (value.goods && value.goods.length > 0)
        return element.value === value.goods[0].unit;
      else return element.value === 'unit';
    });
    if (!this.farm)
      this.farm = await this.store
      .select(getFarm)
      .pipe(take(1))
      .toPromise();
    this.statisticsAPI
      .getCashflowProductionPerdidasByProduct(
        value.morphable_id,
        value.is_animal,
        this.translateService.currentLang
      )
      .subscribe((response: any) => {
        this.selecteProductResult = response;
        this.formatedValues = new Array();
        this.bigTotal = 0;
        if (Array.isArray(response) && response.length > 0)
          if (!value.is_animal) this.formatValueForDisplay(response);
      });
  }

  getRandomColor() {
    const color = GRAPH_COLORS[Math.floor(Math.random() * 12)];
    return color;
  }
  dismiss() {
    this.modalController.dismiss();
  }

  async switchCaseTitle() {
    this.title =
      this.selectedProd === 1
        ? await this.translateService
            .get('production')
            .toPromise()
            .then((value) => value)
        : await this.translateService
            .get('loss')
            .toPromise()
            .then((value) => value);
  }

  formatValueForDisplay(val) {
    var keys;
    if (val[0])
      var values = this.selectedProd === 1 ? val[0]['loss'] : val[0]['prod'];
    else var values = this.selectedProd === 1 ? val['loss'] : val['prod'];

    keys = Object.keys(values);
    this.bigTotal = 0;
    this.formatedValues = new Array();
    keys.forEach((date) => {
      let total = 0;
      let total_not_converted = 0;
      let lastunit = null;
      values[date].forEach((entry) => {
        if (lastunit === null) lastunit = entry.unity;
        total_not_converted += parseFloat(
          getRealGoodTransactionValues(lastunit, entry.unity, entry.quantity)
        );
        total += parseFloat(
          getRealGoodTransactionValues(
            this.currentWorkingUnit?.value,
            entry.unity,
            entry.quantity
          )
        );
      });

      this.formatedValues.push({
        date: date,
        quantity: total,
        total_not_converted: total_not_converted,
        unit: lastunit,
      });
      this.bigTotal += total;
    });
    this.formatedValues = this.formatedValues.map((element) => {
      element.percent = ((element.quantity / this.bigTotal) * 100).toFixed(2);
      return element;
    });
  }

  async prepareChart(val) {
    this.table_view = val;
    if (this.formatedValues && this.table_view == 2) {
      setTimeout(
        async () =>
          await this.barChartBrowser(
            this.formatedValues,
            this.selectedProd,
            this.currentWorkingUnit
          )
      );
    }
  }
}
