import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProductionsPerfarm } from 'src/app/models/farm/production_per_farm';
import { Good } from 'src/app/models/good.model';
import { AnimationUtils } from 'src/app/utils/animations';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { getDisplayableUnit } from 'src/app/utils/unit-convert';
import { environment } from 'src/environments/environment';
import {
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-products-cards',
  templateUrl: './products-cards.component.html',
  styleUrls: ['./products-cards.component.scss'],
})
export class ProductsCardsComponent implements OnInit {
  @Input() productList;
  @Input() selectionEnabled;
  @Output() animalUpdateOuput: EventEmitter<any> = new EventEmitter();
  @Output() animalDeleteOutput: EventEmitter<any> = new EventEmitter();
  @Output() animalDetailOutput: EventEmitter<any> = new EventEmitter();
  @Input() isDashboardView;
  @Input() enableSelection = false;
  @Input() is_selector = false;

  @Input() lang;
  formConfig;
  animalList;
  comment;
  base_url = environment.appRoot;

  constructor(
    private animationUtils: AnimationUtils,
    public routerConfig: RoutingConfig,
    public translateService: TranslateService
  ) {}

  async ngOnInit() {
    this.lang = this.translateService.currentLang;
    let ClassName = 'AnimalesListComponent';
    this.formConfig = await this.routerConfig.getPageFormConfig(ClassName);
  }

  async expand(item, id) {
    let expandable = { id: id };
    if (item.is_expanded) {
      await this.animationUtils.expandAnimation(expandable);
      item.is_expanded = false;
    } else {
      await this.animationUtils.collapsAnimation(expandable);
      item.is_expanded = true;
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.productList) {
      this.productList = this.productList.map(
        (production: ProductionsPerfarm) => {
          production.goods = production.goods.map((good: Good) => {
            good.displayable_unit = getDisplayableUnit(good.unit);
            return good;
          });
          return production;
        }
      );
    }
  }

  deleteClicked(item, product) {
    this.animalDeleteOutput.emit({ item: item, product: product });
  }

  update(categorie) {
    if (this.comment && categorie === this.comment.comment)
      this.lang === 'es'
        ? (categorie = 'Otros animals')
        : (categorie = 'Other animals');
    this.animalUpdateOuput.emit({
      entry: categorie,
    });
  }

  addProduct(categorie, product) {
    this.animalUpdateOuput.emit({
      title: categorie.title,
      name: product.name,
      qty: 0,
      unit: '',
      material_product_id: product.material_product.id,
      comment: null,
    });
  }

  details(item) {
    item.is_animal = true;
    this.animalDetailOutput.emit(item);
  }

  selectProduct(product) {
    product.is_checked = !product.is_checked;
  }

  getCategorieLength(categorie) {
    if (categorie?.morphable?.name) {
      return this.translateService.currentLang === 'es'
        ? categorie.morphable.name.length
        : categorie.morphable['name_' + this.translateService.currentLang]
            .length;
    } else {
      return this.translateService.currentLang === 'es'
        ? categorie.morphable.title.length
        : categorie.morphable['title_' + this.translateService.currentLang]
            .length;
    }
  }
}
