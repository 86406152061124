import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, Input, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../../config/offlines_keys.json';
import { CashflowAPI } from 'src/app/apis/cashflow.service';
import { LoadingController, IonNav } from '@ionic/angular';
import { StatisticsAPI } from 'src/app/apis/statistics.service';
import { AccountingEntryCategorieStatsComponent } from '../../widgets/accounting-entry-categorie-stats/accounting-entry-categorie-stats.component';
import { ProductionPerdidasProductsStatsComponent } from '../../widgets/production-perdidas-products-stats/production-perdidas-products-stats.component';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { getFarm } from 'src/app/utils/states-management/selector/farm-selector';
import { Store } from '@ngrx/store';
import { getCountry } from 'src/app/utils/states-management/selector/countries.selector';

// TODO: export this interface and inherit it in all used files
interface FormConfig {
  is_egresos_needed: boolean;
  is_ingresos_needed: boolean;
  is_production_needed: boolean;
  is_losses_needed: boolean;
  is_transfer_needed: boolean;
  is_activities_showable: boolean;
  is_show_partner: boolean;
  is_show_products_categories: boolean;
  is_show_type_transaction_button: boolean;
}

@Component({
  selector: 'app-cashflow-signle-entry-summary',
  templateUrl: './cashflow-signle-entry-summary.component.html',
  styleUrls: ['./cashflow-signle-entry-summary.component.scss'],
})
export class CashflowSignleEntrySummaryComponent implements OnInit {
  @Input() name_en = 'coffee';
  @Input() id;
  @Input() name;
  @Input() summary;
  @Input() iconPath;
  @Input() nav: IonNav;

  productsAgricol;
  cashflowAPI: CashflowAPI;
  income_entries: any = [];
  expenses_entries: any = [];
  displayableCategoriesIngresos: any[];
  displayableCategoriesEgresos: any[];
  displayableProduccion: any[];
  displayablePerdidas: any[];
  barChart;
  currency;
  showExpenses = 0;
  isTableView = 1;
  laodingModal;
  token;
  farm;
  selectedCategorie;
  byMorphable: Number = 0;
  allAgriculturalCategories: any;

  formConfig: FormConfig = {
    is_egresos_needed: false, // this variable shows revenues button
    is_ingresos_needed: false, // this variable shows expenses button
    is_production_needed: false, // this variable shows production button
    is_losses_needed: false, // this variable shows losses button
    is_transfer_needed: false, // this variable shows production button
    is_activities_showable: false, // this variable shows activities table
    is_show_partner: false, // this list shows partners
    is_show_products_categories: false, // this list shows other products
    is_show_type_transaction_button: true, // this variable disables all buttons when false
  };

  from;
  to;
  productionTotal;
  perdidasTotal;
  money_section = true;
  lang;
  customPickerOptionsStart;
  customPickerOptionsEnd;
  constructor(
    private statisticsAPI: StatisticsAPI,
    private loadingController: LoadingController,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private store:Store
  ) {}
  fromDate: Date;
  toDate: Date;
  startLimitDate
  endLimitDate
  async ngOnInit() {
    this.setCustomPickerOptions();
    this.translateService.onLangChange.subscribe((value: string) => {
      this.setCustomPickerOptions();
      let date = new Date();
      this.from = new Date(
        date.getFullYear(),
        date.getMonth(),
        1
      ).toISOString();
      this.to = new Date().toISOString();
    });
    this.farm = await lastValueFrom(
      this.store.select(getFarm).pipe(take(1))
    );

    this.currency = await (await lastValueFrom(
      this.store.select(getCountry).pipe(take(1))
    )).currency;
    this.lang = (await Storage.get({ key: dev.LANGAGE_KEY })).value;
    let date = new Date();
    this.from = new Date(date.getFullYear(), date.getMonth(), 1).toISOString();
    this.to = new Date().toISOString();
    // this.startLimitDate =new Date(date.getFullYear(), date.getMonth(), new Date().getDate()).toISOString();
    // this.endLimitDate = new Date(date.getFullYear(), date.getMonth(), 3).toISOString();

    this.laodingModal = await this.loadingController.create();

    this.fetchSummaryByCashflowCategories(0, this.from, this.to);
    this.fetchSummaryByCashflowCategories(1, this.from, this.to);
    this.summary.id = this.id;
    await this.showApproriateButtons();
  }

  
  async showApproriateButtons() {
    let CASHFLOW_CATEGORIES = JSON.parse(
      (await Storage.get({ key: dev.CASHFLOW_CATEGORIES })).value
    );

    
    CASHFLOW_CATEGORIES?.find(
      (value) => value.id == this.id
    )?.cashflow_category.forEach((cashflowCategorie) => {
      if (cashflowCategorie.is_spent !== null) {
        if (cashflowCategorie.is_spent === 0) {
          this.formConfig.is_ingresos_needed = true;
        } else if (cashflowCategorie.is_spent === 1) {
          this.formConfig.is_egresos_needed = true;
        }
      }

      if (cashflowCategorie.is_a_loss !== null) {
        if (cashflowCategorie.is_a_loss === 0) {
          this.formConfig.is_losses_needed = true;
        } else if (cashflowCategorie.is_a_loss === 1) {
          this.formConfig.is_production_needed = true;
        }
      }

      if (
        cashflowCategorie.is_spent == null &&
        cashflowCategorie.is_a_loss == null
      ) {
        this.formConfig.is_transfer_needed = true;
      }
    });
  }

  async setCustomPickerOptions() {
    this.customPickerOptionsStart = {
    
      buttons: [
        {
          text: await this.translateService.get('cancel').toPromise(),
          cssClass: 'cancel-btn',

          handler: (value) => {
            return true;
          },
        },
        {
          text: await this.translateService.get('submit').toPromise(),
          cssClass: 'confirm-btn',
          handler: (value) => {
            this.from = new Date(
              value.year.value + '-' + value.month.value + '-' + value.day.value
            ).toISOString();
            this.endLimitDate =  new Date(
              value.year.value + '-' + value.month.value + '-' + (value.day.value+1)
            ).toISOString();
       
            return true;
          },
        },
      ],
    };
    this.customPickerOptionsEnd = {
      buttons: [
        {
          text: await this.translateService.get('cancel').toPromise(),
          cssClass: 'cancel-btn',

          handler: (value) => {
            return true;
          },
        },
        {
          text: await this.translateService.get('submit').toPromise(),
          cssClass: 'confirm-btn',
          handler: (value) => {
            this.to = new Date(
              value.year.value + '-' + value.month.value + '-' + value.day.value
            ).toISOString();

      
            return true;
          },
        },
      ],
    };
  }

  calculatePercent(total, singleEntry) {
    return ((singleEntry / total) * 100).toFixed(2) + ' % ';
  }

  show(val) {
    this.money_section = true;
    this.showExpenses = val;
    val === 2 ? this.pieChartBrowser(true) : this.pieChartBrowser(false);
  }

  async filter() {
    this.fetchSummaryByCashflowCategories(1, this.from, this.to);
  }

  fetchSummaryByCashflowCategories(income, from, to) {
    this.laodingModal.present();
    this.statisticsAPI
      .fetchStatsByCashflowCategories(from, to, this.id)
      .pipe(take(1))
      .subscribe(
        (values: any) => {
          if (income === 1) {
            this.displayableCategoriesIngresos = this.formatResponse(
              values[income]?.result?.details
            );
            this.summary.income = values[income]?.result?.total;
            this.calculateNet();
          } else {
            this.summary.expenses = values[income]?.result?.total;
            this.displayableCategoriesEgresos = this.formatResponse(
              values[income]?.result?.details
            );
            this.calculateNet();
          }

          this.laodingModal.dismiss();
        },
        (e) => {
          this.laodingModal.dismiss();
        }
      );
  }

  formatResponse(valueResponse: any) {
    const formatedValue = new Array();
    for (const [key, value] of Object.entries(valueResponse)) {
      formatedValue.push({
        title: key,
        title_en: value['title_en'],
        amount: value['amount'],
        percent: parseFloat(value['percent_value']).toFixed(2),
      });
    }
    return formatedValue;
  }

  showGraph() {
    this.showExpenses === 2
      ? this.pieChartBrowser(true)
      : this.pieChartBrowser(false);
  }

  async pieChartBrowser(isIncome) {
    let componentProps = {
      money_section: true,
      id: this.id,
      currency: this.currency,
      is_income: isIncome,
      token: this.token,
      from: this.from,
      to: this.to,
      laodingModal: this.laodingModal,
      farm: this.farm,
    };
    let modal = await this.toastUtils.modalBuilder(
      AccountingEntryCategorieStatsComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper']
    );
  }

  async showProduction(val) {
    let componentProps = {
      money_section: true,
      id: this.id,
      currency: this.currency,
      selectedProd: val,
      token: this.token,
      from: this.from,
      to: this.to,
      laodingModal: this.laodingModal,
      farm: this.farm,
    };
    await this.toastUtils.modalBuilder(
      ProductionPerdidasProductsStatsComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper']
    );
  }
  calculateNet() {
    this.summary.net = this.summary.income - this.summary.expenses;
  }
}
