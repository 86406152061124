import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, OnInit } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { dev } from '../../config/offlines_keys.json';
import { ModalController } from '@ionic/angular';
import { ObjectiveApi } from 'src/app/apis/objectives.service';
import { ObjectivesFormsComponent } from './objectives-forms/objectives-forms.component';
import { Objective } from 'src/app/models/objective.model';

@Component({
  selector: 'app-objectives',
  templateUrl: './objectives.component.html',
  styleUrls: ['./objectives.component.scss'],
})
export class ObjectivesComponent implements OnInit {
  owner;
  objectives;
  constructor(
    private objectiveApi: ObjectiveApi,
    private modalController: ModalController,
    private toastUtils: ToastUtils
  ) {}

  async ngOnInit() {
    this.owner = await Storage.get({ key: dev.USER_KEY }).then((value) =>
      JSON.parse(value.value)
    );

    this.refresh();
  }

  async addObjective() {
    const objective = new Objective();
    objective.owner_id = this.owner.id;
    let componentProps = {
      owner_id: this.owner.id,
      objective: objective,
      modalController: this.modalController,
      objectiveApi: this.objectiveApi,
    };
    let modal = await this.toastUtils.modalBuilder(
      ObjectivesFormsComponent,
      componentProps,
      ['my-custom-modal-css', 'modal-wrapper']
    );

    const { data } = await modal.onWillDismiss();
    this.refresh();
  }

  refresh() {
    this.objectiveApi.getObjectives().subscribe(async (val: any) => {
      this.objectives = val;
    });
  }
}
