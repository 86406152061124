import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import { Storage } from '@capacitor/storage';
import { dev } from '../../../../config/offlines_keys.json';
@Component({
  selector: 'app-transaction-type-buttons',
  templateUrl: './transaction-type-buttons.component.html',
  styleUrls: ['./transaction-type-buttons.component.scss'],
})
export class TransactionTypeButtonsComponent implements OnInit {
  @Input() is_production_needed = true;
  @Input() is_egresos_needed = true;
  @Input() is_perdidas_needed = true;
  @Input() is_ingresos_needed = true;
  @Input() is_transfer_needed = true;
  @Output() productionClickedEvent: EventEmitter<void> = new EventEmitter();
  @Output() egresosClickedEvent: EventEmitter<void> = new EventEmitter();
  @Output() ingresosClickedEvent: EventEmitter<void> = new EventEmitter();
  @Output() perdidasClickedEvent: EventEmitter<void> = new EventEmitter();
  @Output() transferClickEvent: EventEmitter<void> = new EventEmitter();
  ingresos_clicked = false;
  production_clicked = false;
  egresos_clicked = false;
  perdidas_clicked = false;
  transfer_clicked = false;
  @Input() cashFlow;
  constructor(private ref: ChangeDetectorRef) {}
  ngAfterContentChecked() {
    this.ref.detectChanges();
  }
  ngDoCheck(): void {
    //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
    //Add 'implements DoCheck' to the class.
    if (this.cashFlow?.goToProduction) {
      delete this.cashFlow?.goToProduction;

      this.ngOnInit();
    }
  }

  async ngOnInit() {
    const lang = await Storage.get({ key: dev.LANGAGE_KEY }).then(
      (value) => value.value
    );

    if (this.cashFlow && this.cashFlow.cashflow_categories) {
      if (
        this.cashFlow.cashflow_categories.is_a_loss == 0 &&
        this.cashFlow.cashflow_categories.is_spent == null
      ) {
        this.productionClick();
      } else if (
        this.cashFlow.cashflow_categories.is_a_loss == null &&
        this.cashFlow.cashflow_categories.is_spent == 1
      ) {
        this.egresosClick();
      } else if (
        this.cashFlow.cashflow_categories.is_a_loss == null &&
        this.cashFlow.cashflow_categories.is_spent == 0
      ) {
        this.ingresosClick();
      } else if (
        this.cashFlow.cashflow_categories.is_a_loss == 1 &&
        this.cashFlow.cashflow_categories.is_spent == null
      ) {
        this.perdidasClick();
      } else if (
        this.cashFlow.cashflow_categories.is_a_loss == null &&
        this.cashFlow.cashflow_categories.is_spent == null
      ) {
        this.transferClick();
      }
    }
  }

  ingresosClick() {
    this.ingresos_clicked = true;
    this.production_clicked = false;
    this.egresos_clicked = false;
    this.transfer_clicked = false;
    this.perdidas_clicked = false;
    this.ingresosClickedEvent.emit();
  }

  egresosClick() {
    this.ingresos_clicked = false;
    this.production_clicked = false;
    this.egresos_clicked = true;
    this.perdidas_clicked = false;
    this.transfer_clicked = false;

    this.egresosClickedEvent.emit();
  }

  productionClick() {
    this.ingresos_clicked = false;
    this.production_clicked = true;
    this.egresos_clicked = false;
    this.perdidas_clicked = false;
    this.transfer_clicked = false;

    this.productionClickedEvent.emit();
  }

  perdidasClick() {
    this.ingresos_clicked = false;
    this.production_clicked = false;
    this.egresos_clicked = false;
    this.perdidas_clicked = true;
    this.transfer_clicked = false;

    this.perdidasClickedEvent.emit();
  }
  transferClick() {
    this.ingresos_clicked = false;
    this.production_clicked = false;
    this.egresos_clicked = false;
    this.perdidas_clicked = false;
    this.transfer_clicked = true;

    this.transferClickEvent.emit();
  }
}
