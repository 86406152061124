import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bottom-sheet-overview',
  templateUrl: './bottom-sheet-overview.component.html',
  styleUrls: ['./bottom-sheet-overview.component.scss'],
})
export class BottomSheetOverviewComponent implements OnInit {
  @Input('item') item;
  @Input('date') date;
  @Input('modalController') modalController;
  constructor(private router: Router) {}

  ngOnInit() {}
  navigate(path,id =null) {
    this.modalController.dismiss();
    if ('/home/book' == path) {
      // this.router.navigate(path, { queryParams: { idCategory: 1 } });
      this.router.navigateByUrl(path+`?idCategory=${id}`);

    } else {
      this.router.navigateByUrl(path);
    }
  }
}
