import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import appRoutes from '../../config/routing.json';
import { PartnerMainViewComponent } from './partner-main-view/partner-main-view.component';
import { PartnerViewOneComponent } from './partner-view-one/partner-view-one.component';

const routes: Routes = [
  {
    path: '',
    component: PartnerMainViewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PartnerRoutingModule {}
