import { PopoverController } from '@ionic/angular';
import { ConfirmModalComponent } from '../widgets/confirm-modal/confirm-modal.component';
import { PopoverComponent } from '../widgets/popover/popover.component';

export const makePopover = async (
  popverController: PopoverController,
  message
): Promise<HTMLIonPopoverElement> => {
  const popover = await popverController.create({
    component: PopoverComponent,
    componentProps: { key1: message },
    cssClass: 'popover-custom',
  });
  await popover.present();
  return popover;
};

export const showConfirmModal = async (
  modalController,
  message,
  data = null,
  confirm_button,
  cancel_button
): Promise<HTMLIonModalElement> => {
  const modal = await modalController.create({
    component: ConfirmModalComponent,
    componentProps: {
      confirmation_text: message,
      modalController: modalController,
      submit_text: confirm_button,
      cancel_text: cancel_button,
      data: data ? data[0] : null,
    },
    cssClass: ['pop-over-style-confirm'],
  });
  modal.present();
  return modal;
};
