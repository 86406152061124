import { ToastUtils } from 'src/app/utils/toast_builder';
import { environment } from '../../../../environments/environment';
import routes from '../../../config/routing.json';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TeamModalPage } from 'src/app/modules/team/team-modal/team-modal.page';
import { EmployeesAPI } from '../../../apis/employees.service';
import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import { PriorDashboardComponent } from '../../prior-dashboard/prior-dashboard.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-team-permanente',
  templateUrl: './team-permanente.component.html',
  styleUrls: ['./team-permanente.component.scss'],
})
export class TeamPermanenteComponent implements OnInit {
  @Input() permanents = [];
  langage_package;
  main_endpoint = environment.appRoot;

  submit_label;
  @Input() isDashboardView;
  @Input() rootStack;
  @Input() nav;
  @Output() hasPermanentChange: EventEmitter<Boolean> = new EventEmitter();
  @Output() triggetDelete: EventEmitter<number> = new EventEmitter();
  constructor(
    public modalController: ModalController,
    public empAPI: EmployeesAPI,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private employeeAPI: EmployeesAPI,
    private router: Router
  ) {}

  async ngOnInit() {
    this.isDashboardView = this.router.url.includes(routes.dashboard.home)
    if (this.isDashboardView) this.fetch();
  }
  async showPopOver() {
    let componentProps = { key1: this.langage_package.info_bulle };

    await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
  }

  getPermanentEmp() {
    this.empAPI.getPermanentOrTomporalData().subscribe((response: any) => {
      this.permanents = response;
      this.permanents = response.filter(
        (value) =>
          value.is_permanent == 1 &&
          !value.morphable_id &&
          !value.morphable_type
      );
    });
  }
  async delete(id) {
    this.triggetDelete.emit(id);
  }
  async openModal(val, action) {
    let componentProps = {
      componentSource: 'permanent',
      action: action,
      paramID: val,
      paramTitle: await this.translateService
        .get('screens.equipe_permanent.header_modal_permanent')
        .toPromise(),
    };
    let modal = await this.toastUtils.modalBuilder(
      TeamModalPage,
      componentProps
    );

    await modal.onWillDismiss().then((value) => {
      const { data } = value;

      if (data.role == 'store') {
        if (!this.permanents) this.permanents = new Array();
        this.permanents.push(data.value);
      } else if (data.role == 'update') {
        let i = this.permanents.findIndex((value) => value.id == data.value.id);
        this.permanents[i] = data.value;
      }
    });
  }

  goBackToDashBoard() {
    this.nav.setRoot(this.rootStack, {
      nav: this.nav,
      rootStack: PriorDashboardComponent,
    });
  }

  fetch() {
    this.employeeAPI.getPermanentOrTomporalData().subscribe((value: any) => {
      this.permanents = value.filter(
        (element) => !element.morphable_type && element.is_permanent
      );
    });
  }

  async deleteEmployee(event) {
    await this.employeeAPI.deleteEmployee(event).toPromise();
    this.fetch();
  }
}
