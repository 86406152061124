import { createAction, props } from '@ngrx/store';
import { ProductSale } from 'src/app/models/cash-flow/sale.model';
import { PaginationResult } from 'src/app/models/pagination-result.model';
import { SaleFilter } from 'src/app/modules/coffee-sales/coffee-sales.component';

export const PAGINATE_SALES = 'PAGINATE_SALES';
export const PAGINATE_SALES_SUCCESS = 'PAGINATE_SALES_SUCCESS';
export const DELETE_SALES = 'DELETE_SALES';
export const paginateSales = createAction(
  PAGINATE_SALES,
  props<{
    ids: Array<number>;
    page_size: number;
    start_date: string;
    end_date: string;
    is_animal: number;
    page: number;
  }>()
);

export const paginateSalesSuccess = createAction(
  PAGINATE_SALES_SUCCESS,
  props<{ paginationResult: PaginationResult<ProductSale> }>()
);
export const deleteSales = createAction(
  DELETE_SALES,
  props<{ filter: SaleFilter; ids: Array<number> }>()
);
