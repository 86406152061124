import { createAction, props } from '@ngrx/store';
import { OtrosDisplayableListItem } from 'src/app/models/cash-flow/otros_displayable_list_item';

export const LOAD_OTROS_LISTABLE_ITEMS = 'LOAD_OTROS_LISTABLE_ITEMS';
export const LOAD_OTROS_LISTABLE_ITEMS_SUCCESS =
  'LOAD_OTROS_LISTABLE_ITEMS_SUCCESS';

export const loadOtrosListableItems = createAction(LOAD_OTROS_LISTABLE_ITEMS);

export const loadOtrosListableItemsSuccess = createAction(
  LOAD_OTROS_LISTABLE_ITEMS_SUCCESS,
  props<{ otrosListablesItems: Array<OtrosDisplayableListItem> }>()
);
