export const MANO_DE_OBRA_ADMINISTRATIVA_FAMILIAR = 285;
export const SALARIO_MENSUALES_ID = 148;
export const INITIAL_SOLD_CASHFLOW_CATEGORIE_ID = 380;
export const BEPS_PENSION_PLAN_CASHFLOW_CATEGORIE = 382;
export const AHORO_PARA_VEJEZ_CASHFLOW_CATEGORIE = 376;
export const APORTES_A_PENSION_CASHFLOW_CATEGORIE = 281;
export const TANSFER_TO_CASH = 113;
export const TRANSFER_TO_BANK = 114;
export const BUY_AGRICOLAS_LOT = 383;
export const BUY_OTRAS_USE_LOT = 384;
export const SELL_AGRICOLAS_LOT_CASHFLOW_CATEGORIE = 385;
export const SELL_OTRO_USO_LOT_CASHFLOW_CATEGORIE = 386;
export const MANO_DE_OBRA_FAMILIAR = 115;
export const MANO_DE_OBRA_PERMANENTE = 116;
export const COMPRA_ARBOLES_CASHFLOW_CATEGORIES = 94;
export const COMPRA_ARBOLES_COFFE_CASHFLOW_CATEGORIES_EXPENSES = 76;

export const PERDIDA_ARBOLES_CASHFLOW_CATEGORIES = 100;
export const VENTA_ARBOLES_CASHFLOW_CATEGORIES = 371;
export const VENTA_ARBOLES_COFFE_CASHFLOW_CATEGORIES = 475;
export const PRODUCTION_ARBOLES = 488;
export const PRODCUTION_ARBOLES_CAFE = 489;
export const PAGO_CAUTION_COFFE_CASHFLOW_EXPENSES = 75;

export const HARVEST_ACTIVITY = 43;
export const HARVEST_ACTIVITY_IDS = [43, 42];
export const MANO_OBRA_EXPENSES = [64, 65, 66];
export const GASTOS_RECURSOS_HUMANOS = 142;
export const GAST_CASHFLOW_CATEGORIES = 95;
export const GAST_CASHFLOW_CATEGORIES_ANIMAL = 359;
export const MANO_DE_OBRA_ADMINISTRATIVA = 381;
export const LOT_TRANSACTION_CASHFLOW_CATEGORIES = [
  BUY_AGRICOLAS_LOT,
  BUY_OTRAS_USE_LOT,
  SELL_AGRICOLAS_LOT_CASHFLOW_CATEGORIE,
  SELL_OTRO_USO_LOT_CASHFLOW_CATEGORIE,
];
export const CASHFLOW_REQUIRING_UNIT_AS_UNIT_ONLY = [
  VENTA_ARBOLES_CASHFLOW_CATEGORIES,
  VENTA_ARBOLES_COFFE_CASHFLOW_CATEGORIES,
  COMPRA_ARBOLES_CASHFLOW_CATEGORIES,
  PRODUCTION_ARBOLES,
  PRODCUTION_ARBOLES_CAFE,
  98,
  389,
  396,
  409,
  422,
  426,
  439,
  449,
  456,
  364,
  388,
  398,
  408,
  421,
  425,
  438,
  448,
  455,
  100,
  391,
  390,
  397,
  398,
  410,
  411,
  423,
  424,
  427,
  428,
  440,
  441,
  450,
  451,
  457,
  458,
];
export const CASHFLOW_FIRST_SALE_COFFEE_ID = [
  261, 262, 263, 304, 350, 351, 361, 360,
];
export const CASHFLOW_SECOND_COFFEE_SELLS_ID = [
  16, 17, 264, 265, 266, 267, 307,
];
export const CASHFLOW_SECOND_SELLS_CEREZA_COFFEE_ID = [264, 265];
export const CASHFLOW_SECOND_SELLS_MOJADO_COFFEE_ID = 266;
export const CASHFLOW_SECOND_SELLS_SECO_COFFEE_ID = 267;
export const CASHFLOW_SECOND_SELLS_SECO_CACAO_ID = 307;

export const PENSION_PLAN_TYPE_GOV = 'pension_plan_gov';
export const PENSION_PLAN_TYPE_NO_GOV = 'pension_plan';
export const CURRENT_ACCOUNT = 'current_account';
export const INVESTMENT_ACCOUNT = 'investment_account';
export const SAVING_ACCOUNT = 'savings_account';
export const CASH_DEPOSI_TYPE = 'cash_money';

export const MONEY_LOAN_OPERATION = 'money loan';
export const DEBT_OPERATION = 'debt';
export const GARENTEE_OPERATION = 'garentee';

export const REASON_OPERATION_CAFE = 'coffee';
export const REASON_OPERATION_ADMIN_ARRAY = 'farm administration';
//export const REASON_OPERATION_ADMIN = 'Administración de la finca';

export const REASON_OPERATION_PERSONAL = 'personal';

export const CAFE_ACCOUNTING_ENTRY_CATEGORY = 1;
export const COCOA_ACCOUNTING_ENTRY_CATEGORY = 10;
export const OTROS_ACCOUNTING_ENTRY_CATEGORY = 2;
export const ANIMALES_ACCOUNTING_ENTRY_CATEGORY = 3;
export const ADMINISTRATION_ACCOUNTING_ENTRY_CATEGORY = 4;
export const AHOROS_ACCOUNTING_ENTRY_CATEGORY = 5;
export const PERSONAL_ACCOUNTING_ENTRY_CATEGORY = 6;
export const PRIPIEDADES_ACCOUNTING_ENTRY_CATEGORY = 7;
export const GAP_CALCULATION_ACCOUNTING_ENTRIE_CATEGORIE_ID = 8;
export const INTITIAL_SOLD_ACCOUNTING_ENTRIE_CATEGORIE_ID = 10;
export const HOGAR_ACCOUNTING_ENTRIE_CATEGORIE_ID = 9;

export const DEBT_REASON_FARM_ADMIN = 'farm administration';
export const DEBT_REASON_PERSONAL = 'personal';
export const DEBT_REASON_CAFE = 'coffee';
export const DEBT_REASON_OTROS_PRODUCTOS = 'other product';

export const PAGO_CAUTION_CASHFLOW_CATEGORIE_ADMIN = 109;
export const RECIBO_DE_DEUDA_CASHFLOW_CATEGORIE_ADMIN = 372;
export const PAGO_DE_PRESTAMO_CASHFLOW_CATEGORIE_ADMIN = 110;

export const PAGO_CAUTION_CASHFLOW_CATEGORIE_PERSONAL = 191;
export const RECIBO_DE_DEUDA_CASHFLOW_CATEGORIE_PERSONAL = 200;
export const PAGO_DE_PRESTAMO_CASHFLOW_CATEGORIE_PERSONAL = 190;

export const PAGO_CAUTION_CASHFLOW_CATEGORIE_CAFE = 7;
export const RECIBO_DE_DEUDA_CASHFLOW_CATEGORIE_CAFE = 369;
//

export const PAGO_CAUTION_CASHFLOW_CATEGORIE_OTROS = 96;
export const RECIBO_DE_DEUDA_CASHFLOW_CATEGORIE_OTROS = 375;

export const RECUPERACION_DE_CAUCION_CASHFLOW_CATEGORIE_OTROS = 103;
export const RECUPERACION_DE_PRESTAMO_CASHFLOW_CATEGORIE_OTROS = 371;
export const REEMBOLSO_DE_DEUDA_CASHFLOW_CATEGORIE_OTROS = 97;
//
export const RECUPERACION_DE_CAUCION_CASHFLOW_CATEGORIE_PERSONAL = 201;
export const RECUPERACION_DE_PRESTAMO_CASHFLOW_CATEGORIE_PERSONAL = 373;
export const REEMBOLSO_DE_DEUDA_CASHFLOW_CATEGORIE_PERSONAL = 189;

export const GAB_CALCULATION_INCOME = 491;
export const GAB_CALCULATION_EXPENSE = 490;

export const OPERATION_UPDATER_CASHFLOW_CATEGORIES = [
  RECUPERACION_DE_CAUCION_CASHFLOW_CATEGORIE_PERSONAL,
  RECUPERACION_DE_PRESTAMO_CASHFLOW_CATEGORIE_PERSONAL,
  REEMBOLSO_DE_DEUDA_CASHFLOW_CATEGORIE_PERSONAL,
  112,
  111,
  107,
  RECUPERACION_DE_CAUCION_CASHFLOW_CATEGORIE_OTROS,
  REEMBOLSO_DE_DEUDA_CASHFLOW_CATEGORIE_OTROS,
  8,
  16,
];

export const CAFE_IDS = [16, 17, 18, 1];
export const LANDING_CASHFLOW_ENTRY = 'landing_cashflow_generated_entry';
export const ANIMAL_IDS = [
  4, 16, 19, 22, 23, 26, 29, 30, 32, 58, 60, 61, 62, 64, 68,
];
export const GRAPH_COLORS = [
  '#303F9F',
  '#8E24AA',
  '#F9A825',
  '#5D4037',
  '#37474F',
  '#FFFF00',
  '#4DB6AC',
  '#FF4081',
  '#CE93D8',
  '#69F0AE',
  '#81D4FA',
  '#311B92',
];

export const LECHE_ID = [18, 21, 25, 54];
export const MASSANDLIQUID_ID = [57];
export const QUESO_IDS = [50, 51, 52, 55, 28, 56, 59];

export const PROD_IDS = [9, 10, 11];

export const MORPH_Farm = 'App\\Models\\Farm';
export const MORPH_FamilyMember = 'App\\Models\\FamilyMember';
export const MORPH_Owner = 'App\\Models\\Owner';
export const MORPH_NotApplicable = 'Not applicable';
export const MORPH_Category = 'App\\Models\\Category';
export const MORPH_Product = 'App\\Models\\Product';
export const MORPH_MaterialCategorie = 'App\\Models\\MaterialCategorie';
export const MORPH_Lot = 'lot';
export const MORPH_Garentee = 'garentee';
export const MORPH_Objective = 'objective';
export const MORPH_Salarie = 'Salarie';
export const MORPH_Coffe_Sell = 'coffe_sell';
export const MORPH_Family_member_personal = 'family_member_personal';
export const PRODUCT_MORPHABLE = 'product';
export const Account = 'account';
