import { AccountingEntryCategorie } from 'src/app/models/accounting/accounting_entries_categories';
import { Observable } from 'rxjs';
import { dev } from '../config/endpoints.json';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AccountingCategoriesAPI {
  base_endpoint = environment.appRoot + '/api/';

  constructor(private httpClient: HttpClient) {}

  getAccountingCategories(): Observable<any> {
    const url =
      this.base_endpoint +
      dev.endpoint.accounting_categories.list_accounting_categories;
    return this.httpClient.get(url);
  }

  getTotalsAccountingCategories(startDate, endDate): Observable<any> {
    const url =
      this.base_endpoint +
      dev.endpoint?.accounting_categories?.get_totals_accounting_categories +
      '/' +
      startDate?.split('T')[0]+
      '/' +
      endDate?.split('T')[0];
    return this.httpClient.get(url);
  }
}
