import { dev } from 'src/app/config/offlines_keys.json';
import { PopoverImgComponent } from 'src/app/widgets/popover-img/popover-img.component';
import { LoginService } from '../../apis/login.service';
import { Component, NgZone, OnInit } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { CountriesService } from 'src/app/apis/countries.service';
import { PopoverComponent } from 'src/app/widgets/popover/popover.component';
import { PopoverController } from '@ionic/angular';
import { ToastUtils } from '../../utils/toast_builder';
import { mapLangeNameObjects } from 'src/app/utils/array-filtrer';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@capacitor/storage';
import { lastValueFrom, take } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  getAllCounties,
  getCountry,
} from 'src/app/utils/states-management/selector/countries.selector';
import { cloneDeep } from 'lodash';
import { environment } from 'src/environments/environment';
import { loadCountries } from 'src/app/utils/states-management/actions/countries.action';
import { CountriesState } from 'src/app/utils/states-management/reducers/countries.reducer';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent implements OnInit {
  country: any;
  userCountry: any;
  mobile_number: any;
  code: any;
  countries = <any>[];
  countryName;
  network = '';
  lang;
  base_url = environment.appRoot;
  loadingModal;
  constructor(
    public LoginService: LoginService,
    public router: Router,
    public toastControllerComponent: ToastController,
    public countriesService: CountriesService,
    private popover: PopoverController,
    private zone: NgZone,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private store: Store<{ countries: CountriesState }>,
    private loadingCtrl: LoadingController
  ) {}
  async ngOnInit(): Promise<void> {
    this.store.select('countries').subscribe(async (value) => {
      if (value.list_countries.length == 0) {
        this.store.dispatch(loadCountries());
      } else {
        this.countries = await mapLangeNameObjects(
          cloneDeep(value?.list_countries),
          this.translateService.currentLang
        );
        this.userCountry = cloneDeep(value?.user_country);
        this.country = this.userCountry;

        this.country.file_path =this.country?.file?.path
        this.countryName = this.translateService.instant(
          'countries.' + this.country.id
        );
        this.code =this.userCountry.indicatif;
      }
    });
    this.loadingModal = await this.loadingCtrl.create({
      spinner: 'bubbles',
      mode: 'ios',
    });
  }

  async showPopOver() {
    let componentProps = {
      key1: await this.translateService.instant(
        'screens.create_account.info_bull'
      ),
    };
    await this.toastUtils.popOverBuilder(
      componentProps,
      PopoverComponent,
      'popover-custom'
    );
  }

  async presentToast(message) {
    this.toastUtils
      .toastBuilder('', 'bottom', message, 'dark');
  }

  async presentToastGoLogin(message) {
    const toast = await this.toastControllerComponent.create({
      message: message,
      duration: 1000,
      position: 'top',
      color: 'dark',
      cssClass: 'custom-toast',
      buttons: [
        {
          side: 'end',
          text: await this.translateService
            .get('login')
            .toPromise()
            .then((value) => value),
          handler: () => {
            this.zone.run(() => {
              this.router.navigate(['/login']);
            });
          },
        },
      ],
    });
    toast.present();
  }

  changeIndicatif(event) {
    this.code = +event.target?.value?.indicatif;
  }

  async handelChangeCountrie(ev: any) {
    const popover = await this.popover.create({
      component: PopoverImgComponent,
      cssClass: 'pop-over-style-login-prefix',
      event: ev,
      translucent: true,
      componentProps: { data: this.countries, component: 'create-account1' },
    });
    popover.present();
    const { data } = await popover.onDidDismiss();
    if (data) {
      this.code = data.data.indicatif;
      this.country = data.data;
      this.countryName = this.translateService.instant(
        'countries.' + data.data.id
      );
      this.country.file_path = this.base_url + data?.data?.file?.path;
    } else {
      this.code = '';
    }
  }

  async handelChangeCountrieName(ev: any) {
    const popover = await this.popover.create({
      component: PopoverImgComponent,
      cssClass: 'pop-over-style-login',
      event: ev,
      translucent: true,
      componentProps: { data: this.countries, component: 'create-account2' },
    });
    popover.present();
    const { data } = await popover.onDidDismiss();
    if (data) {
      this.code = data.data.indicatif;
      this.country = data.data;
      this.countryName = this.translateService.instant(
        'countries.' + data.data.id
      );
      this.country.file_path = this.base_url + data?.data?.file?.path;
    } else {
      this.countryName = '';
    }
  }
  changeValue(mobile_number) {
    this.mobile_number = mobile_number.value;
  }
  async submitForm() {
    const data = new FormData();
    data.append(
      'lang',
      await this.translateService.get('sms_message').toPromise()
    );

    let lang = await (await Storage.get({ key: dev.LANGAGE_KEY })).value;

    // if (this.code && this.code.length > 0) {
      this.code = this.code.toString();
   

    if (!this.mobile_number || !this.code || !this.userCountry?.id) {
      this.presentToast(
        await this.translateService
          .get('errors.all_fields')
          .toPromise()
          .then((value) => value)
      );
      return;
    } else if (
      isNaN(this.mobile_number) ||
      this.mobile_number.toString().length > 10 ||
      this.mobile_number.toString().length < 5
    ) {
      this.presentToast(
        await this.translateService.get('errors.mobile_number').toPromise()
      );
      return;
    } else {
      this.loadingModal.present();
      data.append('code', this.code);

      data.append('country_id', this.userCountry?.id);
      data.append('mobile_number', this.mobile_number);

      this.LoginService.register(data).subscribe(async (response) => {
        if (response.status === 409) {
          this.presentToastGoLogin(
            await this.translateService
              .get('errors.mobile_already_used')
              .toPromise()
          );
          await this.loadingModal.dismiss();
        } else {
          await this.loadingModal.dismiss();

          let componentProps = {
            key1: await this.translateService
              .get('screens.create_account.click_sms_info_bull')
              .toPromise(),
            button_label: 'close',
          };
          let popover = await this.toastUtils.popOverBuilder(
            componentProps,
            PopoverComponent,
            'popover-custom'
          );
          popover.onDidDismiss();
          let network = this.network ? this.network : 'null';
          this.router.navigate([
            '/sms-validation/' +
              this.code +
              '/' +
              this.mobile_number +
              '/' +
              network,
          ]);
        }
      });
    }
  }

  goBack() {
    this.router.navigate(['/terms']);
  }
}
