import { RoutingConfig } from 'src/app/utils/routing-config';
import { ToastUtils } from 'src/app/utils/toast_builder';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@capacitor/storage';
import { dev } from '../../config/offlines_keys.json';
import { IonNav, PopoverController } from '@ionic/angular';
import { UserInfo } from 'src/app/apis/users-info.service';
import { TranslateService } from '@ngx-translate/core';
import { CountriesService } from 'src/app/apis/countries.service';
import { take, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import appRoutes from '../../config/routing.json';
import { Store } from '@ngrx/store';
import { getAllCounties } from 'src/app/utils/states-management/selector/countries.selector';
import { cloneDeep } from 'lodash';
import { mapLangeNameObjects } from 'src/app/utils/array-filtrer';
import {
  loadCountries,
  setUserCountry,
} from 'src/app/utils/states-management/actions/countries.action';
import { LangPopoverComponent } from 'src/app/widgets/lang-popover/lang-popover.component';
@Component({
  selector: 'app-page-country',
  templateUrl: './page-countries.component.html',
  styleUrls: ['./page-countries.component.scss'],
})
export class PageCountriesComponent implements OnInit {
  select_langage;
  list = [
    { value: 'Español', key: 'es' },
    { value: 'English', key: 'en' },
  ];
  @Output() previousClicked: EventEmitter<any> = new EventEmitter();
  searchInput;
  countriesList;
  base_url = environment.appRoot
  selectedCountry;
  @Input('step') step;
  @Input() nav: IonNav;
  constructor(
    private router: Router,
    public modalController: PopoverController,
    private userInfo: UserInfo,
    public translateService: TranslateService,
    private toastUtils: ToastUtils,
    private countriesAPI: CountriesService,
    private store: Store
  ) {}
  handelSearch() {
    if (this.searchInput) {
      this.countriesList = this.countriesList.filter((val) =>
        this.translateService
          .instant('countries.' + val.id)
          .includes(this.searchInput)
      );
    } else {
      this.countriesList = this.FullArray;
    }
  }
  FullArray;

  async ngOnInit() {
    this.store.select(getAllCounties).subscribe(async (countries) => {
      if (!countries?.length) {
        this.store.dispatch(loadCountries());
      }
      this.countriesList = cloneDeep(countries).filter(
        (val) => val?.is_selectable_country
      );
      this.FullArray = this.countriesList;
      this.countriesList.map((element) => {
        element.file_path = this.base_url + element.file.path;
        // element.config = JSON.parse(element.config)
        return element;
      });
      //   this.countriesList = await mapLangeNameObjects(
      //     cloneDeep(countries),
      //     this.translateService.currentLang
      //   );
    });

    // this.countriesAPI
    //   .getSelectableCountries()
    //   // .pipe(take(1))
    //   .subscribe((val) => {
    //     this.countriesList = val;
    //     this.FullArray = val;
    //     this.countriesList.map((element) => {
    //       element.file_path = this.base_url + element.file.path;
    //       // element.config = JSON.parse(element.config)
    //       return element;
    //     });
    //   });
  }
  async onSave() {
    if (this.selectedCountry) {
      let selectedCountry = this.countriesList.find(
        (country) => country.id == this.selectedCountry
      );
      this.store.dispatch(setUserCountry({ country: selectedCountry }));
 
      this.previousClicked.emit(this.step + 1);
    } else {
      
        await this.toastUtils.toastBuilder(
          await this.translateService
            .instant('errors.please_select_country'),
          null,
          null
        )
      
    }
  }

  async ChangeLang(country) {
    const popover = await this.toastUtils.popOverBuilder(
      {
        country: country,
      },
      LangPopoverComponent,
      'pop-over-style-languages-selection'
    );
    const { data } = await popover.onDidDismiss();
    if (data?.langKey) {
      this.onSave();
    } else {
    }
  }
  back() {
    this.previousClicked.emit(this.step - 1);
  }
}
