import { createAction, props } from '@ngrx/store';
import { Farm } from 'src/app/models/farm/farm.model';

export const LOAD_FARM = 'LOAD_FARM';
export const LOAD_FARM_FROM_LOCAL_STORAGE = 'LOAD_FARM_FROM_LOCAL_STORAGE';
export const FARM_SUCCESSFULLY_LOADED = 'FARM_SUCCESSFULLY_LOADED';
export const FARM_SERVER_ACCESS_ERROR = 'FARM_SERVER_ACCESS_ERROR';
export const FARM_SERVER_ACCESS_ERROR_DISPLAYED =
  'FARM_SERVER_ACCESS_ERROR_DISPLAYED';
export const UPDATE_CLUSTER_DETAIL = 'UPDATE_CLUSTER_DETAIL';
export const UPDATE_FARM = 'UPDATE_FARM';
export const UPDATE_FARM_SUCCESS = 'UPDATE_FARM_SUCCESS';

export const loadFarm = createAction(
  LOAD_FARM
);

export const loadFarmFromLocalStorage = createAction(
  LOAD_FARM_FROM_LOCAL_STORAGE
);

export const farmSuccessFulyLoaded = createAction(
  FARM_SUCCESSFULLY_LOADED,
  props<{ farm: Farm }>()
);

export const updateClusterDetails = createAction(
  UPDATE_CLUSTER_DETAIL,
  props<{ data: any }>()
);
export const updateFarm = createAction(
  UPDATE_FARM,
  props<{ data: Farm;id:number }>()
);

export const farmUpdateSuccess = createAction(
  UPDATE_FARM_SUCCESS,
  props<{ data: Farm }>()
);
