import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-scrollable-elements-list',
  templateUrl: './scrollable-elements-list.component.html',
  styleUrls: ['./scrollable-elements-list.component.scss'],
})
export class ScrollableElementsListComponent implements OnInit {

  @Input() modalController: ModalController;
  @Input() modalTitle;
  @Input() itemsList;

  selectedValue: string;

  label_1 = 'cancel';
  label_2 = 'confirm';
  constructor() { }

  ngOnInit() {}

  changeSelectedValue(value: string) {
    this.selectedValue = value
    this.confirm()
  }

  dismiss() {
    this.modalController.dismiss();
  }

  confirm() {
    this.modalController.dismiss({
      title: this.modalTitle,
      selectedValue: this.selectedValue
    });
  }

}
