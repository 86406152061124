import { getOwnerById } from 'src/app/utils/states-management/actions/owner.action';
import { Store } from '@ngrx/store';
import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { LoginService } from 'src/app/apis/login.service';
import { users } from './demo_accounts.json';
import { dev } from '../../../config/offlines_keys.json';
import { OwnerAPI } from 'src/app/apis/owners.service';
import { handleResponse } from 'src/app/utils/user_data_storage';
import { Router } from '@angular/router';
import routes from '../../../config/routing.json';
import { ModalController } from '@ionic/angular';
import { UserInfo } from 'src/app/apis/users-info.service';
import { orderAlphabaticallyByAttribute } from 'src/app/utils/array-filtrer';
import { RoutingConfig } from 'src/app/utils/routing-config';
import { switchMap, take, map } from 'rxjs/operators';
import Owner from 'src/app/models/owner.model';
import { cloneDeep } from 'lodash';
import { lastValueFrom } from 'rxjs';
import { getAllCounties } from 'src/app/utils/states-management/selector/countries.selector';
import { setUserCountry } from 'src/app/utils/states-management/actions/countries.action';

@Component({
  selector: 'app-existing-accounts',
  templateUrl: './existing-accounts.component.html',
  styleUrls: ['./existing-accounts.component.scss'],
})
export class ExistingAccountsComponent implements OnInit {
  lang;
  users = orderAlphabaticallyByAttribute(users, 'name', 'es');

  constructor(
    private loginService: LoginService,
    private ownerApi: OwnerAPI,
    private router: Router,
    private modalController: ModalController,
    private userInfo: UserInfo,
    private routingConfig: RoutingConfig,
    private store: Store<{ owner: Owner }>,

  ) {}

  async ngOnInit() {
    this.lang = await this.userInfo.getSelectedLangage();
  }

  logIn(mobile_number) {
    this.loginService
      .validateAndLoginExistingAccount(mobile_number, '57')
      .pipe(
        take(1),
        switchMap(async (reponse) => {
          await Storage.set({
            key: dev.ACCESS_TOKEN,
            value: reponse.access_token,
          });
          // await Storage.set({
          //   key: dev.USER_DATA,
          //   value: JSON.stringify(reponse.user),
          // });
          // await Storage.set({
          //   key: dev.USER_KEY,
          //   value: JSON.stringify(reponse.user),
          // });
          return reponse;
        }),
        map(async (reponse) => {
          this.store.dispatch(getOwnerById());

        })
      )
      .subscribe(async () => {
        let country = cloneDeep(
          await lastValueFrom(this.store.select(getAllCounties).pipe(take(1)))
        ).find(
          (value) => value.id == 2
        );
        if (country) {
          this.store.dispatch(setUserCountry({country:country}))
          
          await this.routingConfig.saveCropProductListToStorage();
        }

        this.modalController.dismiss();
        this.router.navigateByUrl(routes.dashboard.profile);
      });
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
