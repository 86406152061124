import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { ModalCashflowEntryListComponent } from 'src/app/modules/dashboard/modal-cashflow-entry-list/modal-cashflow-entry-list.component';
import { ToastUtils } from 'src/app/utils/toast_builder';

@Component({
  selector: 'app-section-balance',
  templateUrl: './section-balance.component.html',
  styleUrls: ['./section-balance.component.scss'],
})
export class SectionBalanceComponent implements OnInit {
  @Input() balance;
  @Input() currency;
  @Input() isDataLoading:boolean;

  constructor(
    private popOverController: PopoverController,
    private toastUtils: ToastUtils,
    private router: Router

  ) { }
   animatedBalance=0
  ngOnInit() {
   
  }
  async presentCashFlowInputModal() {
    let componentProps = {
      modalController: this.popOverController,
    };
    const popover = await this.toastUtils.popOverBuilder(
      componentProps,
      ModalCashflowEntryListComponent,
      ['popover_class', 'diario']
    );
    await popover.onWillDismiss();
    this.router.navigateByUrl('/home/book')

  }
ngOnChanges(changes: SimpleChanges): void {
  //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
  //Add '${implements OnChanges}' to the class.
  this.animatedBalance = this.balance;
  // if(this.balance>0){
  //   let interval = setInterval(() => {
  //     this.animatedBalance+=(this.balance * 0.1)
  //     if(this.animatedBalance == this.balance) clearInterval(interval)
  //   }, 1);
  // }else if(this.balance<0){
  //   let interval = setInterval(() => {
  //     this.animatedBalance-=(this.balance * 0.1)
  //     if(this.animatedBalance == this.balance) clearInterval(interval)
  //   }, 1);
  // }
}
}
